import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ProjectCard from 'containers/profile/projects/ProjectCard';
import ClientList from './ClientList';

function RecentlyVisited(props) {
  const { clients, projects } = props;

  return (
    <Fragment>
      <div className='l-container-block l-container-block--boxed' data-testid='recently-visited-clients-wrapper'>
        <h2>Your recent clients</h2>
        <ClientList clients={clients} />
      </div>
      <div className='l-container-block l-container-block--boxed' data-testid='recently-visited-projects-wrapper'>
        <h2>Your recent projects</h2>
        <div className='l-grid l-grid--thirds'>
          {projects.map((project) => (
            <ProjectCard key={project._id} project={project} />
          ))}
        </div>
      </div>
    </Fragment>
  );
}

RecentlyVisited.propTypes = {
  clients: PropTypes.array.isRequired,
};

export default RecentlyVisited;
