import { reaction, observable, computed, makeObservable } from 'mobx';
import AgentPoll from '../common/agent';
import { PERSONAL_DATA_FIELDS } from '../shared/engage/engage-question-constants';

const Agent = AgentPoll.Projects;

const ITEM_LABEL = 'Personal data acquisition';
const UPDATE_SUCCESS = 'Saved';
const UPDATE_ERROR = `Error saving ${ITEM_LABEL}`;
const INITIAL_TITLE = 'Tell us a little about yourself!';
const INITIAL_DESCRIPTION =
  'Before we can start, we need to collect some information about you. We promise it gets more interesting from here!';

const _required = {
  required: true,
  visible: true,
};

const _optional = {
  required: false,
  visible: false,
};
const _extra = {
  required: false,
  visible: false,
  label: '',
  lookupValues: '',
};

function getInitialValues() {
  const fieldConfig = {};
  fieldConfig[PERSONAL_DATA_FIELDS.FIRST_NAME] = _required;
  fieldConfig[PERSONAL_DATA_FIELDS.LAST_NAME] = _required;
  fieldConfig[PERSONAL_DATA_FIELDS.EMAIL] = _required;
  fieldConfig[PERSONAL_DATA_FIELDS.ADDRESS] = _optional;
  fieldConfig[PERSONAL_DATA_FIELDS.POSTAL_CODE] = _optional;
  fieldConfig[PERSONAL_DATA_FIELDS.PHONE] = _optional;
  fieldConfig[PERSONAL_DATA_FIELDS.EXTRA1] = _extra;
  fieldConfig[PERSONAL_DATA_FIELDS.EXTRA2] = _extra;

  const _init = {
    fieldConfiguration: fieldConfig,
    questionText: INITIAL_TITLE,
    questionSubtext: INITIAL_DESCRIPTION,
    active: false,
  };
  return _init;
}

function getDefaultValues() {
  const fieldConfig = {};
  fieldConfig[PERSONAL_DATA_FIELDS.POSTAL_CODE] = '';
  fieldConfig[PERSONAL_DATA_FIELDS.EXTRA1] = '';
  fieldConfig[PERSONAL_DATA_FIELDS.EXTRA2] = '';

  const defs = {
    fieldConfiguration: fieldConfig,
    questionText: '',
    questionSubtext: '',
  };
  return defs;
}

export default class PersonalDataStore {
  _loading = false;

  _initialized = false;

  _error = null;

  _personalData = getInitialValues();

  _personalDataDefault = getDefaultValues();

  constructor(rootStore, projectStore) {
    makeObservable(this, {
      _personalData: observable,
      _personalDataDefault: observable,
      _loading: observable,
      _initialized: observable,
      _error: observable,

      personalData: computed,
      personalDataDefault: computed,
      isLoading: computed,
      isInitialized: computed,
      error: computed,
    });

    this.rootStore = rootStore;
    this.projectStore = projectStore;
    reaction(
      () => this.projectStore.isInitialized,
      () =>
        this.loadData(
          this.projectStore.project?.runtimeOptions?.personalData,
          this.projectStore.projectDefault?.runtimeOptions?.personalData,
        ),
    );
  }

  get isLoading() {
    return this._loading;
  }

  get isInitialized() {
    return this._initialized;
  }

  get error() {
    return this._error;
  }

  /**
   * ADAPTERS
   * In the DATABASE we have an array of options, while on the UI (for now)
   * we have a ;-separated string, so I need to convert the formats back-and-forth
   */

  static fromDb2UiAdapter(orig) {
    const clone = JSON.parse(JSON.stringify(orig));
    ['extra1', 'extra2'].forEach((field) => {
      if (clone[field].lookupValues) {
        clone[field].lookupValues = clone[field].lookupValues.join(';');
      }
    });
    return clone;
  }

  static fromUi2DbAdapter(orig) {
    const clone = JSON.parse(JSON.stringify(orig));
    ['extra1', 'extra2'].forEach((field) => {
      const trimmed = (clone[field]?.lookupValues || '').trim();
      if (trimmed) {
        const split = trimmed.split(';');
        clone[field].lookupValues = split.map((item) => item.trim()).filter((item) => !!item);
      }

      if (!clone[field].lookupValues || clone[field].lookupValues.length === 0) {
        delete clone[field].lookupValues;
      }
    });
    return clone;
  }

  get personalData() {
    return {
      ...this._personalData,
      fieldConfiguration: PersonalDataStore.fromDb2UiAdapter(this._personalData.fieldConfiguration),
    };
  }

  get personalDataDefault() {
    return {
      ...this._personalDataDefault,
      fieldConfiguration: PersonalDataStore.fromDb2UiAdapter(this._personalDataDefault.fieldConfiguration),
    };
  }

  // /**
  //  * Load and prepare data already loaded in ProjectStore
  //  * @param team
  //  */
  loadData(persData, persDataDefault) {
    // if (persData?.fieldConfiguration[PERSONAL_DATA_FIELDS.FIRST_NAME]) {
    if (persData && persData.fieldConfiguration && persData?.fieldConfiguration[PERSONAL_DATA_FIELDS.FIRST_NAME]) {
      this._personalData = persData;
      this._personalDataDefault = persDataDefault;
    } else {
      this._personalData = getInitialValues();
      this._personalDataDefault = getDefaultValues();
    }

    this._initialized = true;
  }

  async update(formData) {
    this._loading = false;
    this._error = null;

    try {
      const data = PersonalDataStore.fromUi2DbAdapter(formData.fieldConfiguration);

      const finalData = {
        fieldConfiguration: data,
        questionText: formData.questionText,
        questionSubtext: formData.questionSubtext,
      };

      await Agent.updatePersonalDataConfiguration(this.projectStore.project._id, finalData);

      await this.projectStore.refresh();
      this.rootStore.toastrStore.success(UPDATE_SUCCESS);
    } catch (err) {
      this._error = err;
      this.rootStore.toastrStore.error(UPDATE_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  resetFlags = () => {
    this._loading = false;
  };
}
