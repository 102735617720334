import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import SvgIcon from '../../../../../common/components/SvgIcon';
import PreviewUtils from '../../../../../common/PreviewUtils';
import { getCrossAppLink } from '../../../../../common/UrlUtils';
import DateUtils from '../../../../../common/DateUtils';

const { OUTPUT_HTML, POLL_PREVIEW_PDF } = require('../../../../../shared/engage/engage-report-enums')()
  .GenerateReportConstants;

function GeneratedReportsCompleted(props) {
  const { name, timestamp, jobId, dataType, ToastrStore, reportType } = props;
  const showPopupButton = dataType.includes(OUTPUT_HTML) && reportType !== POLL_PREVIEW_PDF;

  function downloadReport(e) {
    e.preventDefault();
    PreviewUtils.downloadBatchedReport(jobId, null, ToastrStore);
  }

  function getDisplayUrl(isPDF) {
    return getCrossAppLink(`/gmv-backend-api/v2/reports/common/download/${jobId}${isPDF ? '?format=PDF' : ''}`);
  }

  return (
    <React.Fragment>
      {dataType.includes(OUTPUT_HTML) ? (
        <a
          className='c-report-list__item'
          href={getDisplayUrl(true)}
          target='_blank'
          rel='noopener noreferrer'
          title='Download PDF report'
        >
          <span className='c-report-list__state'>
            <SvgIcon icon='check' />
          </span>
          <h4 className='c-report-list__title'>{name}</h4>
          <div className='c-report-list__meta'>{DateUtils.getDateFullTimeString(timestamp)}</div>
          <SvgIcon icon='arrow-to-bottom' />
          {showPopupButton ? (
            <span
              role='contentinfo'
              className='c-report-list__additional-action'
              onClick={(e) => {
                e.preventDefault();
                window.open(getDisplayUrl(), '_blank');
              }}
              title='Open HTML report in a new window'
            >
              <SvgIcon icon='external-link' />
            </span>
          ) : null}
        </a>
      ) : (
        <a className='c-report-list__item' href='.' onClick={downloadReport}>
          <span className='c-report-list__state'>
            <SvgIcon icon='check' />
          </span>
          <h4 className='c-report-list__title'>{name}</h4>
          <div className='c-report-list__meta'>{DateUtils.getDateFullTimeString(timestamp)}</div>
          <SvgIcon icon='arrow-to-bottom' />
        </a>
      )}
    </React.Fragment>
  );
}

GeneratedReportsCompleted.propTypes = {
  name: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  jobId: PropTypes.string.isRequired,
  dataType: PropTypes.array.isRequired,
  ToastrStore: PropTypes.object.isRequired,
};

export default inject((root) => ({
  ToastrStore: root.RootStore.toastrStore,
}))(observer(GeneratedReportsCompleted));
