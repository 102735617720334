import React, { useEffect, useContext, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
// eslint-disable-next-line import/no-cycle
import { PollReportContext } from '../PollsReport';
import QuestionFooter from '../../QuestionFooter';
import QuestionHeader from '../../QuestionHeader';
import SvgIcon from '../../../../../common/components/SvgIcon';
import PreviewUtils from '../../../../../common/PreviewUtils';
import agent from '../../../../../common/agent/ReportsAgent';
import { getSentiment } from '../PollReportUtils';

function FeedbackPreview(props) {
  const { question, PollReportStore, ToastrStore, GeneralStore, fromDate, toDate, onOpenFeedbackModal } = props;
  const { questionId, countAnswered, countFeedback, sentiment, skipSeen, questionConfiguration } = question;
  const { sentimentPositive, sentimentMixed, sentimentNegative, sentimentNeutral, uncategorisedPercent } = getSentiment(
    sentiment,
    countFeedback,
  );

  const pollReportContext = useContext(PollReportContext);
  const containerReference = useRef();

  useEffect(() => {
    if (pollReportContext)
      pollReportContext.addReference({ id: questionId, containerReference: containerReference.current });
  }, [pollReportContext, questionId]);

  function handlePreview() {
    PollReportStore.feedbackModalActivatorQuestion = question;
    PollReportStore.feedbackModalOpen = true;
    onOpenFeedbackModal();
  }

  function handleDownload() {
    PreviewUtils.excelDownload(
      agent,
      PollReportStore.projectId,
      PollReportStore.pollId,
      questionId,
      ToastrStore,
      fromDate,
      toDate,
      PollReportStore.segmentChoiceId,
      PollReportStore.demographicChoiceId,
    );
  }

  return (
    <div ref={containerReference} className='c-poll-preview__item'>
      <div className='c-question c-question--feedback'>
        <QuestionHeader question={question} />

        <div className='c-question__content'>
          <div className='c-question-feedback'>
            {!questionConfiguration.collectEmailOnly && (
              <div className='c-sentiment-stats'>
                <h3 className='c-sentiment-stats__label'>
                  {'Feedback sentiment '}
                  {!GeneralStore.isSnapshotting && (
                    <span className='o-tooltip o-tooltip--bottom-center'>
                      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                      <span className='o-tooltip__action' tabIndex='0'>
                        <SvgIcon icon='info-circle' />
                      </span>
                      <span className='o-tooltip__description' role='tooltip'>
                        Sentiment is calculated using machine learning and therefore 100% accuracy cannot be guaranteed
                      </span>
                    </span>
                  )}
                </h3>

                <ul className='c-sentiment-stats__items'>
                  <li>
                    <SvgIcon icon='grin' />
                    <strong>{` ${sentimentPositive}% `}</strong>
                    positive
                  </li>
                  <li>
                    <SvgIcon icon='meh' />
                    <strong>{` ${sentimentMixed}% `}</strong>
                    mixed
                  </li>
                  <li>
                    <SvgIcon icon='frown' />
                    <strong>{` ${sentimentNegative}% `}</strong>
                    negative
                  </li>
                  <li>
                    <SvgIcon icon='meh-blank' />
                    <strong>{` ${sentimentNeutral}% `}</strong>
                    neutral
                  </li>
                  {uncategorisedPercent > 0 && (
                    <li>
                      <SvgIcon icon='archive' />
                      <strong>{` ${uncategorisedPercent}% uncategorised `}</strong>
                    </li>
                  )}
                </ul>
              </div>
            )}
            {!GeneralStore.isSnapshotting && (
              <Fragment>
                {!questionConfiguration.collectEmailOnly && (
                  <button className='o-button o-button--s' type='button' onClick={handlePreview}>
                    <span className='o-label'>Preview</span>
                  </button>
                )}
                <button className='o-button o-button--s o-button--primary' type='button' onClick={handleDownload}>
                  <span className='o-label'>Download spreadsheet</span>
                </button>
              </Fragment>
            )}
          </div>
        </div>

        <QuestionFooter answered={countAnswered} skipped={skipSeen.skipCount} viewed={skipSeen.seenCount} />
      </div>
    </div>
  );
}

FeedbackPreview.propTypes = {
  ToastrStore: PropTypes.object.isRequired,
  PollReportStore: PropTypes.object.isRequired,
  GeneralStore: PropTypes.object.isRequired,
  question: PropTypes.shape({
    questionId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    countAnswered: PropTypes.number,
    questionRespondentsCount: PropTypes.number,
    communityAgreement: PropTypes.bool,
    sentimentPositive: PropTypes.number,
    sentimentNegative: PropTypes.number,
    sentimentNeutral: PropTypes.number,
    sentimentMixed: PropTypes.number,
    skipSeen: PropTypes.shape({
      seenCount: PropTypes.number,
      skipCount: PropTypes.number,
    }),
    collectEmailOnly: PropTypes.bool,
  }).isRequired,
  fromDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  toDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onOpenFeedbackModal: PropTypes.func,
};

export default inject((root) => ({
  ToastrStore: root.RootStore.toastrStore,
  PollReportStore: root.RootStore.pollsReportStore,
  GeneralStore: root.RootStore.generalStore,
}))(observer(FeedbackPreview));
