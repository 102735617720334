import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import RadioGroupInputField from '../../../../common/components/form-elements/radioGroupInputField/RadioGroupInputField';
import {
  SPREADSHEET_REPORT_DATA_GROUP,
  PROJECT_OVERALL_EXCEL,
  OUTPUT_EXCEL,
  RAW_DATA_ZIP,
  OUTPUT_ZIP,
} from './ReportEnums';
import SegmentationListItem from './SegmentationListItem';
import PollsListItem from './PollsListItem';
import DemographicsListItem from './DemographicsListItem';
import GenerateReportSubmitButtons from './GenerateReportSubmitButtons';
import ReportUtils from './ReportUtils';

function SpreadsheetGenerate(props) {
  const {
    spreadsheetFormData,
    segmentationQuestions,
    polls,
    onSetSpreadsheetReportFormValues,
    onGenerateReport,
    buttonLoading,
    fromDate,
    toDate,
  } = props;

  const [formValues, setFormValues] = useState({});
  const [initValuesSet, setInitValuesSet] = useState(false);
  const [segmentationFilterQuestions, setSegmentationFilterQuestions] = useState([]);
  const [segmentationFilterQuestionChoices, setSegmentationFilterQuestionChoices] = useState([]);

  const prepareSegmentationQuestionChoices = useCallback((questions, questionId) => {
    const preparedChoices = ReportUtils.prepareSegmentationQuestionChoices(questions, questionId);
    setSegmentationFilterQuestionChoices(preparedChoices);

    return preparedChoices;
  }, []);

  useEffect(() => {
    if (!initValuesSet && segmentationQuestions) {
      const preparedSegmentationQuestions = segmentationQuestions.map((item) => {
        const label = item.questionText ? item.questionText : 'No question title';
        return [item._id, label];
      });
      setSegmentationFilterQuestions(preparedSegmentationQuestions);

      const selectedQuestion = spreadsheetFormData?.segmentationFilterQuestion || segmentationQuestions[0]?._id;
      const selectedSegmentationQuestionChoices = prepareSegmentationQuestionChoices(
        segmentationQuestions,
        selectedQuestion,
      );

      let selectedChoice = [];
      if (selectedSegmentationQuestionChoices?.length > 0)
        selectedChoice =
          spreadsheetFormData?.segmentationFilterQuestionChoice || selectedSegmentationQuestionChoices[0][0];

      if (spreadsheetFormData) {
        if (Object.keys(spreadsheetFormData).length > 0) {
          setFormValues({
            ...spreadsheetFormData,
            segmentationFilterQuestion: selectedQuestion,
            segmentationFilterQuestionChoice: selectedChoice,
          });
        }
      } else {
        setFormValues({
          segmentationFilterQuestion: selectedQuestion,
          segmentationFilterQuestionChoice: selectedChoice,
        });
      }

      setInitValuesSet(true);
    }
  }, [prepareSegmentationQuestionChoices, segmentationQuestions, initValuesSet, spreadsheetFormData]);

  function onSubmit(values) {
    const secondaryOutputType = values.dataGroup === RAW_DATA_ZIP ? OUTPUT_ZIP : null;
    const payload = ReportUtils.createPayload(values, OUTPUT_EXCEL, fromDate, toDate, polls, secondaryOutputType);
    onGenerateReport(payload);
  }

  function onSegmentationFilterQuestionChange(questionId, values) {
    const selectedSegmentationQuestionChoices = prepareSegmentationQuestionChoices(segmentationQuestions, questionId);

    setFormValues({
      ...values,
      segmentationFilterQuestion: questionId,
      segmentationFilterQuestionChoice: selectedSegmentationQuestionChoices[0][0],
    });
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={formValues}
      render={({ values, handleSubmit }) => {
        onSetSpreadsheetReportFormValues(values);
        return (
          <form className='c-fields l-form'>
            <RadioGroupInputField
              fieldName='dataGroup'
              id='dataGroup'
              choices={SPREADSHEET_REPORT_DATA_GROUP}
              label='Choose report'
              horizontalLayout={false}
              value={values.dataGroup}
            />
            {values.dataGroup === PROJECT_OVERALL_EXCEL ? (
              <React.Fragment>
                <div className='c-field l-form__item'>
                  <label className='c-field__label' htmlFor='input-report-filter'>
                    Report filter
                  </label>
                  <div className='c-field__group'>
                    <ul className='c-list-settings'>
                      <SegmentationListItem
                        segmentationFilterValue={values.segmentationFilter}
                        segmentationFilterQuestions={segmentationFilterQuestions}
                        segmentationFilterQuestionChoices={segmentationFilterQuestionChoices}
                        onSegmentationFilterQuestionChange={(questionId) =>
                          onSegmentationFilterQuestionChange(questionId, values)
                        }
                      />
                    </ul>
                  </div>
                </div>

                <div className='c-field l-form__item'>
                  <label className='c-field__label' htmlFor='input-report-filter'>
                    What goes into the report?
                  </label>
                  <div className='c-field__group'>
                    <ul className='c-list-settings'>
                      <PollsListItem
                        pollsDataValue={values.pollsData}
                        pollsDataOptionValue={values.pollsDataOption}
                        polls={polls}
                      />
                      <DemographicsListItem demographicsDataValue={values.demographicsData} />
                    </ul>
                  </div>
                </div>
              </React.Fragment>
            ) : null}
            <GenerateReportSubmitButtons
              formValues={values}
              outputType={OUTPUT_EXCEL}
              buttonLoading={buttonLoading}
              onGenerateReport={handleSubmit}
              pollsList={polls}
            />
          </form>
        );
      }}
    />
  );
}

SpreadsheetGenerate.propTypes = {
  spreadsheetFormData: PropTypes.object,
  segmentationQuestions: PropTypes.array,
  polls: PropTypes.array.isRequired,
  onSetSpreadsheetReportFormValues: PropTypes.func.isRequired,
  onGenerateReport: PropTypes.func.isRequired,
  buttonLoading: PropTypes.bool.isRequired,
  fromDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  toDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default SpreadsheetGenerate;
