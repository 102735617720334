import React from 'react';
import { Field } from 'react-final-form';

import RadioInput from '../../../../common/components/form-elements/radioGroupInputField/RadioInput';
import SimpleCheckboxInput from '../../../../common/components/form-elements/checkboxInputField/SimpleCheckboxInput';
import { getPollsDataOptionChoices, POLL_DATA_OPTIONS } from './ReportEnums';

export default function PollsListItem(props) {
  const { pollsDataValue, pollsDataOptionValue, polls } = props;
  return (
    <li className={`c-list-settings__item ${pollsDataValue ? 'c-list-settings__item--highlight' : ''}`}>
      <Field
        component={SimpleCheckboxInput}
        name='pollsData'
        classModifier='c-list-settings__name'
        label='Surveys'
        id='pollsData'
        type='checkbox'
        defaultValue={true}
      />
      <div className='c-list-settings__options'>
        <Field
          type='radio'
          name='pollsDataOption'
          component={RadioInput}
          choices={getPollsDataOptionChoices()}
          classModifier='o-radio--inline o-radio--s'
          disabled={!pollsDataValue}
          value={pollsDataOptionValue}
          defaultValue={POLL_DATA_OPTIONS.ALL}
        />
      </div>
      <div
        className='c-list-settings__details'
        style={{
          display: `${pollsDataOptionValue === POLL_DATA_OPTIONS.CUSTOM && pollsDataValue ? 'block' : 'none'}`,
        }}
      >
        <div className='c-fields l-form'>
          <div className='c-field l-form__item c-field--label-hidden'>
            <label className='c-field__label'>Surveys</label>
            <div className='c-field__group'>
              {polls.map((poll) => (
                <Field
                  component={SimpleCheckboxInput}
                  name={`pollsDataPoll-${poll.id}`}
                  label={poll.name}
                  id={poll.id}
                  type='checkbox'
                  key={poll.id}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
