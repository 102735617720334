import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { inject, observer } from 'mobx-react';
import { Form } from 'react-final-form';

import SwitchableHeader from '../../../common/components/switchableHeader/SwitchableHeader';
import EngageTextInputField from '../../../common/components/form-elements/textInputField/TextInputField';
import SwitchableButtonBar from '../../../common/components/switchableButtonBar/SwitchableButtonBar';
import SlimFileUpload from '../../../common/components/form-elements/fileUpload/SlimFileUpload';
import { IMAGE_FORMATS } from '../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import EngageMarkdownInputField, {
  MARKDOWN_MID,
} from '../../../common/components/form-elements/markdownInputField/MarkdownInputField';
import displayConfirmationPrompt from '../../../common/PromptUtil';
import { getFormFieldClass, getFormFieldTranslationTooltip } from '../../../common/TranslationUtils';
import ImageUtils from '../../../common/ImageUtils';

/**
 * Influenced desision form
 */

function InfluencedDecisionFn(props) {
  const { item, Store, ToastrStore } = props;
  const imageId = item.data.resource;

  const toggleOpened = () => {
    Store.toggleOpened([item.data._id]);
  };

  const remove = (event) => {
    event.preventDefault();

    const res = displayConfirmationPrompt(null, null, false, ToastrStore);

    if (res === 1) Store.remove(item.data._id);
  };

  const onSubmit = (values) => {
    // console.log('Submit', values);
    Store.update(item.data._id, {
      resource: values.resource,
      title: values.title,
      description: values.description ? values.description : '',
    });
  };

  const uploadImage = (file, progressHandler) => {
    return Store.uploadResource(item.data._id, file, progressHandler);
  };

  const removeImage = (event) => {
    event.preventDefault();
    return Store.removeResource(item.data._id);
  };

  return (
    <div className={`c-switchable ${props.switchableModifier} ${item.formState.isOpened ? 'is-opened' : 'is-closed'}`}>
      <SwitchableHeader
        id={item.data._id}
        label='Decision'
        name={item.data.title}
        icon='users'
        toggleOpened={toggleOpened}
      />
      <div className='c-switchable__content'>
        <Form
          onSubmit={onSubmit}
          keepDirtyOnReinitialize={true}
          initialValues={item.data}
          render={({ values, form, handleSubmit, submitting, pristine }) => {
            return (
              <form className='c-fields l-form' onSubmit={(event) => event.preventDefault()}>
                <EngageTextInputField
                  id={`input-influenced-name-${item.data._id}`}
                  placeholder='Name'
                  label='Name'
                  maxLength='80'
                  fieldDescription=''
                  fieldName='title'
                  isRequired={true}
                  fieldClass={getFormFieldClass(item.defaultData.title, null, 'l-form__item')}
                  tooltipInLabel={getFormFieldTranslationTooltip(item.defaultData.title)}
                />

                <EngageMarkdownInputField
                  id={`input-influenced-description-${item.data._id}`}
                  placeholder='Make it short, sweet and under 300 characters.'
                  label='Description'
                  maxLength='300'
                  fieldDescription=''
                  fieldName='description'
                  isRequired={false}
                  fieldClass={getFormFieldClass(item.defaultData.description, null, 'l-form__item')}
                  tooltipInLabel={getFormFieldTranslationTooltip(item.defaultData.description)}
                  editorConfig={MARKDOWN_MID}
                />

                <SlimFileUpload
                  imageFormat={IMAGE_FORMATS.PANEL}
                  imageId={imageId}
                  defaultImageId={item.defaultData.resource}
                  id={`${item.data._id}`}
                  handleUpload={uploadImage}
                  label='Image'
                  removeImage={removeImage}
                  classModifier={getFormFieldClass(null, item.defaultData.resource)}
                  tooltipInLabel={getFormFieldTranslationTooltip(
                    null,
                    ImageUtils.imageUrl(item.defaultData.resource, IMAGE_FORMATS.PANEL.imageType),
                  )}
                />

                <SwitchableButtonBar updateAction={handleSubmit} removeAction={remove} isNew={!item.data._id} />
              </form>
            );
          }}
        />
      </div>
    </div>
  );
}
const InfluencedDecision = inject((root) => ({
  Store: root.RootStore.influencedStore,
  ToastrStore: root.RootStore.toastrStore,
}))(observer(InfluencedDecisionFn));

/**
 * Sortable item wrapper
 */
const SortableInfluencedDecision = SortableElement((props) => {
  return <InfluencedDecision {...props} />;
});

export default SortableInfluencedDecision;
