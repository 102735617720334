import { reaction, action, observable, computed, makeObservable } from 'mobx';

import AgentPoll from '../common/agent';

const Agent = AgentPoll.NewsAgent;

const ITEM_LABEL = 'News';
const CREATE_SUCCESS = `New ${ITEM_LABEL} added`;
const CREATE_ERROR = `Error creating ${ITEM_LABEL}`;
const UPDATE_SUCCESS = 'Saved';
const UPDATE_ERROR = `Error saving ${ITEM_LABEL}`;
const DELETE_SUCCESS = 'News removed';
const DELETE_ERROR = `Error deleting ${ITEM_LABEL}`;
const RESOURCE_DELETE_ERROR = 'Error deleting resource';
const REORDER_ERROR = 'Error reordering news';

export default class NewsStore {
  _loading = false;

  _initialized = false;

  _error = null;

  _items = [];

  constructor(rootStore, projectStore) {
    makeObservable(this, {
      _items: observable,
      _loading: observable,
      _initialized: observable,
      _error: observable,

      resetFlags: action,

      news: computed,
      isLoading: computed,
      isInitialized: computed,
      error: computed,

      loadData: action,
    });

    this.rootStore = rootStore;
    this.projectStore = projectStore;
    reaction(
      () => this.projectStore.isInitialized,
      () =>
        this.loadData(
          this.projectStore.project.developerAnnoucement,
          this.projectStore.projectDefault.developerAnnoucement,
        ),
    );
  }

  get isLoading() {
    return this._loading;
  }

  get isInitialized() {
    return this._initialized;
  }

  get error() {
    return this._error;
  }

  get news() {
    return this._items;
  }

  /**
   * Load and prepare data already loaded in ProjectStore
   * @param items
   */
  loadData(news, newsDefault) {
    this._items = news;

    const tmpNews = [];
    news.map((item) => {
      const defaultNews = newsDefault.find((newsDef) => newsDef._id === item._id);
      tmpNews.push({
        data: item,
        defaultData: defaultNews,
      });
      return item;
    });
    this._items = tmpNews;

    this._initialized = true;
  }

  /**
   * Create EMPTY news POST /project/:id/news
   * @param data
   */
  // eslint-disable-next-line
  async create(data) {
    this._loading = false;
    this._error = null;

    try {
      const res = await Agent.create(this.projectStore.project._id, data);
      this.rootStore.toastrStore.success(CREATE_SUCCESS);
      await this.projectStore.refresh();
      return res.data._id;
    } catch (err) {
      this._error = err;
      this.rootStore.toastrStore.error(CREATE_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  async reorder(data) {
    this._loading = false;
    this._error = null;

    try {
      await Agent.reorder(
        this.projectStore.project._id,
        data.map((item) => item.data._id),
      );
      await this.projectStore.refresh();
    } catch (err) {
      this._error = err;
      this.rootStore.toastrStore.error(REORDER_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  // Update NEWS PATCH newsId
  update(itemId, data) {
    this._loading = true;
    this._error = null;
    return Agent.update(this.projectStore.project._id, itemId, data)
      .then(() => {
        this.rootStore.toastrStore.success(UPDATE_SUCCESS);
        this.refresh();
      })
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(UPDATE_ERROR, null, err);
      })
      .finally(this.resetFlags);
  }

  /**
   * Remove news DELETE /newsId
   * @param itemId
   * @returns {Promise<T>}
   */
  remove = (itemId) => {
    this._loading = true;
    this._error = null;
    return Agent.remove(this.projectStore.project._id, itemId)
      .then(() => {
        this.rootStore.toastrStore.success(DELETE_SUCCESS);
        return this.projectStore.refresh();
      })
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(DELETE_ERROR, null, err);
      })
      .finally(this.resetFlags);
  };

  /**
   * Upload resource image, progress
   * @param panelId
   * @param file
   * @param progress callback
   */
  uploadResource(newsId, file, progress) {
    this._loading = true;
    this._error = null;
    return Agent.uploadResource(this.projectStore.project._id, newsId, file, progress)
      .then(
        action((res) => {
          const news = this._items.find((item) => item.data._id === newsId);
          news.data.resource = res.data;
        }),
      )
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   *
   * @param newsId
   * @returns {Promise<T>}
   */
  removeResource = (newsId) => {
    this._loading = true;
    this._error = null;
    return Agent.removeResource(this.projectStore.project._id, newsId)
      .then(
        action(() => {
          const news = this._items.find((item) => item.data._id === newsId);
          news.data.resource = null;
        }),
      )
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(RESOURCE_DELETE_ERROR, null, err);
      })
      .finally(this.resetFlags);
  };

  refresh = () => {
    this.projectStore.refresh();
  };

  resetFlags = () => {
    this._loading = false;
  };
}
