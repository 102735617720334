/* eslint-disable no-shadow */
/**
 * #### PERIOD constants
 */
export const PERIOD_TYPE_ALL = 'ALL';
export const PERIOD_TYPE_30_DAYS = 'LAST_30_DAYS';
export const PERIOD_TYPE_3_MONTHS = 'LAST_3_MONTHS';
export const PERIOD_TYPE_CUSTOM = 'CUSTOM';

// ### ON SERVER
// const EngageReportTimeRange = {
//   ALL_TIME: 'allTime',
//   LAST_30_DAYS: 'last30days',
//   LAST_3_MONTHS: 'last3months',
//   CUSTOM: 'custom'
// };
//
export const REPORT_PERIOD_TYPES = [
  [PERIOD_TYPE_ALL, 'All time', 'allTime'],
  [PERIOD_TYPE_30_DAYS, 'Last 30 days', 'last30days'],
  [PERIOD_TYPE_3_MONTHS, 'Last 3 months', 'last3months'],
  // [ PERIOD_TYPE_CUSTOM, 'Custom', 'custom' ],
];
export function decodePeriodType(type) {
  const item = REPORT_PERIOD_TYPES.find((item) => item[0] === type);
  return item ? item[2] : '';
}

/**
 * Report STATUS
 */
export const REPORT_STATUS_RUNNING = 'running';

/**
 * #### REPORT TYPE constants
 */

export const REPORT_TYPE_BASIC = 'REPORT_TYPE_BASIC';
export const REPORT_TYPE_ADVANCED = 'REPORT_TYPE_ADVANCED';
export const REPORT_TYPE_CUSTOM = 'REPORT_TYPE_CUSTOM';

// ### ON SERVER
// const EngageReportType = {
//   BASIC: 'basic',
//   ADVANCED: 'advanced',
//   CUSTOM: 'custom'
// };
export const REPORT_TYPES = [
  {
    value: REPORT_TYPE_BASIC,
    label: 'Basic',
    small: '(Overview of project activity, users and public surveys)',
    serverEnum: 'basic',
  },
  {
    value: REPORT_TYPE_ADVANCED,
    label: 'Advanced',
    small: '(User details, public survey breakdown, all feedback)',
    serverEnum: 'advanced',
  },
  {
    value: REPORT_TYPE_CUSTOM,
    label: 'Custom',
    small: '(Specify what goes into a report)',
    serverEnum: 'custom',
  },
];
export const REPORT_TYPES_SHORT = [
  [REPORT_TYPE_BASIC, 'Basic'],
  [REPORT_TYPE_ADVANCED, 'Advanced'],
  [REPORT_TYPE_CUSTOM, 'Custom'],
];
export function decodeReportType(type) {
  const item = REPORT_TYPES.find((item) => item.value === type);
  return item ? item.serverEnum : '';
}

/**
 * #### FEEDBACK TYPE constants
 */

export const FEEDBACK_PROBLEMS = 'FEEDBACK_PROBLEMS';
export const FEEDBACK_REQUESTS = 'FEEDBACK_REQUESTS';
export const FEEDBACK_OTHER = 'FEEDBACK_OTHER';
export const FEEDBACK_HIGHLIGHTS = 'FEEDBACK_HIGHLIGHTS';
// ### ON SERVER
// const EngageFeedbackTypeFilter = {
//   ENGAGE_FEEDBACK_ALL: 'all',
//   ENGAGE_FEEDBACK_HIGHLIGHTS: 'highlights',
//   ENGAGE_FEEDBACK_PROBLEMS: 'problems',
//   ENGAGE_FEEDBACK_REQUESTS: 'requests',
//   ENGAGE_FEEDBACK_OTHER: 'other',
// };
export const FEEDBACK_TYPES = [
  // [FEEDBACK_PROBLEMS, 'Problems', 'problems'],
  // [FEEDBACK_REQUESTS, 'Requests', 'requests'],
  // [FEEDBACK_OTHER, 'Other', 'other'],
  [FEEDBACK_HIGHLIGHTS, 'Highlighted only', 'highlights'],
];
export function decodeFeedbackType(type) {
  const item = FEEDBACK_TYPES.find((item) => item[0] === type);
  return item ? item[2] : '';
}
export function decodeFeedbackTypeArray(types) {
  return types.map((type) => decodeFeedbackType(type));
}

/**
 * NEW REPORTS CONSTANTS
 */

export const {
  PROJECT_OVERALL_PDF,
  PROJECT_OVERALL_EXCEL,
  FEEDBACK_EXCEL,
  RAW_DATA_ZIP,
  POLL_REPORT_PDF,
  OUTPUT_EXCEL,
  OUTPUT_PDF,
  OUTPUT_ZIP,
  OUTPUT_HTML,
} =
  // eslint-disable-next-line global-require
  require('../../../../shared/engage/engage-report-enums')().GenerateReportConstants;

/**
 * SPREADSHEET report data group constants
 */
export const SPREADSHEET_REPORT_DATA_GROUP = [
  [FEEDBACK_EXCEL, 'Feedback data'],
  [RAW_DATA_ZIP, 'Raw data'],
  [PROJECT_OVERALL_EXCEL, 'Survey data'],
];

export const OVERVIEW_DATA_OPTIONS = { BASIC: 'BASIC', ADVANCED: 'ADVANCED' };

export const getOverviewDataOptionChoices = () => [
  { id: OVERVIEW_DATA_OPTIONS.BASIC, text: 'Basic' },
  { id: OVERVIEW_DATA_OPTIONS.ADVANCED, text: 'Advanced' },
];

export const POLL_DATA_OPTIONS = {
  ALL: 'all',
  CUSTOM: 'custom',
};

export const getPollsDataOptionChoices = () => [
  { id: POLL_DATA_OPTIONS.ALL, text: 'All' },
  { id: POLL_DATA_OPTIONS.CUSTOM, text: 'Custom' },
];

export const REPORT_FORMAT_TYPE_CHOICES = [
  {
    id: OUTPUT_PDF,
    text: 'PDF',
    icon: 'file-pdf',
    description: 'Rich-media PDF report which can be further customised and broken down into elements',
  },
  {
    id: OUTPUT_EXCEL,
    text: 'Spreadsheet',
    icon: 'file-spreadsheet',
    description: 'Raw data report in a spreadsheet format for you to further customise the output',
  },
];

/**
 * Report name character limit
 */
export const REPORT_NAME_CHAR_LIMIT = '100';
