import React from 'react';

import SvgIcon from '../SvgIcon';

export default function SelectItem(props) {
  const { classModifier = 'c-field--label-hidden', optionLabel, options, onChange, optionValue } = props;
  return (
    <div className={`c-field ${classModifier}`}>
      <label className='c-field__label' htmlFor='sort-by'>
        {optionLabel}
      </label>
      <div className='c-field__group'>
        <div className='o-select'>
          <SvgIcon icon='angle-down' />
          <select id='sort-by' onChange={onChange} value={optionValue}>
            {options.map((item) => {
              return (
                <option key={item.value} value={item.value}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </div>
  );
}
