import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import SvgIcon from '../SvgIcon';
import RadioInput from '../form-elements/radioGroupInputField/RadioInput';
import { perc } from '../../../containers/project/reports/polls-report/PollReportUtils';

function SelectedFilter(props) {
  const { fieldName, onClear, filterData, formValues, displayVoteNumbers } = props;

  function prepareChoices() {
    if (!filterData?.question?.choices) return [];

    let questionAnsweredCount = 0;
    filterData.question.choices.forEach((choice) => (questionAnsweredCount += choice.count));

    return filterData.question.choices.map((choice) => {
      const desc = displayVoteNumbers ? `  (${choice.count})` : `${perc(choice.count, questionAnsweredCount)}%`;

      return {
        id: choice._id,
        text: choice.choice,
        description: desc,
      };
    });
  }

  return (
    <div className='c-filter-set__group' data-testid={`${fieldName}-selectedFilter-wrapper`}>
      {filterData && (
        <div className='c-filter-set__info'>
          <div className='c-filter-set__type'>
            <SvgIcon icon={filterData.group.icon} dataTestId={filterData.group.id} />
          </div>
          <h2 className='c-filter-set__poll'>{filterData.group.label}</h2>
          <SvgIcon icon='angle-right' />
          <h3 className='c-filter-set__question'>{filterData.question.questionText}</h3>
        </div>
      )}
      <div className='c-filter-set__choices'>
        <Field
          type='radio'
          name={fieldName}
          component={RadioInput}
          choices={prepareChoices()}
          value={formValues[fieldName]}
          classModifier='o-radio--button o-radio--s o-radio--inline'
          isStrongDescription={true}
        />
      </div>
      <button className='o-button c-filter-set__clear' type='button' onClick={onClear}>
        <SvgIcon icon='times' />
        <span className='o-label'>Clear</span>
      </button>
    </div>
  );
}

SelectedFilter.propTypes = {
  fieldName: PropTypes.string.isRequired,
  filterData: PropTypes.object,
  onClear: PropTypes.func.isRequired,
  formValues: PropTypes.object,
  displayVoteNumbers: PropTypes.bool,
};

export default SelectedFilter;
