import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import SvgIcon from '../SvgIcon';

function TranslationInstructions(props) {
  const { classModifier, canClose, GeneralStore } = props;

  function closeHandler() {
    GeneralStore.isTranslationInstructionVisible = false;
  }

  return (
    <div className={`c-message is-warning-strong ${classModifier}`} data-testid='translation-instruction-container'>
      <h3 className='c-message__title'>How to translate your content?</h3>
      <p className='c-message__description'>
        Please note that if you don&#180;t translate your original content (like text or images), values from original
        language will be used instead. Before translating, you can always check for values in the original language by
        hovering the globe icon
        <span className='no-break'>
          (
          <SvgIcon icon='globe' classModifier='o-icon--small' />)
        </span>{' '}
        next to label.
      </p>
      {canClose && (
        <button className='c-message__close o-button o-button--naked' onClick={closeHandler}>
          <SvgIcon icon='times' />
        </button>
      )}
    </div>
  );
}

TranslationInstructions.propTypes = {
  classModifier: PropTypes.string,
  canClose: PropTypes.bool,
};

export default inject((root) => ({
  GeneralStore: root.RootStore.generalStore,
}))(observer(TranslationInstructions));
