import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import ActionBar from '../../../../common/components/actionBar/ActionBar';
import FeedbackNavFilter from './FeedbackNavFilter';
import FeedbackItem from './FeedbackItem';
import RangePickerInputField from '../../../../common/components/form-elements/datePicker/SimpleRangePickerInputField';

const SentimentTypes = require('../../../../shared/engage').SentimentEnums().SentimentType;

function Feedback(props) {
  const { Store, match } = props;
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [feedbackItems, setFeedbackItems] = useState(Store.feedbackItems);
  const [sentimentFilter, setSentimentFilter] = useState(SentimentTypes.ALL);
  const [sentimentScore, setSentimentScore] = useState();

  const projectId = match.params.id;

  useEffect(() => {
    if (Store && projectId) Store.load(projectId);
  }, [Store, projectId]);

  useEffect(() => {
    // Each time data gets loaded
    if (Store.feedbackItems) {
      // Feed data from store
      setFeedbackItems(Store.feedbackItems);
      // Reset sentiment filters
      setSentimentFilter(SentimentTypes.ALL);

      // Calculate percentages for sentiment filters
      const total = Store.feedbackItems.length;
      let positive = 0;
      let negative = 0;
      let neutral = 0;
      let mixed = 0;
      let uncategorised = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const feedback of Store.feedbackItems) {
        if (feedback.sentimentAnalysis) {
          switch (feedback.sentimentAnalysis.sentiment) {
            case SentimentTypes.POSITIVE:
              positive++;
              break;
            case SentimentTypes.NEGATIVE:
              negative++;
              break;
            case SentimentTypes.NEUTRAL:
              neutral++;
              break;
            case SentimentTypes.MIXED:
              mixed++;
              break;
            default:
              break;
          }
        } else {
          uncategorised++;
        }
      }

      if (positive > 0 || negative > 0 || neutral > 0 || mixed > 0) {
        const score = {};
        score.sentimentPositivePercent = Math.round((positive / total) * 100);
        score.sentimentNegativePercent = Math.round((negative / total) * 100);
        score.sentimentNeutralPercent = Math.round((neutral / total) * 100);
        score.sentimentMixedPercent = Math.round((mixed / total) * 100);
        score.uncategorisedPercent = Math.round((uncategorised / total) * 100);
        setSentimentScore(score);
      } else {
        setSentimentScore();
      }
    }
  }, [Store.feedbackItems]);

  function rangeChangeHandler(rangeFrom, rangeTo) {
    setFromDate(rangeFrom);
    setToDate(rangeTo);

    Store.load(projectId, rangeFrom, rangeTo);
  }

  function onNavFilterChange(selectedFilter) {
    Store.filter = selectedFilter;
    Store.load(projectId, fromDate, toDate);
  }

  function onSentimentFilterChange(selectedFilter) {
    setSentimentFilter(selectedFilter);

    let filteredItems;
    switch (selectedFilter) {
      case SentimentTypes.ALL:
        filteredItems = Store.feedbackItems;
        break;
      case SentimentTypes.POSITIVE:
        filteredItems = Store.feedbackItems.filter(
          (feedback) => feedback.sentimentAnalysis && feedback.sentimentAnalysis.sentiment === SentimentTypes.POSITIVE,
        );
        break;
      case SentimentTypes.NEGATIVE:
        filteredItems = Store.feedbackItems.filter(
          (feedback) => feedback.sentimentAnalysis && feedback.sentimentAnalysis.sentiment === SentimentTypes.NEGATIVE,
        );
        break;
      case SentimentTypes.MIXED:
        filteredItems = Store.feedbackItems.filter(
          (feedback) => feedback.sentimentAnalysis && feedback.sentimentAnalysis.sentiment === SentimentTypes.MIXED,
        );
        break;
      case SentimentTypes.NEUTRAL:
        filteredItems = Store.feedbackItems.filter(
          (feedback) => feedback.sentimentAnalysis && feedback.sentimentAnalysis.sentiment === SentimentTypes.NEUTRAL,
        );
        break;
      case SentimentTypes.UNCATEGORISED:
        filteredItems = Store.feedbackItems.filter((feedback) => !feedback.sentimentAnalysis);
        break;
      default:
        break;
    }

    setFeedbackItems(filteredItems);
  }

  return (
    <div className='l-main'>
      <ActionBar addNew={null} label='Feedback' hasButton={false} />
      <RangePickerInputField
        startDate={fromDate}
        endDate={toDate}
        onRangeChange={rangeChangeHandler}
        fieldClass='l-form__item--l'
        showPredefinedRanges={true}
      />
      <FeedbackNavFilter
        onChange={onNavFilterChange}
        onSentimentChange={onSentimentFilterChange}
        sentimentCurrentFilter={sentimentFilter}
        sentimentScore={sentimentScore}
      />
      {feedbackItems.map((item) => (
        <FeedbackItem key={item._id} feedbackItem={item} />
      ))}
    </div>
  );
}

Feedback.propTypes = {
  Store: PropTypes.object.isRequired,
};

export default inject((root) => ({ Store: root.RootStore.feedbackStore }))(observer(Feedback));
