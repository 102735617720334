import React from 'react';
import PropTypes from 'prop-types';
import QuestionTemplateAction from './QuestionTemplateAction';
import QuestionTemplateHeader from './QuestionTemplateHeader';

function ReorderPreview(props) {
  const { question } = props;
  const sortedChoices = question.choices.slice().sort((a, b) => a.communityAnswer - b.communityAnswer);

  return (
    <div className='c-library__item'>
      <div className='c-question c-question--reorder c-question--library'>
        <QuestionTemplateHeader question={question} />
        <div className='c-question__content'>
          <div className='c-question-reorder'>
            <ol className='c-question-reorder__items'>
              {sortedChoices.map((item, idx) => (
                <li key={idx} className='c-question-reorder__item'>
                  {item.choice}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>
      <QuestionTemplateAction question={question} />
    </div>
  );
}

ReorderPreview.propTypes = {
  question: PropTypes.shape({
    questionText: PropTypes.string.isRequired,
    choices: PropTypes.array.isRequired,
    questionRespondentsCount: PropTypes.number,
  }).isRequired,
};

export default ReorderPreview;
