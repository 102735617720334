import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SvgIcon from '../components/SvgIcon';
import {
  REPORTS,
  REPORTS_DASHBOARD,
  PROJECT,
  routeToLabel,
  REPORTS_POLLS,
  REPORTS_FEEDBACK,
  REPORTS_GENERATE,
  REPORTS_DEMOGRAPHICS,
} from '../constants/RouteConstants';
import GeneratedReports from '../../containers/project/reports/generate/GeneratedReports/GeneratedReports';
import { VIEW } from '../ability';
import { Can } from '../Can';

export default function AsideReports(props) {
  const { match } = props;
  const [isDrawerOpened, setDrawerState] = useState(false);

  // console.log('ASIDE Reports', match);

  function getSelectedClass(item) {
    return item === match.params.route ? 'c-nav-drawer__item is-active' : 'c-nav-drawer__item';
  }

  function getLink(route) {
    return `/${PROJECT}/${match.params.id}/${REPORTS}/${route}`;
  }

  return (
    <div className='l-aside'>
      <div className={`c-nav-drawer c-nav-drawer--icons ${isDrawerOpened ? 'c-nav-drawer-opened' : ''}`}>
        <div className='c-nav-drawer__content'>
          <ul
            className='c-nav-drawer__items'
            onClick={() => {
              setDrawerState(!isDrawerOpened);
            }}
          >
            <Can I={VIEW} a={REPORTS_DASHBOARD}>
              <li className={getSelectedClass(REPORTS_DASHBOARD)} onClick={(e) => e.stopPropagation()}>
                <Link className='c-nav-drawer__link' to={getLink(REPORTS_DASHBOARD)}>
                  <SvgIcon icon='file-chart-line' />
                  {routeToLabel(REPORTS_DASHBOARD)}
                </Link>
              </li>
            </Can>
            <Can I={VIEW} a={REPORTS_POLLS}>
              <li className={getSelectedClass(REPORTS_POLLS)} onClick={(e) => e.stopPropagation()}>
                <Link className='c-nav-drawer__link' to={getLink(REPORTS_POLLS)}>
                  <SvgIcon icon='poll' />
                  {routeToLabel(REPORTS_POLLS)}
                </Link>
              </li>
            </Can>
            <Can I={VIEW} a={REPORTS_DEMOGRAPHICS}>
              <li className={getSelectedClass(REPORTS_DEMOGRAPHICS)} onClick={(e) => e.stopPropagation()}>
                <Link className='c-nav-drawer__link' to={getLink(REPORTS_DEMOGRAPHICS)}>
                  <SvgIcon icon='poll' />
                  {routeToLabel(REPORTS_DEMOGRAPHICS)}
                </Link>
              </li>
            </Can>
            <Can I={VIEW} a={REPORTS_FEEDBACK}>
              <li className={getSelectedClass(REPORTS_FEEDBACK)} onClick={(e) => e.stopPropagation()}>
                <Link className='c-nav-drawer__link' to={getLink(REPORTS_FEEDBACK)}>
                  <SvgIcon icon='envelope' />
                  {routeToLabel(REPORTS_FEEDBACK)}
                </Link>
              </li>
            </Can>
            <Can I={VIEW} a={REPORTS_GENERATE}>
              <li className={getSelectedClass(REPORTS_GENERATE)} onClick={(e) => e.stopPropagation()}>
                <Link className='c-nav-drawer__link' to={getLink(REPORTS_GENERATE)}>
                  <SvgIcon icon='file-spreadsheet' />
                  {routeToLabel(REPORTS_GENERATE)}
                </Link>
              </li>
            </Can>
          </ul>
        </div>
      </div>
      <GeneratedReports projectId={match.params.id} />
    </div>
  );
}
