/* eslint-disable no-param-reassign */
import { v4 as uuidv4 } from 'uuid';
import { QUESTION_CONDITIONAL } from '../shared/engage/engage-question-constants';
import { QUESTION_TYPES, QUESTION_CONDITIONAL_LABELS } from './constants/QuestionConstants';

export const NEW_ID_PREFIX = 'FAKE-';
export const NO_CHOICES_OPTION = ['', 'Please set choice names'];
export const SELECT_OPTION = [null, 'Select...'];
export const NEXT_QUESTION_OPTION = [null, 'Next question'];
export const END_OF_POLL_QUESTION_OPTION = [-1, 'End of survey'];

export const CONDITION_INPUT_TYPE = {
  TEXT: 'TEXT',
  SELECT: 'SELECT',
};

export const CONDITIONAL_LOGICAL_OPERATORS = [
  [QUESTION_CONDITIONAL.$AND, QUESTION_CONDITIONAL_LABELS[QUESTION_CONDITIONAL.$AND]],
  [QUESTION_CONDITIONAL.$OR, QUESTION_CONDITIONAL_LABELS[QUESTION_CONDITIONAL.$OR]],
];

export const CONDITIONAL_EQUALITY = [
  [QUESTION_CONDITIONAL.$IS, QUESTION_CONDITIONAL_LABELS[QUESTION_CONDITIONAL.$IS]],
  [QUESTION_CONDITIONAL.$IS_NOT, QUESTION_CONDITIONAL_LABELS[QUESTION_CONDITIONAL.$IS_NOT]],
];

export const CONDITIONAL_CRITERIA = [
  [QUESTION_CONDITIONAL.$LT, QUESTION_CONDITIONAL_LABELS[QUESTION_CONDITIONAL.$LT]],
  [QUESTION_CONDITIONAL.$LTE, QUESTION_CONDITIONAL_LABELS[QUESTION_CONDITIONAL.$LTE]],
  [QUESTION_CONDITIONAL.$EQ, QUESTION_CONDITIONAL_LABELS[QUESTION_CONDITIONAL.$EQ]],
  [QUESTION_CONDITIONAL.$GT, QUESTION_CONDITIONAL_LABELS[QUESTION_CONDITIONAL.$GT]],
  [QUESTION_CONDITIONAL.$GTE, QUESTION_CONDITIONAL_LABELS[QUESTION_CONDITIONAL.$GTE]],
];

const QUESTION_CHOICE_FIELDS = [
  { fieldName: 'choice1Label', defaultLabel: 'Choice 1 (missing label)' },
  { fieldName: 'choice2Label', defaultLabel: 'Choice 2 (missing label)' },
  { fieldName: 'choice3Label', defaultLabel: 'Choice 3 (missing label)' },
  { fieldName: 'choice4Label', defaultLabel: 'Choice 4 (missing label)' },
  { fieldName: 'choice5Label', defaultLabel: 'Choice 5 (missing label)' },
  { fieldName: 'choice6Label', defaultLabel: 'Choice 6 (missing label)' },
  { fieldName: 'choice7Label', defaultLabel: 'Choice 7 (missing label)' },
  { fieldName: 'choice8Label', defaultLabel: 'Choice 8 (missing label)' },
];

function getConditionalInputType(questionType) {
  let res;
  switch (questionType) {
    case QUESTION_TYPES.TWO_IMAGE_SINGLE_CHOICE:
    case QUESTION_TYPES.THREE_IMAGE_SINGLE_CHOICE:
    case QUESTION_TYPES.FOUR_IMAGE_SINGLE_CHOICE:
    case QUESTION_TYPES.MULTIPLE_IMAGE_MULTIPLE_CHOICE:
    case QUESTION_TYPES.MULTIPLE_IMAGE_OTHER_CHOICE:
      res = CONDITION_INPUT_TYPE.SELECT;
      break;
    case QUESTION_TYPES.SINGLE_SLIDER_CHOICE:
    case QUESTION_TYPES.SLIDER_FEEDBACK:
    case QUESTION_TYPES.RATING:
      res = CONDITION_INPUT_TYPE.TEXT;
      break;
    default:
      break;
  }

  return res;
}

/// //////////////////////////////////////////////
/// //////// FORM ACTION METHODS  ////////////////
/// //////////////////////////////////////////////

function addCondition(previousFormValues, previousConditions, questionId, ruleId, questionType) {
  const newConditionId = `${NEW_ID_PREFIX}${uuidv4()}`;
  const newValues = {};

  // Add new form values
  newValues[`${questionId}-RID_${ruleId}-logicOp-CID_${newConditionId}`] =
    previousConditions.length > 0 ? QUESTION_CONDITIONAL.$AND : null; // [$AND, $OR]

  const conditionInputType = getConditionalInputType(questionType);
  if (conditionInputType === CONDITION_INPUT_TYPE.SELECT) {
    newValues[`${questionId}-RID_${ruleId}-choiceId-CID_${newConditionId}`] = -1; // question choice ID
    newValues[`${questionId}-RID_${ruleId}-criteria-CID_${newConditionId}`] = QUESTION_CONDITIONAL.$IS; // [$IS, $IS_NOT]
  } else if (conditionInputType === CONDITION_INPUT_TYPE.TEXT) {
    newValues[`${questionId}-RID_${ruleId}-value-CID_${newConditionId}`] = null; // slider value
    newValues[`${questionId}-RID_${ruleId}-criteria-CID_${newConditionId}`] = QUESTION_CONDITIONAL.$LT; // [$LT, $LTE, $EQ, $GT, $GTE]
  }

  const newFormValues = {
    ...previousFormValues,
    ...newValues,
  };

  // Add new condition to conditions list
  const newCondition = {
    id: newConditionId,
    order: previousConditions.length + 1,
    logicOp: previousConditions.length > 0 ? `${questionId}-RID_${ruleId}-logicOp-CID_${newConditionId}` : null,
    criteria: `${questionId}-RID_${ruleId}-criteria-CID_${newConditionId}`,
    choice: `${questionId}-RID_${ruleId}-choiceId-CID_${newConditionId}`,
    value: `${questionId}-RID_${ruleId}-value-CID_${newConditionId}`,
  };

  const newConditions = [...previousConditions, newCondition];

  return { newFormValues, newConditions };
}

function addRule(previousFormValues, previousRules, questionId, questionType) {
  const newRuleId = `${NEW_ID_PREFIX}${uuidv4()}`;
  // let newValues;
  let newConditions = [];

  // Create initial condition
  const newState = addCondition(previousFormValues, [], questionId, newRuleId, questionType);

  // Add initial condition form values
  const newValues = { ...newState.newFormValues };
  newConditions = newState.newConditions;

  // Add 'jumpTo' form value
  newValues[`${questionId}-RID_${newRuleId}-jumpTo`] = NEXT_QUESTION_OPTION[0];

  const newFormValues = {
    ...previousFormValues,
    ...newValues,
  };

  // Add new rule to rules list
  const newRule = {
    id: newRuleId,
    order: previousRules.length + 1,
    condition: newConditions,
    jumpTo: `${questionId}-RID_${newRuleId}-jumpTo`,
  };

  const newRules = [...previousRules, newRule];

  return { newFormValues, newRules };
}

function removeRule(previousFormValues, previousRules, ruleId, questionId) {
  // Remove rule form values
  const cleanedFormValues = { ...previousFormValues };

  Object.entries(cleanedFormValues).forEach((cleanedFormValue) => {
    if (cleanedFormValue[0].includes(ruleId)) delete cleanedFormValues[cleanedFormValue[0]];
  });

  // Remove rule from rules list
  const newRules = previousRules.filter((rule) => rule.id !== ruleId);

  // Reset order for all rules
  newRules.forEach((element, index) => {
    element.order = index + 1;
  });

  const newFormValues = {
    ...cleanedFormValues,
  };

  return { newFormValues, newRules };
}

function removeCondition(previousFormValues, previousConditions, previousRules, conditionId, questionId, ruleId) {
  const newValues = { ...previousFormValues };

  // If removed condition was last one on last rule we'll do rule removal
  if (previousRules.length === 1 && previousConditions.length === 1) {
    const newState = removeRule(previousFormValues, previousRules, ruleId, questionId);

    return { newFormValues: newState.newFormValues, newRules: newState.newRules };
  }

  // Clean up form values
  delete newValues[`${questionId}-RID_${ruleId}-logicOp-CID_${conditionId}`];
  delete newValues[`${questionId}-RID_${ruleId}-criteria-CID_${conditionId}`];
  delete newValues[`${questionId}-RID_${ruleId}-choiceId-CID_${conditionId}`];
  delete newValues[`${questionId}-RID_${ruleId}-value-CID_${conditionId}`];
  delete newValues[`${questionId}-RID_${ruleId}-jumpTo`];
  const newFormValues = {
    ...newValues,
  };

  // If first condition is deleted remove logic operand (AND, OR) for next condition it next condition exists
  const removedConditionOrder = previousConditions.find((condition) => condition.id === conditionId).order;
  if (removedConditionOrder === 1) {
    const nextCondition = previousConditions.find((condition) => condition.order === removedConditionOrder + 1);
    if (nextCondition) {
      newFormValues[nextCondition.logicOp] = null;
      nextCondition.logicOp = null;
    }
  }

  // Clean up conditions list
  const newConditions = previousConditions.filter((condition) => condition.id !== conditionId);

  // Reset order for all conditions
  newConditions.forEach((element, index) => {
    element.order = index + 1;
  });

  return { newFormValues, newConditions };
}

/// //////////////////////////////////////////////
/// ///////// FORM SETUP METHODS  ////////////////
/// //////////////////////////////////////////////

/**
 * Map conditional data to form values
 * (plus prepare rules collection used for rendering form)
 *
 * !!!! IMPORTANT !!!!
 *
 * Following schema is used for form field fieldname/value:
 *
 * Condition logic operand:
 * FIELD NAME:  {questionId}-RID_{ruleId}-logicOp-CID_{conditionId}
 * VALUE:       [$AND, $OR]
 *
 * Condition criteria operand:
 * FIELD NAME:  {questionId}-RID_{ruleId}-criteria-CID_{conditionId}
 * VALUE:       [$IS, $IS_NOT, $LT, $LTE, $EQ, $GT, $GTE]
 *
 * Condition text input value:
 * FIELD NAME:  {questionId}-RID_{ruleId}-value-CID_{conditionId}
 * VALUE:       0-100 (slider value)
 *
 * Condition choice input value:
 * FIELD NAME:  {questionId}-RID_{ruleId}-choiceId-CID_{conditionId}
 * VALUE:       choiceXLabel (question choice form field name)
 *
 * Rule jump to destination (for set conditions):
 * FIELD NAME:  {questionId}-RID_{ruleId}-jumpTo
 * VALUE:       selected question id
 *
 * Conditional logic 'else' jump to destination (if no rules/conditions are met):
 * FIELD NAME:  {questionId}-else
 * VALUE:       selected question id
 *
 * NOTE: how the filed names are structured is of absolute importance. Adding and removing rules and conditions as well as
 * preparing data for question update is dependent of this structure!
 */
function mapConditionalDataToFormData(conditionalData, formQuestionValues, question) {
  const formConditionalValues = {};
  const formRules = [];

  // Add form value for conditional 'else' field
  formConditionalValues[`${question._id}-else`] = conditionalData?.else || NEXT_QUESTION_OPTION[0];

  // Go through the rules (if any) and create form values for each rule (conditions + jump to destination)
  conditionalData?.rules?.forEach((rule) => {
    const initialRuleConditions = [];
    rule.condition.forEach((condition, index) => {
      // Add form value for each rule condition (logicOp, criteria, value, choiceId,jumpTo)
      formConditionalValues[`${question._id}-RID_${rule._id}-logicOp-CID_${condition._id}`] = condition.logicOp;
      formConditionalValues[`${question._id}-RID_${rule._id}-criteria-CID_${condition._id}`] = condition.criteria;
      formConditionalValues[`${question._id}-RID_${rule._id}-value-CID_${condition._id}`] = condition.value;

      // Set choiceId form value for question types that have choice based conditions (choice input value)
      const conditionInputType = getConditionalInputType(question.questionType);
      if (conditionInputType === CONDITION_INPUT_TYPE.SELECT) {
        const targetChoice = question.choices.find((choice) => choice._id === condition.choiceId);

        let targetChoiceIndex;
        if (!targetChoice.choice) {
          targetChoiceIndex = question.choices.findIndex((ch) => ch._id === targetChoice._id);
          targetChoice.secondaryLabel = QUESTION_CHOICE_FIELDS[targetChoiceIndex].defaultLabel;
        }

        Object.entries(formQuestionValues).forEach((formQuestionValue) => {
          if (targetChoice.choice && targetChoice.choice === formQuestionValue[1]) {
            formConditionalValues[`${question._id}-RID_${rule._id}-choiceId-CID_${condition._id}`] =
              formQuestionValue[0];
          } else if (!targetChoice.choice && targetChoice.secondaryLabel) {
            formConditionalValues[`${question._id}-RID_${rule._id}-choiceId-CID_${condition._id}`] =
              QUESTION_CHOICE_FIELDS[targetChoiceIndex].fieldName;
          }
        });
      }

      // Create rule object required to render rule form and track form field IDs for that rule
      initialRuleConditions.push({
        id: condition._id,
        logicOp: condition.logicOp ? `${question._id}-RID_${rule._id}-logicOp-CID_${condition._id}` : null,
        criteria: `${question._id}-RID_${rule._id}-criteria-CID_${condition._id}`,
        choice: `${question._id}-RID_${rule._id}-choiceId-CID_${condition._id}`,
        value: `${question._id}-RID_${rule._id}-value-CID_${condition._id}`,
        order: index + 1,
      });
    });

    formConditionalValues[`${question._id}-RID_${rule._id}-jumpTo`] = rule.jumpTo || SELECT_OPTION[0];

    formRules.push({ id: rule._id, condition: initialRuleConditions });
  });

  return { formConditionalValues, formRules };
}

/**
 * Starting point for setting up conditional logic form
 */
function setupFormInitialValues(question, initialFormQuestionValues) {
  const conditionalExecution = question?.questionConfiguration?.conditionalExecution;

  // Map conditional data to form data and create rule objects required to render rule forms
  const mappedDate = mapConditionalDataToFormData(conditionalExecution, initialFormQuestionValues, question);

  return { initialFormConditionValues: mappedDate.formConditionalValues, initialRules: mappedDate.formRules };
}

/// //////////////////////////////////////////////
/// ///////// FORM SUBMIT METHODS  ///////////////
/// //////////////////////////////////////////////

function extractRuleFormValues(ruleId, formValues) {
  const ruleFormValues = [];

  // Extract all form values for this rule (use rule id to identify form values that belong to rule)
  Object.entries(formValues).forEach((item) => {
    if (item[0].includes(ruleId)) ruleFormValues.push({ key: item[0], value: item[1] });
  });

  return ruleFormValues;
}

function extractFullySetConditionIDs(ruleFormValues) {
  const fullySetConditionIds = [];

  ruleFormValues.forEach((item) => {
    const key = item.key;
    if (key.includes('choiceId')) {
      // Extract condition ID for conditions that have question choice selected
      const choiceIdIndex = key.indexOf('choiceId-CID_');
      const conditionId = key.slice(choiceIdIndex + 13);
      if (!fullySetConditionIds.includes(conditionId)) {
        fullySetConditionIds.push(conditionId);
      }
    }

    if (key.includes('value')) {
      // Extract condition ID for conditions that have conditional value
      const choiceIdIndex = key.indexOf('value-CID_');
      const conditionId = key.slice(choiceIdIndex + 10);
      if (!fullySetConditionIds.includes(conditionId)) {
        fullySetConditionIds.push(conditionId);
      }
    }
  });

  return fullySetConditionIds;
}

function prepareFullySetConditions(conditionIds, ruleFormValues, formValues, question, updatedQuestion) {
  const fullySetConditions = [];
  conditionIds.forEach((conditionId) => {
    const fullySetCondition = { _id: conditionId };
    // Merge belonging logicOp, criteria, value and choiceId to each condition
    ruleFormValues.forEach((item) => {
      const key = item.key;
      const value = item.value;
      if (key.includes(conditionId) && key.includes('-logicOp-')) fullySetCondition.logicOp = value;
      if (key.includes(conditionId) && key.includes('-criteria-')) fullySetCondition.criteria = value;
      if (key.includes(conditionId) && key.includes('-value-'))
        fullySetCondition.value = value !== undefined ? value.toString() : value;
      if (key.includes(conditionId) && key.includes('-choiceId-')) {
        const targetChoiceFormValue = formValues[value];

        // This choice has label defined
        let targetChoice;
        if (targetChoiceFormValue) {
          // Form value for condition 'choiceId' field is 'choiceXLabel' but in the end we need exact choice id
          targetChoice = updatedQuestion.choices.find((choice) => choice.choice === targetChoiceFormValue);
        } else {
          // This choice has no label defined (condition choices defined as 'Choice X (missing label)' in conditionl logic form)
          // therefore  we can't use choice label fom value but must determine which question choice this is by using index
          const selectedChoiceIndex = QUESTION_CHOICE_FIELDS.findIndex(
            (choiceField) => choiceField.fieldName === value,
          );
          targetChoice = question.choices[selectedChoiceIndex];
        }

        if (targetChoice) fullySetCondition.choiceId = targetChoice._id;
      }

      // Questions with textual condition input type (slider and slider feedback questions) have conditions with 'value' instead 'choiceId'
      // ('choiceId' is not part of form values)
      // But all conditions should still have 'choiceId' and it's same for all conditions (_id of only choice item, for those two question types)
      const conditionInputType = getConditionalInputType(question.questionType);
      if (conditionInputType === CONDITION_INPUT_TYPE.TEXT) fullySetCondition.choiceId = question.choices[0]._id;
    });

    fullySetConditions.push(fullySetCondition);
  });

  return fullySetConditions;
}

/**
 * Starting point for reparing conditional data for question update
 */
function extractConditionalFormValues(question, updatedQuestion, formValues, rules) {
  let submitData;
  // Setup for 'conditionalExecution' object, depending on whether or not conditional logic was already set for this question before this update
  if (!question?.questionConfiguration?.conditionalExecution) {
    submitData = { rules: [] };
  } else {
    submitData = { _id: question.questionConfiguration.conditionalExecution._id, rules: [] };
  }

  rules.forEach((rule) => {
    // Extract all form values for this rule (use rule id to identify form values that belong to rule)
    const ruleFormValues = extractRuleFormValues(rule.id, formValues);

    // Extract IDs of all fully set conditions (conditions that have all data set - logic operand, criteria and choice/value)
    const fullySetConditionIds = extractFullySetConditionIDs(ruleFormValues);

    // Align order of fully set conditions IDs with order in which conditions are displayed on interface
    // (form values are "scrambled" order wise but rule and condition arrays are strictly aligned with order in which they are displayed on interface)
    const sortedFullySetConditionIds = [];
    rule.condition.forEach((condition) => {
      const targetConditionId = fullySetConditionIds.find((id) => id === condition.id);
      if (targetConditionId) sortedFullySetConditionIds.push(targetConditionId);
    });

    // Create collection of fully set conditions for this rule
    const fullySetConditions = prepareFullySetConditions(
      sortedFullySetConditionIds,
      ruleFormValues,
      formValues,
      question,
      updatedQuestion,
    );

    // Clean temporary condition id for all new conditions
    fullySetConditions.forEach((cond) => {
      if (cond._id.includes(NEW_ID_PREFIX)) delete cond._id;
    });

    // Extract rule 'jumpTo' value
    let ruleJumpToValue = null;
    ruleFormValues.forEach((item) => {
      if (item.key.includes('-jumpTo')) ruleJumpToValue = item.value === '-1' ? parseInt(item.value, 10) : item.value;
    });

    // Create final rule object
    const preparedRule = {
      condition: fullySetConditions,
      jumpTo: ruleJumpToValue,
    };

    // Set existing rule id if this rule was already part of conditional logic before question update
    if (!rule.id.includes(NEW_ID_PREFIX)) preparedRule._id = rule.id;

    submitData.rules.push(preparedRule);
  });

  // Extract 'else' value (with additional string2int parsing, if required)
  submitData.else =
    formValues[`${question._id}-else`] === '-1'
      ? parseInt(formValues[`${question._id}-else`], 10)
      : formValues[`${question._id}-else`];

  // In case there are no conditions and no 'jumpTo' destination we should remove conditional logic
  if (!submitData.else && !submitData.rules.length) submitData = null;

  updatedQuestion.questionConfiguration.conditionalExecution = submitData;
}

function removeConditionalLogic(formValues, questionId) {
  const questionFormValues = {};

  // Remove all form values who's keys contain questionId at index 0 with exception of 'else' field (look at conditional form field key schemas)
  const formValuesArray = Object.entries(formValues);
  for (let index = formValuesArray.length - 1; index > -1; index--) {
    const formValue = formValuesArray[index];
    if (!formValue[0].includes(questionId) || formValue[0].includes('-else') || formValue[0].indexOf(questionId) > 0)
      questionFormValues[formValue[0]] = formValue[1];
  }

  return { newFormValues: questionFormValues, newRules: [] };
}

/// //////////////////////////////////////////////
/// /////////// UTIL METHODS  ////////////////////
/// //////////////////////////////////////////////

function isUnreachableQuestion(question, questions) {
  let isUnreachable = false;

  const questionId = question._id;

  const questionsIndex = questions.findIndex((item) => item._id === questionId);
  // First question is always reachable
  if (questionsIndex !== 0) {
    questions.every((q, index) => {
      isUnreachable = true;

      const conditionalConfiguration = q?.questionConfiguration?.conditionalExecution;

      // Question is reachable if previous question doesn't have conditional logic
      if (!conditionalConfiguration && index === questionsIndex - 1) isUnreachable = false;

      if (conditionalConfiguration) {
        // Question is reachable if selected as conditional logic 'else' destination
        if (questionId === conditionalConfiguration.else) isUnreachable = false;

        // Question is reachable if previous question's 'else' destination is 'Next question'
        const firstRule = conditionalConfiguration?.rules?.length > 0 ? conditionalConfiguration.rules[0] : null;
        if (
          firstRule?.condition?.length > 0 &&
          conditionalConfiguration.else === NEXT_QUESTION_OPTION[0] &&
          index === questionsIndex - 1
        )
          isUnreachable = false;

        // Question is reachable if selected as 'jumpTo' destionation for one of existing rules
        conditionalConfiguration.rules?.forEach((rule) => {
          if (questionId === rule.jumpTo) isUnreachable = false;
        });
      }

      // If 'false' checkout is done, exit loop
      return isUnreachable;
    });
  }

  return isUnreachable;
}

function isConditionalQuestion(questionType) {
  if (
    questionType === QUESTION_TYPES.TWO_IMAGE_SINGLE_CHOICE ||
    questionType === QUESTION_TYPES.THREE_IMAGE_SINGLE_CHOICE ||
    questionType === QUESTION_TYPES.FOUR_IMAGE_SINGLE_CHOICE ||
    questionType === QUESTION_TYPES.MULTIPLE_IMAGE_MULTIPLE_CHOICE ||
    questionType === QUESTION_TYPES.MULTIPLE_IMAGE_OTHER_CHOICE ||
    questionType === QUESTION_TYPES.SINGLE_SLIDER_CHOICE ||
    questionType === QUESTION_TYPES.SLIDER_FEEDBACK ||
    questionType === QUESTION_TYPES.RATING
  )
    return true;

  return false;
}

function parseSelectedOption(value) {
  if (value === NEXT_QUESTION_OPTION[1]) return NEXT_QUESTION_OPTION[0];

  if (value === SELECT_OPTION[1]) return null;

  return value;
}

function hasQuestionConditionalLogic(question) {
  const conditionalConfiguration = question?.questionConfiguration?.conditionalExecution;

  if (!conditionalConfiguration) return false;

  if (conditionalConfiguration.else !== null || conditionalConfiguration.rules.length > 0) return true;
}

function hasSurveyConditionalLogic(questions) {
  let hasCL;
  questions.forEach((question) => {
    if (hasQuestionConditionalLogic(question)) hasCL = true;
  });

  return hasCL;
}

function hasTempConditionalLogic(formValues) {
  const formValuesArray = Object.entries(formValues);
  let hasCL;
  for (let index = formValuesArray.length - 1; index > -1; index--) {
    const formValue = formValuesArray[index];
    if ((formValue[0].includes('-else') || formValue[0].includes('-jumpTo')) && formValue[1]) {
      hasCL = true;
    }
  }

  return hasCL;
}

function prepareAvailableConditionChoices(formValues, choiceFields) {
  const formValuesArray = Object.entries(formValues);

  const preparedChoices = [];
  choiceFields.forEach((choiceField) => {
    const targetChoice = formValuesArray.find((formValue) => formValue[0] === choiceField.fieldName);

    // If choice has no label set we'll display default label value
    if (targetChoice) {
      preparedChoices.push([targetChoice[0], targetChoice[1]]);
    } else {
      preparedChoices.push([choiceField.fieldName, choiceField.defaultLabel]);
    }
  });

  return preparedChoices;
}

export default {
  setupFormInitialValues,
  addRule,
  addCondition,
  removeRule,
  removeCondition,
  extractConditionalFormValues,
  removeConditionalLogic,
  isConditionalQuestion,
  getConditionalInputType,
  isUnreachableQuestion,
  parseSelectedOption,
  hasQuestionConditionalLogic,
  hasSurveyConditionalLogic,
  hasTempConditionalLogic,
  prepareAvailableConditionChoices,
  QUESTION_CHOICE_FIELDS,
};
