import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import MDEditor from '@uiw/react-md-editor';
import SvgIcon from '../SvgIcon';

function SidebarHelp(props) {
  const { HelpStore, location } = props;

  const helpItem = HelpStore.currentHelpItem;

  const [currentPath, setCurrentPath] = useState(location.pathname);

  useEffect(() => {
    HelpStore.closeHelp();
  }, [HelpStore, location]);

  function closeHanlder() {
    HelpStore.closeHelp();
  }

  return (
    <div
      className={`c-help__description ${
        HelpStore.isContextualHelpActive && HelpStore.currentHelpKey ? 'is-visible' : ''
      }`}
      style={{ top: `${HelpStore.positionTop}px` }}
      data-testid='sidebar-help-wrapper'
      data-color-mode='light'
    >
      <button className='c-help__close' onClick={closeHanlder}>
        <SvgIcon icon='times' />
        <span className='o-label'>Close</span>
      </button>
      <p className='c-help__title'>{helpItem?.header}</p>
      <MDEditor.Markdown source={helpItem?.content} linkTarget='_blank' className='c-help__markdown' />
    </div>
  );
}

SidebarHelp.propTypes = {
  HelpStore: PropTypes.object.isRequired,
};

export default withRouter(inject((root) => ({ HelpStore: root.RootStore.helpStore }))(observer(SidebarHelp)));
