import { reaction, action, observable, computed, makeObservable } from 'mobx';

import Agent from '../common/agent';
import enums from '../shared/engage/engage-flow-enums';
import { DEFAULT_BUTTON } from '../containers/project/actions/CustomActionsUtils';

const ENUMS = enums().EngageEndOfPollFlowButtonType;

export default class ActionStore {
  _loading = false;

  _initialized = false;

  _error = null;

  _items = [];

  constructor(rootStore, projectStore) {
    makeObservable(this, {
      _items: observable,
      _loading: observable,
      _initialized: observable,
      _error: observable,

      resetFlags: action,
      createAddonButtonDraft: action,

      customActions: computed,
      getAddonButtons: computed,
      getDefaultButton: computed,
      isLoading: computed,
      isInitialized: computed,
      error: computed,

      loadData: action,
      toggleOpened: action,
    });

    this.rootStore = rootStore;
    this.projectStore = projectStore;
    reaction(
      () => this.projectStore.isInitialized,
      () => this.loadData(this.projectStore.project.runtimeOptions, this.projectStore.projectDefault.runtimeOptions),
    );
  }

  get isLoading() {
    return this._loading;
  }

  get isInitialized() {
    return this._initialized;
  }

  get error() {
    return this._error;
  }

  get customActions() {
    return this._items;
  }

  /**
   * Toggle item/form open/close status
   * @param item
   */
  // eslint-disable-next-line class-methods-use-this
  toggleOpened(item) {
    if (item) {
      // eslint-disable-next-line no-param-reassign
      item.formState.isOpened = !item.formState.isOpened;
    }
  }

  get getAddonButtons() {
    return this.getCustomButtons([ENUMS.ADDON_BUTTON, ENUMS.ADDON_SECOND_BUTTON]);
  }

  get getDefaultButton() {
    return this.getCustomButtons([ENUMS.DEFAULT_BUTTON]);
  }

  getCustomButtons = (buttonTypes) =>
    this._items.filter((item) => buttonTypes.includes(item.data.buttonTypeIdentifier));

  /**
   * Load and prepare data already loaded in ProjectStore
   * @param data
   */
  loadData(data, actionsDefault) {
    const tmp = [];
    if (data?.endOfPollFlows?.endOfPollButtons) {
      data?.endOfPollFlows?.endOfPollButtons.map((item) => {
        const defaultAction = actionsDefault.endOfPollFlows.endOfPollButtons.find(
          (actionDef) => actionDef._id === item._id,
        );
        tmp.push({
          data: item,
          defaultData: defaultAction,
          formState: { isOpened: false },
        });
        return item;
      });
    }
    if (!tmp.find((item) => item.data.buttonTypeIdentifier === ENUMS.DEFAULT_BUTTON)) {
      tmp.push({
        data: DEFAULT_BUTTON,
        defaultData: {},
        formState: { isOpened: false },
      });
    }
    this._items = tmp;
    this._initialized = true;
    // console.log(this._team);
  }

  /**
   * Remove decision DELETE /project/:id/faq/:faqId
   * @param itemId
   * @returns {Promise<T>}
   */
  removeEndOfPollFlowById = (itemId) => {
    // console.log('DELETE STORE');
    this._loading = true;
    this._error = null;
    return Agent.Projects.removeEndOfPollButtons(this.projectStore.project._id, itemId)
      .then(() => {
        this.rootStore.toastrStore.success('Custom button deleted');
        return this.projectStore.refresh();
      })
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error('Error deleting custom button', null, err);
      })
      .finally(this.resetFlags);
  };

  /**
   * Retrieve basic project info GET /project/:id
   * @param id
   * @returns {Promise<boolean>}
   */
  getEndOfPollFlowById = (id) => {
    this._loading = true;
    this._error = null;
    if (!id) return;
    return Agent.Projects.getById(id)
      .then((data) => data.data.runtimeOptions.endOfPollFlows.endOfPollButtons)
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error('Error loading project', null, err);
      })
      .finally(() => (this.loading = false));
  };

  createAddonButtonDraft = (button) => {
    this._items.push({
      data: { ...button, _id: 0 },
      defaultData: {},
      formState: { isOpened: true },
    });
  };

  /**
   * set end of poll button
   */
  setEndOfPollButtons = (values, openDefault = false) => {
    this._loading = true;
    this._error = null;
    return Agent.Projects.setEndOfPollButtons(this.projectStore.project._id, values)
      .then(() => {
        this.refreshAndKeepItemOpen(values.buttonTypeIdentifier, openDefault);
        this.rootStore.toastrStore.success('Project updated', null);
        // console.log(this._items);
      })
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error('Error saving project', null, err);
      })
      .finally(() => (this.loading = false));
  };

  refreshAndKeepItemOpen = (buttonType, openDefault) => {
    // console.log(buttonType);
    this.projectStore.refresh().then(() => {
      let item = [];
      if (openDefault) {
        // eslint-disable-next-line no-shadow
        item = this._items.find((item) => item.data.buttonTypeIdentifier === ENUMS.DEFAULT_BUTTON);
      } else {
        // eslint-disable-next-line no-shadow
        item = this._items.find((item) => item.data.buttonTypeIdentifier === buttonType);
      }
      this.toggleOpened(item);
    });
  };

  resetFlags = () => {
    this._loading = false;
  };
}
