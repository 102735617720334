import agent from './agent/ReportsAgent';

/**
 * Constants moved to shared
 * Here we're only remapping for use in populo to avoid signature change
 */
const getEmoji = require('../shared/engage').QuestionConstants.getEmoji;
const getEmojiStyle = require('../shared/engage').QuestionConstants.getEmojiStyle;

function download(res) {
  const bytes = atob(res.data);

  const byteNumbers = new Array(bytes.length);
  for (let i = 0; i < bytes.length; i++) {
    byteNumbers[i] = bytes.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  const blob = new Blob([byteArray], {
    type: res.headers['content-type'],
  });
  const downloadLink = document.createElement('a');
  // In Firefox, you need to add the created element to the DOM and it will work:
  // https://stackoverflow.com/questions/55162944/document-createelementa-click-not-working-in-firefox
  document.body.appendChild(downloadLink);
  downloadLink.download = decodeURIComponent(res.headers.filename);
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.dataset.downloadurl = [res.headers['content-type'], downloadLink.download, downloadLink.href].join(':');
  downloadLink.click();
  setTimeout(() => {
    document.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(downloadLink.href);
  }, 100);
}

function excelDownload(
  downloadAgent,
  projectId,
  pollId,
  questionId,
  toastrStore,
  fromDate,
  toDate,
  segmentChoiceId,
  demographicChoiceId,
) {
  const query = {
    segment: segmentChoiceId,
    demographic: demographicChoiceId,
    timestampFrom: fromDate,
    timestampTo: toDate,
  };

  downloadAgent
    .getExcelReport(projectId, pollId, questionId, query)
    .then((res) => {
      download(res);
    })
    .catch((err) => {
      toastrStore.info('Failed generating report', 'Report generation', err);
    });
}

// TODO reportType ignored for now
// I handle here only Excel downloads
// Needs handling when we start handling downloading PDFs
// ???????? if we don't opt for direct download via GET ????????
function downloadBatchedReport(jobId, reportType, toastrStore) {
  agent
    .downloadBatchedReport(jobId)
    .then((res) => {
      download(res);
    })
    .catch((err) => {
      toastrStore.error('Failed downloading report', 'Report download', err);
    });
}
// eslint-disable-next-line
export default {
  getEmoji,
  getEmojiStyle,
  excelDownload,
  downloadBatchedReport,
};
