import React, { useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import MDEditor from '@uiw/react-md-editor';
// eslint-disable-next-line import/no-cycle
import { PollReportContext } from '../PollsReport';
import QuestionHeader from '../../QuestionHeader';
import { IMAGE_FORMATS } from '../../../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import SnapshotableImage from '../../../../../common/components/snapshotableImage/ScreenshotableImage';
import QuestionFooter from '../../QuestionFooter';

function FactComparePreview(props) {
  const { question } = props;
  const { questionId, label, resource, resource1, skipSeen } = question;

  const pollReportContext = useContext(PollReportContext);
  const containerReference = useRef();

  useEffect(() => {
    if (pollReportContext)
      pollReportContext.addReference({ id: questionId, containerReference: containerReference.current });
  }, [pollReportContext, questionId]);

  return (
    <div ref={containerReference} className='c-poll-preview__item c-poll-preview__item--fact'>
      <div className='c-question c-question--fact-compare'>
        <QuestionHeader question={question} showTitle={false} />
        <div className='c-question__content'>
          <div className='c-question-fact c-question-fact--compare'>
            <div className='c-question-fact__content u-rich-text'>
              <MDEditor.Markdown source={label} className='markdown_preview' />
            </div>
            <div className='c-question-fact__items'>
              <div className='c-question-fact__item'>
                {resource && resource.length > 0 && (
                  <SnapshotableImage resource={resource} format={IMAGE_FORMATS.SQUARE.imageType} />
                )}
              </div>
              <div className='c-question-image__item'>
                {resource && resource.length > 1 && (
                  <SnapshotableImage resource={resource1} format={IMAGE_FORMATS.SQUARE.imageType} />
                )}
              </div>
            </div>
          </div>
        </div>
        <QuestionFooter viewed={skipSeen.seenCount} showSkipped={false} showAnswered={false} />
      </div>
    </div>
  );
}

FactComparePreview.propTypes = {
  question: PropTypes.shape({
    questionId: PropTypes.string.isRequired,
    label: PropTypes.string,
    resource: PropTypes.string,
    resource1: PropTypes.string,
    skipSeen: PropTypes.shape({
      seenCount: PropTypes.number,
    }),
  }).isRequired,
};

export default FactComparePreview;
