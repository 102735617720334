/**
 * From spec
 */
// 0 	Question type 1 - Single choice image
// 1 	Question type 2 - Ordering most / least preferred
// 2 	Question type 3 - Percentage preference
// 3 	Question type 4 - Multi choice values
// 4 	Question type 5 - Single choice 4 multi-image
// 5 	Question type 6 - Single choice 2 multi-image
// 10 	Fact type 1 - Description text
// 11 	Fact type 2 - Description text and image
// 12 	Fact type 3 - Multiple images
// -------- QQ
// 102 	QQ type 102 - Single choice 2-image
// 103 	QQ type 103 - Single choice 3-image
// 104 	QQ type 104 - Single choice 4-image
// 105  QQ type 106 - Multiple image multiple choice

/**
 * From BE
 */
// SINGLE_CHOICE: 'three-image single choice',               // type 1
// ORDERING_CHOICE: 'ordering choice',                       // type 2
// SINGLE_SLIDER_CHOICE: 'single-slider choice',             // type 3
// THREE_SLIDER_CHOICE_VALUES: 'three-slider choice values', // type 4
// FOUR_IMAGE_SINGLE_CHOICE: 'four-image single choice',     // type 5
// TWO_IMAGE_SINGLE_CHOICE: 'two-image single choice',       // type 6
// MULTIPLE_IMAGE_MULTIPLE_CHOICE: 'multiple-image multiple choice', // type 7
// DESCRIPTION_FACT: 'description fact',                     // type 10
// SINGLE_IMAGE_FACT: 'single-image fact',                   // type 11
// TWO_IMAGE_FACT: 'two-image fact'                          // type 12
// FEEDBACK: 'feedback',                                     // type 13
// SLIDER_FEEDBACK: 'slider feedback'                        // type 14
// MULTIPLE_IMAGE_OTHER_CHOICE: 'multiple-image other choice'// type 15

// import POLL_ENUMS from '../../shared/engage/engage-poll-enums';
// import DEMOGRAPHIC_ENUMS from '../../shared/engage/engage-demographics-enums';
const eachDeep = require('deepdash/eachDeep');
const POLL_ENUMS = require('./engage-poll-enums');
const DEMOGRAPHIC_ENUMS = require('../../shared/engage/engage-demographics-enums');

const PollTypesIntegerMap = POLL_ENUMS().QuestionTypesIntegerMap();
const SegmentationTypesIntegerMap = POLL_ENUMS().SegmentationQuestionTypesIntegerMap();
const DemographicsTypesIntegerMap = DEMOGRAPHIC_ENUMS().DemographicsTypesIntegerMap();
const PollQuestionTypesEnums = POLL_ENUMS().QuestionTypesEnums;
const PollQuestionGroupsEnums = POLL_ENUMS().QuestionGroupsEnums;
const PollQuestionLabels = POLL_ENUMS().QuestionLabels;
const DemographicQuestionTypesEnums = DEMOGRAPHIC_ENUMS().DemographicsTypesEnums;
const DemographicQuestionLabels = DEMOGRAPHIC_ENUMS().DemographicLabels;

const THREE_IMAGE_SINGLE_CHOICE = PollQuestionTypesEnums.THREE_IMAGE_SINGLE_CHOICE;
const ORDERING_CHOICE = PollQuestionTypesEnums.ORDERING_CHOICE;
const SINGLE_SLIDER_CHOICE = PollQuestionTypesEnums.SINGLE_SLIDER_CHOICE;
const THREE_SLIDER_CHOICE_VALUES = PollQuestionTypesEnums.THREE_SLIDER_CHOICE_VALUES;
const FOUR_IMAGE_SINGLE_CHOICE = PollQuestionTypesEnums.FOUR_IMAGE_SINGLE_CHOICE;
const TWO_IMAGE_SINGLE_CHOICE = PollQuestionTypesEnums.TWO_IMAGE_SINGLE_CHOICE;
const MULTIPLE_IMAGE_MULTIPLE_CHOICE = PollQuestionTypesEnums.MULTIPLE_IMAGE_MULTIPLE_CHOICE;
const DESCRIPTION_FACT = PollQuestionTypesEnums.DESCRIPTION_FACT;
const SINGLE_IMAGE_FACT = PollQuestionTypesEnums.SINGLE_IMAGE_FACT;
const TWO_IMAGE_FACT = PollQuestionTypesEnums.TWO_IMAGE_FACT;
const FEEDBACK = PollQuestionTypesEnums.FEEDBACK;
const SLIDER_FEEDBACK = PollQuestionTypesEnums.SLIDER_FEEDBACK;
const MULTIPLE_IMAGE_OTHER_CHOICE = PollQuestionTypesEnums.MULTIPLE_IMAGE_OTHER_CHOICE;
const ANNOTATION_IMAGE = PollQuestionTypesEnums.ANNOTATION_IMAGE;
const ANNOTATION_MAP = PollQuestionTypesEnums.ANNOTATION_MAP;
const QUICK_TEXT = PollQuestionTypesEnums.QUICK_TEXT;
const RATING = PollQuestionTypesEnums.RATING;
const IMAGE_GROUP = PollQuestionGroupsEnums.IMAGE_GROUP;
const FACT_GROUP = PollQuestionGroupsEnums.FACT_GROUP;
const ANNOTATE_GROUP = PollQuestionGroupsEnums.ANNOTATE_GROUP;

// QQ
const QQ_TWO_IMAGE_SINGLE_CHOICE = `QQ_${TWO_IMAGE_SINGLE_CHOICE}`;
const QQ_THREE_IMAGE_SINGLE_CHOICE = `QQ_${THREE_IMAGE_SINGLE_CHOICE}`;
const QQ_FOUR_IMAGE_SINGLE_CHOICE = `QQ_${FOUR_IMAGE_SINGLE_CHOICE}`;
const QQ_MULTIPLE_IMAGE_MULTIPLE_CHOICE = `QQ_${MULTIPLE_IMAGE_MULTIPLE_CHOICE}`;

// DEMOGRAPHIC
const DEMOGRAPHIC_AGE = DemographicQuestionTypesEnums.DEMOGRAPHIC_AGE;
const DEMOGRAPHIC_ETHNICITY = DemographicQuestionTypesEnums.DEMOGRAPHIC_ETHNICITY;
const DEMOGRAPHIC_GENDER = DemographicQuestionTypesEnums.DEMOGRAPHIC_GENDER;
const DEMOGRAPHIC_SEXUALITY = DemographicQuestionTypesEnums.DEMOGRAPHIC_SEXUALITY;
const DEMOGRAPHIC_RELIGION = DemographicQuestionTypesEnums.DEMOGRAPHIC_RELIGION;
const DEMOGRAPHIC_HOUSEHOLD_NUMBER = DemographicQuestionTypesEnums.DEMOGRAPHIC_HOUSEHOLD_NUMBER;
const DEMOGRAPHIC_HOUSEHOLD_INCOME = DemographicQuestionTypesEnums.DEMOGRAPHIC_HOUSEHOLD_INCOME;
const DEMOGRAPHIC_ACCOMMODATION_TYPE = DemographicQuestionTypesEnums.DEMOGRAPHIC_ACCOMODATION_TYPE;
const DEMOGRAPHIC_ACCOMMODATION_OWNERSHIP = DemographicQuestionTypesEnums.DEMOGRAPHIC_ACCOMODATION_OWNERSHIP;
const DEMOGRAPHIC_EMPLOYMENT = DemographicQuestionTypesEnums.DEMOGRAPHIC_EMPLOYEMENT;
const DEMOGRAPHIC_CUSTOM_TYPE = DemographicQuestionTypesEnums.DEMOGRAPHIC_CUSTOM_TYPE;

// FEEDBACK SLIDER EMOJI
const QUESTION_EMOJI = [
  ['😡', 'very angry'],
  ['😠', 'angry'],
  ['🤨', 'sceptical'],
  ['😐', 'neutral'],
  ['🙂', 'slightly happy'],
  ['😄', 'very happy'],
  ['😍', 'ecstatic'],
];
const QUESTION_EMOJI_STYLE = [
  ['is-emoji-1', 'very angry'],
  ['is-emoji-2', 'angry'],
  ['is-emoji-3', 'sceptical'],
  ['is-emoji-4', 'neutral'],
  ['is-emoji-5', 'slightly happy'],
  ['is-emoji-6', 'very happy'],
  ['is-emoji-7', 'ecstatic'],
];

// logicOps
const $AND = '$AND';
const $OR = '$OR';
// predicates
const $IS = '$IS';
const $IS_NOT = '$IS_NOT';
const $LT = '$LT';
const $LTE = '$LTE';
const $EQ = '$EQ';
const $GT = '$GT';
const $GTE = '$GTE';

exports.QUESTION_CONDITIONAL = {
  $AND,
  $OR,
  logicOpEnum: [$AND, $OR],
  $IS,
  $IS_NOT,
  $LT,
  $LTE,
  $EQ,
  $GT,
  $GTE,
  criteriaEnum: [$IS, $IS_NOT, $LT, $LTE, $EQ, $GT, $GTE],
  selectCriteriaEnum: [$IS, $IS_NOT],
  sliderCriteriaEnum: [$LT, $LTE, $EQ, $GT, $GTE],
};

exports.QUESTION_CONDITIONAL_LABELS = {
  $IS: 'Is',
  $IS_NOT: 'Is not',
  $LT: 'Less than',
  $LTE: 'Less than or equal to',
  $EQ: 'Equal to',
  $GTE: 'Greater than or equal to',
  $GT: 'Greater than',
  $AND: 'And',
  $OR: 'Or',
};

exports.REACTION_CATEGORIES = {
  RATING: 'Rating',
};

const FIRST_NAME = 'firstName';
const LAST_NAME = 'lastName';
const ADDRESS = 'address';
const POSTAL_CODE = 'postalCode';
const EMAIL = 'email';
const PHONE = 'phone';
const EXTRA1 = 'extra1';
const EXTRA2 = 'extra2';

exports.PERSONAL_DATA_FIELDS = {
  FIRST_NAME,
  LAST_NAME,
  ADDRESS,
  POSTAL_CODE,
  EMAIL,
  PHONE,
  EXTRA1,
  EXTRA2,
  personalDataFieldsEnum: [FIRST_NAME, LAST_NAME, ADDRESS, POSTAL_CODE, EMAIL, PHONE, EXTRA1, EXTRA2],
};

exports.PERSONAL_DATA_FIELDS_LABELS = {
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  ADDRESS: 'Address',
  POSTAL_CODE: 'Postcode',
  EMAIL: 'Email',
  PHONE: 'Phone',
  EXTRA1: 'Extra 1',
  EXTRA2: 'Extra 2',
};

exports.INVERS_PERSONAL_DATA_FIELD_LABELS = {
  firstName: 'First name',
  lastName: 'Last name',
  address: 'Address',
  postalCode: 'Postcode',
  email: 'Email',
  phone: 'Phone',
  extra1: 'Extra 1',
  extra2: 'Extra 2',
};

exports.PERSONAL_DATA_QUESTION_ID = 'personal-data';

exports.QUESTION_GROUPS = {
  IMAGE_GROUP,
  FACT_GROUP,
  ANNOTATE_GROUP,
};

exports.QUESTION_TYPES = {
  THREE_IMAGE_SINGLE_CHOICE,
  ORDERING_CHOICE,
  SINGLE_SLIDER_CHOICE,
  THREE_SLIDER_CHOICE_VALUES,
  FOUR_IMAGE_SINGLE_CHOICE,
  MULTIPLE_IMAGE_OTHER_CHOICE,
  TWO_IMAGE_SINGLE_CHOICE,
  MULTIPLE_IMAGE_MULTIPLE_CHOICE,
  DESCRIPTION_FACT,
  SINGLE_IMAGE_FACT,
  TWO_IMAGE_FACT,
  FEEDBACK,
  SLIDER_FEEDBACK,
  ANNOTATION_IMAGE,
  ANNOTATION_MAP,
  QUICK_TEXT,
  RATING,
  // QQ
  QQ_TWO_IMAGE_SINGLE_CHOICE,
  QQ_THREE_IMAGE_SINGLE_CHOICE,
  QQ_FOUR_IMAGE_SINGLE_CHOICE,
  QQ_MULTIPLE_IMAGE_MULTIPLE_CHOICE,
  // DEMOGRAPHIC
  DEMOGRAPHIC_AGE,
  DEMOGRAPHIC_ETHNICITY,
  DEMOGRAPHIC_GENDER,
  DEMOGRAPHIC_SEXUALITY,
  DEMOGRAPHIC_RELIGION,
  DEMOGRAPHIC_HOUSEHOLD_NUMBER,
  DEMOGRAPHIC_HOUSEHOLD_INCOME,
  DEMOGRAPHIC_ACCOMMODATION_TYPE,
  DEMOGRAPHIC_ACCOMMODATION_OWNERSHIP,
  DEMOGRAPHIC_EMPLOYMENT,
  DEMOGRAPHIC_CUSTOM_TYPE,

  properties: {
    IMAGE_GROUP: {
      BELabel: 'image-choice-group',
      label: 'Image choice',
      icon: 'poll-image-choice-four',
      templateImg: 'example-question-image-choice-multiple.png',
      tip: 'Pick between a different number of images',
      type: 'image-group',
      items: {
        // type 6
        TWO_IMAGE_SINGLE_CHOICE: {
          BELabel: 'two-image single choice',
          label: PollQuestionLabels[TWO_IMAGE_SINGLE_CHOICE],
          icon: 'poll-image-choice-two',
          templateImg: 'example-question-two-image-choice.png',
          tip: 'Pick between 2 images',
          type: PollTypesIntegerMap[TWO_IMAGE_SINGLE_CHOICE],
        },
        // type 1
        THREE_IMAGE_SINGLE_CHOICE: {
          BELabel: 'three-image single choice',
          label: PollQuestionLabels[THREE_IMAGE_SINGLE_CHOICE],
          icon: 'poll-image-choice-three',
          templateImg: 'example-question-three-image-choice.png',
          tip: 'Pick between 3 images based on a lead image',
          type: PollTypesIntegerMap[THREE_IMAGE_SINGLE_CHOICE],
        },
        // type 5
        FOUR_IMAGE_SINGLE_CHOICE: {
          BELabel: 'four-image single choice',
          label: PollQuestionLabels[FOUR_IMAGE_SINGLE_CHOICE],
          icon: 'poll-image-choice-four',
          templateImg: 'example-question-four-image-choice.png',
          tip: 'Pick between 4 images',
          type: PollTypesIntegerMap[FOUR_IMAGE_SINGLE_CHOICE],
        },
        MULTIPLE_IMAGE_MULTIPLE_CHOICE: {
          BELabel: 'multiple-image multiple choice',
          label: PollQuestionLabels[MULTIPLE_IMAGE_MULTIPLE_CHOICE],
          icon: 'poll-image-choice-multiple',
          templateImg: 'example-question-image-choice-multiple.png',
          tip: 'Select multiple choices',
          type: PollTypesIntegerMap[MULTIPLE_IMAGE_MULTIPLE_CHOICE],
        },
        //type 15
        MULTIPLE_IMAGE_OTHER_CHOICE: {
          BELabel: 'multiple-image other choice',
          label: PollQuestionLabels[MULTIPLE_IMAGE_OTHER_CHOICE],
          icon: 'poll-image-choice-other',
          templateImg: 'example-question-image-choice-other.png',
          tip: 'Pick choice or write your own',
          type: PollTypesIntegerMap[MULTIPLE_IMAGE_OTHER_CHOICE],
        },
      },
    },
    FACT_GROUP: {
      BELabel: 'fact-group',
      label: 'Facts',
      icon: 'poll-fact-image',
      templateImg: 'example-question-fact-image.png',
      tip: 'Add text description, images and compare',
      type: 'fact-group',
      items: {
        // type 10
        DESCRIPTION_FACT: {
          BELabel: 'description fact',
          label: PollQuestionLabels[DESCRIPTION_FACT],
          icon: 'poll-fact-text',
          templateImg: 'example-question-fact-text.png',
          tip: 'Short text description',
          type: PollTypesIntegerMap[DESCRIPTION_FACT],
        },
        // type 11
        SINGLE_IMAGE_FACT: {
          BELabel: 'single-image fact',
          label: PollQuestionLabels[SINGLE_IMAGE_FACT],
          icon: 'poll-fact-image',
          templateImg: 'example-question-fact-image.png',
          tip: 'Short text description with image',
          type: PollTypesIntegerMap[SINGLE_IMAGE_FACT],
        },
        // type 12
        TWO_IMAGE_FACT: {
          BELabel: 'two-image fact',
          label: PollQuestionLabels[TWO_IMAGE_FACT],
          icon: 'poll-fact-compare',
          templateImg: 'example-question-fact-compare.png',
          tip: 'Short text with 2 images overlayed',
          type: PollTypesIntegerMap[TWO_IMAGE_FACT],
        },
      },
    },
    ANNOTATE_GROUP: {
      BELabel: 'annotate-group',
      label: 'Annotate',
      icon: 'poll-annotation-image',
      templateImg: 'example-question-annotate-image.png',
      tip: 'Add reactions over image or map area',
      type: 'annotate-group',
      items: {
        // Type 17
        ANNOTATION_IMAGE: {
          BELabel: 'visually bound annotation',
          label: PollQuestionLabels[ANNOTATION_IMAGE],
          icon: 'poll-annotation-image',
          templateImg: 'example-question-annotate-image.png',
          tip: 'Add reactions over an image',
          type: PollTypesIntegerMap[ANNOTATION_IMAGE],
        },
        // Type 18
        ANNOTATION_MAP: {
          BELabel: 'visually bound annotation',
          label: PollQuestionLabels[ANNOTATION_MAP],
          icon: 'poll-annotation-map',
          templateImg: 'example-question-annotate-map.png',
          tip: 'Add reactions over a map',
          type: PollTypesIntegerMap[ANNOTATION_MAP],
        },
      },
    },
    // type 19
    QUICK_TEXT: {
      BELabel: 'quick text',
      label: PollQuestionLabels[QUICK_TEXT],
      icon: 'poll-quick-answer',
      templateImg: 'example-question-quick-answer.png',
      tip: 'Write up to five answers',
      type: PollTypesIntegerMap[QUICK_TEXT],
    },
    // type 120
    RATING: {
      BELabel: 'rating',
      label: PollQuestionLabels[RATING],
      icon: 'star-filled',
      templateImg: 'example-question-rating.png',
      tip: 'Give your rating',
      type: PollTypesIntegerMap[RATING],
    },
    // type 4
    THREE_SLIDER_CHOICE_VALUES: {
      BELabel: 'three-slider choice values',
      label: PollQuestionLabels[THREE_SLIDER_CHOICE_VALUES],
      icon: 'poll-distribute',
      templateImg: 'example-question-distribute.png',
      tip: 'Distribute number between categories',
      type: PollTypesIntegerMap[THREE_SLIDER_CHOICE_VALUES],
    },
    // type 2
    ORDERING_CHOICE: {
      BELabel: 'ordering choice',
      label: PollQuestionLabels[ORDERING_CHOICE],
      icon: 'poll-reorder',
      templateImg: 'example-question-reorder.png',
      tip: 'Reorder choices from top to bottom',
      type: PollTypesIntegerMap[ORDERING_CHOICE],
    },
    // type 3
    SINGLE_SLIDER_CHOICE: {
      BELabel: 'single-slider choice',
      label: PollQuestionLabels[SINGLE_SLIDER_CHOICE],
      icon: 'poll-slider',
      templateImg: 'example-question-slider.png',
      tip: 'Rate on a 1-7 scale',
      type: PollTypesIntegerMap[SINGLE_SLIDER_CHOICE],
      /**
       * Not a bad idea to give defaults but API does not support it at the moment
       * For now passing only questionType field
       */
      // newTemplate: {
      //   questionText: `New Slider question`,
      //   questionType: SINGLE_SLIDER_CHOICE,
      //   // resource: [],
      //   // awardPoints: 5,
      //   // choices: [{
      //   //   "id": 1,
      //   //   "order": 0,
      //   //   "range": [0,100],
      //   //   "resource": null,
      //   // }]
      // }
    },
    // type 14
    SLIDER_FEEDBACK: {
      BELabel: 'slider feedback',
      label: PollQuestionLabels[SLIDER_FEEDBACK],
      icon: 'poll-slider-feedback',
      templateImg: 'example-question-slider-feedback.png',
      tip: 'Rate on 1-7 scale and leave feedback',
      type: PollTypesIntegerMap[SLIDER_FEEDBACK],
    },
    // Type 13
    FEEDBACK: {
      BELabel: 'feedback',
      label: PollQuestionLabels[FEEDBACK],
      icon: 'poll-feedback',
      templateImg: 'example-question-feedback.png',
      tip: 'Leave feedback',
      type: PollTypesIntegerMap[FEEDBACK],
    },
    // type 102
    QQ_TWO_IMAGE_SINGLE_CHOICE: {
      BELabel: 'two-image single choice',
      label: 'Two image choice',
      icon: 'poll-image-choice-two',
      templateImg: 'example-question-two-image-choice.png',
      tip: 'Pick between 2 images',
      type: SegmentationTypesIntegerMap[TWO_IMAGE_SINGLE_CHOICE],
      isQQ: true,
    },
    // type 103
    QQ_THREE_IMAGE_SINGLE_CHOICE: {
      BELabel: 'three-image single choice',
      label: 'Three image choice',
      icon: 'poll-image-choice-three',
      templateImg: 'example-question-three-image-choice.png',
      tip: 'Pick between 3 images based on a lead image',
      type: SegmentationTypesIntegerMap[THREE_IMAGE_SINGLE_CHOICE],
      isQQ: true,
    },
    // type 104
    QQ_FOUR_IMAGE_SINGLE_CHOICE: {
      BELabel: 'four-image single choice',
      label: 'Four image choice',
      icon: 'poll-image-choice-four',
      templateImg: 'example-question-four-image-choice.png',
      tip: 'Pick between 4 images',
      type: SegmentationTypesIntegerMap[FOUR_IMAGE_SINGLE_CHOICE],
      isQQ: true,
    },
    // type 105
    QQ_MULTIPLE_IMAGE_MULTIPLE_CHOICE: {
      BELabel: 'multiple-image multiple choice',
      label: 'Multiple image choice',
      icon: 'poll-image-choice-multiple',
      templateImg: 'example-question-image-choice-multiple.png',
      tip: 'Select multiple choices',
      type: SegmentationTypesIntegerMap[MULTIPLE_IMAGE_MULTIPLE_CHOICE],
      isQQ: true,
    },
    // type 1001
    DEMOGRAPHIC_AGE: {
      BELabel: 'age',
      label: DemographicQuestionLabels[DEMOGRAPHIC_AGE],
      icon: 'user-lock',
      templateImg: 'example-demographic-age.png',
      defaultLeadImg: 'tooltip-demographic-age.png',
      tip: 'What is your age?',
      type: DemographicsTypesIntegerMap[DEMOGRAPHIC_AGE],
      isDemographic: true,
    },
    // type 1002
    DEMOGRAPHIC_ETHNICITY: {
      BELabel: 'ethnicity',
      label: DemographicQuestionLabels[DEMOGRAPHIC_ETHNICITY],
      icon: 'user-lock',
      templateImg: 'example-demographic-ethnicity.png',
      defaultLeadImg: null,
      tip: 'How do you describe your ethnicity?',
      type: DemographicsTypesIntegerMap[DEMOGRAPHIC_ETHNICITY],
      isDemographic: true,
    },
    // type 1003
    DEMOGRAPHIC_GENDER: {
      BELabel: 'gender',
      label: DemographicQuestionLabels[DEMOGRAPHIC_GENDER],
      icon: 'user-lock',
      templateImg: 'example-demographic-gender.png',
      defaultLeadImg: 'tooltip-demographic-gender.png',
      tip: 'What gender do you identify as?',
      type: DemographicsTypesIntegerMap[DEMOGRAPHIC_GENDER],
      isDemographic: true,
    },
    // type 1004
    DEMOGRAPHIC_SEXUALITY: {
      BELabel: 'sexuality',
      label: DemographicQuestionLabels[DEMOGRAPHIC_SEXUALITY],
      icon: 'user-lock',
      templateImg: 'example-demographic-sexuality.png',
      defaultLeadImg: null,
      tip: 'What sexuality do you identify as?',
      type: DemographicsTypesIntegerMap[DEMOGRAPHIC_SEXUALITY],
      isDemographic: true,
    },
    // type 1005
    DEMOGRAPHIC_RELIGION: {
      BELabel: 'religion',
      label: DemographicQuestionLabels[DEMOGRAPHIC_RELIGION],
      icon: 'user-lock',
      templateImg: 'example-demographic-religion.png',
      defaultLeadImg: 'tooltip-demographic-religion.png',
      tip: 'What is your religion?',
      type: DemographicsTypesIntegerMap[DEMOGRAPHIC_RELIGION],
      isDemographic: true,
    },
    // type 1006
    DEMOGRAPHIC_HOUSEHOLD_NUMBER: {
      BELabel: 'household-number',
      label: DemographicQuestionLabels[DEMOGRAPHIC_HOUSEHOLD_NUMBER],
      icon: 'user-lock',
      templateImg: 'example-demographic-household-number.png',
      defaultLeadImg: 'tooltip-demographic-household-number.png',
      tip: 'How many people live in your household?',
      type: DemographicsTypesIntegerMap[DEMOGRAPHIC_HOUSEHOLD_NUMBER],
      isDemographic: true,
    },
    // type 1007
    DEMOGRAPHIC_HOUSEHOLD_INCOME: {
      BELabel: 'household-income',
      label: DemographicQuestionLabels[DEMOGRAPHIC_HOUSEHOLD_INCOME],
      icon: 'user-lock',
      templateImg: 'example-demographic-household-income.png',
      defaultLeadImg: 'tooltip-demographic-household-income.png',
      tip: 'What is your household income?',
      type: DemographicsTypesIntegerMap[DEMOGRAPHIC_HOUSEHOLD_INCOME],
      isDemographic: true,
    },
    // type 1008
    DEMOGRAPHIC_ACCOMMODATION_TYPE: {
      BELabel: 'accommodation-type',
      label: DemographicQuestionLabels[DEMOGRAPHIC_ACCOMMODATION_TYPE],
      icon: 'user-lock',
      templateImg: 'example-demographic-accommodation-type.png',
      defaultLeadImg: 'tooltip-demographic-accommodation-type.png',
      tip: 'What type of accommodation do you live in?',
      type: DemographicsTypesIntegerMap[DEMOGRAPHIC_ACCOMMODATION_TYPE],
      isDemographic: true,
    },
    // type 1009
    DEMOGRAPHIC_ACCOMMODATION_OWNERSHIP: {
      BELabel: 'accommodation-ownership',
      label: DemographicQuestionLabels[DEMOGRAPHIC_ACCOMMODATION_OWNERSHIP],
      icon: 'user-lock',
      templateImg: 'example-demographic-accommodation-ownership.png',
      defaultLeadImg: null,
      tip: 'Does your household own or rent this accommodation?',
      type: DemographicsTypesIntegerMap[DEMOGRAPHIC_ACCOMMODATION_OWNERSHIP],
      isDemographic: true,
    },
    // type 1010
    DEMOGRAPHIC_EMPLOYMENT: {
      BELabel: 'employment',
      label: DemographicQuestionLabels[DEMOGRAPHIC_EMPLOYMENT],
      icon: 'user-lock',
      templateImg: 'example-demographic-employment.png',
      defaultLeadImg: null,
      tip: 'What is your current state of employment?',
      type: DemographicsTypesIntegerMap[DEMOGRAPHIC_EMPLOYMENT],
      isDemographic: true,
    },
    // type 1100
    DEMOGRAPHIC_CUSTOM_TYPE: {
      BELabel: 'custom',
      label: DemographicQuestionLabels[DEMOGRAPHIC_CUSTOM_TYPE],
      icon: 'user-edit',
      templateImg: 'example-demographic-custom.png',
      defaultLeadImg: null,
      tip: 'Create a completely custom question from scratch',
      type: DemographicsTypesIntegerMap[DEMOGRAPHIC_CUSTOM_TYPE],
      isDemographic: true,
    },
  },
};

exports.getQuestionObject = getQuestionObject;
/**
 * Function assigns icon and label to the question,
 *  used in the question header
 */
function getQuestionObject(question, QUESTION_TYPES) {
  const clone = JSON.parse(JSON.stringify(QUESTION_TYPES));
  eachDeep(clone, (value, key) => {
    if (key === question.questionType && key !== value) {
      question.questionIcon = value.icon;
      question.type = value.label;
    }
  });

  return question;
}

exports.getEmoji = getEmoji;
/**
 * Function returns emoji object for provided slider value,
 *  used on feedback with slider question
 */
function getEmoji(value) {
  if (value <= 14) return QUESTION_EMOJI[0];
  if (value <= 28) return QUESTION_EMOJI[1];
  if (value <= 42) return QUESTION_EMOJI[2];
  if (value <= 56) return QUESTION_EMOJI[3];
  if (value <= 70) return QUESTION_EMOJI[4];
  if (value <= 84) return QUESTION_EMOJI[5];
  if (value <= 100) return QUESTION_EMOJI[6];
  return {};
}

exports.getEmojiStyle = getEmojiStyle;
/**
 * Function returns emoji style object for provided slider value,
 *  used on feedback with slider question
 */
function getEmojiStyle(value) {
  if (value <= 14) return QUESTION_EMOJI_STYLE[0];
  if (value <= 28) return QUESTION_EMOJI_STYLE[1];
  if (value <= 42) return QUESTION_EMOJI_STYLE[2];
  if (value <= 56) return QUESTION_EMOJI_STYLE[3];
  if (value <= 70) return QUESTION_EMOJI_STYLE[4];
  if (value <= 84) return QUESTION_EMOJI_STYLE[5];
  if (value <= 100) return QUESTION_EMOJI_STYLE[6];
  return {};
}

exports.LEAD_IMAGE_TYPES = {
  STANDARD_SINGLE_IMAGE: 'STANDARD_SINGLE_IMAGE',
  ZOOM_SINGLE_IMAGE: 'ZOOM_SINGLE_IMAGE',
  STANDARD_CAROUSEL: 'STANDARD_CAROUSEL',
};
