module.exports = function(){

  let _ = require('lodash');

  const EngagePhaseSize = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large'
  };

  const EngagePhaseType = {
      IN_PROGRESS: 'in progress',
      DISRUPTIVE: 'disruptive',
      FUTURE: 'future',
      DONE: 'done'
    };

  return {
    EngagePhaseSize: EngagePhaseSize,
    EngagePhaseSizeList: EngagePhaseSizeList,
    EngagePhaseSizeMap: EngagePhaseSizeMap,
    EngagePhaseType: EngagePhaseType,
    EngagePhaseTypeList: EngagePhaseTypeList,
    EngagePhaseTypeMap: EngagePhaseTypeMap,
  };
  /**
   *
   */
  function EngagePhaseSizeList(){
    return _.map(Object.keys(EngagePhaseSize), (key) => { return EngagePhaseSize[key]; })
  }
  /**
   *
   */
  function EngagePhaseSizeMap(){
    let aMap = {};
    aMap[EngagePhaseSize.SMALL] = {};
    aMap[EngagePhaseSize.SMALL].description = 'Small';
    aMap[EngagePhaseSize.SMALL].value = 0;

    aMap[EngagePhaseSize.MEDIUM] = {};
    aMap[EngagePhaseSize.MEDIUM].description = 'Medium';
    aMap[EngagePhaseSize.MEDIUM].value = 1;

    aMap[EngagePhaseSize.LARGE] = {};
    aMap[EngagePhaseSize.LARGE].description = 'Large';
    aMap[EngagePhaseSize.LARGE].value = 2;
    return aMap;
  }
  /**
   *
   */
  function EngagePhaseTypeList(){
    return _.map(Object.keys(EngagePhaseType), (key) => { return EngagePhaseType[key]; })
  }
  /**
   *
   */
  function EngagePhaseTypeMap(){
    let aMap = {};
    aMap[EngagePhaseType.IN_PROGRESS] = {};
    aMap[EngagePhaseType.IN_PROGRESS].description = 'In Progress';
    aMap[EngagePhaseType.IN_PROGRESS].value = 0;

    aMap[EngagePhaseType.DISRUPTIVE] = {};
    aMap[EngagePhaseType.DISRUPTIVE].description = 'Disruptive';
    aMap[EngagePhaseType.DISRUPTIVE].value = 1;

    aMap[EngagePhaseType.FUTURE] = {};
    aMap[EngagePhaseType.FUTURE].description = 'Future';
    aMap[EngagePhaseType.FUTURE].value = 2;

    aMap[EngagePhaseType.DONE] = {};
    aMap[EngagePhaseType.DONE].description = 'Done';
    aMap[EngagePhaseType.DONE].value = 3;
    return aMap;
  }
};
