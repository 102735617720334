import React from 'react';
import PropTypes from 'prop-types';
import UpdateDetailsRow from './UpdateDetailsRow';

function PollUpdateDetails(props) {
  const { pollValidation, sectionLabel } = props;
  const { validation, questions } = pollValidation;

  return (
    <table>
      <thead>
        <tr>
          <th>{sectionLabel}</th>
          {validation.languages.map((item, index) => (
            <th key={`${item.language}-${index}`}>
              <abbr title={item.languageTitleEN}>{item.language}</abbr>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {questions &&
          questions.map((question) => (
            <UpdateDetailsRow
              key={question.validation._id}
              rowLabel={question.validation.questionText}
              languageData={question.validation.thisTreeLanguageData}
            />
          ))}
      </tbody>
    </table>
  );
}

PollUpdateDetails.propTypes = {
  pollValidation: PropTypes.shape({
    validation: PropTypes.shape({
      thisTreeLanguageData: PropTypes.array.isRequired,
    }).isRequired,
    questions: PropTypes.array.isRequired,
  }).isRequired,
  sectionLabel: PropTypes.string.isRequired,
};

export default PollUpdateDetails;
