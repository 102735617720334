import Agent from './agent';

const requests = Agent.requests;
const ENDPOINT = 'timeline';
const SUB_ENDPOINT = 'phase';
const SUB_SUB_ENDPOINT = 'update';

const TimelineAgent = {
  // ### PHASES
  createPhase: (projectId, data) => requests.post(`/projects/${projectId}/${ENDPOINT}/${SUB_ENDPOINT}`, data),
  reorderPhases: (projectId, data) => requests.post(`/projects/${projectId}/${ENDPOINT}/reorder`, data),
  removePhase: (projectId, phaseId) => requests.remove(`/projects/${projectId}/${ENDPOINT}/${SUB_ENDPOINT}/${phaseId}`),
  updatePhase: (projectId, phaseId, data) =>
    requests.patch(`/projects/${projectId}/${ENDPOINT}/${SUB_ENDPOINT}/${phaseId}`, data),

  // ### UPDATES (STAGES)
  createUpdate: (projectId, phaseId, data) =>
    requests.post(`/projects/${projectId}/${ENDPOINT}/${SUB_ENDPOINT}/${phaseId}/${SUB_SUB_ENDPOINT}`, data),
  reorderUpdates: (projectId, phaseId, data) =>
    requests.post(`/projects/${projectId}/${ENDPOINT}/${SUB_ENDPOINT}/${phaseId}/${SUB_SUB_ENDPOINT}/reorder`, data),
  updateStage: (projectId, phaseId, updateId, data) =>
    requests.patch(
      `/projects/${projectId}/${ENDPOINT}/${SUB_ENDPOINT}/${phaseId}/${SUB_SUB_ENDPOINT}/${updateId}`,
      data,
    ),
  removeUpdate: (projectId, phaseId, updateId) =>
    requests.remove(`/projects/${projectId}/${ENDPOINT}/${SUB_ENDPOINT}/${phaseId}/${SUB_SUB_ENDPOINT}/${updateId}`),
  uploadUpdateResource: (projectId, phaseId, stageId, file, progress) =>
    requests.uploadImage(
      `/projects/${projectId}/${ENDPOINT}/${SUB_ENDPOINT}/${phaseId}/${SUB_SUB_ENDPOINT}/${stageId}/resource`,
      'project',
      file,
      progress,
    ),
  removeUpdateResource: (projectId, phaseId, stageId) =>
    requests.remove(
      `/projects/${projectId}/${ENDPOINT}/${SUB_ENDPOINT}/${phaseId}/${SUB_SUB_ENDPOINT}/${stageId}/resource`,
    ),
};

export default TimelineAgent;
