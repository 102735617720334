import { reaction, action, observable, computed, makeObservable } from 'mobx';

import AgentPoll from '../common/agent';

const Agent = AgentPoll.GalleryAgent;

// const ITEM_LABEL = 'influenced decision';
const REORDER_ERROR = `Error reordering images`;
const RESOURCE_DELETE_ERROR = 'Error deleting image';
const CAPTION_UPDATED_SUCCESS = 'Caption updated';
const CAPTION_REMOVED_SUCCESS = 'Caption removed';
const CAPTION_ERROR = `Error updating image caption`;

export default class GalleryStore {
  _loading = false;

  _initialized = false;

  _error = null;

  _items = [];

  constructor(rootStore, projectStore) {
    makeObservable(this, {
      _items: observable,
      _loading: observable,
      _initialized: observable,
      _error: observable,

      resetFlags: action,

      gallery: computed,
      isLoading: computed,
      isInitialized: computed,
      error: computed,

      loadData: action,
    });

    this.rootStore = rootStore;
    this.projectStore = projectStore;
    reaction(
      () => this.projectStore.isInitialized,
      () => this.loadData(this.projectStore.project.gallery, this.projectStore.projectDefault.gallery),
    );
  }

  get isLoading() {
    return this._loading;
  }

  get isInitialized() {
    return this._initialized;
  }

  get error() {
    return this._error;
  }

  get gallery() {
    return this._items;
  }

  /**
   * Load and prepare data already loaded in ProjectStore
   * @param items
   */
  loadData(gallery, galleryDefault) {
    const tmpGallery = [];
    gallery.map((gal) => {
      const defaultGallery = galleryDefault.find((galleryDef) => galleryDef._id === gal._id);
      tmpGallery.push({
        data: gal,
        defaultData: defaultGallery,
      });
      return gal;
    });
    this._items = tmpGallery;

    this._initialized = true;
  }

  async reorder(data) {
    this._loading = false;
    this._error = null;

    try {
      const newOrder = data.map((item) => item.data._id);
      await Agent.reorder(this.projectStore.project._id, newOrder);
      await this.projectStore.refresh();
    } catch (err) {
      this._error = err;
      this.rootStore.toastrStore.error(REORDER_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  /**
   * Upload decision resource
   * @param decisionId
   * @param file
   * @param progress
   * @returns {Promise<boolean> | Promise<T> | *}
   */
  uploadResource(file, progress) {
    // console.log('UPLOAD STORE');
    this._loading = true;
    this._error = null;
    return Agent.uploadResource(this.projectStore.project._id, file, progress)
      .then(() => this.refresh())
      .finally(() => (this._loading = false));
  }

  /**
   *
   * @param data - {resource: <videoURL>>}
   * @returns {*}
   */
  postVideo = (data) => {
    // console.log('Post video');
    this._loading = true;
    this._error = null;
    return Agent.postVideo(this.projectStore.project._id, data)
      .then(() => this.refresh())
      .finally(() => (this._loading = false));
  };

  /**
   * remove decision resource
   * @param decisionId
   * @returns {Promise<T>}
   */
  async removeResource(itemId) {
    try {
      await Agent.removeResource(this.projectStore.project._id, itemId);
      await this.projectStore.refresh();
    } catch (err) {
      this._error = err;
      this.rootStore.toastrStore.error(RESOURCE_DELETE_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  /**
   *
   */
  updateCaption = (itemId, caption) => {
    this._loading = true;
    this._error = null;
    return Agent.uploadCaption(this.projectStore.project._id, itemId, { caption })
      .then(() => {
        if (caption) {
          this.rootStore.toastrStore.success(CAPTION_UPDATED_SUCCESS);
        } else {
          this.rootStore.toastrStore.success(CAPTION_REMOVED_SUCCESS);
        }

        this.refresh();
      })
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(CAPTION_ERROR, null, err);
      });
  };

  refresh = () => {
    this.projectStore.refresh();
  };

  resetFlags = () => {
    this._loading = false;
  };
}
