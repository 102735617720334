import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ConditionalLogicUtil, { CONDITION_INPUT_TYPE, SELECT_OPTION } from '../../ConditionalLogicUtil';
import NoConditionsComponent from './NoConditionsComponent';
import ConditionalFooter from './ConditionalFooter';
import Rule from './rule/Rule';
import DestinationSelector from './DestinationSelector';

function ConditionalLogic(props) {
  const { formValues, setFormValues, rules, setRules, question, questions, show, choiceFieldNames = [] } = props;

  const [questionChoices, setQuestionChoices] = useState([]);
  const [choiceInputType, setChoiceInputType] = useState(CONDITION_INPUT_TYPE.SELECT);

  useEffect(() => {
    if (formValues && question) {
      const conditionInputType = ConditionalLogicUtil.getConditionalInputType(question.questionType);
      setChoiceInputType(conditionInputType);

      const preparedChoices = ConditionalLogicUtil.prepareAvailableConditionChoices(formValues, choiceFieldNames);

      if (preparedChoices.length > 0) setQuestionChoices([SELECT_OPTION, ...preparedChoices]);
    }
  }, [choiceFieldNames, formValues, question]);

  function addRule() {
    const newState = ConditionalLogicUtil.addRule(formValues, rules, question._id, question.questionType);
    setFormValues(newState.newFormValues);
    setRules(newState.newRules);
  }

  function removeRule(ruleId) {
    const newState = ConditionalLogicUtil.removeRule(formValues, rules, ruleId, question._id);
    setFormValues(newState.newFormValues);
    setRules(newState.newRules);
  }

  function refreshRules(ruleId, newConditions) {
    let refreshedRules = [];

    // If condition was the last in rule remove that rule
    if (!newConditions.length) {
      refreshedRules = rules.filter((rule) => rule.id !== ruleId);
    } else {
      const changedRule = rules.find((rule) => rule.id === ruleId);
      changedRule.condition = newConditions;

      rules.forEach((rule) => {
        if (rule.id === changedRule.id) {
          refreshedRules.push(changedRule);
        } else {
          refreshedRules.push(rule);
        }
      });
    }

    return refreshedRules;
  }

  /** This method is called in two situations:
   * 1) when adding additional condition to rule
   * 2) when user clicks on "Add rule" in initial form
   * (because at that point first rule without conditions is already created)
   */
  function addCondition(conditions, ruleId) {
    const newState = ConditionalLogicUtil.addCondition(
      formValues,
      conditions,
      question._id,
      ruleId,
      question.questionType,
    );
    setFormValues(newState.newFormValues);

    const refreshedRules = refreshRules(ruleId, newState.newConditions);
    setRules([...refreshedRules]);
  }

  function removeCondition(conditionId, ruleId, conditions) {
    const newState = ConditionalLogicUtil.removeCondition(
      formValues,
      conditions,
      rules,
      conditionId,
      question._id,
      ruleId,
    );
    setFormValues(newState.newFormValues);

    let refreshedRules;
    // Rules are updated different way depending on whether removed condition was last one in last existing rule or not
    if (newState.newConditions) {
      refreshedRules = refreshRules(ruleId, newState.newConditions);
    } else if (newState.newRules) {
      refreshedRules = newState.newRules;
    }

    setRules(refreshedRules);
  }

  function removeConditionalLogic() {
    const newState = ConditionalLogicUtil.removeConditionalLogic(formValues, question._id);
    setFormValues(newState.newFormValues);
    setRules(newState.newRules);
  }

  function hasConditions() {
    return rules[0]?.condition?.length;
  }

  return (
    <div className='c-toggle-content__content' role='tabpanel' hidden={!show} data-testid='conditionalLogic-wrapper'>
      {!hasConditions() ? (
        <NoConditionsComponent
          formValues={formValues}
          rules={rules}
          question={question}
          questions={questions}
          onAddRule={addRule}
        />
      ) : (
        <div className='c-conditional'>
          {rules.map((rule, ruleIndex) => (
            <Rule
              key={rule.id}
              ruleIndex={ruleIndex}
              rule={rule}
              rules={rules}
              formValues={formValues}
              choiceInputType={choiceInputType}
              question={question}
              questions={questions}
              questionChoices={questionChoices}
              onAddCondition={addCondition}
              onRemoveCondition={removeCondition}
              onAddRule={addRule}
              onRemoveRule={removeRule}
            />
          ))}
          <div className='c-conditional__rule c-conditional__rule--always'>
            <DestinationSelector
              fieldName={`${question._id}-else`}
              formValues={formValues}
              question={question}
              questions={questions}
              titleLabel1='If'
              titleLabelStrong='nothing else matches'
              titleLabel2='jump to question'
              hasNextOption={true}
              hasEndOfPollOption={true}
              markSelectedDestinations={true}
            />
          </div>
          <ConditionalFooter onRemoveConditionalLogic={removeConditionalLogic} />
        </div>
      )}
    </div>
  );
}

ConditionalLogic.propTypes = {
  formValues: PropTypes.object,
  setFormValues: PropTypes.func.isRequired,
  rules: PropTypes.array,
  setRules: PropTypes.func.isRequired,
  question: PropTypes.object,
  questions: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
  choiceFieldNames: PropTypes.array,
};

export default ConditionalLogic;
