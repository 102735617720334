module.exports = function () {
  const roadmapOverlayStyle = {
    strokeColor: '#070047',
    strokeOpacity: 0.7,
    strokeWeight: 3,
    fillColor: '#070047',
    fillOpacity: 0.1,
    clickable: false,
    draggable: true,
    editable: true,
    zIndex: 2,
  };

  const satelliteOverlayStyle = {
    strokeColor: '#070047',
    strokeOpacity: 0.7,
    strokeWeight: 3,
    fillColor: '#ffffff',
    fillOpacity: 0.3,
    clickable: false,
    draggable: true,
    editable: true,
    zIndex: 2,
  };

  const markerStyle = {
    icon: '/assets-v2/images/pin-purple.png',
    zIndex: 2,
    draggable: true,
  };

  const staticMapParams = {
    LATITUDE: 'LATITUDE',
    LONGITUDE: 'LONGITUDE',
    MARKER_ICON: 'MARKER_ICON',
  };

  const staticMapTemplate = `https://maps.googleapis.com/maps/api/staticmap?size=312x312&style=feature:poi.medical%7Celement:geometry.fill%visibility:off&style=feature:poi.place_of_worship%7Celement:all%visibility:off&style=feature:poi.school%7Celement:all%visibility:off&style=feature:poi.sports_complex%7Celement:all%visibility:off&style=feature:poi.sports_complex%7Celement:geometry%visibility:off&style=feature:poi.sports_complex%7Celement:labels%visibility:on&markers=icon:${staticMapParams.MARKER_ICON}%7C${staticMapParams.LATITUDE},${staticMapParams.LONGITUDE}&key=AIzaSyA9aEbiby0sOo0nqhEoPpd3jfJkKobDoJM`;

  const locationMapStyle = [
    {
      featureType: 'poi.medical',
      elementType: 'geometry.fill',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.place_of_worship',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.school',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.sports_complex',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.sports_complex',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.sports_complex',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
  ];

  // Shape types MUST be aligned with google map API constants for overlay types
  const shapeTypes = {
    CIRCLE: 'circle', // google.maps.drawing.OverlayType.CIRCLE
    POLYGON: 'polygon', // google.maps.drawing.OverlayType.POLYGON
    RECTANGLE: 'rectangle', // google.maps.drawing.OverlayType.RECTANGLE
    MARKER: 'marker', // google.maps.drawing.OverlayType.MARKER
  };

  const mapOptions = {
    zoom: 14,
    lat: 51.508859,
    lng: -0.111376,
  };

  return {
    roadmapOverlayStyle: roadmapOverlayStyle,
    satelliteOverlayStyle: satelliteOverlayStyle,
    markerStyle: markerStyle,
    staticMapParams: staticMapParams,
    staticMapTemplate: staticMapTemplate,
    locationMapStyle: locationMapStyle,
    shapeTypes: shapeTypes,
    mapOptions: mapOptions,
  };
};
