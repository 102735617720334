import React from 'react';
import PropTypes from 'prop-types';
import PillActionButton from '../buttons/PillActionButton';
import SvgIcon from '../SvgIcon';

export default function SearchBar(props) {
  const {
    value,
    onChange,
    onClear,
    onKeywordRemove,
    keywords,
    suggestedKeywords,
    onKeyPress,
    onSuggestionSelect,
    placeholder,
  } = props;
  return (
    <div className='c-field l-form__item c-field--label-hidden'>
      <label className='c-field__label' htmlFor='library-search'>
        Search
      </label>
      <div className='c-field__group'>
        <div
          className={`c-suggest c-suggest--floating js-suggest ${
            suggestedKeywords?.length > 0 ? 'c-suggest--active' : ''
          }`}
        >
          <div className='c-suggest__search o-input-icon o-input-clear'>
            <input
              type='text'
              id='library-search'
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              onKeyPress={onKeyPress}
            />
            <SvgIcon icon='search' />
            {value && (
              <button className='o-button' onClick={onClear}>
                <SvgIcon icon='times' />
                <span className='o-label'>Clear</span>
              </button>
            )}
          </div>
          <div className='c-suggest__content'>
            <ul className='c-suggest__items'>
              {suggestedKeywords?.map((suggestedKeyword) => (
                <li key={suggestedKeyword} className='c-suggest__item'>
                  <button className='c-suggest__link' onClick={() => onSuggestionSelect(suggestedKeyword)}>
                    {suggestedKeyword}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {keywords?.length > 0 && (
          <div className='c-pills'>
            {[...keywords].map((item, index) => (
              <PillActionButton id={`${index}-${item}`} key={index} label={item} onKeywordRemove={onKeywordRemove} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

SearchBar.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onKeywordRemove: PropTypes.func.isRequired,
  keywords: PropTypes.arrayOf(PropTypes.string),
  suggestedKeywords: PropTypes.arrayOf(PropTypes.string),
  onKeyPress: PropTypes.func.isRequired,
  onSuggestionSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};
