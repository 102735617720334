import React from 'react';
import { inject, observer } from 'mobx-react';
import SvgIcon from '../SvgIcon';
import Agent from '../../agent';

const ENGAGE_LANGUAGES = require('../../../shared/engage/engage-languages')().engageLanguages();

function LanguageSelector(props) {
  const { ProjectStore, GeneralStore } = props;

  const selectedLanguage = Agent.getLanguage();

  const onLanguageChange = (event) => {
    ProjectStore.changeLanguage(event.target.value);

    if (!localStorage.getItem('languageChanged')) {
      localStorage.setItem('languageChanged', true);
      GeneralStore.isTranslationInstructionVisible = true;
    }
  };

  const getLanguage = (lngCode) => {
    const lng = ENGAGE_LANGUAGES.find((item) => item.languageCode === lngCode);
    return lng;
  };

  function showLanguageSelector() {
    return ProjectStore.project.languages && ProjectStore.project.languages.length > 1;
  }

  return (
    <React.Fragment>
      {showLanguageSelector() && (
        <div className='c-nav-drawer__items' data-testid='language-selector-container'>
          <div className='o-select o-select--floating-label'>
            <label className='o-select__label' htmlFor='input-page-language'>
              Content language
            </label>
            <SvgIcon icon='angle-down' />
            <select id='input-page-language' defaultValue={selectedLanguage} onChange={onLanguageChange}>
              {ProjectStore.project.languages &&
                ProjectStore.project.languages.map((lang, index) => {
                  return (
                    getLanguage(lang) && (
                      <option value={lang} key={`${lang}-${index}`}>
                        {getLanguage(lang).languageTitleEN}
                      </option>
                    )
                  );
                })}
            </select>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default inject((root) => ({
  ProjectStore: root.RootStore.projectStore,
  GeneralStore: root.RootStore.generalStore,
}))(observer(LanguageSelector));
