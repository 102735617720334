import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import MapSearchBox from './MapSearchBox';
import MapOverlayInfo from './MapOverlayInfo';
import GOOGLE_MAP_ENUMS from '../../../shared/engage/engage-google-map-enums';
import MapUtils from './MapUtils';

const google = window.google;

const shapeTypes = GOOGLE_MAP_ENUMS().shapeTypes;
const mapOptions = GOOGLE_MAP_ENUMS().mapOptions;

function MapComponent(props) {
  const {
    id,
    label,
    mapData,
    setMapData,
    mapZoom,
    setMapZoom,
    mapType,
    setMapType,
    mapDescription,
    clearMapText,
    allowedDrawTools,
    showMapTypeControl = false,
    showZoomControl = true,
    showFullscreenControl = false,
    showScaleControl = false,
    showStreetViewControl = false,
    includeSearchControl = false,
    mapStyle,
    memorizeZoomLevel = false,
    customMapUrl = null,
    clickableOverlays = false,
  } = props;

  const [map, setMap] = useState();
  const [definedArea, setDefinedArea] = useState();
  const [drawingManager, setDrawingManager] = useState();

  const [drawnAreaWidth, setDrawnAreaWidth] = useState('');
  const [drawnAreaHeight, setDrawnAreaHeight] = useState('');
  const [drawnAreaRadius, setDrawnAreaRadius] = useState('');
  const [drawnAreaUnit, setDrawnAreaUnit] = useState('');

  const [showSearchControl, setShowSearchControl] = useState(false);
  const [customMapLayer, setCustomMapLayer] = useState();

  useEffect(() => {
    const existingCustomMapUrl = MapUtils.getCustomMapUrl(customMapLayer);

    if (!map || existingCustomMapUrl === customMapUrl) return;

    // Draw custom map layer on the map
    const newCustomMapLayer = MapUtils.drawCustomMapLayer(map, customMapUrl, customMapLayer);
    setCustomMapLayer(newCustomMapLayer);

    // Set new map bounds so both custom map and defined area layers are visible
    if (definedArea || newCustomMapLayer) MapUtils.setMapBounds(definedArea, newCustomMapLayer, map);

    // Each time custom map URL changes enable or disable annotate area draging
    MapUtils.setDefinedAreaInteractivity(map, definedArea, customMapUrl);
  }, [map, customMapUrl, customMapLayer, definedArea]);

  // Handle event triggered by user adding new overlay or user editing already existing overlay
  const handleOverlayEvents = useCallback(
    (area, shapeType) => {
      if (!area) return;

      let circle = null;
      let rectangle = null;
      let polygon = null;
      let point = null;

      switch (shapeType) {
        case shapeTypes.CIRCLE: {
          circle = MapUtils.prepareCircleData(area);
          MapUtils.determineCircleDrawnAreaInfo(area, setDrawnAreaRadius, setDrawnAreaUnit);
          break;
        }
        case shapeTypes.RECTANGLE: {
          const rectangleBounds = area.getBounds();

          rectangle = MapUtils.prepareRectangleData(rectangleBounds);
          MapUtils.determinePolygonDrawnAreaInfo(
            rectangleBounds,
            setDrawnAreaWidth,
            setDrawnAreaHeight,
            setDrawnAreaUnit,
          );
          break;
        }
        case shapeTypes.POLYGON: {
          polygon = MapUtils.preparePolygonData(area);

          const polygonBounds = new google.maps.LatLngBounds();
          polygon.path.forEach((coord) => {
            polygonBounds.extend(coord);
          });

          MapUtils.determinePolygonDrawnAreaInfo(
            polygonBounds,
            setDrawnAreaWidth,
            setDrawnAreaHeight,
            setDrawnAreaUnit,
          );
          break;
        }
        case shapeTypes.MARKER: {
          point = MapUtils.preparePointData(area);
          break;
        }
        default:
          break;
      }

      const newMapData = {};
      newMapData.circle = circle;
      newMapData.rectangle = rectangle;
      newMapData.polygon = polygon;
      newMapData.point = point;
      newMapData.shape = shapeType;

      setMapData(newMapData);
    },
    [setMapData],
  );

  useEffect(() => {
    // Create map and drawing tool instances and draw defined area overlay
    if (!map && mapData) {
      // Set up map instance
      const mapInstance = MapUtils.setUpMapInstance(
        id,
        mapOptions,
        mapData,
        mapZoom,
        mapType,
        mapStyle,
        showMapTypeControl,
        showZoomControl,
        showFullscreenControl,
        showScaleControl,
        showStreetViewControl,
      );
      setMap(mapInstance);

      // Set drawing manager instance
      const drawingManagerInstance = MapUtils.setUpDrawingManagerInstance(
        mapInstance,
        mapData,
        allowedDrawTools,
        clickableOverlays,
      );
      // Set drawing manager event listeners
      MapUtils.attachDrawingManagerEventHandlers(drawingManagerInstance, handleOverlayEvents, setDefinedArea);
      setDrawingManager(drawingManagerInstance);

      // Set up search control if required
      if (includeSearchControl) MapUtils.setUpSearchControl(id, mapInstance);

      // If area is defined draw overlay
      const drawnArea = MapUtils.drawDefinedAreaOverlay(mapInstance, mapData, clickableOverlays);

      if (drawnArea) {
        // After adding defined area overlay adjust map position and zoom level
        if (drawnArea.bounds)
          MapUtils.setMapPositionAndZoomLevel(mapInstance, drawnArea.bounds, mapZoom, memorizeZoomLevel);

        if (drawnArea.shape)
          setDefinedArea({ overlay: drawnArea.shape, type: mapData.shape, bounds: drawnArea.bounds });
      }

      // Set up event listeners for map type change and zoom change
      MapUtils.attachMapEventHandlers(
        mapInstance,
        mapData,
        drawingManagerInstance,
        memorizeZoomLevel,
        setMapZoom,
        setMapType,
        handleOverlayEvents,
      );
    }
  }, [
    id,
    map,
    mapData,
    showMapTypeControl,
    includeSearchControl,
    showZoomControl,
    showFullscreenControl,
    showScaleControl,
    showStreetViewControl,
    allowedDrawTools,
    mapStyle,
    memorizeZoomLevel,
    clickableOverlays,
    handleOverlayEvents,
    mapZoom,
    mapType,
    setMapZoom,
    setMapType,
  ]);

  useEffect(() => {
    if (definedArea) {
      // Set event listeners for newly added overlay
      MapUtils.attachDefinedAreaEventHandlers(definedArea, handleOverlayEvents);

      // Calculate defined area dimensions
      switch (definedArea.type) {
        case shapeTypes.CIRCLE: {
          MapUtils.determineCircleDrawnAreaInfo(definedArea.overlay, setDrawnAreaRadius, setDrawnAreaUnit);
          break;
        }
        case shapeTypes.POLYGON: {
          const polygonBounds = new google.maps.LatLngBounds();
          definedArea.overlay.getPath().forEach((coord) => {
            polygonBounds.extend(coord);
          });
          MapUtils.determinePolygonDrawnAreaInfo(
            polygonBounds,
            setDrawnAreaWidth,
            setDrawnAreaHeight,
            setDrawnAreaUnit,
          );
          break;
        }
        case shapeTypes.RECTANGLE: {
          MapUtils.determinePolygonDrawnAreaInfo(
            definedArea.overlay.getBounds(),
            setDrawnAreaWidth,
            setDrawnAreaHeight,
            setDrawnAreaUnit,
          );
          break;
        }
        default:
          break;
      }

      setShowSearchControl(false);
    } else {
      // Clear overlay info after overlay is removed
      setDrawnAreaRadius('');
      setDrawnAreaUnit('');
      setDrawnAreaHeight('');
      setDrawnAreaWidth('');

      setShowSearchControl(true);
    }
  }, [definedArea, handleOverlayEvents]);

  function clearShape() {
    // Show drawing tool and reset it's drawing mode
    drawingManager.setOptions({
      drawingControl: true,
      drawingMode: null,
    });

    // Detach removed overlay from map, clear all event listeners on removed overlay
    google.maps.event.clearInstanceListeners(definedArea.overlay);
    definedArea.overlay.setMap(null);
    setDefinedArea(null);

    // Clear defined area object
    const newMapData = {};
    newMapData.shape = '';
    newMapData.circle = null;
    newMapData.rectangle = null;
    newMapData.polygon = null;
    newMapData.point = null;
    newMapData.mapTypeId = mapData.mapTypeId;
    setMapData(newMapData);
  }

  return (
    <div className='c-field l-form__item '>
      <label className='c-field__label'>{label}</label>
      <div className='c-field__group'>
        <div className='o-map'>
          {includeSearchControl && <MapSearchBox id={id} show={showSearchControl} />}
          <div id={`map-${id}`} className='o-map__content' />
          <p className='o-map__info' />
        </div>

        <MapOverlayInfo
          mapDescription={mapDescription}
          drawnShape={definedArea}
          drawnAreaRadius={drawnAreaRadius}
          drawnAreaUnit={drawnAreaUnit}
          drawnAreaWidth={drawnAreaWidth}
          drawnAreaHeight={drawnAreaHeight}
          clearMapText={clearMapText}
          clearShape={clearShape}
        />
      </div>
    </div>
  );
}

MapComponent.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  mapData: PropTypes.object,
  setMapData: PropTypes.func,
  mapZoom: PropTypes.number,
  setMapZoom: PropTypes.func,
  mapType: PropTypes.string,
  setMapType: PropTypes.func,
  mapDescription: PropTypes.string.isRequired,
  clearMapText: PropTypes.string.isRequired,
  allowedDrawTools: PropTypes.array.isRequired,
  showMapTypeControl: PropTypes.bool,
  showZoomControl: PropTypes.bool,
  showFullscreenControl: PropTypes.bool,
  showScaleControl: PropTypes.bool,
  showStreetViewControl: PropTypes.bool,
  includeSearchControl: PropTypes.bool,
  mapStyle: PropTypes.array,
  memorizeZoomLevel: PropTypes.bool,
  customMapUrl: PropTypes.string,
  clickableOverlays: PropTypes.bool,
};

export default MapComponent;
