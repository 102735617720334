import React from 'react';
import PropTypes from 'prop-types';
import { QUESTION_CHARACTER_LIMIT } from 'common/constants/QuestionConstants';
import { SELECT_OPTION, NEXT_QUESTION_OPTION, END_OF_POLL_QUESTION_OPTION } from '../../ConditionalLogicUtil';
import FieldDescription from '../form-elements/fieldDescription/FieldDescription';
import SvgIcon from '../SvgIcon';

function SimpleDestinationSelector(props) {
  const {
    input,
    meta,
    fieldName,
    allowNull = true,
    formValues,
    question,
    questions,
    titleLabel1,
    titleLabelStrong,
    titleLabel2,
    hasSelectOption = false,
    hasNextOption = false,
    hasEndOfPollOption = false,
    markSelectedDestinations = false,
  } = props;

  function prepareOptionLabel(fielValue, q, index) {
    let questionLabel = `${index + 1}. ${q.questionText}` || `${index + 1}.`;

    if (q._id === question._id) questionLabel = `${index + 1}. (Current) ${q.questionText}`;

    // All questions that are already selected as rule 'jumpTo' destination or 'else' destination should be marked as selected
    if (markSelectedDestinations)
      Object.entries(formValues).forEach((item) => {
        const matchingOption =
          (fieldName.includes('-jumpTo') && item[0].includes('-else')) ||
          (fieldName.includes('-else') && item[0].includes('-jumpTo')) ||
          (fieldName.includes('-jumpTo') && item[0].includes('-jumpTo'));
        if (matchingOption && item[1] === q._id && fielValue !== q._id)
          questionLabel = `${index + 1}. (Already selected) ${q.questionText}`;
      });

    if (questionLabel.length > QUESTION_CHARACTER_LIMIT)
      questionLabel = `${questionLabel.substring(0, QUESTION_CHARACTER_LIMIT)}...`;

    return questionLabel;
  }

  function isOptionDisabled(optionQuestion) {
    let res = false;
    if (optionQuestion._id === question._id) res = true;

    Object.entries(formValues).forEach((item) => {
      if ((item[0].includes('-jumpTo') || item[0].includes('-else')) && item[1] === optionQuestion._id) res = true;
    });

    return res;
  }

  function getContainerClass() {
    const errorClass = meta.error && meta.touched ? 'is-error' : '';
    return `c-field l-form__item is-required ${
      input.value || (input.value === null && allowNull) ? 'is-populated' : ''
    } ${errorClass}`;
  }

  return (
    <div
      className='c-conditional__option c-conditional__option--action'
      data-testid='simpleDestinationSelector-wrapper'
    >
      <div className='conditional__title'>
        {titleLabel1 ? `${titleLabel1} ` : null}
        {titleLabelStrong ? <strong>{titleLabelStrong}</strong> : null}
        {titleLabel2 ? ` ${titleLabel2}` : null}
      </div>
      <div className='conditional__options'>
        <div className={getContainerClass()}>
          <div className='c-field__group'>
            <div className='o-select'>
              <SvgIcon icon='angle-down' />
              <select {...input}>
                {hasSelectOption && <option value={SELECT_OPTION[0]}>{SELECT_OPTION[1]}</option>}
                {hasNextOption && <option value={NEXT_QUESTION_OPTION[0]}>{NEXT_QUESTION_OPTION[1]}</option>}
                {questions.map((q, index) => (
                  <option key={q._id} value={q._id} disabled={isOptionDisabled(q)}>
                    {prepareOptionLabel(input.value, q, index)}
                  </option>
                ))}
                {hasEndOfPollOption && (
                  <option value={END_OF_POLL_QUESTION_OPTION[0]}>{END_OF_POLL_QUESTION_OPTION[1]}</option>
                )}
              </select>
            </div>
            <FieldDescription meta={meta} val={input.value} />
          </div>
        </div>
      </div>
    </div>
  );
}

SimpleDestinationSelector.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  allowNull: PropTypes.bool,
  formValues: PropTypes.object.isRequired,
  question: PropTypes.object,
  questions: PropTypes.array.isRequired,
  titleLabel1: PropTypes.string,
  titleLabelStrong: PropTypes.string,
  titleLabel2: PropTypes.string,
  hasSelectOption: PropTypes.bool,
  hasNextOption: PropTypes.bool,
  hasEndOfPollOption: PropTypes.bool,
  markSelectedDestinations: PropTypes.bool,
  isRequired: PropTypes.bool,
};

export default SimpleDestinationSelector;
