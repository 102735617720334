module.exports = function(){

  let _ = require('lodash');

  const EngagePanelType = {
    BANNER: 'banner',
  };

  const EngagePanelLocationType = {
      ALL: 'all',
      HOME: 'home',
      NEWS_AND_MEDIA: 'newsAndMedia',
      POLLS: 'polls',
      TIMELINE: 'timeline'
    };

  return {
    EngagePanelType: EngagePanelType,
    engagePanelTypeList: engagePanelTypeList,
    EngagePanelLocationType: EngagePanelLocationType,
    engagePanelLocationTypeList: engagePanelLocationTypeList
  };

  function engagePanelTypeList(){
    return _.map(Object.keys(EngagePanelType), (key) => { return EngagePanelType[key]; });
  }

  function engagePanelLocationTypeList(){
    return _.map(Object.keys(EngagePanelLocationType), (key) => { return EngagePanelLocationType[key]; });
  }

};
