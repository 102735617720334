import React, { forwardRef, useImperativeHandle, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import AnnotateImageCluster from 'common/components/annotateQuestion/annotateImageCluster/AnnotateImageCluster';
import ImageUtils from '../../../../../common/ImageUtils';
import { IMAGE_FORMATS } from '../../../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import usePanZoom from '../../../../../common/components/panZoomHook/usePanZoom';
import { ZOOM_IN, ZOOM_OUT } from '../../../../../common/components/panZoomHook/utils';
import SnapshotableImage from '../../../../../common/components/snapshotableImage/ScreenshotableImage';

const AnnotateImageArea = forwardRef((props, ref) => {
  const { resource, placedReactions, isExporting } = props;

  const { containerRef, dragRef, onWheel, onReset, onZoom, state } = usePanZoom();

  const [markers, setMarkers] = useState([]);
  const [openClusterId, setOpenClusterId] = useState('');

  // eslint-disable-next-line
  const delayedSetMarkersForZoomLevel = useCallback(
    debounce((scaleValue, allLevels) => {
      const availableZoomLevels = allLevels.map((level) => level.zoomLevel);
      const goal = scaleValue;

      if (availableZoomLevels.length > 0 && goal) {
        const closestZoomLevel = availableZoomLevels.reduce((prev, curr) =>
          Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev,
        );

        const zoomLevel = allLevels.find((level) => level.zoomLevel === closestZoomLevel);
        setMarkers([...zoomLevel.markers]);
      }
    }, 300),
    [],
  );

  useEffect(() => {
    delayedSetMarkersForZoomLevel(state.scale, placedReactions);
    setOpenClusterId('');
  }, [state.scale, delayedSetMarkersForZoomLevel, placedReactions]);

  const onZoomInButton = () => {
    const containerRect = containerRef.current.getBoundingClientRect();
    onZoom(containerRect.left + containerRect.width / 2, containerRect.top + containerRect.height / 2, ZOOM_IN);
  };

  const onResetButton = () => {
    onReset();
  };

  const onZoomOutButton = () => {
    const containerRect = containerRef.current.getBoundingClientRect();
    onZoom(containerRect.left + containerRect.width / 2, containerRect.top + containerRect.height / 2, ZOOM_OUT);
  };

  useImperativeHandle(ref, () => ({ onZoomInButton, onResetButton, onZoomOutButton }));

  return (
    <div className='c-result-annotate__content' ref={containerRef} onWheel={onWheel}>
      <div ref={dragRef} className='c-annotate-image'>
        <div
          className='c-annotate-image__group'
          style={{
            backgroundImage: `url(${ImageUtils.imageUrl(
              resource,
              IMAGE_FORMATS.REACTION_IMAGE.imageType,
              isExporting,
            )})`,
          }}
        >
          <img
            onLoad={() =>
              setTimeout(() => {
                if (!isExporting) onReset();
              }, 150)
            }
            className='c-annotate-image__proportion'
            src={`${ImageUtils.imageUrl(resource, IMAGE_FORMATS.REACTION_IMAGE.imageType)}`}
            alt=''
          />

          {markers.map((marker, index) => {
            return marker.count ? (
              <AnnotateImageCluster
                key={index}
                cluster={marker}
                openClusterId={openClusterId}
                setOpenClusterId={(id) => setOpenClusterId(id)}
                scale={state.scale}
              />
            ) : (
              <div
                className='c-annotate-image__reaction'
                style={{ left: `${marker.left}%`, top: `${marker.top}%`, transform: `scale(${1 / state.scale})` }}
                key={index}
              >
                <picture>
                  <SnapshotableImage
                    key={index}
                    resource={marker.resource}
                    format={IMAGE_FORMATS.REACTION.imageType}
                    isPng={true}
                    alt={marker.label}
                  />
                </picture>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
});

AnnotateImageArea.displayName = 'AnnotateImageArea';

AnnotateImageArea.propTypes = {
  resource: PropTypes.string.isRequired,
  placedReactions: PropTypes.array,
  isExporting: PropTypes.bool,
};

export default AnnotateImageArea;
