import { action, observable, computed, runInAction, makeObservable } from 'mobx';

import AgentPoll from '../../common/agent';

const Agent = AgentPoll.ReportsAgent;

const LOAD_ERROR = `Error loading report data`;
const LOADING_FEEDBACK_ERROR = 'Error loading feedback data';
const DOWNLOADING_FEEDBACK_ERROR = 'Error downloading feedback';
const DOWNLOADING_FEEDBACK_IMAGES_ERROR = 'Error downloading feedback images';
const DOWNLOADING_SINGLE_FEEDBACK_ERROR = 'Error downloading feedback';
const HIGHLIGHTING_FEEDBACK_ERROR = 'Error highlighting feedback';

export default class PollsReportStore {
  _loading = false;

  _initialized = false;

  _error = null;

  _pollId = null;

  _projectId = null;

  _report = { meta: {}, questions: [] };

  _segmentationFilterQuestions = [];

  _demographicFilterQuestions = [];

  _pollFilterQuestions = [];

  _segmentChoiceId = null;

  _demographicChoiceId = null;

  _feedbackModalFeedback = [];

  _feedbackModalOpen = false;

  _feedbackModalActivatorQuestion = null;

  _feedbackModalActivatorReactions = [];

  constructor(rootStore) {
    makeObservable(this, {
      _loading: observable,
      _initialized: observable,
      _error: observable,

      _report: observable,
      _pollId: observable,
      _projectId: observable,
      _segmentationFilterQuestions: observable,
      _demographicFilterQuestions: observable,
      _pollFilterQuestions: observable,
      _segmentChoiceId: observable,
      _demographicChoiceId: observable,
      _feedbackModalFeedback: observable,
      _feedbackModalOpen: observable,
      _feedbackModalActivatorQuestion: observable,
      _feedbackModalActivatorReactions: observable,

      isLoading: computed,
      isInitialized: computed,
      error: computed,

      pollsReport: computed,
      pollId: computed,
      projectId: computed,
      segmentationFilterQuestions: computed,
      demographicFilterQuestions: computed,
      pollFilterQuestions: computed,
      segmentChoiceId: computed,
      demographicChoiceId: computed,
      feedbackModalFeedback: computed,
      feedbackModalOpen: computed,
      feedbackModalActivatorQuestion: computed,
      feedbackModalActivatorReactions: computed,

      reset: action,
    });

    this.rootStore = rootStore;
  }

  get isLoading() {
    return this._loading;
  }

  get isInitialized() {
    return this._initialized;
  }

  get error() {
    return this._error;
  }

  get pollsReport() {
    return this._report;
  }

  get segmentationFilterQuestions() {
    return this._segmentationFilterQuestions;
  }

  get demographicFilterQuestions() {
    return this._demographicFilterQuestions;
  }

  get pollFilterQuestions() {
    return this._pollFilterQuestions;
  }

  get segmentChoiceId() {
    return this._segmentChoiceId;
  }

  get demographicChoiceId() {
    return this._demographicChoiceId;
  }

  get projectId() {
    return this._projectId;
  }

  set projectId(id) {
    this._projectId = id;
  }

  get pollId() {
    return this._pollId;
  }

  get feedbackModalFeedback() {
    return this._feedbackModalFeedback;
  }

  get feedbackModalOpen() {
    return this._feedbackModalOpen;
  }

  set feedbackModalOpen(val) {
    this._feedbackModalOpen = val;
  }

  get feedbackModalActivatorQuestion() {
    return this._feedbackModalActivatorQuestion;
  }

  set feedbackModalActivatorQuestion(val) {
    this._feedbackModalActivatorQuestion = val;
  }

  get feedbackModalActivatorReactions() {
    return this._feedbackModalActivatorReactions;
  }

  set feedbackModalActivatorReactions(val) {
    this._feedbackModalActivatorReactions = val;
  }

  reset = (projectId) => {
    this._loading = false;
    this._initialized = false;
    this._error = null;

    this._pollId = null;
    this._projectId = projectId || null;
    this._report = { questions: [] };
    this._segmentationFilterQuestions = [];
    this._demographicFilterQuestions = [];
    this._pollFilterQuestions = [];
    this._segmentChoiceId = null;
    this._demographicChoiceId = null;

    this._feedbackModalFeedback = [];
    this._feedbackModalOpen = false;
    this._feedbackModalActivatorQuestionId = null;
    this._feedbackModalActivatorReactions = [];
  };

  async loadPollFilterData(projectId, pollId, rangeFrom, rangeTo) {
    const query = {
      timestampFrom: rangeFrom,
      timestampTo: rangeTo,
    };

    try {
      const res = await Agent.loadPollFilterData(projectId, pollId, query);

      runInAction(() => {
        this._segmentationFilterQuestions = res.data.segmentationStats;
        this._demographicFilterQuestions = res.data.demographicsStats;
        this._pollFilterQuestions = res.data.conditionalQuestionsStats;
      });
    } catch (err) {
      this._error = err.response;
      this.rootStore.toastrStore.error(LOAD_ERROR, null, err);
    }
  }

  async loadPollsReport(projectId, pollId, segmentChoiceId, demographicChoiceId, pollChoiceId, rangeFrom, rangeTo) {
    if (!(projectId && pollId)) {
      return;
    }

    this._loading = true;
    this._error = null;

    const query = {
      segment: segmentChoiceId,
      demographic: demographicChoiceId,
      poll: pollChoiceId,
      timestampFrom: rangeFrom,
      timestampTo: rangeTo,
    };

    try {
      const res = await Agent.loadPollsReport(projectId, pollId, query);

      runInAction(() => {
        this._report = res.data.report;
        this._loading = false;
        this._error = null;
        this._pollId = pollId;
        this._segmentChoiceId = segmentChoiceId;
        this._demographicChoiceId = demographicChoiceId;
      });
    } catch (err) {
      this._error = err.response;
      this.rootStore.toastrStore.error(LOAD_ERROR, null, err);
    }
  }

  async loadQuestionFeedback(
    projectId,
    questionId,
    rangeFrom,
    rangeTo,
    segmentationFilter,
    demographicsFilter,
    conditionalFilter,
    search,
    withMedia,
    withText,
    choiceId,
    paging,
  ) {
    this._loading = false;
    this._error = null;

    const query = {
      filter: {
        dateFrom: rangeFrom === '' ? undefined : rangeFrom,
        dateTo: rangeTo === '' ? undefined : rangeTo,
        segmentation: segmentationFilter ? [segmentationFilter] : [],
        demographics: demographicsFilter ? [demographicsFilter] : [],
        conditional: conditionalFilter ? [conditionalFilter] : [],
        text: search,
        withMedia,
        withText,
        choiceIds: choiceId ? [choiceId] : [],
      },
      paging: { size: paging.size, page: paging.page },
      sort: 'answerTimestamp',
    };

    try {
      const res = await Agent.getQuestionFeedback(projectId, this._pollId, questionId, query);

      runInAction(() => {
        this._feedbackModalFeedback = res;
      });
      return res;
    } catch (err) {
      this._error = err.response;
      this.rootStore.toastrStore.error(LOADING_FEEDBACK_ERROR, null, err);
    }
  }

  async highlightQuestionFeedback(projectId, questionId, feedbackId) {
    this._loading = false;
    this._error = null;

    try {
      const res = await Agent.highlightQuestionFeedback(projectId, questionId, feedbackId);

      runInAction(() => {
        this._feedbackModalFeedback = res;
      });
      return res;
    } catch (err) {
      this._error = err.response;
      this.rootStore.toastrStore.error(HIGHLIGHTING_FEEDBACK_ERROR, null, err);
    }
  }

  async downloadSingleQuestionFeedback(projectId, questionId, feedbackId) {
    this._loading = false;
    this._error = null;

    try {
      const res = await Agent.downloadSingleQuestionFeedback(projectId, questionId, feedbackId);

      runInAction(() => {
        this._feedbackModalFeedback = res;
      });
      return res;
    } catch (err) {
      this._error = err.response;
      this.rootStore.toastrStore.error(DOWNLOADING_SINGLE_FEEDBACK_ERROR, null, err);
    }
  }

  async downloadFeedback(projectId, questionId) {
    this._loading = false;
    this._error = null;

    try {
      await Agent.downloadQuestionFeedback(projectId, questionId);
    } catch (err) {
      this._error = err.response;
      this.rootStore.toastrStore.error(DOWNLOADING_FEEDBACK_ERROR, null, err);
    }
  }

  async downloadImages(projectId, questionId) {
    this._loading = false;
    this._error = null;

    try {
      await Agent.downloadQuestionFeedbackImages(projectId, questionId);
    } catch (err) {
      this._error = err.response;
      this.rootStore.toastrStore.error(DOWNLOADING_FEEDBACK_IMAGES_ERROR, null, err);
    }
  }
}
