import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MDEditor from '@uiw/react-md-editor';
import DateUtils from 'common/DateUtils';

function NewsTeaser(props) {
  const { title, id, description, date, status, classModifier = '' } = props;
  return (
    <div className={`c-text-teaser ${classModifier}`} data-testid={`${id}-news-teaser-container`}>
      <h3 className='c-text-teaser__title'>
        <Link to={`news/${id}/edit-news`} onClick={() => window.scrollTo(0, 0)}>
          {title || 'No title'}
        </Link>
        {status === 'published' && <span className='o-tag'>Active</span>}
      </h3>
      <p className='c-text-teaser__description'>
        <MDEditor.Markdown source={description} className='markdown_preview' />
      </p>
      {date && (
        <ul className='c-text-teaser__meta'>
          <li>{DateUtils.getDateTimeString(date)}</li>
        </ul>
      )}
    </div>
  );
}

NewsTeaser.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  description: PropTypes.string,
  date: PropTypes.string,
  status: PropTypes.string.isRequired,
  classModifier: PropTypes.string,
};

export default NewsTeaser;
