import { action, observable, computed, runInAction, makeObservable } from 'mobx';

import AgentPoll from '../../common/agent';

const Agent = AgentPoll.ReportsAgent;

const LOAD_ERROR = `Error loading report data`;

export default class DemographicsReportStore {
  _loading = false;

  _initialized = false;

  _error = null;

  _projectId = null;

  _report = { meta: {}, questions: [] };

  constructor(rootStore) {
    makeObservable(this, {
      _loading: observable,
      _initialized: observable,
      _error: observable,
      _report: observable,
      _projectId: observable,

      isLoading: computed,
      isInitialized: computed,
      error: computed,
      demographicsReport: computed,
      projectId: computed,

      reset: action,
      resetFlags: action,
    });

    this.rootStore = rootStore;
  }

  get isLoading() {
    return this._loading;
  }

  get isInitialized() {
    return this._initialized;
  }

  get error() {
    return this._error;
  }

  get demographicsReport() {
    return this._report;
  }

  get projectId() {
    return this._projectId;
  }

  set projectId(id) {
    this._projectId = id;
  }

  async loadDemographicsReport(projectId, rangeFrom, rangeTo) {
    if (!projectId) return;

    this._loading = true;
    this._error = null;

    const query = {
      timestampFrom: rangeFrom,
      timestampTo: rangeTo,
    };

    try {
      const res = await Agent.loadDemographicsReport(projectId, query);

      runInAction(() => {
        this._report = res.data.report;
      });
    } catch (err) {
      this._error = err;
      this.rootStore.toastrStore.error(LOAD_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  reset = (projectId) => {
    this._loading = false;
    this._initialized = false;
    this._error = null;

    this._projectId = projectId || null;
    this._report = { questions: [] };
  };

  resetFlags = () => {
    this._loading = false;
    this._error = null;
  };
}
