import { action, observable, computed, makeObservable } from 'mobx';
// import {toJS} from 'mobx';

import AgentPoll from '../common/agent';

const Agent = AgentPoll.FeedbackAgent;

const ITEM_LABEL = 'Feedback';
// const CREATE_SUCCESS = `New ${ITEM_LABEL} added`;
// const CREATE_ERROR = `Error creating ${ITEM_LABEL}`;
// const REORDER_ERROR = `Error reordering ${ITEM_LABEL}`;
// const UPDATE_SUCCESS = 'Saved';
// const UPDATE_ERROR = `Error saving ${ITEM_LABEL}`;
const DELETE_SUCCESS = 'Feedback removed';
const DELETE_ERROR = `Error deleting ${ITEM_LABEL}`;
// const RESOURCE_DELETE_ERROR = 'Error deleting resource';

export const EngageFeedbackTypeFilter = {
  ENGAGE_FEEDBACK_ALL: 'all',
  ENGAGE_FEEDBACK_HIGHLIGHTS: 'highlights',
  ENGAGE_FEEDBACK_PROBLEMS: 'problems',
  ENGAGE_FEEDBACK_REQUESTS: 'requests',
  ENGAGE_FEEDBACK_OTHER: 'other',
};

// const EngageFeedbackType = {
//   ENGAGE_FEEDBACK_TYPE_PROBLEM: 'problem feedback',
//   ENGAGE_FEEDBACK_TYPE_REQUEST: 'request feedback',
//   ENGAGE_FEEDBACK_TYPE_GENERAL: 'general feedback',
// };

const EngageFeedbackTypeLabel = {
  'problem feedback': 'Problem',
  'request feedback': 'Request',
  'general feedback': 'Other',
};

export default class FeedbackStore {
  _loading = false;

  _initialized = false;

  _error = null;

  _items = [];

  _projectId = null;

  _filter = 'all';

  constructor(rootStore) {
    makeObservable(this, {
      _items: observable,
      _loading: observable,
      _initialized: observable,
      _error: observable,
      _filter: observable,

      // resetFlags: action,
      //
      feedbackItems: computed,
      filter: computed,

      isLoading: computed,
      isInitialized: computed,
      error: computed,
      //
      toggleOpened: action,
    });

    this.rootStore = rootStore;
  }

  get isLoading() {
    return this._loading;
  }

  get isInitialized() {
    return this._initialized;
  }

  get error() {
    return this._error;
  }

  get feedbackItems() {
    // console.log(toJS(this._items));
    return (this._items && this._items.feedback) || [];
  }

  get filter() {
    return this._filter;
  }

  set filter(filter) {
    // console.log(this._filter);
    this._filter = filter;
  }

  getTypeLabel = (type) => EngageFeedbackTypeLabel[type];

  /**
   * Loads all polls for a given project
   */
  load = (projectId, rangeFrom, rangeTo) => {
    // console.log('Load feedback', projectId);
    this._loading = true;
    this._error = null;
    this._projectId = projectId;

    const query = {
      filter: this.filter,
      page: 1,
      pageSize: 100,
      timestampFrom: rangeFrom,
      timestampTo: rangeTo,
    };

    return Agent.get(projectId, query)
      .then(action((res) => (this._items = res.data)))
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error('Error loading feedback', null, err);
      })
      .finally(action(() => (this._loading = false)));
  };

  /**
   * Toggle item/form open/close status
   * @param item
   */
  toggleOpened = (item) => {
    if (item) {
      // eslint-disable-next-line no-param-reassign
      item.isReplyOpen = !item.isReplyOpen;
    }
  };

  toggleHighLight = (item) =>
    Agent.setHighlight(this._projectId, item._id, !item.highlight)
      .then(
        action((res) => {
          // eslint-disable-next-line no-param-reassign
          item.highlight = !item.highlight;
        }),
      )
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error('Error toggling highlight', null, err);
      })
      .finally(action(() => (this._loading = false)));

  submitReply = (item, reply) => {
    // console.log('STORE', item, reply);
    return Agent.reply(this._projectId, item._id, reply)
      .then(
        action((res) => {
          // Patching the object in memory instead of reloading from server
          // eslint-disable-next-line no-param-reassign
          item.adminResponse = reply.response;
          // eslint-disable-next-line no-param-reassign
          item.adminResponseTimestamp = new Date();
        }),
      )
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error('Error replying to feedback', null, err);
      })
      .finally(action(() => (this._loading = false)));
  };

  /**
   * Create EMPTY decision  POST /project/:id/faq
   * @param projectId
   * @param data
   * @returns {Promise<T>}
   */
  // create = (projectId, data) => {
  //   //console.log('CREATE STORE');
  //   this._loading = true;
  //   this._error = null;
  //   return Agent.create(this.projectStore.project._id)
  //     .then((res) => {
  //       // console.log('New member', res.data._id);
  //       this.rootStore.toastrStore.success(CREATE_SUCCESS);
  //       this.refreshAndKeepItemOpen(res.data._id)
  //     })
  //     .catch(err => {
  //       this._error = err;
  //       this.rootStore.toastrStore.error(CREATE_ERROR, null, err);
  //     })
  //     .finally(this.resetFlags);
  // };

  // Update FAQ
  // update(itemId, data){
  //   console.log('UPDATE FAQ STORE', data);
  //   this._loading = true;
  //   this._error = null;
  //   return Agent.update(this.projectStore.project._id, itemId, data)
  //     .then(() => {
  //       // console.log('New member', res.data._id);
  //       this.rootStore.toastrStore.success(UPDATE_SUCCESS);
  //       this.refreshAndKeepItemOpen(itemId);
  //     })
  //     .catch(err => {
  //       this._error = err;
  //       this.rootStore.toastrStore.error(UPDATE_ERROR, null, err);
  //     })
  //     .finally(this.resetFlags);
  //
  // }

  /**
   * Remove decision DELETE /project/:id/feedback/:id
   * @param itemId
   * @returns {Promise<T>}
   */
  remove = (item) => {
    // console.log('DELETE STORE');
    this._loading = true;
    this._error = null;
    return Agent.remove(this._projectId, item._id)
      .then(
        action(() => {
          // console.log(this._items);
          const items = this._items.feedback.filter((_item) => item._id !== _item._id);
          this._items.feedback = items;
          this.rootStore.toastrStore.success(DELETE_SUCCESS);
        }),
      )
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(DELETE_ERROR, null, err);
      });
    // .finally(this.resetFlags);
  };

  // refreshAndKeepItemOpen = (faqId) => {
  //   this.projectStore.refresh()
  //     .then(() => {
  //       let item = this._items.find( faq => faq.data._id === faqId );
  //       this.toggleOpened(item);
  //     })
  // };

  // resetFlags = () => {
  //   this._loading = false;
  // }
}
