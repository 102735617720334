import React from 'react';
import { Form } from 'react-final-form';
import { inject, observer } from 'mobx-react';
import { SortableElement } from 'react-sortable-hoc';

import SwitchableHeader from '../../../common/components/switchableHeader/SwitchableHeader';
import EngageTextInputField from '../../../common/components/form-elements/textInputField/TextInputField';
import SwitchableButtonBar from '../../../common/components/switchableButtonBar/SwitchableButtonBar';
import EngageSelectInputField from '../../../common/components/form-elements/selectInputField/SelectInputField';
import { STAGE_SIZE, STAGE_TYPES } from './StageEnums';
import SlimFileUpload from '../../../common/components/form-elements/fileUpload/SlimFileUpload';
import { IMAGE_FORMATS } from '../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import EngageMarkdownInputField, {
  MARKDOWN_MID,
} from '../../../common/components/form-elements/markdownInputField/MarkdownInputField';
import displayConfirmationPrompt from '../../../common/PromptUtil';
import { getFormFieldClass, getFormFieldTranslationTooltip } from '../../../common/TranslationUtils';

function StageFn(props) {
  const { stage, phaseId, Store, ToastrStore } = props;
  const imageId = stage.resource;

  const initialValues = {
    title: stage.title,
    description: stage.description,
    date: stage.date,
    size: stage.size,
    type: stage.type,
  };

  const toggleOpened = () => {
    Store.toggleOpened(stage);
  };

  const onSubmit = (values) => {
    const newStage = {
      title: values.title,
      description: values.description || '',
      date: values.date || '',
      size: values.size,
      type: values.type,
    };
    Store.updateStage(phaseId, stage._id, newStage);
  };

  const remove = (event) => {
    event.preventDefault();

    const res = displayConfirmationPrompt(null, null, false, ToastrStore);

    if (res === 1) Store.removeUpdate(phaseId, stage._id);
  };

  const uploadImage = (file, progressHandler) => {
    return Store.uploadStageResource(phaseId, stage._id, file, progressHandler);
  };

  const removeImage = (event) => {
    event.preventDefault();
    return Store.removeStageResource(phaseId, stage._id);
  };

  const getStageIcon = () => {
    return STAGE_TYPES.find((type) => type[0] === stage.type)[2];
  };

  return (
    <div className={`c-switchable ${props.switchableModifier} ${stage.isOpened ? 'is-opened' : 'is-closed'}`}>
      <SwitchableHeader
        id={stage._id}
        label='Stage'
        name={stage.title}
        icon={getStageIcon()}
        toggleOpened={toggleOpened}
      />

      <div className='c-switchable__content'>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ values, form, handleSubmit, submitting, pristine }) => {
            return (
              <form className='c-fields l-form'>
                <EngageTextInputField
                  id={`input-stage-name-${stage._id}`}
                  placeholder='Name'
                  label='Name'
                  maxLength='80'
                  fieldDescription=''
                  fieldName='title'
                  isRequired={true}
                  fieldClass={getFormFieldClass(stage.defaultData.title, null, 'l-form__item')}
                  tooltipInLabel={getFormFieldTranslationTooltip(stage.defaultData.title)}
                />

                <EngageMarkdownInputField
                  id={`input-stage-description-${stage._id}`}
                  placeholder='Make it short, sweet and under 500 characters.'
                  label='Description'
                  maxLength='500'
                  fieldDescription=''
                  fieldName='description'
                  isRequired={false}
                  editorConfig={MARKDOWN_MID}
                  fieldClass={getFormFieldClass(stage.defaultData.description, null, 'l-form__item')}
                  tooltipInLabel={getFormFieldTranslationTooltip(stage.defaultData.description)}
                />

                <SlimFileUpload
                  imageFormat={IMAGE_FORMATS.PANEL}
                  // imageId={stage.resource}
                  imageId={imageId}
                  id={`${stage._id}`}
                  handleUpload={uploadImage}
                  label='Stage image'
                  tip=''
                  removeImage={removeImage}
                />

                <EngageTextInputField
                  id={`input-stage-date-${stage._id}`}
                  placeholder='Enter date'
                  label='Stage date'
                  maxLength='25'
                  fieldDescription=''
                  fieldName='date'
                  isRequired={false}
                  fieldClass={getFormFieldClass(stage.defaultData.date, null, 'l-form__item--s')}
                  tooltipInLabel={getFormFieldTranslationTooltip(stage.defaultData.date)}
                />

                <EngageSelectInputField
                  choices={STAGE_TYPES}
                  id={`input-stage-type-${stage._id}`}
                  fieldClass='l-form__item--s'
                  fieldName='type'
                  label='Stage type'
                />
                <EngageSelectInputField
                  choices={STAGE_SIZE}
                  id={`input-stage-size-${stage._id}`}
                  fieldClass='l-form__item--s'
                  fieldName='size'
                  label='Stage size'
                />

                <SwitchableButtonBar updateAction={handleSubmit} removeAction={remove} isNew={!stage._id} />
              </form>
            );
          }}
        />
      </div>
    </div>
  );
}
const Stage = inject((root) => ({ Store: root.RootStore.timelineStore, ToastrStore: root.RootStore.toastrStore }))(
  observer(StageFn),
);

const SortableItem = SortableElement((props) => {
  return <Stage {...props} />;
});
export default SortableItem;
