import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import ActionBar from '../../../common/components/actionBar/ActionBar';
import EngageTextareaInputField from '../../../common/components/form-elements/textareaInputField/TextareaInputField';
import EngageTextInputField from '../../../common/components/form-elements/textInputField/TextInputField';
import { CONTENT_INPUT_TYPES, getContent } from './ContentUtil';
import { MARKDOWN_MID } from '../../../common/components/form-elements/markdownInputField/MarkdownConstants';
import EngageMarkdownInputField from '../../../common/components/form-elements/markdownInputField/MarkdownInputField';
import SwitchableButtonBar from '../../../common/components/switchableButtonBar/SwitchableButtonBar';
import EngageDoubleTextInputField from '../../../common/components/form-elements/doubleTextInputField/DoubleTextInputField';

function Content(props) {
  const { StaticContentStore, match } = props;

  const [available, setAvailable] = useState(false);
  const [staticText, setStaticText] = useState({});

  useEffect(() => {
    async function fetchData() {
      const result = await StaticContentStore.getTextById(match.params.id);
      setAvailable(true);
      setStaticText(result);
    }
    fetchData();
  }, [StaticContentStore, match.params.id, available, StaticContentStore.projectStore.project]);

  const TextInputField = (values) => {
    return getContent().map((content, index) => {
      return (
        <React.Fragment key={index}>
          <h3 className='c-heading-section'>{content.section}</h3>
          {/* eslint-disable-next-line no-shadow */}
          {content.entries.map((value, index) => {
            let inputComponent;
            switch (value.inputType) {
              case CONTENT_INPUT_TYPES.TEXT_AREA_INPUT:
                inputComponent = (
                  <EngageTextareaInputField
                    key={`${value.id}-${index}`}
                    id={value.id}
                    placeholder={value.placeholder}
                    label={value.label}
                    tooltipInLabel={{
                      img: `/assets-v2/images/customize/${value.img}`,
                      modifier: 'o-tooltip--left-center o-tooltip--media',
                      icon: 'info-circle',
                      description: value.placeholder,
                    }}
                    maxLength={value.maxLength || 300}
                    fieldName={value.fieldName}
                    isRequired={false}
                    fieldClass='l-form__item'
                  />
                );
                break;
              case CONTENT_INPUT_TYPES.RICH_TEXT_AREA_INPUT:
                inputComponent = (
                  <EngageMarkdownInputField
                    id={value.id}
                    key={`${value.id}-${index}`}
                    placeholder={value.placeholder}
                    tooltipInLabel={{
                      img: `/assets-v2/images/customize/${value.img}`,
                      modifier: 'o-tooltip--left-center o-tooltip--media',
                      icon: 'info-circle',
                      description: value.placeholder,
                    }}
                    label={value.label}
                    maxLength={value.maxLength}
                    fieldDescription=''
                    fieldName={value.fieldName}
                    editorConfig={MARKDOWN_MID}
                  />
                );
                break;
              case CONTENT_INPUT_TYPES.EXPANDABLE_TEXT_INPUT:
                inputComponent = (
                  <EngageDoubleTextInputField
                    id={value.id}
                    key={`${value.id}-${index}`}
                    placeholder={value.placeholder}
                    tooltipInLabel={{
                      img: `/assets-v2/images/customize/${value.img}`,
                      modifier: 'o-tooltip--left-center o-tooltip--media',
                      icon: 'info-circle',
                      description: value.placeholder,
                    }}
                    label={value.label}
                    maxLength={value.maxLength}
                    fieldDescription=''
                    fieldName={value.fieldName}
                    actionTooltip={value.actionTooltip}
                    extraField={value.extraField}
                    extraFieldValue={values[value.extraField.fieldName]}
                  />
                );
                break;
              default:
                inputComponent = (
                  <EngageTextInputField
                    key={`${value.id}-${index}`}
                    id={value.id}
                    placeholder={value.placeholder}
                    tooltipInLabel={{
                      img: `/assets-v2/images/customize/${value.img}`,
                      modifier: 'o-tooltip--left-center o-tooltip--media',
                      icon: 'info-circle',
                      description: value.placeholder,
                    }}
                    label={value.label}
                    maxLength={value.maxLength || 40}
                    fieldName={value.fieldName}
                    isRequired={false}
                    fieldClass='l-form__item'
                  />
                );
                break;
            }

            return inputComponent;
          })}
        </React.Fragment>
      );
    });
  };

  const submit = (values) => {
    StaticContentStore.updateStaticText(match.params.id, values);
    setAvailable(false);
  };

  return (
    <div className='l-main'>
      <ActionBar hasButton={false} label='Static Text' />
      <p>Edit static text throughout the site to give your project a more personal feel.</p>
      {available && (
        <Form
          onSubmit={submit}
          initialValues={staticText}
          render={({ values, form, handleSubmit, submitting, pristine }) => {
            return (
              <form className='c-fields l-form' onSubmit={handleSubmit}>
                {TextInputField(values)}
                <SwitchableButtonBar updateAction={handleSubmit} />
              </form>
            );
          }}
        />
      )}
    </div>
  );
}

export default inject((root) => ({ StaticContentStore: root.RootStore.staticContentStore }))(observer(Content));

Content.propTypes = {
  StaticContentStore: PropTypes.object,
};
