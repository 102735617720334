import React, { useState } from 'react';

import { inject, observer } from 'mobx-react';
import { SortableContainer } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import Phase from './Phase';
import ActionBar from '../../../common/components/actionBar/ActionBar';

function TimelineContainerFn(props) {
  const { Store, allowDrag } = props;
  return (
    <div className='c-switchable-list c-switchable-list--timeline'>
      {Store.phases.map((item, index) => (
        <Phase
          key={item._id}
          index={index}
          phase={item}
          switchableModifier='c-switchable--reorder'
          disabled={!allowDrag}
        />
      ))}
    </div>
  );
}
const TimelineContainer = inject((root) => ({ Store: root.RootStore.timelineStore }))(observer(TimelineContainerFn));

function TimelineFn(props) {
  const { Store } = props;

  const [allowDrag, setAllowDrag] = useState(true);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setAllowDrag(false);
    const newOrder = arrayMoveImmutable(props.Store.phases, oldIndex, newIndex);
    Store.reorderPhases(newOrder).then(() => {
      setAllowDrag(true);
    });
  };

  const addNew = () => {
    Store.createPhase();
  };

  return (
    <div className='l-main'>
      <ActionBar addNew={addNew} label='Timeline' hasButton={true} buttonLabel='Add phase' />
      <SortableList useDragHandle={true} onSortEnd={onSortEnd} allowDrag={allowDrag} />
    </div>
  );
}
const Timeline = inject((root) => ({ Store: root.RootStore.timelineStore }))(observer(TimelineFn));

const SortableList = SortableContainer((props) => {
  return <TimelineContainer {...props} />;
});

export default Timeline;
