import { action, runInAction, observable, makeObservable } from 'mobx';

import Agent from '../common/agent';
import { ERROR_NO_SUBSCRIPTION } from '../common/constants/RouteConstants';
import history from '../common/history';
import { ApiErrorEnums } from '../shared/engage';

export default class ProjectList {
  projectList = [];

  loading = false;

  error = null;

  constructor(rootStore) {
    makeObservable(this, {
      loading: observable,
      projectList: observable,
      load: action,
      loadWithClientId: action,
      create: action,
    });

    this.rootStore = rootStore;
  }

  /**
   * Loads all project for given company account
   */
  load = () => {
    this.loading = true;
    this.error = null;
    return Agent.Projects.all()
      .then(action((res) => (this.projectList = res.data)))
      .catch((err) => {
        this.error = err;
        this.rootStore.toastrStore.error('Error loading projects', null, err);
      })
      .finally(action(() => (this.loading = false)));
  };

  /**
   * Loads all project for given client ID
   */
  async loadWithClientId(clientId) {
    this.loading = true;
    this.error = null;

    // No client - just load an empty list
    if (!clientId) {
      this.projectList = [];
      return;
    }

    try {
      const res = await Agent.Projects.getClientProjects(clientId);
      runInAction(() => {
        this.projectList = res.data;
      });
      return this.projectList;
    } catch (err) {
      const errorInfo = err?.response?.data ? err.response.data : '';
      if (errorInfo === ApiErrorEnums().Err403KeyMmap.NO_SUBSCRIPTION) {
        history.push(`/${ERROR_NO_SUBSCRIPTION}`);
      } else {
        this.error = err;
        this.rootStore.toastrStore.error('Error loading projects', null, err);
      }
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  /**
   * Create a project with client Id
   * @param project {projectName, slug}
   * @returns {Promise|*|Promise<T | never>}
   */
  createWithClientId = (clientId, project) =>
    Agent.Projects.createWithClientId(clientId, project)
      .then((data) => {
        this.rootStore.toastrStore.success('Project created');
        return data;
      })
      .catch((err, data) => {
        this.error = err;
        if (err.response.status === 422) {
          this.rootStore.toastrStore.error(err.response.data.error, 'Error creating project', err);
        } else {
          this.rootStore.toastrStore.error('Error creating project', null, err);
        }
        throw err;
      });

  /**
   * Create a project,
   * ############ IMPORTANT ###########
   * should be deprecated when store with clientId is implemented,
   * also check Masthead and Home components
   * ##################################
   * @param project {projectName, slug}
   * @returns {Promise|*|Promise<T | never>}
   */
  create = (project) =>
    Agent.Projects.create(project)
      .then((data) => {
        this.rootStore.toastrStore.success('Project created');
        return data;
      })
      .catch((err, data) => {
        this.error = err;
        if (err.response.status === 422) {
          this.rootStore.toastrStore.error(err.response.data.error, 'Error creating project', err);
        } else {
          this.rootStore.toastrStore.error('Error creating project', null, err);
        }
        throw err;
      });
}
