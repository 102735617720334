import { action, observable, computed, runInAction, makeObservable } from 'mobx';
import { ClientAgent as Agent } from '../common/agent';
import PROTOCOL_ENUMS from '../shared/engage/engage-protocolEnum';

const ERROR_LOADING_CLIENT_LIST = 'Error loading list of clients';
const PROJECTS_LOAD_ERROR = `Error loading client projects`;
const RECENT_CLEINTS_AND_PROJECTS_LOAD_ERROR = `Error loading recently visited clients and projects`;
export default class ClientStore {
  _initialized = false;

  _loading = false;

  _error = null;

  _clients = [];

  _clientsProjects = [];

  constructor(rootStore) {
    makeObservable(this, {
      _initialized: observable,
      _loading: observable,
      _error: observable,

      _clients: observable,
      clients: computed,

      _clientsProjects: observable,
      clientsProjects: computed,

      resetFlags: action,
      loadAllClients: action,
    });

    this.rootStore = rootStore;
  }

  get clients() {
    return this._clients;
  }

  get clientsProjects() {
    return this._clientsProjects;
  }

  async loadAllClients() {
    this._initialized = false;
    this._loading = false;
    this._error = null;

    try {
      const res = await Agent.getAllClients();
      runInAction(() => {
        this._clients = res.data;
      });
      return this._clients;
    } catch (err) {
      this._error = err.response;
      this.rootStore.toastrStore.error(ERROR_LOADING_CLIENT_LIST, null, err);
    } finally {
      this.resetFlags();
    }
  }

  async getAllProjectsForClients(clientIds) {
    this._loading = true;
    this._error = null;
    try {
      const res = await Agent.getAllProjectsForClients(clientIds);
      runInAction(() => {
        this._clientsProjects = res.data;
      });
      return res.data;
    } catch (err) {
      this.rootStore.toastrStore.error(PROJECTS_LOAD_ERROR, null, err);
      throw err;
    } finally {
      this.resetFlags();
    }
  }

  async getRecentClientsAndProjects() {
    this._loading = true;
    this._error = null;
    try {
      const res = await Agent.getRecentClientsAndProjects();

      return res.data;
    } catch (err) {
      this.rootStore.toastrStore.error(RECENT_CLEINTS_AND_PROJECTS_LOAD_ERROR, null, err);
      throw err;
    } finally {
      this.resetFlags();
    }
  }

  async registerAsVisited(clientId) {
    try {
      await Agent.registerAsVisited({
        destination: PROTOCOL_ENUMS().VISIT_DESTINATIONS.CLIENT,
        _id: clientId,
      });

      return true;
    } catch (err) {
      this._error = err;
    } finally {
      this.resetFlags();
    }
  }

  resetFlags = () => {
    this._loading = false;
    this._error = null;
  };
}
