import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';
import { PollEnums } from '../../../shared/engage';
import { QUESTION_TYPES } from '../../../shared/engage/engage-question-constants';
import SimpleReactionPicker from '../form-elements/reactionPicker/SimpleReactionPicker';

export const SHOW_ALL_REACTIONS = { choiceId: 'SHOW_ALL_REACTIONS', label: 'Show all reactions' };

function ActiveFilter(props) {
  const {
    searchValue,
    showMediaFeedback,
    showTextFeeedback,
    selectedReaction,
    reactions = [],
    onMediaFilterChange,
    onTextFilterChange,
    onSearchChange,
    onSearchClear,
    onReactionChange,
    onClearActiveFilters,
    questionType,
    feedbackMeta,
    isFetchingData,
  } = props;

  function reactionChangeHandler(reaction) {
    onReactionChange(reaction);
  }

  function clearSearchHandler(event) {
    event.preventDefault();
    // avoid hitting the API if clearing an empty field
    if (!searchValue) {
      return 5;
    }
    onSearchClear();
  }

  function showContentFilters() {
    return questionType !== QUESTION_TYPES.QUICK_TEXT;
  }

  function showReactionsFilter() {
    return PollEnums().isAnnotationQuestion(questionType) && reactions.length > 2;
  }

  function showMediaFilter() {
    return feedbackMeta?.questionConfiguration?.allowUserContentUpload?.image;
  }

  function searchKeyHandler(event) {
    if (event.key === 13 || event.keyCode === 13) event.preventDefault();
  }

  return (
    <div className='c-browse-feedback__filter' data-testid='feedback-active-filter'>
      <form className='c-fields l-fields'>
        <div className='c-field l-form__item c-field--label-hidden'>
          <label htmlFor='library-search' className='c-field__label'>
            Search
          </label>
          <div className='c-field__group'>
            <div className='c-suggest c-suggest--floating'>
              <div className='c-suggest__search o-input-icon o-input-clear'>
                <input
                  type='text'
                  id='library-search'
                  placeholder='Search feedback...'
                  onChange={(event) => onSearchChange(event.target.value)}
                  value={searchValue}
                  onKeyDown={searchKeyHandler}
                />
                <SvgIcon icon='search' />
                <button className={`${isFetchingData ? 'is-loading' : ''} o-button`} onClick={clearSearchHandler}>
                  <SvgIcon icon='times' />
                  <span className='o-label'>Clear</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        {showContentFilters() && (
          <div className='c-field c-field--label-hidden'>
            <label className='c-field__label'>Options</label>
            <div className='c-field__group'>
              {showMediaFilter() && (
                <div className='o-checkbox o-checkbox--inline js-browseFeedbackToggleMedia'>
                  <input
                    type='checkbox'
                    id='checkbox-show-media'
                    onChange={onMediaFilterChange}
                    checked={showMediaFeedback}
                  />
                  <label htmlFor='checkbox-show-media'>
                    Media feedback
                    <SvgIcon icon='check' />
                  </label>
                </div>
              )}
              <div className='o-checkbox o-checkbox--inline js-browseFeedbackToggleText'>
                <input
                  type='checkbox'
                  id='checkbox-show-text'
                  onChange={onTextFilterChange}
                  checked={showTextFeeedback}
                />
                <label htmlFor='checkbox-show-text'>
                  Text feedback
                  <SvgIcon icon='check' />
                </label>
              </div>
            </div>
          </div>
        )}
        {showReactionsFilter() && (
          <SimpleReactionPicker
            reactions={reactions}
            selectedReaction={selectedReaction}
            onReactionChange={reactionChangeHandler}
            reactionsListClass='c-filter-dropdown--reactions'
          />
        )}
        {(searchValue !== '' || showMediaFeedback || showTextFeeedback || selectedReaction !== SHOW_ALL_REACTIONS) && (
          <button className='o-button o-button--primary' type='button' onClick={onClearActiveFilters}>
            <span className='o-label'>Clear filters</span>
          </button>
        )}
      </form>
    </div>
  );
}

ActiveFilter.propTypes = {
  searchValue: PropTypes.string.isRequired,
  showMediaFeedback: PropTypes.bool.isRequired,
  showTextFeeedback: PropTypes.bool.isRequired,
  selectedReaction: PropTypes.object,
  reactions: PropTypes.array,
  onMediaFilterChange: PropTypes.func.isRequired,
  onTextFilterChange: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onSearchClear: PropTypes.func.isRequired,
  onReactionChange: PropTypes.func,
  onClearActiveFilters: PropTypes.func,
  questionType: PropTypes.string.isRequired,
  feedbackMeta: PropTypes.object,
  isFetchingData: PropTypes.bool.isRequired,
};

export default ActiveFilter;
