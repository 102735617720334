import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import SlimFileUpload from '../../../common/components/form-elements/fileUpload/SlimFileUpload';
import SvgIcon from '../../../common/components/SvgIcon';
import { IMAGE_FORMATS } from '../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import { QUESTION_TYPES } from '../../../common/constants/QuestionConstants';

function LeadImage(props) {
  const { question, isCustomQuestion, uploadImage, removeImage } = props;
  const { _id, questionType, resource } = question;

  const [showImage, setShowImage] = useState(false);
  const [leadImage, setLeadImage] = useState();
  const [defaultLeadImage, setDefaultLeadImage] = useState();

  // eslint-disable-next-line no-shadow
  const getQuestionDefaultLeadImage = useCallback((questionType) => {
    return QUESTION_TYPES.properties[questionType.toUpperCase()].defaultLeadImg;
  }, []);

  useEffect(() => {
    if (resource) setLeadImage(resource && resource.length > 0 ? resource[0] : null);

    if (questionType) setDefaultLeadImage(getQuestionDefaultLeadImage(questionType));
  }, [resource, questionType, getQuestionDefaultLeadImage, question]);

  return defaultLeadImage || isCustomQuestion ? (
    <div className='c-field l-form__item js-demographicLeadImage js-demographicLeadImageEmpty'>
      <label className='c-field__label' htmlFor='d-q-1-image'>
        Lead image
      </label>
      <div className='c-field__group'>
        {leadImage || showImage ? (
          <SlimFileUpload
            imageFormat={IMAGE_FORMATS.PANEL}
            imageId={leadImage?._id || null}
            imageUrl={leadImage?.url || null}
            id={_id}
            handleUpload={uploadImage}
            label=''
            classModifier='c-field--label-hidden'
            removeImage={removeImage}
          />
        ) : (
          <p className='c-field__description'>
            <span className='o-tooltip o-tooltip--bottom-center o-tooltip--media'>
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
              <span className='o-tooltip__action' tabIndex='0'>
                <span className='o-label'>{defaultLeadImage ? 'Default image specified' : 'Using images'}</span>
                {!defaultLeadImage && <SvgIcon icon='info-circle' />}
              </span>
              <span className='o-tooltip__description' role='tooltip'>
                {defaultLeadImage ? (
                  <img src={`/assets-v2/images/customize/${defaultLeadImage}`} alt='' />
                ) : (
                  `Lead images can be used to make a question more visually appealing, but should also be neutral
                      so that it does not influence a user's choice.`
                )}
              </span>
            </span>
            {!defaultLeadImage && !leadImage && (
              <Fragment>
                <span className='u-inline-spacer'>—</span>
                <button
                  className='o-button o-button--link'
                  type='button'
                  onClick={() => setShowImage(true)}
                  data-testid='action-tooltip-button'
                >
                  <SvgIcon icon='image' dataTestId='action-tooltip-button-icon' />
                  <span className='o-label' data-testid='action-tooltip-button-label'>
                    Add lead image
                  </span>
                </button>
              </Fragment>
            )}
          </p>
        )}
      </div>
    </div>
  ) : null;
}

LeadImage.propTypes = {
  question: PropTypes.object.isRequired,
  isCustomQuestion: PropTypes.bool.isRequired,
  uploadImage: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired,
};

export default observer(LeadImage);
