import React from 'react';
import PropTypes from 'prop-types';

export default function SimpleRadioGroupInputField(props) {
  const {
    choices,
    id,
    label,
    input,
    disabled,
    onSelectionChange,
    fieldClass = '',
    inputWrapperClass = '',
    horizontalLayout = true,
    type = 'radio',
  } = props;
  const { onChange } = input;

  const mergedOnChange = (e) => {
    onChange(e);
    if (onSelectionChange) onSelectionChange(e);
  };

  const newInput = { ...input, onChange: mergedOnChange };

  return (
    <div className={`c-field l-form__item ${fieldClass}`} data-testid={`${id}-wrapper`} style={{ display: 'block' }}>
      <label className='c-field__label' htmlFor={id} data-testid={`${id}-group-label`}>
        {label}
      </label>
      <div className='c-field__group' data-testid={`${id}-radio-wrapper`}>
        {choices.map((choice) => {
          return (
            <div
              className={`o-radio ${inputWrapperClass} ${horizontalLayout ? 'o-radio--inline' : ''}`}
              key={choice[0]}
            >
              <input
                {...newInput}
                id={`${id}-${choice[0]}`}
                disabled={disabled}
                checked={newInput.value === choice[0]}
                data-testid={`${id}-${choice[0]}-radio`}
                name={`${id}-radioGroup`}
                value={choice[0]}
                className='custom-control-input'
                type={type}
              />
              <label
                htmlFor={`${id}-${choice[0]}`}
                data-testid={`${id}-${choice[0]}-label`}
                className='custom-control-label'
              >
                {choice[1]}
                <small data-testid={`${id}-${choice[0]}-label-small`}> {choice[2]}</small>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}

SimpleRadioGroupInputField.propTypes = {
  choices: PropTypes.array,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  input: PropTypes.object,
  disabled: PropTypes.bool,
  onSelectionChange: PropTypes.func,
  fieldClass: PropTypes.string,
  inputWrapperClass: PropTypes.string,
  horizontalLayout: PropTypes.bool,
  type: PropTypes.string,
};
