import React, { useState } from 'react';
import PropTypes from 'prop-types';

function MapSearchBox(props) {
  const { id, show } = props;

  const [searchValue, setSearchValue] = useState('');

  return (
    <div
      id={`${id}-map-search-input-wrapper`}
      className='o-map__search'
      style={{ display: `${show ? 'flex' : 'none'}` }}
    >
      <input
        id={`${id}-map-search-input`}
        value={searchValue}
        onChange={(event) => setSearchValue(event.target.value)}
        placeholder='Search map'
        type='text'
      />
    </div>
  );
}

MapSearchBox.propTypes = {
  id: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
};

export default MapSearchBox;
