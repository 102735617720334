import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Form } from 'react-final-form';
import displayConfirmationPrompt from 'common/PromptUtil';
import EngageTextInputField from '../../../../../common/components/form-elements/textInputField/TextInputField';
import EngageSelectInputField from '../../../../../common/components/form-elements/selectInputField/SelectInputField';
import SwitchableButtonBar from '../../../../../common/components/switchableButtonBar/SwitchableButtonBar';
import { getFormFieldClass, getFormFieldTranslationTooltip } from '../../../../../common/TranslationUtils';
import ConditionalLogic from '../../../../../common/components/conditionalLogic/ConditionalLogic';
import ConditionalLogicUtil from '../../../../../common/ConditionalLogicUtil';
import { CheckboxInputField as EnagegeCheckboxInputField } from '../../../../../common/components/form-elements';
import EngageCheckboxInputField from '../../../../../common/components/form-elements/checkboxInputField/CheckboxInputField';
import ReactionPicker from '../../../../../common/components/form-elements/reactionPicker/ReactionPicker';
import LeadImage from '../../../../../common/components/form-elements/leadImage/LeadImage';
import { IMAGE_FORMATS } from '../../../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import { LEAD_IMAGE_TYPES } from '../../../../../shared/engage/engage-question-constants';

export const QUESTION_CHARACTER_LIMIT = '80';
export const IMAGE_CHARACTER_LIMIT = '30';
export const CUSTOM_LABEL_CHARACTER_LIMIT = '20';

const CHOICES = [
  [3, '3'],
  [4, '4'],
  [5, '5'],
  [6, '6'],
  [7, '7'],
  [8, '8'],
  [9, '9'],
  [10, '10'],
];

export const SHOW_ALL_REACTIONS = { choiceId: 'SHOW_ALL_REACTIONS', label: 'Show all reactions' };

function RatingQuestion(props) {
  const {
    question,
    updateQuestion,
    removeQuestion,
    removeMedia,
    uploadMedia,
    uploadVideo,
    openPollPreview,
    showConditionalLogic,
    Store,
    ToastrStore,
  } = props;

  const { _id, resource, defaultData } = question;

  const [formValues, setFormValues] = useState({});
  const [rules, setRules] = useState([]);
  const [reactions, setReactions] = useState([]);
  const [selectedReaction, setSelectedReaction] = useState();

  const defaultResourceLabels = question?.defaultData?.questionConfiguration?.ratingConfiguration?.resourceLabels || {};

  useEffect(() => {
    if (!question || !Store) return;

    const initialFormQuestionValues = {
      questionText: question.questionText,
      multiChoicesOfferedBound: question.multiChoicesOfferedBound,
      ratingType: question.choices?.length ? question.choices[0].resource : '',
      allowFeedback: question.questionConfiguration?.allowComments,
      useCustomLabels: question.questionConfiguration?.ratingConfiguration?.useCustomLabels,
      lowestResponseText: question.questionConfiguration?.ratingConfiguration?.resourceLabels?.lowest,
      highestResponseText: question.questionConfiguration?.ratingConfiguration?.resourceLabels?.highest,
      leadImageType: question.questionConfiguration?.leadImageType || LEAD_IMAGE_TYPES.STANDARD_SINGLE_IMAGE,
    };

    const initialState = ConditionalLogicUtil.setupFormInitialValues(question, initialFormQuestionValues);
    setFormValues({ ...initialFormQuestionValues, ...initialState.initialFormConditionValues });
    setRules(initialState.initialRules);

    Store.loadRatingIcons().then((data) => {
      if (data) {
        const preparedReactions = data.map((reaction) => {
          return { choiceId: reaction._id, resource: reaction.resource, label: reaction.choice };
        });

        setReactions(preparedReactions);

        const type = question.choices?.length ? question.choices[0].resource : '';
        const targetReaction = preparedReactions.find((reaction) => reaction.resource === type);
        if (targetReaction) setSelectedReaction(targetReaction);
      }
    });
  }, [Store, question]);

  const onSubmit = (values) => {
    const updatedQuestionData = toJS(question);
    // Question content
    updatedQuestionData.questionText = values.questionText;
    updatedQuestionData.multiChoicesOfferedBound = values.multiChoicesOfferedBound;
    updatedQuestionData.choices[0].resource = values.ratingType;
    updatedQuestionData.questionConfiguration.allowComments = values.allowFeedback;
    updatedQuestionData.questionConfiguration.leadImageType = values.leadImageType;

    if (!updatedQuestionData.questionConfiguration.ratingConfiguration)
      updatedQuestionData.questionConfiguration.ratingConfiguration = {};
    updatedQuestionData.questionConfiguration.ratingConfiguration.useCustomLabels = values.useCustomLabels;

    updatedQuestionData.questionConfiguration.ratingConfiguration.resourceLabels = {};
    updatedQuestionData.questionConfiguration.ratingConfiguration.resourceLabels.lowest = values.useCustomLabels
      ? values.lowestResponseText
      : '';
    updatedQuestionData.questionConfiguration.ratingConfiguration.resourceLabels.highest = values.useCustomLabels
      ? values.highestResponseText
      : '';

    ConditionalLogicUtil.extractConditionalFormValues(question, updatedQuestionData, values, rules);

    updateQuestion(updatedQuestionData);
  };

  function reactionChangeHandler(args, state) {
    setSelectedReaction(args[0]);

    const ratingTypeField = state.fields.ratingType;
    ratingTypeField.change(args[0].resource);
  }

  function ratingRangeChangeHandler(newRange, oldRange, currentFormValues) {
    let changeApproved = true;

    if (newRange < oldRange) {
      if (ConditionalLogicUtil.hasTempConditionalLogic(currentFormValues)) {
        const promptMessage =
          "If you reduce rating range all conditional logic rules will be removed. Please enter 'DELETE' to confirm removal of conditional logic rules.";

        const res = displayConfirmationPrompt(promptMessage, '', false, ToastrStore, true);

        if (res !== 1) changeApproved = false;
      }

      if (changeApproved) {
        const newState = ConditionalLogicUtil.removeConditionalLogic(currentFormValues, question._id);
        setRules(newState.newRules);
        setFormValues(newState.newFormValues);
      }
    }

    return changeApproved;
  }

  return (
    <Form
      onSubmit={onSubmit}
      keepDirtyOnReinitialize={true}
      initialValues={formValues}
      mutators={{ reactionChangeHandler }}
      render={({ values, handleSubmit, form }) => {
        return (
          <form className='c-fields l-form' onSubmit={(event) => event.preventDefault()}>
            <EngageTextInputField
              id={`input-question-${_id}`}
              placeholder='Enter question'
              label='Question'
              maxLength={QUESTION_CHARACTER_LIMIT}
              fieldDescription=''
              fieldName='questionText'
              isRequired={true}
              fieldClass={getFormFieldClass(question.defaultData.questionText)}
              tooltipInLabel={getFormFieldTranslationTooltip(question.defaultData.questionText)}
            />

            <div className='c-toggle-content'>
              <div className='c-toggle-content__container'>
                <ConditionalLogic
                  formValues={values}
                  setFormValues={setFormValues}
                  question={question}
                  questions={Store.poll.questions}
                  rules={rules}
                  setRules={setRules}
                  show={showConditionalLogic}
                  choiceFieldNames={[]}
                />
                <div className='c-toggle-content__content' hidden={showConditionalLogic}>
                  <div className='l-form'>
                    <LeadImage
                      questionId={_id}
                      resource={resource}
                      defaultResource={defaultData?.resource}
                      store={Store}
                      label='Lead image'
                      uploadImage={uploadMedia}
                      uploadVideo={uploadVideo}
                      removeMedia={removeMedia}
                      fieldName='leadImageType'
                      leadImageType={values.leadImageType}
                      classModifier={getFormFieldClass(null, defaultData.resource)}
                      tooltipInLabel={getFormFieldTranslationTooltip(
                        null,
                        defaultData.resource,
                        IMAGE_FORMATS.FLUID_PANEL,
                      )}
                    />

                    <EngageSelectInputField
                      choices={CHOICES}
                      fieldName='multiChoicesOfferedBound'
                      id={`input-items-number-${_id}`}
                      label='Number of items'
                      fieldClass='l-form__item--m'
                      firstOptionEmpty={false}
                      onSelectionChange={(newValue) =>
                        ratingRangeChangeHandler(newValue, values.multiChoicesOfferedBound, values)
                      }
                      requireChangeApproval={true}
                    />
                    <ReactionPicker
                      fieldName='ratingType'
                      label='Rating type'
                      reactions={reactions}
                      selectedReaction={selectedReaction}
                      onReactionChange={form.mutators.reactionChangeHandler}
                      hasShowAllOption={false}
                      wrapperClass='c-filter-set--icons'
                      isRequired={true}
                    />
                    <EngageCheckboxInputField
                      id={`checkbox-allow-feedback-${_id}`}
                      type='checkbox'
                      fieldName='allowFeedback'
                      fieldClass='l-form__item--m'
                      isToggle={true}
                      checkedLabel='Show feedback form'
                      uncheckedLabel='Show feedback form'
                    />
                    <EnagegeCheckboxInputField
                      id={`checkbox-use-custom-labels-${_id}`}
                      type='checkbox'
                      fieldName='useCustomLabels'
                      fieldClass='l-form__item--m'
                      isToggle={true}
                      checkedLabel='Use custom labels'
                      uncheckedLabel='Use custom labels'
                    />
                    {values.useCustomLabels && (
                      <Fragment>
                        <EngageTextInputField
                          id={`input-low-response-label-${_id}`}
                          placeholder='Enter lowest response label'
                          label='Lowest response'
                          maxLength={CUSTOM_LABEL_CHARACTER_LIMIT}
                          fieldDescription=''
                          fieldName='lowestResponseText'
                          fieldClass={getFormFieldClass(defaultResourceLabels.lowest, null, 'l-form__item--m')}
                          tooltipInLabel={getFormFieldTranslationTooltip(defaultResourceLabels.lowest)}
                          isRequired={true}
                        />

                        <EngageTextInputField
                          id={`input-high-response-label-${_id}`}
                          placeholder='Enter highest response label'
                          label='Highest response'
                          maxLength={CUSTOM_LABEL_CHARACTER_LIMIT}
                          fieldDescription=''
                          fieldName='highestResponseText'
                          fieldClass={getFormFieldClass(defaultResourceLabels.highest, null, 'l-form__item--m')}
                          tooltipInLabel={getFormFieldTranslationTooltip(defaultResourceLabels.highest)}
                          isRequired={true}
                        />
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <SwitchableButtonBar
              updateAction={handleSubmit}
              removeAction={removeQuestion}
              isNew={null}
              previewAction={() => openPollPreview(question._id)}
            />
          </form>
        );
      }}
    />
  );
}

RatingQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  updateQuestion: PropTypes.func.isRequired,
  removeQuestion: PropTypes.func.isRequired,
  uploadMedia: PropTypes.func.isRequired,
  uploadVideo: PropTypes.func.isRequired,
  removeMedia: PropTypes.func.isRequired,
  openPollPreview: PropTypes.func,
  showConditionalLogic: PropTypes.bool.isRequired,
  Store: PropTypes.object.isRequired,
  ToastrStore: PropTypes.object.isRequired,
};

export default observer(RatingQuestion);
