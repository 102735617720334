import React from 'react';
import { Link } from 'react-router-dom';

import ImageUtils, { IMAGE_TYPE_LOGO, IMAGE_TYPE_PROFILE_BACKGROUND } from '../../../common/ImageUtils';
import { PROJECT, INFORMATION, EDIT_PROJECT } from '../../../common/constants/RouteConstants';

function ProjectCard(props) {
  return (
    <div className='l-grid__item' data-testid='project-card-wrapper'>
      <div className='c-community-card'>
        <div className='c-community-card__media'>
          <Link to={`/${PROJECT}/${props.project._id}/${EDIT_PROJECT}/${INFORMATION}`}>
            {props.project.projectHeader ? (
              <img
                alt='Project card background'
                className='c-community-card__background'
                src={ImageUtils.imageUrl(props.project.projectHeader, IMAGE_TYPE_PROFILE_BACKGROUND)}
              />
            ) : (
              <span
                className='c-community-card__background c-community-card__background--placeholder'
                data-testid='card-background-placeholder'
              />
            )}
          </Link>
        </div>
        <div className='c-community-card__info'>
          <Link to={`/${PROJECT}/${props.project._id}/${EDIT_PROJECT}/${INFORMATION}`}>
            {props.project.projectLogo ? (
              <img
                alt='Project card logo'
                className='c-community-card__logo'
                src={ImageUtils.imageUrl(props.project.projectLogo, IMAGE_TYPE_LOGO)}
              />
            ) : (
              <span
                className='c-community-card__logo c-community-card__logo--placeholder'
                data-testid='card-logo-placeholder'
              />
            )}
          </Link>
          <h2 className='c-community-card__title'>
            <Link to={`/${PROJECT}/${props.project._id}/${EDIT_PROJECT}/${INFORMATION}`}>
              {props.project.projectName}
            </Link>
          </h2>
          <p className='c-community-card__subtitle'>{props.project.architect}</p>
        </div>
        <div className='c-community-card__meta'>
          <p>{props.project.published ? 'Published' : 'Not published'}</p>
          {props.project.unpublishedModifications && <p>Changes in draft</p>}
          <p>{props.project.languages.join(', ')}</p>
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
