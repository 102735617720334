import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { SortableContainer } from 'react-sortable-hoc';
import DemographicQuestion from './DemographicQuestion';

function QuestionContainerFn(props) {
  const { Store, openPollPreview, projectId, allowDrag } = props;
  return (
    <div className='c-switchable-list'>
      {Store.demographic
        ? Store.demographic.questions.map((question, index) => (
            <DemographicQuestion
              key={question._id}
              index={index}
              question={question}
              projectId={projectId}
              openPollPreview={openPollPreview}
              switchableModifier='c-switchable--reorder'
              disabled={!allowDrag}
            />
          ))
        : null}
    </div>
  );
}

QuestionContainerFn.propTypes = {
  Store: PropTypes.object.isRequired,
  openPollPreview: PropTypes.func,
  projectId: PropTypes.string.isRequired,
  allowDrag: PropTypes.bool.isRequired,
};

const QuestionContainer = inject((root) => ({ Store: root.RootStore.demographicsStore }))(
  observer(QuestionContainerFn),
);

/**
 * Sortable collection wrapper
 */
const QuestionsList = SortableContainer((props) => {
  return <QuestionContainer {...props} />;
});

export default QuestionsList;
