import { action, runInAction, observable, computed, makeObservable } from 'mobx';
import axios from 'axios';
import Agent from '../common/agent';

const AgentPoll = Agent.PollLibraryAgent;

export default class PollLibraryStore {
  _loading = false;

  _error = null;

  _suggestedKeywords = [];

  _profiles = [];

  _projects = [];

  _polls = [];

  _questions = null;

  _isCopying = false;

  _axiosCancelTokenSource = null;

  _fetchingQuestions = false;

  constructor(rootStore) {
    makeObservable(this, {
      _loading: observable,
      _error: observable,
      _suggestedKeywords: observable,
      _profiles: observable,
      _projects: observable,
      _polls: observable,
      _questions: observable,
      _isCopying: observable,

      isLoading: computed,
      suggestedKeywords: computed,
      error: computed,
      profiles: computed,
      projects: computed,
      polls: computed,
      questions: computed,
      isCopying: computed,

      updateQuestionStatus: action,
    });

    this.rootStore = rootStore;
  }

  get isLoading() {
    return this._loading;
  }

  get error() {
    return this._error;
  }

  get suggestedKeywords() {
    return this._suggestedKeywords;
  }

  get profiles() {
    return this._profiles.slice().sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  get projects() {
    return this._projects;
  }

  get polls() {
    return this._polls;
  }

  get questions() {
    return this._questions;
  }

  get isCopying() {
    return this._isCopying;
  }

  set isCopying(val) {
    this._isCopying = val;
  }

  /**
   * Loads suggested key words
   */
  getSuggestedKeywords = (keyword) => {
    action(() => (this._loading = true));
    this._error = null;
    return AgentPoll.suggestKeyword(keyword)
      .then(action((res) => (this._suggestedKeywords = res.data)))
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error('Error loading suggested keywords', null, err);
      })
      .finally(action(() => (this._loading = false)));
  };

  /**
   * Load questions that match client, project, poll,
   * question type selections and selected keywords
   */
  getQuestions = (data) => {
    // Cancel previous request if still in progress
    if (this._fetchingQuestions) this._axiosCancelTokenSource.cancel('New search request was made');

    this._fetchingQuestions = true;
    this._axiosCancelTokenSource = axios.CancelToken.source();

    AgentPoll.search(data, this._axiosCancelTokenSource.token)
      .then(
        action((res) => {
          this._suggestedKeywords = [];
          this._questions = res.data.questions;
        }),
      )
      .catch((err) => {
        if (!axios.isCancel(err)) {
          this._error = err;
          this.rootStore.toastrStore.error('Error loading questions', null, err);
        }
      })
      .finally(() => {
        this._fetchingQuestions = false;
        action(() => (this._loading = false));
      });
  };

  /**
   * Loads profiles
   */
  getProfiles = (keyword) => {
    // console.log('Load profiles', keyword);
    action(() => (this._loading = true));
    this._error = null;
    return AgentPoll.profiles(keyword)
      .then(action((res) => (this._profiles = res.data)))
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error('Error loading client profiles', null, err);
      })
      .finally(action(() => (this._loading = false)));
  };

  /**
   * Loads projects
   */
  getProjects = (data) => {
    // console.log('Load projects', data);
    action(() => (this._loading = true));
    this._error = null;
    return AgentPoll.projects(data)
      .then(action((res) => (this._projects = res.data)))
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error('Error loading projects', null, err);
      })
      .finally(action(() => (this._loading = false)));
  };

  /**
   * Loads polls
   */
  getPolls = (data) => {
    // console.log('Load polls ', data);
    action(() => (this._loading = true));
    this._error = null;
    return AgentPoll.polls(data)
      .then(action((res) => (this._polls = res.data)))
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error('Error loading surveys', null, err);
      })
      .finally(action(() => (this._loading = false)));
  };

  /**
   * Loads poll questions
   */
  getPollQuestions = (projectId, pollId, data) => {
    // console.log('Load questions', projectId, pollId, data);
    action(() => (this._loading = true));
    this._error = null;
    return AgentPoll.questions(projectId, pollId, data)
      .then(action((res) => (this._questions = res.data)))
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error('Error loading survey questions', null, err);
      })
      .finally(action(() => (this._loading = false)));
  };

  /**
   * Update question template status after adding it to the poll
   */
  updateQuestionStatus = (questionId) => {
    // console.log('updateQuestionStatus', questionId);
    const questionIndex = this._questions.findIndex((_question) => _question._id === questionId);
    this._questions[questionIndex].copiedToPoll = true;
  };
}
