import React from 'react';
import PropTypes from 'prop-types';
import QuestionTemplateAction from './QuestionTemplateAction';
import { IMAGE_FORMATS } from '../../../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import PreviewUtils from '../../../../../common/PreviewUtils';
import ImageUtils from '../../../../../common/ImageUtils';
import QuestionTemplateHeader from './QuestionTemplateHeader';
import MediaItem from '../../../../../common/components/mediaItem/MediaItem';

function SliderPreview(props) {
  const { question } = props;
  const inputValue = (question.choices[0].range[1] - question.choices[0].range[0]) / 2;

  const mediaResource = question?.resource?.length > 0 ? question.resource[0] : null;

  const imageId = mediaResource?._id || null;
  const imageUrl = mediaResource?.url || null;
  const imageType = mediaResource?.mediaType || null;

  return (
    <div className='c-library__item'>
      <div className='c-question c-question--slider c-question--library'>
        <QuestionTemplateHeader question={question} />
        <div className='c-question__content'>
          <div className='c-question-slider'>
            <div className='c-question-slider__lead'>
              {mediaResource && (
                <MediaItem
                  type={imageType}
                  mediaId={imageId}
                  mediaUrl={imageUrl}
                  imageClassModifier='l-form__item c-upload--fill'
                  videoClassModifier='c-upload--fill'
                  linkToVideo={true}
                />
              )}
            </div>
            <div className='o-input-range o-input-range--emoji'>
              <input
                type='range'
                id='input-poll'
                min={question.choices[0].range[0]}
                max={question.choices[0].range[1]}
                value={inputValue}
                disabled=''
                readOnly
              />
              <span className='o-input-range__value' role='img' aria-label={PreviewUtils.getEmoji(inputValue)[1]}>
                {PreviewUtils.getEmoji(inputValue)[0]}
              </span>
            </div>
          </div>
        </div>
      </div>
      <QuestionTemplateAction question={question} />
    </div>
  );
}

SliderPreview.propTypes = {
  question: PropTypes.shape({
    questionText: PropTypes.string.isRequired,
    choices: PropTypes.array.isRequired,
    resource: PropTypes.array.isRequired,
  }).isRequired,
};

export default SliderPreview;
