import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import { EngageFeedbackTypeFilter } from '../../../../store/FeedbackStore';
import SvgIcon from '../../../../common/components/SvgIcon';

const SentimentTypes = require('../../../../shared/engage').SentimentEnums().SentimentType;

function FeedbackNavFilter(props) {
  const { Store, onChange, onSentimentChange, sentimentScore, sentimentCurrentFilter } = props;

  const ALL = EngageFeedbackTypeFilter.ENGAGE_FEEDBACK_ALL;
  const HIGHLIGHTS = EngageFeedbackTypeFilter.ENGAGE_FEEDBACK_HIGHLIGHTS;
  // const PROBLEMS = EngageFeedbackTypeFilter.ENGAGE_FEEDBACK_PROBLEMS;
  // const REQUESTS = EngageFeedbackTypeFilter.ENGAGE_FEEDBACK_REQUESTS;
  // const OTHER = EngageFeedbackTypeFilter.ENGAGE_FEEDBACK_OTHER;

  return (
    <div className='c-nav-filter'>
      <ul className='c-nav-filter__items'>
        <li className='c-nav-filter__item c-nav-filter__item--label'>Show</li>
        <li className='c-nav-filter__item'>
          <button
            className={`c-nav-filter__action ${Store.filter === ALL ? 'is-active' : ''}`}
            onClick={() => onChange(ALL)}
          >
            <span className='o-label'>All feedback</span>
          </button>
        </li>
        <li className='c-nav-filter__item'>
          <button
            className={`c-nav-filter__action ${Store.filter === HIGHLIGHTS ? 'is-active' : ''}`}
            onClick={() => onChange(HIGHLIGHTS)}
          >
            <span className='o-label'>Highlights</span>
          </button>
        </li>
        {/* <li className='c-nav-filter__item'>
          <button
            className={`c-nav-filter__action ${Store.filter === PROBLEMS ? 'is-active' : ''}`}
            onClick={() => (Store.filter = PROBLEMS)}
          >
            <span className='o-label'>Problems</span>
          </button>
        </li>
        <li className='c-nav-filter__item'>
          <button
            className={`c-nav-filter__action ${Store.filter === REQUESTS ? 'is-active' : ''}`}
            onClick={() => (Store.filter = REQUESTS)}
          >
            <span className='o-label'>Requests</span>
          </button>
        </li>
        <li className='c-nav-filter__item'>
          <button
            className={`c-nav-filter__action ${Store.filter === OTHER ? 'is-active' : ''}`}
            onClick={() => (Store.filter = OTHER)}
          >
            <span className='o-label'>Other</span>
          </button>
        </li> */}
      </ul>
      {sentimentScore && (
        <ul className='c-nav-filter__items'>
          <li className='c-nav-filter__item c-nav-filter__item--label'>Show</li>
          <li className='c-nav-filter__item'>
            <button
              className={`c-nav-filter__action o-tooltip o-tooltip--button o-tooltip--bottom-center ${
                sentimentCurrentFilter === SentimentTypes.ALL ? 'is-active' : ''
              }`}
              onClick={() => onSentimentChange(SentimentTypes.ALL)}
            >
              <span className='o-label'>All sentiments</span>
              <span className='o-tooltip__description'>
                Sentiment is calculated using machine learning and therefore 100% accuracy cannot be guaranteed
              </span>
            </button>
          </li>
          <li className='c-nav-filter__item'>
            <button
              className={`c-nav-filter__action ${
                sentimentCurrentFilter === SentimentTypes.POSITIVE ? 'is-active' : ''
              }`}
              onClick={() => onSentimentChange(SentimentTypes.POSITIVE)}
            >
              <SvgIcon icon='grin' />
              <strong>{` ${sentimentScore.sentimentPositivePercent}% `}</strong>
            </button>
          </li>
          <li className='c-nav-filter__item'>
            <button
              className={`c-nav-filter__action ${sentimentCurrentFilter === SentimentTypes.MIXED ? 'is-active' : ''}`}
              onClick={() => onSentimentChange(SentimentTypes.MIXED)}
            >
              <SvgIcon icon='meh' />
              <strong>{` ${sentimentScore.sentimentMixedPercent}% `}</strong>
            </button>
          </li>
          <li className='c-nav-filter__item'>
            <button
              className={`c-nav-filter__action ${
                sentimentCurrentFilter === SentimentTypes.NEGATIVE ? 'is-active' : ''
              }`}
              onClick={() => onSentimentChange(SentimentTypes.NEGATIVE)}
            >
              <SvgIcon icon='frown' />
              <strong>{` ${sentimentScore.sentimentNegativePercent}% `}</strong>
            </button>
          </li>
          <li className='c-nav-filter__item'>
            <button
              className={`c-nav-filter__action ${sentimentCurrentFilter === SentimentTypes.NEUTRAL ? 'is-active' : ''}`}
              onClick={() => onSentimentChange(SentimentTypes.NEUTRAL)}
            >
              <SvgIcon icon='meh-blank' />
              <strong>{` ${sentimentScore.sentimentNeutralPercent}% `}</strong>
            </button>
          </li>
          {sentimentScore && sentimentScore.uncategorisedPercent > 0 && (
            <li className='c-nav-filter__item'>
              <button
                className={`c-nav-filter__action ${
                  sentimentCurrentFilter === SentimentTypes.UNCATEGORISED ? 'is-active' : ''
                }`}
                onClick={() => onSentimentChange(SentimentTypes.UNCATEGORISED)}
              >
                <SvgIcon icon='archive' />
                <strong>{` ${sentimentScore.uncategorisedPercent}% uncategorised `}</strong>
              </button>
            </li>
          )}
        </ul>
      )}
    </div>
  );
}

FeedbackNavFilter.propTypes = {
  Store: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSentimentChange: PropTypes.func.isRequired,
  sentimentScore: PropTypes.shape({
    sentimentPositivePercent: PropTypes.number,
    sentimentNegativePercent: PropTypes.number,
    sentimentNeutralPercent: PropTypes.number,
    sentimentMixedPercent: PropTypes.number,
    uncategorisedPercent: PropTypes.number,
  }),
  sentimentCurrentFilter: PropTypes.string.isRequired,
};

export default inject((root) => ({ Store: root.RootStore.feedbackStore }))(observer(FeedbackNavFilter));
