import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import SidebarHelp from 'common/components/sidebarHelp/SidebarHelp';
import ProjectMasthead from '../../common/masthead/ProjectMasthead';
import { SETTINGS, PUBLISHING, EMBED, TOOLS, CONFIGURATION } from '../../common/constants/RouteConstants';
import Publishing from './settings/publishing/Publishing';
import Embed from './settings/embed/Embed';
import Aside from '../../common/aside/AsideSettings';
import Tools from './settings/tools/Tools';
import Configuration from './settings/configuration/Configuration';

function SettingsContainer(props) {
  const { match, ProjectStore, HelpStore } = props;
  const id = match.params.id;

  return (
    <div className={`l-page l-page--aside ${HelpStore.isContextualHelpActive ? 'l-page--show-help' : ''}`}>
      <div className='l-masthead'>
        <ProjectMasthead
          id={id}
          route={SETTINGS}
          projectLogo={ProjectStore.project.projectLogo}
          projectName={ProjectStore.project.projectName}
        />
      </div>

      <React.Fragment>
        <Route path={`${match.path}/:route`} component={Aside} />
      </React.Fragment>

      <React.Fragment>
        <Route exact path={`${match.path}/${PUBLISHING}`} component={Publishing} />
        <Route exact path={`${match.path}/${CONFIGURATION}`} component={Configuration} />
        <Route exact path={`${match.path}/${EMBED}`} component={Embed} />
        <Route exact path={`${match.path}/${TOOLS}`} component={Tools} />
      </React.Fragment>

      <SidebarHelp />
    </div>
  );
}

export default inject((root) => ({
  ProjectStore: root.RootStore.projectStore,
  HelpStore: root.RootStore.helpStore,
}))(observer(SettingsContainer));
