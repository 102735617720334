import { action, observable, computed, runInAction, makeObservable } from 'mobx';

import AgentPoll from '../common/agent';

const Agent = AgentPoll.PollsAgent;

export default class PollsListStore {
  _loading = false;

  _initialized = false;

  _error = null;

  _items = [];

  constructor(rootStore) {
    makeObservable(this, {
      _items: observable,
      _loading: observable,
      _initialized: observable,
      _error: observable,

      isLoading: computed,
      isInitialized: computed,
      error: computed,
      polls: computed,
    });

    this.rootStore = rootStore;
  }

  get isLoading() {
    return this._loading;
  }

  get isInitialized() {
    return this._initialized;
  }

  get error() {
    return this._error;
  }

  get polls() {
    return this._items;
  }

  /**
   * Loads all polls for a given project
   */
  async load(projectId) {
    // console.log('Load polls list', projectId);
    this._loading = true;
    this._error = null;

    try {
      const res = await Agent.all(projectId);

      runInAction(() => {
        this._items = res.data;
      });
      return res.data;
    } catch (err) {
      this._error = err;
      this.rootStore.toastrStore.error('Error loading surveys', null, err);
    } finally {
      this.resetFlags();
    }
  }

  create = (projectId, data) => {
    // console.log('Load polls list', projectId);
    this._loading = true;
    this._error = null;
    return Agent.create(projectId, data)
      .then((res) => {
        this.rootStore.toastrStore.success('Survey created', null);
        const pollId = res.data._id;
        this.load(projectId);
        return pollId;
      })
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error('Error loading surveys', null, err);
      })
      .finally(action(() => (this._loading = false)));
  };

  async update(projectId, data) {
    this._loading = false;
    this._error = null;

    try {
      await Agent.reorderPolls(
        projectId,
        data.map((item) => item._id),
      );
      await this.load(projectId);
    } catch (err) {
      this._error = err;
      this.rootStore.toastrStore.error('Error updating items', null, err);
    } finally {
      this.resetFlags();
    }
  }

  resetFlags = () => {
    this._loading = false;
  };
}
