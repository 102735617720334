module.exports = function(){

  let _ = require('lodash');

  const SentimentType = {
      POSITIVE: 'POSITIVE',
      NEGATIVE: 'NEGATIVE',
      NEUTRAL: 'NEUTRAL',
      MIXED: 'MIXED',
      UNCATEGORISED: 'UNCATEGORISED', // for nonprocessed items
      ALL: 'ALL' // Note: 'ALL' added for query purposes. Not present in AWS Comprehend protocol!!!
    };

  return {
    SentimentType: SentimentType,
    sentimentTypeList: sentimentTypeList,
  };

  function sentimentTypeList(){
    return _.map(Object.keys(SentimentType), (key) => { return SentimentType[key]; });
  }


};
