import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from 'common/components/SvgIcon';
import FilterList from 'common/components/filterList/FilterList';
import FilterSearch from 'common/components/filterSearch/FilterSearch';
import { CREATE_PROJECT } from 'common/constants/RouteConstants';
import { Link } from 'react-router-dom';
import { PROJECT_FILTER_OPTIONS } from 'common/FilterUtils';

function ProjectsBar(props) {
  const { filterState, setFilterState } = props.filter;

  function filterListChangeHandler(newOption) {
    setFilterState({ ...filterState, selectedFilterOption: newOption });
  }

  function filterSearchChangeHandler(newValue) {
    setFilterState({ ...filterState, searchValue: newValue });
  }

  return (
    <div className='c-action-bar' data-testid='projects-filter-wrapper'>
      <h1 className='c-action-bar__title'>Projects</h1>
      <div className='c-action-bar__action'>
        <FilterList
          availableOptions={PROJECT_FILTER_OPTIONS}
          onFilterChange={filterListChangeHandler}
          selectedOption={filterState.selectedFilterOption}
        />
        <FilterSearch
          searchValue={filterState.searchValue}
          onFilterChange={filterSearchChangeHandler}
          placeholder='Filter projects'
        />
        <Link className='o-button o-button--primary' to={CREATE_PROJECT}>
          <span className='o-label'>New project</span>
        </Link>
      </div>
    </div>
  );
}

ProjectsBar.propTypes = {
  filter: PropTypes.shape({
    filterState: PropTypes.object.isRequired,
    setFilterState: PropTypes.func.isRequired,
  }).isRequired,
};

export default ProjectsBar;
