/**
 * Sample combination react-sortable-hoc+Mobx in
 * https://codesandbox.io/s/5yp8r35p14
 */

import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { inject, observer } from 'mobx-react';
import { Form } from 'react-final-form';

import { SAMPLE_URL, validateUrl } from 'common/UrlUtils';
import SwitchableHeader from '../../../common/components/switchableHeader/SwitchableHeader';
import EngageTextInputField from '../../../common/components/form-elements/textInputField/TextInputField';
import SwitchableButtonBar from '../../../common/components/switchableButtonBar/SwitchableButtonBar';
import SlimFileUpload from '../../../common/components/form-elements/fileUpload/SlimFileUpload';
import { IMAGE_FORMATS } from '../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import displayConfirmationPrompt from '../../../common/PromptUtil';
import { getFormFieldClass, getFormFieldTranslationTooltip } from '../../../common/TranslationUtils';

/**
 * Team form
 */
function TeamMemberFn(props) {
  const { member, TeamStore, ToastrStore } = props;
  const imageId = member.data.resource;
  const defaultImageId = member.defaultData.resource;

  const toggleOpened = () => {
    TeamStore.toggleOpened([member.data._id]);
  };

  const remove = (event) => {
    event.preventDefault();

    const res = displayConfirmationPrompt(null, null, false, ToastrStore);

    if (res === 1) TeamStore.deleteTeamMember(member.data._id);
  };

  const onSubmit = (values) => {
    // console.log('Submit', values);
    TeamStore.updateTeamMember(member.data._id, {
      resource: values.resource,
      title: values.title ? values.title : '',
      url: values.url,
      role: values.role ? values.role : '',
    });
  };

  const uploadImage = (file, progressHandler) => {
    return TeamStore.uploadMemberLogo(member.data._id, file, progressHandler);
  };

  const removeImage = (event) => {
    event.preventDefault();
    return TeamStore.removeMemberLogo(member.data._id);
  };

  return (
    <div
      className={`c-switchable ${props.switchableModifier} ${member.formState.isOpened ? 'is-opened' : 'is-closed'}`}
    >
      <SwitchableHeader
        id={member.data._id}
        label='Team'
        name={member.data.title}
        icon='building'
        toggleOpened={toggleOpened}
      />
      <div className='c-switchable__content'>
        <Form
          onSubmit={onSubmit}
          keepDirtyOnReinitialize={true}
          initialValues={member.data}
          render={({ values, form, handleSubmit, submitting, pristine }) => {
            return (
              <form className='c-fields l-form'>
                <EngageTextInputField
                  id={`input-member-name-${member.data._id}`}
                  placeholder='Name'
                  label='Name'
                  maxLength='30'
                  fieldDescription=''
                  fieldName='title'
                  isRequired={true}
                  fieldClass={getFormFieldClass(member.defaultData.title, null, 'l-form__item--m')}
                  tooltipInLabel={getFormFieldTranslationTooltip(member.defaultData.title)}
                />

                <EngageTextInputField
                  id={`input-member-role-${member.data._id}`}
                  placeholder='Role'
                  label='Role'
                  maxLength='30'
                  fieldDescription=''
                  fieldName='role'
                  isRequired={false}
                  fieldClass={getFormFieldClass(member.defaultData.role, null, 'l-form__item--m')}
                  tooltipInLabel={getFormFieldTranslationTooltip(member.defaultData.role)}
                />

                <EngageTextInputField
                  id={`input-member-url-${member.data._id}`}
                  placeholder={SAMPLE_URL}
                  label='URL'
                  maxLength='100'
                  fieldDescription=''
                  fieldName='url'
                  isRequired={false}
                  fieldClass={getFormFieldClass(member.defaultData.url)}
                  tooltipInLabel={getFormFieldTranslationTooltip(member.defaultData.url)}
                  validator={validateUrl}
                />

                <SlimFileUpload
                  imageFormat={IMAGE_FORMATS.LOGO}
                  imageId={imageId}
                  defaultImageId={defaultImageId}
                  isSharedResource={true}
                  id={`${member.data._id}`}
                  handleUpload={uploadImage}
                  label='Logo'
                  removeImage={removeImage}
                  enableLibrarySearch={false}
                />

                <SwitchableButtonBar updateAction={handleSubmit} removeAction={remove} isNew={!member.data._id} />
              </form>
            );
          }}
        />
      </div>
    </div>
  );
}
const TeamMember = inject((root) => ({ TeamStore: root.RootStore.teamStore, ToastrStore: root.RootStore.toastrStore }))(
  observer(TeamMemberFn),
);

/**
 * Sortable item wrapper
 */
const SortableTeamMember = SortableElement((props) => {
  return <TeamMember {...props} />;
});

export default SortableTeamMember;
// export default TeamMember;
