/**
 * Returns
 * 1 if prompt matched or confirmed by clicking "OK"
 * 0 if user cancel
 * -1 if prompt NOT matched
 *
 * If ToastrStore is defined raises Toastr message, so caller can ignore error handling
 *
 * @param title
 * @param phraseToMatch
 * @param acceptPartialMatch
 * @param isPhraseConfirmation
 * @param ToastrStore
 * @returns {boolean|number}
 */
export default function displayConfirmationPrompt(
  title,
  phraseToMatch,
  acceptPartialMatch,
  ToastrStore,
  isPhraseConfirmation = false,
) {
  let trimmedTitle = title ? title.trim() : '';
  let trimmedPhraseToMatch = phraseToMatch ? phraseToMatch.trim() : phraseToMatch;
  if (isPhraseConfirmation) {
    trimmedTitle = title || `Please enter 'DELETE' to delete item.`;
    trimmedPhraseToMatch = trimmedPhraseToMatch || 'DELETE';
  } else {
    trimmedTitle = title || `Press 'OK' to confirm action.`;
  }

  let answer;
  if (isPhraseConfirmation) {
    answer = prompt(trimmedTitle, '');
  } else {
    // eslint-disable-next-line no-restricted-globals
    answer = confirm(trimmedTitle, '');
  }

  // case Cancel
  if ((answer === null || !answer) && answer !== '') {
    return 0;
  }

  // partial match - enforced on string start
  const isPartialMatch = acceptPartialMatch && answer.length > 7 && trimmedPhraseToMatch.indexOf(answer) === 0;

  if (answer === trimmedPhraseToMatch || isPartialMatch || !isPhraseConfirmation) {
    return 1;
  }
  // case failed match
  if (ToastrStore) {
    ToastrStore.error('Entered phrase does not match required phrase!', 'Confirmation failed', null);
  }
  return -1;
}
