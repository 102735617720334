import { reaction, action, observable, computed, makeObservable } from 'mobx';

import AgentPoll from '../common/agent';
import { EngagePhaseType, EngagePhaseSize } from '../containers/project/timeline/StageEnums';

const Agent = AgentPoll.TimelineAgent;

const ITEM_LABEL = 'phase';
const SUB_ITEM_LABEL = 'stage';

const CREATE_SUCCESS = `New ${ITEM_LABEL} added`;
const CREATE_ERROR = `Error creating ${ITEM_LABEL}`;
const REORDER_ERROR = `Error reordering ${ITEM_LABEL}`;
const UPDATE_PHASE_SUCCESS = 'Saved';
const UPDATE_PHASE_ERROR = `Error saving ${ITEM_LABEL}`;
const DELETE_PHASE_SUCCESS = 'Phase removed';
const DELETE_PHASE_ERROR = `Error deleting ${ITEM_LABEL}`;
const RESOURCE_DELETE_ERROR = 'Error deleting image';

const CREATE_UPDATE_SUCCESS = `New ${SUB_ITEM_LABEL} added`;
const CREATE_UPDATE_ERROR = `Error creating ${SUB_ITEM_LABEL}`;
const DELETE_UPDATE_SUCCESS = 'Stage removed';
const DELETE_UPDATE_ERROR = `Error deleting ${SUB_ITEM_LABEL}`;
const UPDATE_STAGE_SUCCESS = 'Saved';
const UPDATE_STAGE_ERROR = `Error saving ${SUB_ITEM_LABEL}`;

export default class TimelineStore {
  _loading = false;

  _initialized = false;

  _error = null;

  _items = [];

  constructor(rootStore, projectStore) {
    makeObservable(this, {
      _items: observable,
      _loading: observable,
      _initialized: observable,
      _error: observable,

      resetFlags: action,

      phases: computed,
      isLoading: computed,
      isInitialized: computed,
      error: computed,

      loadData: action,
      toggleOpened: action,
    });

    this.rootStore = rootStore;
    this.projectStore = projectStore;
    reaction(
      () => this.projectStore.isInitialized,
      () => this.loadData(this.projectStore.project.timeline, this.projectStore.projectDefault.timeline),
    );
  }

  get isLoading() {
    return this._loading;
  }

  get isInitialized() {
    return this._initialized;
  }

  get error() {
    return this._error;
  }

  get phases() {
    return this._items;
  }

  /**
   * Load and prepare data already loaded in ProjectStore
   * @param phases
   */
  loadData(phases, phasesDefault) {
    const tmpPhases = [];
    phases.map((phase) => {
      const defaultPhase = phasesDefault.find((teamDef) => teamDef._id === phase._id);
      const mergedPhase = { ...phase };
      mergedPhase.defaultData = { ...defaultPhase };

      mergedPhase.updates.forEach((element) => {
        const defaultUpdate = mergedPhase.defaultData.updates.find((updateDef) => element._id === updateDef._id);
        // eslint-disable-next-line no-param-reassign
        element.defaultData = { ...defaultUpdate };
      });

      tmpPhases.push(mergedPhase);

      return mergedPhase;
    });
    this._items = tmpPhases;
    this._initialized = true;
  }

  /**
   * Toggle item/form open/close status
   * @param item
   */
  // eslint-disable-next-line
  toggleOpened(item) {
    if (item) {
      // eslint-disable-next-line
      item.isOpened = !item.isOpened;
    }
  }

  // ###
  // PHASES
  // ###

  /**
   * Create EMPTY phase  POST {{URL}}/projects/{{projectId}}/timeline/phase
   */
  createPhase = () => {
    // console.log('CREATE PHASE');
    this._loading = true;
    this._error = null;
    return Agent.createPhase(this.projectStore.project._id, { phaseTitle: 'New Phase', phasePercentage: 0 })
      .then((res) => {
        this.rootStore.toastrStore.success(CREATE_SUCCESS);
        this.refreshAndKeepItemOpen(res.data._id);
      })
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(CREATE_ERROR, null, err);
      })
      .finally(this.resetFlags);
  };

  /**
   * Remove phase DELETE project}/timeline/phase/:phaseId
   */
  removePhase = (phaseId) => {
    // console.log('DELETE PHASE');
    this._loading = true;
    this._error = null;
    return Agent.removePhase(this.projectStore.project._id, phaseId)
      .then(() => {
        this.rootStore.toastrStore.success(DELETE_PHASE_SUCCESS);
        this.refreshAndKeepItemOpen(phaseId);
      })
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(DELETE_PHASE_ERROR, null, err);
      })
      .finally(this.resetFlags);
  };

  /**
   * Update phase PATCH project}/timeline/phase/:phaseId
   */
  updatePhase = (phaseId, data) => {
    // console.log('UPDATE PHASE');
    this._loading = true;
    this._error = null;
    return Agent.updatePhase(this.projectStore.project._id, phaseId, data)
      .then(() => {
        this.rootStore.toastrStore.success(UPDATE_PHASE_SUCCESS);
        this.refreshAndKeepItemOpen(phaseId);
      })
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(UPDATE_PHASE_ERROR, null, err);
      })
      .finally(this.resetFlags);
  };

  /**
   * Reorder phases POST {project}/timeline/reorder
   * @param data
   */
  async reorderPhases(data) {
    this._loading = false;
    this._error = null;

    try {
      await Agent.reorderPhases(
        this.projectStore.project._id,
        data.map((item) => item._id),
      );
      await this.projectStore.refresh();
    } catch (err) {
      this._error = err;
      this.rootStore.toastrStore.error(REORDER_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  // ###
  // UPDATES / STAGES
  // on UI we have Phase + Stage and in DB/API we have Phase + Update
  // ###

  /**
   * Reorder phases POST {project}/timeline/reorder/:phaseId/update/reorder
   * @param data
   */
  async reorderUpdates(phaseId, data) {
    this._loading = false;
    this._error = null;

    try {
      await Agent.reorderUpdates(
        this.projectStore.project._id,
        phaseId,
        data.map((item) => item._id),
      );
      await this.projectStore.refresh();
    } catch (err) {
      this._error = err;
      this.rootStore.toastrStore.error(REORDER_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  /**
   * Create EMPTY update  POST {project}/timeline/reorder/:phaseId/update
   */
  createUpdate = (phaseId) => {
    // console.log('CREATE UPDATE');
    this._loading = true;
    this._error = null;

    const data = {
      title: 'New update',
      type: EngagePhaseType.FUTURE,
      size: EngagePhaseSize.MEDIUM,
    };

    return Agent.createUpdate(this.projectStore.project._id, phaseId, data)
      .then((res) => {
        // console.log('New member', res.data._id);
        this.rootStore.toastrStore.success(CREATE_UPDATE_SUCCESS);
        this.refreshAndKeepUpdateOpen(res.data._id);
      })
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(CREATE_UPDATE_ERROR, null, err);
      })
      .finally(this.resetFlags);
  };

  /**
   * Remove update DELETE project}/timeline/phase/:phaseId/update/:updateId
   */
  removeUpdate = (phaseId, updateId) => {
    // console.log('DELETE STAGE');
    this._loading = true;
    this._error = null;
    return Agent.removeUpdate(this.projectStore.project._id, phaseId, updateId)
      .then(() => {
        this.rootStore.toastrStore.success(DELETE_UPDATE_SUCCESS);
        this.refreshAndKeepUpdateOpen();
      })
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(DELETE_UPDATE_ERROR, null, err);
      })
      .finally(this.resetFlags);
  };

  /**
   * Update stage PATCH {project}/timeline/phase/:phaseId/update/:updateId
   */
  updateStage(phaseId, updateId, data) {
    // console.log('UPDATE STORE', data);
    this._loading = true;
    this._error = null;
    return Agent.updateStage(this.projectStore.project._id, phaseId, updateId, data)
      .then(() => {
        // console.log('New member', res.data._id);
        this.rootStore.toastrStore.success(UPDATE_STAGE_SUCCESS);
        this.refreshAndKeepUpdateOpen(updateId);
      })
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(UPDATE_STAGE_ERROR, null, err);
      })
      .finally(this.resetFlags);
  }

  /**
   * Upload Stage resource
   * POST {{projectId}}/timeline/phase/{{phaseId}}/update/{{phaseUpdateId}}/resource
   */
  uploadStageResource(phaseId, stageId, file, progress) {
    // console.log('UPLOAD STAGE IMAGE');
    this._loading = true;
    this._error = null;
    return (
      Agent.uploadUpdateResource(this.projectStore.project._id, phaseId, stageId, file, progress)
        // .then(() => this.refreshAndKeepUpdateOpen(stageId))
        .then(
          action((res) => {
            this.findStage(stageId).resource = res.data;
          }),
        )
        .finally(this.resetFlags)
    );
  }

  /**
   * remove Stage resource
   * DELETE {{projectId}}/timeline/phase/{{phaseId}}/update/{{phaseUpdateId}}/resource
   */
  removeStageResource = (phaseId, stageId) => {
    // console.log('REMOVE STAGE IMAGE');
    this._loading = true;
    this._error = null;
    return (
      Agent.removeUpdateResource(this.projectStore.project._id, phaseId, stageId)
        // .then(() => this.refreshAndKeepUpdateOpen(stageId))
        .then(
          action(() => {
            this.findStage(stageId).resource = null;
          }),
        )
        .catch((err) => {
          this._error = err;
          this.rootStore.toastrStore.error(RESOURCE_DELETE_ERROR, null, err);
        })
        .finally(this.resetFlags)
    );
  };

  refreshAndKeepItemOpen = (phaseId) => {
    this.projectStore.refresh().then(() => {
      const item = this._items.find((decision) => decision._id === phaseId);
      this.toggleOpened(item);
    });
  };

  /**
   * Finds stage by id
   * Looking through 2 levels phase/stage
   * @param updateId
   * @returns {*}
   */
  findStage = (updateId) => {
    // pick phase where any of the children has updateId
    const phase = this._items.find((_phase) => _phase.updates.find((_update) => _update._id === updateId));
    // then from updates pick the one
    return phase.updates.find((_update) => _update._id === updateId);
  };

  refreshAndKeepUpdateOpen = (updateId) =>
    this.projectStore.refresh().then(() => {
      this._items.map((phase) => {
        const update = phase.updates.find((_update) => _update._id === updateId);
        this.toggleOpened(update);
        return update;
      });
      return updateId;
    });

  resetFlags = () => {
    this._loading = false;
  };
}
