import agent from './agent';

const requests_v2 = agent.requestsV2;
const requests = agent.requests;
const ENDPOINT = 'clients';

const ClientAgent = {
  getAllClients: async () => requests.get(`/${ENDPOINT}`),
  getRecentClientsAndProjects: async () => requests_v2.get(`/${ENDPOINT}/overview`),
  registerAsVisited: (data) => requests_v2.post(`/visit`, data),
};

export default ClientAgent;
