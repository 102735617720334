import React from 'react';
import PropTypes from 'prop-types';
import QuestionTemplateAction from './QuestionTemplateAction';
import { IMAGE_FORMATS } from '../../../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import ImageUtils from '../../../../../common/ImageUtils';
import QuestionTemplateHeader from './QuestionTemplateHeader';

function FourImagePreview(props) {
  const { question } = props;

  return (
    <div className='c-library__item'>
      <div className='c-question c-question--image-four c-question--library'>
        <QuestionTemplateHeader question={question} />
        <div className='c-question__content'>
          <div className='c-question-image c-question-image--four'>
            <div className='c-question-image__items'>
              {question.choices.map((choice, idx) => (
                <div key={idx} className='c-question-image__item'>
                  <div className='c-question-image__media'>
                    <img src={ImageUtils.imageUrl(choice.resource, IMAGE_FORMATS.SQUARE.imageType)} alt='' />
                  </div>
                  <h3 className='c-question-image__label'>{choice.choice}</h3>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <QuestionTemplateAction question={question} />
    </div>
  );
}

FourImagePreview.propTypes = {
  question: PropTypes.shape({
    questionText: PropTypes.string.isRequired,
    choices: PropTypes.array.isRequired,
  }).isRequired,
};

export default FourImagePreview;
