import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { EDIT_USERS } from '../../../common/constants/RouteConstants';

export default function UserGridRow(props) {
  const { baseUrl, user } = props;
  const { _id, name, email, roles, adminMayEditUser } = user;
  const redirectUrl = adminMayEditUser ? `${baseUrl}/${EDIT_USERS}/${_id}` : baseUrl;

  return (
    <tr>
      <td className='c-table__name'>
        <Link to={redirectUrl}>{name}</Link>
      </td>
      <td>{email}</td>
      <td>{roles[0] ? roles[0].label : ''}</td>
      <td className='c-table__actions'>
        {adminMayEditUser && (
          <Link className='o-button o-button--xs' to={redirectUrl}>
            Edit
          </Link>
        )}
      </td>
    </tr>
  );
}

UserGridRow.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    roles: PropTypes.array.isRequired,
    adminMayEditUser: PropTypes.bool.isRequired,
  }).isRequired,
  baseUrl: PropTypes.string.isRequired,
};
