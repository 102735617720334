/* eslint-disable */
// import { toJS } from 'mobx';
import React, { useState, useEffect, useContext, useRef, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import html2canvas from 'html2canvas';
import { MarkerClusterer, MarkerUtils, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
// eslint-disable-next-line import/no-extraneous-dependencies
import _, { set } from 'lodash';
// eslint-disable-next-line import/no-cycle
import { PollReportContext } from '../PollsReport';
import QuestionFooter from '../../QuestionFooter';
import QuestionHeader from '../../QuestionHeader';
import PreviewUtils from '../../../../../common/PreviewUtils';
import agent from '../../../../../common/agent/ReportsAgent';
import { IMAGE_FORMATS } from '../../../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import ImageUtils, { ACCESS_IMAGES_DIRECTLY_ON_S3 } from '../../../../../common/ImageUtils';
import GOOGLE_MAP_ENUMS from '../../../../../shared/engage/engage-google-map-enums';
import AnnotatePreviewReactionsList from './AnnotatePreviewReactionsList';
import { DefaultValueEnums } from '../../../../../shared/engage';
import ReportUtils from '../../../../../common/ReportUtils';
import MapUtils from 'common/components/map/MapUtils';
import AnnotateQuestionControls from 'common/components/annotateQuestion/annotateQuestionControls/AnnotateQuestionControls';

const google = window.google;

const shapeTypes = GOOGLE_MAP_ENUMS().shapeTypes;
const roadmapOverlayStyle = { ...GOOGLE_MAP_ENUMS().roadmapOverlayStyle, editable: false, draggable: false };
const satelliteOverlayStyle = { ...GOOGLE_MAP_ENUMS().satelliteOverlayStyle, editable: false, draggable: false };
const mapOptions = GOOGLE_MAP_ENUMS().mapOptions;
const mapStyle = GOOGLE_MAP_ENUMS().locationMapStyle;

const MARKER_ICON_SIZE = 32;

function AnnotateMapPreview(props) {
  const { PollReportStore, ToastrStore, GeneralStore, question, fromDate, toDate, pollName, onOpenFeedbackModal } =
    props;
  const { questionId, choices, countAnswered, questionMap, skipSeen, questionConfiguration } = question;

  const [map, setMap] = useState();
  const [area, setArea] = useState();
  const [markers, setMarkers] = useState();
  const [clusterMarkers, setClusterMarkers] = useState();
  const [cluster, setCluster] = useState();
  const [choicesSelection, setChoicesSelection] = useState({});
  const [choicesCount, setChoicesCount] = useState(0);
  const [resetMarkers, setResetMarkers] = useState();
  const [infoWindow, setInfoWindow] = useState();

  const [drawnAreaWidth, setDrawnAreaWidth] = useState(1);
  const [drawnAreaHeight, setDrawnAreaHeight] = useState(1);
  const [maxZoomLevel, setMaxZoomLevel] = useState(1);

  const pollReportContext = useContext(PollReportContext);
  const snapshotContainer = useRef();

  // eslint-disable-next-line no-shadow
  const addShape = useCallback((questionMap, map) => {
    if (!questionMap.shape) return;

    let definedArea;
    const currentOverlayStyle =
      questionMap.mapTypeId === google.maps.MapTypeId.ROADMAP || !questionMap.mapTypeId
        ? roadmapOverlayStyle
        : satelliteOverlayStyle;
    switch (questionMap.shape) {
      case shapeTypes.CIRCLE:
        // eslint-disable-next-line no-case-declarations
        const circle = new google.maps.Circle({
          map,
          center: new google.maps.LatLng(questionMap.circle.center.lat, questionMap.circle.center.lng),
          radius: questionMap.circle.radius,
          ...currentOverlayStyle,
        });

        map.fitBounds(circle.getBounds());
        definedArea = { area: circle, bounds: circle.getBounds() };
        break;
      case shapeTypes.POLYGON:
        // eslint-disable-next-line no-case-declarations
        const polygonCoords = [];
        questionMap.polygon.path.forEach((coordinate) => {
          polygonCoords.push({ lat: coordinate.lat, lng: coordinate.lng });
        });

        // eslint-disable-next-line no-case-declarations
        const polygon = new google.maps.Polygon({
          map,
          paths: polygonCoords,
          ...currentOverlayStyle,
        });

        // eslint-disable-next-line vars-on-top, no-var
        var bounds = new google.maps.LatLngBounds();
        polygonCoords.forEach((coord) => {
          bounds.extend(coord);
        });
        map.fitBounds(bounds);
        definedArea = { area: polygon, bounds };
        break;
      case shapeTypes.RECTANGLE:
        const north = questionMap.rectangle.bounds.ne.lat;
        const south = questionMap.rectangle.bounds.sw.lat;
        const east = questionMap.rectangle.bounds.ne.lng;
        const west = questionMap.rectangle.bounds.sw.lng;

        const rectangle = new google.maps.Rectangle({
          map,
          bounds: { north, south, east, west },
          ...currentOverlayStyle,
        });

        map.fitBounds(rectangle.getBounds());
        definedArea = { area: rectangle, bounds: rectangle.getBounds() };
        break;
      default:
        break;
    }

    const dimensions = MapUtils.getBoundsWidthAndHeight(definedArea.bounds);
    setDrawnAreaHeight(dimensions.height);
    setDrawnAreaWidth(dimensions.width);
    setArea(definedArea);
  }, []);

  const addMarkers = useCallback(async (selectedChoices, map, mapInfoWindow, maxZoomLevel = 10) => {
    const preparedMarkerGroups = [];
    const allMarkers = [];
    let count = 0;

    if (selectedChoices && map) {
      selectedChoices.forEach((choice) => {
        const preparedMarkers = [];
        // console.log(toJS(selectedChoices));

        if (choice.mapPins) {
          choice.mapPins.forEach((reaction) => {
            // In case reaction was removed from annotate question and resource value was permanently deleted
            const resourceIcon = choice.resource || DefaultValueEnums().DELETED_ANNOTATE_REACTION;

            // console.log(toJS(reaction));
            const marker = new google.maps.Marker({
              position: new google.maps.LatLng(reaction.lat, reaction.lng),
              map: map,
              icon: {
                url: ImageUtils.pngImageUrl(
                  resourceIcon,
                  IMAGE_FORMATS.REACTION.imageType,
                  ACCESS_IMAGES_DIRECTLY_ON_S3,
                ),
                scaledSize: new google.maps.Size(MARKER_ICON_SIZE, MARKER_ICON_SIZE),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(MARKER_ICON_SIZE / 2, MARKER_ICON_SIZE / 2),
              },
              opacity: choice.isDeleted ? 0.5 : 1,
            });

            google.maps.event.addListener(marker, 'dblclick', () => {
              map.setZoom(map.getZoom() + 1);
            });

            preparedMarkers.push(marker);
            allMarkers.push(marker);

            marker.feedback = reaction.feedback;
            // Sax
            marker.feedback.resource = choice.resource;
            marker.feedback.label = choice.label;

            google.maps.event.addListener(marker, 'click', () => {
              const infoWindowConentString = ReportUtils.prepareMapMarkerFeedbackInfoWindow(marker.feedback);

              mapInfoWindow.setContent(infoWindowConentString);
              // console.log(marker);
              mapInfoWindow.setPosition({
                lat: marker.getPosition().lat(),
                lng: marker.getPosition().lng(),
              });
              mapInfoWindow.open(map);
            });
          });

          count += choice.mapPins.length;
        }

        preparedMarkerGroups.push({ choiceId: choice.choiceId, markers: preparedMarkers });
      });
    }

    setMarkers([...preparedMarkerGroups]);
    setChoicesCount(count);
    setClusterMarkers(allMarkers);

    google.maps.event.addListener(map, 'zoom_changed', () => {
      if (mapInfoWindow) mapInfoWindow.close();
    });
  }, []);

  const clearOverlays = useCallback((markers, cluster) => {
    if (markers?.length > 0) {
      markers.forEach((markerGroup) => {
        markerGroup.markers.forEach((marker) => {
          marker.setMap(null);
          marker = null;
        });
      });
      setMarkers(null);

      if (cluster) {
        cluster.clearMarkers();
        setCluster(null);
      }
    }
  }, []);

  useEffect(() => {
    if (clusterMarkers?.length && drawnAreaHeight && drawnAreaWidth && maxZoomLevel) {
      const markerCluster = new MarkerClusterer({
        algorithm: new SuperClusterAlgorithm({ maxZoom: maxZoomLevel, radius: 110 }),
        map: map,
        markers: clusterMarkers,
        renderer: MapUtils.clusterRenderer,
      });
      setCluster(markerCluster);
    }
  }, [clusterMarkers, drawnAreaHeight, drawnAreaWidth, maxZoomLevel]);

  useEffect(() => {
    // Draw map
    if (!map && questionMap) {
      // console.log('useEffect create map');
      const mapInstance = new google.maps.Map(document.getElementById(`map-${questionId}`), {
        center: new google.maps.LatLng(mapOptions.lat, mapOptions.lng),
        mapTypeId: questionMap.mapTypeId ? questionMap.mapTypeId : google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: true,
        styles: mapStyle,
        zoom: 10,
      });

      // Draw custom layer on the map
      if (questionMap.customLayer)
        new google.maps.KmlLayer(questionMap.customLayer, {
          preserveViewport: true,
          map: mapInstance,
        });

      addShape(questionMap, mapInstance);

      setMap(mapInstance);

      let infoWindow = new google.maps.InfoWindow();
      setInfoWindow(infoWindow);
    }
  }, [addShape, choices, map, questionId, questionMap]);

  useEffect(() => {
    // If map data gets updated we'll remove all overlays from map
    if (questionMap && cluster && markers && resetMarkers) {
      // console.log('useEffect clearOverlays');
      clearOverlays(markers, cluster);
      setResetMarkers(false);
    }
  }, [clearOverlays, cluster, markers, questionMap, resetMarkers]);

  useEffect(() => {
    // console.log('useEffect setResetMarkers');
    setResetMarkers(true);
  }, [choices]);

  useEffect(async () => {
    // If map data gets updated we'll add markers to the map
    if (choices && map && !markers) {
      const maxZoomLevel = await MapUtils.getMaxZoomLevel(new google.maps.LatLng(mapOptions.lat, mapOptions.lng));
      setMaxZoomLevel(maxZoomLevel);

      addMarkers(choices, map, infoWindow);

      let selection = {};
      choices.forEach((choice) => {
        selection[choice.choiceId] = true;
      });
      setChoicesSelection(selection);
    }
  }, [addMarkers, choices, map, markers, questionId, infoWindow]);

  useEffect(() => {
    if (pollReportContext) {
      // console.log('useEffect pollReportContext');
      pollReportContext.addReference({ id: questionId, containerReference: snapshotContainer.current });
    }
  }, [pollReportContext, questionId]);

  function onSelectionChange(event, choiceId) {
    // Maintain choice checkbox state
    const newCS = _.clone(choicesSelection);
    newCS[choiceId] = event.target.checked;
    setChoicesSelection(newCS);

    // Prepare new data set for map
    const selectedChoiceIds = [];
    Object.entries(newCS).forEach((entrie) => {
      if (entrie[1]) selectedChoiceIds.push(entrie[0]);
    });
    const remainingChoices = choices.filter((choice) => selectedChoiceIds.includes(choice.choiceId));

    // Clear all map overlays (markers, cluster) and redraw with new data set
    clearOverlays(markers, cluster);
    addMarkers(remainingChoices, map, infoWindow);

    if (infoWindow) infoWindow.close();
  }

  function handleDownload() {
    PreviewUtils.excelDownload(
      agent,
      PollReportStore.projectId,
      PollReportStore.pollId,
      questionId,
      ToastrStore,
      fromDate,
      toDate,
      PollReportStore.segmentChoiceId,
      PollReportStore.demographicChoiceId,
    );
  }

  function handlePreview() {
    PollReportStore.feedbackModalActivatorQuestion = question;
    PollReportStore.feedbackModalActivatorReactions = choices;
    PollReportStore.feedbackModalOpen = true;
    onOpenFeedbackModal();
  }

  function handleSnapshot() {
    GeneralStore.isSnapshotting = true;
    GeneralStore.isSnapshottingSingleQuestion = true;

    setTimeout(() => {
      html2canvas(snapshotContainer.current, {
        useCORS: true,
        scrollX: -8,
        scrollY: -window.scrollY,
        removeContainer: true,
      })
        .then(function (canvas) {
          const a = document.createElement('a');
          a.href = canvas.toDataURL('image/png');
          a.download = `${pollName}-${question.label}.png`;
          a.click();
        })
        .catch(function (error) {
          ToastrStore.error('Exporting overview report failed!', null, error);
        })
        .finally(function () {
          GeneralStore.isSnapshotting = false;
          GeneralStore.isSnapshottingSingleQuestion = false;
        });
    }, 1000);
  }

  const onZoomIn = () => {
    map.setZoom(map.getZoom() + 1);
  };

  const onZoomOut = () => {
    map.setZoom(map.getZoom() - 1);
  };

  const onReset = () => {
    if (map && area?.bounds) map.fitBounds(area.bounds);
  };

  return (
    <div ref={snapshotContainer} className='c-poll-preview__item'>
      <div className='c-question'>
        <QuestionHeader
          question={question}
          onSnapshot={handleSnapshot}
          exportInProgress={GeneralStore.isSnapshotting}
        />

        <div className='c-question__content'>
          <div className='c-result-annotate c-result-annotate--map'>
            {!GeneralStore.isSnapshotting && (
              <AnnotateQuestionControls onZoomIn={onZoomIn} onZoomOut={onZoomOut} onReset={onReset} />
            )}
            <div className='o-map'>
              <div id={`map-${questionId}`} className='o-map__content'></div>
            </div>
          </div>

          <div className='c-result-group'>
            <ul className='c-result-group__items'>
              {choices
                .slice()
                .sort((choiceA, choiceB) => choiceB.count - choiceA.count)
                .map((choice, idx) => (
                  <AnnotatePreviewReactionsList
                    key={idx}
                    choice={choice}
                    choicesCount={choicesCount}
                    onSelectionChange={onSelectionChange}
                  />
                ))}
            </ul>
            {!GeneralStore.isSnapshotting && (
              <Fragment>
                {(questionConfiguration.allowComments || questionConfiguration?.allowUserContentUpload?.image) && (
                  <button className='o-button o-button--s' type='button' onClick={handlePreview}>
                    <span className='o-label'>Preview</span>
                  </button>
                )}
                <button className='o-button o-button--s o-button--primary' type='button' onClick={handleDownload}>
                  <span className='o-label'>Download spreadsheet</span>
                </button>
              </Fragment>
            )}
          </div>
        </div>

        <QuestionFooter answered={countAnswered} skipped={skipSeen.skipCount} viewed={skipSeen.seenCount} />
      </div>
    </div>
  );
}

AnnotateMapPreview.propTypes = {
  ToastrStore: PropTypes.object.isRequired,
  PollReportStore: PropTypes.object.isRequired,
  GeneralStore: PropTypes.object.isRequired,
  question: PropTypes.shape({
    questionId: PropTypes.string.isRequired,
    countAnswered: PropTypes.number,
    choices: PropTypes.array,
    questionType: PropTypes.string.isRequired,
    multiChoicesOfferedBound: PropTypes.number,
    multiChoicesSelectableBound: PropTypes.number,
    skipSeen: PropTypes.shape({
      seenCount: PropTypes.number,
      skipCount: PropTypes.number,
    }),
  }).isRequired,
  fromDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  toDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  pollName: PropTypes.string.isRequired,
  onOpenFeedbackModal: PropTypes.func,
};

export default inject((root) => ({
  ToastrStore: root.RootStore.toastrStore,
  PollReportStore: root.RootStore.pollsReportStore,
  GeneralStore: root.RootStore.generalStore,
}))(observer(AnnotateMapPreview));
