import React from 'react';
import PropTypes from 'prop-types';
import QuestionTemplateAction from './QuestionTemplateAction';
import { IMAGE_FORMATS } from '../../../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import ImageUtils from '../../../../../common/ImageUtils';
import QuestionTemplateHeader from './QuestionTemplateHeader';

function MultiImageChoicePreview(props) {
  const { question } = props;

  function perc(item, total) {
    return total > 0 ? Math.round((item / total) * 100) : 0;
  }

  return (
    <div className='c-library__item'>
      <div className='c-question c-question--image-multiple c-question--library'>
        <QuestionTemplateHeader question={question} />
        <div className='c-question__content'>
          <div className='c-question-multiple'>
            <div className='c-question-multiple__items'>
              {question.choices
                .slice(0, question.multiChoicesOfferedBound)
                .sort((a, b) => {
                  return (
                    perc(b.choiceRespondentsCount, question.questionRespondentsCount) -
                    perc(a.choiceRespondentsCount, question.questionRespondentsCount)
                  );
                })
                .map((choice, idx) => (
                  <div key={idx} className='c-question-multiple__item'>
                    <div className='c-question-multiple__media'>
                      <img src={ImageUtils.imageUrl(choice.resource, IMAGE_FORMATS.SQUARE.imageType)} alt='' />
                    </div>
                    <h3 className='c-question-multiple__label'>{choice.choice}</h3>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <QuestionTemplateAction question={question} />
    </div>
  );
}

MultiImageChoicePreview.propTypes = {
  question: PropTypes.shape({
    questionText: PropTypes.string.isRequired,
    choices: PropTypes.array.isRequired,
    questionRespondentsCount: PropTypes.number,
  }).isRequired,
};

export default MultiImageChoicePreview;
