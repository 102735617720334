import React from 'react';
import PropTypes from 'prop-types';

import { HELP } from 'shared/engage/engage-help-enums';
import SelectItem from '../selectItem/SelectItem';
import HelpActionButton from '../help/HelpActionButton';

export default function ActionBar(props) {
  const {
    addNew,
    label,
    hasButton,
    buttonLabel,
    isDisabled = false,
    options,
    optionLabel = '',
    optionsOnChange,
    optionValue,
    helpKey,
    isTextOnlyHelp,
  } = props;
  const disableButtonClass = isDisabled ? 'is-disabled' : '';

  return (
    <div className='c-action-bar' data-testid='action-bar-wrapper'>
      <h1 className='c-action-bar__title'>{label}</h1>
      <HelpActionButton helpKey={helpKey} isTextOnly={isTextOnlyHelp} />
      {hasButton && (
        <div className='c-action-bar__action' data-testid='button'>
          {options && (
            <SelectItem
              optionLabel={optionLabel}
              onChange={optionsOnChange}
              options={options}
              optionValue={optionValue}
            />
          )}
          <button className={`o-button o-button--primary ${disableButtonClass}`} onClick={addNew} disabled={isDisabled}>
            <span className='o-label'>{buttonLabel}</span>
          </button>
        </div>
      )}
    </div>
  );
}

ActionBar.propTypes = {
  addNew: PropTypes.func,
  label: PropTypes.string.isRequired,
  hasButton: PropTypes.bool,
  buttonLabel: PropTypes.string,
  isDisabled: PropTypes.bool,
  options: PropTypes.array,
  optionLabel: PropTypes.string,
  optionsOnChange: PropTypes.func,
  optionValue: PropTypes.string,
  helpKey: PropTypes.string,
  isTextOnlyHelp: PropTypes.bool,
};
