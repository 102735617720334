import React, { useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import MDEditor from '@uiw/react-md-editor';
// eslint-disable-next-line import/no-cycle
import { PollReportContext } from '../PollsReport';
import QuestionHeader from '../../QuestionHeader';
import QuestionFooter from '../../QuestionFooter';
import MediaItem from '../../../../../common/components/mediaItem/MediaItem';

function FactMediaPreview(props) {
  const { question } = props;
  const { questionId, label, skipSeen, resource, resourceMediaType } = question;

  const imageId = questionId || null;
  const imageUrl = resource || null;
  const imageType = resourceMediaType || null;

  const pollReportContext = useContext(PollReportContext);
  const containerReference = useRef();

  useEffect(() => {
    if (pollReportContext)
      pollReportContext.addReference({ id: questionId, containerReference: containerReference.current });
  }, [pollReportContext, questionId]);

  return (
    <div ref={containerReference} className='c-poll-preview__item c-poll-preview__item--fact'>
      <div className='c-question c-question--fact-image'>
        <QuestionHeader question={question} showTitle={false} />
        <div className='c-question__content'>
          <div className='c-question-fact c-question-fact--image'>
            <div className='c-question-fact__content u-rich-text'>
              <MDEditor.Markdown source={label} className='markdown_preview' />
            </div>
            <div className='c-question-fact__media'>
              <MediaItem
                type={imageType}
                mediaId={imageId}
                mediaUrl={imageUrl}
                imageClassModifier='l-form__item c-upload--fill'
                videoClassModifier='c-upload--fill'
                linkToVideo={true}
              />
            </div>
          </div>
        </div>
        <QuestionFooter viewed={skipSeen.seenCount} showSkipped={false} showAnswered={false} />
      </div>
    </div>
  );
}

FactMediaPreview.propTypes = {
  question: PropTypes.shape({
    questionId: PropTypes.string.isRequired,
    label: PropTypes.string,
    resource: PropTypes.string,
    resourceMediaType: PropTypes.string,
    skipSeen: PropTypes.shape({
      seenCount: PropTypes.number,
    }),
  }).isRequired,
};

export default FactMediaPreview;
