import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../tooltip/Tooltip';
import ActionTooltip from '../../tooltip/ActionTooltip';
import SvgIcon from '../../SvgIcon';

export default function FieldDescription(props) {
  const {
    meta,
    fieldDescription,
    fieldDescriptionContext,
    fieldDescriptionLinkUrl = '',
    showSlugLabel = false,
    val = '',
    tooltip = null,
    actionTooltip = null,
  } = props;

  const isErrorShown = meta.error && meta.touched;
  const isDescriptionShown = !isErrorShown && (fieldDescription || tooltip || actionTooltip);

  return (
    <React.Fragment>
      {showSlugLabel && (
        <p className='c-field__description' data-testid='slugLabel'>
          www.givemyview.com/<strong>{val}</strong>
        </p>
      )}

      {isDescriptionShown && (
        <p className='c-field__description' data-testid='description'>
          {fieldDescriptionLinkUrl && (
            <Fragment>
              <a
                className='o-button o-button--link'
                href={fieldDescriptionLinkUrl}
                rel='noopener noreferrer'
                target='_blank'
              >
                {`Permalink `}
                <SvgIcon icon='external-link' />
              </a>
              {` \u2014 `}
            </Fragment>
          )}
          {fieldDescriptionContext && <strong data-testid='fieldDescriptionContext'>{fieldDescriptionContext}</strong>}
          {fieldDescription || ''}
          {tooltip && <Tooltip {...tooltip} />}
          {actionTooltip && <ActionTooltip {...actionTooltip} />}
        </p>
      )}

      {isErrorShown && (
        <p className='c-field__description c-field__description--error' data-testid='error'>
          {meta.error}
        </p>
      )}
    </React.Fragment>
  );
}

FieldDescription.propTypes = {
  meta: PropTypes.object.isRequired,
  fieldDescription: PropTypes.string,
  fieldDescriptionContext: PropTypes.string,
  fieldDescriptionLinkUrl: PropTypes.string,
  showSlugLabel: PropTypes.bool,
  val: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tooltip: PropTypes.object,
  actionTooltip: PropTypes.object,
};
