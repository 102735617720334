import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import SvgIcon from '../../../common/components/SvgIcon';
import EngageTextInputField from '../../../common/components/form-elements/textInputField/TextInputField';
import SwitchableButtonBar from '../../../common/components/switchableButtonBar/SwitchableButtonBar';
import { QUESTION_CHARACTER_LIMIT } from '../../../common/constants/QuestionConstants';
import DEMOGRAPHIC_ENUMS from '../../../shared/engage/engage-demographics-enums';
import ChoicesList, { CHOICE_IDENTIFIER_PREFIX } from './ChoicesList';
import LeadImage from './LeadImage';

const DEMOGRAPHIC_CUSTOM_TYPE = DEMOGRAPHIC_ENUMS().DemographicsTypes.DEMOGRAPHIC_CUSTOM_TYPE;
const DEMOGRAPHIC_HOUSEHOLD_NUMBER = DEMOGRAPHIC_ENUMS().DemographicsTypes.DEMOGRAPHIC_HOUSEHOLD_NUMBER;
function DemographicForm(props) {
  const {
    question,
    updateQuestion,
    overrideQuestion,
    addChoice,
    removeChoice,
    reorderChoice,
    uploadImage,
    removeImage,
    removeQuestion,
    openPollPreview,
    allowDrag,
    allowChoiceDelete,
    showWarningMessage,
  } = props;

  const { _id, questionType } = question;

  const isCustom = questionType
    ? questionType === DEMOGRAPHIC_ENUMS().DemographicsTypes.DEMOGRAPHIC_CUSTOM_TYPE
    : false;

  const [currentChoices, setCurrentChoices] = useState([]);
  const [initValues, setInitValues] = useState();

  const getQuestionTitle = useCallback(
    (questionText) => {
      if (questionType === DEMOGRAPHIC_CUSTOM_TYPE) return questionText;
      return questionText ? questionText.slice(questionText.indexOf(':') + 1, questionText.length) : '';
    },
    [questionType],
  );

  const prepareInitValues = useCallback(
    (newChoices) => {
      const formInitialValues = {
        questionText: getQuestionTitle(question.questionText),
      };

      for (let index = 0; index < newChoices.length; index++) {
        const element = newChoices[index];
        formInitialValues[`${CHOICE_IDENTIFIER_PREFIX}-${element._id}`] = element.choice ? element.choice : '';
      }
      setInitValues(formInitialValues);
    },
    [getQuestionTitle, question.questionText],
  );

  useEffect(() => {
    if (question) {
      const choicesClone = JSON.parse(JSON.stringify(question.choices));
      setCurrentChoices(choicesClone);
      prepareInitValues(choicesClone);
    }
  }, [getQuestionTitle, prepareInitValues, question]);

  const onSubmit = (values) => {
    // console.log(values);
    const q = toJS(question);
    q.questionText = values.questionText;

    const updatedChoices = currentChoices.map((choice) => {
      const preparedChoice = {};
      preparedChoice._id = choice.isNew ? '' : choice._id;
      preparedChoice.choice = values[`${CHOICE_IDENTIFIER_PREFIX}-${choice._id}`];
      preparedChoice.choiceContentType = choice.choiceContentType;
      preparedChoice.order = choice.order;
      preparedChoice.choiceArchived = choice.choiceArchived ? choice.choiceArchived : false;
      preparedChoice.resource = choice.resource;
      preparedChoice.range = choice.range;
      return preparedChoice;
    });

    const updatedQuestion = {
      questionType: question.questionType,
      questionText: values.questionText,
      order: question.order,
      // THIS IS QUESTIONABLE questionTranslationKey: question.questionTranslationKey,
      _id: question._id,
      choices: updatedChoices,
    };

    updateQuestion(updatedQuestion);
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initValues}
      keepDirtyOnReinitialize={true}
      render={({ values, form, handleSubmit, submitting, pristine }) => {
        return (
          <form className='c-fields l-form'>
            <EngageTextInputField
              id={`input-question-${_id}`}
              placeholder='Enter question'
              label='Question'
              maxLength={QUESTION_CHARACTER_LIMIT}
              fieldDescription=''
              fieldName='questionText'
              isRequired={true}
              fieldClass=''
              disabled={!isCustom}
            />

            <LeadImage
              question={question}
              isCustomQuestion={isCustom}
              uploadImage={uploadImage}
              removeImage={removeImage}
            />

            <ChoicesList
              currentChoices={currentChoices}
              addChoice={addChoice}
              removeChoice={removeChoice}
              reorderChoice={reorderChoice}
              showWarningMessage={showWarningMessage}
              allowDrag={allowDrag}
              allowChoiceDelete={allowChoiceDelete}
              isCustomQuestion={isCustom}
            />

            {questionType === DEMOGRAPHIC_HOUSEHOLD_NUMBER && (
              <div className='l-form__item'>
                <div className='c-message c-message--icon is-information'>
                  <SvgIcon icon='info-circle' />
                  <p className='c-message__description'>
                    This question has a different layout compared to other demographic questions. Overriding will change
                    the layout to the list layout.
                  </p>
                </div>
              </div>
            )}

            <SwitchableButtonBar
              overrideAction={overrideQuestion}
              updateAction={handleSubmit}
              removeAction={removeQuestion}
              isNew={null}
              isOverrideActive={isCustom}
              previewAction={() => openPollPreview(question._id)}
            />
          </form>
        );
      }}
    />
  );
}

DemographicForm.propTypes = {
  question: PropTypes.object.isRequired,
  updateQuestion: PropTypes.func.isRequired,
  overrideQuestion: PropTypes.func.isRequired,
  addChoice: PropTypes.func.isRequired,
  removeChoice: PropTypes.func.isRequired,
  reorderChoice: PropTypes.func.isRequired,
  uploadImage: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired,
  removeQuestion: PropTypes.func.isRequired,
  openPollPreview: PropTypes.func.isRequired,
  allowDrag: PropTypes.bool.isRequired,
  allowChoiceDelete: PropTypes.bool.isRequired,
  showWarningMessage: PropTypes.func.isRequired,
};

export default observer(DemographicForm);
