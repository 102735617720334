import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { IMAGE_FORMATS } from '../../../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import QuestionTemplateAction from './QuestionTemplateAction';
import ImageUtils from '../../../../../common/ImageUtils';
import QuestionTemplateHeader from './QuestionTemplateHeader';
import MediaItem from '../../../../../common/components/mediaItem/MediaItem';

function RatingPreview(props) {
  const { question } = props;
  const { _id, multiChoicesOfferedBound, choices, questionConfiguration } = question;

  const ratingChoices = choices && choices.length ? choices[0] : null;
  const ratingConfiguration = questionConfiguration?.ratingConfiguration;
  const ratingRange = Array.from({ length: multiChoicesOfferedBound }, (_, i) => i + 1);

  const mediaResource = question?.resource?.length > 0 ? question.resource[0] : null;

  const imageId = mediaResource?._id || null;
  const imageUrl = mediaResource?.url || null;
  const imageType = mediaResource?.mediaType || null;

  return (
    <div className='c-library__item'>
      <div className='c-question c-question--library c-question--rating'>
        <QuestionTemplateHeader question={question} />
        <div className='c-question__content'>
          <div className='c-question-rating c-question-rating--image'>
            <div className='c-question-rating__lead'>
              {mediaResource && (
                <MediaItem
                  type={imageType}
                  mediaId={imageId}
                  mediaUrl={imageUrl}
                  imageClassModifier='l-form__item c-upload--fill'
                  videoClassModifier='c-upload--fill'
                  linkToVideo={true}
                />
              )}
            </div>
            <div className='c-rating__group'>
              <div className='c-rating__control'>
                {ratingRange.map((element, index) => (
                  <Fragment key={index}>
                    <input
                      className='u-hide-visually'
                      id={`input-rating-${_id}-${index}`}
                      value={element}
                      type='radio'
                      name='rating'
                    />
                    <label
                      htmlFor={`input-rating-${_id}-${index}`}
                      style={{
                        backgroundImage: `url(${ImageUtils.spritePngImageUrl(ratingChoices.resource)}) `,
                      }}
                    >
                      <span className='u-hide-visually'>{element}</span>
                    </label>
                  </Fragment>
                ))}
              </div>
              {ratingConfiguration?.useCustomLabels ? (
                <div className='c-rating__labels'>
                  <div className='c-rating__labels-container'>
                    <span className='c-rating__label-item-low'>{ratingConfiguration?.resourceLabels?.lowest}</span>
                    <span className='c-rating__label-item-high'>{ratingConfiguration?.resourceLabels?.highest}</span>
                  </div>
                </div>
              ) : (
                <div className='c-rating__labels c-rating__labels--numbered'>
                  <ol className='c-rating__labels-container'>
                    {ratingRange.map((element, index) => (
                      <li className='c-rating__label-item' key={`label-rating-${_id}-${index}`}>
                        {element}
                      </li>
                    ))}
                  </ol>
                </div>
              )}
            </div>
            {questionConfiguration?.allowComments && (
              <p className='c-question-rating__info'>This question has feedback form</p>
            )}
          </div>
        </div>
      </div>
      <QuestionTemplateAction question={question} />
    </div>
  );
}

RatingPreview.propTypes = {
  question: PropTypes.shape({
    questionText: PropTypes.string.isRequired,
    resource: PropTypes.array,
    multiChoicesOfferedBound: PropTypes.number.isRequired,
    choices: PropTypes.array.isRequired,
    questionConfiguration: PropTypes.object,
  }).isRequired,
};

export default RatingPreview;
