import React, { useState, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes, { func } from 'prop-types';
import SimpleTextInputWithAction from 'common/components/form-elements/textInputField/SimpleTextInputWithAction';
import displayConfirmationPrompt from 'common/PromptUtil';
import GdprReferencesTable from './GdprReferencesTable';

function Anonymisation(props) {
  const { ToolsStore, AuthStore, ToastrStore, ProjectStore } = props;

  const [email, setEmail] = useState('');
  const [gdprEmailReferences, setGdprEmailReferences] = useState([]);
  const [showNoGDPRDataMessage, setShowNoGDPRDataMessage] = useState(false);

  const clientId = AuthStore.clientId;
  const projectId = ProjectStore.project._id;

  function getGdprEmailReferences() {
    if (email)
      ToolsStore.getGdprEmailReferences(email, clientId, projectId).then((data) => {
        const test = data.pollResponseFeedback;
        if (data?.feedback?.length > 0 || data?.pollResponseFeedback?.length > 0) {
          setGdprEmailReferences([...data.feedback, ...data.pollResponseFeedback]);
        } else {
          setShowNoGDPRDataMessage(true);
        }
      });
  }

  function onSearchChanged(event) {
    setEmail(event.target.value);
    setGdprEmailReferences([]);
    setShowNoGDPRDataMessage(false);
  }

  const anonymiseHandler = () => {
    const prompt = `WARNING!!! This is a destructive operation that will anonymise and/or delete user data.\nTo proceed, please confirm by entering user's email \n${email}`;
    const res = displayConfirmationPrompt(prompt, email, false, ToastrStore, true);

    if (res === 1) {
      ToolsStore.removeGdprEmailReferences(email, clientId, projectId).then((result) => {
        if (result) {
          setGdprEmailReferences([]);
          setShowNoGDPRDataMessage(false);
        }
      });
    }
  };

  function searchKeyDownHandler(event) {
    // We don't want to submit the form (call ) when the user presses enter
    if (event.key === 13 || event.keyCode === 13) event.preventDefault();
  }

  return (
    <Fragment>
      <h3>Anonymisation</h3>
      <div className='c-field l-form__item'>
        <div className='c-field__group'>
          <div className='c-message'>
            <p className='c-message__description'>
              If you wish to anonymise user information for your project, you can do so by searching for a specific
              email below.
            </p>
          </div>
        </div>
      </div>
      <SimpleTextInputWithAction
        id='tools-search'
        buttonLabel='Search'
        label='Search for an email address you wish to anonymise'
        placeholder='Enter e-mail'
        fieldName='search'
        isRequired={false}
        input={{ value: email, onChange: onSearchChanged }}
        onClick={getGdprEmailReferences}
        buttonType='button'
        onKeyDown={searchKeyDownHandler}
      />

      {gdprEmailReferences.length > 0 ? (
        <div
          className='l-container-block no-margin l-container-block--boxed'
          id='anonymisation-information'
          data-testid='tools-results-container'
        >
          <div className='c-field l-form__item'>
            <div className='c-field__group'>
              <h4>{`Results for "${email}"`}</h4>
            </div>
          </div>
          <GdprReferencesTable gdprEmailReferences={gdprEmailReferences} />
          <div className='c-field l-form__item'>
            <div className='c-field__group'>
              <div className='c-message is-danger-strong'>
                <h3 className='c-message__title'>Important!</h3>
                <p className='c-message__description'>
                  When you click <strong>Anonymise user data</strong>, all identifiable user references in the database
                  will be deleted. <br />
                  <strong>This action is irreversible</strong>.
                </p>
              </div>
            </div>
          </div>
          <div className='c-field l-form__item' data-testid='anonymise-button'>
            <div className='c-field__group'>
              <button className='o-button o-button--primary' onClick={anonymiseHandler}>
                <span className='o-label'>Anonymise user data</span>
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {showNoGDPRDataMessage && (
        <div className='c-field l-form__item'>
          <div className='c-field__group'>
            <div className='c-message'>
              <p className='c-message__description'>No emails were found. Please try another search.</p>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

Anonymisation.propTypes = {
  AuthStore: PropTypes.object.isRequired,
  ToolsStore: PropTypes.object.isRequired,
  ToastrStore: PropTypes.object.isRequired,
  ProjectStore: PropTypes.object.isRequired,
};

export default inject((root) => ({
  AuthStore: root.RootStore.authStore,
  ToolsStore: root.RootStore.toolsStore,
  ToastrStore: root.RootStore.toastrStore,
  ProjectStore: root.RootStore.projectStore,
}))(observer(Anonymisation));
