import React from 'react';
import SvgIcon from '../../../../common/components/SvgIcon';

export default function FeedbackToolbar(props) {
  const { toggleOpened, toggleHighLight, removeItem } = props;
  return (
    <ul className='c-feedback__actions'>
      <li>
        <button className='c-feedback__action c-feedback__action--reply' onClick={toggleOpened}>
          <SvgIcon icon='reply' />
          <span className='o-label'>Reply</span>
        </button>
      </li>
      <li>
        <button className='c-feedback__action c-feedback__action--trash' onClick={removeItem}>
          <SvgIcon icon='trash' />
          <span className='o-label'>Delete</span>
        </button>
      </li>
      <li>
        <button className='c-feedback__action c-feedback__action--star' onClick={toggleHighLight}>
          <SvgIcon icon='star' />
          <SvgIcon icon='star-filled' />
          <span className='o-label'>Highlight</span>
        </button>
      </li>
    </ul>
  );
}
