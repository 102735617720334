import React, { useState, useEffect, useContext, useRef, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import html2canvas from 'html2canvas';
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash';
// eslint-disable-next-line import/no-cycle
import { PollReportContext } from '../PollsReport';
import QuestionFooter from '../../QuestionFooter';
import QuestionHeader from '../../QuestionHeader';
import PreviewUtils from '../../../../../common/PreviewUtils';
import agent from '../../../../../common/agent/ReportsAgent';
import AnnotateImageArea from './AnnotateImageArea';
import AnnotateQuestionControls from '../../../../../common/components/annotateQuestion/annotateQuestionControls/AnnotateQuestionControls';
import GeneralUtils from '../../../../../common/GeneralUtils';
import AnnotatePreviewReactionsList from './AnnotatePreviewReactionsList';
import { DefaultValueEnums } from '../../../../../shared/engage';
import SimpleCheckboxInputField from '../../../../../common/components/form-elements/checkboxInputField/SimpleCheckboxInputField';

function AnnotateImagePreview(props) {
  const { PollReportStore, ToastrStore, GeneralStore, question, fromDate, toDate, pollName, onOpenFeedbackModal } =
    props;
  const { questionId, choices, countAnswered, resource, skipSeen, questionConfiguration } = question;

  const [placedReactions, setPlacedReactions] = useState([]);
  const [choicesSelection, setChoicesSelection] = useState({});
  const [choicesCount, setChoicesCount] = useState(0);
  const [showClusters, setShowClusters] = useState(true);

  const pollReportContext = useContext(PollReportContext);
  const snapshotContainer = useRef();
  const zoomRef = useRef(null);

  const addOverlays = useCallback(
    (selectedChoices) => {
      let count = 0;

      // Define cluster grid for each zoom level
      const minMarkersInCluster = 3;
      const fullDataSet = [
        { zoomLevel: 1, cells: 4, rows: 4, units: [], markers: [] },
        { zoomLevel: 1.5, cells: 6, rows: 6, units: [], markers: [] },
        { zoomLevel: 2, cells: 8, rows: 8, units: [], markers: [] },
        { zoomLevel: 2.5, cells: 10, rows: 10, units: [], markers: [] },
        { zoomLevel: 3, cells: 12, rows: 12, units: [], markers: [] },
        { zoomLevel: 3.5, cells: 14, rows: 14, units: [], markers: [] },
      ];

      // Create units/cells for each zoom level
      fullDataSet.forEach((zoomLevel) => {
        const cellWidth = 100 / zoomLevel.cells;
        const cellHeight = 100 / zoomLevel.rows;
        for (let i = 0; i < zoomLevel.rows; i++) {
          for (let j = 0; j < zoomLevel.cells; j++) {
            const unit = {};
            unit.xStart = cellWidth * j;
            unit.xEnd = cellWidth * (j + 1);
            unit.yStart = cellHeight * i;
            unit.yEnd = cellHeight * (i + 1);
            unit.markers = [];
            zoomLevel.units.push(unit);
          }
        }
      });

      // Merge all reaction markers in one collection
      const initialMarkers = [];
      selectedChoices.forEach((choice) => {
        choice.imagePins.forEach((reaction) => {
          const marker = {};
          marker.left = reaction.x;
          marker.top = reaction.y;

          // In case reaction was removed from annotate question and resource value was permanently deleted
          marker.resource = choice.resource || DefaultValueEnums().DELETED_ANNOTATE_REACTION;

          initialMarkers.push(marker);
        });

        count += choice.imagePins.length;
      });

      // Put each marker in belonging unit/cell on each zoom level
      initialMarkers.forEach((marker) => {
        fullDataSet.forEach((zoomLevel) => {
          const markerUnit = zoomLevel.units.find(
            (unit) =>
              unit.xStart <= marker.left &&
              unit.xEnd >= marker.left &&
              unit.yStart <= marker.top &&
              unit.yEnd >= marker.top,
          );

          if (markerUnit) {
            markerUnit.markers.push(marker);
            // eslint-disable-next-line no-useless-return
            return;
          }
        });
      });

      // Go through each unit/cell on all zoom levels and do following:
      // - create cluster marker where number of markers in unit/cell exceeds cluster threshold number,
      //   place that cluster marker inside zoom level markers collection
      // - where number of markers in unit does not exceed cluster threshold number place all unit
      //   markers inside zoom level markers collection
      fullDataSet.forEach((zoomLevel) => {
        zoomLevel.units.forEach((unit) => {
          const createCluster = showClusters && unit.markers.length >= minMarkersInCluster;
          if (createCluster) {
            const clusterMarker = {};
            clusterMarker.id = GeneralUtils.generateUUID();
            clusterMarker.count = unit.markers.length;
            clusterMarker.left = unit.xStart + (unit.xEnd - unit.xStart) / 2;
            clusterMarker.top = unit.yStart + (unit.yEnd - unit.yStart) / 2;

            // Define collection of reactions included in cluster, count them and sort them
            // This data is used for displaying cluster icon, cluster count and popup containing cluster details
            clusterMarker.reactions = [];
            const includedReactionTypes = [...new Set(unit.markers.map((marker) => marker.resource))];
            includedReactionTypes.forEach((reactionType) => {
              const reactionTypeCount = unit.markers.filter((marker) => marker.resource === reactionType).length;
              clusterMarker.reactions.push({ resource: reactionType, count: reactionTypeCount });
            });
            clusterMarker.reactions.sort((a, b) => b.count - a.count);

            zoomLevel.markers.push(clusterMarker);
            // console.log('CLUSTER: ', clusterMarker);
          } else {
            // eslint-disable-next-line no-param-reassign
            zoomLevel.markers = zoomLevel.markers.concat(unit.markers);
          }
        });
      });

      setPlacedReactions(fullDataSet);
      setChoicesCount(count);
    },
    [showClusters],
  );

  useEffect(() => {
    if (choices) {
      addOverlays(choices);

      const selection = {};
      choices.forEach((choice) => {
        selection[choice.choiceId] = true;
      });
      setChoicesSelection(selection);
    }
  }, [addOverlays, choices]);

  useEffect(() => {
    if (pollReportContext)
      pollReportContext.addReference({ id: questionId, containerReference: snapshotContainer.current });
  }, [pollReportContext, questionId]);

  function onSelectionChange(event, choiceId) {
    // Maintain choice checkbox state
    const newCS = _.clone(choicesSelection);
    newCS[choiceId] = event.target.checked;
    setChoicesSelection(newCS);

    // Prepare new data set for map
    const selectedChoiceIds = [];
    Object.entries(newCS).forEach((entrie) => {
      if (entrie[1]) selectedChoiceIds.push(entrie[0]);
    });
    const remainingChoices = choices.filter((choice) => selectedChoiceIds.includes(choice.choiceId));

    addOverlays(remainingChoices);
  }

  function handleDownload() {
    // TODO Handle export once API ready
    PreviewUtils.excelDownload(
      agent,
      PollReportStore.projectId,
      PollReportStore.pollId,
      questionId,
      ToastrStore,
      fromDate,
      toDate,
      PollReportStore.segmentChoiceId,
      PollReportStore.demographicChoiceId,
    );
  }

  function handlePreview() {
    PollReportStore.feedbackModalActivatorQuestion = question;
    PollReportStore.feedbackModalActivatorReactions = choices;
    PollReportStore.feedbackModalOpen = true;
    onOpenFeedbackModal();
  }

  function handleSnapshot() {
    GeneralStore.isSnapshotting = true;
    GeneralStore.isSnapshottingSingleQuestion = true;

    setTimeout(() => {
      html2canvas(snapshotContainer.current, {
        useCORS: true,
        scrollX: -8,
        scrollY: -window.scrollY,
        removeContainer: true,
      })
        .then((canvas) => {
          const a = document.createElement('a');
          a.href = canvas.toDataURL('image/png');
          a.download = `${pollName}-${question.label}.png`;
          a.click();
        })
        .catch((error) => {
          ToastrStore.error('Exporting overview report failed!', null, error);
        })
        .finally(() => {
          GeneralStore.isSnapshotting = false;
          GeneralStore.isSnapshottingSingleQuestion = false;
        });
    }, 1000);
  }

  const onZoomIn = () => {
    zoomRef.current.onZoomInButton();
  };

  const onZoomOut = () => {
    zoomRef.current.onZoomOutButton();
  };

  const onReset = () => {
    zoomRef.current.onResetButton();
  };

  // ##### HOTFIX to handle un-clustering on annotate image
  // https://github.com/sdesregistry/IH-Engage/issues/2237
  // We need a better solution for reporting on annotate image
  const toggleReactionsGrouping = (event) => {
    // console.log(event.target.checked);
    setShowClusters(event.target.checked);
  };

  return (
    <div ref={snapshotContainer} className='c-poll-preview__item'>
      <div className='c-question'>
        <QuestionHeader
          question={question}
          onSnapshot={handleSnapshot}
          exportInProgress={GeneralStore.isSnapshotting}
        />

        <div className='c-question__content'>
          <div className='c-result-annotate c-result-annotate--image'>
            {!GeneralStore.isSnapshotting && (
              <AnnotateQuestionControls onZoomIn={onZoomIn} onZoomOut={onZoomOut} onReset={onReset} />
            )}
            <AnnotateImageArea
              resource={resource}
              ref={zoomRef}
              placedReactions={placedReactions}
              isExporting={GeneralStore.isSnapshotting}
            />
          </div>

          <div className='c-result-group'>
            <ul className='c-result-group__items'>
              {choices
                .slice()
                .sort((choiceA, choiceB) => choiceB.count - choiceA.count)
                .map((choice, idx) => (
                  <AnnotatePreviewReactionsList
                    key={idx}
                    choice={choice}
                    choicesCount={choicesCount}
                    onSelectionChange={onSelectionChange}
                  />
                ))}
            </ul>
            {!GeneralStore.isSnapshotting && (
              <Fragment>
                <SimpleCheckboxInputField
                  id={`toggle-reactions-grouping-${question.questionId}`}
                  type='checkbox'
                  input={{ checked: showClusters, onChange: toggleReactionsGrouping }}
                  isToggle={true}
                  label='Group reactions'
                  // checkedLabel='Group reactions'
                  // uncheckedLabel='Show single reactions'
                  hideLabel={true}
                />
                {(questionConfiguration.allowComments || questionConfiguration?.allowUserContentUpload?.image) && (
                  <button className='o-button o-button--s' type='button' onClick={handlePreview}>
                    <span className='o-label'>Preview</span>
                  </button>
                )}
                <button className='o-button o-button--s o-button--primary' type='button' onClick={handleDownload}>
                  <span className='o-label'>Download spreadsheet</span>
                </button>
              </Fragment>
            )}
          </div>
        </div>

        <QuestionFooter answered={countAnswered} skipped={skipSeen.skipCount} viewed={skipSeen.seenCount} />
      </div>
    </div>
  );
}

AnnotateImagePreview.propTypes = {
  ToastrStore: PropTypes.object.isRequired,
  PollReportStore: PropTypes.object.isRequired,
  GeneralStore: PropTypes.object.isRequired,
  question: PropTypes.shape({
    questionId: PropTypes.string.isRequired,
    countAnswered: PropTypes.number,
    choices: PropTypes.array,
    questionType: PropTypes.string.isRequired,
    multiChoicesOfferedBound: PropTypes.number,
    multiChoicesSelectableBound: PropTypes.number,
    skipSeen: PropTypes.shape({
      seenCount: PropTypes.number,
      skipCount: PropTypes.number,
    }),
  }).isRequired,
  fromDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  toDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  pollName: PropTypes.string.isRequired,
  onOpenFeedbackModal: PropTypes.func,
};

export default inject((root) => ({
  ToastrStore: root.RootStore.toastrStore,
  PollReportStore: root.RootStore.pollsReportStore,
  GeneralStore: root.RootStore.generalStore,
}))(observer(AnnotateImagePreview));
