import React from 'react';
import PropTypes from 'prop-types';
import BaseNewDatePickerInputField from './BaseDatePickerInputField';
import { getRangeDates } from './DatePickerUtils';

function SimpleRangePickerInputField(props) {
  const {
    startDate,
    endDate,
    onRangeChange,
    startPlaceholder = 'From',
    endPlaceholder = 'To',
    showTimeSelect = false,
    showPredefinedRanges = false,
    fieldClass = '',
    label = 'Choose date range',
  } = props;

  function predefinedRangeSelectHandler(predefinedPeriod) {
    const rangeDates = getRangeDates(predefinedPeriod);
    onRangeChange(rangeDates.rangeStart, rangeDates.rangeEnd);
  }

  function onStartChange(date) {
    onRangeChange(date, endDate);
  }

  function onEndChange(date) {
    onRangeChange(startDate, date);
  }

  return (
    <div className={`c-field l-form__item ${fieldClass}`}>
      <label className='c-field__label' htmlFor='input-range-from-date'>
        {label}
      </label>
      <div className='c-field__group'>
        <div className='c-date-picker'>
          <BaseNewDatePickerInputField
            input={{ value: startDate, onChange: onStartChange }}
            returnDayEndTime={false}
            selectsStart={true}
            startDate={startDate}
            endDate={endDate}
            placeholder={startPlaceholder}
            showTimeSelect={showTimeSelect}
            showPredefinedRanges={showPredefinedRanges}
            onPredefinedRangeSelect={predefinedRangeSelectHandler}
          />
          <BaseNewDatePickerInputField
            input={{ value: endDate, onChange: onEndChange }}
            showTimeSelect={showTimeSelect}
            returnDayEndTime={true}
            placeholder={endPlaceholder}
            selectsEnd={true}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            showPredefinedRanges={showPredefinedRanges}
            onPredefinedRangeSelect={predefinedRangeSelectHandler}
          />
        </div>
      </div>
    </div>
  );
}

SimpleRangePickerInputField.propTypes = {
  startDate: PropTypes.object,
  startPlaceholder: PropTypes.string,
  endDate: PropTypes.object,
  endPlaceholder: PropTypes.string,
  showTimeSelect: PropTypes.bool,
  showPredefinedRanges: PropTypes.bool,
  fieldClass: PropTypes.string,
  label: PropTypes.string,
};

export default SimpleRangePickerInputField;
