import React from 'react';
import PropTypes from 'prop-types';

function ActionBanner(props) {
  const { title, description, actionLabel, onAction, fieldClass } = props;
  return (
    <div className={`c-message c-message--highlight ${fieldClass}`} data-testid='action-banner-wrapper'>
      <h3 className='c-message__title'>{title}</h3>
      <p className='c-message__description'>
        {description}
        <button className='o-button o-button--primary o-button--xs' type='button' onClick={onAction}>
          <span className='o-label'>{actionLabel}</span>
        </button>
      </p>
    </div>
  );
}

ActionBanner.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  actionLabel: PropTypes.string.isRequired,
  onAction: PropTypes.func.isRequired,
  fieldClass: PropTypes.string,
};

export default ActionBanner;
