import Agent from './agent';

const requests = Agent.requests;
const ENDPOINT = 'gallery';

const GalleryAgent = {
  reorder: (projectId, data) => requests.post(`/projects/${projectId}/${ENDPOINT}/reorder`, data),
  uploadResource: (projectId, file, progress) =>
    requests.uploadImage(`/projects/${projectId}/${ENDPOINT}`, 'project', file, progress),
  removeResource: (projectId, itemId) => requests.remove(`/projects/${projectId}/${ENDPOINT}/${itemId}`),
  postVideo: (projectId, data) => requests.post(`/projects/${projectId}/${ENDPOINT}/video`, data),
  uploadCaption: (projectId, itemId, data) =>
    requests.post(`/projects/${projectId}/${ENDPOINT}/caption/${itemId}`, data),
};

export default GalleryAgent;
