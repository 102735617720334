import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { inject, observer } from 'mobx-react';
import { Form } from 'react-final-form';

import SwitchableHeader from '../../../common/components/switchableHeader/SwitchableHeader';
import EngageTextInputField from '../../../common/components/form-elements/textInputField/TextInputField';
import SwitchableButtonBar from '../../../common/components/switchableButtonBar/SwitchableButtonBar';
import EngageTextareaInputField from '../../../common/components/form-elements/textareaInputField/TextareaInputField';
import SlimFileUpload from '../../../common/components/form-elements/fileUpload/SlimFileUpload';
import { IMAGE_FORMATS } from '../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import { CheckboxInputField } from '../../../common/components/form-elements';
import displayConfirmationPrompt from '../../../common/PromptUtil';
import { getFormFieldClass, getFormFieldTranslationTooltip } from '../../../common/TranslationUtils';

/**
 * Influenced desision form
 */
function CharityItem(props) {
  const { item, Store, ToastrStore, switchableModifier } = props;
  const imageId = item.data.resource;

  // hotfix for https://github.com/sdesregistry/IH-Engage/issues/1261
  // [HOTFIX] Enable setting 4 charities for "Barking Riverside" project
  const MAX_CHARITIES = Store.projectId === '5c65622df6953e00049a4cf0' ? 4 : 3;

  const toggleOpened = () => {
    Store.toggleOpened([item.data._id]);
  };

  const remove = (event) => {
    event.preventDefault();

    let res;
    const trimmedTitle = item.data.title ? item.data.title.trim() : item.data.title;
    if (item.data.pointsDonated > 0) {
      const promptMessage = `Points have been allocated to this charity.\nPlease enter 'DELETE' to delete item.`;
      res = displayConfirmationPrompt(promptMessage, 'DELETE', false, ToastrStore, true);
    } else {
      res = displayConfirmationPrompt(null, null, false, ToastrStore, true);
    }

    if (res === 1) Store.remove(item.data._id);
  };

  const onSubmit = (values) => {
    const activeCharities = Store.charities.filter(
      (charity) => charity.data.isActive && charity.data.enabled && charity.data._id !== values._id,
    );

    if (values.isActive && activeCharities.length >= MAX_CHARITIES) {
      ToastrStore.error(`${MAX_CHARITIES} Charities already active!`);
    } else {
      Store.update(item.data._id, {
        resource: values.resource,
        title: values.title,
        description: values.description ? values.description : '',
        isActive: values.isActive,
      });
    }
  };

  const uploadImage = (file, progressHandler) => {
    // event.preventDefault();
    return Store.uploadResource(item.data._id, file, progressHandler);
  };

  const removeImage = (event) => {
    event.preventDefault();
    return Store.removeResource(item.data._id);
  };

  return (
    <div
      className={`c-switchable ${switchableModifier} ${item.formState.isOpened ? 'is-opened' : 'is-closed'} ${
        item.data.isActive ? 'is-active' : ''
      }`}
      data-testid={`${item.data._id}-charityItem-wrapper`}
    >
      <SwitchableHeader
        id={item.data._id}
        label='Charity'
        name={item.data.title}
        icon={item.data.isActive ? 'check-circle' : 'users'}
        toggleOpened={toggleOpened}
      />
      <div className='c-switchable__content'>
        <Form
          onSubmit={onSubmit}
          keepDirtyOnReinitialize={true}
          initialValues={item.data}
          render={({ values, form, handleSubmit, submitting, pristine }) => {
            return (
              <form className='c-fields l-form' onSubmit={(event) => event.preventDefault()}>
                <EngageTextInputField
                  id={`input-charity-name-${item.data._id}`}
                  placeholder='Name'
                  label='Name'
                  maxLength='80'
                  fieldDescription=''
                  fieldName='title'
                  isRequired={true}
                  fieldClass={getFormFieldClass(item.defaultData.title, null, 'l-form__item')}
                  tooltipInLabel={getFormFieldTranslationTooltip(item.defaultData.title)}
                />

                <EngageTextareaInputField
                  id={`input-charity-description-${item.data._id}`}
                  placeholder='Make it short, sweet and under 300 characters.'
                  label='Description'
                  maxLength='300'
                  fieldDescription=''
                  fieldName='description'
                  isRequired={false}
                  fieldClass={getFormFieldClass(item.defaultData.description, null, 'l-form__item')}
                  tooltipInLabel={getFormFieldTranslationTooltip(item.defaultData.description)}
                />

                <SlimFileUpload
                  imageFormat={IMAGE_FORMATS.LOGO}
                  imageId={imageId}
                  isSharedResource={true}
                  id={`${item.data._id}`}
                  handleUpload={uploadImage}
                  label='Logo'
                  removeImage={removeImage}
                  enableLibrarySearch={false}
                />

                <CheckboxInputField
                  id={`checkbox-charity-status-${item.data._id}`}
                  type='checkbox'
                  fieldName='isActive'
                  label='Status'
                  isToggle={true}
                  checkedLabel='Active'
                  uncheckedLabel='Inactive'
                  hideLabel={false}
                />
                <SwitchableButtonBar updateAction={handleSubmit} removeAction={remove} isNew={!item.data._id} />
              </form>
            );
          }}
        />
      </div>
    </div>
  );
}

export default inject((root) => ({
  Store: root.RootStore.charitiesStore,
  ToastrStore: root.RootStore.toastrStore,
}))(observer(CharityItem));
