import { reaction, action, observable, computed, makeObservable } from 'mobx';

import Agent from '../common/agent';

const TeamAgent = Agent.Team;

export default class TeamStore {
  _loading = false;

  _initialized = false;

  _error = null;

  _team = [];

  constructor(rootStore, projectStore) {
    makeObservable(this, {
      _team: observable,
      _loading: observable,
      _initialized: observable,
      _error: observable,

      resetFlags: action,

      team: computed,
      isLoading: computed,
      isInitialized: computed,
      error: computed,

      loadData: action,
      toggleOpened: action,
      // removeMember: action, // should this be removeMemberLogo?
    });

    this.rootStore = rootStore;
    this.projectStore = projectStore;
    reaction(
      () => this.projectStore.isInitialized,
      () => this.loadData(this.projectStore.project.team, this.projectStore.projectDefault.team),
    );
  }

  get isLoading() {
    return this._loading;
  }

  get isInitialized() {
    return this._initialized;
  }

  get error() {
    return this._error;
  }

  get team() {
    return this._team;
  }

  /**
   * Load and prepare data already loaded in ProjectStore
   * @param team
   */
  loadData(team, teamDefault) {
    const tmpTeam = [];
    team.map((member) => {
      const defaultMember = teamDefault.find((teamDef) => teamDef._id === member._id);
      tmpTeam.push({
        data: member,
        defaultData: defaultMember,
        formState: { isOpened: false },
      });
      return member;
    });
    this._team = tmpTeam;
    this._initialized = true;
    // console.log(this._team);
  }

  /**
   * Create EMPTY team member POST /project/:id/team
   * @param projectId
   * @param data
   * @returns {Promise<T>}
   */
  create = () => {
    // console.log('CREATE STORE');
    this._loading = true;
    this._error = null;
    return TeamAgent.create(this.projectStore.project._id)
      .then((res) => {
        // console.log('New member', res.data._id);
        this.rootStore.toastrStore.success('New team member added');
        this.refreshAndKeepItemOpen(res.data._id);
      })
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error('Error creating new project member', null, err);
      })
      .finally(this.resetFlags);
  };

  async reorder(data) {
    this._loading = false;
    this._error = null;

    try {
      await TeamAgent.reorder(
        this.projectStore.project._id,
        data.map((item) => item.data._id),
      );
      await this.projectStore.refresh();
    } catch (err) {
      this._error = err;
      this.rootStore.toastrStore.error('Error rteordering team members', null, err);
    } finally {
      this.resetFlags();
    }
  }

  // Team member
  updateTeamMember(teamId, data) {
    // console.log('UPDATE STORE', data);
    this._loading = true;
    this._error = null;
    return TeamAgent.updateTeamMember(this.projectStore.project._id, teamId, data)
      .then(() => {
        // console.log('New member', res.data._id);
        this.rootStore.toastrStore.success('Saved');
        this.refreshAndKeepItemOpen(teamId);
      })
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error('Error saving project member', null, err);
      })
      .finally(this.resetFlags);
  }

  /**
   * Remove team member DELETE /project/:id/team/:memberId
   * @param memberId
   * @returns {Promise<T>}
   */
  deleteTeamMember = (memberId) => {
    // console.log('DELETE STORE');
    this._loading = true;
    this._error = null;
    return TeamAgent.deleteTeamMember(this.projectStore.project._id, memberId)
      .then(() => {
        this.rootStore.toastrStore.success('Team member removed');
        return this.projectStore.refresh();
      })
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error('Error deleting project member', null, err);
      })
      .finally(this.resetFlags);
  };

  /**
   * Upload team logo file, progress
   * @param projectId
   * @param teamId
   * @param data
   */
  uploadMemberLogo(teamId, file, progress) {
    // console.log('UPLOAD STORE');
    // TeamAgent.uploadMemberLogo(this.projectStore.project._id, teamId, file, progress);
    // this.projectStore.refresh();projectId, teamId, file, progress
    this._loading = true;
    this._error = null;
    return (
      TeamAgent.uploadMemberLogo(this.projectStore.project._id, teamId, file, progress)
        // .then(() => this.refreshAndKeepItemOpen(teamId))
        .then(
          action((res) => {
            // this.refreshAndKeepItemOpen(teamId)
            // console.log('DATA', res);
            const member = this._team.find((item) => item.data._id === teamId);
            member.data.resource = res.data;
          }),
        )
        .finally(() => {
          this.loading = false;
        })
    );
  }

  /**
   *
   * @param teamId
   * @returns {Promise<T>}
   */
  removeMemberLogo = (teamId) => {
    // console.log('REMOVE IMAGE STORE');
    this._loading = true;
    this._error = null;
    return (
      TeamAgent.removeMemberLogo(this.projectStore.project._id, teamId)
        // .then(() => this.projectStore.refresh())
        // NOW .then(() => this.refreshAndKeepItemOpen(teamId))
        .then(
          action(() => {
            const member = this._team.find((item) => item.data._id === teamId);
            member.data.resource = null;
          }),
        )
        .catch((err) => {
          this._error = err;
          this.rootStore.toastrStore.error('Error deleting logo', null, err);
        })
        .finally(this.resetFlags)
    );
  };

  // removeMemberLogo = (teamId) => {
  //   //console.log('REMOVE IMAGE STORE');
  //   this._loading = true;
  //   this._error = null;
  //   return TeamAgent.removeMemberLogo(this.projectStore.project._id, teamId)
  //     //.then(() => this.projectStore.refresh())
  //     // NOW .then(() => this.refreshAndKeepItemOpen(teamId))
  //     .then(action(() => {
  //       let x = this._team.find(item => item.data._id === teamId);
  //       console.log('member', toJS(x));
  //       x.data.resource = null;
  //       console.log('member', toJS(x));
  //       console.log('team', toJS(this._team));
  //     }))
  //     .catch(err => {
  //       this._error = err;
  //       this.rootStore.toastrStore.error('Error deleting logo', null, err);
  //     })
  //     .finally(this.resetFlags);
  // };

  /**
   * Toggle members/form open/close status
   * @param memberIds
   */
  toggleOpened = (memberIds) => {
    memberIds.forEach((id) => {
      const member = this._team.find((team) => team.data._id === id);
      if (member) {
        member.formState.isOpened = !member.formState.isOpened;
      }
    });
  };

  async refreshAndKeepItemOpen(itemId) {
    const openedMemberIds = this.team
      .filter((member) => member.formState.isOpened && member.data._id !== itemId)
      .map((mbr) => mbr.data._id);

    this.projectStore.refresh().then(() => this.toggleOpened([...openedMemberIds, itemId]));
  }

  resetFlags = () => {
    this._loading = false;
  };
}
