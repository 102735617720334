import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import SidebarHelp from 'common/components/sidebarHelp/SidebarHelp';
import Aside from '../../common/aside/AsideEdit';

import ProjectMasthead from '../../common/masthead/ProjectMasthead';
import {
  ACTIONS,
  BLOCKS,
  PERSONAL_DATA,
  CHARITIES,
  CONTENT,
  CUSTOMIZE,
  DEMOGRAPHICS,
  EDIT_PROJECT,
  FAQ,
  GEOLOCATION,
  IMAGE_GALLERY,
  INFLUENCED_DECISIONS,
  INFORMATION,
  LANGUAGES,
  NEWS,
  PANELS,
  POLLS,
  SEGMENTATION,
  TEAM,
  TIMELINE,
} from '../../common/constants/RouteConstants';
import Information from './information/Information';
import Geolocation from './geolocation/Geolocation';
import Languages from './languages/Languages';
import Timeline from './timeline/Timeline';
import Gallery from './gallery/Gallery';
import Team from './team/Team';
import Influenced from './influenced/Influenced';
import Charities from './charities/Charities';
import PollsList from './polls/pollsList/PollsList';
import CreatePoll from './polls/createPoll/CreatePoll';
import PollEdit from './polls/pollEdit/PollEdit';
import Segmentation from './reports/segmentation/Segmentation';
import Customize from './customize/Customize';
import Panels from './panels/Panels';
import Content from './content/Content';
import Actions from './actions/Actions';
import Blocks from './blocks/Blocks';
import Faq from './faq/Faq';
import News from './news/News';
import CreateNews from './news/NewsCreate';
import NewsEdit from './news/NewsEdit';
import Demographics from './demographics/Demographics';
import { VIEW } from '../../common/ability';
import { Can } from '../../common/Can';
import PersonalData from './personalData/PersonalData';

function ProjectEditContainer(props) {
  const { match, ProjectStore, HelpStore } = props;
  const id = match.params.id;

  return (
    <div className={`l-page l-page--aside ${HelpStore.isContextualHelpActive ? 'l-page--show-help' : ''}`}>
      <div className='l-masthead'>
        <ProjectMasthead
          id={id}
          route={EDIT_PROJECT}
          projectLogo={ProjectStore.project.projectLogo}
          projectName={ProjectStore.project.projectName}
        />
      </div>
      <React.Fragment>
        <Route path={`${match.path}/:route`} component={Aside} />
      </React.Fragment>
      <React.Fragment>
        {/* INFORMATION MENU */}
        <Route
          exact
          path={`${match.path}/${INFORMATION}`}
          render={(componentProps) => (
            <Can I={VIEW} a={INFORMATION}>
              {() => <Information {...componentProps} />}
            </Can>
          )}
        />
        <Route
          exact
          path={`${match.path}/${GEOLOCATION}`}
          render={(componentProps) => (
            <Can I={VIEW} a={GEOLOCATION}>
              {() => <Geolocation {...componentProps} />}
            </Can>
          )}
        />
        <Route
          exact
          path={`${match.path}/${TIMELINE}`}
          render={(componentProps) => (
            <Can I={VIEW} a={TIMELINE}>
              {() => <Timeline {...componentProps} />}
            </Can>
          )}
        />
        <Route
          exact
          path={`${match.path}/${LANGUAGES}`}
          render={(componentProps) => (
            <Can I={VIEW} a={LANGUAGES}>
              {() => <Languages {...componentProps} />}
            </Can>
          )}
        />
        <Route
          exact
          path={`${match.path}/${IMAGE_GALLERY}`}
          render={(componentProps) => (
            <Can I={VIEW} a={IMAGE_GALLERY}>
              {() => <Gallery {...componentProps} />}
            </Can>
          )}
        />
        <Route
          exact
          path={`${match.path}/${TEAM}`}
          render={(componentProps) => (
            <Can I={VIEW} a={TEAM}>
              {() => <Team {...componentProps} />}
            </Can>
          )}
        />
        <Route
          exact
          path={`${match.path}/${INFLUENCED_DECISIONS}`}
          render={(componentProps) => (
            <Can I={VIEW} a={INFLUENCED_DECISIONS}>
              {() => <Influenced {...componentProps} />}
            </Can>
          )}
        />
        <Route
          exact
          path={`${match.path}/${CHARITIES}`}
          render={(componentProps) => (
            <Can I={VIEW} a={CHARITIES}>
              {() => <Charities {...componentProps} />}
            </Can>
          )}
        />

        {/* POLLS MENU  */}
        <Route
          exact
          path={`${match.path}/${POLLS}`}
          render={(componentProps) => (
            <Can I={VIEW} a={POLLS}>
              {() => <PollsList {...componentProps} />}
            </Can>
          )}
        />
        <Route
          exact
          path={`${match.path}/${POLLS}/create-survey`}
          render={(componentProps) => (
            <Can I={VIEW} a={POLLS}>
              {() => <CreatePoll {...componentProps} />}
            </Can>
          )}
        />
        <Route
          exact
          path={`${match.path}/${POLLS}/:pollId/edit-survey`}
          render={(componentProps) => (
            <Can I={VIEW} a={POLLS}>
              {() => <PollEdit {...componentProps} />}
            </Can>
          )}
        />
        <Route
          exact
          path={`${match.path}/${SEGMENTATION}`}
          render={(componentProps) => (
            <Can I={VIEW} a={SEGMENTATION}>
              {() => <Segmentation {...componentProps} />}
            </Can>
          )}
        />
        <Route
          exact
          path={`${match.path}/${DEMOGRAPHICS}`}
          render={(componentProps) => (
            <Can I={VIEW} a={DEMOGRAPHICS}>
              {() => <Demographics {...componentProps} />}
            </Can>
          )}
        />

        {/* CUSTOMIZE MENU */}
        <Route
          exact
          path={`${match.path}/${CUSTOMIZE}`}
          render={(componentProps) => (
            <Can I={VIEW} a={CUSTOMIZE}>
              {() => <Customize {...componentProps} />}
            </Can>
          )}
        />
        <Route
          exact
          path={`${match.path}/${PANELS}`}
          render={(componentProps) => (
            <Can I={VIEW} a={PANELS}>
              {() => <Panels {...componentProps} />}
            </Can>
          )}
        />
        <Route
          exact
          path={`${match.path}/${CONTENT}`}
          render={(componentProps) => (
            <Can I={VIEW} a={CONTENT}>
              {() => <Content {...componentProps} />}
            </Can>
          )}
        />
        <Route
          exact
          path={`${match.path}/${ACTIONS}`}
          render={(componentProps) => (
            <Can I={VIEW} a={ACTIONS}>
              {() => <Actions {...componentProps} />}
            </Can>
          )}
        />
        <Route
          exact
          path={`${match.path}/${BLOCKS}`}
          render={(componentProps) => (
            <Can I={VIEW} a={BLOCKS}>
              {() => <Blocks {...componentProps} />}
            </Can>
          )}
        />
        <Route
          exact
          path={`${match.path}/${PERSONAL_DATA}`}
          render={(componentProps) => (
            <Can I={VIEW} a={PERSONAL_DATA}>
              {() => <PersonalData {...componentProps} />}
            </Can>
          )}
        />

        {/* FAQ MENU */}
        <Route
          exact
          path={`${match.path}/${FAQ}`}
          render={(componentProps) => (
            <Can I={VIEW} a={FAQ}>
              {() => <Faq {...componentProps} />}
            </Can>
          )}
        />

        {/* NEWS MENU */}
        <Route
          exact
          path={`${match.path}/${NEWS}`}
          render={(componentProps) => (
            <Can I={VIEW} a={NEWS}>
              {() => <News {...componentProps} />}
            </Can>
          )}
        />
        <Route
          exact
          path={`${match.path}/${NEWS}/create-news`}
          render={(componentProps) => (
            <Can I={VIEW} a={NEWS}>
              {() => <CreateNews {...componentProps} />}
            </Can>
          )}
        />
        <Route
          exact
          path={`${match.path}/${NEWS}/:newsId/edit-news`}
          render={(componentProps) => (
            <Can I={VIEW} a={NEWS}>
              {() => <NewsEdit {...componentProps} />}
            </Can>
          )}
        />
        <SidebarHelp />
      </React.Fragment>
    </div>
  );
}

export default inject((root) => ({
  ProjectStore: root.RootStore.projectStore,
  HelpStore: root.RootStore.helpStore,
}))(observer(ProjectEditContainer));
