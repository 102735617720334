import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import SvgIcon from '../../../../common/components/SvgIcon';
import GeneratedReportsCompleted from '../../reports/generate/GeneratedReports/GeneratedReportsCompleted';
import GeneratedReportsWorking from '../../reports/generate/GeneratedReports/GeneratedReportsWorking';

const { FAILED, COMPLETED, RUNNING, QUEUED } = require('../../../../shared/engage/engage-report-enums')().QUEUE_STATUS;
const { POLL_PREVIEW_PDF, OUTPUT_PDF } = require('../../../../shared/engage/engage-report-enums')()
  .GenerateReportConstants;

function GeneratePreviewForm(props) {
  const { projectId, pollId, previewLink, ReportsStore } = props;

  const [buttonLoading, setButtonLoading] = useState(false);

  // I want reports in descending order (most recent first)
  const reportStatuses = ReportsStore.reportStatuses
    .slice()
    .filter(
      (report) => report.reportType === POLL_PREVIEW_PDF && report.inputParams?.payload?.params?.pollId === pollId,
    )
    .sort((a, b) => {
      return new Date(b.timestamp) - new Date(a.timestamp);
    });

  useEffect(() => {
    // Skip if not yet initialized
    if (ReportsStore && projectId) {
      ReportsStore.getJobStatus(projectId);
      ReportsStore.setCurrentProjectId(projectId);
    }
  }, [projectId, ReportsStore]);

  const generatePDF = () => {
    const params = {
      reportType: POLL_PREVIEW_PDF,
      outputType: OUTPUT_PDF,
      pollId,
      filter: {},
    };

    setButtonLoading(true);
    setTimeout(() => setButtonLoading(false), 2500);

    ReportsStore.generateReport(projectId, params);
  };

  return (
    <div className='c-field l-form__item c-survey-export'>
      <label className='c-field__label'>Survey export</label>
      <div className='c-field__group'>
        <div className='c-message c-message--icon is-naked'>
          <SvgIcon icon='bell' />
          <p className='c-message__description'>
            Use <strong>Generate survey preview PDF</strong> button to prepare survey journey in both HTML and PDF
            formats. Keep in mind that links to these survey exports <strong>will expire after 30 minutes</strong>.
          </p>
        </div>
        <button
          className={`o-button o-button--s o-button--primary ${buttonLoading ? 'is-loading' : ''}`}
          type='button'
          onClick={generatePDF}
        >
          <span className='o-label'>Generate survey preview PDF</span>
        </button>
        <a className='o-button o-button--s o-button--link' href={previewLink} target='_blank' rel='noreferrer noopener'>
          <span className='o-label'>Preview survey</span>
        </a>
        {reportStatuses.length > 0 ? (
          <div className='c-report-download c-report-download--survey-export'>
            <div className='c-report-list'>
              {reportStatuses.map((report) => {
                return (
                  <React.Fragment key={report._id}>
                    {COMPLETED === report.status ? (
                      <GeneratedReportsCompleted
                        name={report.reportKey}
                        timestamp={report.timestamp}
                        jobId={report._id}
                        dataType={report.dataType}
                        reportType={report.reportType}
                      />
                    ) : null}
                    {[RUNNING, QUEUED, FAILED].includes(report.status) ? (
                      <GeneratedReportsWorking
                        name={report.reportKey}
                        timestamp={report.timestamp}
                        isError={report.status === FAILED}
                      />
                    ) : null}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

// export default GeneratePreviewForm;

export default inject((root) => ({
  ReportsStore: root.RootStore.generateReportStore,
}))(observer(GeneratePreviewForm));
