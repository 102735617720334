import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import SimpleCheckboxInput from '../../../../common/components/form-elements/checkboxInputField/SimpleCheckboxInput';
import SelectInputField from '../../../../common/components/form-elements/selectInputField/SelectInputField';

function SegmentationListItem(props) {
  const {
    segmentationFilterValue,
    segmentationFilterQuestions,
    segmentationFilterQuestionChoices,
    onSegmentationFilterQuestionChange,
  } = props;
  return (
    <li className={`c-list-settings__item ${segmentationFilterValue ? 'c-list-settings__item--highlight' : ''}`}>
      <Field
        component={SimpleCheckboxInput}
        name='segmentationFilter'
        classModifier='c-list-settings__name'
        label='Segmentation'
        id='segmentationFilter'
        type='checkbox'
        disabled={segmentationFilterQuestions.length === 0}
      />
      <div className='c-list-settings__details' style={{ display: `${segmentationFilterValue ? 'block' : 'none'}` }}>
        <div className='c-fields l-form'>
          <SelectInputField
            firstOptionEmpty={false}
            label='Segmentation question'
            id='segmentationFilterQuestion'
            fieldClass='l-form__item--m'
            fieldName='segmentationFilterQuestion'
            choices={segmentationFilterQuestions}
            onSelectionChange={onSegmentationFilterQuestionChange}
          />
          <SelectInputField
            firstOptionEmpty={false}
            label='Segmentation group'
            id='segmentationFilterQuestionChoice'
            fieldClass='l-form__item--m'
            fieldName='segmentationFilterQuestionChoice'
            choices={segmentationFilterQuestionChoices}
          />
        </div>
      </div>
    </li>
  );
}

SegmentationListItem.propTypes = {
  segmentationFilterValue: PropTypes.bool,
  segmentationFilterQuestions: PropTypes.array.isRequired,
  segmentationFilterQuestionChoices: PropTypes.array.isRequired,
  onSegmentationFilterQuestionChange: PropTypes.func.isRequired,
};

export default SegmentationListItem;
