import Agent from './agent';

const requests = Agent.requests;
const requestsV2 = Agent.requestsV2;
const ProjectAgent = {
  // Project list
  all: () => requests.get(`/projects`),
  getClientProjects: (clientId) => requests.get(`/projects/client/${clientId}`),
  create: (data) => requests.post(`/projects`, data),
  createWithClientId: (clientId, data) => requests.post(`/projects/client/${clientId}`, data),

  // Project Basic
  getById: (projectId) => requests.get(`/projects/${projectId}`),
  uploadLogo: (file, projectId, progress) =>
    requests.uploadImage(`/projects/${projectId}/logo`, 'logo', file, progress),
  removeLogo: (projectId) => requests.remove(`/projects/${projectId}/logo`),
  uploadBackground: (file, projectId, progress) =>
    requests.uploadImage(`/projects/${projectId}/background`, 'profileBackground', file, progress),
  removeBackground: (projectId) => requests.remove(`/projects/${projectId}/background`),
  updateInformation: (projectId, data) => requests.patch(`/projects/${projectId}`, data),
  validateSlug: (slug) => requestsV2.post(`/projects/slug/slug-validator`, slug),
  registerAsVisited: (data) => requestsV2.post(`/visit`, data),

  // Geolocation
  setGeoLocation: (projectId, data) => requests.post(`/projects/${projectId}/location`, data),
  setGeoValidArea: (projectId, data) => requests.post(`/projects/${projectId}/validity-area`, data),
  // removeGeoValidArea: (projectId) => requests.remove(`/projects/${projectId}/validity-area`),

  // Languages
  updateLanguages: (projectId, languages) => requests.post(`/projects/${projectId}/languages`, languages),

  // Customization
  updateStaticText: (projectId, data) => requests.post(`/projects/${projectId}/static-texts-replacements`, data),
  setEndOfPollButtons: (projectId, data) => requests.post(`/projects/${projectId}/end-of-poll-flow`, data),
  removeEndOfPollButtons: (projectId, data) => requests.remove(`/projects/${projectId}/end-of-poll-flow/${data}`),
  setHomepageSectionsFlow: (projectId, data) => requests.post(`/projects/${projectId}/homepage-sections-flow`, data),
  deleteHomepageSectionsFlow: (projectId) => requests.remove(`/projects/${projectId}/homepage-sections-flow`),
  updatePersonalDataConfiguration: (projectId, data) => requests.post(`/projects/${projectId}/personal-data`, data),
};

export default ProjectAgent;
