import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import ClientListItem from './ClientListItem';

function ClientList(props) {
  const { clients } = props;

  return (
    <ul className='c-list-clients'>
      {clients.length > 0 && clients.map((client, index) => <ClientListItem key={client._id} client={client} />)}
    </ul>
  );
}

ClientList.propTypes = {
  clients: PropTypes.array.isRequired,
};

export default ClientList;
