import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../tooltip/Tooltip';

export default function SimpleTextInputWithAction(props) {
  const {
    disabled,
    fieldClass,
    fieldDescription,
    id,
    input,
    label,
    buttonLabel,
    onClick,
    onKeyDown,
    meta = {},
    placeholder,
    tooltipInLabel,
    buttonType = 'submit',
  } = props;

  const isErrorShown = meta.error && meta.touched;

  function getContainerClass(metaForClass) {
    const errorClass = metaForClass.error && metaForClass.touched ? 'is-error' : '';
    return `c-field l-form__item ${fieldClass} ${errorClass}`;
  }

  function clickHandler() {
    if (!meta.error && onClick) onClick();
  }

  return (
    <div className={getContainerClass(meta)} data-testid={`${id}-wrapper`}>
      <label className='c-field__label' htmlFor={id} data-testid={`${id}-label`}>
        {label}
        {tooltipInLabel && <Tooltip {...tooltipInLabel} />}
      </label>
      <div className='c-field__group'>
        <div className='o-input-button'>
          <input
            {...input}
            data-testid={`${id}-input`}
            disabled={disabled}
            id={id}
            placeholder={placeholder}
            type='text'
            onKeyDown={onKeyDown}
          />
          <button className='o-button o-button--primary' type={buttonType} onClick={clickHandler}>
            <span className='o-label'>{buttonLabel}</span>
          </button>
        </div>

        {isErrorShown && <p className='c-field__description c-field__description--error'>{meta.error}</p>}

        <p
          className='c-field__description'
          data-testid='description'
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: fieldDescription || '' }}
        />
      </div>
    </div>
  );
}

SimpleTextInputWithAction.propTypes = {
  disabled: PropTypes.bool,
  fieldClass: PropTypes.string,
  fieldDescription: PropTypes.string,
  id: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  buttonLabel: PropTypes.string,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  tooltipInLabel: PropTypes.object,
  buttonType: PropTypes.string,
};
