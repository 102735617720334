import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';
import DateUtils from '../../DateUtils';
import { perc } from '../../../containers/project/reports/polls-report/PollReportUtils';

function PassiveFilter(props) {
  const { filters } = props;

  const { fromDate, toDate, segmentation, demographic, conditional } = filters;

  const [segmentationFilterDesc, setSegmentationFilterDesc] = useState();
  const [demographicFilterDesc, setDemographicFilterDesc] = useState();
  const [conditionalLogicFilterDesc, setConditionalLogicFilterDesc] = useState();

  useEffect(() => {
    if (segmentation) {
      let questionAnsweredCount = 0;
      segmentation.question.question.choices.forEach((choice) => (questionAnsweredCount += choice.count));

      const selectedSegmentationChoice = segmentation.question.question.choices.find(
        (choice) => choice._id === segmentation.selectedChoiceId,
      );

      setSegmentationFilterDesc({
        choiceId: selectedSegmentationChoice._id,
        questionText: segmentation.question.question.questionText,
        choiceText: selectedSegmentationChoice.choice,
        count: `${perc(selectedSegmentationChoice.count, questionAnsweredCount)}%`,
      });
    }

    if (demographic) {
      let questionAnsweredCount = 0;
      demographic.question.question.choices.forEach((choice) => (questionAnsweredCount += choice.count));

      const selectedDemographicChoice = demographic.question.question.choices.find(
        (choice) => choice._id === demographic.selectedChoiceId,
      );

      setDemographicFilterDesc({
        questionText: demographic.question.question.questionText,
        choiceText: selectedDemographicChoice.choice,
        count: `${perc(selectedDemographicChoice.count, questionAnsweredCount)}%`,
      });
    }

    if (conditional) {
      let questionAnsweredCount = 0;
      conditional.question.question.choices.forEach((choice) => (questionAnsweredCount += choice.count));

      const selectedChoice = conditional.question.question.choices.find(
        (choice) => choice._id === conditional.selectedChoiceId,
      );

      setConditionalLogicFilterDesc({
        questionText: conditional.question.question.questionText,
        choiceText: selectedChoice.choice,
        count: `${perc(selectedChoice.count, questionAnsweredCount)}%`,
      });
    }
  }, [segmentation, demographic, conditional]);

  function getDateRangeLabel() {
    if (fromDate && !toDate) return `from ${DateUtils.getShortDateString(fromDate)}`;

    if (!fromDate && toDate) return `until ${DateUtils.getShortDateString(toDate)}`;

    if (fromDate && toDate)
      return `${DateUtils.getShortDateString(fromDate)} - ${DateUtils.getShortDateString(toDate)}`;
  }

  return (
    <div className='c-browse-feedback__primary-filter' data-testid='feedback-passive-filter'>
      <div className='c-filter-overview-list c-filter-overview-list--minimalistic'>
        <div className='c-filter-overview-list__header'>
          <h3 className='c-filter-overview-list__title'>
            Survey filters
            <span className='o-tooltip o-tooltip--bottom-center o-tooltip--dark'>
              <span className='o-tooltip__action'>
                <SvgIcon icon='info-circle' />
              </span>
              <span className='o-tooltip__description' role='tooltip'>
                Survey filters can be changed under Reporting &gt; Surveys, for each desired survey.
              </span>
            </span>
          </h3>
        </div>
        <div className='c-filter-overview-list__content'>
          <ol className='c-filter-overview-list__active-filters'>
            {(fromDate || toDate) && (
              <li className='c-filter-overview-list__item'>
                <span className='o-filter-label'>Date range</span>
                <span className='o-filter-value'>{getDateRangeLabel()}</span>
              </li>
            )}
            {segmentationFilterDesc && (
              <li className='c-filter-overview-list__item' data-testid='passive-filter-segmentation'>
                <span className='o-filter-label'>Custom metric</span>
                <span className='o-filter-value'>
                  Segmentation &gt; {segmentationFilterDesc.questionText}
                  <strong>
                    {' '}
                    {/* '-1' is dedicated to "Did not answer" option, no need to display both question and choice texts because they are very similar */}
                    {`${segmentationFilterDesc.choiceId !== '-1' ? segmentationFilterDesc.choiceText : ''} ${
                      segmentationFilterDesc.count
                    }`}
                  </strong>
                </span>
              </li>
            )}
            {demographicFilterDesc && (
              <li className='c-filter-overview-list__item' data-testid='passive-filter-demographic'>
                <span className='o-filter-label'>Custom metric</span>
                <span className='o-filter-value'>
                  Demographic &gt; {demographicFilterDesc.questionText}
                  <strong> {`${demographicFilterDesc.choiceText} ${demographicFilterDesc.count}`}</strong>
                </span>
              </li>
            )}

            {conditionalLogicFilterDesc && (
              <li className='c-filter-overview-list__item' data-testid='passive-filter-conditional'>
                <span className='o-filter-label'>Custom metric</span>
                <span className='o-filter-value'>
                  Conditional &gt; {conditionalLogicFilterDesc.questionText}
                  <strong> {`${conditionalLogicFilterDesc.choiceText} ${conditionalLogicFilterDesc.count}`}</strong>
                </span>
              </li>
            )}
          </ol>
        </div>
      </div>
    </div>
  );
}

PassiveFilter.propTypes = {
  filters: PropTypes.shape({
    fromDate: PropTypes.string,
    toDate: PropTypes.string,
    segmentation: PropTypes.shape({
      questions: PropTypes.array,
      selectedChoiceId: PropTypes.string,
    }),
    demographic: PropTypes.shape({
      questions: PropTypes.array,
      selectedChoiceId: PropTypes.string,
    }),
    conditional: PropTypes.shape({
      questions: PropTypes.array,
      selectedChoiceId: PropTypes.string,
    }),
  }),
};

export default PassiveFilter;
