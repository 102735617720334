import React from 'react';
import SvgIcon from '../components/SvgIcon';

function NoSubscription() {
  return (
    <div className='l-body'>
      <div className='l-container'>
        <div className='l-page'>
          <div className='l-main'>
            <div className='c-message c-message--icon c-message--highlight is-warning'>
              <SvgIcon icon='megaphone' />
              <h3 className='c-message__title'>No active subscription</h3>
              <p className='c-message__description'>
                Unfortunately, your subscription is not yet active. To fix this, please contact the administrator.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoSubscription;
