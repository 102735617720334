import React, { useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import MDEditor from '@uiw/react-md-editor';
// eslint-disable-next-line import/no-cycle
import { PollReportContext } from '../PollsReport';
import QuestionHeader from '../../QuestionHeader';
import QuestionFooter from '../../QuestionFooter';

function FactTextPreview(props) {
  const { question } = props;
  const { questionId, label, skipSeen } = question;

  const pollReportContext = useContext(PollReportContext);
  const containerReference = useRef();

  useEffect(() => {
    if (pollReportContext)
      pollReportContext.addReference({ id: questionId, containerReference: containerReference.current });
  }, [pollReportContext, questionId]);

  return (
    <div ref={containerReference} className='c-poll-preview__item c-poll-preview__item--fact'>
      <div className='c-question c-question--fact'>
        <QuestionHeader question={question} showTitle={false} />
        <div className='c-question__content'>
          <div className='c-question-fact'>
            <div className='c-question-fact__content u-rich-text'>
              <MDEditor.Markdown source={label} className='markdown_preview' />
            </div>
          </div>
        </div>
        <QuestionFooter viewed={skipSeen.seenCount} showSkipped={false} showAnswered={false} />
      </div>
    </div>
  );
}

FactTextPreview.propTypes = {
  question: PropTypes.shape({
    questionId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    skipSeen: PropTypes.shape({
      seenCount: PropTypes.number,
    }),
  }).isRequired,
};

export default FactTextPreview;
