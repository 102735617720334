import {
  IMAGE_TYPE_LOGO,
  IMAGE_TYPE_PROFILE_BACKGROUND,
  IMAGE_TYPE_PROJECT_PANEL,
  IMAGE_TYPE_PROJECT_SQUARE_2x,
  IMAGE_ORIGINAL,
  REACTION_PNG_IMAGE_TYPE,
} from '../../../ImageUtils';

export const IMAGE_FORMATS = {
  AVATAR: {
    ratio: '1:1',
    minSize: { width: 200, height: 200 },
    size: { width: 200, height: 200 },
    slimModifier: 'o-slim--circle',
    previewModifier: 'c-upload--circle',
    imageType: IMAGE_TYPE_LOGO,
    popupModifier: 'slim-popover--crop-circle',
  },
  LOGO: {
    ratio: '1:1',
    minSize: { width: 300, height: 300 },
    size: { width: 600, height: 600 },
    slimModifier: 'o-slim--square',
    imageType: IMAGE_TYPE_LOGO,
  },
  SQUARE: {
    ratio: '1:1',
    minSize: { width: 300, height: 300 },
    size: { width: 600, height: 600 },
    slimModifier: 'o-slim--fluid',
    imageType: IMAGE_TYPE_PROJECT_SQUARE_2x,
  },
  SQUARE_BIG: {
    ratio: '1:1',
    minSize: { width: 600, height: 600 },
    size: { width: 1200, height: 1200 },
    slimModifier: 'o-slim--fluid',
    imageType: IMAGE_TYPE_PROJECT_SQUARE_2x,
  },
  BACKGROUND: {
    ratio: '4:1',
    minSize: { width: 1600, height: 400 },
    size: { width: 1600, height: 400 },
    slimModifier: 'o-slim--background',
    imageType: IMAGE_TYPE_PROFILE_BACKGROUND,
  },
  PANEL: {
    ratio: '5:3',
    minSize: { width: 750, height: 450 },
    size: { width: 1500, height: 900 },
    slimModifier: 'o-slim--landscape',
    imageType: IMAGE_TYPE_PROJECT_PANEL,
  },
  FLUID_PANEL: {
    ratio: '5:3',
    minSize: { width: 750, height: 450 },
    size: { width: 1500, height: 900 },
    slimModifier: 'o-slim--fluid',
    imageType: IMAGE_TYPE_PROJECT_PANEL,
  },
  REACTION: {
    ratio: '1:1',
    minSize: { width: 64, height: 64 },
    size: { width: 64, height: 64 },
    slimModifier: 'o-slim--circle',
    previewModifier: 'c-upload--circle',
    // For reaction png images
    imageType: REACTION_PNG_IMAGE_TYPE,
    // For reaction jpeg images
    // imageType: IMAGE_TYPE_LOGO,
    popupModifier: 'slim-popover--crop-circle',
  },
  REACTION_IMAGE: {
    ratio: 'free',
    size: { width: 1600, height: 1600 },
    slimModifier: 'o-slim--landscape',
    imageType: IMAGE_ORIGINAL,
  },
  ZOOMABLE_LEAD_IMAGE: {
    ratio: 'free',
    size: { width: 1600, height: 1600 },
    minSize: { width: 800, height: 800 },
    slimModifier: 'o-slim--landscape',
    imageType: IMAGE_ORIGINAL,
  },
};
