import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

export default function SwitchableButtonBar({
  overrideAction,
  updateAction,
  removeAction,
  isNew,
  isOverrideActive = false,
  labelOverride = 'Override',
  labelUpdate = 'Update',
  labelRemove = 'Delete',
  previewAction,
  updateEnabled = true,
  previewEnabled = true,
  removeEnabled = true,
  submitButtonType = 'button',
}) {
  return (
    <div className='c-field c-field--action l-form__item' data-testid='switchableButtonBar-wrapper'>
      <div className='c-field__group' data-testid='actions-wrapper'>
        {/* Temporarily disable override [ ON REVERT ENABLE OVERRIDE BUTTON AND LABEL RELATED STUFF IN TEST FILE] */}
        {overrideAction && !isOverrideActive && (
          <button
            className='o-button o-button--primary'
            type='button'
            onClick={overrideAction}
            data-testid='override-action-button'
          >
            <span className='o-label' data-testid='override-action-label'>
              {labelOverride}
            </span>
          </button>
        )}
        {updateAction && (!overrideAction || isOverrideActive) ? (
          <button
            className={`o-button o-button--primary ${updateEnabled ? '' : 'is-disabled'}`}
            onClick={updateAction}
            data-testid='update-action-button'
            type={submitButtonType}
          >
            <span className='o-label' data-testid='update-action-label'>
              {isNew ? 'Create' : labelUpdate}
            </span>
          </button>
        ) : null}
        {previewAction && (
          <button
            className={`o-button o-button--alternate ${previewEnabled ? '' : 'is-disabled'}`}
            type='button'
            onClick={previewAction}
            data-testid='preview-action-button'
          >
            <SvgIcon icon='mobile' />
            <span className='o-label' data-testid='preview-action-label'>
              Preview
            </span>
          </button>
        )}
        {(removeAction || isOverrideActive) && (
          <button
            className={`o-button o-button--danger ${removeEnabled ? '' : 'is-disabled'}`}
            onClick={removeAction}
            data-testid='remove-action-button'
            type='button'
          >
            <span className='o-label' data-testid='remove-action-label'>
              {isNew ? 'Cancel' : labelRemove}
            </span>
          </button>
        )}
      </div>
    </div>
  );
}

SwitchableButtonBar.propTypes = {
  overrideAction: PropTypes.func,
  updateAction: PropTypes.func,
  removeAction: PropTypes.func,
  previewAction: PropTypes.func,
  isNew: PropTypes.bool,
  isOverrideActive: PropTypes.bool,
  labelUpdate: PropTypes.string,
  labelRemove: PropTypes.string,
  updateEnabled: PropTypes.bool,
  previewEnabled: PropTypes.bool,
  removeEnabled: PropTypes.bool,
};
