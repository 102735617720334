import Agent from './agent';

const requests = Agent.requests;
const requestsV2 = Agent.requestsV2;
const ReportsAgent = {
  loadOverviewGAReport: (projectId, query) =>
    requestsV2.get(`/projects/${projectId}/reports/project-overview/ga-data`, query),
  loadOverviewDBReport: (projectId, query) =>
    requestsV2.get(`/projects/${projectId}/reports/project-overview/db-data`, query),

  loadPollsReport: (projectId, pollId, query) =>
    requestsV2.get(`/projects/${projectId}/reports/polls/${pollId}/report`, query),

  loadDemographicsReport: (projectId, query) => requestsV2.get(`/projects/${projectId}/reports/demographics`, query),
  loadPollFilterData: (projectId, pollId, query) =>
    requestsV2.get(`/projects/${projectId}/reports/polls/${pollId}/filter-stats`, query),

  // Launch batched report job
  startReport: (projectId, params) => requestsV2.post(`/projects/${projectId}/reports/generate`, params),

  // general BATCHED report handling
  // status & download
  checkReportStatus: (projectId) => requestsV2.get(`/reports/common/status?projectId=${projectId}`),
  downloadBatchedReport: (jobId) => requestsV2.get(`/reports/common/download/${jobId}`),

  // getExcelReport: (projectId, pollId, questionId, query) =>
  //   requests.get(`/projects/${projectId}/polls/${pollId}/questions/${questionId}/feedback/report/excel`, query),
  getExcelReport: (projectId, pollId, questionId, query) =>
    requestsV2.get(
      `/projects/${projectId}/reports/polls/${pollId}/questions/${questionId}/report/feedback/excel`,
      query,
    ),

  // Single question feedback
  getQuestionFeedback: (projectId, pollId, questionId, query) =>
    requestsV2.post(
      `/projects/${projectId}/reports/polls/${pollId}/questions/${questionId}/report/feedback/online`,
      query,
    ),
  downloadQuestionFeedback: (projectId, questionId) =>
    requests.get(`/projects/${projectId}/questions/${questionId}/feedback/excel`),
  downloadQuestionFeedbackImages: (projectId, questionId) =>
    requests.get(`/projects/${projectId}/questions/${questionId}/feedback/images`),
  highlightQuestionFeedback: (projectId, questionId, feedbackId) =>
    requests.get(`/projects/${projectId}/questions/${questionId}/feedback/${feedbackId}/highlight`),
  downloadSingleQuestionFeedback: (projectId, questionId, feedbackId) =>
    requests.get(`/projects/${projectId}/questions/${questionId}/feedback/${feedbackId}/download`),
};

export default ReportsAgent;
