// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';

export const THIS_WEEK = 'this_week';
export const LAST_WEEK = 'last_week';
export const THIS_MONTH = 'this_month';
export const LAST_MONTH = 'last_month';

export function getRangeDates(predefinedPeriod) {
  let rangeStart;
  let rangeEnd;
  switch (predefinedPeriod) {
    case THIS_WEEK:
      rangeStart = moment().startOf('week').toDate();
      rangeEnd = moment().endOf('week').toDate();
      break;
    case LAST_WEEK:
      rangeStart = moment().subtract(1, 'weeks').startOf('week').toDate();
      rangeEnd = moment().subtract(1, 'weeks').endOf('week').toDate();
      break;
    case THIS_MONTH:
      rangeStart = moment().startOf('month').toDate();
      rangeEnd = moment().endOf('month').toDate();
      break;
    case LAST_MONTH:
      rangeStart = moment().subtract(1, 'months').startOf('month').toDate();
      rangeEnd = moment().subtract(1, 'months').endOf('month').toDate();
      break;
    default:
      rangeStart = '';
      rangeEnd = '';
      break;
  }

  return { rangeStart, rangeEnd };
}
