import React from 'react';
import PropTypes from 'prop-types';
import PassiveFilter from './PassiveFilter';
import ActiveFilter from './ActiveFilter';
import { PollEnums } from '../../../shared/engage';

export default function PollReportFeedbackHeader(props) {
  const {
    title,
    searchValue,
    showMediaFeedback,
    showTextFeeedback,
    selectedReaction,
    reactions,
    onClearActiveFilters,
    onMediaFilterChange,
    onTextFilterChange,
    onSearchChange,
    onSearchClear,
    onReactionChange,
    displayLocations,
    onDisplayLocationsChange,
    nonEditableSurveyFilters,
    questionType,
    feedbackMeta,
    isFetchingData,
  } = props;

  function hasNonEditableFilterData() {
    return (
      nonEditableSurveyFilters?.fromDate ||
      nonEditableSurveyFilters?.toDate ||
      nonEditableSurveyFilters?.segmentation ||
      nonEditableSurveyFilters?.demographic ||
      nonEditableSurveyFilters?.conditional
    );
  }

  function showLocationMediaSwitch() {
    return (
      PollEnums().isAnnotationQuestion(questionType) &&
      feedbackMeta?.questionConfiguration?.allowUserContentUpload?.image
    );
  }

  return (
    <div className='c-browse-feedback__header' data-testid='feedback-header-wrapper'>
      {hasNonEditableFilterData() && <PassiveFilter filters={nonEditableSurveyFilters} />}
      <h2 className='c-browse-feedback__title'>{title}</h2>
      <div className='c-browse-feedback__options'>
        <ActiveFilter
          searchValue={searchValue}
          showMediaFeedback={showMediaFeedback}
          showTextFeeedback={showTextFeeedback}
          selectedReaction={selectedReaction}
          reactions={reactions}
          onMediaFilterChange={onMediaFilterChange}
          onTextFilterChange={onTextFilterChange}
          onSearchChange={onSearchChange}
          onSearchClear={onSearchClear}
          onReactionChange={onReactionChange}
          onClearActiveFilters={onClearActiveFilters}
          questionType={questionType}
          feedbackMeta={feedbackMeta}
          isFetchingData={isFetchingData}
        />
        {showLocationMediaSwitch() && (
          <div className='c-browse-feedback__display-options'>
            <div className='c-toggle-navigation'>
              <ul
                className='c-toggle-navigation__container'
                role='tablist'
                aria-label='Toggle between media and location'
              >
                <li className='c-toggle-navigation__item'>
                  <button
                    id='showMedia_btn'
                    role='tab'
                    aria-selected={!displayLocations}
                    tabIndex='0'
                    aria-controls='panel--01'
                    onClick={() => onDisplayLocationsChange(false)}
                  >
                    Show media
                  </button>
                </li>

                <li className='c-toggle-navigation__item'>
                  <button
                    id='showLocation_btn'
                    role='tab'
                    aria-selected={displayLocations}
                    tabIndex='-1'
                    aria-controls='panel--02'
                    onClick={() => onDisplayLocationsChange(true)}
                  >
                    Show location
                  </button>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

PollReportFeedbackHeader.propTypes = {
  title: PropTypes.string,
  searchValue: PropTypes.string.isRequired,
  showMediaFeedback: PropTypes.bool.isRequired,
  showTextFeeedback: PropTypes.bool.isRequired,
  selectedReaction: PropTypes.object,
  reactions: PropTypes.array,
  onClearActiveFilters: PropTypes.func.isRequired,
  onMediaFilterChange: PropTypes.func.isRequired,
  onTextFilterChange: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onSearchClear: PropTypes.func.isRequired,
  onReactionChange: PropTypes.func,
  displayLocations: PropTypes.bool.isRequired,
  onDisplayLocationsChange: PropTypes.func,
  nonEditableSurveyFilters: PropTypes.shape({
    fromDate: PropTypes.string,
    toDate: PropTypes.string,
    segmentation: PropTypes.object,
    demographic: PropTypes.object,
    conditional: PropTypes.object,
  }),
  questionType: PropTypes.string.isRequired,
  feedbackMeta: PropTypes.object,
  isFetchingData: PropTypes.bool.isRequired,
};
