import { reaction, action, observable, computed, makeObservable } from 'mobx';

import AgentPoll from '../common/agent';

const Agent = AgentPoll.CharitiesAgent;

const ITEM_LABEL = 'charity';
const CREATE_SUCCESS = `New ${ITEM_LABEL} added`;
const CREATE_ERROR = `Error creating ${ITEM_LABEL}`;
const REORDER_ERROR = `Error reordering ${ITEM_LABEL}`;
const UPDATE_SUCCESS = 'Saved';
const UPDATE_ERROR = `Error saving ${ITEM_LABEL}`;
const DELETE_SUCCESS = 'Charity removed';
const DELETE_ERROR = `Error deleting ${ITEM_LABEL}`;
const RESOURCE_DELETE_ERROR = 'Error deleting resource';

export default class CharitiesStore {
  _loading = false;

  _initialized = false;

  _error = null;

  _items = [];

  constructor(rootStore, projectStore) {
    makeObservable(this, {
      _items: observable,
      _loading: observable,
      _initialized: observable,
      _error: observable,

      resetFlags: action,

      charities: computed,
      projectId: computed,
      isLoading: computed,
      isInitialized: computed,
      error: computed,

      loadData: action,
      toggleOpened: action,
    });

    this.rootStore = rootStore;
    this.projectStore = projectStore;
    reaction(
      () => this.projectStore.isInitialized,
      () => this.loadData(this.projectStore.project.charities, this.projectStore.projectDefault.charities),
    );
  }

  get isLoading() {
    return this._loading;
  }

  get isInitialized() {
    return this._initialized;
  }

  get error() {
    return this._error;
  }

  get charities() {
    return this._items;
  }

  // needed for hotfix https://github.com/sdesregistry/IH-Engage/issues/1261
  get projectId() {
    return this.projectStore.project ? this.projectStore.project._id : null;
  }

  /**
   * Load and prepare data already loaded in ProjectStore
   * @param charities
   */
  loadData(charities, charitiesDefault) {
    const tmp = [];
    charities.map((item) => {
      const defaultCharity = charitiesDefault.find((charityDef) => charityDef._id === item._id);
      tmp.push({
        data: item,
        defaultData: defaultCharity,
        formState: { isOpened: false },
      });
      return item;
    });
    this._items = tmp;
    this._initialized = true;
    // console.log(this._team);
  }

  /**
   * Create charity
   * @param data
   * @returns {Promise<T>}
   */
  create = () => {
    // console.log('CREATE STORE');
    this._loading = true;
    this._error = null;
    return Agent.create(this.projectStore.project._id)
      .then((res) => {
        // console.log('New member', res.data._id);
        this.rootStore.toastrStore.success(CREATE_SUCCESS);
        this.refreshAndKeepItemOpen(res.data._id);
      })
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(CREATE_ERROR, null, err);
      })
      .finally(this.resetFlags);
  };

  async reorder(data) {
    this._loading = false;
    this._error = null;

    try {
      await Agent.reorder(
        this.projectStore.project._id,
        data.map((item) => item.data._id),
      );
      await this.projectStore.refresh();
    } catch (err) {
      this._error = err;
      this.rootStore.toastrStore.error(REORDER_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  // Update decision
  update(itemId, data) {
    // console.log('UPDATE STORE', data);
    this._loading = true;
    this._error = null;
    return Agent.update(this.projectStore.project._id, itemId, data)
      .then(() => {
        // console.log('New member', res.data._id);
        this.rootStore.toastrStore.success(UPDATE_SUCCESS);
        this.refreshAndKeepItemOpen(itemId);
      })
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(UPDATE_ERROR, null, err);
      })
      .finally(this.resetFlags);
  }

  /**
   * Remove decision DELETE /project/:id/influenced-decisions/:decisionId
   * @param itemId
   * @returns {Promise<T>}
   */
  remove = (itemId) => {
    // console.log('DELETE STORE');
    this._loading = true;
    this._error = null;
    return Agent.remove(this.projectStore.project._id, itemId)
      .then(() => {
        this.rootStore.toastrStore.success(DELETE_SUCCESS);
        return this.projectStore.refresh();
      })
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(DELETE_ERROR, null, err);
      })
      .finally(this.resetFlags);
  };

  /**
   * Upload decision resource
   * @param decisionId
   * @param file
   * @param progress
   * @returns {Promise<boolean> | Promise<T> | *}
   */
  uploadResource(itemId, file, progress) {
    // console.log('UPLOAD STORE');
    this._loading = true;
    this._error = null;
    return (
      Agent.uploadResource(this.projectStore.project._id, itemId, file, progress)
        // .then(() => this.refreshAndKeepItemOpen(itemId))
        .then(
          action((res) => {
            const item = this._items.find((_charity) => _charity.data._id === itemId);
            item.data.resource = res.data;
          }),
        )
        .finally(() => (this._loading = false))
    );
  }

  /**
   * remove decision resource
   * @param decisionId
   * @returns {Promise<T>}
   */
  removeResource = (itemId) => {
    // console.log('REMOVE IMAGE STORE');
    this._loading = true;
    this._error = null;
    return (
      Agent.removeResource(this.projectStore.project._id, itemId)
        // .then(() => this.refreshAndKeepItemOpen(itemId))
        .then(
          action(() => {
            const item = this._items.find((_charity) => _charity.data._id === itemId);
            item.data.resource = null;
          }),
        )
        .catch((err) => {
          this._error = err;
          this.rootStore.toastrStore.error(RESOURCE_DELETE_ERROR, null, err);
        })
        .finally(this.resetFlags)
    );
  };

  /**
   * Toggle items/form open/close status
   * @param charitiesIds
   */
  toggleOpened = (charitiesIds) => {
    charitiesIds.forEach((id) => {
      const ch = this.charities.find((charity) => charity.data._id === id);
      if (ch) {
        ch.formState.isOpened = !ch.formState.isOpened;
      }
    });
  };

  async refreshAndKeepItemOpen(itemId) {
    const openedCharitiesIds = this.charities
      .filter((charity) => charity.formState.isOpened && charity.data._id !== itemId)
      .map((ch) => ch.data._id);

    this.projectStore.refresh().then(() => this.toggleOpened([...openedCharitiesIds, itemId]));
  }

  resetFlags = () => {
    this._loading = false;
  };
}
