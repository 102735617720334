import { action, observable, computed, makeObservable, runInAction } from 'mobx';
// import { toJS } from 'mobx';

import AgentPoll from '../../common/agent';
import { QuestionPositionType } from '../../containers/project/polls/pollEdit/questions/segmentation/QuestionEnums';
import { ApiErrorEnums } from '../../shared/engage';

const Agent = AgentPoll.SegmentationAgent;

const ITEM_LABEL = 'question';
const LOAD_ERROR = `Error loading ${ITEM_LABEL}s`;
const CREATE_SUCCESS = `New ${ITEM_LABEL} added`;
const CREATE_ERROR = `Error creating ${ITEM_LABEL}`;
// const ACTIVATION_ERROR = `Failed to activate`;
// const QUESTION_SELECTION_SUCCESS = `Question selected`;
// const QUESTION_SELECTION_ERROR = `Error selecting question`;
const UPDATE_QUESTION_SUCCESS = `Saved ${ITEM_LABEL}`;
const UPDATE_QUESTION_ERROR = `Error saving ${ITEM_LABEL}`;
const DELETE_QUESTION_SUCCESS = 'Question removed';
const DELETE_QUESTION_ERROR = `Error deleting ${ITEM_LABEL}`;
const RESOURCE_DELETE_ERROR = 'Error deleting resource';

export default class SegmentationStore {
  _loading = false;

  _initialized = false;

  _error = null;

  _questions = [];

  _segmentation = {};

  _projectId = null;

  constructor(rootStore) {
    makeObservable(this, {
      _questions: observable,
      _loading: observable,
      _initialized: observable,
      _error: observable,
      _segmentation: observable,

      isLoading: computed,
      isInitialized: computed,
      error: computed,
      questions: computed,
      activeQuestion: computed,

      toggleOpened: action,
      resetFlags: action,
    });

    this.rootStore = rootStore;
  }

  get isLoading() {
    return this._loading;
  }

  get isInitialized() {
    return this._initialized;
  }

  get error() {
    return this._error;
  }

  get questions() {
    return this._questions;
  }

  get activeQuestion() {
    return this._questions.find((q) => q.isActive);
  }

  /**
   * Loads all Segmentation & questions for a given project
   */
  async load(projectId) {
    this._loading = false;
    this._error = null;

    try {
      const questionsRes = await Agent.allQuestions(projectId);
      const segmentationRes = await Agent.getSegmentation(projectId);

      runInAction(() => {
        this._questions = questionsRes.data;
        this._segmentation = segmentationRes.data;
        this._projectId = projectId;
      });

      return questionsRes.data;
    } catch (err) {
      this._error = err;
      this.rootStore.toastrStore.error(LOAD_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  // ###
  // QUESTION
  // ###
  uploadImage = (question, file, progress) =>
    Agent.uploadQuestionImage(this._projectId, question._id, file, progress).then(
      action((res) => {
        const q = this.questions.find((_question) => _question._id === question._id);
        q.resource = res.data;
      }),
    );

  removeImage = (question, imageId) =>
    Agent.removeQuestionImage(this._projectId, question._id, imageId)
      .then(
        action(() => {
          const q = this.questions.find((_question) => _question._id === question._id);
          q.resource = q.resource.filter((img) => img._id !== imageId);
        }),
      )
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(RESOURCE_DELETE_ERROR, null, err);
      })
      .finally(this.resetFlags);

  createQuestion = (questionType) => {
    // console.log('CREATE question', questionType);
    this._loading = true;
    this._error = null;

    // Ugly fix for mismatch between server and client constants for
    // question types. On server Sivi reuses question types because those are on
    // separate endpoints from poll questions
    // Here on client I have them in the same collection and handling by the same code
    // So I have QQ_TWO_IMAGE_SINGLE_CHOICE and TWO_IMAGE_SINGLE_CHOICE
    // and server expects TWO_IMAGE_SINGLE_CHOICE for both
    // ### So I strip down leading QQ_
    // Bad karma
    const qType = questionType.substring(3);

    return Agent.createQuestion(this._projectId, { questionType: qType, positionInPoll: QuestionPositionType.LAST })
      .then((res) => {
        // console.log('New member', res.data._id);
        this.rootStore.toastrStore.success(CREATE_SUCCESS);
        this.refreshAndKeepOpen(res.data._id);
      })
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(CREATE_ERROR, null, err);
      })
      .finally(this.resetFlags);
  };

  removeQuestion = (questionId) => {
    // console.log('Remove question', toJS(questionId));
    this._loading = true;
    this._error = null;
    return Agent.removeQuestion(this._projectId, questionId)
      .then(() => {
        this.rootStore.toastrStore.success(DELETE_QUESTION_SUCCESS);
        this.refreshAndKeepOpen();
      })
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(DELETE_QUESTION_ERROR, null, err);
      })
      .finally(this.resetFlags);
  };

  updateQuestion = (question) => {
    // console.log('UPDATE STORE', data);
    this._loading = true;
    this._error = null;
    return Agent.updateQuestion(this._projectId, question._id, question)
      .then(() => {
        // console.log('New member', res.data._id);
        this.rootStore.toastrStore.success(UPDATE_QUESTION_SUCCESS);
        this.refreshAndKeepOpen(question._id);
      })
      .catch((err) => {
        const errorInfo =
          err && err.response && err.response.data && err.response.data.error ? err.response.data.error : '';
        if (errorInfo === ApiErrorEnums().Err422KeyMmap.COOLING_PERIOD_NOT_EXPIRED) {
          this._error = errorInfo;
          this.rootStore.toastrStore.error(errorInfo, null, err);
        } else {
          this._error = err;
          this.rootStore.toastrStore.error(UPDATE_QUESTION_ERROR, null, err);
        }
      })
      .finally(this.resetFlags);
  };

  // ###
  // CHOICE
  // ###
  uploadChoiceImage = (question, choiceId, file, progress) =>
    Agent.uploadChoiceImage(this._projectId, question._id, choiceId, file, progress).then(() =>
      this.refreshAndKeepOpen(question._id),
    );

  removeChoiceImage = (question, choiceId) =>
    Agent.removeChoiceImage(this._projectId, question._id, choiceId)
      .then(() => this.refreshAndKeepOpen(question._id))
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(RESOURCE_DELETE_ERROR, null, err);
      })
      .finally(this.resetFlags);

  resetFlags = () => {
    this._loading = false;
  };

  toggleOpened = (questionIds) => {
    questionIds.forEach((id) => {
      const q = this.questions.find((question) => question._id === id);
      if (q) {
        q.isOpened = !q.isOpened;
      }
    });
  };

  async refreshAndKeepOpen(itemId) {
    const openedQuestionsIds = this.questions
      .filter((question) => question.isOpened && question._id !== itemId)
      .map((q) => q._id);

    await this.load(this._projectId);
    this.toggleOpened([...openedQuestionsIds, itemId]);
  }
}
