import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import EngageTextInputField from '../../../../../../common/components/form-elements/textInputField/TextInputField';
import ImageChoice from '../../../../../../common/components/form-elements/imageChoice/ImageChoice';
import SwitchableButtonBar from '../../../../../../common/components/switchableButtonBar/SwitchableButtonBar';
import { IMAGE_FORMATS } from '../../../../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import SlimFileUpload from '../../../../../../common/components/form-elements/fileUpload/SlimFileUpload';
import EngageSelectInputField from '../../../../../../common/components/form-elements/selectInputField/SelectInputField';
import { QUESTION_POSITION_TYPES } from './QuestionEnums';
import { QUESTION_CHARACTER_LIMIT, IMAGE_CHARACTER_LIMIT } from '../../../../../../common/constants/QuestionConstants';
import { CheckboxInputField } from '../../../../../../common/components/form-elements';

function QQThreeImagesQuestion(props) {
  const {
    question,
    updateQuestion,
    removeQuestion,
    uploadChoiceImage,
    removeChoiceImage,
    uploadImage,
    removeImage,
    CHOICE_LABELS,
  } = props;

  const leadImage = question?.resource ? question.resource[0] : null;

  const formInitialValues = {
    questionText: question.questionText,
    positionInPoll: question.positionInPoll,
    choice1Label: question.choices[0] ? question.choices[0].choice : '',
    choice2Label: question.choices[1] ? question.choices[1].choice : '',
    choice3Label: question.choices[2] ? question.choices[2].choice : '',
    isActive: question.isActive,
  };

  const onSubmit = (values) => {
    const q = toJS(question);
    q.questionText = values.questionText;
    q.isActive = values.isActive;
    q.positionInPoll = values.positionInPoll;
    q.choices[0].choice = values.choice1Label;
    q.choices[1].choice = values.choice2Label;
    q.choices[2].choice = values.choice3Label;

    updateQuestion(q);
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={formInitialValues}
      render={({ values, form, handleSubmit, submitting, pristine }) => {
        return (
          <form className='c-fields l-form'>
            <EngageTextInputField
              id={`input-question-${question._id}`}
              placeholder='Enter question'
              label='Question'
              maxLength={QUESTION_CHARACTER_LIMIT}
              fieldDescription=''
              fieldName='questionText'
              isRequired={true}
              fieldClass='l-form__item'
            />

            <SlimFileUpload
              imageFormat={IMAGE_FORMATS.PANEL}
              imageId={leadImage?._id || null}
              imageUrl={leadImage?.url || null}
              id={`${question._id}`}
              handleUpload={uploadImage}
              label='Lead image'
              removeImage={removeImage}
            />

            {question.choices.map((choice, index) => (
              <ImageChoice
                imageFormat={IMAGE_FORMATS.SQUARE}
                key={choice._id}
                index={index}
                classModifier='l-form__item--s'
                placeholder='Choice name'
                maxLength={IMAGE_CHARACTER_LIMIT}
                choice={choice}
                fieldName={`choice${index + 1}Label`}
                label={CHOICE_LABELS[index]}
                handleUpload={uploadChoiceImage}
                removeImage={removeChoiceImage}
              />
            ))}

            <EngageSelectInputField
              choices={QUESTION_POSITION_TYPES}
              id={`input-segment-position-${question._id}`}
              fieldClass='l-form__item--m'
              fieldName='positionInPoll'
              label='Question position'
            />

            <CheckboxInputField
              id={`checkbox-segmentation-status-${question._id}`}
              type='checkbox'
              fieldName='isActive'
              fieldClass='l-form__item--m'
              label='Status'
              isToggle={true}
              checkedLabel='Active'
              uncheckedLabel='Inactive'
              hideLabel={false}
            />

            <SwitchableButtonBar updateAction={handleSubmit} removeAction={removeQuestion} isNew={null} />
          </form>
        );
      }}
    />
  );
}

QQThreeImagesQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  updateQuestion: PropTypes.func.isRequired,
  removeQuestion: PropTypes.func.isRequired,
  uploadChoiceImage: PropTypes.func.isRequired,
  removeChoiceImage: PropTypes.func.isRequired,
  uploadImage: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired,
  CHOICE_LABELS: PropTypes.array.isRequired,
};

export default observer(QQThreeImagesQuestion);
