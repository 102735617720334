import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import SimpleTextInputField from './SimpleTextInputField';

export default function EngageTextInputField(props) {
  const { fieldName, isRequired, validator, customType } = props;

  function validateRequired(value) {
    const val = value && value.trim();
    return !val && isRequired ? 'Required' : undefined;
  }

  return (
    <Field
      component={SimpleTextInputField}
      name={fieldName}
      validate={validator || validateRequired}
      {...props}
      customType={customType}
    />
  );
}

EngageTextInputField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  validator: PropTypes.func,
};
