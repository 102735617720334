// eslint-disable-next-line no-shadow
import NumberUtils from '../../../../common/NumberUtils';

export function getTooltips(showGA4) {
  const GATooltip = showGA4 ? 'GA4' : 'GA';
  const GALabel = 'Data from Google Analytics - UA (Universal Analytics)';
  const GA4Label = 'Data from Google Analytics - GA4';
  const abbrGA = { title: showGA4 ? GA4Label : GALabel, label: GATooltip };
  const abbrBD = { title: 'Data from Built-ID', label: 'BD' };
  return { abbrGA, abbrBD };
}

export function percNewUsers(_percNewUsers) {
  return [
    { label: 'New users', value: _percNewUsers },
    { label: 'Returning users', value: 100 - _percNewUsers },
  ];
}

export function devicesStats(devices) {
  if (!devices)
    return [
      { label: 'Mobile', value: '-' },
      { label: 'Tablet', value: '-' },
      { label: 'Desktop', value: '-' },
    ];

  let mobile = parseInt(devices?.mobile, 10) || 0;
  let tablet = parseInt(devices?.tablet, 10) || 0;
  let desktop = parseInt(devices?.desktop, 10) || 0;
  const total = mobile + tablet + desktop;

  // Convert to % if we do have any answers
  if (total > 0) {
    mobile = Math.round((mobile / total) * 100);
    tablet = Math.round((tablet / total) * 100);
    desktop = 100 - mobile - tablet;
  }

  return [
    { label: 'Mobile', value: mobile },
    { label: 'Tablet', value: tablet },
    { label: 'Desktop', value: desktop },
  ];
}

export function timeStats(time) {
  if (!time)
    return [
      { label: 'Surveys', value: '-' },
      { label: 'Project information', value: '-' },
      { label: 'News & updates', value: '-' },
    ];

  let polls = time.polls || 0;
  let information = time.projectInfo || 0;
  let news = time.newsUpdates || 0;
  const total = polls + information + news;

  // Convert to % if we do have any answers
  if (total > 0) {
    polls = Math.round((polls / total) * 100);
    information = Math.round((information / total) * 100);
    news = 100 - polls - information;
  }

  return [
    { label: 'Surveys', value: polls },
    { label: 'Project information', value: information },
    { label: 'News & updates', value: news },
  ];
}

/**
 * Convert seconds in min:sec format
 */
export function secsToMinSecs(secs) {
  if (!secs || secs === 0) {
    return '0:00';
  }
  // return Math.floor(secs/60) + ':' + Math.round(secs%60)
  const measuredTime = new Date(null);
  measuredTime.setSeconds(secs); // specify value of SECONDS
  return measuredTime.toISOString().substr(14, 5);
}

export function charityPerc(points, totalPoints, allItems, isLastItem) {
  if (!totalPoints) {
    return '-';
  }
  if (!points) {
    return '0%';
  }

  if (!isLastItem) return `${NumberUtils.formatIntWithSeparator((points / totalPoints) * 100)}%`;

  // last item = 100% - the sum of previous items (due to floating-point and round-off errors)
  const percentagesSum = allItems.reduce((sum, item, index) => {
    if (index === allItems.length - 1) return sum;

    return sum + parseInt(NumberUtils.formatIntWithSeparator((item.points / totalPoints) * 100), 10);
  }, 0);

  return `${100 - percentagesSum}%`;
}
