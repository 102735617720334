import React, { useState, useEffect, Fragment } from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import PropTypes from 'prop-types';
import { arrayMoveImmutable } from 'array-move';
import EngageSelectInputField from '../selectInputField/SelectInputField';
import { LEAD_IMAGE_TYPES } from '../../../../shared/engage/engage-question-constants';
import CarouselImage from './CarouselImage';
import SlimUploader from '../fileUpload/SlimUploader';
import { IMAGE_FORMATS } from '../fileUpload/ImageFormatEnums';
import SvgIcon from '../../SvgIcon';
import displayConfirmationPrompt from '../../../PromptUtil';
import MediaUploader from '../mediaUploader/MediaUploader';
import MediaItem from '../../mediaItem/MediaItem';
import Tooltip from '../../tooltip/Tooltip';

const CHOICES = [
  [LEAD_IMAGE_TYPES.STANDARD_SINGLE_IMAGE, 'Single image or video'],
  [LEAD_IMAGE_TYPES.ZOOM_SINGLE_IMAGE, 'Single image with Pan & Zoom'],
  [LEAD_IMAGE_TYPES.STANDARD_CAROUSEL, 'Carousel of up to 5 images or videos'],
];

function CarouselContainer(props) {
  const { choices, allowDrag, selectedMediaItem, onClick } = props;

  return (
    <Fragment>
      {choices &&
        choices.map((image, index) => (
          <CarouselImage
            key={`${image.url}-${index}`}
            image={image}
            disabled={!allowDrag}
            onClick={onClick}
            selectedMediaItem={selectedMediaItem}
            index={index}
          />
        ))}
    </Fragment>
  );
}

CarouselContainer.propTypes = {
  choices: PropTypes.array.isRequired,
  allowDrag: PropTypes.bool.isRequired,
};

const SortList = SortableContainer((props) => {
  const { choices, selectedMediaItem, questionId, addImageHandler } = props;
  return (
    <ul className='c-multiple-image-upload-navigation__container' aria-label='Choose your image' role='tablist'>
      <CarouselContainer {...props} />
      {!selectedMediaItem && (
        <li
          className='c-multiple-image-upload-navigation__item c-multiple-image-upload-navigation__item--reorder'
          key={`${questionId}-new-list-item`}
        >
          <button role='tab' aria-selected='true' aria-controls='multiple-image-upload--panel--02' type='button'>
            <label />
          </button>
        </li>
      )}
      {choices?.length < 5 && (
        <li
          className='c-multiple-image-upload-navigation__item c-multiple-image-upload-navigation__item--add-image'
          key={`${questionId}-add-list-item`}
        >
          <button type='button' tabIndex='0' onClick={addImageHandler}>
            <SvgIcon icon='plus' />
            <div className='o-label u-hide-visually'>Add another image</div>
          </button>
        </li>
      )}
    </ul>
  );
});

function LeadImage(props) {
  const {
    questionId,
    leadImageType,
    resource,
    defaultResource,
    uploadImage,
    uploadVideo,
    removeMedia,
    label,
    store,
    fieldName,
    ToastrStore,
    classModifier,
    tooltipInLabel,
  } = props;

  const [allowDrag, setAllowDrag] = useState(true);
  const [selectedMediaItem, setSelectedMediaItem] = useState();
  const [carouselItemDragging, setCarouselItemDragging] = useState(false);

  const defaultLeadImage = defaultResource?.length > 0 ? defaultResource[0] : null;

  useEffect(() => {
    if (!resource) return;

    if (resource.length > 0) {
      setSelectedMediaItem(resource[0]);
    } else {
      setSelectedMediaItem(null);
    }
  }, [resource, leadImageType]);

  function removeCarouselImages(images, removeAll) {
    removeMedia(null, images[images.length - 1]._id).then((res) => {
      if (res?.length > 1 || (res?.length === 1 && removeAll)) removeCarouselImages(res, removeAll);
    });
  }

  function leadImageTypeChangeHandler(type) {
    let changeApproved = true;

    if (type !== LEAD_IMAGE_TYPES.STANDARD_CAROUSEL && resource?.length > 0) {
      // In case user switches from carousel to single image we need to display
      // warning prompt if multiple images were attached before deleting them and leaving only first one.
      // If first resource is video we should delete it too.

      // eslint-disable-next-line no-lonely-if
      let message;
      let removeAll = false;

      if (type === LEAD_IMAGE_TYPES.ZOOM_SINGLE_IMAGE) {
        message = 'This action will delete all lead images and videos!';
        removeAll = true;
      } else if (type === LEAD_IMAGE_TYPES.STANDARD_SINGLE_IMAGE && resource?.length > 1) {
        message = 'This action will delete all carousel images and videos except first one!';
      }

      if (message) {
        const res = displayConfirmationPrompt(message, null, false, ToastrStore);

        if (res === 1) {
          removeCarouselImages(resource, removeAll);
        } else {
          changeApproved = false;
        }
      }
    }

    return changeApproved;
  }

  function onSortEnd({ oldIndex, newIndex }) {
    setCarouselItemDragging(false);

    if (oldIndex === newIndex) return;

    const newOrder = arrayMoveImmutable(resource, oldIndex, newIndex);
    setAllowDrag(false);
    store.reorderLeadImages(questionId, newOrder, selectedMediaItem).then(() => {
      setAllowDrag(true);
    });
  }

  function addImageHandler() {
    if (selectedMediaItem === '') return;

    setSelectedMediaItem('');
  }

  function listClickHandler(image) {
    setSelectedMediaItem(image);
  }

  function postVideo(value) {
    return uploadVideo({ resource: value });
  }

  return (
    <div className={`c-field l-form__item c-lead-image ${classModifier}`}>
      <label className='c-field__label' htmlFor={`${questionId}-lead-image`}>
        {label}
        {tooltipInLabel && <Tooltip {...tooltipInLabel} />}
      </label>
      <div className='c-field__group'>
        <EngageSelectInputField
          choices={CHOICES}
          fieldName={fieldName}
          questionId={`input-lead-image-type-${questionId}`}
          label='Choose between different types for your lead image area'
          fieldClass='l-form__item'
          firstOptionEmpty={false}
          onSelectionChange={(newValue) => leadImageTypeChangeHandler(newValue)}
          requireChangeApproval={true}
        />
        {leadImageType === LEAD_IMAGE_TYPES.STANDARD_SINGLE_IMAGE &&
          (selectedMediaItem ? (
            <MediaItem
              type={selectedMediaItem.mediaType}
              mediaId={selectedMediaItem._id}
              mediaUrl={selectedMediaItem.url}
              removeMedia={removeMedia}
              imageClassModifier='l-form__item c-upload--fill'
              videoClassModifier='c-upload--fill'
              linkToVideo={true}
              showButton={false}
            />
          ) : (
            <MediaUploader
              id={`${questionId}-single-lead-image`}
              uploadImage={uploadImage}
              uploadVideo={postVideo}
              imageFormat={IMAGE_FORMATS.PANEL}
              defaultImageId={defaultLeadImage}
            />
          ))}
        {leadImageType === LEAD_IMAGE_TYPES.ZOOM_SINGLE_IMAGE && (
          <SlimUploader
            imageFormat={IMAGE_FORMATS.ZOOMABLE_LEAD_IMAGE}
            imageId={selectedMediaItem?._id || null}
            imageUrl={selectedMediaItem?.url || null}
            defaultImageId={defaultLeadImage}
            id={`${questionId}-single-lead-image-uploader`}
            handleUpload={uploadImage}
            removeImage={removeMedia}
            isZoomableImage={true}
          />
        )}
        {leadImageType === LEAD_IMAGE_TYPES.STANDARD_CAROUSEL && (
          <div className='c-field l-form__item c-field--multiple-image-upload'>
            <label className='c-field__label' htmlFor={`${questionId}-lead-image--carousel`}>
              Add your images or videos, then drag and drop them to change their order
            </label>
            <div className='c-field__group'>
              <div className='c-multiple-image-upload'>
                <div className='c-multiple-image-upload-navigation'>
                  <SortList
                    choices={resource}
                    useDragHandle={true}
                    helperClass='c-field--reorder-helper'
                    onSortEnd={onSortEnd}
                    allowDrag={allowDrag}
                    selectedMediaItem={selectedMediaItem}
                    axis='xy'
                    onClick={listClickHandler}
                    addImageHandler={addImageHandler}
                    questionId={questionId}
                    isDragging={carouselItemDragging}
                  />
                </div>
                {selectedMediaItem ? (
                  <div className='c-multiple-image-upload-content'>
                    <MediaItem
                      type={selectedMediaItem.mediaType}
                      mediaId={selectedMediaItem._id}
                      mediaUrl={selectedMediaItem.url}
                      removeMedia={removeMedia}
                      imageClassModifier='l-form__item c-upload--fill'
                      videoClassModifier='c-upload--fill'
                      linkToVideo={true}
                      showButton={false}
                    />
                  </div>
                ) : (
                  <MediaUploader
                    id={`${questionId}-carousel-lead-image`}
                    uploadImage={uploadImage}
                    uploadVideo={postVideo}
                    imageFormat={IMAGE_FORMATS.PANEL}
                    defaultImageId={defaultLeadImage}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

LeadImage.propTypes = {
  questionId: PropTypes.string.isRequired,
  leadImageType: PropTypes.string,
  resource: PropTypes.array,
  defaultResource: PropTypes.array,
  uploadImage: PropTypes.func.isRequired,
  uploadVideo: PropTypes.func.isRequired,
  removeMedia: PropTypes.func.isRequired,
  label: PropTypes.string,
  store: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  classModifier: PropTypes.string,
};

export default LeadImage;
