export const RECENT = 'recent';
export const MANAGED = 'managed';
export const SAAS = 'saas';
export const ALL = 'all';
export const SURVEYS_VISIBLE = 'surveys_visible';
export const ACTIVE = 'active';

export const OVERVIEW_FILTER_OPTIONS = [
  { label: 'Recent', value: RECENT },
  { label: 'Managed', value: MANAGED },
  { label: 'SAAS', value: SAAS },
  { label: 'All', value: ALL },
];

export const PROJECT_FILTER_OPTIONS = [
  { label: 'Active', value: ACTIVE },
  { label: 'Surveys visible', value: SURVEYS_VISIBLE },
  { label: 'All', value: ALL },
];

export function getFilteredClients(clients, selectedFilterOption, searchValue) {
  const filteredBySearch = clients.filter((client) =>
    client.profileName.toLowerCase().includes(searchValue.toLowerCase()),
  );

  switch (selectedFilterOption) {
    case MANAGED:
      return filteredBySearch.filter((client) => !client.isSaas);
    case SAAS:
      return filteredBySearch.filter((client) => client.isSaas);
    default:
      return filteredBySearch;
  }
}

export function getFilteredProjects(projects, searchValue, selectedFilterOption) {
  const filteredBySearch = projects.filter((project) =>
    project.projectName.toLowerCase().includes(searchValue.toLowerCase()),
  );

  switch (selectedFilterOption) {
    case ACTIVE:
      return filteredBySearch.filter((project) => project.published);
    case SURVEYS_VISIBLE:
      return filteredBySearch.filter((project) => project.activeSurveysCount > 0);
    default:
      return filteredBySearch;
  }
}
