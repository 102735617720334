import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import SimpleRangeInputField from './SimpleRangeInputField';

export default function EngageRangeInputField(props) {
  const { fieldName } = props;

  return <Field component={SimpleRangeInputField} {...props} name={fieldName} type='range' />;
}

EngageRangeInputField.propTypes = {
  fieldName: PropTypes.string,
};
