import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { PROJECT_PERMISSION_TYPES, ONLY_VIEW, VIEW_AND_EDIT } from '../../../common/constants/ProjectPermissionsEnum';
import SvgIcon from '../../../common/components/SvgIcon';

export default function ProjectItem(props) {
  const { project } = props;
  const { _id, projectName, isAssigned, projectsViewOnly } = project;

  const [projectSelected, setProjectSelected] = useState(false);
  const [projectPermissioValue, setProjectPermissioValue] = useState();

  useEffect(() => {
    // console.log('ProjectItem useEffect ', _id, projectsViewOnly);
    projectsViewOnly ? setProjectPermissioValue(ONLY_VIEW) : setProjectPermissioValue(VIEW_AND_EDIT);
    isAssigned ? setProjectSelected(true) : setProjectSelected(false);
  }, [projectsViewOnly, isAssigned, _id]);

  function projectSelectionChangeHandler(event) {
    setProjectSelected(event.currentTarget.checked);
    project.isAssigned = event.currentTarget.checked;
  }

  function projectPermissionChangeHandler(event) {
    setProjectPermissioValue(event.currentTarget.value);
    project.projectsViewOnly = event.currentTarget.value === ONLY_VIEW;
  }

  return (
    <li className={`c-list-settings__item ${projectSelected ? 'c-list-settings__item--highlight' : ''}`}>
      <div className='o-checkbox c-list-settings__name'>
        <input
          id={_id}
          checked={projectSelected}
          name='projectsAccessRadioGroup'
          type='checkbox'
          className='custom-control-input'
          onChange={projectSelectionChangeHandler}
        />
        <label htmlFor={_id} className='custom-control-label'>
          {projectName}
          <SvgIcon icon='check' />
        </label>
      </div>
      <div className='c-list-settings__options'>
        {PROJECT_PERMISSION_TYPES.map((permission, index) => {
          return (
            <div className='o-radio o-radio--inline o-radio--s' key={permission[0]}>
              <input
                id={`${_id}-${permission[0]}`}
                value={permission[0]}
                checked={projectSelected && projectPermissioValue === permission[0]}
                name={_id}
                type='radio'
                disabled={!projectSelected}
                onChange={projectPermissionChangeHandler}
              />
              <label htmlFor={`${_id}-${permission[0]}`}>{permission[1]}</label>
            </div>
          );
        })}
      </div>
    </li>
  );
}

ProjectItem.propTypes = {
  project: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    projectName: PropTypes.string.isRequired,
    isAssigned: PropTypes.bool.isRequired,
    projectsViewOnly: PropTypes.bool.isRequired,
  }).isRequired,
};
