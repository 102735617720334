import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import SvgIcon from '../../SvgIcon';

export function CheckboxInput(props) {
  const { classModifier, input, id, checkboxLabel, checkboxIcon, name, isToggle, disabled = false } = props;
  return (
    <div
      className={`o-checkbox ${classModifier} ${isToggle ? 'o-checkbox--toggle o-checkbox--inline' : ''} ${
        disabled ? 'is-disabled' : ''
      }`}
    >
      <input type='checkbox' {...input} name={name} id={id} disabled={disabled} />
      <label htmlFor={id}>
        <SvgIcon icon='check' />
        {checkboxIcon && <SvgIcon icon={checkboxIcon} />}
        {checkboxLabel}
      </label>
    </div>
  );
}

CheckboxInput.propTypes = {
  id: PropTypes.string.isRequired,
  classModifier: PropTypes.string,
  input: PropTypes.object,
  checkboxLabel: PropTypes.string,
  checkboxIcon: PropTypes.string,
  name: PropTypes.string,
  isToggle: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default function EngageCheckboxGroupInputField(props) {
  const { choices, label, fieldClass, fieldName, isToggle, disabled } = props;
  return (
    <div className={`c-field l-form__item ${fieldClass}`}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className='c-field__label'>{label}</label>
      <div className='c-field__group'>
        {choices.map((item) => (
          <Field
            key={item[0]}
            type='checkbox'
            name={`${fieldName}-${item[0]}`}
            id={`${fieldName}-${item[0]}`}
            component={CheckboxInput}
            classModifier=''
            checkboxLabel={item[1]}
            isToggle={isToggle}
            {...props}
          />
        ))}
      </div>
    </div>
  );
}

EngageCheckboxGroupInputField.propTypes = {
  choices: PropTypes.array.isRequired,
  label: PropTypes.string,
  fieldClass: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  isToggle: PropTypes.bool,
  disabled: PropTypes.bool,
};
