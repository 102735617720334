import React from 'react';
import PropTypes from 'prop-types';

const google = window.google;

function MapOverlayInfo(props) {
  const {
    mapDescription = '',
    drawnShape,
    drawnAreaRadius,
    drawnAreaUnit,
    drawnAreaWidth,
    drawnAreaHeight,
    clearMapText = '',
    clearShape,
  } = props;
  return (
    <p className='c-field__description' id='map-notification'>
      {mapDescription}
      <br />
      {drawnShape && drawnShape.type === google.maps.drawing.OverlayType.CIRCLE ? (
        <span>
          Radius: <strong>{drawnAreaRadius}</strong> {drawnAreaUnit} &mdash;{' '}
        </span>
      ) : (
        <span />
      )}
      {drawnShape && drawnShape.type === google.maps.drawing.OverlayType.RECTANGLE ? (
        <span>
          Rectangle size: <strong>{drawnAreaWidth}</strong> {drawnAreaUnit}
          &times; <strong>{drawnAreaHeight}</strong> {drawnAreaUnit} &mdash;{' '}
        </span>
      ) : (
        <span />
      )}
      {drawnShape && drawnShape.type === google.maps.drawing.OverlayType.POLYGON ? (
        <span>
          Polygon size: <strong>{drawnAreaWidth}</strong> {drawnAreaUnit}
          &times; <strong>{drawnAreaHeight}</strong> {drawnAreaUnit} &mdash;{' '}
        </span>
      ) : (
        <span />
      )}
      {drawnShape ? (
        <span>
          <button className='o-button o-button--link' type='button' onClick={clearShape}>
            {clearMapText}
          </button>
        </span>
      ) : (
        <span />
      )}
    </p>
  );
}

MapOverlayInfo.propTypes = {
  mapDescription: PropTypes.string,
  drawnShape: PropTypes.object,
  drawnAreaRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  drawnAreaUnit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  drawnAreaWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  drawnAreaHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clearMapText: PropTypes.string,
  clearShape: PropTypes.func.isRequired,
};

export default MapOverlayInfo;
