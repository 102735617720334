import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import useComponentVisible from './UseComponentVisible';
import SvgIcon from '../components/SvgIcon';
import history from '../history';

import { AUTH, AUTH_LOGIN, HOME } from '../constants/RouteConstants';
import ImageUtils, { IMAGE_TYPE_LOGO } from '../ImageUtils';
import HelpMenu from './HelpMenu';

function Header(props) {
  const { AuthStore, HelpStore } = props;
  const me = AuthStore.me;
  const isContextualHelpActive = HelpStore.isContextualHelpActive;

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const isOpened = isComponentVisible ? 'is-opened' : '';

  function toggleOpen() {
    setIsComponentVisible(!isComponentVisible);
  }

  function logout(e) {
    e.preventDefault();
    AuthStore.logout();
    history.push(`/${AUTH}/${AUTH_LOGIN}`);
  }

  function contexutalHelpHandler() {
    HelpStore.isContextualHelpActive = !HelpStore.isContextualHelpActive;
  }

  return (
    <header className='c-header l-header' id='header' role='banner'>
      <div className='l-container'>
        <h1 className='c-header-logo'>
          <Link className='c-header-logo__link' to={`/${HOME}`} data-testid='header-appLogo'>
            Give My View
          </Link>
        </h1>

        {me._id && (
          <nav className='c-header-nav'>
            <HelpMenu isContextualHelpActive={isContextualHelpActive} onContexutalHelpChange={contexutalHelpHandler} />
            <div className={`c-header-nav__item c-header-nav__item--icon c-header-nav__item--parent ${isOpened}`}>
              <button className='c-header-nav__link' type='button'>
                {me.avatar ? (
                  <img
                    className='c-header-nav__avatar'
                    src={ImageUtils.imageUrl(me.avatar, IMAGE_TYPE_LOGO)}
                    alt=''
                    onClick={toggleOpen}
                  />
                ) : (
                  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                  <span className='c-header-nav__avatar' onClick={toggleOpen} />
                )}
                <span className='c-header-nav__label'>Profile</span>
                <SvgIcon icon='angle-down' />
              </button>

              <div ref={ref}>
                {isComponentVisible && (
                  <div className='c-header-dropdown'>
                    <h3 className='c-header-dropdown__title'>{me.name}</h3>
                    <ul className='c-header-dropdown__children'>
                      <li className='c-header-dropdown__item'>
                        <a className='c-header-dropdown__link' href='.' onClick={logout}>
                          Sign out
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </nav>
        )}
      </div>
    </header>
  );
}

Header.propTypes = {
  AuthStore: PropTypes.object.isRequired,
  HelpStore: PropTypes.object.isRequired,
};

export default inject((root) => ({ AuthStore: root.RootStore.authStore, HelpStore: root.RootStore.helpStore }))(
  observer(Header),
);
