import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import ActionBar from 'common/components/actionBar/ActionBar';
import { Can } from 'common/Can';
import { VIEW } from 'common/ability';
import { PANELS } from 'common/constants/RouteConstants';
import ProjectStatusToggle from './ProjectStatusToggle';
import UpdateSection from './updates/UpdateSection';
import SurveySection from './survey/SurveySection';
import PanelsSection from './panel/PanelsSection';

function Publishing(props) {
  const { Store, match } = props;

  const projectId = match.params.id;

  useEffect(() => {
    Store.getStatus(projectId);
  }, [Store, projectId]);

  return (
    <div className='l-main' data-testid='publishing-wrapper'>
      <ActionBar label='Publishing' />

      <ProjectStatusToggle />

      <UpdateSection />

      <SurveySection projectId={projectId} />

      <Can I={VIEW} a={PANELS}>
        <PanelsSection projectId={projectId} />
      </Can>
    </div>
  );
}

Publishing.propTypes = {
  Store: PropTypes.object.isRequired,
};

export default inject((root) => ({ Store: root.RootStore.projectControlStore }))(observer(Publishing));
