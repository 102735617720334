import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import SimpleTextInputWithAction from './SimpleTextInputWithAction';

export default function EngageTextInputWithAction(props) {
  const { fieldName, isRequired, validator } = props;

  function validateHandler(value) {
    const val = value && value.trim();

    if (!val && isRequired) return 'Required';

    if (val && validator) return validator(value);

    return undefined;
  }

  return <Field component={SimpleTextInputWithAction} name={fieldName} validate={validateHandler} {...props} />;
}

EngageTextInputWithAction.propTypes = {
  fieldName: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  validator: PropTypes.func,
};
