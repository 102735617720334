/**
 *  Embed TYPE -- popup or inline
 */
export const EMBED_POPUP = 'popup';
export const EMBED_INLINE = 'inline';

export const EMBED_TYPE_CHOICES = [
  {
    id: EMBED_POPUP,
    text: 'Popup',
    icon: 'clone',
    description: 'Give My View is displayed in a popup window which is activated by pressing on a button.',
  },
  {
    id: EMBED_INLINE,
    text: 'Inline',
    icon: 'window',
    description: 'Give My View is displayed directly in the page with a dynamic or a fixed height.',
  },
];

// Default option values
export const DEFAULT_POPUP_BUTTON_LABEL = 'Answer survey';
export const DEFAULT_POPUP_BUTTON_TEXT_COLOR = '#FFFFFF';
export const DEFAULT_POPUP_BUTTON_BACKGROUND_COLOR = '#070047';
export const DEFAULT_POPUP_BUTTON_RADIUS = '3';
export const DEFAULT_HEIGHT_VALUE = '800';
export const EXCLUDE_HEADER_AND_NAVIGATION_PARAM = 'embed=true';
export const DYNAMIC_HEIGHT = 'autoheight=true';
export const IFRAME_RESIZER_SCRIPT =
  '<script type="text/javascript" src="https://www.givemyview.com/js/iframeResizer.min.js"></script>';
export const IFRAME_RESIZER_LOG_SCRIPT = '<script>iFrameResize({log:true})</script>';

// Placeholders
export const IFRAME_RESIZER_SCRIPT_PLACEHOLDER = 'IFRAME_RESIZER_SCRIPT_PLACEHOLDER';
export const IFRAME_RESIZER_LOG_SCRIPT_PLACEHOLDER = 'IFRAME_RESIZER_LOG_SCRIPT_PLACEHOLDER';
export const IFRAME_SOURCE_PLACEHOLDER = 'IFRAME_SOURCE_PLACEHOLDER';
export const HIDE_HEADER_AND_NAVIGATION_PLACEHOLDER = 'HIDE_HEADER_AND_NAVIGATION_PLACEHOLDER';
export const DYNAMIC_HEIGHT_PLACEHOLDER = 'DYNAMIC_HEIGHT_PLACEHOLDER';
export const DYNAMIC_HEIGHT_VALUE_PLACEHOLDER = 'DYNAMIC_HEIGHT_VALUE_PLACEHOLDER';
export const POPUP_BUTTON_LABEL_PLACEHOLDER = 'POPUP_BUTTON_LABEL_PLACEHOLDER';
export const POPUP_BUTTON_TEXT_COLOR_PLACEHOLDER = 'POPUP_BUTTON_TEXT_COLOR_PLACEHOLDER';
export const POPUP_BUTTON_BACKGROUND_COLOR_PLACEHOLDER = 'POPUP_BUTTON_BACKGROUND_COLOR_PLACEHOLDER';
export const POPUP_BUTTON_RADIUS_PLACEHOLDER = 'POPUP_BUTTON_RADIUS_PLACEHOLDER';

// Embed code templates
export const POPUP_EMBED_CODE_TEMPLATE = `
    <script>

    // Clicking the button toggles the modal
    function toggleModal() {

        // Show or hide modal
        var modal = document.getElementById("gmv-modal");
        if (modal.style.display === "none") {
        modal.style.display = "block";
        } else {
        modal.style.display = "none";
        }

        // Show or hide header (so there are no z-index problems)
        var header = document.getElementById("header");
        if (header.style.display === "none") {
        header.style.display = "block";
        } else {
        header.style.display = "none";
        }

        // Make the page container not scrollable
        var body = document.getElementsByTagName("body");
        if (body.style.overflow === "auto") {
        body.style.overflow = "hidden";
        } else {
        body.style.overflow = "auto";
        }

    }

    </script>

    <style>
    body {
        overflow: auto;
    }
    .gmv-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .gmv-button {
        display: inline-block;
        padding: 1rem 2rem;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 1rem;
        line-height: 1.5;
        background: ${POPUP_BUTTON_BACKGROUND_COLOR_PLACEHOLDER};
        color: ${POPUP_BUTTON_TEXT_COLOR_PLACEHOLDER};
        border: none;
        border-radius: ${POPUP_BUTTON_RADIUS_PLACEHOLDER}px;
    }
    .gmv-button:hover,
    .gmv-button:focus {
        color: #070047;
        background: #fff;
    }
    .gmv-modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 3000;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .gmv-modal-content {
        width: 100%;
        height: 100%;
    }
    .gmv-modal-close {
        position: absolute;
        z-index: 2000;
        top: 1rem;
        left: 1rem;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 3rem;
        min-height: 3rem;
        padding: 0;
        margin: 0;
        font-size: 1.75rem;
        line-height: 1;
        border-radius: 50%;
        background: #fff;
        color: #070047;
        transition: all .2s ease-in;
    }
    .gmv-modal-close:hover,
    .gmv-modal-close:focus {
        color: #070047;
        background: #fff;
        box-shadow: 0 .0625rem .125rem 0 rgba(0,0,0,.1);
    }
    .gmv-modal-close-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='512' height='512' viewBox='0 0 512 512'%3E%3Cpath d='M446.627 110.627l-45.254-45.254-145.373 145.372-145.373-145.372-45.254 45.253 145.373 145.374-145.373 145.373 45.254 45.254 145.373-145.373 145.373 145.373 45.254-45.255-145.372-145.372z'%3E%3C/path%3E%3C/svg%3E%0A");
        text-indent: -9999px;
        background-size: 1.25rem 1.25rem;
        width: 1.25rem;
        height: 1.25rem;
        display: block;
    }
    .gmv-modal-close-label {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }
    .gmv-modal iframe {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        border: none;
        height: 100%;
    }
    </style>

    <div class="gmv-container">

    <button class="gmv-button" onclick="toggleModal()">${POPUP_BUTTON_LABEL_PLACEHOLDER}</button>

    <div id="gmv-modal" class="gmv-modal" style="display: none">
        <div clas="gmv-modal-content">
        <button onclick="toggleModal()" type="button" class="gmv-modal-close" href="#gmv-modal-button">
            <span class="gmv-modal-close-icon">×</span>
            <span class="gmv-modal-close-label">Close</span>
        </button>
        <iframe src="${IFRAME_SOURCE_PLACEHOLDER}" width="100%" height="800px"></iframe>
        </div>
    </div>

    </div>
`;

export const INLINE_EMBED_CODE_TEMPLATE = `
    ${IFRAME_RESIZER_SCRIPT_PLACEHOLDER}

    <div class="givemyview-wrapper" id="givemyview">
    <iframe src="${IFRAME_SOURCE_PLACEHOLDER}" width="100%" height="${DYNAMIC_HEIGHT_VALUE_PLACEHOLDER}px" style="border: none;"></iframe>
    </div>

    ${IFRAME_RESIZER_LOG_SCRIPT_PLACEHOLDER}
`;
