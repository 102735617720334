import enums from '../../../shared/engage/engage-flow-enums';

const ENUMS = enums().EngageEndOfPollFlowButtonType;

export const ADDON_BUTTON = {
  buttonTypeIdentifier: ENUMS.ADDON_BUTTON,
  endOfPollButtonActionUrl: '',
  endOfPollButtonSubTitle: '',
  endOfPollButtonTitle: '',
  openInNewWindow: true,
};
export const ADDON_SECOND_BUTTON = {
  buttonTypeIdentifier: ENUMS.ADDON_SECOND_BUTTON,
  endOfPollButtonActionUrl: '',
  endOfPollButtonSubTitle: '',
  endOfPollButtonTitle: '',
  openInNewWindow: true,
};
export const DEFAULT_BUTTON = {
  buttonTypeIdentifier: ENUMS.DEFAULT_BUTTON,
  endOfPollButtonActionUrl: '/',
  endOfPollButtonSubTitle: 'Find out more about the project',
  endOfPollButtonTitle: 'About the project',
  openInNewWindow: false,
};

export const INFO_DEFAULT_MESSAGE =
  'You can override the default action that is displayed at the end of each survey. The default action links to the project home page.';
export const INFO_ADDON_MESSAGE = 'This action will only be visible after the person has answered the last survey.';
