import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { perc } from '../polls-report/PollReportUtils';
import SvgIcon from '../../../../common/components/SvgIcon';
import DEMOGRAPHIC_ENUMS from '../../../../shared/engage/engage-demographics-enums';

const OTHER = DEMOGRAPHIC_ENUMS().DemographicsChoiceContentTypes.OTHER;
const SKIP = DEMOGRAPHIC_ENUMS().DemographicsChoiceContentTypes.SKIP;

function DemographicChoice(props) {
  const {
    compareActive = false,
    isExporting = false,
    buttonLabel = '',
    // onButtonClick,
    choice,
    questionAnsweredCount,
    questionOverallAnsweredCount,
  } = props;

  const [showFreeText, setShowFreeText] = useState(false);

  const isOtherChoice = choice ? choice.choiceContentType === OTHER : false;
  const isSkipChoice = choice ? choice.choiceContentType === SKIP : false;

  // console.log('CHOICE', choice.label, choice.count, choice.countOverall);
  function getIcon() {
    let icon = '';
    if (isOtherChoice) icon = 'pen-alt';
    if (isSkipChoice) icon = 'forward';
    return icon;
  }
  return (
    <li className={`c-result-choice ${compareActive ? 'c-result-choice--compare' : ''}`}>
      <div className='c-result-choice__content'>
        {(isOtherChoice || isSkipChoice) && (
          <span className='c-result-choice__icon'>
            <SvgIcon icon={getIcon()} />
          </span>
        )}
        <span className='c-result-choice__title'>{choice.label}</span>
        {!isExporting && isOtherChoice && (
          // <button className='o-button o-button--xs o-button--inline' onClick={onButtonClick}>
          <button className='o-button o-button--xs o-button--inline' onClick={() => setShowFreeText(!showFreeText)}>
            <span className='o-label'>{showFreeText ? 'Hide' : buttonLabel}</span>
          </button>
        )}
        {!compareActive && <span className='c-result-choice__votes'>{choice.count}</span>}
        <div className='c-result-choice__value'>
          <div className='c-result-choice__bar'>
            <div
              className='c-result-choice__bar-filled'
              style={{ width: `${perc(choice.count, questionAnsweredCount)}%` }}
            >
              {' '}
            </div>
          </div>
          <div className='c-result-choice__percent'>{`${perc(choice.count, questionAnsweredCount)}%`}</div>
        </div>
        {compareActive && (
          <div className='c-result-choice__compare' style={{ display: `flex` }}>
            <div className='c-result-choice__bar'>
              <div
                className='c-result-choice__bar-filled'
                style={{ width: `${perc(choice.countOverall, questionOverallAnsweredCount)}%` }}
              >
                {' '}
              </div>
            </div>
            <div className='c-result-choice__percent'>{`${perc(
              choice.countOverall,
              questionOverallAnsweredCount,
            )}%`}</div>
          </div>
        )}
        {isOtherChoice && showFreeText && (
          <pre style={{ width: '100%', overflow: 'hidden' }}>{(choice.freeText || []).join('\n')}</pre>
        )}
      </div>
    </li>
  );
}

DemographicChoice.propTypes = {
  isExporting: PropTypes.bool,
  buttonLabel: PropTypes.string,
  // onButtonClick: PropTypes.func,
  compareActive: PropTypes.bool,
  choice: PropTypes.shape({
    label: PropTypes.string,
    count: PropTypes.number,
    countOverall: PropTypes.number,
  }).isRequired,
  // isWinner: PropTypes.bool,
  // isOther: PropTypes.bool,
  questionAnsweredCount: PropTypes.number,
  questionOverallAnsweredCount: PropTypes.number,
};

export default DemographicChoice;
