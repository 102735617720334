import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import EngageTextInputField from '../../../../../common/components/form-elements/textInputField/TextInputField';
import SwitchableButtonBar from '../../../../../common/components/switchableButtonBar/SwitchableButtonBar';
import { QUESTION_CHARACTER_LIMIT } from '../../../../../common/constants/QuestionConstants';
import { getFormFieldClass, getFormFieldTranslationTooltip } from '../../../../../common/TranslationUtils';
import ConditionalLogic from '../../../../../common/components/conditionalLogic/ConditionalLogic';
import ConditionalLogicUtil from '../../../../../common/ConditionalLogicUtil';

function ThreeSliderQuestion(props) {
  const { question, updateQuestion, openPollPreview, showConditionalLogic, Store } = props;

  const [formValues, setFormValues] = useState({});
  const [rules, setRules] = useState([]);

  useEffect(() => {
    if (!question) return;

    const initialFormQuestionValues = {
      questionText: question.questionText,
      maxValue: question.maxValue,
      label: question.label,
    };

    question.choices.forEach((choice, index) => {
      const choiceFieldName = ConditionalLogicUtil.QUESTION_CHOICE_FIELDS[index].fieldName;
      if (choice.choice) initialFormQuestionValues[choiceFieldName] = choice.choice;
    });

    const initialState = ConditionalLogicUtil.setupFormInitialValues(question, initialFormQuestionValues);
    setFormValues({ ...initialFormQuestionValues, ...initialState.initialFormConditionValues });
    setRules(initialState.initialRules);
  }, [question]);

  const onSubmit = (values) => {
    const updatedQuestionData = toJS(question);
    updatedQuestionData.questionText = values.questionText;
    updatedQuestionData.choices[0].choice = values.choice1Label || '';
    updatedQuestionData.choices[1].choice = values.choice2Label || '';
    updatedQuestionData.choices[2].choice = values.choice3Label || '';
    updatedQuestionData.maxValue = values.maxValue;
    updatedQuestionData.label = values.label || '';

    ConditionalLogicUtil.extractConditionalFormValues(question, updatedQuestionData, values, rules);

    updateQuestion(updatedQuestionData);
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={formValues}
      render={({ values, form, handleSubmit, submitting, pristine }) => {
        return (
          <form className='c-fields l-form'>
            <EngageTextInputField
              id={`input-question-${question._id}`}
              placeholder='Enter question'
              label='Question'
              maxLength={QUESTION_CHARACTER_LIMIT}
              fieldDescription=''
              fieldName='questionText'
              isRequired={true}
              fieldClass={getFormFieldClass(question.defaultData.questionText, null, 'l-form__item')}
              tooltipInLabel={getFormFieldTranslationTooltip(question.defaultData.questionText)}
            />

            <div className='c-toggle-content'>
              <div className='c-toggle-content__container'>
                <ConditionalLogic
                  formValues={values}
                  setFormValues={setFormValues}
                  question={question}
                  questions={Store.poll.questions}
                  rules={rules}
                  setRules={setRules}
                  show={showConditionalLogic}
                />
                <div className='c-toggle-content__content' hidden={showConditionalLogic}>
                  <div className='l-form'>
                    {question.choices.map((choice, index) => (
                      <EngageTextInputField
                        key={choice._id}
                        index={index}
                        id={`input-choice-${choice._id}`}
                        placeholder='Choice name'
                        s
                        label={props.CHOICE_LABELS[index]}
                        maxLength='25'
                        fieldDescription=''
                        fieldName={`choice${index + 1}Label`}
                        isRequired={false}
                        fieldClass={getFormFieldClass(choice.defaultData.choice, null, 'l-form__item--s')}
                        tooltipInLabel={getFormFieldTranslationTooltip(choice.defaultData.choice)}
                      />
                    ))}

                    <EngageTextInputField
                      id={`input-question-amount-${question._id}`}
                      placeholder=''
                      label='Number'
                      maxLength=''
                      fieldDescription='Maximum number you can define is 1000. Use label field to define greater numbers. For example “£10 million” or “£100K”.'
                      fieldName='maxValue'
                      isRequired={true}
                      type='number'
                      fieldClass='l-form__item--m'
                      validator={(value) => {
                        return !value || value > 1000 || value < 0
                          ? 'Please enter a value between 0 and 1000'
                          : undefined;
                      }}
                    />

                    <EngageTextInputField
                      id={`input-question-label-${question._id}`}
                      placeholder='E.g. £10k'
                      label='Label'
                      maxLength='20'
                      fieldDescription=''
                      fieldName='label'
                      isRequired={false}
                      fieldClass='l-form__item--m'
                    />
                  </div>
                </div>
              </div>
            </div>
            <SwitchableButtonBar
              updateAction={handleSubmit}
              removeAction={props.removeQuestion}
              isNew={null}
              previewAction={() => openPollPreview(question._id)}
            />
          </form>
        );
      }}
    />
  );
}

ThreeSliderQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  updateQuestion: PropTypes.func.isRequired,
  removeQuestion: PropTypes.func.isRequired,
  openPollPreview: PropTypes.func.isRequired,
  showConditionalLogic: PropTypes.bool.isRequired,
  Store: PropTypes.object.isRequired,
};

export default observer(ThreeSliderQuestion);
