import React from 'react';
import { inject } from 'mobx-react';
import { SortableElement } from 'react-sortable-hoc';

import QuestionSwitcherFn from './QuestionSwitcher';

// const QuestionSwitcher = inject(root => ({ Store: root.RootStore.pollEditStore }))(observer(QuestionSwitcherFn));
const QuestionSwitcher = inject((root) => ({
  Store: root.RootStore.pollEditStore,
  ProjectStore: root.RootStore.projectStore,
  HelpStore: root.RootStore.helpStore,
  ToastrStore: root.RootStore.toastrStore,
}))(QuestionSwitcherFn);

/**
 * Sortable item wrapper
 */
const SortableQuestion = SortableElement((props) => {
  return <QuestionSwitcher {...props} />;
});

export default SortableQuestion;
