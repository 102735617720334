import { reaction, action, observable, computed, makeObservable } from 'mobx';
// import { toJS } from 'mobx';

import Agent from '../common/agent';

const PanelsAgent = Agent.PanelsAgent;

const EngagePanelLocationType = require('../shared/engage/engage-panel-enums')().EngagePanelLocationType;
const EngagePanelType = require('../shared/engage/engage-panel-enums')().EngagePanelType;

const ITEM_LABEL = 'panel';
const CREATE_SUCCESS = `New ${ITEM_LABEL} added`;
const CREATE_ERROR = `Error creating ${ITEM_LABEL}`;
const UPDATE_SUCCESS = 'Saved';
const UPDATE_ERROR = `Error saving ${ITEM_LABEL}`;
const DELETE_SUCCESS = 'Panel removed';
const DELETE_ERROR = `Error deleting ${ITEM_LABEL}`;
const RESOURCE_DELETE_ERROR = 'Error deleting resource';

export default class PanelsStore {
  _loading = false;

  _initialized = false;

  _error = null;

  _panels = [];

  constructor(rootStore, projectStore) {
    makeObservable(this, {
      _panels: observable,
      _loading: observable,
      _initialized: observable,
      _error: observable,

      // resetFlags: action,
      //
      panels: computed,
      isLoading: computed,
      isInitialized: computed,
      error: computed,

      loadData: action,
      toggleOpened: action,
      // removeMember: action,
    });

    this.rootStore = rootStore;
    this.projectStore = projectStore;
    reaction(
      () => this.projectStore.isInitialized,
      () => this.loadData(this.projectStore.project.panels || [], this.projectStore.projectDefault.panels),
    );
  }

  get isLoading() {
    return this._loading;
  }

  get isInitialized() {
    return this._initialized;
  }

  get error() {
    return this._error;
  }

  get panels() {
    return this._panels;
  }

  /**
   * Load and prepare data already loaded in ProjectStore
   * @param team
   */
  loadData(panels, panelsDefault) {
    const tmpPanels = [];
    panels.map((panel) => {
      const defaultPanel = panelsDefault.find((panelDef) => panelDef._id === panel._id);
      tmpPanels.push({
        data: panel,
        defaultData: defaultPanel,
        formState: { isOpened: false },
      });
      return panel;
    });
    this._panels = tmpPanels;
    this._initialized = true;
    // console.log(this._team);
  }

  /**
   * Create EMPTY panel POST /project/:id/panel
   * @param projectId
   * @param data
   * @returns {Promise<T>}
   */
  create = (projectId, _data) => {
    // console.log('CREATE STORE');
    this._loading = true;
    this._error = null;

    // defaults for creation
    const data = _data || {
      panelLocation: EngagePanelLocationType.POLLS,
      panelIdentifier: 'New surveys panel',
      panelType: EngagePanelType.BANNER,
    };

    return PanelsAgent.create(this.projectStore.project._id, data)
      .then((res) => {
        // console.log('New member', res.data._id);
        this.rootStore.toastrStore.success(CREATE_SUCCESS);
        this.refreshAndKeepItemOpen(res.data._id);
      })
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(CREATE_ERROR, null, err);
      })
      .finally(this.resetFlags);
  };

  // Panel update PATCH /project/:id/panel/:panelId
  updatePanel(panelId, data) {
    // console.log('UPDATE STORE', data);
    this._loading = true;
    this._error = null;
    return PanelsAgent.update(this.projectStore.project._id, panelId, data)
      .then(() => {
        // console.log('New member', res.data._id);
        this.rootStore.toastrStore.success(UPDATE_SUCCESS);
        this.refreshAndKeepItemOpen(panelId);
      })
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(UPDATE_ERROR, null, err);
      })
      .finally(this.resetFlags);
  }

  /**
   * Remove panel DELETE /project/:id/panel/:panelId
   * @param panelId
   */
  deletePanel = (panelId) => {
    // console.log('DELETE STORE');
    this._loading = true;
    this._error = null;
    return PanelsAgent.remove(this.projectStore.project._id, panelId)
      .then(() => {
        this.rootStore.toastrStore.success(DELETE_SUCCESS);
        return this.projectStore.refresh();
      })
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(DELETE_ERROR, null, err);
      })
      .finally(this.resetFlags);
  };

  /**
   * Upload resource image, progress
   * @param panelId
   * @param file
   * @param progress callback
   */
  uploadResource(panelId, file, progress) {
    this._loading = true;
    this._error = null;
    return PanelsAgent.uploadResource(this.projectStore.project._id, panelId, file, progress)
      .then(
        action((res) => {
          // this.refreshAndKeepItemOpen(teamId)
          // console.log('DATA', res);
          const panel = this._panels.find((item) => item.data._id === panelId);
          panel.data.resource = res.data;
        }),
      )
      .finally(() => (this.loading = false));
  }

  /**
   *
   * @param teamId
   * @returns {Promise<T>}
   */
  removeResource = (panelId) => {
    // console.log('REMOVE IMAGE STORE');
    this._loading = true;
    this._error = null;
    return PanelsAgent.removeResource(this.projectStore.project._id, panelId)
      .then(
        action(() => {
          const panel = this._panels.find((item) => item.data._id === panelId);
          panel.data.resource = null;
        }),
      )
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(RESOURCE_DELETE_ERROR, null, err);
      })
      .finally(this.resetFlags);
  };

  /**
   * Toggle panel/form open/close status
   * @param panelIds
   */
  toggleOpened = (panelIds) => {
    panelIds.forEach((id) => {
      const panel = this._panels.find((pan) => pan.data._id === id);
      if (panel) {
        panel.formState.isOpened = !panel.formState.isOpened;
      }
    });
  };

  async refreshAndKeepItemOpen(itemId) {
    const openedPanelIds = this._panels
      .filter((panel) => panel.formState.isOpened && panel.data._id !== itemId)
      .map((pan) => pan.data._id);

    this.projectStore.refresh().then(() => this.toggleOpened([...openedPanelIds, itemId]));
  }

  resetFlags = () => {
    this._loading = false;
  };
}
