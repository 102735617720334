(function(){
/**
  NOTE:
        These error messages are shared globally via chain
        /server/config/express.js
        /server/gmv/shared/errors/engage-error-handler.js
        /server/gmv/shared/errors/api-error-messages.js

 */
module.exports = function() {

  const Err400Status = {
      ERR_400: '400',
      ERR_401: '401',
      ERR_403: '403',
      ERR_422: '422',
  };
  const Err400KeyMmap = {
    CHARITY_ALREADY_CHOSEN: 'Charity already chosen',
    POLL_NOT_COMPLETED: 'Poll not completed',
    CHARITY_ALREADY_DONATED: 'Charity already donated',
    INVALID_LOCATION: 'Unauthorized. Unable to retrieve poll.',
    INVALID_USER: 'Unauthorized. Unable to perform the request.',
    TOKEN_EXPIRED: 'jwt expired', // this one is emitted from JWT package!
    IS_CONDITION_DESTINATION_QUESTION: 'Question referenced from conditional! Can\'t delete.',
  };

  const Err401KeyMmap = {
      NOT_AUTHENTICATED: 'Not authenticated',
      NUMBER_EXCEEDED_MSG: 'Number of failed attempts exceeded.',
      INVALID_CREDENTIALS_MSG: 'Invalid credentials.'
    };
  const Err403KeyMmap = {
      FORBIDDEN: 'FORBIDDEN',
      TOKEN_EXPIRED: 'Token expired',
      NO_SUBSCRIPTION: 'No subscription set'
    };

  const Err404KeyMmap = {
      NO_DATA: 'NO DATA',
    };

  const Err422KeyMmap = {
      BRANCH_MANAGER_MUST_CONSTRAIN_PROJECTS: 'Branch manager allows custom projects only!',
      CHOICES_MISMATCH: 'Choices must match',
      COOLING_PERIOD_NOT_EXPIRED: 'Cooling period not expired!',
      DATA_ALREADY_EXIST: 'Data already exist',
      EMAIL_ALREADY_EXISTS:  'Email already exists',
      NO_EMPTY_PROJECT_NAME: 'Project name cannot be empty',
      NO_EMPTY_SLUG: 'Slug cannot be empty',
      POLL_CONTAINS_RESPONSES: 'Survey contains responses! Cannot remove.',
      PROFILE_SERVICE_DOES_NOT_EXIST: 'Profile / service doesn\'t exist',
      PROFILE_NAME_AND_EMAIL_ALREADY_EXISTS: 'Profile name and email already exists',
      PROJECT_ALREADY_DRAFT:  'Project already draft',
      PROJECT_ALREADY_PUBLISHED:  'Project already published',
      QUESTION_TYPE_MISMATCH: 'Question type must match',
      SLUG_ALREADY_EXISTS:  'Slug already exists',
      VALIDATION_AREA_MISSING: 'Please define your geofence before activating',
      VALIDATION_ERROR: 'Validation error',
      WRONG_DATA: 'Wrong (missing) data',
      WRONG_REQUEST: 'Wrong request',
      SUBJECT_IS_REQUIRED: 'Feedback subject is required',

      // Administrative errors
      CLIENT_USER_ONE_PROFILE_ONLY: 'Single profile for client staff! \n Change user\'s status othewise',

      // demographic templates
      TEMPLATE_NAME_MISSING: 'Template name missing!',
      TEMPLATE_QUESTION_ALREADY_USED: 'Template question already used!',
      TEMPLATE_CHOICE_ALREADY_USED: 'Template choice already used!',
      TEMPLATE_TRANSLATION_LINK_MISSING: 'Template translation link missing!',

      // contextual help
      HELP_KEY_ALREADY_EXISTS: 'Help key already exists',
  };

  return {
    Err400Status: Err400Status,
    Err400KeyMmap: Err400KeyMmap,
    Err401KeyMmap: Err401KeyMmap,
    Err403KeyMmap: Err403KeyMmap,
    Err404KeyMmap: Err404KeyMmap,
    Err422KeyMmap: Err422KeyMmap,
    Err_400: Err_400,
    Err_403: Err_403,
    Err_404: Err_404,
    Err_422: Err_422,
  };
  /**
   *
   */
  function Err_400(message, errObject){
    let returnObj = {error: (message ? message : 'Not applicable request')};
    if(errObject){returnObj.error += ' ' + JSON.stringify(errObject); }
    return returnObj;
  }
  /**
   *
   */
  function Err_403(message, errObject){
    let returnObj = {error: (message ? message : 'Invalid credentials')};
    if(errObject){returnObj.error += ' ' + JSON.stringify(errObject); }
    return returnObj;
  }
  /**
   *
   */
  function Err_404(message, errObject){
    let returnObj = {error: (message ? message : 'Object not found')};
    if(errObject){returnObj.error += ' ' + JSON.stringify(errObject); }
    return returnObj;
  }
  /**
   *
   */
  function Err_422(message, errObject){
    let returnObj = {error: (message ? message : 'Data validation failed')};
    if(errObject){returnObj.error += ' ' + JSON.stringify(errObject); }
    return returnObj;
  }
}
})();
