import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import UpdateItem, { CUSTOMISE, DEMOGRAPHICS, POLL, PROJECT, SEGMENTATION } from './UpdateItem';

function UpdateSection(props) {
  const { Store } = props;
  const { modifiedDemographics, modifiedPolls, validation, publishStatus } = Store;

  const [hasSelectedUpdates, setHasSelectedUpdates] = useState(false);

  function getInfoMessage() {
    let infoMessage = (
      <Fragment>
        There are no updates available at the moment. {` `}
        <strong>If you make changes to surveys or information pages, updates will be visible here.</strong>
      </Fragment>
    );

    if (Store.hasUpdates)
      infoMessage = (
        <Fragment>
          You have made several changes to the project, preview the changes and publish to make them visible to
          everyone. You can select and publish multiple updates at once. {` `}
          <strong>
            If your survey is not yet visible to the community, publishing your updates here will just save your
            changes, it will not set anything live.
          </strong>
        </Fragment>
      );

    return infoMessage;
  }

  function doPublish(event) {
    event.preventDefault();

    Store.doPublish().then((result) => {
      if (result) setHasSelectedUpdates(false);
    });
  }

  function getPollValidation(id) {
    const val = validation.polls.find((item) => item.validation._id === id);

    return val;
  }

  function onSelectionChange() {
    const formState = Store.formState;
    setHasSelectedUpdates(
      formState.information ||
        formState.customize ||
        formState.segmentation ||
        formState.polls.length > 0 ||
        formState.demographics.length > 0,
    );
  }

  return (
    <form className='c-fields c-fields--modern l-form' data-testid='update-section-wrapper'>
      <h2 className='c-heading-section c-heading-section--large'>Project updates</h2>

      <div className='c-message is-note-subtle c-message--large'>
        <p className='c-message__description'>{getInfoMessage()}</p>
      </div>

      {Store.hasUpdates && (
        <Fragment>
          <div className='c-field c-field--modern l-form__item'>
            <div className='c-field__group'>
              {publishStatus.projectInformationHasUpdates && (
                <UpdateItem
                  validation={validation.info}
                  onSelectionChange={onSelectionChange}
                  label='Information'
                  type={PROJECT}
                  id={PROJECT}
                />
              )}
              {publishStatus.projectCustomizeHasUpdates && (
                <UpdateItem
                  validation={validation.customize}
                  onSelectionChange={onSelectionChange}
                  label='Customise'
                  type={CUSTOMISE}
                  id={CUSTOMISE}
                />
              )}
              {publishStatus.segmentationHasUpdates && (
                <UpdateItem
                  hasDetails={false}
                  onSelectionChange={onSelectionChange}
                  label='Segmentation'
                  type={SEGMENTATION}
                  id={SEGMENTATION}
                />
              )}
              {modifiedPolls.map((poll) => (
                <UpdateItem
                  key={poll._id}
                  validation={getPollValidation(poll._id)}
                  onSelectionChange={onSelectionChange}
                  label={`Survey: ${poll.name}`}
                  type={POLL}
                  id={`${POLL}-${poll._id}`}
                  value={poll._id}
                  tag={poll.isVisible ? 'Public' : 'Hidden'}
                />
              ))}
              {modifiedDemographics.map((demog) => (
                <UpdateItem
                  key={demog._id}
                  onSelectionChange={onSelectionChange}
                  label='Demographics'
                  type={DEMOGRAPHICS}
                  id={`${DEMOGRAPHICS}-${demog._id}`}
                  value={demog._id}
                  hasDetails={false}
                  tag={demog.isVisible ? 'Public' : 'Hidden'}
                />
              ))}
            </div>
          </div>

          <div className='c-message is-warning-strong c-message--large'>
            <p className='c-message__description'>
              <strong>Updates are irreversible</strong>, preview and double check all changes before publishing.
            </p>
          </div>

          <div className='c-field c-field--action l-form__item'>
            <div className='c-field__group'>
              <button
                className={`o-button o-button--primary ${hasSelectedUpdates ? '' : 'is-disabled'}`}
                type='submit'
                onClick={doPublish}
                disabled={!hasSelectedUpdates}
              >
                <span className='o-label'>Publish update</span>
              </button>
            </div>
          </div>
        </Fragment>
      )}
    </form>
  );
}

UpdateSection.propTypes = {
  Store: PropTypes.object.isRequired,
};

export default inject((root) => ({
  Store: root.RootStore.projectControlStore,
}))(observer(UpdateSection));
