import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { SortableContainer } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

import ActionBar from '../../../common/components/actionBar/ActionBar';
import InlineButton from '../../../common/components/inlineButton/InlineButton';
import InfluencedDecision from './InfluencedItem';

/**
 * Member list container
 * ### IMPORTANT this one is sort container!!
 */

function InfluencedContainerFn(props) {
  const { Store, allowDrag } = props;
  return (
    <div className='c-switchable-list c-switchable-list--polls'>
      {Store.influenced.map((item, index) => (
        <InfluencedDecision
          key={item.data._id}
          index={index}
          item={item}
          switchableModifier='c-switchable--reorder'
          disabled={!allowDrag}
        />
      ))}
    </div>
  );
}

const InfluencedContainer = inject((root) => ({ Store: root.RootStore.influencedStore }))(
  observer(InfluencedContainerFn),
);

/**
 * Team page container
 */
function InfluencedFn(props) {
  // console.log(props);
  const { Store } = props;

  const [allowDrag, setAllowDrag] = useState(true);

  const addNew = () => {
    Store.create();
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setAllowDrag(false);
    const newOrder = arrayMoveImmutable(props.Store.influenced, oldIndex, newIndex);
    Store.reorder(newOrder).then(() => {
      setAllowDrag(true);
    });
  };

  return (
    <div className='l-main'>
      <ActionBar addNew={addNew} label='Influenced decisions' hasButton={true} buttonLabel='Add' />
      <SortableList useDragHandle={true} onSortEnd={onSortEnd} allowDrag={allowDrag} />
      {Store.influenced.length > 8 && <InlineButton onClick={addNew} buttonLabel='Add' />}
    </div>
  );
}

const Influenced = inject((root) => ({ Store: root.RootStore.influencedStore }))(observer(InfluencedFn));

/**
 * Sortable collection wrapper
 */
const SortableList = SortableContainer((props) => {
  return <InfluencedContainer {...props} />;
});

export default Influenced;
