import React, { useState, useEffect, useContext, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
// eslint-disable-next-line import/no-cycle
import { PollReportContext } from '../PollsReport';
import QuestionFooter from '../../QuestionFooter';
import QuestionHeader from '../../QuestionHeader';
import SvgIcon from '../../../../../common/components/SvgIcon';
import agent from '../../../../../common/agent/ReportsAgent';
import { getSentiment } from '../PollReportUtils';
import PreviewUtils from '../../../../../common/PreviewUtils';
import NumberUtils from '../../../../../common/NumberUtils';
import QuestionChoice from './QuestionChoice';
import MediaItem from '../../../../../common/components/mediaItem/MediaItem';

function RatingPreview(props) {
  const { question, PollReportStore, ToastrStore, GeneralStore, fromDate, toDate, onOpenFeedbackModal } = props;
  const {
    questionId,
    countAnswered,
    countFeedback,
    sentiment,
    skipSeen,
    choices,
    questionConfiguration,
    resource,
    resourceMediaType,
  } = question;
  const { sentimentPositive, sentimentMixed, sentimentNegative, sentimentNeutral, uncategorisedPercent } = getSentiment(
    sentiment,
    countFeedback,
  );

  const choice = choices?.length ? choices[0] : {};
  const ratingConfiguration = questionConfiguration?.ratingConfiguration;

  const imageId = questionId || null;
  const imageUrl = resource || null;
  const imageType = resourceMediaType || null;

  const pollReportContext = useContext(PollReportContext);
  const containerReference = useRef();

  const [ratings, setRatings] = useState([]);
  const [compareActive, setCompareActive] = useState(false);

  useEffect(() => {
    if (pollReportContext)
      pollReportContext.addReference({ id: questionId, containerReference: containerReference.current });
  }, [pollReportContext, questionId]);

  useEffect(() => {
    if (!question) return;
    const amountBucketing = question.choices?.length ? question.choices[0].amountBucketing : [];
    const splicedData = amountBucketing.splice(0, question.multiChoicesOfferedBound + 1);
    const preparedData = [];

    let winnerCount = 0;
    splicedData.forEach((element, index) => {
      if (index === 0) return;

      if (element > winnerCount) winnerCount = element;
      preparedData.push({ label: index, count: element });
    });

    const mostSelectedRating = preparedData.find((pd) => pd.count === winnerCount);
    if (mostSelectedRating) mostSelectedRating.isWinner = true;

    setRatings(preparedData);
  }, [question]);

  function handlePreview() {
    PollReportStore.feedbackModalActivatorQuestion = question;
    PollReportStore.feedbackModalOpen = true;
    onOpenFeedbackModal();
  }

  function handleDownload() {
    PreviewUtils.excelDownload(
      agent,
      PollReportStore.projectId,
      PollReportStore.pollId,
      questionId,
      ToastrStore,
      fromDate,
      toDate,
      PollReportStore.segmentChoiceId,
      PollReportStore.demographicChoiceId,
    );
  }

  return (
    <div ref={containerReference} className='c-poll-preview__item'>
      <div className='c-question'>
        <QuestionHeader question={question} onCompareChange={() => setCompareActive(!compareActive)} />

        <div className='c-question__content'>
          <div className='c-question__lead-image'>
            <MediaItem
              type={imageType}
              mediaId={imageId}
              mediaUrl={imageUrl}
              imageClassModifier='l-form__item c-upload--fill'
              videoClassModifier='c-upload--fill'
              linkToVideo={true}
            />
          </div>
          <div className='c-result-group'>
            {ratingConfiguration?.useCustomLabels && (
              <div className='c-result-group__label c-result-group__label--top c-result-group__label--negative'>
                {ratingConfiguration?.resourceLabels?.lowest}
              </div>
            )}

            <ul className='c-result-group__items'>
              {ratings.map((ch, idx) => (
                <QuestionChoice
                  key={idx}
                  isExporting={GeneralStore.isSnapshotting}
                  buttonLabel='Download'
                  onButtonClick={handleDownload}
                  compareActive={compareActive}
                  choice={ch}
                  questionAnsweredCount={countAnswered}
                  hasMedia={false}
                  hasTitle={false}
                  isWinner={ch.isWinner}
                />
              ))}
            </ul>

            {ratingConfiguration?.useCustomLabels && (
              <div className='c-result-group__label c-result-group__label--bottom c-result-group__label--positive'>
                {ratingConfiguration?.resourceLabels?.highest}
              </div>
            )}

            <ul className='c-result-group__meta'>
              <li>
                Average rating is <strong>{NumberUtils.fixedDecimals(choice.avgAmount)}</strong>{' '}
              </li>
            </ul>

            {questionConfiguration.allowComments && (
              <div className='c-sentiment-stats'>
                <h3 className='c-sentiment-stats__label'>
                  {'Feedback sentiment '}
                  {!GeneralStore.isSnapshotting && (
                    <span className='o-tooltip o-tooltip--bottom-center'>
                      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                      <span className='o-tooltip__action' tabIndex='0'>
                        <SvgIcon icon='info-circle' />
                      </span>
                      <span className='o-tooltip__description' role='tooltip'>
                        Sentiment is calculated using machine learning and therefore 100% accuracy cannot be guaranteed
                      </span>
                    </span>
                  )}
                </h3>

                <ul className='c-sentiment-stats__items'>
                  <li>
                    <SvgIcon icon='grin' />
                    <strong>{` ${sentimentPositive}% `}</strong>
                    positive
                  </li>
                  <li>
                    <SvgIcon icon='meh' />
                    <strong>{` ${sentimentMixed}% `}</strong>
                    mixed
                  </li>
                  <li>
                    <SvgIcon icon='frown' />
                    <strong>{` ${sentimentNegative}% `}</strong>
                    negative
                  </li>
                  <li>
                    <SvgIcon icon='meh-blank' />
                    <strong>{` ${sentimentNeutral}% `}</strong>
                    neutral
                  </li>
                  {uncategorisedPercent > 0 && (
                    <li>
                      <SvgIcon icon='archive' />
                      <strong>{` ${uncategorisedPercent}% uncategorised `}</strong>
                    </li>
                  )}
                </ul>
              </div>
            )}

            {!GeneralStore.isSnapshotting && (
              <Fragment>
                {questionConfiguration.allowComments && (
                  <button className='o-button o-button--s' type='button' onClick={handlePreview}>
                    <span className='o-label'>Preview</span>
                  </button>
                )}
                <button className='o-button o-button--s o-button--primary' type='button' onClick={handleDownload}>
                  <span className='o-label'>Download spreadsheet</span>
                </button>
              </Fragment>
            )}
          </div>
        </div>

        <QuestionFooter answered={countAnswered} skipped={skipSeen.skipCount} viewed={skipSeen.seenCount} />
      </div>
    </div>
  );
}

RatingPreview.propTypes = {
  GeneralStore: PropTypes.object.isRequired,
  PollReportStore: PropTypes.object.isRequired,
  ToastrStore: PropTypes.object.isRequired,
  question: PropTypes.shape({
    questionId: PropTypes.string.isRequired,
    choices: PropTypes.array.isRequired,
    countAnswered: PropTypes.number,
    skipSeen: PropTypes.shape({
      seenCount: PropTypes.number,
      skipCount: PropTypes.number,
    }),
  }).isRequired,
  fromDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  toDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onOpenFeedbackModal: PropTypes.func,
};

export default inject((root) => ({
  GeneralStore: root.RootStore.generalStore,
  PollReportStore: root.RootStore.pollsReportStore,
  ToastrStore: root.RootStore.toastrStore,
}))(observer(RatingPreview));
