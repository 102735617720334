import React from 'react';
import PropTypes from 'prop-types';
import HelpActionButton from 'common/components/help/HelpActionButton';
import { REPORT_FORMAT_TYPE_CHOICES } from './ReportEnums';
import RadioInput from '../../../../common/components/form-elements/radioGroupInputField/RadioInput';

function GenerateFileTypeChoice(props) {
  const { value, onSelectionChange, helpKey } = props;
  return (
    <div className='c-field l-form__item'>
      <label className='c-field__label' htmlFor='input-report-type'>
        Report type
      </label>
      <div className='c-field__group'>
        <HelpActionButton helpKey={helpKey} />
        <div className='l-list l-list--halfs'>
          <RadioInput
            choices={REPORT_FORMAT_TYPE_CHOICES}
            classModifier='o-radio--card'
            input={{
              value,
              onChange: (event) => onSelectionChange(event.target.value),
            }}
          />
        </div>
      </div>
    </div>
  );
}

GenerateFileTypeChoice.propTypes = {
  value: PropTypes.string,
  onSelectionChange: PropTypes.func.isRequired,
  helpKey: PropTypes.string,
};

export default GenerateFileTypeChoice;
