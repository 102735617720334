import React, { useCallback, useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import SvgIcon from '../../SvgIcon';
import ImageUtils from '../../../ImageUtils';
import { isDefaultLanguage } from '../../../TranslationUtils';
import Slim from '../slimFileUpload/slim.react';

function SlimUploader(props) {
  const {
    imageFormat,
    id,
    tip,
    imageId,
    defaultImageId,
    isSharedResource = false,
    imageUrl,
    modifierClass,
    removeImage,
    handleUpload,
    enableLibrarySearch = true,
    ImageLibraryStore,
    isZoomableImage = false,
  } = props;
  const [slimComponent, setSlimComponent] = useState();
  const [currentScrollPosition, setCurrentScrollPosition] = useState(0);
  const [deleteEnabled, setDeleteEnabled] = useState(true);

  // This flag controls visibility of search button, changes on SlimUploader instance that's currently uploading image.
  // It works in paralel with 'ImageLibraryStore.showUploaderSearchButton' which controls visibility of search
  // button on ALL SlimUploader instances at the same time (is set to 'false' while image download in progress on ANY instance)
  const [showSearchButton, setShowSearchButton] = useState(true);

  const loadImageByUrl = useCallback(
    (url) => {
      slimComponent.load(url);
    },
    [slimComponent],
  );

  useEffect(() => {
    if (!ImageLibraryStore.modalOpen && currentScrollPosition > 0) window.scrollTo(0, currentScrollPosition);
  }, [ImageLibraryStore.modalOpen, currentScrollPosition]);

  // After image gets selected from image library start image download process
  useEffect(() => {
    if (
      ImageLibraryStore.currentlySelectedUrl &&
      ImageLibraryStore.modalActivatorId === id &&
      !ImageLibraryStore.modalOpen &&
      ImageLibraryStore.showUploaderSearchButton
    ) {
      ImageLibraryStore.showUploaderSearchButton = false;
      ImageLibraryStore.modalActivatorId = null;

      loadImageByUrl(ImageLibraryStore.currentlySelectedUrl);
    }
  }, [
    ImageLibraryStore,
    ImageLibraryStore.currentlySelectedUrl,
    ImageLibraryStore.modalActivatorId,
    ImageLibraryStore.modalOpen,
    ImageLibraryStore.showUploaderSearchButton,
    id,
    loadImageByUrl,
  ]);

  // When image is cropped and start image upload process
  // eslint-disable-next-line no-unused-vars
  function slimUpload(formdata, progress, success, failure, slim) {
    ImageLibraryStore.currentlySelectedUrl = null;
    ImageLibraryStore.modalActivatorId = null;
    setShowSearchButton(false);

    handleUpload(formdata[0], (progressEvent) => {
      progress(progressEvent.loaded, progressEvent.total);
    }).then(() => {
      ImageLibraryStore.showUploaderSearchButton = true;
      setShowSearchButton(true);
      success();
    });
  }

  /**
   * Need this transformation to add white background to transparent PNG images
   * No problem (not needed) on any non-transparent image
   * refs.
   * solution https://codecanyon.net/comments/17060275
   * API ref. https://pqina.nl/slim/ data-did-transform
   * ### IMPORTANT ###
   * between the answer and new version there is a difference that new version
   * does NOT receive ready callback, but instead data is returned directly
   */
  const didTransform = (data /* API change , ready */) => {
    // get the drawing context and size of the output image
    const ctx = data.output.image.getContext('2d');
    const width = data.output.width;
    const height = data.output.height;

    // draw behind current image
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = '#fff'; // use white background color
    ctx.fillRect(0, 0, width, height);

    // restore to default composite operation (is draw over current image)
    ctx.globalCompositeOperation = 'source-over';

    // continue saving the data
    return data;
    // API changed - original uses ready callback
    // ready(data);
  };

  // Add Slim reference to state so we can call use 'slim.load()' later
  const didInit = (data, slim) => {
    setSlimComponent(slim);
  };

  // Clear image from Slim after upload finished
  const didUpload = (error, data, response, slim) => {
    slim && slim.remove();
  };

  // eslint-disable-next-line no-unused-vars
  const didCancel = (error, data, response) => {
    ImageLibraryStore.currentlySelectedUrl = null;
  };

  const didLoad = () => {
    ImageLibraryStore.showUploaderSearchButton = true;
    return true;
  };

  const willLoad = () => {
    ImageLibraryStore.showUploaderSearchButton = false;
  };

  const didThrowError = () => {
    ImageLibraryStore.currentlySelectedUrl = null;
    ImageLibraryStore.showUploaderSearchButton = true;
  };

  const openImageLibrary = () => {
    setCurrentScrollPosition(window.pageYOffset);

    ImageLibraryStore.modalOpen = true;
    ImageLibraryStore.requiredImageFormat = imageFormat.size;
    ImageLibraryStore.modalActivatorId = id;
  };

  function getLabel() {
    let message = 'Click to upload or drop your image here';
    const sizeMessage = imageFormat.minSize
      ? `<br/>(min size ${imageFormat.minSize.width}x${imageFormat.minSize.height} px)`
      : '';
    message = message.concat(sizeMessage);

    // in default language show just the default message
    if (isDefaultLanguage()) return message;

    // If I'm on a different language and image can be internationalized (not all images can be multilanguage)
    // add warning about default image
    if (defaultImageId && !isSharedResource) {
      // Sax - bad inline styling - not changing css without designer though
      message = message.concat(`<br/>
      <strong style="font-size: x-small;">
        If you don't upload a different image version for this language, image uploaded to original language will be
        used instead.
      </strong>`);
    }

    return message;
  }

  async function removeImageHandler(ev) {
    setDeleteEnabled(false);
    await removeImage(ev, imageId);
    setDeleteEnabled(true);
  }

  return (
    <Fragment>
      {
        /* Case when image present */
        imageId && (
          <React.Fragment>
            <div
              className={`${
                isZoomableImage
                  ? 'c-upload-preview'
                  : `c-upload ${modifierClass || ''} ${imageFormat.previewModifier || ''} is-filled`
              }`}
            >
              <div className={`${isZoomableImage ? 'c-upload-preview__content' : 'c-upload__content'}`}>
                <img src={ImageUtils.imageUrl(imageUrl || imageId, imageFormat.imageType)} alt='' />

                <button
                  className={`${isZoomableImage ? 'c-upload-preview__remove' : 'c-upload__remove'}`}
                  type='button'
                  onClick={removeImageHandler}
                  disabled={!deleteEnabled}
                >
                  <SvgIcon icon='times' />
                  <span className='o-label'>Remove</span>
                </button>
              </div>
            </div>
            {tip && <p className='c-field__description'>{tip}</p>}
          </React.Fragment>
        )
      }

      {/* No image, choose system file or search image library */}
      {!imageId && (
        <Fragment>
          <div className={`o-slim ${imageFormat.slimModifier}`}>
            {enableLibrarySearch && ImageLibraryStore.showUploaderSearchButton && showSearchButton && (
              <button
                type='button'
                className='o-slim__search o-button o-button--naked o-button--s'
                onClick={openImageLibrary}
              >
                <SvgIcon icon='search' />
                <span className='o-label'>Search</span>
              </button>
            )}
            <Slim
              {...imageFormat}
              jpegCompression={isZoomableImage ? 50 : 100}
              forceType='jpg'
              service={slimUpload}
              serviceFormat='file'
              instantEdit={true}
              label={getLabel()}
              push={true}
              maxFileSize={15}
              didUpload={didUpload}
              didInit={didInit}
              popoverClassName={imageFormat.popupModifier}
              didTransform={didTransform}
              didCancel={didCancel}
              didLoad={didLoad}
              willLoad={willLoad}
              didThrowError={didThrowError}
            >
              <input type='file' name='foo' id={`input-image-${id}`} accept='image/jpeg, image/png, image/gif' />
            </Slim>
          </div>
          {tip && <p className='c-field__description'>{tip}</p>}
        </Fragment>
      )}
    </Fragment>
  );
}

SlimUploader.propTypes = {
  imageFormat: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  tip: PropTypes.string,
  imageId: PropTypes.string,
  defaultImageId: PropTypes.string,
  isSharedResource: PropTypes.bool,
  imageUrl: PropTypes.string,
  modifierClass: PropTypes.string,
  removeImage: PropTypes.func.isRequired,
  handleUpload: PropTypes.func.isRequired,
  enableLibrarySearch: PropTypes.bool,
  ImageLibraryStore: PropTypes.object.isRequired,
  isZoomableImage: PropTypes.bool,
};

export default inject((root) => ({ ImageLibraryStore: root.RootStore.imageLibraryStore }))(observer(SlimUploader));
