import { AbilityBuilder, Ability } from '@casl/ability';
import { RoleEnums, ServiceEnums } from '../shared/engage';
import {
  OVERVIEW,
  EDIT_PROJECT,
  REPORTS,
  PUBLISHING,
  GEOLOCATION,
  INFORMATION,
  TIMELINE,
  LANGUAGES,
  IMAGE_GALLERY,
  TEAM,
  INFLUENCED_DECISIONS,
  CHARITIES,
  PANELS,
  POLLS,
  SEGMENTATION,
  DEMOGRAPHICS,
  CUSTOMIZE,
  CONTENT,
  ACTIONS,
  BLOCKS,
  PERSONAL_DATA,
  FAQ,
  NEWS,
  REPORTS_DASHBOARD,
  REPORTS_POLLS,
  REPORTS_FEEDBACK,
  REPORTS_DEMOGRAPHICS,
  REPORTS_GENERATE,
  SETTINGS,
  EMBED,
  TOOLS,
  CONFIGURATION,
} from './constants/RouteConstants';

const EngagePanelLocationType = require('../shared/engage/engage-panel-enums')().EngagePanelLocationType;

// Actions
export const VIEW = 'view';
export const EDIT = 'edit';
export const SET = 'set';

// Action targets
export const HOMEPAGE_CONTENT = 'HOMEPAGE_CONTENT';
export const POLLS_CONTENT = 'POLLS_CONTENT';
export const FEEDBACK_QUESTION_CONTENT = 'FEEDBACK_QUESTION_CONTENT';
export const CHARITIES_CONTENT = 'CHARITIES_CONTENT';
export const MORE_CONTENT = 'MORE_CONTENT';

export const PANEL_LOCATION_ALL = 'PANEL_LOCATION_ALL';
export const PANEL_LOCATION_HOME = 'PANEL_LOCATION_HOME';
export const PANEL_LOCATION_TIMELINE = 'PANEL_LOCATION_TIMELINE';
export const PANEL_LOCATION_NEWS_AND_MEDIA = 'PANEL_LOCATION_NEWS_AND_MEDIA';
export const PANEL_LOCATION_POLLS = 'PANEL_LOCATION_POLLS';

export const EMBED_START_HOME_SCREEN = 'EMBED_START_HOME_SCREEN';
export const EMBED_START_POLLS_SCREEN = 'EMBED_START_POLLS_SCREEN';

export const PROJECT_WEBSITE = 'PROJECT_WEBSITE';
export const PROJECT_DESCRIPTION = 'PROJECT_DESCRIPTION';

export const DUPLICATE_SURVEY = 'DUPLICATE_SURVEY';

export const superClient = {
  subscriptionType: ServiceEnums().serviceServices.basicEngageService,
};

const ability = new Ability([]);

function setFullVisibility(can) {
  can(VIEW, OVERVIEW);
  can(VIEW, EDIT_PROJECT);
  can(VIEW, REPORTS);
  can(VIEW, PUBLISHING);
  can(VIEW, GEOLOCATION);
  can(VIEW, TIMELINE);
  can(VIEW, INFORMATION);
  can(VIEW, LANGUAGES);
  can(VIEW, IMAGE_GALLERY);
  can(VIEW, TEAM);
  can(VIEW, INFLUENCED_DECISIONS);
  can(VIEW, CHARITIES);
  can(VIEW, POLLS);
  can(VIEW, SEGMENTATION);
  can(VIEW, DEMOGRAPHICS);
  can(VIEW, CUSTOMIZE);
  can(VIEW, PANELS);
  can(VIEW, CONTENT);
  can(VIEW, ACTIONS);
  can(VIEW, BLOCKS);
  can(VIEW, FAQ);
  can(VIEW, NEWS);
  can(VIEW, REPORTS_DASHBOARD);
  can(VIEW, REPORTS_POLLS);
  can(VIEW, REPORTS_DEMOGRAPHICS);
  can(VIEW, REPORTS_FEEDBACK);
  can(VIEW, REPORTS_GENERATE);
  can(VIEW, SETTINGS);
  can(VIEW, CONFIGURATION);
  can(VIEW, EMBED);
  can(VIEW, PROJECT_WEBSITE);
  can(VIEW, PROJECT_DESCRIPTION);

  can(EDIT, HOMEPAGE_CONTENT);
  can(EDIT, POLLS_CONTENT);
  can(EDIT, FEEDBACK_QUESTION_CONTENT);
  can(EDIT, CHARITIES_CONTENT);
  can(EDIT, MORE_CONTENT);

  can(SET, PANEL_LOCATION_ALL);
  can(SET, PANEL_LOCATION_HOME);
  can(SET, PANEL_LOCATION_TIMELINE);
  can(SET, PANEL_LOCATION_NEWS_AND_MEDIA);
  can(SET, PANEL_LOCATION_POLLS);

  can(SET, EMBED_START_HOME_SCREEN);
  can(SET, EMBED_START_POLLS_SCREEN);
}

function setPollsOnlyVisibility(can) {
  can(VIEW, EDIT_PROJECT);
  can(VIEW, REPORTS);
  can(VIEW, SETTINGS);
  can(VIEW, INFORMATION);
  can(VIEW, LANGUAGES);
  can(VIEW, CHARITIES);
  can(VIEW, POLLS);
  can(VIEW, SEGMENTATION);
  can(VIEW, DEMOGRAPHICS);
  can(VIEW, CUSTOMIZE);
  can(VIEW, PANELS);
  can(VIEW, CONTENT);
  can(VIEW, ACTIONS);
  can(VIEW, REPORTS_DASHBOARD);
  can(VIEW, REPORTS_POLLS);
  can(VIEW, REPORTS_FEEDBACK);
  can(VIEW, REPORTS_DEMOGRAPHICS);
  can(VIEW, REPORTS_GENERATE);
  can(VIEW, PUBLISHING);
  can(VIEW, CONFIGURATION);
  can(VIEW, SETTINGS);
  can(VIEW, EMBED);

  can(EDIT, POLLS_CONTENT);
  can(EDIT, FEEDBACK_QUESTION_CONTENT);
  can(EDIT, CHARITIES_CONTENT);

  can(SET, PANEL_LOCATION_POLLS);

  can(SET, EMBED_START_POLLS_SCREEN);
}

function isToolsPageAvailable(userRole) {
  const roleIds = RoleEnums().roleIDs;
  return [
    roleIds.SUPER_ADMIN,
    roleIds.ADMINISTRATOR,
    roleIds.ACCOUNT_MANAGER,
    roleIds.ACCOUNT_ASISTANT,
    roleIds.ACCOUNT_VIEWER,
    roleIds.CLIENT_MASTER,
  ].includes(userRole);
}

function isDuplicateSurveyAvailable(userRole) {
  const roleIds = RoleEnums().roleIDs;
  return [
    roleIds.SUPER_ADMIN,
    roleIds.ADMINISTRATOR,
    roleIds.ACCOUNT_MANAGER,
    roleIds.ACCOUNT_VIEWER,
    roleIds.CLIENT_MASTER,
  ].includes(userRole);
}

export function updateAbility(
  updatedAbility,
  userRole = '',
  isPollsOnlyProject = false,
  isPersonalDataAcquisitionEnabled = false,
) {
  const { can, rules } = new AbilityBuilder(Ability);

  if (isPollsOnlyProject) {
    setPollsOnlyVisibility(can, isPersonalDataAcquisitionEnabled);
  } else {
    setFullVisibility(can, isPersonalDataAcquisitionEnabled);
  }

  if (isPersonalDataAcquisitionEnabled) can(VIEW, PERSONAL_DATA);

  const toolsPageAvailable = isToolsPageAvailable(userRole);
  if (toolsPageAvailable) can(VIEW, TOOLS);

  const duplicateSurveyAvailable = isDuplicateSurveyAvailable(userRole);
  if (duplicateSurveyAvailable) can(VIEW, DUPLICATE_SURVEY);

  updatedAbility.update(rules);
}

export default ability;
