import moment from 'moment';
import DATE_ENUMS from '../shared/engage/engage-date-enums';

function getShortDateString(inDate) {
  return moment(inDate).format(DATE_ENUMS().DateFormatPatterns.DATE_ONLY_BASE);
}
function getDateTimeString(inDate) {
  return moment(inDate).format(DATE_ENUMS().DateFormatPatterns.DATE_WITH_TIME_BASE);
}

function getTimeDateString(inDate) {
  return moment(inDate).format(DATE_ENUMS().DateFormatPatterns.TIME_WITH_DATE_BASE);
}

function getDateFullTimeString(inDate) {
  return moment(inDate).format(DATE_ENUMS().DateFormatPatterns.DATE_WITH_FULL_TIME_BASE);
}

export default {
  getDateTimeString,
  getTimeDateString,
  getShortDateString,
  getDateFullTimeString,
};
