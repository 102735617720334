import React from 'react';
import { Route } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { CLIENTS } from '../../common/constants/RouteConstants';
import ClientList from './ClientList';
import Overview from './Overview';

function Staff(props) {
  const { HelpStore, match } = props;
  return (
    <div className='l-body'>
      <div className='l-container'>
        <div className={`l-page ${HelpStore.isContextualHelpActive ? 'l-page--show-help' : ''}`}>
          <div className='l-main'>
            <Route path={`${match.path}/${CLIENTS}`} component={Overview} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default inject((root) => ({
  HelpStore: root.RootStore.helpStore,
}))(observer(Staff));
