import React from 'react';

import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import { HELP } from 'shared/engage/engage-help-enums';
import ActionBar from '../../../common/components/actionBar/ActionBar';
import InlineButton from '../../../common/components/inlineButton/InlineButton';
import ActionsItem from './ActionsItem';
import {
  ADDON_BUTTON,
  ADDON_SECOND_BUTTON,
  DEFAULT_BUTTON,
  INFO_DEFAULT_MESSAGE,
  INFO_ADDON_MESSAGE,
} from './CustomActionsUtils';
import enums from '../../../shared/engage/engage-flow-enums';

const ENUMS = enums().EngageEndOfPollFlowButtonType;

function Actions(props) {
  const { ActionStore } = props;

  const createCustomActionButton = (data, isDefault) => {
    ActionStore.setEndOfPollButtons(data, isDefault);
  };

  const createAddonButtonDraft = () => {
    const addonButton = ActionStore.getAddonButtons.find(
      (button) => button.data.buttonTypeIdentifier === ENUMS.ADDON_BUTTON,
    );
    addonButton
      ? ActionStore.createAddonButtonDraft(ADDON_SECOND_BUTTON)
      : ActionStore.createAddonButtonDraft(ADDON_BUTTON);
  };

  /**
   * Default button should be visible in UI
   * even if not on server so logic is different from other buttons
   * Data for it is created in the ActionStore during loadData if it does not exist on server
   *
   * Also logic for this button is different because it first
   * needs to be overriden (which creates the button on the server and refreshes from that data)
   * And when reset returns to that overrideable state
   */

  return (
    <div className='l-main'>
      <ActionBar
        hasButton={false}
        label='End of Survey Actions'
        helpKey={HELP.PROJECT.EDIT_PROJECT.CUSTOMISE.END_OF_SURVEY_ACTIONS.PAGE}
        isTextOnlyHelp={true}
      />
      <p>Edit the thank you screen at the end of surveys to direct your community to what is most important to you.</p>
      <div className='c-switchable-list c-switchable-list--polls'>
        {ActionStore.getDefaultButton.map((item, index) => {
          return (
            <ActionsItem
              key={index}
              index={index}
              item={item}
              isDisabled={!item.data._id}
              override={() => createCustomActionButton(DEFAULT_BUTTON, true)}
              infoMessage={!item.data._id && INFO_DEFAULT_MESSAGE}
            />
          );
        })}
        {ActionStore.getAddonButtons.map((item, index) => (
          <ActionsItem
            key={item.data._id}
            index={index}
            item={item}
            infoMessage={index === 1 ? INFO_ADDON_MESSAGE : ''}
            isNew={!item.data._id}
          />
        ))}
      </div>
      {/* <ActionsItem item={{ data: {}, formState: { isOpened: false } }} /> */}
      {ActionStore.getAddonButtons.length < 2 && <InlineButton onClick={createAddonButtonDraft} buttonLabel='Add' />}
    </div>
  );
}

export default inject((root) => ({ ActionStore: root.RootStore.actionStore }))(observer(Actions));

Actions.propTypes = {
  ActionStore: PropTypes.object,
};
