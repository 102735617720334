const QUESTION_PICKER_LIST = {
  IMAGE_GROUP: 'project.editProject.survey.questionPicker.imageGroup',
  FACT_GROUP: 'project.editProject.survey.questionPicker.factGroup',
  ANNOTATE_GROUP: 'project.editProject.survey.questionPicker.annotateGroup',
  TWO_IMAGE_CHOICE: 'project.editProject.survey.questionPicker.twoImageChoice',
  THREE_IMAGE_CHOICE: 'project.editProject.survey.questionPicker.threeImageChoice',
  FOUR_IMAGE_CHOICE: 'project.editProject.survey.questionPicker.fourImageChoice',
  MULTIPLE_IMAGE_CHOICE: 'project.editProject.survey.questionPicker.multipleImageChoice',
  IMGAGE_CHOICE_OR_OTHER: 'project.editProject.survey.questionPicker.imageChoiceOrOther',
  DISTRIBUTE: 'project.editProject.survey.questionPicker.distribute',
  REORDER: 'project.editProject.survey.questionPicker.reorder',
  SLIDER: 'project.editProject.survey.questionPicker.slider',
  SLIDER_FEEDBACK: 'project.editProject.survey.questionPicker.sliderFeedback',
  FACT_TEXT: 'project.editProject.survey.questionPicker.factText',
  FACT_MEDIA: 'project.editProject.survey.questionPicker.factMedia',
  FACT_COMPARE: 'project.editProject.survey.questionPicker.factCompare',
  FEEDBACK: 'project.editProject.survey.questionPicker.feedback',
  ANNOTATION_IMAGE: 'project.editProject.survey.questionPicker.annotateImage',
  ANNOTATION_MAP: 'project.editProject.survey.questionPicker.annotateMap',
  QUICK_TEXT: 'project.editProject.survey.questionPicker.quickText',
  RATING: 'project.editProject.survey.questionPicker.rating',
};

exports.QUESTION_PICKER = QUESTION_PICKER_LIST;

const SURVEY_QUESTIONS = {
  TWO_IMAGE_CHOICE: { BASE: 'project.editProject.survey.questions.twoImageChoice.base' },
  THREE_IMAGE_CHOICE: { BASE: 'project.editProject.survey.questions.threeImageChoice.base' },
  FOUR_IMAGE_CHOICE: { BASE: 'project.editProject.survey.questions.fourImageChoice.base' },
  MULTIPLE_IMAGE_CHOICE: {
    BASE: 'project.editProject.survey.questions.multipleImageChoice.base',
  },
  IMGAGE_CHOICE_OR_OTHER: {
    BASE: 'project.editProject.survey.questions.imageChoiceOrOther.base',
  },
  DISTRIBUTE: { BASE: 'project.editProject.survey.questions.distribute.base' },
  REORDER: {
    BASE: 'project.editProject.survey.questions.reorder.base',
  },
  SLIDER: { BASE: 'project.editProject.survey.questions.slider.base' },
  SLIDER_FEEDBACK: { BASE: 'project.editProject.survey.questions.sliderFeedback.base' },
  FACT_TEXT: { BASE: 'project.editProject.survey.questions.factText.base' },
  FACT_MEDIA: { BASE: 'project.editProject.survey.questions.factMedia.base' },
  FACT_COMPARE: { BASE: 'project.editProject.survey.questions.factCompare.base' },
  FEEDBACK: { BASE: 'project.editProject.survey.questions.feedback.base' },
  ANNOTATION_IMAGE: { BASE: 'project.editProject.survey.questions.annotateImage.base' },
  ANNOTATION_MAP: { BASE: 'project.editProject.survey.questions.annotateMap.base' },
  QUICK_TEXT: { BASE: 'project.editProject.survey.questions.quickText.base' },
  RATING: { BASE: 'project.editProject.survey.questions.rating.base' },
};

exports.SURVEY_QUESTIONS = SURVEY_QUESTIONS;

exports.HELP = {
  CLIENTS: {},
  PROJECTS: {},
  USERS: {},
  PROJECT: {
    EDIT_PROJECT: {
      INFORMATION: {
        PAGE: 'project.editProject.information.page',
        NAME: 'project.editProject.information.projectName',
        SLUG: 'project.editProject.information.projectSlug',
        DEVELOPERS: 'project.editProject.information.projectDevelopers',
        DESCRIPTION: 'project.editProject.information.projectDescription',
      },
      CHARITIES: {
        PAGE: 'project.editProject.charities.page',
      },
      CUSTOMISE: {
        PANELS: {
          PAGE: 'project.editProject.customise.panels.page',
        },
        END_OF_SURVEY_ACTIONS: {
          PAGE: 'project.editProject.customise.endOfSurveyActions.page',
        },
        PERSONAL_DATA: {
          PAGE: 'project.editProject.customise.personalData.page',
        },
      },
      SURVEY: {
        PAGE: 'project.editProject.survey.page',
        NAME: 'project.editProject.survey.name',
        PHASE: 'project.editProject.survey.phase',
        QUESTIONS: SURVEY_QUESTIONS,
        QUESTION_PICKER: QUESTION_PICKER_LIST,
        CONDITIONAL_LOGIC: 'project.editProject.survey.conditionalLogic',
      },
      SEGMENTATION: {
        PAGE: 'project.editProject.segmentation.page',
      },
      DEMOGRAPHICS: {
        PAGE: 'project.editProject.demographics.page',
      },
    },
    REPORTING: {
      SURVEYS: {
        PAGE: 'project.reports.surveys.page',
      },
      GENERATE: {
        REPORT_TYPE: 'project.reports.generate.reportType',
      },
    },
    SETTINGS: {
      PUBLISHING: {},
      CONFIGURATION: {
        PAGE: 'project.settings.configuration.base',
      },
      EMBED: {},
      TOOLS: {
        DUPLICATE_SURVEY: {
          TITLE: 'project.settings.tools.duplicateSurvey.title',
        },
      },
    },
  },
};

exports.KNOWLEDGLE_BASE_URL = 'https://www.google.com';
