module.exports = function () {
  const DateFormatPatterns = {
    DATE_ONLY: 'dd/MM/yyyy',

    /**
     * Double format because of different file types in raw data report (.xlsx & .csv).
     * See server/gmv/v2/reporting/ReportingUtility.js:190
     */
    // FORMAT USED FOR .xlsx
    DATE_WITH_TIME: 'dd/MM/yyyy hh:mm',
    // FORMAT USED FOR .csv
    MOMENT_DATE_WITH_TIME: 'DD/MM/yyyy hh:mm A',
    /**
     *
     */

    DATE_WITH_FULL_TIME: 'dd/MM/yyyy hh:mm:ss',
    DATE_WITH_MERIDIEM_TIME: 'dd/MM/yyyy h:mm aa',

    DATE_ONLY_BASE: 'DD/MM/YYYY',
    DATE_WITH_TIME_BASE: 'DD/MM/YYYY HH:mm',
    DATE_WITH_FULL_TIME_BASE: 'DD/MM/YYYY HH:mm:ss',
    DATE_WITH_MERIDIEM_TIME_BASE: 'DD/MM/YYYY h:mm aa',

    TIME_WITH_DATE_BASE: 'HH:mm DD/MM/YYYY',
  };

  return {
    DateFormatPatterns: DateFormatPatterns,
  };
};
