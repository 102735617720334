import React from 'react';

import RadioInput from '../../../../common/components/form-elements/radioGroupInputField/RadioInput';
import { EMBED_TYPE_CHOICES } from './EmbedEnums';

export default function EmbedTypePicker(props) {
  const { value, setValue } = props;
  return (
    <div className='c-field l-form__item'>
      <label className='c-field__label'>Embed type</label>
      <div className='c-field__group'>
        <div className='l-list l-list--halfs'>
          <RadioInput
            choices={EMBED_TYPE_CHOICES}
            classModifier='o-radio--card'
            input={{
              value,
              onChange: (event) => setValue(event.target.value),
            }}
          />
        </div>
      </div>
    </div>
  );
}
