import React, { useState, useCallback, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import EngageCheckboxInputField from 'common/components/form-elements/checkboxInputField/CheckboxInputField';
import EngageTextInputField from '../../../common/components/form-elements/textInputField/TextInputField';
import history from '../../../common/history';
import { INFORMATION, PROJECT, EDIT_PROJECT } from '../../../common/constants/RouteConstants';
import { getValidatingButtonClass, _validateSlugMemoized } from '../../../common/formValidators';

const {
  isSlugValid,
  SLUG_VALIDATION_TYPE: { SLUG_CREATE },
} = require('../../../shared/engage').ProjectEnums;

function Top() {
  return (
    <Fragment>
      <h1 className='c-community-new__title'>New project</h1>
      <p className='c-community-new__description'>
        Enter the project name and a unique slug for this project. Make it short and memorable — your community will
        interact with it. We will guide you the rest of the way.
      </p>
    </Fragment>
  );
}

function CreateProject(props) {
  const { ProjectListStore, AuthStore, ProjectInformationStore } = props;

  const initialValues = {
    isSurveyOnly: false,
    projectName: '',
    slug: '',
  };

  function onSubmit(values) {
    ProjectListStore.createWithClientId(AuthStore.clientId, values).then((res) => {
      history.push(`/${PROJECT}/${res.data._id}/${EDIT_PROJECT}/${INFORMATION}`);
    });
  }

  /**
   * Validate slug formally + online uniqueness validation
   * Need to memoize to avoid validating slug on every form update ie. project name
   */
  const validateSlugMemoized = _validateSlugMemoized(ProjectInformationStore, SLUG_CREATE);

  return (
    <div className='l-main'>
      <div className='c-community-new'>
        <Top />
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={(formProps) => {
            const { handleSubmit, invalid, touched, errors, validating } = formProps;

            return (
              <form className='c-fields l-form l-form--single-column' onSubmit={handleSubmit}>
                <EngageTextInputField
                  id='input-project-name'
                  placeholder='Name'
                  label='Project name'
                  maxLength='35'
                  fieldDescription='Short names work best'
                  fieldName='projectName'
                  isRequired={true}
                  meta={{ touched: touched.projectName, error: errors.projectName }}
                />

                <EngageTextInputField
                  id='input-project-slug'
                  placeholder='Slug'
                  label='Project slug'
                  maxLength='45'
                  fieldDescription='Your project will be visible at this URL. Only alphanumerics and dashes allowed.'
                  fieldName='slug'
                  isRequired={true}
                  showSlugLabel={true}
                  validator={validateSlugMemoized}
                  meta={{ touched: touched.slug, error: errors.slug }}
                />
                <hr />
                <EngageCheckboxInputField
                  id='checkbox-project-type'
                  type='checkbox'
                  fieldName='isSurveyOnly'
                  label='This is a survey-only project'
                  checkboxLabelTooltip={{
                    modifier: 'o-tooltip--left-center o-tooltip--dark',
                    icon: 'info-circle',
                    description:
                      'Survey-only projects don’t have all the additional website content options of regular projects and are focused, as the name suggests, on the surveys.',
                  }}
                  isToggle={true}
                  hideLabel={true}
                />
                <div className='c-field c-field--action l-form__item'>
                  <div className='c-field__group'>
                    <button
                      className={`o-button o-button--primary ${getValidatingButtonClass(invalid, validating)}`}
                      type='submit'
                      disabled={invalid || validating}
                    >
                      <span className='o-label'>Create project</span>
                    </button>
                  </div>
                </div>
              </form>
            );
          }}
        />
      </div>
    </div>
  );
}

CreateProject.propTypes = {
  ProjectListStore: PropTypes.object.isRequired,
  AuthStore: PropTypes.object.isRequired,
  ProjectInformationStore: PropTypes.object.isRequired,
};

export default inject((root) => ({
  ProjectListStore: root.RootStore.projectListStore,
  AuthStore: root.RootStore.authStore,
  ProjectInformationStore: root.RootStore.projectInformationStore,
}))(observer(CreateProject));
