import React from 'react';
import PropTypes from 'prop-types';
import MDEditor from '@uiw/react-md-editor';
import { IMAGE_FORMATS } from '../../../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import QuestionTemplateAction from './QuestionTemplateAction';
import ImageUtils from '../../../../../common/ImageUtils';
import QuestionTemplateHeader from './QuestionTemplateHeader';

function FactComparePreview(props) {
  const { question } = props;

  return (
    <div className='c-library__item'>
      <div className='c-question c-question--fact-compare c-question--library'>
        <QuestionTemplateHeader question={question} showTitle={false} />
        <div className='c-question__content'>
          <div className='c-question-fact c-question-fact--compare'>
            <div className='c-question-fact__content u-rich-text'>
              <MDEditor.Markdown source={question.questionText} className='markdown_preview' />
            </div>
            <div className='c-question-fact__items'>
              <div className='c-question-fact__item'>
                {question.resource && question.resource.length > 0 && (
                  <img src={ImageUtils.imageUrl(question.resource[0], IMAGE_FORMATS.SQUARE.imageType)} alt='' />
                )}
              </div>
              <div className='c-question-fact__item'>
                {question.resource && question.resource.length > 1 && (
                  <img src={ImageUtils.imageUrl(question.resource[1], IMAGE_FORMATS.SQUARE.imageType)} alt='' />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <QuestionTemplateAction question={question} />
    </div>
  );
}

FactComparePreview.propTypes = {
  question: PropTypes.shape({
    questionText: PropTypes.string.isRequired,
    resource: PropTypes.array.isRequired,
  }).isRequired,
};

export default FactComparePreview;
