import React from 'react';
import PropTypes from 'prop-types';

import SimpleCheckboxInput from './SimpleCheckboxInput';
import SvgIcon from '../../SvgIcon';
import FieldDescription from '../fieldDescription/FieldDescription';

export default function SimpleCheckboxInputField(props) {
  const {
    input,
    id,
    label,
    labelTooltip,
    checkboxLabelTooltip,
    fieldClass = '',
    actionTooltip,
    fieldDescription,
    meta = {},
    tooltip,
    checkedLabel = label,
    uncheckedLabel = label,
    isToggle = false,
    hideLabel = true,
    classModifier = '',
    disabled = false,
    onSelectionChange,
  } = props;
  const { onChange } = input;

  const currentLabel = input && input.checked ? checkedLabel : uncheckedLabel;

  const mergedOnChange = (e) => {
    onChange(e);
    if (onSelectionChange) onSelectionChange(e);
  };

  const newInput = { ...input, onChange: mergedOnChange };

  return (
    <div className={`c-field l-form__item ${fieldClass} ${hideLabel ? 'c-field--label-hidden' : ''}`}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className='c-field__label' htmlFor={id}>
        {`${label} `}
        {labelTooltip && (
          <span className='o-tooltip o-tooltip--top-center o-tooltip--media'>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
            <span className='o-tooltip__action' tabIndex='0'>
              <SvgIcon icon='info-circle' />
            </span>
            <span className='o-tooltip__description' role='tooltip'>
              {labelTooltip}
            </span>
          </span>
        )}
      </label>
      <div className='c-field__group'>
        <SimpleCheckboxInput
          classModifier={classModifier}
          isToggle={isToggle}
          input={newInput}
          id={id}
          checked={newInput.checked}
          label={currentLabel}
          disabled={disabled}
          tooltip={checkboxLabelTooltip}
        />
        <FieldDescription
          actionTooltip={actionTooltip}
          fieldDescription={fieldDescription}
          meta={meta}
          tooltip={tooltip}
          val={newInput.value}
        />
      </div>
    </div>
  );
}

SimpleCheckboxInputField.propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.object,
  label: PropTypes.string,
  actionTooltip: PropTypes.object,
  checkboxLabelTooltip: PropTypes.object,
  fieldDescription: PropTypes.string,
  meta: PropTypes.object,
  tooltip: PropTypes.object,
  labelTooltip: PropTypes.string,
  checkedLabel: PropTypes.string,
  uncheckedLabel: PropTypes.string,
  isToggle: PropTypes.bool,
  hideLabel: PropTypes.bool,
  fieldClass: PropTypes.string,
  disabled: PropTypes.bool,
};
