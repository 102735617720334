function formatPercent(inNumber) {
  if (inNumber !== 0 && !inNumber) {
    return null;
  }
  return new Intl.NumberFormat('en-GB', { style: 'percent' }).format(inNumber / 100);
}

// function formatInt(inNumber) {}
// function formatDecimal(inNumber) {}
function formatIntWithSeparator(inNumber) {
  if (inNumber !== 0 && !inNumber) {
    return null;
  }
  return new Intl.NumberFormat('en-GB', { style: 'decimal', maximumFractionDigits: 0 }).format(inNumber);
}

function fixedDecimals(value, decimals = 2, ifNotNumberLabel = 'n/a') {
  const valueNumber = Number(value);

  if (Number.isNaN(valueNumber) || value === '') return ifNotNumberLabel;

  return valueNumber.toFixed(decimals);
}
// eslint-disable-next-line
export default {
  formatPercent,
  // formatInt,
  // formatDecimal,
  formatIntWithSeparator,
  fixedDecimals,
};
