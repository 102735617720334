import Agent from './agent';

const requests = Agent.requests;
const ENDPOINT = 'segmentation';
const SUB_ENDPOINT = 'questions';
const SUB_SUB_ENDPOINT = 'choice';

const SegmentationAgent = {
  getSegmentation: (projectId) => requests.get(`/projects/${projectId}/segmentation-status`),

  allQuestions: (projectId) => requests.get(`/projects/${projectId}/${ENDPOINT}/${SUB_ENDPOINT}`),
  createQuestion: (projectId, data) => requests.post(`/projects/${projectId}/${ENDPOINT}/${SUB_ENDPOINT}`, data),

  updateQuestion: (projectId, questionId, data) =>
    requests.patch(`/projects/${projectId}/${ENDPOINT}/${SUB_ENDPOINT}/${questionId}`, data),
  removeQuestion: (projectId, questionId) =>
    requests.remove(`/projects/${projectId}/${ENDPOINT}/${SUB_ENDPOINT}/${questionId}`),
  uploadQuestionImage: (projectId, questionId, file, progress) =>
    requests.uploadImage(
      `/projects/${projectId}/${ENDPOINT}/${SUB_ENDPOINT}/${questionId}/resource/`,
      'project',
      file,
      progress,
    ),
  removeQuestionImage: (projectId, questionId, questionImageId) =>
    requests.remove(`/projects/${projectId}/${ENDPOINT}/${SUB_ENDPOINT}/${questionId}/resource/${questionImageId}`),

  // ###
  // Choice
  // ###
  uploadChoiceImage: (projectId, questionId, choiceId, file, progress) =>
    requests.uploadImage(
      `/projects/${projectId}/${ENDPOINT}/${SUB_ENDPOINT}/${questionId}/${SUB_SUB_ENDPOINT}/${choiceId}/resource/`,
      'project',
      file,
      progress,
    ),
  removeChoiceImage: (projectId, questionId, choiceId) =>
    requests.remove(
      `/projects/${projectId}/${ENDPOINT}/${SUB_ENDPOINT}/${questionId}/${SUB_SUB_ENDPOINT}/${choiceId}/resource`,
    ),
};

export default SegmentationAgent;
