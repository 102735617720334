import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import SvgIcon from '../components/SvgIcon';
import { PROJECT, PUBLISHING, EMBED, routeToLabel, TOOLS, CONFIGURATION, SETTINGS } from '../constants/RouteConstants';
import { VIEW } from '../ability';
import { Can } from '../Can';

export default function AsidePublishing(props) {
  const { match } = props;
  const [isDrawerOpened, setDrawerState] = useState(false);
  // console.log('ASIDE Publishing', match);

  function getSelectedClass(item) {
    return item === match.params.route ? 'c-nav-drawer__item is-active' : 'c-nav-drawer__item';
  }

  function getLink(route) {
    return `/${PROJECT}/${match.params.id}/${SETTINGS}/${route}`;
  }

  return (
    <div className='l-aside'>
      <div className={`c-nav-drawer c-nav-drawer--icons ${isDrawerOpened ? 'c-nav-drawer-opened' : ''}`}>
        <div className='c-nav-drawer__content'>
          <ul
            className='c-nav-drawer__items'
            onClick={() => {
              setDrawerState(!isDrawerOpened);
            }}
          >
            <Can I={VIEW} a={PUBLISHING}>
              <li className={getSelectedClass(PUBLISHING)} onClick={(e) => e.stopPropagation()}>
                <Link className='c-nav-drawer__link' to={getLink(PUBLISHING)}>
                  <SvgIcon icon='eye' />
                  {routeToLabel(PUBLISHING)}
                </Link>
              </li>
            </Can>
            <Can I={VIEW} a={CONFIGURATION}>
              <li className={getSelectedClass(CONFIGURATION)} onClick={(e) => e.stopPropagation()}>
                <Link className='c-nav-drawer__link' to={getLink(CONFIGURATION)}>
                  <SvgIcon icon='cog' />
                  {routeToLabel(CONFIGURATION)}
                </Link>
              </li>
            </Can>
            <Can I={VIEW} a={EMBED}>
              <li className={getSelectedClass(EMBED)} onClick={(e) => e.stopPropagation()}>
                <Link className='c-nav-drawer__link' to={getLink(EMBED)}>
                  <SvgIcon icon='code' />
                  {routeToLabel(EMBED)}
                </Link>
              </li>
            </Can>
            <Can I={VIEW} a={TOOLS}>
              <li className={getSelectedClass(TOOLS)} onClick={(e) => e.stopPropagation()}>
                <Link className='c-nav-drawer__link' to={getLink(TOOLS)}>
                  <SvgIcon icon='code' />
                  {routeToLabel(TOOLS)}
                </Link>
              </li>
            </Can>
          </ul>
        </div>
      </div>
    </div>
  );
}
