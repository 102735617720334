import React from 'react';
import { Form } from 'react-final-form';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import PropTypes from 'prop-types';

import { IMAGE_FORMATS } from '../../../../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import EngageSelectInputField from '../../../../../../common/components/form-elements/selectInputField/SelectInputField';
import EngageTextInputField from '../../../../../../common/components/form-elements/textInputField/TextInputField';
import ImageChoice from '../../../../../../common/components/form-elements/imageChoice/ImageChoice';
import SwitchableButtonBar from '../../../../../../common/components/switchableButtonBar/SwitchableButtonBar';
import { QUESTION_POSITION_TYPES } from './QuestionEnums';
import { QUESTION_CHARACTER_LIMIT, IMAGE_CHARACTER_LIMIT } from '../../../../../../common/constants/QuestionConstants';
import { CheckboxInputField } from '../../../../../../common/components/form-elements';

function QQTenImageQuestion(props) {
  const { question, updateQuestion, uploadChoiceImage, removeChoiceImage, removeQuestion } = props;

  const CHOICES = [
    [4, '4 choices'],
    [6, '6 choices'],
    [8, '8 choices'],
    [10, '10 choices'],
  ];
  const ANSWERS = [
    [1, 'Only 1'],
    [2, 'Up to 2'],
    [3, 'Up to 3'],
    [4, 'Up to 4'],
  ];
  const CHOICE_LABELS = [
    'Choice 1',
    'Choice 2',
    'Choice 3',
    'Choice 4',
    'Choice 5',
    'Choice 6',
    'Choice 7',
    'Choice 8',
    'Choice 9',
    'Choice 10',
  ];

  const formInitialValues = () => {
    const tempObj = {};

    tempObj.questionText = question.questionText;
    tempObj.positionInPoll = question.positionInPoll;
    tempObj.multiChoicesOfferedBound = question.multiChoicesOfferedBound;
    tempObj.multiChoicesSelectableBound = question.multiChoicesSelectableBound;
    question.choices.forEach((choice, index) => {
      tempObj[`choice${index + 1}Label`] = choice.choice ? choice.choice : '';
    });
    tempObj.isActive = question.isActive;
    return tempObj;
  };

  const onSubmit = (values) => {
    const q = toJS(question);
    // console.log('PRIJE', q);
    q.questionText = values.questionText;
    q.isActive = values.isActive;
    q.positionInPoll = values.positionInPoll;
    q.multiChoicesOfferedBound = values.multiChoicesOfferedBound;
    q.multiChoicesSelectableBound = values.multiChoicesSelectableBound;
    q.choices.forEach((choice, index) => {
      q.choices[index].choice = values[`choice${index + 1}Label`];
    });
    // q.choices[0].choice = values.choice1Label;
    // q.choices[1].choice = values.choice2Label;
    // console.log('POSLIJE', q);

    updateQuestion(q);
  };

  function isChoicesLimit(value, limit) {
    if (!value) return false;
    return parseInt(value, 10) === limit;
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={formInitialValues()}
      render={({ values, form, handleSubmit, submitting, pristine }) => {
        return (
          <form className='c-fields l-form'>
            <EngageTextInputField
              id={`input-question-${question._id}`}
              placeholder='Enter question'
              label='Question'
              maxLength={QUESTION_CHARACTER_LIMIT}
              fieldDescription=''
              fieldName='questionText'
              isRequired={true}
              fieldClass='l-form__item'
            />

            <EngageSelectInputField
              choices={CHOICES}
              fieldName='multiChoicesOfferedBound'
              id={`input-question-choices-${question._id}`}
              label='Choices'
              fieldClass='l-form__item--m'
              firstOptionEmpty={false}
            />

            <EngageSelectInputField
              choices={ANSWERS}
              fieldName='multiChoicesSelectableBound'
              id={`input-question-answers-${question._id}`}
              label='Possible answers'
              fieldClass='l-form__item--m'
              firstOptionEmpty={false}
            />

            {question.choices.slice(0, values.multiChoicesOfferedBound).map((choice, index) => (
              <ImageChoice
                imageFormat={IMAGE_FORMATS.SQUARE}
                key={choice._id}
                index={index}
                classModifier={
                  isChoicesLimit(values.multiChoicesOfferedBound, 4) ? 'l-form__item--m' : 'l-form__item--s'
                }
                placeholder='Choice name'
                maxLength={IMAGE_CHARACTER_LIMIT}
                choice={choice}
                fieldName={`choice${index + 1}Label`}
                label={CHOICE_LABELS[index]}
                handleUpload={uploadChoiceImage}
                removeImage={removeChoiceImage}
              />
            ))}

            {isChoicesLimit(values.multiChoicesOfferedBound, 8) && <div className='l-form__item l-form__item--s' />}
            {isChoicesLimit(values.multiChoicesOfferedBound, 10) && <div className='l-form__item l-form__item--s' />}
            {isChoicesLimit(values.multiChoicesOfferedBound, 10) && <div className='l-form__item l-form__item--s' />}

            <EngageSelectInputField
              choices={QUESTION_POSITION_TYPES}
              id={`input-segment-position-${question._id}`}
              fieldClass='l-form__item--m'
              fieldName='positionInPoll'
              label='Question position'
              firstOptionEmpty={false}
            />

            <CheckboxInputField
              id={`checkbox-segmentation-status-${question._id}`}
              type='checkbox'
              fieldName='isActive'
              fieldClass='l-form__item--m'
              label='Status'
              isToggle={true}
              checkedLabel='Active'
              uncheckedLabel='Inactive'
              hideLabel={false}
            />

            <SwitchableButtonBar updateAction={handleSubmit} removeAction={removeQuestion} isNew={null} />
          </form>
        );
      }}
    />
  );
}

QQTenImageQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  updateQuestion: PropTypes.func.isRequired,
  uploadChoiceImage: PropTypes.func.isRequired,
  removeChoiceImage: PropTypes.func.isRequired,
  removeQuestion: PropTypes.func.isRequired,
};

export default observer(QQTenImageQuestion);
