import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from 'common/components/SvgIcon';
import FilterList from 'common/components/filterList/FilterList';
import FilterSearch from 'common/components/filterSearch/FilterSearch';
import { OVERVIEW_FILTER_OPTIONS } from 'common/FilterUtils';

function OverviewFilters(props) {
  const { filterState, setFilterState } = props.filter;

  function filterListChangeHandler(newOption) {
    setFilterState({ ...filterState, selectedFilterOption: newOption });
  }

  function filterSearchChangeHandler(newValue) {
    setFilterState({ ...filterState, searchValue: newValue });
  }

  return (
    <div className='c-action-bar' data-testid='overview-filter-wrapper'>
      <h1 className='c-action-bar__title'>Overview</h1>
      <div className='c-action-bar__action'>
        <FilterList
          availableOptions={OVERVIEW_FILTER_OPTIONS}
          onFilterChange={filterListChangeHandler}
          selectedOption={filterState.selectedFilterOption}
        />
        <FilterSearch
          searchValue={filterState.searchValue}
          onFilterChange={filterSearchChangeHandler}
          placeholder='Filter clients'
        />
      </div>
    </div>
  );
}

OverviewFilters.propTypes = {
  filter: PropTypes.shape({
    filterState: PropTypes.object.isRequired,
    setFilterState: PropTypes.func.isRequired,
  }).isRequired,
};

export default OverviewFilters;
