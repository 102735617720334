import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import SimpleTextareaInputField from './SimpleTextareaInputField';

export default function EngageTextareaInputField(props) {
  const { fieldName, isRequired } = props;

  function validateRequired(value) {
    if (!isRequired) return;
    const val = value && value.trim();
    return !val && isRequired ? 'Required' : undefined;
  }

  return <Field component={SimpleTextareaInputField} name={fieldName} validate={validateRequired} {...props} />;
}

EngageTextareaInputField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
};
