import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { SortableContainer } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

import { HELP } from 'shared/engage/engage-help-enums';
import ActionBar from '../../../common/components/actionBar/ActionBar';
import SortableCharityItem from './SortableCharityItem';

/**
 * Member list container
 * ### IMPORTANT this one is sort container!!
 */
function CharitiesContainerFn(props) {
  const { Store, allowDrag } = props;
  return (
    <div className='c-switchable-list c-switchable-list--polls'>
      {Store.charities.map((item, index) =>
        item.data.enabled ? (
          <SortableCharityItem
            key={item.data._id}
            index={index}
            item={item}
            switchableModifier='c-switchable--reorder'
            disabled={!allowDrag}
          />
        ) : null,
      )}
    </div>
  );
}

/**
 * Team page container
 */
function CharitiesFn(props) {
  // console.log(props);
  const { Store } = props;

  const [allowDrag, setAllowDrag] = useState(true);

  const addNew = () => {
    Store.create();
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setAllowDrag(false);
    const newOrder = arrayMoveImmutable(props.Store.charities, oldIndex, newIndex);
    Store.reorder(newOrder).then(() => {
      setAllowDrag(true);
    });
  };

  return (
    <div className='l-main'>
      <ActionBar
        addNew={addNew}
        label='Charities'
        hasButton={true}
        buttonLabel='Add'
        helpKey={HELP.PROJECT.EDIT_PROJECT.CHARITIES.PAGE}
        isTextOnlyHelp={true}
      />
      <SortableList useDragHandle={true} onSortEnd={onSortEnd} allowDrag={allowDrag} />
    </div>
  );
}

const Charities = inject((root) => ({ Store: root.RootStore.charitiesStore }))(observer(CharitiesFn));

const CharitiesContainer = inject((root) => ({ Store: root.RootStore.charitiesStore }))(observer(CharitiesContainerFn));

/**
 * Sortable collection wrapper
 */
const SortableList = SortableContainer((props) => {
  return <CharitiesContainer {...props} />;
});

export default Charities;
