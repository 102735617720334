import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ModalCloseButton from '../buttons/ModalCloseButton';
import SvgIcon from '../SvgIcon';
import { openModal } from '../../../containers/project/polls/PollUtils';

export default function PreviewModal(props) {
  const { closeModal, iframeSrc } = props;
  const [modalType, setModalType] = useState('mobile');

  useEffect(() => {
    return openModal(closeModal);
  }, [closeModal]);

  return (
    <div className='c-modal c-modal--full-screen' style={{ display: 'block' }}>
      <div className='c-modal__container'>
        <div className='c-modal__header'>
          <nav className='c-nav-tabs c-nav-tabs--icons-mobile'>
            <ul className='c-nav-tabs__items'>
              <li className={`c-nav-tabs__item ${modalType === 'mobile' ? 'is-active' : ''}`}>
                <button
                  className='c-nav-tabs__link js-previewTabMobile'
                  onClick={() => setModalType('mobile')}
                  type='button'
                >
                  <SvgIcon icon='mobile' />
                  <div className='o-label'>Mobile</div>
                </button>
              </li>
              <li className={`c-nav-tabs__item ${modalType === 'tablet' ? 'is-active' : ''}`}>
                <button
                  className='c-nav-tabs__link js-previewTabTablet'
                  onClick={() => setModalType('tablet')}
                  type='button'
                >
                  <SvgIcon icon='tablet' />
                  <div className='o-label'>Tablet</div>
                </button>
              </li>
              <li className={`c-nav-tabs__item ${modalType === 'desktop' ? 'is-active' : ''}`}>
                <button
                  className='c-nav-tabs__link js-previewTabDesktop'
                  onClick={() => setModalType('desktop')}
                  type='button'
                >
                  <SvgIcon icon='desktop' />
                  <div className='o-label'>Desktop</div>
                </button>
              </li>
            </ul>
          </nav>
          <ModalCloseButton closeModal={closeModal} />
        </div>
        <div className='c-modal__content'>
          <div className={`c-preview c-preview--${modalType}`}>
            <div className='c-preview__content'>
              <iframe title='Give My View' src={iframeSrc} frameBorder='0' height='100%' width='100%' />
            </div>
          </div>
          <div className='c-preview-open'>
            <a
              className='o-button o-button--naked o-button--xs'
              href={iframeSrc}
              target='_blank'
              rel='noopener noreferrer'
            >
              <span className='o-label'>Open preview in a new window</span>
              <SvgIcon icon='external-link' />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

PreviewModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};
