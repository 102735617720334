module.exports = function(){

  let _ = require('lodash');

  const EngageEndOfPollFlowButtonType = {
    DEFAULT_BUTTON: 'defaultButton',
    ADDON_BUTTON: 'addonButton',
    ADDON_SECOND_BUTTON: 'addonSecondButton',
  };

  const HomepageSectionFlowTypes = {
    INTRO: 'INTRO',
    TIMELINE: 'TIMELINE',
    INFLUENCED_DECISIONS: 'INFLUENCED_DECISIONS',
    MEDIA: 'MEDIA',
    LOCATION: 'LOCATION',
    TEAM: 'TEAM'
  };


  return {
    EngageEndOfPollFlowButtonType: EngageEndOfPollFlowButtonType,
    engageEndOfPollFlowButtonTypeList: engageEndOfPollFlowButtonTypeList,
    HomepageSectionFlowTypes: HomepageSectionFlowTypes,
    homepageSectionFlowTypeList: homepageSectionFlowTypeList
  };

  function engageEndOfPollFlowButtonTypeList(){
    return _.map(Object.keys(EngageEndOfPollFlowButtonType), (key) => { return EngageEndOfPollFlowButtonType[key]; });
  }

  function homepageSectionFlowTypeList(){
    return _.map(Object.keys(HomepageSectionFlowTypes), (key) => { return HomepageSectionFlowTypes[key]; });
  }


};
