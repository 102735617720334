import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import MDEditor from '@uiw/react-md-editor';
import SvgIcon from '../../../../common/components/SvgIcon';

function PollChoice(props) {
  const { HelpStore, questionType, selectHandler, helpKey } = props;
  const key = questionType[0];
  const val = questionType[1];

  const helpItem = HelpStore?.help?.find((item) => item.key === helpKey) || {};

  return (
    <li className='c-poll-choice__item' onClick={() => selectHandler(questionType)}>
      <input name='inputPollAdd' type='radio' value={key} id={key} disabled={questionType.disabled} />
      <label className='c-poll-choice__label' htmlFor={key}>
        <SvgIcon icon={val.icon} />
        <span className='c-poll-choice__title'>
          {val.label}
          {val.items && <span className='c-poll-choice__count'>{Object.entries(val.items).length}</span>}
        </span>
        <span className='c-poll-choice__description'>{val.tip}</span>
        {val.templateImg && (
          <div className='c-poll-choice__image' data-color-mode='light'>
            <img src={`/assets-v2/images/polls/${val.templateImg}`} alt='' />
            {helpKey && helpItem?.active && (
              <MDEditor.Markdown source={helpItem?.content} linkTarget='_blank' className='c-poll-choice__note' />
            )}
          </div>
        )}
      </label>
    </li>
  );
}

PollChoice.propTypes = {
  HelpStore: PropTypes.object.isRequired,
  questionType: PropTypes.array.isRequired,
  selectHandler: PropTypes.func,
  helpKey: PropTypes.string,
};

export default inject((root) => ({ HelpStore: root.RootStore.helpStore }))(observer(PollChoice));
