import { action, observable, computed, reaction, makeObservable } from 'mobx';
import Agent from '../common/agent';

const ENGAGE_LANGUAGES = require('../shared/engage/engage-languages')().engageLanguages();

export default class LanguageStore {
  initialized = false;

  loading = false;

  error = null;

  _languages = ['en'];

  constructor(rootStore, projectStore) {
    makeObservable(this, {
      initialized: observable,
      loading: observable,
      error: observable,

      _languages: observable,
      languages: computed,
      saveLanguages: action,
    });

    this.rootStore = rootStore;
    this.projectStore = projectStore;
    reaction(
      () => this.projectStore.isInitialized,
      () => {
        // Filter out inactive languages
        // ref https://github.com/sdesregistry/IH-Engage/issues/584
        this._languages = this.projectStore.project.languages.filter((language) =>
          ENGAGE_LANGUAGES.find((lang) => lang.languageCode === language && lang.available),
        );
        // console.log('LANGUAGES', this._languages);
      },
    );
  }

  get languages() {
    return this._languages;
  }

  set languages(val) {
    this._languages = val;
  }

  saveLanguages = () => {
    this.loading = true;
    this.error = null;
    return Agent.Projects.updateLanguages(this.projectStore.project._id, { languages: this._languages })
      .then(() => {
        this.rootStore.toastrStore.success('Saved project languages');

        const currentLang = this._languages.find((lang) => lang === Agent.getLanguage());
        if (!currentLang) {
          // Sets language to default language ('en') and refreshes project
          this.projectStore.changeLanguage(Agent.DEFAULT_LANGUAGE);
        } else {
          this.projectStore.getById(this.projectStore.project._id);
        }
      })
      .catch((err) => {
        this.error = err;
        this.rootStore.toastrStore.error('Error saving project languages', null, err);
      })
      .finally(() => (this.loading = false));
  };
}
