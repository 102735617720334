import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Condition from '../condition/Condition';
import RuleFooter from './RuleFooter';
import DestinationSelector from '../DestinationSelector';

function Rule(props) {
  const {
    ruleIndex,
    rule,
    rules,
    formValues,
    choiceInputType,
    question,
    questions,
    questionChoices,
    onAddCondition,
    onRemoveCondition,
    onAddRule,
    onRemoveRule,
  } = props;
  return (
    <Fragment>
      <div className='c-conditional__rule' data-testid='rule-wrapper'>
        {rule.condition.map((condition, conditionIndex) => (
          <Condition
            key={condition.id}
            condition={condition}
            choiceInputType={choiceInputType}
            conditionIndex={conditionIndex}
            questionChoices={questionChoices}
            rule={rule}
            onAddCondition={onAddCondition}
            onRemoveCondition={onRemoveCondition}
            questionType={question.questionType}
            formValues={formValues}
          />
        ))}
        <DestinationSelector
          fieldName={`${question._id}-RID_${rule.id}-jumpTo`}
          allowNull={false}
          formValues={formValues}
          question={question}
          questions={questions}
          titleLabelStrong='Then'
          titleLabel2='jump to question'
          hasSelectOption={true}
          hasEndOfPollOption={true}
          markSelectedDestinations={true}
          isRequired={true}
        />
      </div>
      <RuleFooter ruleIndex={ruleIndex} rule={rule} rules={rules} onAddRule={onAddRule} onRemoveRule={onRemoveRule} />
    </Fragment>
  );
}

Rule.propTypes = {
  ruleIndex: PropTypes.number.isRequired,
  rule: PropTypes.object.isRequired,
  rules: PropTypes.array.isRequired,
  formValues: PropTypes.object.isRequired,
  choiceInputType: PropTypes.string.isRequired,
  question: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  questionChoices: PropTypes.array.isRequired,
  onAddCondition: PropTypes.func.isRequired,
  onRemoveCondition: PropTypes.func.isRequired,
  onAddRule: PropTypes.func.isRequired,
  onRemoveRule: PropTypes.func.isRequired,
};

export default Rule;
