import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from 'common/components/SvgIcon';

function FilterSearch(props) {
  const { searchValue, onFilterChange, placeholder } = props;

  function filterChangeHandler(e) {
    onFilterChange(e.target.value);
  }

  function clearFilterHandler() {
    onFilterChange('');
  }

  return (
    <div className='c-field c-field--label-hidden' data-testid='filter-search-wrapper'>
      <label className='c-field__label' htmlFor='filter-input'>
        Filter
      </label>
      <div className='c-field__group'>
        <div className='o-input-icon o-input-clear'>
          <input
            type='text'
            id='filter-input'
            placeholder={placeholder}
            value={searchValue}
            onChange={filterChangeHandler}
          />
          <SvgIcon icon='search' />
          {searchValue?.length > 0 && (
            <button className='o-button' onClick={clearFilterHandler}>
              <SvgIcon icon='times' />
              <span className='o-label'>Clear</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

FilterSearch.propTypes = {
  searchValue: PropTypes.string.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default FilterSearch;
