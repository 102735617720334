import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ConditionalLogicUtil, { CONDITIONAL_EQUALITY, NO_CHOICES_OPTION } from '../../../ConditionalLogicUtil';
import EngageSelectInputField from '../../form-elements/selectInputField/SelectInputField';

function ChoiceConditionBlock(props) {
  const { condition, questionChoices = [] } = props;

  function validator(value) {
    return !value || value === -1 ? 'Required' : undefined;
  }

  function prepareChoices() {
    if (questionChoices.length > 0) return questionChoices;

    return [NO_CHOICES_OPTION, ...questionChoices];
  }

  return (
    <Fragment>
      <EngageSelectInputField
        choices={CONDITIONAL_EQUALITY}
        id={condition.criteria}
        fieldName={condition.criteria}
        firstOptionEmpty={false}
      />

      <EngageSelectInputField
        choices={prepareChoices()}
        id={condition.choice}
        fieldName={condition.choice}
        firstOptionEmpty={false}
        isRequired={true}
        validate={validator}
        parse={(value) => ConditionalLogicUtil.parseSelectedOption(value)}
      />
    </Fragment>
  );
}

ChoiceConditionBlock.propTypes = {
  condition: PropTypes.object,
  questionChoices: PropTypes.array,
};

export default ChoiceConditionBlock;
