import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Form } from 'react-final-form';

import ActionBar from '../../../../common/components/actionBar/ActionBar';
import EmbedTypePicker from './EmbedTypePicker';
import SimpleSelectInputField from '../../../../common/components/form-elements/selectInputField/SimpleSelectInputField';
import './Embed.css';
import EmbedPreview from './EmbedPreview';
import EmbedInlineOptions from './EmbedInlineOptions';
import EmbedPopupOptions from './EmbedPopupOptions';
import SvgIcon from '../../../../common/components/SvgIcon';
import {
  EMBED_INLINE,
  EMBED_POPUP,
  IFRAME_SOURCE_PLACEHOLDER,
  IFRAME_RESIZER_SCRIPT,
  IFRAME_RESIZER_LOG_SCRIPT,
  IFRAME_RESIZER_SCRIPT_PLACEHOLDER,
  IFRAME_RESIZER_LOG_SCRIPT_PLACEHOLDER,
  INLINE_EMBED_CODE_TEMPLATE,
  POPUP_EMBED_CODE_TEMPLATE,
  EXCLUDE_HEADER_AND_NAVIGATION_PARAM,
  DYNAMIC_HEIGHT,
  DYNAMIC_HEIGHT_VALUE_PLACEHOLDER,
  DEFAULT_POPUP_BUTTON_LABEL,
  DEFAULT_POPUP_BUTTON_BACKGROUND_COLOR,
  DEFAULT_POPUP_BUTTON_TEXT_COLOR,
  DEFAULT_POPUP_BUTTON_RADIUS,
  DEFAULT_HEIGHT_VALUE,
  POPUP_BUTTON_LABEL_PLACEHOLDER,
  POPUP_BUTTON_TEXT_COLOR_PLACEHOLDER,
  POPUP_BUTTON_BACKGROUND_COLOR_PLACEHOLDER,
  POPUP_BUTTON_RADIUS_PLACEHOLDER,
} from './EmbedEnums';
import ability, { SET, EMBED_START_HOME_SCREEN, EMBED_START_POLLS_SCREEN } from '../../../../common/ability';

const HOME_SCREEN = ['1', 'Home'];
const POLLS_SCREEN = ['2', 'Surveys'];

function Embed(props) {
  const { PollListStore, ProjectStore, ConfigStore, match } = props;

  const clientEnvironment = ConfigStore.config.clientPath;
  const projectSlug = ProjectStore.project.slug;
  const projectId = match.params.id;
  // const language = ProjectStore.language;

  const BASE_IFRAME_URL = `${clientEnvironment}/${projectSlug}`;

  const [embedType, setEmbedType] = useState(EMBED_POPUP);
  const [pollsList, setPollsList] = useState([]);
  const [embedCode, setEmbedCode] = useState('');
  const [iframeSource, setIframeSource] = useState(`${BASE_IFRAME_URL}/`);
  const [codeCopied, setCodeCopied] = useState(false);

  // Common embed type options
  const [startingScreenList, setStartingScreenList] = useState([]);
  const [startingScreen, setStartingScreen] = useState(HOME_SCREEN[0]);
  const [visibleHeaderAndNavigation, setVisibleHeaderAndNavigation] = useState(false);

  // Popup embed type options
  const [buttonLabel, setButtonLabel] = useState(DEFAULT_POPUP_BUTTON_LABEL);
  const [backgroundColor, setBackgroundColor] = useState(DEFAULT_POPUP_BUTTON_BACKGROUND_COLOR);
  const [textColor, setTextColor] = useState(DEFAULT_POPUP_BUTTON_TEXT_COLOR);
  const [borderRadius, setBorderRadius] = useState(DEFAULT_POPUP_BUTTON_RADIUS);

  // Inline embed type options
  const [dynamicHeight, setDynamicHeight] = useState(true);
  const [dynamicHeightValue, setDynamicHeightValue] = useState(DEFAULT_HEIGHT_VALUE);

  const embedCodeInput = useRef(null);

  useEffect(() => {
    let startingEmbedCode = POPUP_EMBED_CODE_TEMPLATE;
    const startingIframeSource = `${BASE_IFRAME_URL}?${EXCLUDE_HEADER_AND_NAVIGATION_PARAM}`;

    startingEmbedCode = startingEmbedCode.replace(
      POPUP_BUTTON_BACKGROUND_COLOR_PLACEHOLDER,
      DEFAULT_POPUP_BUTTON_BACKGROUND_COLOR,
    );
    startingEmbedCode = startingEmbedCode.replace(POPUP_BUTTON_TEXT_COLOR_PLACEHOLDER, DEFAULT_POPUP_BUTTON_TEXT_COLOR);
    startingEmbedCode = startingEmbedCode.replace(POPUP_BUTTON_RADIUS_PLACEHOLDER, DEFAULT_POPUP_BUTTON_RADIUS);
    startingEmbedCode = startingEmbedCode.replace(POPUP_BUTTON_LABEL_PLACEHOLDER, DEFAULT_POPUP_BUTTON_LABEL);
    startingEmbedCode = startingEmbedCode.replace(IFRAME_SOURCE_PLACEHOLDER, startingIframeSource);

    setEmbedCode(startingEmbedCode);
    setIframeSource(startingIframeSource);
  }, [BASE_IFRAME_URL]);

  useEffect(() => {
    if (PollListStore && projectId) {
      PollListStore.load(projectId).then((res) => {
        const baseList = [];
        if (ability.can(SET, EMBED_START_HOME_SCREEN)) baseList.push(HOME_SCREEN);
        if (ability.can(SET, EMBED_START_POLLS_SCREEN)) baseList.push(POLLS_SCREEN);

        const polls = res || [];
        setPollsList([...polls]);

        // eslint-disable-next-line no-shadow
        const startingScreenList = [...baseList.concat(polls.map((item) => [item._id, item.name]))];
        setStartingScreenList([...startingScreenList]);
      });
    }
  }, [PollListStore, projectId]);

  function startingScreenChangeHandler(event) {
    if (embedType === EMBED_POPUP) {
      // eslint-disable-next-line no-use-before-define
      setupPopUpEmbed(
        visibleHeaderAndNavigation,
        buttonLabel,
        backgroundColor,
        textColor,
        borderRadius,
        event.target.value,
      );
    } else {
      // eslint-disable-next-line no-use-before-define
      setupInlineEmbed(visibleHeaderAndNavigation, dynamicHeight, dynamicHeightValue, event.target.value);
    }
  }

  function setIframeUrlPath(screen) {
    let newIframeUrlPath = BASE_IFRAME_URL;

    if (screen === POLLS_SCREEN[0]) newIframeUrlPath = newIframeUrlPath.concat('/', 'polls');

    const selectedPoll = pollsList.find((poll) => poll._id === screen);
    if (selectedPoll) newIframeUrlPath = newIframeUrlPath.concat('/', 'polls').concat('/', selectedPoll._id);

    return newIframeUrlPath;
  }

  function setupPopUpEmbed(visibleHeaderAndNav, label, bckgColor, txtColor, borderRad, startingScrn) {
    setStartingScreen(startingScrn);
    setVisibleHeaderAndNavigation(visibleHeaderAndNav);
    setButtonLabel(label);
    setBackgroundColor(bckgColor);
    setTextColor(txtColor);
    setBorderRadius(borderRad);

    let newIframeUrl = setIframeUrlPath(startingScrn);
    let newEmbedCode = POPUP_EMBED_CODE_TEMPLATE;

    if (!visibleHeaderAndNav) newIframeUrl = newIframeUrl.concat('?', EXCLUDE_HEADER_AND_NAVIGATION_PARAM);

    newEmbedCode = newEmbedCode.replace(POPUP_BUTTON_LABEL_PLACEHOLDER, label);
    newEmbedCode = newEmbedCode.replace(POPUP_BUTTON_BACKGROUND_COLOR_PLACEHOLDER, bckgColor);
    newEmbedCode = newEmbedCode.replace(POPUP_BUTTON_TEXT_COLOR_PLACEHOLDER, txtColor);
    newEmbedCode = newEmbedCode.replace(POPUP_BUTTON_RADIUS_PLACEHOLDER, borderRad);

    newEmbedCode = newEmbedCode.replace(IFRAME_SOURCE_PLACEHOLDER, newIframeUrl);

    newEmbedCode.trim();

    setIframeSource(newIframeUrl);
    setEmbedCode(newEmbedCode);
  }

  function setupInlineEmbed(visibleHeaderAndNav, isDynamicHeight, dynamicHeightVal, startingScrn) {
    setStartingScreen(startingScrn);
    setVisibleHeaderAndNavigation(visibleHeaderAndNav);
    setDynamicHeight(isDynamicHeight);
    setDynamicHeightValue(dynamicHeightVal);

    let newEmbedCode = INLINE_EMBED_CODE_TEMPLATE;

    let newIframeUrl = setIframeUrlPath(startingScrn);

    if (!visibleHeaderAndNav) newIframeUrl = newIframeUrl.concat('?', EXCLUDE_HEADER_AND_NAVIGATION_PARAM);

    if (isDynamicHeight) {
      newEmbedCode = newEmbedCode.replace(IFRAME_RESIZER_SCRIPT_PLACEHOLDER, IFRAME_RESIZER_SCRIPT);
      newEmbedCode = newEmbedCode.replace(IFRAME_RESIZER_LOG_SCRIPT_PLACEHOLDER, IFRAME_RESIZER_LOG_SCRIPT);
      newEmbedCode = newEmbedCode.replace(DYNAMIC_HEIGHT_VALUE_PLACEHOLDER, DEFAULT_HEIGHT_VALUE);
      newIframeUrl = newIframeUrl.concat(`${!visibleHeaderAndNav ? '&' : '?'}`, DYNAMIC_HEIGHT);
    } else {
      newEmbedCode = newEmbedCode.replace(DYNAMIC_HEIGHT_VALUE_PLACEHOLDER, dynamicHeightVal);
      newEmbedCode = newEmbedCode.replace(IFRAME_RESIZER_SCRIPT_PLACEHOLDER, '');
      newEmbedCode = newEmbedCode.replace(IFRAME_RESIZER_LOG_SCRIPT_PLACEHOLDER, '');
    }

    newEmbedCode = newEmbedCode.replace(IFRAME_SOURCE_PLACEHOLDER, newIframeUrl);

    newEmbedCode.trim();

    setIframeSource(newIframeUrl);
    setEmbedCode(newEmbedCode);
  }

  function embedTypeHandler(value) {
    setEmbedType(value);

    if (value === EMBED_POPUP) {
      setupPopUpEmbed(
        visibleHeaderAndNavigation,
        buttonLabel,
        backgroundColor,
        textColor,
        borderRadius,
        startingScreen,
      );
    } else {
      setupInlineEmbed(visibleHeaderAndNavigation, dynamicHeight, dynamicHeightValue, startingScreen);
    }
  }

  function copyHandler(value) {
    embedCodeInput.current.select();
    document.execCommand('copy');
    embedCodeInput.current.selectionStart = embedCodeInput.current.selectionEnd;

    setCodeCopied(true);

    setTimeout(() => {
      setCodeCopied(false);
    }, 2500);
  }

  function downloadHandler(value) {
    let startScreenName = '';

    if (startingScreen === EMBED_START_HOME_SCREEN[0]) startScreenName = EMBED_START_HOME_SCREEN[1];
    if (startingScreen === EMBED_START_POLLS_SCREEN[0]) startScreenName = EMBED_START_POLLS_SCREEN[1];

    const selectedPoll = pollsList.find((poll) => poll._id === startingScreen);
    if (selectedPoll) startScreenName = selectedPoll.name;

    const element = document.createElement('a');
    element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(embedCode)}`);
    element.setAttribute(
      'download',
      `${ProjectStore.project.projectName}_${startScreenName}_${embedType === EMBED_POPUP ? 'popup' : 'inline'}.html`,
    );

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  return (
    <div className='l-main'>
      <ActionBar addNew={null} label='Embed' hasButton={false} />

      <Form
        onSubmit={() => {}}
        render={({ values, form, handleSubmit, submitting, pristine }) => {
          return (
            <form className='c-fields l-form' onSubmit={handleSubmit}>
              <EmbedTypePicker value={embedType} setValue={embedTypeHandler} />

              <SimpleSelectInputField
                input={{ value: startingScreen, onChange: (event) => startingScreenChangeHandler(event) }}
                choices={startingScreenList}
                label='Starting screen'
                firstOptionEmpty={false}
              />
              <React.Fragment>
                {embedType === EMBED_POPUP ? (
                  <EmbedPopupOptions
                    startingScreen={startingScreen}
                    visibleHeaderAndNavigation={visibleHeaderAndNavigation}
                    buttonLabel={buttonLabel}
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                    borderRadius={borderRadius}
                    onPopupOptionsChange={setupPopUpEmbed}
                  />
                ) : null}
                {embedType === EMBED_INLINE ? (
                  <EmbedInlineOptions
                    startingScreen={startingScreen}
                    visibleHeaderAndNavigation={visibleHeaderAndNavigation}
                    dynamicHeight={dynamicHeight}
                    dynamicHeightValue={dynamicHeightValue}
                    onInlineOptionsChange={setupInlineEmbed}
                  />
                ) : null}

                <EmbedPreview
                  embedType={embedType}
                  iframeSource={iframeSource}
                  buttonLabel={buttonLabel}
                  backgroundColor={backgroundColor}
                  textColor={textColor}
                  borderRadius={borderRadius}
                />

                <div className='c-field l-form__item'>
                  <label className='c-field__label' htmlFor='input-embed-code'>
                    Embed code
                  </label>
                  <div className='c-field__group'>
                    <div className='o-textarea o-textarea--code'>
                      <textarea
                        id='input-embed-code'
                        ref={embedCodeInput}
                        value={embedCode}
                        onChange={() => {}}
                        placeholder='Button label'
                        readOnly={true}
                      />
                    </div>
                  </div>
                </div>

                <div className='c-field c-field--action l-form__item'>
                  <div className='c-field__group'>
                    <button
                      className={`o-button ${codeCopied ? 'o-button--success' : 'o-button--primary'}`}
                      type='button'
                      onClick={copyHandler}
                    >
                      {codeCopied && <SvgIcon icon='check' />}
                      <span className='o-label'>{`${codeCopied ? 'Code copied' : 'Copy code'}`}</span>
                    </button>
                    <button className='o-button o-button--alternate' type='button' onClick={downloadHandler}>
                      <span className='o-label'>Download code</span>
                    </button>
                  </div>
                </div>
              </React.Fragment>
            </form>
          );
        }}
      />
    </div>
  );
}

Embed.propTypes = {
  PollListStore: PropTypes.object.isRequired,
  ProjectStore: PropTypes.object.isRequired,
  ConfigStore: PropTypes.object.isRequired,
};

export default inject((root) => ({
  PollListStore: root.RootStore.pollsListStore,
  ProjectStore: root.RootStore.projectStore,
  ConfigStore: root.RootStore.configStore,
}))(observer(Embed));
