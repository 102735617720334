import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';
import ImageUtils, { REACTION_PNG_IMAGE_TYPE_STATIC_MAP } from '../../ImageUtils';
import GOOGLE_MAP_ENUMS from '../../../shared/engage/engage-google-map-enums';
import POLL_ENUMS from '../../../shared/engage/engage-poll-enums';
import { IMAGE_FORMATS } from '../form-elements/fileUpload/ImageFormatEnums';
import DateUtils from '../../DateUtils';
import { DefaultValueEnums } from '../../../shared/engage';

const staticMapTemplate = GOOGLE_MAP_ENUMS().staticMapTemplate;
const staticMapParams = GOOGLE_MAP_ENUMS().staticMapParams;

function getResource(isAnnotationQuestion, resource) {
  if (!isAnnotationQuestion) return null;

  return resource || DefaultValueEnums().DELETED_ANNOTATE_REACTION;
}

function PollReportFeedbackListItem(props) {
  const { feedback, feedbackMeta, displayLocation } = props;
  const {
    isHighlighted,
    timestamp,
    attachment,
    resource,
    resourceLabel,
    language,
    email,
    postcode,
    isDeleted,
    questionType,
  } = feedback;

  const [tooltipMessage, setTooltipMessage] = useState('');
  const [showLocation, setShowLocation] = useState(displayLocation);
  const [isMapLocation, setIsMapLocation] = useState(false);
  const [mapUrl, setMapUrl] = useState();
  const [imageMarker, setImageMarker] = useState();
  const [imageId, setImageId] = useState();

  const feedbackImageId =
    attachment && attachment.length > 0 ? attachment.filter((item) => item.mediaType === 'image')[0]._id : '';

  const isAnnotationQuestion = POLL_ENUMS().isAnnotationQuestion(feedback.questionType);
  const imageUploadAllowed = feedbackMeta?.questionConfiguration?.allowUserContentUpload?.image;

  // In case reaction was removed from annotate question and resource and label values were permanently deleted
  const reactionResource = getResource(isAnnotationQuestion, resource);
  const reactionLabel = resourceLabel || '';

  useEffect(() => {
    if (feedback && feedbackMeta) {
      if (feedback.questionType === POLL_ENUMS().QuestionTypes.ANNOTATION_IMAGE) {
        const marker = {};
        marker.left = feedback.pin.imagePin.x;
        marker.top = feedback.pin.imagePin.y;
        marker.resource = reactionResource;

        setImageMarker(marker);
        setImageId(feedbackMeta.resource);
        setIsMapLocation(false);
        if (feedbackMeta?.questionConfiguration?.allowUserContentUpload?.image && feedback?.attachment?.length > 0)
          setTooltipMessage('Click to show uploaded image');
      } else if (feedback.questionType === POLL_ENUMS().QuestionTypes.ANNOTATION_MAP) {
        let staticMapTemplateUrl = staticMapTemplate.replace(staticMapParams.LATITUDE, feedback.pin.mapPin.lat);
        staticMapTemplateUrl = staticMapTemplateUrl.replace(staticMapParams.LONGITUDE, feedback.pin.mapPin.lng);
        staticMapTemplateUrl = staticMapTemplateUrl.replace(
          staticMapParams.MARKER_ICON,
          ImageUtils.pngImageUrl(reactionResource, REACTION_PNG_IMAGE_TYPE_STATIC_MAP),
        );
        setMapUrl(staticMapTemplateUrl);
        setIsMapLocation(true);
        if (feedbackMeta?.questionConfiguration?.allowUserContentUpload?.image && feedback?.attachment?.length > 0)
          setTooltipMessage('Click to show uploaded image');
      } else {
        setTooltipMessage('');
      }
    }
  }, [feedback, feedbackMeta, reactionResource]);

  useEffect(() => {
    setShowLocation(displayLocation);
    if (!displayLocation) {
      setTooltipMessage('Click to show feedback location');
    } else if (feedbackImageId) {
      setTooltipMessage('Click to show uploaded image');
    } else {
      setTooltipMessage('');
    }
  }, [displayLocation, feedbackImageId]);

  function feedbackClickHandler() {
    if (!feedbackImageId) {
      if (showLocation) return;

      setTooltipMessage('');
      setShowLocation(!showLocation);
      return;
    }

    if (isAnnotationQuestion && imageUploadAllowed) {
      if (showLocation) {
        setTooltipMessage('Click to show feedback location');
      } else {
        setTooltipMessage('Click to show uploaded image');
      }

      setShowLocation(!showLocation);
    }
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      title={tooltipMessage}
      className='c-browse-feedback__item'
      onClick={feedbackClickHandler}
      data-testid='feedback-list-item-wrapper'
    >
      <div
        className={`c-feedback c-feedback--browse c-feedback--media c-feedback--location ${
          isHighlighted ? 'c-feedback--highlight' : ''
        }`}
      >
        <div className='c-feedback__header'>
          {timestamp && <span className='c-feedback__time'>{DateUtils.getTimeDateString(timestamp)}</span>}
          {email && <strong className='c-feedback__author'>{email}</strong>}
          {postcode && <span className='c-feedback__time'>{postcode}</span>}
          {reactionResource && (
            <span className='c-feedback__reaction'>
              <img
                src={`${ImageUtils.pngImageUrl(reactionResource, IMAGE_FORMATS.REACTION.imageType)}`}
                alt={reactionLabel}
              />
              {reactionLabel}
              {isDeleted && <strong className='o-tag'>Deleted</strong>}
            </span>
          )}
          {language && <span className='c-feedback__language'>{language}</span>}
        </div>
        <div className='c-feedback__content'>
          <div className='c-feedback__media'>
            {/* eslint-disable-next-line no-nested-ternary */}
            {showLocation ? (
              <div className='c-feedback__location'>
                {isMapLocation ? (
                  <img src={mapUrl} alt='' />
                ) : (
                  <div
                    className='c-annotate-image__group'
                    style={{
                      backgroundImage: `url(${ImageUtils.imageUrl(imageId, IMAGE_FORMATS.REACTION_IMAGE.imageType)})`,
                    }}
                  >
                    {imageId && (
                      <img src={`${ImageUtils.imageUrl(imageId, IMAGE_FORMATS.REACTION_IMAGE.imageType)}`} alt='' />
                    )}
                    {imageMarker && (
                      <div
                        className='c-annotate-image__reaction'
                        style={{
                          left: `${imageMarker.left}%`,
                          top: `${imageMarker.top}%`,
                          transform: `scale(1)`,
                        }}
                      >
                        <img
                          src={`${ImageUtils.pngImageUrl(imageMarker.resource, IMAGE_FORMATS.REACTION.imageType)}`}
                          alt=''
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : feedbackImageId ? (
              <img src={ImageUtils.feedbackImageUrl(feedbackImageId)} alt='' />
            ) : null}
          </div>
          <p>{feedback.feedback.text}</p>
        </div>
      </div>
    </div>
  );
}

PollReportFeedbackListItem.propTypes = {
  feedback: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    isHighlighted: PropTypes.bool,
    timestamp: PropTypes.string,
    attachment: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        mediaType: PropTypes.string,
      }),
    ).isRequired,
    feedback: PropTypes.shape({
      _id: PropTypes.string,
      text: PropTypes.string,
    }).isRequired,
    pin: PropTypes.shape({
      imagePin: PropTypes.shape({
        imagePin: PropTypes.number,
        text: PropTypes.number,
      }),
      mapPin: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
      }),
    }),
  }).isRequired,
  feedbackMeta: PropTypes.shape({
    resource: PropTypes.string,
  }),
};

export default PollReportFeedbackListItem;
