import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Can } from 'common/Can';
import { VIEW } from 'common/ability';
import { DEMOGRAPHICS } from 'common/constants/RouteConstants';
import SurveysVisibilityInfo from './SurveysVisibilityInfo';
import DemographicVisibility from './DemographicVisibility';
import SurveyVisibility from './SurveyVisibility';

function SurveySection(props) {
  const { Store, projectId } = props;

  const polls = Store?.publishStatus?.polls || [];

  return (
    <form
      className='c-fields c-fields--modern l-form __web-inspector-hide-shortcut__'
      data-testid='survey-section-wrapper'
    >
      <h2 className='c-heading-section c-heading-section--large'>Surveys</h2>

      <SurveysVisibilityInfo hasPolls={Store.hasPolls} projectId={projectId} />

      {polls?.length > 0 && <SurveyVisibility projectId={projectId} />}

      <Can I={VIEW} a={DEMOGRAPHICS}>
        <DemographicVisibility projectId={projectId} />
      </Can>
    </form>
  );
}

SurveySection.propTypes = {
  Store: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
};

export default inject((root) => ({
  Store: root.RootStore.projectControlStore,
}))(observer(SurveySection));
