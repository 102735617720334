import React, { useEffect, useState, createContext } from 'react';
import PropTypes from 'prop-types';
import html2canvas from 'html2canvas';
import { inject, observer } from 'mobx-react';
import ActionBar from '../../../../common/components/actionBar/ActionBar';
import SimpleCheckboxInputField from '../../../../common/components/form-elements/checkboxInputField/SimpleCheckboxInputField';
// eslint-disable-next-line import/no-cycle
import DemographicPreview from './DemographicPreview';
import SvgIcon from '../../../../common/components/SvgIcon';
import RangePickerInputField from '../../../../common/components/form-elements/datePicker/SimpleRangePickerInputField';

export const DemographicReportContext = createContext();

function DemographicsReport(props) {
  const { Store, GeneralStore, ToastrStore, ProjectStore, match } = props;

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [compareActive, setCompareActive] = useState();
  const [exportCounter, setExportCounter] = useState(1);
  const [questionReferences, setQuestionReferences] = useState([]);

  const projectId = match.params.id;
  const report = Store ? Store.demographicsReport : { questions: [] };

  useEffect(() => {
    if (Store && projectId) {
      Store.reset(projectId);

      Store.loadDemographicsReport(projectId);
    }
  }, [Store, projectId]);

  function rangeChangeHandler(rangeFrom, rangeTo) {
    setFromDate(rangeFrom);
    setToDate(rangeTo);

    setQuestionReferences([]);
    Store.loadDemographicsReport(projectId, rangeFrom, rangeTo);
  }

  async function createSnapshot(reference, name, index) {
    setExportCounter(index + 1);
    return html2canvas(reference, {
      useCORS: true,
      scrollX: -8,
      scrollY: -window.scrollY,
      removeContainer: true,
    })
      .then((canvas) => {
        const a = document.createElement('a');
        a.href = canvas.toDataURL('image/png');
        a.download = `${name}-${index + 1}.png`;
        a.click();
      })
      .catch();
  }

  async function createSnapshots() {
    setExportCounter(1);
    GeneralStore.isSnapshotting = true;
    setTimeout(async () => {
      try {
        const exportSet = [];
        report.questions.forEach((question) => {
          const matchingRef = questionReferences.find((refer) => refer.id === question.questionId);
          if (matchingRef) exportSet.push(matchingRef);
        });
        for (let i = 0; i < exportSet.length; i++) {
          // eslint-disable-next-line no-await-in-loop
          await createSnapshot(exportSet[i].containerReference, `${ProjectStore.project.projectName}-demographic`, i);
        }
      } catch (error) {
        ToastrStore.error('Exporting demographics report failed!', null, error);
      } finally {
        GeneralStore.isSnapshotting = false;
      }
    }, 1000);
  }

  function addExportReference(newReference) {
    const existingReference = questionReferences.find((reference) => reference.id === newReference.id);
    if (!existingReference) {
      questionReferences.push({ id: newReference.id, containerReference: newReference.containerReference });
    } else {
      existingReference.containerReference = newReference.containerReference;
    }
  }

  return (
    <div className='l-main'>
      <ActionBar label='Demographics' hasButton={false} />
      <RangePickerInputField
        startDate={fromDate}
        endDate={toDate}
        onRangeChange={rangeChangeHandler}
        fieldClass='l-form__item--l'
        showPredefinedRanges={true}
      />
      <form className='c-fields l-form'>
        <SimpleCheckboxInputField
          id='compare-across-demographic-averages'
          type='checkbox'
          input={{ checked: compareActive, onChange: (event) => setCompareActive(event.target.checked) }}
          label='Compare across average'
          isToggle={true}
          checkedLabel='Active'
          uncheckedLabel='Inactive'
          hideLabel={false}
        />
      </form>
      <div className='c-poll-preview'>
        <div className='c-poll-preview__actions'>
          <div className='c-poll-preview__label'>{`${report.questions.length} questions`}</div>
          <button
            className={`o-button o-button--naked o-button--s c-poll-preview__snapshot o-tooltip o-tooltip--button o-tooltip--bottom-center ${
              GeneralStore.isSnapshotting ? 'is-disabled' : ''
            }`}
            onClick={createSnapshots}
            data-testid='snapshot-action-button'
            disabled={GeneralStore.isSnapshotting}
          >
            <span className='o-label'>
              {GeneralStore.isSnapshotting
                ? `Generating ${exportCounter} of ${report.questions.length}`
                : 'Capture snapshots'}
            </span>
            {!GeneralStore.isSnapshotting && <SvgIcon icon='image' />}
            <span className='o-tooltip__description'>
              Generate an image of each question. Images will begin downloading once the capture is complete.
            </span>
          </button>
        </div>
        <div className='c-poll-preview__items'>
          {report.questions.map((question) => {
            return (
              <DemographicReportContext.Provider
                key={question.questionId}
                value={{
                  addReference: (reference) => addExportReference(reference),
                }}
              >
                <DemographicPreview
                  key={question._id}
                  question={question}
                  fromDate={fromDate}
                  toDate={toDate}
                  compareActive={compareActive}
                />
              </DemographicReportContext.Provider>
            );
          })}
        </div>
      </div>
    </div>
  );
}

DemographicsReport.propTypes = {
  Store: PropTypes.object.isRequired,
  ProjectStore: PropTypes.object.isRequired,
  GeneralStore: PropTypes.object.isRequired,
  ToastrStore: PropTypes.object.isRequired,
};

export default inject((root) => ({
  Store: root.RootStore.demographicsReportStore,
  ProjectStore: root.RootStore.projectStore,
  GeneralStore: root.RootStore.generalStore,
  ToastrStore: root.RootStore.toastrStore,
}))(observer(DemographicsReport));
