import Agent from './agent';

const requests = Agent.requests;
const ENDPOINT = 'feedback';

const FeedbackAgent = {
  // get feedback items
  get: (projectId, query) => requests.get(`/projects/${projectId}/${ENDPOINT}`, query),
  setHighlight: (projectId, feedbackId, isHighlight) =>
    requests.patch(`/projects/${projectId}/${ENDPOINT}/${feedbackId}/highlight`, { highlight: isHighlight }),
  remove: (projectId, feedbackId) => requests.remove(`/projects/${projectId}/${ENDPOINT}/${feedbackId}`),
  reply: (projectId, feedbackId, reply) =>
    requests.patch(`/projects/${projectId}/${ENDPOINT}/${feedbackId}/answer`, reply),
};

export default FeedbackAgent;
