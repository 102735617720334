import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SettingsToggle from './SettingsToggle';

function SettingsOption(props) {
  const {
    id,
    title,
    description,
    isActive,
    onChange,
    toggleLabelOn,
    toggleLabelOff = toggleLabelOn,
    toggleTag = '',
    toggleGoToActionUrl = '',
    toggleGoToActionLabel = '',
    toggleGoToActionIcon = '',
    isDisabled = false,
  } = props;

  return (
    <div className='c-field c-field--modern c-field--border l-form__item' data-testid={`setting-option-wrapper-${id}`}>
      <label className='c-field__label'>{title}</label>
      <div className='c-field__group'>
        <SettingsToggle
          id={id}
          labelOn={toggleLabelOn}
          labelOff={toggleLabelOff}
          tag={toggleTag}
          goToActionUrl={toggleGoToActionUrl}
          goToActionLabel={toggleGoToActionLabel}
          goToActionIcon={toggleGoToActionIcon}
          isActive={isActive}
          onChange={onChange}
          isDisabled={isDisabled}
        />
        {description && (
          <div className='c-message is-note-subtle c-message--large'>
            {/* eslint-disable-next-line react/no-danger */}
            <p className='c-message__description' dangerouslySetInnerHTML={{ __html: description || '' }} />
          </div>
        )}
      </div>
    </div>
  );
}

SettingsOption.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isActive: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  toggleLabelOn: PropTypes.string,
  toggleLabelOff: PropTypes.string,
  toggleTag: PropTypes.string,
  toggleGoToActionUrl: PropTypes.string,
  toggleGoToActionLabel: PropTypes.string,
  toggleGoToActionIcon: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default SettingsOption;
