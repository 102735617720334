import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'common/components/tooltip/Tooltip';
import SvgIcon from '../../SvgIcon';

export default function SimpleCheckboxInput(props) {
  const { classModifier, isToggle, input, id, checked, label, tooltip, disabled = false } = props;
  return (
    <div
      className={`o-checkbox ${isToggle ? 'o-checkbox--toggle' : ''} ${classModifier} ${disabled ? 'is-disabled' : ''}`}
    >
      <input {...input} id={id} type='checkbox' checked={checked || input.checked} disabled={disabled} />
      <label htmlFor={id}>
        {label}
        <SvgIcon icon='check' />
        {tooltip && <Tooltip {...tooltip} />}
      </label>
    </div>
  );
}

SimpleCheckboxInput.propTypes = {
  id: PropTypes.string.isRequired,
  classModifier: PropTypes.string,
  isToggle: PropTypes.bool,
  input: PropTypes.object,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  checkboxLabelTooltip: PropTypes.object,
  disabled: PropTypes.bool,
};
