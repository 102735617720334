import { action, observable, computed, toJS, makeObservable } from 'mobx';

import AgentPoll from '../common/agent';

const Agent = AgentPoll.OverviewAgent;

const ERROR_LOADING = 'Error loading project status';

export default class OverviewStore {
  _loading = false;

  _initialized = false;

  _error = null;

  _projectId = null;

  _publishStatus = {
    polls: [],
    isProjectActive: false,
    projectInformationHasUpdates: false,
    projectCustomizeHasUpdates: false,
  };

  _projectOverview = {
    polls: {},
    questions: {},
    facts: {},
    languages: {},
    news: {},
    timeline: {},
    influenced_decisions: {},
    charities: {},
  };

  constructor(rootStore) {
    makeObservable(this, {
      _projectOverview: observable,
      _publishStatus: observable,

      _loading: observable,
      _initialized: observable,
      _error: observable,

      resetFlags: action,
      loadData: action,
      loadStatusData: action,

      isLoading: computed,
      isInitialized: computed,
      error: computed,

      projectOverview: computed,
      publishStatus: computed,
      nrUpdates: computed,
      hasUpdates: computed,
      daysSinceFirstPublish: computed,
    });

    this.rootStore = rootStore;
  }

  get isLoading() {
    return this._loading;
  }

  get isInitialized() {
    return this._initialized;
  }

  get error() {
    return this._error;
  }

  get projectOverview() {
    return this._projectOverview;
  }

  get publishStatus() {
    return this._publishStatus;
  }

  get hasUpdates() {
    return (
      this._publishStatus.projectInformationHasUpdates ||
      this._publishStatus.projectCustomizeHasUpdates ||
      this._publishStatus.polls.find((poll) => poll.hasUpdates)
    );
  }

  get daysSinceFirstPublish() {
    const start = new Date(this._projectOverview.firstPublishDate);
    const now = new Date();
    const days = Math.round((now - start) / (1000 * 60 * 60 * 24));
    return days;
  }

  get nrUpdates() {
    const status = this._projectOverview;
    const nrChanges =
      status.polls.nrChanged +
      status.questions.nrChanged +
      status.facts.nrChanged +
      status.languages.nrChanged +
      status.news.nrChanged +
      status.timeline.nrPhasesChanged +
      status.timeline.nrUpdatesChanged +
      status.influenced_decisions.nrChanged +
      status.charities.nrChanged;
    return nrChanges;
  }

  /**
   * Loads publish status for a given project GET {{URL}}/projects/{{projectId}}/publish-status
   */
  getOverview = (projectId) => {
    // console.log('Load Project publish status', projectId);
    this._loading = true;
    this._error = null;
    this._projectId = projectId;
    return Agent.getProjectOverview(projectId)
      .then((res) => this.loadData(res, projectId))
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(ERROR_LOADING, null, err);
      })
      .finally(this.resetFlags);
  };

  /**
   * Loads publish status for a given project GET {{URL}}/projects/{{projectId}}/publish-status
   */
  getStatus = (projectId) => {
    // console.log('Load Project publish status', projectId);
    this._loading = true;
    this._error = null;
    this._projectId = projectId;
    return Agent.getStatus(projectId)
      .then((res) => this.loadStatusData(res, projectId))
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(ERROR_LOADING, null, err);
      })
      .finally(this.resetFlags);
  };

  loadData(overview, projectId) {
    this._projectOverview = overview.data;
    this._initialized = true;
    this._projectId = projectId;
    // console.log('Loading overview', toJS(this._projectOverview));
  }

  loadStatusData(status, projectId) {
    this._publishStatus = status.data;
    this._initialized = true;
    this._projectId = projectId;
    // console.log('Loading status', toJS(this._publishStatus));
  }

  resetFlags = () => {
    this._loading = false;
  };

  refresh = () => this.getStatus(this._projectId);
}
