import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Form } from 'react-final-form';

import SelectInputField from '../../../../common/components/form-elements/selectInputField/SelectInputField';
import SwitchableButtonBar from '../../../../common/components/switchableButtonBar/SwitchableButtonBar';
import PollReportFilter, { FILTER_GROUPS } from '../../../../common/components/pollReportFilter/PollReportFilter';
import { VIEW } from '../../../../common/ability';
import { SEGMENTATION } from '../../../../common/constants/RouteConstants';
import { Can } from '../../../../common/Can';

function PollSegmentation(props) {
  const {
    Store,
    polls,
    segmentationQuestions,
    demographicQuestions,
    pollQuestions,
    fromDate,
    toDate,
    onPollSelectionChange,
    onUpdate,
    updateEnabled,
    selectedPollId,
  } = props;

  const [initValues, setInitValues] = useState();
  const [demographicSelectedFilter, setDemographicSelectedFilter] = useState();
  const [segmentationSelectedFilter, setSegmentationSelectedFilter] = useState();
  const [pollSelectedFilter, setPollSelectedFilter] = useState();
  const [filterOptions, setFilterOptions] = useState([]);
  const [updateFilter, setUpdateFilter] = useState(true);

  useEffect(() => {
    const options = [];
    if (segmentationQuestions?.length)
      options.push({ icon: 'poll-segment', label: FILTER_GROUPS.SEGMENTATION.label, questions: segmentationQuestions });
    if (demographicQuestions?.length)
      options.push({ icon: 'user', label: FILTER_GROUPS.DEMOGRAPHIC.label, questions: demographicQuestions });
    if (pollQuestions?.length)
      options.push({ icon: 'poll', label: FILTER_GROUPS.POLL.label, questions: pollQuestions });

    setFilterOptions(options);
    setUpdateFilter(true);
  }, [segmentationQuestions, demographicQuestions, pollQuestions]);

  useEffect(() => {
    // Filter options data changes when date range or selected poll changes. This change requiries update of selected filter.
    if (
      updateFilter &&
      filterOptions &&
      (demographicSelectedFilter || segmentationSelectedFilter || pollSelectedFilter)
    ) {
      setUpdateFilter(false);

      filterOptions.forEach((option) => {
        let demographicFilterQuestion = null;
        if (demographicSelectedFilter)
          demographicFilterQuestion = option.questions.find(
            (question) => question._id === demographicSelectedFilter.question._id,
          );

        let segmentationFilterQuestion = null;
        if (segmentationSelectedFilter)
          segmentationFilterQuestion = option.questions.find(
            (question) => question._id === segmentationSelectedFilter.question._id,
          );

        let pollFilterQuestion = null;
        if (pollSelectedFilter)
          pollFilterQuestion = option.questions.find((question) => question._id === pollSelectedFilter.question._id);

        if (demographicFilterQuestion)
          setDemographicSelectedFilter({ ...demographicSelectedFilter, question: demographicFilterQuestion });

        if (segmentationFilterQuestion)
          setSegmentationSelectedFilter({ ...segmentationSelectedFilter, question: segmentationFilterQuestion });

        if (pollFilterQuestion) setPollSelectedFilter({ ...pollSelectedFilter, question: pollFilterQuestion });
      });
    }
  }, [filterOptions, updateFilter, demographicSelectedFilter, segmentationSelectedFilter, pollSelectedFilter]);

  const prepareInitValues = useCallback((pollId, demographicPollFilter, segmentationPollFilter, pollFilter) => {
    const formInitialValues = {
      pollId,
    };

    if (demographicPollFilter) formInitialValues.demographicPollFilter = demographicPollFilter;
    if (segmentationPollFilter) formInitialValues.segmentationPollFilter = segmentationPollFilter;
    if (pollFilter) formInitialValues.pollFilter = pollFilter;

    setInitValues(formInitialValues);
  }, []);

  useEffect(() => {
    if (selectedPollId) prepareInitValues(selectedPollId);
  }, [selectedPollId, prepareInitValues]);

  // eslint-disable-next-line no-shadow
  const pollsAdapter = (polls) => {
    if (!polls) {
      return [];
    }
    return polls.map((poll) => {
      let name = poll.name ? poll.name : 'No title';
      if (poll.status === 'active') {
        name += ' [ACTIVE]';
      }
      return [poll._id, name];
    });
  };
  const pollSelectList = pollsAdapter(polls);

  function getSegmentationQuestionForChoice(choiceId) {
    let targetQuestion;
    segmentationQuestions.forEach((item) => {
      if (item.choices.find((choice) => choice._id === choiceId)) targetQuestion = item;
    });

    return targetQuestion;
  }

  function getDemographicQuestionForChoice(choiceId) {
    let targetQuestion;
    demographicQuestions.forEach((item) => {
      if (item.choices.find((choice) => choice._id === choiceId)) targetQuestion = item;
    });

    return targetQuestion;
  }

  function getPollQuestionForChoice(choiceId) {
    let targetQuestion;
    pollQuestions.forEach((item) => {
      if (item.choices.find((choice) => choice._id === choiceId)) targetQuestion = item;
    });

    return targetQuestion;
  }

  const onSubmit = (formValues) => {
    const segmentationId = formValues.segmentationPollFilter || null;
    const demographicId = formValues.demographicPollFilter || null;
    const pollId = formValues.pollFilter || null;

    Store.loadPollsReport(Store.projectId, formValues.pollId, segmentationId, demographicId, pollId, fromDate, toDate);

    const filterData = {};
    if (segmentationId)
      filterData.segmentation = { selectedChoiceId: segmentationId, question: segmentationSelectedFilter };
    if (demographicId)
      filterData.demographic = { selectedChoiceId: demographicId, question: demographicSelectedFilter };
    if (pollId) filterData.conditional = { selectedChoiceId: pollId, question: pollSelectedFilter };

    onUpdate(filterData);
  };

  function filterSelectedHandle(formValues, filterChoiceId) {
    let demographicFilterChoiceId;
    let segmentationFilterChoiceId;
    let pollFilterChoiceId;

    const chosenSegment = getSegmentationQuestionForChoice(filterChoiceId);
    if (chosenSegment) {
      setSegmentationSelectedFilter({ group: FILTER_GROUPS.SEGMENTATION, question: chosenSegment });
      segmentationFilterChoiceId = filterChoiceId;
      demographicFilterChoiceId = formValues.demographicPollFilter;
      pollFilterChoiceId = formValues.pollFilter;
    }

    const chosenDemographic = getDemographicQuestionForChoice(filterChoiceId);
    if (chosenDemographic) {
      setDemographicSelectedFilter({ group: FILTER_GROUPS.DEMOGRAPHIC, question: chosenDemographic });
      segmentationFilterChoiceId = formValues.segmentationPollFilter;
      demographicFilterChoiceId = filterChoiceId;
      pollFilterChoiceId = formValues.pollFilter;
    }

    const chosenPoll = getPollQuestionForChoice(filterChoiceId);
    if (chosenPoll) {
      setPollSelectedFilter({ group: FILTER_GROUPS.POLL, question: chosenPoll });
      segmentationFilterChoiceId = formValues.segmentationPollFilter;
      demographicFilterChoiceId = formValues.demographicPollFilter;
      pollFilterChoiceId = filterChoiceId;
    }

    // In case no poll id is provided that means first item on the list is selectedd
    let pId = formValues.pollId;
    if (!formValues.pollId && pollSelectList?.length) pId = pollSelectList[0][0];

    // Set form initial values (poll id and selected filter choice id)
    prepareInitValues(pId, demographicFilterChoiceId, segmentationFilterChoiceId, pollFilterChoiceId);
  }

  function clearFilterHandler(formValues, filterType) {
    if (filterType === FILTER_GROUPS.DEMOGRAPHIC.id) {
      const segmentationFilterChoiceId = formValues.segmentationPollFilter;
      const pollFilterChoiceId = formValues.pollFilter;
      prepareInitValues(formValues.pollId, null, segmentationFilterChoiceId, pollFilterChoiceId);
      setDemographicSelectedFilter(null);
    } else if (filterType === FILTER_GROUPS.SEGMENTATION.id) {
      const demographicFilterChoiceId = formValues.demographicPollFilter;
      const pollFilterChoiceId = formValues.pollFilter;
      prepareInitValues(formValues.pollId, demographicFilterChoiceId, null, pollFilterChoiceId);
      setSegmentationSelectedFilter(null);
    } else {
      const segmentationFilterChoiceId = formValues.segmentationPollFilter;
      const demographicFilterChoiceId = formValues.demographicPollFilter;
      prepareInitValues(formValues.pollId, demographicFilterChoiceId, segmentationFilterChoiceId, null);
      setPollSelectedFilter(null);
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initValues}
      render={({ values, handleSubmit }) => {
        return (
          <form className='c-fields l-form' action=''>
            <SelectInputField
              fieldName='pollId'
              choices={pollSelectList}
              label='Select survey'
              firstOptionEmpty={false}
              onSelectionChange={onPollSelectionChange}
            />

            <Can I={VIEW} a={SEGMENTATION}>
              <PollReportFilter
                demographicFieldName='demographicPollFilter'
                segmentationFieldName='segmentationPollFilter'
                pollFieldName='pollFilter'
                formValues={values}
                demographicSelectedFilter={demographicSelectedFilter}
                segmentationSelectedFilter={segmentationSelectedFilter}
                pollSelectedFilter={pollSelectedFilter}
                filterOptions={filterOptions}
                onFilterSelected={(choiceId) => filterSelectedHandle(values, choiceId)}
                onClearFilter={(filterType) => clearFilterHandler(values, filterType)}
              />
            </Can>

            <SwitchableButtonBar
              updateAction={handleSubmit}
              removeAction={null}
              isNew={false}
              updateEnabled={updateEnabled}
            />
          </form>
        );
      }}
    />
  );
}

PollSegmentation.propTypes = {
  Store: PropTypes.object.isRequired,
  polls: PropTypes.array,
  segmentationQuestions: PropTypes.array,
  demographicQuestions: PropTypes.array,
  fromDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  toDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onUpdate: PropTypes.func,
  onPollSelectionChange: PropTypes.func,
  updateEnabled: PropTypes.bool,
};

export default inject((root) => ({ Store: root.RootStore.pollsReportStore }))(observer(PollSegmentation));
