import React, { useState, useEffect, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import html2canvas from 'html2canvas';

import { REPORTS_GENERATE } from '../../../../common/constants/RouteConstants';
import ActionBar from '../../../../common/components/actionBar/ActionBar';
import StatBlock from './StatBlock';
import StatGraphBlock from './StatGraphBlock';
import StatEffectivenessBlock from './StatsEffectivenessBlock';
import RangePickerInputField from '../../../../common/components/form-elements/datePicker/SimpleRangePickerInputField';
import CheckboxToggleControl from '../../../../common/components/checkboxToggleControl/CheckboxToggleControl';
import NumberUtils from '../../../../common/NumberUtils';
import SvgIcon from '../../../../common/components/SvgIcon';
import SegmentationStat from './SegmentationStat';
import DateUtils from '../../../../common/DateUtils';
import ReportUtils from '../../../../common/ReportUtils';
import { charityPerc, percNewUsers, timeStats, devicesStats, secsToMinSecs, getTooltips } from './dashboardUtils';

function Dashboard(props) {
  const { Store, GeneralStore, ToastrStore, match, history } = props;
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [showGA4, setShowGA4] = useState(true);
  const [showLegacyEffectivness, setShowLegacyEffectivness] = useState(false);
  const snapshotContainer = useRef(null);

  const projectId = match.params.id;
  // const overview = Store.overviewReport;
  const overview = Store.fetchReport(showGA4);
  const overviewPolls = overview ? overview.polls : {};
  const languageStats = ReportUtils.prepareLanguageStats(overview?.languageStats);
  const isLegacyPoints = overview.meta.legacyFlags && overview.meta.legacyFlags.isLegacyPoints;
  const segmentation = overview.segmentation.segmentation.find((seg) => seg.current);
  const { abbrBD, abbrGA } = getTooltips(showGA4);

  /**
   * Switches between new and legacy charity points and filters only active charities
   */
  function getCharities() {
    // eslint-disable-next-line no-shadow
    const charities = isLegacyPoints ? overview.charitiesLegacy : overview.charities;
    // Change of hart - show all charities (including inactive ones, but excluding "archived" ones - we use "enabled" flag)
    return (charities || []).filter((ch) => ch.enabled);
    // return charities || [];
  }

  const charities = getCharities();
  const showCharities = charities && charities.length > 0;
  const charitiesTotalPoints = charities.reduce((acc, ch) => {
    return acc + ch.points;
  }, 0);

  useEffect(() => {
    Store.loadOverviewReport(projectId);
  }, [Store, projectId]);

  function rangeChangeHandler(rangeFrom, rangeTo) {
    setFromDate(rangeFrom);
    setToDate(rangeTo);

    Store.loadOverviewReport(projectId, rangeFrom, rangeTo);
  }

  function toggleShowAdvanced(val) {
    setShowAdvanced(val);
  }
  function toggleShowGA4(val) {
    setShowGA4(val);
  }
  function toggleShowLegacyEffectivness(val) {
    setShowLegacyEffectivness(val);
  }

  function periodLabel(timestampFrom, timestampTo) {
    const label = `${DateUtils.getShortDateString(timestampFrom) || ' '}  \u2014
        ${DateUtils.getShortDateString(timestampTo) || ' '}`;

    return label;
  }

  function doSnapshot() {
    GeneralStore.isSnapshotting = true;

    setTimeout(() => {
      html2canvas(snapshotContainer.current, {
        useCORS: true,
        scrollX: -8,
        scrollY: -window.scrollY,
        removeContainer: true,
      })
        .then((canvas) => {
          const a = document.createElement('a');
          a.href = canvas.toDataURL('image/png');
          a.download = overview.meta.projectName ? `${overview.meta.projectName}.png` : 'Overview Report.png';
          a.click();
        })
        .catch((error) => {
          ToastrStore.error('Exporting overview report failed!', null, error);
        })
        .finally((GeneralStore.isSnapshotting = false));
    }, 1000);
  }

  return (
    <div className='l-main'>
      <ActionBar
        addNew={() => history.push(REPORTS_GENERATE)}
        label='Reports'
        hasButton={false}
        buttonLabel='Generate'
      />
      <div className='c-fields l-form l-form--single-row'>
        <RangePickerInputField
          startDate={fromDate}
          endDate={toDate}
          onRangeChange={rangeChangeHandler}
          fieldClass='l-form__item--l'
          showPredefinedRanges={true}
        />
        <div className='c-field c-field--action l-form__item l-form__item--xs'>
          <div className='c-field__group'>
            <button className='o-button o-button--block' type='submit' onClick={doSnapshot}>
              <SvgIcon icon='image' />
              <span className='o-label'>Snapshot</span>
            </button>
          </div>
        </div>
      </div>

      <div className='c-fields l-form l-form--single-row'>
        <div className='c-field c-field--action l-form__item l-form__item '>
          <div>
            * Google stopped accepting Universal Analytics (UA / GA3) data in favor of GA4 on October 5th 2023. For
            older projects you can visualize the UA data by toggling the switch. This will display the archived UA data.
            <strong> Date filters are NOT applied against archived UA data.</strong>
          </div>
        </div>
        <div className='c-field c-field--action l-form__item l-form__item--xs '>
          <CheckboxToggleControl
            id='checkbox-ga4'
            initialValue={showGA4}
            onChangeCallback={toggleShowGA4}
            labelOn='Show UA data'
            labelOff='Show GA4 data'
            classModifier='o-checkbox--s'
          />
        </div>
      </div>
      {overview.meta.isFromCache && (
        <div className='c-message'>
          <p className='c-message__description'>
            Overview report generated on <strong>{DateUtils.getDateTimeString(overview.meta.generatedAt)}</strong>.
            Report stats are refreshed every 5 minutes.
          </p>
        </div>
      )}

      <div className='c-report-overview'>
        {/* HEADER */}
        <div className='c-report-overview__toggle '>
          <CheckboxToggleControl
            id='checkbox-block-intro'
            initialValue={showAdvanced}
            onChangeCallback={toggleShowAdvanced}
            labelOn='Advanced'
            classModifier='o-checkbox--s'
          />
        </div>
        <div className='c-report-overview__snapshot' ref={snapshotContainer}>
          <div className='c-report-overview__header'>
            <h2 className='c-report-overview__title' contentEditable='true' suppressContentEditableWarning={true}>
              {overview.meta.projectName}
            </h2>
            <p className='c-report-overview__date'>
              {periodLabel(overview.meta.timestampFrom, overview.meta.timestampTo)}
            </p>
          </div>
          {/* BODY */}
          <div className='c-report-overview__body'>
            {/* USERS */}
            <h2 className='c-heading-section c-heading-section--separator'>
              <span>Users</span>
            </h2>
            <div className='c-stat-list l-stat-list l-stat-list--three'>
              <StatBlock
                title='Total visits'
                highlight={NumberUtils.formatIntWithSeparator(overview.users.totalVisitsGA) || '-'}
                description='visits to the project'
                icon='users'
                abbr={abbrGA}
              />
              <StatBlock
                title='Voters'
                highlight={NumberUtils.formatIntWithSeparator(overview.users.totalVoters) || '-'}
                description='number of people voted'
                icon='user'
              />
              <StatBlock
                title='Votes'
                highlight={NumberUtils.formatIntWithSeparator(overview.users.totalVotes) || '-'}
                description='questions answered'
                icon='check'
              />
              <StatBlock
                title='Emails'
                highlight={NumberUtils.formatIntWithSeparator(overview.users.totalUniqueEmails) || '-'}
                description='unique emails from voters leaving open feedback'
                icon='envelope'
              />
              <StatBlock
                title='Qualitative answers'
                highlight={NumberUtils.formatIntWithSeparator(overview.users.totalFeedbacks) || '-'}
                description='pieces of written feedback'
                icon='pen-alt'
              />
              {overview.meta.hasAnnotate && (
                <StatBlock
                  title='Annotated reactions'
                  highlight={NumberUtils.formatIntWithSeparator(overview.users.totalReactions) || '-'}
                  description='number of reaction left on all the project annotate questions'
                  icon='poll-annotation-map'
                />
              )}
            </div>

            {/* POLLS */}
            <h2 className='c-heading-section c-heading-section--separator'>
              <span>Surveys</span>
            </h2>
            <div className='c-stat-list l-stat-list l-stat-list--three'>
              {overviewPolls.polls &&
                overviewPolls.polls
                  .filter((poll) => poll.nrVoters > 0)
                  .map((poll, idx) => (
                    <StatBlock
                      key={idx}
                      title={poll.name}
                      highlight={NumberUtils.formatIntWithSeparator(poll.nrVoters) || '-'}
                      description='voters'
                      icon='poll'
                    />
                  ))}
            </div>

            {/* SEGMENTATION */}
            {segmentation && <SegmentationStat question={segmentation} />}

            {/* ADVANCED SECTION */}
            {showAdvanced && (
              <div>
                {/* USER DETAILS */}
                <h2 className='c-heading-section c-heading-section--separator'>
                  <span>User details</span>
                </h2>
                <div className='c-stat-list l-stat-list l-stat-list--three'>
                  <StatGraphBlock
                    title='Users'
                    icon='users'
                    abbr={abbrGA}
                    stats={percNewUsers(overview.users.percNewUsersGA)}
                  />
                  <StatGraphBlock
                    title='Devices'
                    icon='phone-laptop'
                    abbr={abbrGA}
                    stats={devicesStats(overview.users.devicesGA)}
                  />
                  <StatGraphBlock
                    title='Time breakdown'
                    icon='clock'
                    abbr={abbrGA}
                    stats={timeStats(overview.users.timeBreakdownGA)}
                  />
                  <StatBlock
                    title='Average time'
                    highlight={`${secsToMinSecs(overview.users.averageTimeGA)} min` || '-'}
                    description='spent in the app by each user'
                    icon='clock'
                    abbr={abbrGA}
                  />
                </div>

                {/* POLL EFFECTIVENESS */}
                <div>
                  <h2 className='c-heading-section c-heading-section--separator'>
                    <span>Survey effectiveness</span>
                  </h2>
                  <div className='c-stat-list'>
                    <CheckboxToggleControl
                      id='checkbox-legacy-effectivness'
                      initialValue={showLegacyEffectivness}
                      onChangeCallback={toggleShowLegacyEffectivness}
                      labelOn='Show legacy data'
                      classModifier='o-checkbox--s'
                    />
                  </div>

                  <div className='c-stat-list l-stat-list l-stat-list--three'>
                    {overviewPolls.polls &&
                      overviewPolls.polls
                        .filter((poll) => poll.nrVoters > 0)
                        .map((poll, idx) => <StatEffectivenessBlock key={idx} pollStats={poll} icon='poll' />)}
                  </div>
                  {showLegacyEffectivness && (
                    <Fragment>
                      <h2 className='c-heading-section c-heading-section--separator'>
                        <span>Legacy survey effectiveness</span>
                      </h2>
                      <div className='c-stat-list l-stat-list l-stat-list--three'>
                        {overviewPolls.polls &&
                          overviewPolls.polls
                            .filter((poll) => poll.nrVoters > 0)
                            .map((poll, idx) => (
                              <StatEffectivenessBlock key={idx} pollStats={poll} icon='poll' isLegacy={true} />
                            ))}
                      </div>
                    </Fragment>
                  )}
                </div>

                {/* CHARITIES */}
                {showCharities && (
                  <React.Fragment>
                    <h2 className='c-heading-section c-heading-section--separator'>
                      <span>Charities {isLegacyPoints ? '*' : ''}</span>
                    </h2>
                    <div className='c-stat-list l-stat-list l-stat-list--three'>
                      {charities.map((charity, idx) => (
                        <StatBlock
                          key={idx}
                          title={`${charity.label}${!charity.isActive ? ' (Archived)' : ''}`}
                          highlight={charityPerc(
                            charity.points,
                            charitiesTotalPoints,
                            charities,
                            idx === charities.length - 1,
                          )}
                          description={`${NumberUtils.formatIntWithSeparator(charity.points)} points`}
                          icon='poll-coin'
                        />
                      ))}
                    </div>
                  </React.Fragment>
                )}

                {/* LANGUAGES */}
                {languageStats.length > 1 && (
                  <Fragment>
                    <h2 className='c-heading-section c-heading-section--separator'>
                      <span>Language overview</span>
                    </h2>
                    <div className='c-stat-list l-stat-list'>
                      <StatGraphBlock
                        title='Total languages'
                        icon='globe'
                        abbr={abbrBD}
                        stats={languageStats}
                        wrapperClass='l-stat--l'
                        showCount={true}
                      />
                    </div>
                  </Fragment>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

Dashboard.propTypes = {
  Store: PropTypes.object.isRequired,
  GeneralStore: PropTypes.object.isRequired,
  ToastrStore: PropTypes.object.isRequired,
};

export default inject((root) => ({
  Store: root.RootStore.overviewReportStore,
  GeneralStore: root.RootStore.generalStore,
  ToastrStore: root.RootStore.toastrStore,
}))(observer(Dashboard));
