import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import SvgIcon from 'common/components/SvgIcon';
import { POLLS, PROJECT } from 'common/constants/RouteConstants';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import HelpActionButton from 'common/components/help/HelpActionButton';
import { HELP } from 'shared/engage/engage-help-enums';

const DEFAULT_SURVEY_OPTION = [uuidv4(), 'Select a survey you wish to duplicate'];
const DEFAULT_PROJECT_OPTION = [uuidv4(), 'Select target project'];

function DuplicateSurvey(props) {
  const { ProjectStore, ProjectListStore, PollsListStore, ToolsStore } = props;

  const projectId = ProjectStore.project._id;

  const [availableProjects, setAvailableProjects] = useState([]);
  const [availableSurveys, setAvailableSurveys] = useState([]);
  const [targetSurvey, setTargetSurvey] = useState();
  const [targetProject, setTargetProject] = useState();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [surveysScreenUrl, setSurveysScreenUrl] = useState();

  useEffect(() => {
    if (!ProjectStore) return;

    Promise.all([
      PollsListStore.load(ProjectStore.project._id),
      ProjectListStore.loadWithClientId(ProjectStore.project._profileId),
    ]).then((data) => {
      const surveysData = data[0].map((survey) => [survey._id, survey.name]);
      setAvailableSurveys([DEFAULT_SURVEY_OPTION, ...surveysData]);

      const projectsData = data[1].map((project) => {
        let projectName = project.projectName;
        if (project._id === projectId) projectName = `(Current project) ${projectName} `;
        return [project._id, projectName];
      });
      setAvailableProjects([DEFAULT_PROJECT_OPTION, ...projectsData]);
    });
  }, [ProjectStore, ProjectListStore, PollsListStore, projectId]);

  function tagetSurveyChangeHandler(value) {
    setTargetSurvey(value);

    setShowSuccessMessage(false);
    setTargetProject(DEFAULT_PROJECT_OPTION[0]);
  }

  function tagetProjectChangeHandler(value) {
    setTargetProject(value);
  }

  function duplicateSurveyHandler() {
    ToolsStore.duplicateSurvey(targetSurvey, projectId, targetProject).then((res) => {
      if (!res) return;

      setSurveysScreenUrl(`/${PROJECT}/${res.destinationProjectId}/edit/${POLLS}`);
      setTargetSurvey(DEFAULT_SURVEY_OPTION[0]);
      setShowSuccessMessage(true);
    });
  }

  function getDescriptionMessage() {
    return showSuccessMessage ? (
      <div className='c-message c-message--large is-positive' data-testid='success-message-wrapper'>
        <p className='c-message__description'>
          <strong>Survey has been duplicated!</strong> You can see all your surveys in the{' '}
          <Link to={surveysScreenUrl}>surveys screen</Link>.
        </p>
      </div>
    ) : (
      <div
        className={`c-message is-warning-strong c-message--large ${targetSurvey && targetProject ? '' : 'is-hidden'}`}
        data-testid='warning-message-wrapper'
      >
        <p className='c-message__description'>
          <strong>Important!</strong> Please make sure you are duplicating a correct survey into a correct project.
        </p>
      </div>
    );
  }

  return (
    <div className='c-fields c-fields--modern l-form' data-testid='duplicate-survey-wrapper'>
      <h2 className='c-heading-section c-heading-section--large'>
        <HelpActionButton helpKey={HELP.PROJECT.SETTINGS.TOOLS.DUPLICATE_SURVEY.TITLE} isTextOnly={true} />
        Duplicate surveys
      </h2>

      <div className='c-message is-note-subtle c-message--large'>
        <p className='c-message__description'>
          You are about to duplicate an entire survey. The new survey will contain the same questions, but{' '}
          <strong>responses from the original survey will not be copied</strong>.
        </p>
      </div>
      <div className='c-field c-field--modern c-field--large l-form__item'>
        <div className='c-field__group'>
          <div className='o-select-group' data-testid='target-survey-select'>
            <div className='o-select'>
              <SvgIcon icon='angle-down' />
              <select
                id='survey-duplication-selector'
                value={targetSurvey}
                onChange={(event) => tagetSurveyChangeHandler(event.target.value)}
              >
                {availableSurveys.map((type, index) => (
                  <option key={index} value={type[0]} disabled={index === 0} selected={index === 0}>
                    {type[1]}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div
            className={`o-select-group ${targetSurvey && targetSurvey !== DEFAULT_SURVEY_OPTION[0] ? '' : 'is-hidden'}`}
            data-testid='target-project-select'
          >
            <div className='o-select'>
              <SvgIcon icon='angle-down' />
              <select
                id='survey-duplication-project-selector'
                value={targetProject}
                onChange={(event) => tagetProjectChangeHandler(event.target.value)}
              >
                {availableProjects.map((type, index) => (
                  <option key={index} value={type[0]} disabled={index === 0} selected={index === 0}>
                    {type[1]}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {getDescriptionMessage()}

          <div className='c-field c-field--action'>
            <div className='c-field__group'>
              <button
                className={`o-button o-button--primary ${
                  targetSurvey &&
                  targetSurvey !== DEFAULT_SURVEY_OPTION[0] &&
                  targetProject &&
                  targetProject !== DEFAULT_PROJECT_OPTION[0]
                    ? ''
                    : 'is-disabled'
                }`}
                id='duplicate-survey'
                onClick={duplicateSurveyHandler}
              >
                <span className='o-label'>Duplicate survey</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

DuplicateSurvey.propTypes = {
  ProjectStore: PropTypes.object.isRequired,
  ProjectListStore: PropTypes.object.isRequired,
  PollsListStore: PropTypes.object.isRequired,
  ToolsStore: PropTypes.object.isRequired,
};

export default inject((root) => ({
  ProjectStore: root.RootStore.projectStore,
  ProjectListStore: root.RootStore.projectListStore,
  PollsListStore: root.RootStore.pollsListStore,
  ToolsStore: root.RootStore.toolsStore,
}))(observer(DuplicateSurvey));
