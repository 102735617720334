import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Form } from 'react-final-form';

import SwitchableButtonBar from '../../../../../common/components/switchableButtonBar/SwitchableButtonBar';
import SlimFileUpload from '../../../../../common/components/form-elements/fileUpload/SlimFileUpload';
import { IMAGE_FORMATS } from '../../../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import EngageMarkdownInputField, {
  MARKDOWN_MIN_PLUS,
} from '../../../../../common/components/form-elements/markdownInputField/MarkdownInputField';
import { getFormFieldClass, getFormFieldTranslationTooltip } from '../../../../../common/TranslationUtils';
import ConditionalLogic from '../../../../../common/components/conditionalLogic/ConditionalLogic';
import ConditionalLogicUtil from '../../../../../common/ConditionalLogicUtil';

function TwoImageFact(props) {
  const {
    question,
    updateQuestion,
    removeQuestion,
    uploadImage,
    removeImage,
    openPollPreview,
    showConditionalLogic,
    Store,
  } = props;

  const [formValues, setFormValues] = useState({});
  const [rules, setRules] = useState([]);

  const imageId = question?.resource?.length > 0 ? question.resource[0] : null;
  const image2Id = question?.resource?.length > 1 ? question.resource[1] : null;
  const defaultImageId = question?.defaultData?.resource?.length > 0 ? question.defaultData.resource[0] : null;
  const defaultImage2Id = question?.defaultData?.resource?.length > 1 ? question.defaultData.resource[1] : null;

  useEffect(() => {
    if (!question) return;

    const initialFormQuestionValues = {
      questionText: question.questionText,
    };

    const initialState = ConditionalLogicUtil.setupFormInitialValues(question, initialFormQuestionValues);
    setFormValues({ ...initialFormQuestionValues, ...initialState.initialFormConditionValues });
    setRules(initialState.initialRules);
  }, [question]);

  const onSubmit = (values) => {
    const updatedQuestionData = toJS(question);

    // Question content
    updatedQuestionData.questionText = values.questionText;

    ConditionalLogicUtil.extractConditionalFormValues(question, updatedQuestionData, values, rules);

    updateQuestion(updatedQuestionData);
  };

  return (
    <Form
      onSubmit={onSubmit}
      keepDirtyOnReinitialize={true}
      initialValues={formValues}
      render={({ values, handleSubmit }) => {
        return (
          <form className='c-fields l-form' action=''>
            <EngageMarkdownInputField
              id={`input-question-${question._id}`}
              placeholder='Keep description short'
              label='Fact'
              maxLength='200'
              fieldDescription=''
              fieldName='questionText'
              isRequired={true}
              editorConfig={MARKDOWN_MIN_PLUS}
              fieldClass={getFormFieldClass(question.defaultData.questionText, null, 'l-form__item')}
              tooltipInLabel={getFormFieldTranslationTooltip(question.defaultData.questionText)}
            />
            <div className='c-toggle-content'>
              <div className='c-toggle-content__container'>
                <ConditionalLogic
                  formValues={values}
                  setFormValues={setFormValues}
                  question={question}
                  questions={Store.poll.questions}
                  rules={rules}
                  setRules={setRules}
                  show={showConditionalLogic}
                  choiceFieldNames={[]}
                />
                <div className='c-toggle-content__content' hidden={showConditionalLogic}>
                  <div className='l-form'>
                    <SlimFileUpload
                      imageFormat={IMAGE_FORMATS.SQUARE_BIG}
                      imageId={imageId}
                      defaultImageId={defaultImageId}
                      id={`${question._id}-1`}
                      handleUpload={uploadImage}
                      label='Bottom image'
                      removeImage={removeImage}
                      classModifier={getFormFieldClass(imageId, defaultImageId, 'l-form__item--m c-upload--fill')}
                      tooltipInLabel={getFormFieldTranslationTooltip(null, defaultImageId, IMAGE_FORMATS.SQUARE_BIG)}
                    />

                    <SlimFileUpload
                      imageFormat={IMAGE_FORMATS.SQUARE_BIG}
                      imageId={image2Id}
                      defaultImageId={defaultImage2Id}
                      id={`${question._id}-2`}
                      handleUpload={uploadImage}
                      label='Top image'
                      removeImage={removeImage}
                      classModifier={getFormFieldClass(imageId, defaultImage2Id, 'l-form__item--m c-upload--fill')}
                      tooltipInLabel={getFormFieldTranslationTooltip(null, defaultImage2Id, IMAGE_FORMATS.SQUARE_BIG)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <SwitchableButtonBar
              updateAction={handleSubmit}
              removeAction={removeQuestion}
              isNew={!question._id}
              previewAction={() => openPollPreview(question._id)}
            />
          </form>
        );
      }}
    />
  );
}

TwoImageFact.propTypes = {
  question: PropTypes.object.isRequired,
  updateQuestion: PropTypes.func.isRequired,
  removeQuestion: PropTypes.func.isRequired,
  uploadImage: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired,
  openPollPreview: PropTypes.func.isRequired,
  showConditionalLogic: PropTypes.bool.isRequired,
  Store: PropTypes.object.isRequired,
};

export default observer(TwoImageFact);
