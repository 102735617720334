import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { SortableContainer } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

import ActionBar from '../../../common/components/actionBar/ActionBar';
import BlockItem from './BlockItem';

function BlockContainer(props) {
  const { items, onChange, allowDrag } = props;
  return (
    <div className='c-field l-form__item'>
      <div className='c-field__group'>
        <div className='c-reorder-list c-reorder-list--content'>
          {items.map((item, index) => (
            <BlockItem key={index} index={index} item={item} onChange={onChange} disabled={!allowDrag} />
          ))}
        </div>
      </div>
    </div>
  );
}

function Blocks(props) {
  const { BlockStore } = props;

  const [allowDrag, setAllowDrag] = useState(true);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setAllowDrag(false);
    const newOrder = arrayMoveImmutable(BlockStore.blocks, oldIndex, newIndex);
    BlockStore.update(newOrder).then(() => {
      setAllowDrag(true);
    });
  };

  const onChange = (event, itemName) => {
    BlockStore.toggleCheckItem(itemName, event.target.checked);
  };

  return (
    <div className='l-main'>
      <ActionBar hasButton={false} label='Homepage Blocks' />
      <p>Reorder the following blocks on the homepage. Hide ones that are not relevant at the moment.</p>
      <SortableList
        items={BlockStore.blocks}
        onChange={onChange}
        onSortEnd={onSortEnd}
        useDragHandle={true}
        allowDrag={allowDrag}
      />
      <button className='o-button o-button--alternate o-button--xs' onClick={() => BlockStore.reset()}>
        <div className='o-label'>Reset order</div>
      </button>
    </div>
  );
}

export default inject((root) => ({ BlockStore: root.RootStore.blockStore }))(observer(Blocks));

const SortableList = SortableContainer((props) => {
  return <BlockContainer {...props} />;
});

Blocks.propTypes = {
  BlockStore: PropTypes.object.isRequired,
};
