// import { toJS } from 'mobx';
import DateUtils from './DateUtils';
import ImageUtils, { PNG_IMAGE_URL_PREFIX } from './ImageUtils';

const ENGAGE_LANGUAGES = require('../shared/engage/engage-languages')().engageLanguages();
const N_A = require('../shared/engage/engage-languages')().N_A;

// Sum of returned language percentage values must be 100
// Since we don't want to show decimal spaces on returned values this sum will not always be 100
// When sum is not 100 we need to redistribute difference (100-sum) evenly starting from first item on the list
function prepareLanguageStats(languageStats) {
  if (!languageStats?.length) return [];

  let total = 0;
  languageStats.forEach((stat) => {
    total += stat.count;
  });

  let percTotal = 0;
  const preparedLanguageStats = [];
  languageStats.forEach((stat, index) => {
    const lng = ENGAGE_LANGUAGES.find((item) => item.languageCode === stat.language);

    const perc = Math.round((stat.count / total) * 100);

    percTotal += perc;

    const lngLabel = lng?.languageTitleEN || N_A;

    // used to display number of resposes in specific language in multilanguage project
    const numValue = stat.count || 0;

    preparedLanguageStats.push({ label: lngLabel, value: perc, count: numValue });
  });

  let sumGap = 100 - percTotal;
  if (sumGap === 0) return preparedLanguageStats;

  // In case the percentages sum is not equal to 100 (difference can be negative or positive ) distribute sum difference starting from first item in list
  const finalizedLanguageStats = [];
  preparedLanguageStats.forEach((preparedLanguageStat) => {
    if (sumGap === 0) {
      finalizedLanguageStats.push(preparedLanguageStat);
      return;
    }

    if (sumGap > 0) {
      finalizedLanguageStats.push({
        label: preparedLanguageStat.label,
        value: preparedLanguageStat.value + 1,
        count: preparedLanguageStat.count,
      });
      sumGap--;
    } else {
      finalizedLanguageStats.push({
        label: preparedLanguageStat.label,
        value: preparedLanguageStat.value - 1,
        count: preparedLanguageStat.count,
      });
      sumGap++;
    }
  });

  return finalizedLanguageStats;
}

function prepareMapMarkerFeedbackInfoWindow(feedback) {
  // console.log(toJS(feedback));
  // This is same HTML structure we have on feedback item inside browse feedback modal, but it looks lite it needs tweeking
  // Sax LOL - no apparent problem with css, but here you used className and should be class since it is HTML (not React/JSX markup)
  const emailNode = feedback.email ? `<strong class='c-feedback__author'>${feedback.email}</strong>` : '';

  let timestampNode = '';
  if (feedback.timestamp) {
    timestampNode = `<span class='c-feedback__time'>${DateUtils.getTimeDateString(feedback.timestamp)}</span>`;
  }

  const lng = ENGAGE_LANGUAGES.find((item) => item.languageCode === feedback.language);
  const languageNode = lng?.languageTitleEN ? `<span class='c-feedback__language'>${lng?.languageTitleEN}</span>` : '';

  let imageNode = '';
  if (feedback?.attachment?._id) {
    imageNode = `<div class='c-feedback__media' style='width:300px'>
        <img src=${ImageUtils.feedbackImageUrl(feedback?.attachment?._id)} alt=''/>
      </div>`;
  }

  const reactionNode = `<span class="c-feedback__reaction">
            <img src="${PNG_IMAGE_URL_PREFIX}/${feedback.resource}-gmv-emoji-l_144x144.png" alt="Biodiversity">
            ${feedback.label}
            </span>`;

  const textNode = feedback.feedbackText ? `<p>${feedback.feedbackText}</p>` : '';

  const infoWindowContentString = `<div class='c-info-window'>
  <div class='c-feedback c-feedback--browse'>
    <div class='c-feedback__header'>
      ${timestampNode}
      ${emailNode}
      ${reactionNode}
      ${languageNode}
    </div>
    <div class='c-feedback__content'>
      ${imageNode}
      ${textNode}
  </div>
  </div>
  </div>`;

  return infoWindowContentString;
}

export default {
  prepareLanguageStats,
  prepareMapMarkerFeedbackInfoWindow,
};
