import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import ProjectCard from './ProjectCard';

function ProjectList(props) {
  const { projects } = props;

  return (
    <div className='l-grid l-grid--thirds' data-testid='projects-list-wrapper'>
      {projects.length > 0 && projects.map((project, index) => <ProjectCard key={project._id} project={project} />)}
    </div>
  );
}

ProjectList.propTypes = {
  projects: PropTypes.array.isRequired,
};

export default ProjectList;
