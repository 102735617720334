import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from 'common/components/SvgIcon';
import { LanguageDataCompletness } from 'common/constants/GeneralConstants';

function UpdateDetailsButton(props) {
  const { updateStatus, toggleUpdateDetailsVisible } = props;

  const STATUS_TO_CLASS = {
    [LanguageDataCompletness.ALL_COMPLETE]: 'is-success',
    [LanguageDataCompletness.DEFAULT_ONLY_COMPLETE]: 'is-warning',
    [LanguageDataCompletness.DEFAULT_INCOMPLETE]: 'is-danger',
  };

  return (
    <button
      className={`o-button o-button--ellipsis ${STATUS_TO_CLASS[updateStatus]}`}
      type='button'
      title='Language status'
      onClick={toggleUpdateDetailsVisible}
    >
      <span className='o-label'>Status</span>
      <SvgIcon icon='ellipsis-h' />
    </button>
  );
}

UpdateDetailsButton.propTypes = {
  updateStatus: PropTypes.string.isRequired,
  toggleUpdateDetailsVisible: PropTypes.func.isRequired,
};

export default UpdateDetailsButton;
