import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form } from 'react-final-form';
import { toJS } from 'mobx';
import EngageTextInputField from '../../../../../common/components/form-elements/textInputField/TextInputField';
import EngageSelectInputField from '../../../../../common/components/form-elements/selectInputField/SelectInputField';
import SwitchableButtonBar from '../../../../../common/components/switchableButtonBar/SwitchableButtonBar';
import { IMAGE_FORMATS } from '../../../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import SvgIcon from '../../../../../common/components/SvgIcon';
import { getFormFieldClass, getFormFieldTranslationTooltip } from '../../../../../common/TranslationUtils';
import ConditionalLogic from '../../../../../common/components/conditionalLogic/ConditionalLogic';
import ConditionalLogicUtil from '../../../../../common/ConditionalLogicUtil';
import LeadImage from '../../../../../common/components/form-elements/leadImage/LeadImage';
import { LEAD_IMAGE_TYPES } from '../../../../../shared/engage/engage-question-constants';

export const QUESTION_CHARACTER_LIMIT = '80';
export const IMAGE_CHARACTER_LIMIT = '30';

const CHOICES = [
  [1, '1 answer'],
  [2, '2 answers'],
  [3, '3 answers'],
  [4, '4 answers'],
  [5, '5 answers'],
];

function QuickText(props) {
  const {
    question,
    updateQuestion,
    removeQuestion,
    removeMedia,
    uploadMedia,
    uploadVideo,
    openPollPreview,
    showConditionalLogic,
    Store,
  } = props;

  const { _id, resource, defaultData } = question;

  const [showImage, setShowImage] = useState(resource?.length > 0);
  const [formValues, setFormValues] = useState({});
  const [rules, setRules] = useState([]);

  const leadImage = resource?.length > 0 ? resource[0] : null;
  const defaultLeadImage = defaultData?.resource?.length > 0 ? defaultData.resource[0] : null;

  useEffect(() => {
    if (!question) return;

    const initialFormQuestionValues = {
      questionText: question.questionText,
      multiChoicesOfferedBound: question.multiChoicesOfferedBound,
      leadImageType: question.questionConfiguration?.leadImageType || LEAD_IMAGE_TYPES.STANDARD_SINGLE_IMAGE,
    };

    const initialState = ConditionalLogicUtil.setupFormInitialValues(question, initialFormQuestionValues);
    setFormValues({ ...initialFormQuestionValues, ...initialState.initialFormConditionValues });
    setRules(initialState.initialRules);
  }, [question]);

  const onSubmit = (values) => {
    const updatedQuestionData = toJS(question);
    // Question content
    updatedQuestionData.questionText = values.questionText;
    updatedQuestionData.multiChoicesOfferedBound = values.multiChoicesOfferedBound;
    updatedQuestionData.questionConfiguration.leadImageType = values.leadImageType;

    ConditionalLogicUtil.extractConditionalFormValues(question, updatedQuestionData, values, rules);

    updateQuestion(updatedQuestionData);
  };

  return (
    <Form
      onSubmit={onSubmit}
      keepDirtyOnReinitialize={true}
      initialValues={formValues}
      render={({ values, handleSubmit }) => {
        return (
          <form className='c-fields l-form' onSubmit={(event) => event.preventDefault()}>
            <EngageTextInputField
              id={`input-question-${_id}`}
              placeholder='Enter question'
              label='Question'
              maxLength={QUESTION_CHARACTER_LIMIT}
              fieldDescription=''
              fieldName='questionText'
              isRequired={true}
              fieldClass={getFormFieldClass(question.defaultData.questionText)}
              tooltipInLabel={getFormFieldTranslationTooltip(question.defaultData.questionText)}
            />

            <div className='c-toggle-content'>
              <div className='c-toggle-content__container'>
                <ConditionalLogic
                  formValues={values}
                  setFormValues={setFormValues}
                  question={question}
                  questions={Store.poll.questions}
                  rules={rules}
                  setRules={setRules}
                  show={showConditionalLogic}
                  choiceFieldNames={[]}
                />
                <div className='c-toggle-content__content' hidden={showConditionalLogic}>
                  <div className={getFormFieldClass(leadImage, defaultLeadImage, 'c-field l-form__item')}>
                    {!showImage && !defaultData?.resource?.length && (
                      <label className='c-field__label'>Lead image</label>
                    )}
                    <div className='c-field__group'>
                      {resource?.length > 0 || defaultData?.resource?.length > 0 || showImage ? (
                        <LeadImage
                          questionId={_id}
                          resource={resource}
                          defaultResource={defaultData?.resource}
                          store={Store}
                          label='Lead image'
                          uploadImage={uploadMedia}
                          uploadVideo={uploadVideo}
                          removeMedia={removeMedia}
                          fieldName='leadImageType'
                          leadImageType={values.leadImageType}
                          classModifier={getFormFieldClass(null, defaultData.resource)}
                          tooltipInLabel={getFormFieldTranslationTooltip(
                            null,
                            defaultData.resource,
                            IMAGE_FORMATS.FLUID_PANEL,
                          )}
                        />
                      ) : (
                        <p className='c-field__description'>
                          {!leadImage && !defaultData?.resource?.length && (
                            <Fragment>
                              <button
                                className='o-button o-button--link'
                                type='button'
                                onClick={() => setShowImage(true)}
                                data-testid='action-tooltip-button'
                              >
                                <SvgIcon icon='image' dataTestId='action-tooltip-button-icon' />
                                <span className='o-label' data-testid='action-tooltip-button-label'>
                                  Add lead image
                                </span>
                              </button>
                            </Fragment>
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                  <EngageSelectInputField
                    choices={CHOICES}
                    fieldName='multiChoicesOfferedBound'
                    id={`input-answers-number-${_id}`}
                    label='Number of answers'
                    fieldClass='l-form__item'
                    firstOptionEmpty={false}
                  />
                </div>
              </div>
            </div>
            <SwitchableButtonBar
              updateAction={handleSubmit}
              removeAction={removeQuestion}
              isNew={null}
              previewAction={() => openPollPreview(question._id)}
            />
          </form>
        );
      }}
    />
  );
}

QuickText.propTypes = {
  question: PropTypes.object.isRequired,
  updateQuestion: PropTypes.func.isRequired,
  removeQuestion: PropTypes.func.isRequired,
  uploadMedia: PropTypes.func.isRequired,
  uploadVideo: PropTypes.func.isRequired,
  removeMedia: PropTypes.func.isRequired,
  openPollPreview: PropTypes.func,
  showConditionalLogic: PropTypes.bool.isRequired,
  Store: PropTypes.object.isRequired,
};

export default observer(QuickText);
