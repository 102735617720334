import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { SortableContainer } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash';
import debounce from 'lodash.debounce';

import ReactionsList from 'common/components/annotateQuestion/reactionsList/ReactionsList';
import { URL_TYPES, validateUrl } from 'common/UrlUtils';
import Agent from 'common/agent';
import EngageTextInputField from '../../../../../common/components/form-elements/textInputField/TextInputField';
import EngageSelectInputField from '../../../../../common/components/form-elements/selectInputField/SelectInputField';
import SwitchableButtonBar from '../../../../../common/components/switchableButtonBar/SwitchableButtonBar';
import { QUESTION_CHARACTER_LIMIT, QUESTION_TYPES } from '../../../../../common/constants/QuestionConstants';
import SlimFileUpload from '../../../../../common/components/form-elements/fileUpload/SlimFileUpload';
import { IMAGE_FORMATS } from '../../../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import MapComponent from '../../../../../common/components/map/MapComponent';
import GOOGLE_MAP_ENUMS from '../../../../../shared/engage/engage-google-map-enums';
import EngageCheckboxInputField from '../../../../../common/components/form-elements/checkboxInputField/CheckboxInputField';
import { getFormFieldClass, getFormFieldTranslationTooltip } from '../../../../../common/TranslationUtils';
import ConditionalLogic from '../../../../../common/components/conditionalLogic/ConditionalLogic';
import ConditionalLogicUtil from '../../../../../common/ConditionalLogicUtil';

const CHOICE_IDENTIFIER_PREFIX = 'choice';
const shapeTypes = GOOGLE_MAP_ENUMS().shapeTypes;
const mapStyle = GOOGLE_MAP_ENUMS().locationMapStyle;
const HOW_TO_USE_CUSTOM_MAP_URL = 'https://www.built-id.com/how-to-add-a-custom-map-layer';

function AnnotateQuestion(props) {
  const {
    question,
    updateQuestion,
    removeQuestion,
    uploadImage,
    removeImage,
    openPollPreview,
    openReactionsModal,
    showConditionalLogic,
    Store,
    ProjectStore,
    ToastrStore,
    HelpStore,
  } = props;
  const { _id, questionType, isOpened } = question;

  const isImageQuestion = questionType === QUESTION_TYPES.ANNOTATION_IMAGE;
  const isMapQuestion = questionType === QUESTION_TYPES.ANNOTATION_MAP;
  const imageId = question?.resource?.length > 0 ? question.resource[0] : null;
  const defaultImageId = question?.defaultData?.resource?.length > 0 ? question.defaultData.resource[0] : null;

  const [mapData, setMapData] = useState();
  const [mapZoom, setMapZoom] = useState();
  const [mapType, setMapType] = useState();
  const [mapSet, setMapSet] = useState(false);
  const [currentReactions, setCurrentReactions] = useState([]);
  const [allowDragAndDelete, setAllowDragAndDelete] = useState(true);

  const [formValues, setFormValues] = useState({});
  const [newReactionsFormValues, setNewReactionsFormValues] = useState({});
  const [rules, setRules] = useState([]);
  const [customMapUrl, setCustomMapUrl] = useState('');

  const CHOICES = [
    [1, 'Only 1'],
    [2, 'Up to 2'],
    [3, 'Up to 3'],
    [4, 'Up to 4'],
    [5, 'Up to 5'],
  ];

  const prepareInitValues = useCallback(
    (newReactions) => {
      let allowComments = true;
      if (question.questionConfiguration && 'allowComments' in question.questionConfiguration)
        allowComments = question.questionConfiguration.allowComments;

      let allowImageUpload = false;
      if (question.questionConfiguration && question.questionConfiguration.allowUserContentUpload)
        allowImageUpload = question.questionConfiguration.allowUserContentUpload.image;

      const initialFormQuestionValues = {
        questionText: question.questionText,
        multiChoicesSelectableBound: question.multiChoicesSelectableBound ? question.multiChoicesSelectableBound : 3,
        allowComments,
        allowImages: allowImageUpload,
        customLayer: question.questionMap?.customLayer,
        tooltip: question.questionConfiguration?.tooltip || '',
        showCustomTooltip:
          question.questionConfiguration?.tooltip ||
          (ProjectStore.language !== Agent.DEFAULT_LANGUAGE && question.defaultData?.questionConfiguration?.tooltip),
      };

      setCustomMapUrl(question.questionMap?.customLayer);

      for (let index = 0; index < newReactions.length; index++) {
        const element = newReactions[index];
        initialFormQuestionValues[`${CHOICE_IDENTIFIER_PREFIX}-${element._id}`] = element.choice ? element.choice : '';
      }

      const initialState = ConditionalLogicUtil.setupFormInitialValues(question, initialFormQuestionValues);
      setFormValues({ ...initialFormQuestionValues, ...initialState.initialFormConditionValues });
    },
    [ProjectStore.language, question],
  );

  const debouncedCustomMap = useMemo(() => debounce((value) => setCustomMapUrl(value), 300), []);

  const customMapUrlChangeHandler = useCallback((value) => debouncedCustomMap(value), [debouncedCustomMap]);

  useEffect(() => {
    if (!question) return;

    const reactionsClone = JSON.parse(JSON.stringify(question.choices));
    setCurrentReactions(reactionsClone);
    prepareInitValues(reactionsClone);

    // Set map data for map question if map data not already set
    if (!mapSet && question.questionType === QUESTION_TYPES.ANNOTATION_MAP) {
      setMapSet(true);
      setMapData(toJS(question.questionMap));
      setMapZoom(toJS(question.questionMap.zoom));
      setMapType(toJS(question.questionMap.mapTypeId));
    }
  }, [mapSet, prepareInitValues, question]);

  useEffect(() => {
    // This will be executed each time user adds new reaction(s)
    if (formValues) {
      const reactionsClone = JSON.parse(JSON.stringify(question.choices));
      setCurrentReactions(reactionsClone);

      const newReactions = {};
      for (let index = 0; index < reactionsClone.length; index++) {
        const element = reactionsClone[index];
        newReactions[`${CHOICE_IDENTIFIER_PREFIX}-${element._id}`] = element.choice ? element.choice : '';
      }
      setNewReactionsFormValues(newReactions);
    }
  }, [question.choices, formValues]);

  const onSubmit = (values) => {
    // Image question requires that image is set and at least one reaction added
    const questionWithImageAndAddedReactions = isImageQuestion && (!imageId || currentReactions.length < 1);
    if (questionWithImageAndAddedReactions) {
      ToastrStore.warning(`Image and at least one reaction are required before updating question!`);
      return;
    }

    // Map question requires that area is defined on map and at least one reaction added
    const questionWithMapAreaAndAddedReactions =
      isMapQuestion &&
      ((!mapData.rectangle &&
        !mapData.circle &&
        (!mapData.polygon || (mapData.polygon && mapData.polygon.path.length === 0))) ||
        currentReactions.length < 1);

    if (questionWithMapAreaAndAddedReactions) {
      ToastrStore.warning(`Defined map area and at least one reaction are required before updating question!`);
      return;
    }

    const updatedQuestionData = toJS(question);
    updatedQuestionData.questionText = values.questionText;
    updatedQuestionData.multiChoicesSelectableBound = values.multiChoicesSelectableBound;

    updatedQuestionData.questionConfiguration = {
      allowComments: values.allowComments,
      allowUserContentUpload: {
        image: values.allowImages,
      },
      tooltip: values.showCustomTooltip ? values.tooltip : '',
    };

    const updatedReactions = currentReactions.map((choice) => {
      const preparedReaction = _.cloneDeep(choice);
      preparedReaction.choice = values[`${CHOICE_IDENTIFIER_PREFIX}-${choice._id}`];
      return preparedReaction;
    });

    updatedQuestionData.choices = updatedReactions;

    // Set map data in case of map question
    if (isMapQuestion) {
      updatedQuestionData.questionMap = mapData;
      updatedQuestionData.questionMap.zoom = mapZoom;
      updatedQuestionData.questionMap.mapTypeId = mapType;
      updatedQuestionData.questionMap.customLayer = customMapUrl;
    }

    ConditionalLogicUtil.extractConditionalFormValues(question, updatedQuestionData, values, rules);

    updateQuestion(updatedQuestionData);
  };

  function onSortEnd({ oldIndex, newIndex }) {
    const newOrder = arrayMoveImmutable(currentReactions, oldIndex, newIndex);
    setAllowDragAndDelete(false);
    Store.reorderAnnotationReactions(_id, newOrder).then(() => {
      setAllowDragAndDelete(true);
    });
  }

  function removeReaction(reactionId) {
    const currentPos = window.pageYOffset;

    setAllowDragAndDelete(false);
    Store.removeAnnotationReaction(_id, reactionId).then(() => {
      setAllowDragAndDelete(true);
      window.scrollTo(0, currentPos);
    });
  }

  function openModal(allowImageUpload) {
    Store.annotateReactionsModalActivatorId = _id;
    Store.annotateReactionsModalInputIds = currentReactions.map((choice) => choice.reactionOrigin);
    Store.annotateReactionsModalAllowSingleReaction = allowImageUpload;

    HelpStore.closeHelp();

    openReactionsModal();
  }

  function allowImagesChangeHandler(event) {
    Store.annotateReactionsModalAllowSingleReaction = event.currentTarget.checked;
  }

  function customMapLabelLinkClickHandler(event) {
    const newWindow = window.open(HOW_TO_USE_CUSTOM_MAP_URL, '_blank', 'noopener,noreferrer');
  }

  function getFieldDescription() {
    let fieldDescription =
      'Use the pan & zoom function to explore the map. Drag & drop your selected reactions to a specific location within the boundary set.';

    if (isImageQuestion)
      fieldDescription =
        'Use the pan & zoom function to explore the image. Drag & drop your selected reactions to wherever you think is best.';

    if (ProjectStore.language !== Agent.DEFAULT_LANGUAGE && question.defaultData?.questionConfiguration?.tooltip)
      fieldDescription = question.defaultData?.questionConfiguration.tooltip;

    return fieldDescription;
  }
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ ...formValues, ...newReactionsFormValues }}
      keepDirtyOnReinitialize={true}
      render={({ values, handleSubmit }) => {
        return (
          <form className='c-fields l-form' onSubmit={(event) => event.preventDefault()}>
            <EngageTextInputField
              id={`input-question-${question._id}`}
              placeholder='Enter question'
              label='Question'
              maxLength={QUESTION_CHARACTER_LIMIT}
              fieldDescription=''
              fieldName='questionText'
              isRequired={true}
              fieldClass={getFormFieldClass(question.defaultData.questionText, null, 'l-form__item')}
              tooltipInLabel={getFormFieldTranslationTooltip(question.defaultData.questionText)}
            />

            <div className='c-toggle-content'>
              <div className='c-toggle-content__container'>
                <ConditionalLogic
                  formValues={values}
                  setFormValues={setFormValues}
                  question={question}
                  questions={Store.poll.questions}
                  rules={rules}
                  setRules={setRules}
                  show={showConditionalLogic}
                />
                <div className='c-toggle-content__content' hidden={showConditionalLogic}>
                  <div className='l-form'>
                    {/* Render image for image question */}
                    {isImageQuestion && (
                      <SlimFileUpload
                        imageFormat={IMAGE_FORMATS.REACTION_IMAGE}
                        imageId={imageId}
                        defaultImageId={defaultImageId}
                        id={`${_id}`}
                        handleUpload={uploadImage}
                        label='Annotation image'
                        removeImage={removeImage}
                        tip='User will be able to interact with the image, zoom in and out.'
                        enableLibrarySearch={false}
                        isZoomableImage={true}
                        classModifier={getFormFieldClass(imageId, defaultImageId)}
                        tooltipInLabel={getFormFieldTranslationTooltip(
                          null,
                          defaultImageId,
                          IMAGE_FORMATS.REACTION_IMAGE,
                        )}
                      />
                    )}

                    {/* Render map for map question */}
                    {isMapQuestion && mapData && isOpened && (
                      <MapComponent
                        id={_id}
                        mapData={mapData}
                        setMapData={setMapData}
                        mapZoom={mapZoom}
                        setMapZoom={setMapZoom}
                        mapType={mapType}
                        setMapType={setMapType}
                        label='Annotation area'
                        mapDescription='Define area with a circle, rectangle or a custom polygon and toggle between Map or Satellite view.'
                        clearMapText='Clear selection'
                        allowedDrawTools={[shapeTypes.CIRCLE, shapeTypes.POLYGON, shapeTypes.RECTANGLE]}
                        showMapTypeControl={true}
                        includeSearchControl={true}
                        mapStyle={mapStyle}
                        customMapUrl={customMapUrl}
                      />
                    )}

                    {isMapQuestion && (
                      <EngageTextInputField
                        id='kml-url'
                        placeholder='Enter custom map URL'
                        label='Custom map URL'
                        maxLength='200'
                        fieldName='customLayer'
                        fieldDescription=''
                        isRequired={false}
                        fieldClass='l-form__item'
                        onValueChange={customMapUrlChangeHandler}
                        labelLink='How to use'
                        onLabelLinkClick={customMapLabelLinkClickHandler}
                        validator={(value) => validateUrl(value, URL_TYPES.GOOGLE_MAP_URL)}
                      />
                    )}

                    <EngageSelectInputField
                      choices={CHOICES}
                      fieldName='multiChoicesSelectableBound'
                      id={`input-question-choices-${_id}`}
                      s
                      label='Possible reactions per user'
                      fieldClass='l-form__item--s'
                      firstOptionEmpty={false}
                    />

                    <EngageCheckboxInputField
                      id={`checkbox-allow-comments-${_id}`}
                      type='checkbox'
                      fieldName='allowComments'
                      fieldClass='l-form__item--s'
                      label='Reactions with comments'
                      isToggle={true}
                      checkedLabel='Include comments'
                      uncheckedLabel='Include comments'
                      hideLabel={false}
                    />

                    <EngageCheckboxInputField
                      id={`checkbox-allow-images-${_id}`}
                      type='checkbox'
                      fieldName='allowImages'
                      fieldClass='l-form__item--s'
                      label='Image upload'
                      labelTooltip='Users will be able to attach an image with their reaction. This is only available in situations when there
            is one reaction type available.'
                      isToggle={true}
                      checkedLabel='Enable images'
                      uncheckedLabel='Enable images'
                      hideLabel={false}
                      onSelectionChange={allowImagesChangeHandler}
                      disabled={currentReactions.length > 1}
                    />
                    <ReactionsList
                      currentReactions={currentReactions}
                      onSortEnd={onSortEnd}
                      onRemoveReaction={removeReaction}
                      allowDragAndDelete={allowDragAndDelete}
                      allowImages={!!values.allowImages}
                      onOpenModal={openModal}
                    />
                    <EngageCheckboxInputField
                      id={`checkbox-allow-tooltip-${_id}`}
                      type='checkbox'
                      fieldName='showCustomTooltip'
                      fieldClass='l-form__item group-with-next'
                      isToggle={true}
                      checkedLabel='Enable custom tooltip'
                      uncheckedLabel='Enable custom tooltip'
                    />
                    {values.showCustomTooltip && (
                      <EngageTextInputField
                        id={`input-custom-tooltip-${_id}`}
                        placeholder='Write custom tooltip'
                        label='Custom tooltip '
                        maxLength={320}
                        fieldDescription={getFieldDescription()}
                        fieldDescriptionContext='Default text: '
                        fieldName='tooltip'
                        fieldClass='l-form__item'
                        tooltipInLabel={{
                          icon: 'info-circle',
                          modifier: 'o-tooltip o-tooltip--top-center',
                          description:
                            'This text field will show default instructions to voters. If you would like to personalise the instructions, please enter your preferred text here.',
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <SwitchableButtonBar
              updateAction={handleSubmit}
              removeAction={removeQuestion}
              isNew={null}
              previewAction={() => openPollPreview(question._id)}
            />
          </form>
        );
      }}
    />
  );
}

export default observer(AnnotateQuestion);

AnnotateQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  updateQuestion: PropTypes.func.isRequired,
  removeQuestion: PropTypes.func.isRequired,
  uploadImage: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired,
  openPollPreview: PropTypes.func.isRequired,
  showConditionalLogic: PropTypes.bool.isRequired,
  Store: PropTypes.object.isRequired,
  ProjectStore: PropTypes.object.isRequired,
  ToastrStore: PropTypes.object.isRequired,
  HelpStore: PropTypes.object.isRequired,
};
