module.exports = function () {
  const EngageReportTimeRange = {
    ALL_TIME: 'allTime',
    LAST_30_DAYS: 'last30days',
    LAST_3_MONTHS: 'last3months',
    CUSTOM: 'custom',
  };

  const EngageReportType = {
    BASIC: 'basic',
    ADVANCED: 'advanced',
    CUSTOM: 'custom',
  };

  const GenerateReportConstants = {
    PROJECT_OVERALL_PDF: 'PROJECT_OVERALL_PDF',
    PROJECT_OVERALL_EXCEL: 'PROJECT_OVERALL_EXCEL',
    FEEDBACK_EXCEL: 'FEEDBACK_EXCEL',
    RAW_DATA_ZIP: 'RAW_DATA_ZIP',
    POLL_PREVIEW_PDF: 'POLL_PREVIEW_PDF',
    POLL_REPORT_PDF: 'POLL_REPORT_PDF',
    OUTPUT_PDF: 'PDF',
    OUTPUT_EXCEL: 'EXCEL',
    OUTPUT_HTML: 'HTML',
    OUTPUT_ZIP: 'ZIP',
  };

  const QUEUE_STATUS = {
    QUEUED: 'QUEUED',
    RUNNING: 'RUNNING',
    COMPLETED: 'COMPLETED',
    FAILED: 'FAILED',
  };

  const LambdaPDFGeneratorReportTypes = {
    SAMPLE_REPORT: 'SAMPLE_REPORT',
    PROJECT_OVERALL_REPORT: 'PROJECT_OVERALL_REPORT',
    POLL_PREVIEW: 'POLL_PREVIEW',
  };

  const CHART_LEGEND_COLORS = [
    '#EEF4FB',
    '#78DCA0',
    '#060049',
    '#7882DC',
    '#DC78B4',
    '#DCD278',
    '#CBE11E',
    '#E11ECB',
    '#AEE3A9',
    '#060049',
    '#E08C17',
  ];

  function getChartColor(index) {
    if (index >= CHART_LEGEND_COLORS.length) {
      return CHART_LEGEND_COLORS[CHART_LEGEND_COLORS.length - 1];
    }

    return CHART_LEGEND_COLORS[index];
  }

  return {
    EngageReportTimeRange,
    EngageReportType,
    GenerateReportConstants,
    QUEUE_STATUS,
    LambdaPDFGeneratorReportTypes,
    getChartColor,
  };
};
