import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

function GdprReferencesTableRow(props) {
  const { gdprReference } = props;
  const { email, postCode, feedback, createdDate, pollName } = gdprReference;

  return (
    <tr>
      <td>{createdDate ? moment(createdDate).format('YYYY/MM/DD hh:mm') : ''}</td>
      <td>{pollName ? `Poll: ${pollName}` : 'Feedback'}</td>
      <td>
        <strong>{email}</strong>
      </td>
      <td>{postCode}</td>
      <td>{feedback}</td>
    </tr>
  );
}

GdprReferencesTableRow.propTypes = {
  gdprReference: PropTypes.object.isRequired,
};

export default GdprReferencesTableRow;
