/* eslint-disable no-param-reassign */
import { action, runInAction, observable, computed, toJS, makeObservable } from 'mobx';

import AgentPoll from '../common/agent';
import { BACKEND_API_ROOT } from '../shared/engage/client-constants';
import { ApiErrorEnums } from '../shared/engage';

const Agent = AgentPoll.PollsAgent;

const ITEM_LABEL = 'question';
const LOAD_POLL_ERROR = `Error loading survey`;
const CREATE_SUCCESS = `New ${ITEM_LABEL} added`;
const CREATE_ERROR = `Error creating ${ITEM_LABEL}`;
const REORDER_ERROR = `Error reordering ${ITEM_LABEL}`;
const UPDATE_SUCCESS = 'Survey updated';
const UPDATE_ERROR = `Error saving ${ITEM_LABEL}`;
const DELETE_SUCCESS = 'Survey removed';
const DELETE_ERROR = `Error deleting ${ITEM_LABEL}`;
const UPDATE_QUESTION_SUCCESS = `Saved ${ITEM_LABEL}`;
const UPDATE_QUESTION_ERROR = `Error saving ${ITEM_LABEL}`;
const DELETE_QUESTION_SUCCESS = 'Question removed';
const DELETE_QUESTION_ERROR = `Error deleting ${ITEM_LABEL}`;
const ADD_FROM_LIBRARY_SUCCESS = 'Added question from library';
const ADD_FROM_LIBRARY_ERROR = `Error adding ${ITEM_LABEL} from library`;
const RESOURCE_DELETE_ERROR = 'Error deleting resource';
const REORDER_CHOICE_SUCCESS = `Choice reordered`;
const REORDER_CHOICE_ERROR = `Error reordering choice`;
const REORDER_LEAD_IMAGES_SUCCESS = `Lead images reordered`;
const REORDER_LEAD_IMAGES_ERROR = `Error reordering lead images`;
const DELETE_CHOICE_SUCCESS = 'Choice removed';
const DELETE_CHOICE_ERROR = `Error deleting choice`;
const ANNOTATION_REACTIONS_LOAD_ERROR = `Error loading reactions`;
const RATING_ICONS_LOAD_ERROR = `Error loading rating icons`;
const ANNOTATION_REACTION_CREATE_ERROR = `Error adding reaction`;

export default class PollEditStore {
  _loading = false;

  _initialized = false;

  _error = null;

  _poll = { questions: [] };

  _projectId = null;

  _annotateReactions = [];

  _annotateReactionCategories = [];

  _annotateReactionsModalOpen = false;

  _annotateReactionsModalActivatorId = null;

  _annotateReactionsModalInputIds = [];

  _annotateReactionsModalAllowSingleReaction = false;

  _ratingIcons = [];

  constructor(rootStore) {
    makeObservable(this, {
      _poll: observable,
      _loading: observable,
      _initialized: observable,
      _error: observable,
      _annotateReactions: observable,
      _annotateReactionsModalOpen: observable,
      _annotateReactionsModalActivatorId: observable,
      _annotateReactionsModalInputIds: observable,
      _annotateReactionsModalAllowSingleReaction: observable,
      _ratingIcons: observable,

      isInitialized: computed,
      error: computed,
      poll: computed,
      projectSlug: computed,
      annotateReactions: computed,
      annotateReactionsModalOpen: computed,
      annotateReactionsModalActivatorId: computed,
      annotateReactionsModalInputIds: computed,
      annotateReactionsModalAllowSingleReaction: computed,
      ratingIcons: computed,

      toggleOpened: action,
      resetFlags: action,
    });

    this.rootStore = rootStore;
  }

  get isLoading() {
    return this._loading;
  }

  get isInitialized() {
    return this._initialized;
  }

  get error() {
    return this._error;
  }

  set poll(value) {
    this._poll = value;
  }

  get poll() {
    return this._poll;
  }

  get pollQuestions() {
    return this._poll?.questions || [];
  }

  get projectSlug() {
    const slug = this.rootStore.projectStore.project && this.rootStore.projectStore.project.slug;
    return slug || '';
  }

  get annotateReactionsModalOpen() {
    return this._annotateReactionsModalOpen;
  }

  set annotateReactionsModalOpen(val) {
    this._annotateReactionsModalOpen = val;
  }

  get annotateReactionsModalActivatorId() {
    return this._annotateReactionsModalActivatorId;
  }

  set annotateReactionsModalActivatorId(val) {
    this._annotateReactionsModalActivatorId = val;
  }

  get annotateReactionsModalInputIds() {
    return this._annotateReactionsModalInputIds;
  }

  set annotateReactionsModalInputIds(val) {
    this._annotateReactionsModalInputIds = val;
  }

  get annotateReactionsModalAllowSingleReaction() {
    return this._annotateReactionsModalAllowSingleReaction;
  }

  set annotateReactionsModalAllowSingleReaction(val) {
    this._annotateReactionsModalAllowSingleReaction = val;
  }

  get annotateReactions() {
    return this._annotateReactions;
  }

  get ratingIcons() {
    return this._ratingIcons;
  }

  get annotateReactionCategories() {
    const uniqueCategoriesSet = new Set();
    this._annotateReactions.forEach((reaction) => {
      reaction.categories?.forEach((category) => uniqueCategoriesSet.add(category));
    });
    return [...uniqueCategoriesSet];
  }

  async getPollWithDefaultData(projectId = this._projectId, pollId = this._poll._id) {
    // Load poll
    const res = await Agent.getById(projectId, pollId);

    // Map default poll data to poll
    const updatedWithDefaultData = this.mapDefaultData(res.data);

    return updatedWithDefaultData;
  }

  /**
   * Loads all polls for a given project
   */
  async load(projectId, pollId) {
    try {
      const pollWithDefaultData = await this.getPollWithDefaultData(projectId, pollId);

      runInAction(() => {
        this._poll = pollWithDefaultData;
        this._projectId = projectId;
      });
      return pollWithDefaultData;
    } catch (err) {
      this._error = err.response;
      this.rootStore.toastrStore.error(LOAD_POLL_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  async reorder(data) {
    this.rootStore.helpStore.closeHelp();

    try {
      await Agent.reorder(
        this._projectId,
        this._poll._id,
        data.map((item) => item._id),
      );
      await this.load(this._projectId, this._poll._id);
    } catch (err) {
      this._error = err;
      this.rootStore.toastrStore.error(REORDER_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  async update(data) {
    try {
      await Agent.update(this._projectId, this._poll._id, data);

      const pollWithDefaultData = await this.getPollWithDefaultData();

      // Keep poll questions unchanged (this prevents question form resets if they are edited but changes are not saved)
      pollWithDefaultData.questions = this._poll.questions;

      runInAction(() => {
        this._poll = pollWithDefaultData;
      });

      this.rootStore.toastrStore.success(UPDATE_SUCCESS);
    } catch (err) {
      this._error = err;
      this.rootStore.toastrStore.error(UPDATE_ERROR, null, err);
    }
  }

  remove = () => {
    return Agent.remove(this._projectId, this._poll._id)
      .then(() => {
        this.rootStore.toastrStore.success(DELETE_SUCCESS);
        return true;
      })
      .catch((err) => {
        const errorInfo =
          err && err.response && err.response.data && err.response.data.error ? err.response.data.error : '';
        if (errorInfo === ApiErrorEnums().Err422KeyMmap.POLL_CONTAINS_RESPONSES) {
          this._error = errorInfo;
          this.rootStore.toastrStore.error(errorInfo, null, err);
        } else {
          this._error = err;
          this.rootStore.toastrStore.error(DELETE_ERROR, null, err);
        }
      })
      .finally(this.resetFlags);
  };

  previewPath = () => {
    const previewUrl = `${BACKEND_API_ROOT}/projects/${this._projectId}/polls/${this._poll?._id}/report/survey-preview?lang=${this.rootStore.projectStore.language}`;
    return process.env.NODE_ENV === 'development' ? `http://localhost:9000${previewUrl}` : previewUrl;
  };

  uploadPollResource = (pollId, file, progress) =>
    Agent.uploadPollImage(this._projectId, pollId, file, progress).then(
      action((res) => {
        this._poll.resource = res.data;
      }),
    );

  removePollResource = (pollId) =>
    Agent.removePollImage(this._projectId, pollId)
      .then(
        action(() => {
          this._poll.resource = null;
        }),
      )
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(RESOURCE_DELETE_ERROR, null, err);
      })
      .finally(this.resetFlags);

  // ###
  // QUESTION
  // ###
  uploadVideo = (question, data) =>
    Agent.uploadQuestionVideo(this._projectId, this._poll._id, question._id, data).then(
      action((res) => {
        const q = this._poll.questions.find((_question) => _question._id === question._id);
        q.resource = res.data;
      }),
    );

  // #### IMPORTANT
  // On SingleImageFact (as a special case) we receive resource as array of OBJECTS
  // while on other we receive an array of imageIDs so we have to handle the special case
  // indicated by media flag
  // #### CONSIDER REFACTORING in two separate functions if other question types change to array of objects in the API
  uploadImage = (question, file, progress, media = false) =>
    Agent.uploadQuestionImage(this._projectId, this._poll._id, question._id, file, progress).then(
      action((res) => {
        const q = this._poll.questions.find((_question) => _question._id === question._id);
        media ? (q.resource = res.data) : q.resource.push(res.data);
      }),
    );

  async removeImage(question, imageId) {
    try {
      const res = await Agent.removeQuestionImage(this._projectId, this._poll._id, question._id, imageId);

      const q = this._poll.questions.find((_question) => _question._id === question._id);

      runInAction(() => {
        q.resource = q.resource.filter((img) => img !== imageId);
      });

      return q.resource;
    } catch (err) {
      this._error = err;
      this.rootStore.toastrStore.error(RESOURCE_DELETE_ERROR, null, err);
    }
  }

  async removeMedia(question, mediaId) {
    try {
      const res = await Agent.removeQuestionImage(this._projectId, this._poll._id, question._id, mediaId);

      const q = this._poll.questions.find((_question) => _question._id === question._id);

      runInAction(() => {
        q.resource = q.resource.filter((img) => img._id !== mediaId);
      });

      return q.resource;
    } catch (err) {
      this._error = err;
      this.rootStore.toastrStore.error(RESOURCE_DELETE_ERROR, null, err);
    }
  }

  createQuestion = async (questionType) => {
    try {
      const res = await Agent.createQuestion(this._projectId, this._poll._id, { questionType });

      const pollWithDefaultData = await this.getPollWithDefaultData();

      // Get updated question and set it as opened
      const updatedQuestion = pollWithDefaultData.questions.find((q) => q._id === res.data._id);
      updatedQuestion.isOpened = true;

      runInAction(() => {
        this._poll.questions = [...this._poll.questions, updatedQuestion];
      });

      this.rootStore.toastrStore.success(CREATE_SUCCESS);
    } catch (err) {
      this._error = err;
      this.rootStore.toastrStore.error(CREATE_ERROR, null, err);
    }
  };

  async removeQuestion(questionId) {
    this.rootStore.helpStore.closeHelp();

    try {
      await Agent.removeQuestion(this._projectId, this._poll._id, questionId);

      runInAction(() => {
        const cleanedQuestionsList = this._poll.questions.filter((question) => question._id !== questionId);
        this._poll.questions = cleanedQuestionsList;
      });

      this.rootStore.toastrStore.success(DELETE_QUESTION_SUCCESS);
    } catch (err) {
      const errorInfo = err?.response?.data?.message ? err.response.data.message : '';
      if (errorInfo === ApiErrorEnums().Err400KeyMmap.IS_CONDITION_DESTINATION_QUESTION) {
        this._error = errorInfo;
        this.rootStore.toastrStore.error(errorInfo, null, err);
      } else {
        this._error = err;
        this.rootStore.toastrStore.error(DELETE_QUESTION_ERROR, null, err);
      }
    } finally {
      this.resetFlags();
    }
  }

  async updateQuestion(question) {
    try {
      // Update question
      await Agent.updateQuestion(this._projectId, this._poll._id, question._id, question);

      if (this?._poll?._id) {
        const pollWithDefaultData = await this.getPollWithDefaultData();

        // Get updated question and set it as opened
        const changedQuestionIndex = pollWithDefaultData.questions.findIndex((q) => q._id === question._id);
        const updatedQuestion = pollWithDefaultData.questions.find((q) => q._id === question._id);
        updatedQuestion.isOpened = true;

        runInAction(() => {
          const updatedQuestions = [...this._poll.questions];
          updatedQuestions[changedQuestionIndex] = updatedQuestion;
          this._poll.questions = updatedQuestions;
        });
      }

      this.rootStore.toastrStore.success(UPDATE_QUESTION_SUCCESS);
    } catch (err) {
      this._error = err;
      this.rootStore.toastrStore.error(UPDATE_QUESTION_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  async copyFromQuestionTemplate(data) {
    try {
      const res = await Agent.copyFromQuestionTemplate(this._projectId, this._poll._id, data);

      const pollWithDefaultData = await this.getPollWithDefaultData();

      // Get created question that contains default data
      const createdQuestion = pollWithDefaultData.questions.find((q) => q._id === res.data._id);

      runInAction(() => {
        this._poll.questions.push(createdQuestion);
      });

      this.rootStore.toastrStore.success(ADD_FROM_LIBRARY_SUCCESS);
      return true;
    } catch (err) {
      this._error = err;
      this.rootStore.toastrStore.error(ADD_FROM_LIBRARY_ERROR, null, err);
      return false;
    }
  }

  // ###
  // CHOICE
  // ###
  uploadChoiceImage = (question, choiceId, file, progress) =>
    Agent.uploadChoiceImage(this._projectId, this._poll._id, question._id, choiceId, file, progress).then((res) => {
      this.updateChoiceResource(question._id, choiceId, res.data);
    });

  removeChoiceImage = (question, choiceId) =>
    Agent.removeChoiceImage(this._projectId, this._poll._id, question._id, choiceId)
      .then(() => this.updateChoiceResource(question._id, choiceId, null))
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(RESOURCE_DELETE_ERROR, null, err);
      })
      .finally(this.resetFlags);

  async loadAnnotationReactions() {
    try {
      const res = await Agent.loadAnnotationReactions();

      runInAction(() => {
        this._annotateReactions = res.data;
      });

      return res.data;
    } catch (err) {
      this._error = err;
      this.rootStore.toastrStore.error(ANNOTATION_REACTIONS_LOAD_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  async loadRatingIcons() {
    try {
      const res = await Agent.loadRatingIcons();

      runInAction(() => {
        this._ratingIcons = res.data;
      });

      return res.data;
    } catch (err) {
      this._error = err;
      this.rootStore.toastrStore.error(RATING_ICONS_LOAD_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  async addAnnotationReactions(data) {
    const questionId = this._annotateReactionsModalActivatorId;
    try {
      const res = await Agent.addAnnotationReactions(this._projectId, this._poll._id, questionId, data);

      const pollWithDefaultData = await this.getPollWithDefaultData();

      // Get target question and target question that contains default data (for added choices)
      const targetQuestion = this._poll.questions.find((q) => q._id === questionId);
      const updatedTargetQuestion = pollWithDefaultData.questions.find((q) => q._id === questionId);
      targetQuestion.choices = updatedTargetQuestion.choices;

      runInAction(() => {
        this._annotateReactionsModalOpen = false;
        this._annotateReactionsModalActivatorId = null;
        this._annotateReactionsModalInputIds = [];
      });
    } catch (err) {
      this._error = err;
      this.rootStore.toastrStore.error(ANNOTATION_REACTION_CREATE_ERROR, null, err);

      return false;
    } finally {
      this.resetFlags();
    }
  }

  async removeAnnotationReaction(questionId, annotationReactionId) {
    try {
      const res = await Agent.removeAnnotationReaction(
        this._projectId,
        this._poll._id,
        questionId,
        annotationReactionId,
      );

      const targetQuestion = this._poll.questions.find((q) => q._id === questionId);

      const remainingChoices = targetQuestion.choices.filter((c) => c._id !== annotationReactionId);
      targetQuestion.choices = remainingChoices;

      this.rootStore.toastrStore.success(DELETE_CHOICE_SUCCESS, null);
    } catch (err) {
      this._error = err;
      this.rootStore.toastrStore.error(DELETE_CHOICE_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  async reorderAnnotationReactions(questionId, data) {
    try {
      const res = await Agent.reorderAnnotationReactions(this._projectId, this._poll._id, questionId, {
        reactionIds: data.map((item) => item._id),
      });

      const pollWithDefaultData = await this.getPollWithDefaultData();

      // Get target question and target question that contains default data (for added choices)
      const targetQuestion = this._poll.questions.find((q) => q._id === questionId);
      const updatedTargetQuestion = pollWithDefaultData.questions.find((q) => q._id === questionId);
      targetQuestion.choices = updatedTargetQuestion.choices;

      this.rootStore.toastrStore.success(REORDER_CHOICE_SUCCESS, null);
    } catch (err) {
      this._error = err;
      this.rootStore.toastrStore.error(REORDER_CHOICE_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  async reorderLeadImages(questionId, data) {
    try {
      const res = await Agent.reorderLeadImages(this._projectId, this._poll._id, questionId, data);

      const pollWithDefaultData = await this.getPollWithDefaultData();

      // Get target question and target question that contains default data (for added images)
      const targetQuestion = this._poll.questions.find((q) => q._id === questionId);
      const updatedTargetQuestion = pollWithDefaultData.questions.find((q) => q._id === questionId);
      targetQuestion.resource = updatedTargetQuestion.resource;

      this.rootStore.toastrStore.success(REORDER_LEAD_IMAGES_SUCCESS, null);
    } catch (err) {
      this._error = err;
      this.rootStore.toastrStore.error(REORDER_LEAD_IMAGES_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  mapDefaultData = (pollData) => {
    pollData.questions.forEach((question) => {
      const defaultQuestion = pollData.defaultEnglish.questions.find((questionDef) => questionDef._id === question._id);
      question.defaultData = defaultQuestion;

      if (question.choices) {
        question.choices.forEach((choice) => {
          const defaultChoice = defaultQuestion.choices.find((choiceDef) => choiceDef._id === choice._id);
          choice.defaultData = defaultChoice;
        });
      }

      if (question.resourceLabels) {
        question.resourceLabels.forEach((element, index) => {
          element.defaultData = defaultQuestion.resourceLabels[index];
        });
      }
    });

    return pollData;
  };

  resetFlags = () => {
    this._loading = false;
    this._error = null;
  };

  toggleOpened = (questionIds) => {
    questionIds.forEach((id) => {
      const q = this._poll.questions.find((question) => question._id === id);
      if (q) {
        q.isOpened = !q.isOpened;
      }
    });
  };

  updateChoiceResource = (questionId, choiceId, newResourceValue) => {
    const targetQuestion = this.poll.questions.find((q) => q._id === questionId);
    const originalChoiceIdsOrder = targetQuestion.choices.map((ch) => ch._id);
    const targetChoice = targetQuestion.choices.find((choice) => choice._id === choiceId);

    const unchangedChoices = targetQuestion.choices.filter((choice) => choice._id !== choiceId);

    const unOrderedUpdatedChoices = [...unchangedChoices, { ...targetChoice, resource: newResourceValue }];

    const orderedAndUpdated = [];
    originalChoiceIdsOrder.forEach((id) => {
      const targetCh = unOrderedUpdatedChoices.find((choice) => choice._id === id);
      orderedAndUpdated.push(targetCh);
    });
    targetQuestion.choices = orderedAndUpdated;
  };
}
