import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
// eslint-disable-next-line import/no-cycle
import { PollReportContext } from '../PollsReport';
import QuestionFooter from '../../QuestionFooter';
import QuestionHeader from '../../QuestionHeader';
import NumberUtils from '../../../../../common/NumberUtils';

function ReorderPreview(props) {
  const { question, GeneralStore } = props;
  const { questionId, choices, countAnswered, skipSeen } = question;

  const sortedChoices = [...choices].sort((a, b) => a.avgOrder - b.avgOrder);

  const pollReportContext = useContext(PollReportContext);
  const containerReference = useRef();

  const [compareActive, setCompareActive] = useState(false);

  useEffect(() => {
    if (pollReportContext)
      pollReportContext.addReference({ id: questionId, containerReference: containerReference.current });
  }, [pollReportContext, questionId]);

  return (
    <div ref={containerReference} className='c-poll-preview__item'>
      <div className='c-question c-question--reorder'>
        <QuestionHeader question={question} onCompareChange={() => setCompareActive(!compareActive)} />

        <div className='c-question__content'>
          <div className='c-question-reorder'>
            <div className='c-question-reorder__label c-question-reorder__label--top'>Highest priority</div>
            <ol className='c-question-reorder__items'>
              {sortedChoices.map((item, idx) => (
                <li key={idx} className='c-question-reorder__item'>
                  {item.label}
                  <span className='o-tooltip o-tooltip--s o-tooltip--bottom-center'>
                    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                    <span className='o-tooltip__action' tabIndex='0'>
                      <span className='o-label'>{NumberUtils.fixedDecimals(item.avgOrder, 2)}</span>
                    </span>
                    {!GeneralStore.isSnapshotting && (
                      <span className='o-tooltip__description' role='tooltip'>
                        The average position of each choice with 1 being the highest and 4 being the lowest.
                      </span>
                    )}
                  </span>
                </li>
              ))}
            </ol>
            <div className='c-question-reorder__label c-question-reorder__label--bottom'>Lowest priority</div>
          </div>
        </div>

        <QuestionFooter answered={countAnswered} skipped={skipSeen.skipCount} viewed={skipSeen.seenCount} />
      </div>
    </div>
  );
}

ReorderPreview.propTypes = {
  GeneralStore: PropTypes.object.isRequired,
  question: PropTypes.shape({
    questionId: PropTypes.string.isRequired,
    choices: PropTypes.array.isRequired,
    countAnswered: PropTypes.number,
    skipSeen: PropTypes.shape({
      seenCount: PropTypes.number,
      skipCount: PropTypes.number,
    }),
  }).isRequired,
};

export default inject((root) => ({
  GeneralStore: root.RootStore.generalStore,
}))(observer(ReorderPreview));
