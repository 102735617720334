import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import EngageTextInputField from '../../../../common/components/form-elements/textInputField/TextInputField';
import { REPORT_NAME_CHAR_LIMIT, OUTPUT_PDF, getOverviewDataOptionChoices, OVERVIEW_DATA_OPTIONS } from './ReportEnums';
import SimpleCheckboxInput from '../../../../common/components/form-elements/checkboxInputField/SimpleCheckboxInput';
import RadioInput from '../../../../common/components/form-elements/radioGroupInputField/RadioInput';
import SegmentationListItem from './SegmentationListItem';
import PollsListItem from './PollsListItem';
import DemographicsListItem from './DemographicsListItem';
import GenerateReportSubmitButtons from './GenerateReportSubmitButtons';
import ReportUtils from './ReportUtils';

function GeneratePdf(props) {
  const {
    pdfFormData,
    segmentationQuestions,
    polls,
    onSetPdfReportFormValues,
    onGenerateReport,
    buttonLoading,
    fromDate,
    toDate,
  } = props;

  const [formValues, setFormValues] = useState({});
  const [initValuesSet, setInitValuesSet] = useState(false);
  const [segmentationFilterQuestions, setSegmentationFilterQuestions] = useState([]);
  const [segmentationFilterQuestionChoices, setSegmentationFilterQuestionChoices] = useState([]);

  const prepareSegmentationQuestionChoices = useCallback((questions, questionId) => {
    const preparedChoices = ReportUtils.prepareSegmentationQuestionChoices(questions, questionId);
    setSegmentationFilterQuestionChoices(preparedChoices);

    return preparedChoices;
  }, []);

  useEffect(() => {
    if (!initValuesSet && pdfFormData && segmentationQuestions) {
      const preparedSegmentationQuestions = segmentationQuestions.map((item) => {
        const label = item.questionText ? item.questionText : 'No question title';
        return [item._id, label];
      });
      setSegmentationFilterQuestions(preparedSegmentationQuestions);

      const selectedSegmentationQuestionChoices = prepareSegmentationQuestionChoices(
        segmentationQuestions,
        pdfFormData.segmentationFilterQuestion,
      );

      let selectedChoice = [];
      if (selectedSegmentationQuestionChoices?.length > 0)
        selectedChoice = pdfFormData?.segmentationFilterQuestionChoice || selectedSegmentationQuestionChoices[0][0];

      if (Object.keys(pdfFormData).length > 0) {
        setFormValues({
          ...pdfFormData,
          segmentationFilterQuestionChoice: selectedChoice,
        });

        setInitValuesSet(true);
      }
    }
  }, [prepareSegmentationQuestionChoices, segmentationQuestions, initValuesSet, pdfFormData]);

  function onSubmit(values) {
    const payload = ReportUtils.createPayload(values, OUTPUT_PDF, fromDate, toDate, polls);
    onGenerateReport(payload);
  }

  function onSegmentationFilterQuestionChange(questionId, values) {
    const selectedSegmentationQuestionChoices = prepareSegmentationQuestionChoices(segmentationQuestions, questionId);

    setFormValues({
      ...values,
      segmentationFilterQuestion: questionId,
      segmentationFilterQuestionChoice: selectedSegmentationQuestionChoices[0][0],
    });
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={formValues}
      render={({ values, handleSubmit }) => {
        onSetPdfReportFormValues(values);
        return (
          <form className='c-fields l-form'>
            <EngageTextInputField
              id='input-report-name'
              placeholder='Name'
              label='Report name'
              maxLength={REPORT_NAME_CHAR_LIMIT}
              fieldDescription=''
              fieldName='reportName'
              fieldClass=''
            />
            <div className='c-field l-form__item'>
              <label className='c-field__label' htmlFor='input-report-filter'>
                Report filter
              </label>
              <div className='c-field__group'>
                <ul className='c-list-settings'>
                  <SegmentationListItem
                    segmentationFilterValue={values.segmentationFilter}
                    segmentationFilterQuestions={segmentationFilterQuestions}
                    segmentationFilterQuestionChoices={segmentationFilterQuestionChoices}
                    onSegmentationFilterQuestionChange={(questionId) =>
                      onSegmentationFilterQuestionChange(questionId, values)
                    }
                  />
                </ul>
              </div>
            </div>
            <div className='c-field l-form__item'>
              <label className='c-field__label' htmlFor='input-report-filter'>
                What goes into the report?
              </label>
              <div className='c-field__group'>
                <ul className='c-list-settings'>
                  <li
                    className={`c-list-settings__item ${values.overviewData ? 'c-list-settings__item--highlight' : ''}`}
                  >
                    <Field
                      component={SimpleCheckboxInput}
                      name='overviewData'
                      classModifier='c-list-settings__name'
                      label='Overview'
                      id='overviewData'
                      type='checkbox'
                      defaultValue={true}
                    />
                    <div className='c-list-settings__options'>
                      <Field
                        type='radio'
                        name='overviewDataOptions'
                        component={RadioInput}
                        choices={getOverviewDataOptionChoices()}
                        classModifier='o-radio--inline o-radio--s'
                        disabled={!values.overviewData}
                        value={values.overviewDataOptions}
                        defaultValue={OVERVIEW_DATA_OPTIONS.BASIC}
                      />
                    </div>
                  </li>
                  <PollsListItem
                    pollsDataValue={values.pollsData}
                    pollsDataOptionValue={values.pollsDataOption}
                    polls={polls}
                  />
                  <DemographicsListItem demographicsDataValue={values.demographicsData} />
                </ul>
              </div>
            </div>
            <GenerateReportSubmitButtons
              formValues={values}
              outputType={OUTPUT_PDF}
              buttonLoading={buttonLoading}
              onGenerateReport={handleSubmit}
              pollsList={polls}
            />
          </form>
        );
      }}
    />
  );
}

GeneratePdf.propTypes = {
  pdfFormData: PropTypes.object,
  segmentationQuestions: PropTypes.array,
  polls: PropTypes.array.isRequired,
  onSetPdfReportFormValues: PropTypes.func.isRequired,
  onGenerateReport: PropTypes.func.isRequired,
  buttonLoading: PropTypes.bool.isRequired,
  fromDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  toDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default GeneratePdf;
