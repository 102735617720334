import React from 'react';
import PropTypes from 'prop-types';
import QuestionTemplateAction from './QuestionTemplateAction';
import { IMAGE_FORMATS } from '../../../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import ImageUtils from '../../../../../common/ImageUtils';
import QuestionTemplateHeader from './QuestionTemplateHeader';

function TwoImagePreview(props) {
  const { question } = props;

  return (
    <div className='c-library__item'>
      <div className='c-question c-question--image-two c-question--library'>
        <QuestionTemplateHeader question={question} />
        <div className='c-question__content'>
          <div className='c-question-image c-question-image--two'>
            <div className='c-question-image__items'>
              <div className='c-question-image__item'>
                <div className='c-question-image__media'>
                  <img src={ImageUtils.imageUrl(question.choices[0].resource, IMAGE_FORMATS.PANEL.imageType)} alt='' />
                </div>
                <h3 className='c-question-image__label'>{question.choices[0].choice}</h3>
              </div>
              <div className='c-question-image__item'>
                <div className='c-question-image__media'>
                  <img src={ImageUtils.imageUrl(question.choices[1].resource, IMAGE_FORMATS.PANEL.imageType)} alt='' />
                </div>
                <h3 className='c-question-image__label'>{question.choices[1].choice}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <QuestionTemplateAction question={question} />
    </div>
  );
}

TwoImagePreview.propTypes = {
  question: PropTypes.shape({
    questionText: PropTypes.string.isRequired,
    choices: PropTypes.array.isRequired,
  }).isRequired,
};

export default TwoImagePreview;
