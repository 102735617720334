import React from 'react';
import { Field } from 'react-final-form';
import { validateMarkdownLength } from 'common/formValidators';
import SimpleMarkdownInputField from './SimpleMarkdownInputField';

import { MARKDOWN_MIN, MARKDOWN_MIN_LINK, MARKDOWN_MIN_PLUS, MARKDOWN_MID, MARKDOWN_MAX } from './MarkdownConstants';

function EngageMarkdownInputField(props) {
  const { fieldName, isRequired, validator = validateMarkdownLength, maxLength } = props;

  function validateRequired(value) {
    return !value && isRequired ? 'Required' : undefined;
  }

  function validateHandler(value) {
    const val = value && value.trim();

    if (!val && isRequired) return 'Required';

    if (val && validator) return validator(value, maxLength);

    return undefined;
  }

  return <Field component={SimpleMarkdownInputField} name={fieldName} validate={validateHandler} {...props} />;
}
export { MARKDOWN_MIN, MARKDOWN_MIN_LINK, MARKDOWN_MIN_PLUS, MARKDOWN_MID, MARKDOWN_MAX };
export default EngageMarkdownInputField;
