import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-cycle
import { PollReportContext } from '../PollsReport';
import QuestionFooter from '../../QuestionFooter';
import QuestionHeader from '../../QuestionHeader';
import SliderChoice from './SliderChoice';

function DistributePreview(props) {
  const { question } = props;
  const { questionId, choices, countAnswered, resource, skipSeen } = question;

  const pollReportContext = useContext(PollReportContext);
  const containerReference = useRef();

  const [compareActive, setCompareActive] = useState(false);
  const totalAmount = choices.reduce((acc, choice) => {
    return acc + choice.avgAmount;
  }, 0);
  const weightedAmount = choices.map((choice) => {
    if (!totalAmount) {
      return 0;
    }
    return (choice.avgAmount * 100) / totalAmount;
  });

  useEffect(() => {
    if (pollReportContext)
      pollReportContext.addReference({ id: questionId, containerReference: containerReference.current });
  }, [pollReportContext, questionId]);

  return (
    <div ref={containerReference} className='c-poll-preview__item'>
      <div className='c-question'>
        <QuestionHeader question={question} onCompareChange={() => setCompareActive(!compareActive)} />
        <div className='c-question__content'>
          <div className='c-result-group'>
            <ul className='c-result-group__items'>
              {choices.map((choice, idx) => (
                <SliderChoice
                  key={idx}
                  compareActive={compareActive}
                  choice={choice}
                  showEmoji={false}
                  questionResource={resource}
                  weightedAmount={weightedAmount[idx]}
                />
              ))}
            </ul>
          </div>
        </div>
        <QuestionFooter answered={countAnswered} skipped={skipSeen.skipCount} viewed={skipSeen.seenCount} />
      </div>
    </div>
  );
}

DistributePreview.propTypes = {
  question: PropTypes.shape({
    questionId: PropTypes.string.isRequired,
    countAnswered: PropTypes.number,
    countSkipped: PropTypes.number,
    count: PropTypes.number,
    countFeedback: PropTypes.number,
    choices: PropTypes.array,
    sentiment: PropTypes.array,
    resource: PropTypes.string,
    skipSeen: PropTypes.shape({
      seenCount: PropTypes.number,
      skipCount: PropTypes.number,
    }),
  }).isRequired,
};

export default DistributePreview;
