import React from 'react';
import SvgIcon from '../../../../common/components/SvgIcon';

export default function StatBlock(props) {
  const { title, highlight, description, modifierClass, status, icon, abbr } = props;
  return (
    <div className={`c-stat l-stat ${modifierClass}`}>
      <h3 className='c-stat__title'>
        <div className='c-stat__icon'>
          <SvgIcon icon={icon} />
        </div>
        {title}
        {status && <span className='o-tag'>{status}</span>}
        {abbr && <abbr title={abbr.title}>{abbr.label}</abbr>}
      </h3>
      <div className='c-stat__highlight'>{highlight}</div>
      {/* eslint-disable-next-line react/no-danger */}
      <div className='c-stat__description' dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
}
