import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Form } from 'react-final-form';
import { HELP } from 'shared/engage/engage-help-enums';
import ActionBar from '../../../common/components/actionBar/ActionBar';
import SwitchableButtonBar from '../../../common/components/switchableButtonBar/SwitchableButtonBar';
import FieldEdit from './PersonalDataFieldEdit';
import EngageTextInputField from '../../../common/components/form-elements/textInputField/TextInputField';
import EngageTextareaInputField from '../../../common/components/form-elements/textareaInputField/TextareaInputField';
import { PERSONAL_DATA_FIELDS } from '../../../shared/engage/engage-question-constants';
import { getFormFieldClass, getFormFieldTranslationTooltip } from '../../../common/TranslationUtils';

function PersonalData(props) {
  const { Store } = props;

  const initialValues = Store.personalData;
  const defaultValues = Store.personalDataDefault || {};

  const submit = async (values) => {
    await Store.update(values);
  };

  return (
    <div className='l-main' data-testid='personal-data'>
      <ActionBar
        addNew={() => {}}
        label='Personal Data '
        hasButton={false}
        buttonLabel='Add'
        helpKey={HELP.PROJECT.EDIT_PROJECT.CUSTOMISE.PERSONAL_DATA.PAGE}
        isTextOnlyHelp={true}
      />
      <Form
        onSubmit={submit}
        initialValues={initialValues}
        render={({ values, handleSubmit }) => {
          return (
            <form className='c-fields l-form' onSubmit={handleSubmit}>
              <p>
                Here you can configure <strong>personal data gathering form</strong>.
              </p>

              <div className='c-message is-warning'>
                <p className=' c-message__description'>
                  Personal data acquisition is <strong>{Store.personalData.active ? 'Active' : 'Hidden'}</strong>. To
                  {Store.personalData.active ? ' deactivate' : ' activate'} go to the Publishing page.
                </p>
              </div>

              <div className='c-field l-form__item c-field--boxed'>
                <div className='c-field__group'>
                  <EngageTextInputField
                    id='personal-data-questionText'
                    placeholder='Define form title'
                    label='Form title'
                    maxLength={40}
                    fieldName='questionText'
                    isRequired={true}
                    fieldDescription='Providing an engaging title helps visitors understand why are you requesting these information.'
                    fieldClass={getFormFieldClass(defaultValues.questionText)}
                    tooltipInLabel={getFormFieldTranslationTooltip(defaultValues.questionText)}
                  />
                  <EngageTextareaInputField
                    id='personal-data-questionSubtext'
                    label='Form description'
                    placeholder='Write down a short description'
                    fieldName='questionSubtext'
                    maxLength={140}
                    isRequired={false}
                    fieldDescription='Provide a short description so people will be more inclined to answer all these questions.'
                    fieldClass={getFormFieldClass(defaultValues.questionSubtext)}
                    tooltipInLabel={getFormFieldTranslationTooltip(defaultValues.questionSubtext)}
                  />
                </div>
              </div>
              <div className='c-field l-form__item'>
                <div className='c-field__group'>
                  <h3>Predefined fields</h3>
                  <FieldEdit field={`fieldConfiguration.${PERSONAL_DATA_FIELDS.FIRST_NAME}`} label='First name' />
                  <FieldEdit field={`fieldConfiguration.${PERSONAL_DATA_FIELDS.LAST_NAME}`} label='Last name' />
                  <FieldEdit field={`fieldConfiguration.${PERSONAL_DATA_FIELDS.EMAIL}`} label='Email' />
                  <FieldEdit field={`fieldConfiguration.${PERSONAL_DATA_FIELDS.ADDRESS}`} label='Address' />
                  <FieldEdit
                    field={`fieldConfiguration.${PERSONAL_DATA_FIELDS.POSTAL_CODE}`}
                    label='Postcode'
                    showLabelField={true}
                    defaultLabel={defaultValues?.fieldConfiguration[PERSONAL_DATA_FIELDS.POSTAL_CODE]?.label}
                  />
                  <FieldEdit field={`fieldConfiguration.${PERSONAL_DATA_FIELDS.PHONE}`} label='Phone' />
                </div>
              </div>

              <div className='c-field l-form__item'>
                <div className='c-field__group'>
                  <h3>Extra fields</h3>
                  <FieldEdit
                    field='fieldConfiguration.extra1'
                    label='Extra field 1'
                    showLabelField={true}
                    showOptionsField={true}
                    defaultLabel={defaultValues?.fieldConfiguration[PERSONAL_DATA_FIELDS.EXTRA1]?.label}
                  />
                  <FieldEdit
                    field='fieldConfiguration.extra2'
                    label='Extra field 2'
                    showLabelField={true}
                    showOptionsField={true}
                    defaultLabel={defaultValues?.fieldConfiguration[PERSONAL_DATA_FIELDS.EXTRA2]?.label}
                  />
                </div>
              </div>

              <SwitchableButtonBar updateAction={handleSubmit} />
            </form>
          );
        }}
      />
    </div>
  );
}

PersonalData.propTypes = {
  Store: PropTypes.object.isRequired,
};

export default inject((root) => ({ Store: root.RootStore.personalDataStore }))(observer(PersonalData));
