import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import EngageTextInputField from '../../../../../common/components/form-elements/textInputField/TextInputField';
import ImageChoice from '../../../../../common/components/form-elements/imageChoice/ImageChoice';
import SwitchableButtonBar from '../../../../../common/components/switchableButtonBar/SwitchableButtonBar';
import { IMAGE_FORMATS } from '../../../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import { QUESTION_CHARACTER_LIMIT, IMAGE_CHARACTER_LIMIT } from '../../../../../common/constants/QuestionConstants';
import { getFormFieldClass, getFormFieldTranslationTooltip } from '../../../../../common/TranslationUtils';
import ConditionalLogic from '../../../../../common/components/conditionalLogic/ConditionalLogic';
import ConditionalLogicUtil from '../../../../../common/ConditionalLogicUtil';

function FourImageQuestion(props) {
  const {
    question,
    updateQuestion,
    removeQuestion,
    uploadChoiceImage,
    removeChoiceImage,
    CHOICE_LABELS,
    openPollPreview,
    showConditionalLogic,
    Store,
  } = props;

  const [formValues, setFormValues] = useState();
  const [rules, setRules] = useState([]);
  const [choiceFieldNames, setChoiceFieldNames] = useState([]);

  const choices = question?.choices || [];

  useEffect(() => {
    if (question) {
      const initialFormQuestionValues = {
        questionText: question.questionText,
      };

      question.choices.forEach((choice, index) => {
        const choiceFieldName = ConditionalLogicUtil.QUESTION_CHOICE_FIELDS[index].fieldName;
        if (choice.choice) initialFormQuestionValues[choiceFieldName] = choice.choice;
      });

      const initialState = ConditionalLogicUtil.setupFormInitialValues(question, initialFormQuestionValues);
      setFormValues({ ...initialFormQuestionValues, ...initialState.initialFormConditionValues });
      setRules(initialState.initialRules);
      setChoiceFieldNames(ConditionalLogicUtil.QUESTION_CHOICE_FIELDS.slice(0, 4));
    }
  }, [question]);

  const onSubmit = (values) => {
    // console.log(values);
    const updatedQuestionData = toJS(question);
    updatedQuestionData.questionText = values.questionText;
    updatedQuestionData.choices[0].choice = values.choice1Label || '';
    updatedQuestionData.choices[1].choice = values.choice2Label || '';
    updatedQuestionData.choices[2].choice = values.choice3Label || '';
    updatedQuestionData.choices[3].choice = values.choice4Label || '';

    ConditionalLogicUtil.extractConditionalFormValues(question, updatedQuestionData, values, rules);

    updateQuestion(updatedQuestionData);
  };

  return (
    <Form
      onSubmit={onSubmit}
      keepDirtyOnReinitialize={true}
      initialValues={formValues}
      render={({ values, handleSubmit }) => {
        return (
          <form className='c-fields l-form'>
            <EngageTextInputField
              id={`input-question-${question._id}`}
              placeholder='Enter question'
              label='Question'
              maxLength={QUESTION_CHARACTER_LIMIT}
              fieldDescription=''
              fieldName='questionText'
              isRequired={true}
              fieldClass={getFormFieldClass(question.defaultData.questionText, null, 'l-form__item')}
              tooltipInLabel={getFormFieldTranslationTooltip(question.defaultData.questionText)}
            />
            <div className='c-toggle-content'>
              <div className='c-toggle-content__container'>
                <ConditionalLogic
                  formValues={values}
                  setFormValues={setFormValues}
                  question={question}
                  questions={Store.poll.questions}
                  rules={rules}
                  setRules={setRules}
                  show={showConditionalLogic}
                  choiceFieldNames={choiceFieldNames}
                />
                <div className='c-toggle-content__content' hidden={showConditionalLogic}>
                  <div className='l-form'>
                    {choices.map((choice, index) => (
                      <ImageChoice
                        imageFormat={IMAGE_FORMATS.SQUARE}
                        key={choice._id}
                        index={index}
                        placeholder='Choice name'
                        maxLength={IMAGE_CHARACTER_LIMIT}
                        choice={choice}
                        fieldName={`choice${index + 1}Label`}
                        label={CHOICE_LABELS[index]}
                        handleUpload={uploadChoiceImage}
                        removeImage={removeChoiceImage}
                        classModifier={getFormFieldClass(
                          choice.defaultData.choice,
                          choice.defaultData.resource,
                          'l-form__item--m',
                        )}
                        tooltipInLabel={getFormFieldTranslationTooltip(
                          choice.defaultData.choice,
                          choice.defaultData.resource,
                          IMAGE_FORMATS.SQUARE,
                        )}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <SwitchableButtonBar
              updateAction={handleSubmit}
              removeAction={removeQuestion}
              isNew={null}
              previewAction={() => openPollPreview(question._id)}
            />
          </form>
        );
      }}
    />
  );
}

FourImageQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  updateQuestion: PropTypes.func.isRequired,
  CHOICE_LABELS: PropTypes.array.isRequired,
  uploadChoiceImage: PropTypes.func.isRequired,
  removeChoiceImage: PropTypes.func.isRequired,
  removeQuestion: PropTypes.func.isRequired,
  openPollPreview: PropTypes.func.isRequired,
  showConditionalLogic: PropTypes.bool.isRequired,
  Store: PropTypes.object.isRequired,
};

export default observer(FourImageQuestion);
