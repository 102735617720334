(function () {
  const _ = require('lodash');

  module.exports = function () {
    const serviceState = {
      ENABLED: 'ENABLED',
      TERMINATED: 'TERMINATED',
      TRIAL: 'TRIAL',
    };

    const serviceServices = {
      basicEngageService: 'BASIC_ENGAGE_SERVICE',
      pollsOnlyEngageService: 'POLLS_ONLY_ENGAGE_SERVICE',
      personalDataEngageService: 'PERSONAL_DATA_ENGAGE_SERVICE',
    };

    const serviceLabels = {
      basicEngageService: 'GMV Basic',
      pollsOnlyEngageService: 'GMV Survey only',
      personalDataEngageService: 'GMV Personal Data Collecting',
      enabled: 'Enabled',
      terminnated: 'Terminated',
      trial: 'Trial',
    };

    const serviceToLabel = {
      BASIC_ENGAGE_SERVICE: serviceLabels.basicEngageService,
      POLLS_ONLY_ENGAGE_SERVICE: serviceLabels.pollsOnlyEngageService,
      PERSONAL_DATA_ENGAGE_SERVICE: serviceLabels.personalDataEngageService,
      ENABLED: serviceLabels.enabled,
      TERMINATED: serviceLabels.terminnated,
      TRIAL: serviceLabels.trial,
    };

    return {
      serviceServices: serviceServices,
      serviceServicesList: serviceServicesList,
      serviceState: serviceState,
      serviceStateList: serviceStateList,
      serviceLabels: serviceLabels,
      serviceToLabel: serviceToLabel,
    };
    /**
     *
     */
    function serviceServicesList() {
      return _.map(Object.keys(serviceServices), function (key) {
        return serviceServices[key];
      });
    }
    /**
     *
     */
    function serviceStateList() {
      return _.map(Object.keys(serviceState), function (key) {
        return serviceState[key];
      });
    }
  };
})();
