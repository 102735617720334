
module.exports = function(){

  let _ = require('lodash');

  const DEFAULT_LANGUAGE = 'en';
  const N_A = 'n/a';

  const ENGAGE_LANGUAGES = [
    {languageCode:'ar', languageTitleEN:'Arabic',   languageTitle:'العربية', script:'arabic', rightToLeft: true, available: true},
    {languageCode:'bn', languageTitleEN:'Bengali',  languageTitle:'বাংলা', script:'bengali', rightToLeft: false, available: true},
    {languageCode:'en', languageTitleEN:'English',  languageTitle:'English', script:'latin', rightToLeft: false, available: true},
    {languageCode:'es', languageTitleEN:'Spanish',  languageTitle:'Español', script:'latin', rightToLeft: false, available: false},
    {languageCode:'cy', languageTitleEN:'Welsh',    languageTitle:'Cymraeg', script:'latin', rightToLeft: false, available: true},
    {languageCode:'gu', languageTitleEN:'Gujarati', languageTitle:'ગુજરાતી', script:'gujarati', rightToLeft: false, available: true},
    {languageCode:'pa', languageTitleEN:'Punjabi',  languageTitle:'ਪੰਜਾਬੀ', script:'arabic', rightToLeft: false, available: true},
    {languageCode:'pl', languageTitleEN:'Polish',   languageTitle:'Polski', script:'latin', rightToLeft: false, available: true},
    {languageCode:'ur', languageTitleEN:'Urdu',     languageTitle:'اُردو', script:'arabic', rightToLeft: true, available: true},
    {languageCode:'de', languageTitleEN:'German',   languageTitle:'Deutsch', script:'latin', rightToLeft: false, available: false},
    {languageCode:'hi', languageTitleEN:'Hindi',    languageTitle:'हिंदी', script:'devanagari', rightToLeft: false, available: true},
    {languageCode:'lt', languageTitleEN:'Lithuanian',   languageTitle:'Lietuviškai', script:'latin', rightToLeft: false, available: true},
    {languageCode:'fa', languageTitleEN:'Persian (Farsi)',    languageTitle:'فارسی', script:'persian', rightToLeft: true, available: true},
    {languageCode:'so', languageTitleEN:'Somali',   languageTitle:'Soomaali', script:'latin', rightToLeft: false, available: true},
    {languageCode:'tr', languageTitleEN:'Turkish',  languageTitle:'Türkçe', script:'latin', rightToLeft: false, available: true},
    {languageCode:'cmn', languageTitleEN:'Mandarin Chinese',    languageTitle:'汉语', script:'chinese', rightToLeft: false, available: false},
    {languageCode:'yue', languageTitleEN:'Cantonese',           languageTitle:'廣東話', script:'chinese', rightToLeft: false, available: false},
    {languageCode:'hr', languageTitleEN:'Croatian', languageTitle:'Hrvatski', script:'latin', rightToLeft: false, available:true},
    {languageCode:'fr', languageTitleEN:'French',  languageTitle:'Français', script:'latin', rightToLeft: false, available: true},
    {languageCode:'ta', languageTitleEN:'Tamil',  languageTitle:'தமிழ்', script:'tamil', rightToLeft: false, available: true},
  ];
  const LanguageDataCompletness = {
      ALL_COMPLETE: 'all-complete',
      DEFAULT_ONLY_COMPLETE: 'default-only-complete',
      DEFAULT_INCOMPLETE: 'default-incomplete',
      LANGUAGE_COMPLETE: 'language-complete',
      LANGUAGE_INCOMPLETE: 'language-incomplete',
      LANGUAGE_MISSING: 'language-missing'
    };

  return {
    engageLanguages: engageLanguages,
    LanguageDataCompletness: LanguageDataCompletness,
    engageLanguagesKeyEnMap: engageLanguagesKeyEnMap,
    DEFAULT_LANGUAGE,
    N_A
  };
  function engageLanguages(){
    return _.cloneDeep(ENGAGE_LANGUAGES);
  }
  /**
  *
  */
  function engageLanguagesKeyEnMap(){
    let aMap = {};
    _.map(ENGAGE_LANGUAGES, (item) => { aMap[item.languageCode] = item.languageTitleEN; });
    return aMap;
  }
};

