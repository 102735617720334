import { QUESTION_TYPES } from '../../../../common/constants/QuestionConstants';

export function perc(item, total) {
  return total > 0 ? Math.round((item / total) * 100) : 0;
}

export function getSentiment(sentiment, countFeedback) {
  function findSentiment(ins) {
    const s = sentiment ? sentiment.find((item) => item.sentiment === ins) : null;
    return s ? s.count : 0;
  }
  const mixed = findSentiment('MIXED');
  const negative = findSentiment('NEGATIVE');
  const neutral = findSentiment('NEUTRAL');
  const positive = findSentiment('POSITIVE');

  const sentimentMixed = perc(mixed, countFeedback);
  const sentimentNegative = perc(negative, countFeedback);
  const sentimentNeutral = perc(neutral, countFeedback);
  const sentimentPositive = perc(positive, countFeedback);

  const uncategorisedPercent = 100 - Math.round(((positive + negative + neutral + mixed) / countFeedback) * 100);

  return { sentimentPositive, sentimentMixed, sentimentNegative, sentimentNeutral, uncategorisedPercent };
}

export function isOtherChoice(choice, choices, questionType) {
  return choices.length === 3 && questionType === QUESTION_TYPES.MULTIPLE_IMAGE_OTHER_CHOICE
    ? choice.choiceId === choices[2].choiceId
    : false;
}
