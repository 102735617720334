import React from 'react';
import { Field } from 'react-final-form';
import SlimUploader from '../fileUpload/SlimUploader';
import Tooltip from '../../tooltip/Tooltip';

export default function ImageChoice(props) {
  const { choice, label, classModifier, fieldName, placeholder, maxLength, isRequired, imageFormat, tooltipInLabel } =
    props;

  const validateRequired = (value) => {
    // console.log();
    return !value && isRequired ? 'Required' : undefined;
  };

  const uploadChoiceImage = (file, progressHandler) => {
    // event.preventDefault();
    return props.handleUpload(choice._id, file, progressHandler);
  };

  const removeChoiceImage = (event) => {
    // console.log('remove', event);
    event.preventDefault();
    return props.removeImage(choice._id);
  };

  return (
    <div className={`c-field l-form__item ${classModifier}`}>
      <label className='c-field__label' htmlFor={`input-question-choice-${choice._id}`}>
        {label}
        {tooltipInLabel && <Tooltip {...tooltipInLabel} />}
      </label>
      <div className='c-field__group'>
        <SlimUploader
          imageFormat={imageFormat}
          imageId={choice.resource}
          defaultImageId={choice?.defaultData?.resource}
          id={`${choice._id}}`}
          label=''
          tip=''
          removeImage={removeChoiceImage}
          handleUpload={uploadChoiceImage}
          modifierClass='c-upload--option'
        />

        <Field name={fieldName} type='text' placeholder={placeholder} maxLength={maxLength} validate={validateRequired}>
          {/* eslint-disable-next-line no-unused-vars, no-shadow */}
          {({ input, meta, placeholder, maxLength }) => {
            // console.log(input, value);
            return (
              <React.Fragment>
                <div className='o-input'>
                  <input
                    {...input}
                    id={`input-question-choice-${choice._id}`}
                    placeholder={placeholder}
                    maxLength={maxLength}
                  />
                </div>
                <span
                  className={`c-field__count ${maxLength - input.value.length < 10 ? 'c-field__count--limit' : ''}`}
                  title='Characters left'
                >
                  {maxLength ? maxLength - input.value.length : ''}
                </span>
              </React.Fragment>
            );
          }}
        </Field>
      </div>
    </div>
  );
}
