import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import PropTypes from 'prop-types';

import ReorderHandle from '../../../common/components/reorderHandle/ReorderHandle';
import SvgIcon from '../../../common/components/SvgIcon';
import enums from '../../../shared/engage/engage-flow-enums';

const ENUMS = enums().HomepageSectionFlowTypes;

const blockObjects = {
  [ENUMS.INTRO]: {
    name: 'Intro',
    description: 'Introduction block containing a description of the project and a call to action',
  },
  [ENUMS.TIMELINE]: { name: 'Timeline', description: 'Current phase of the timeline' },
  [ENUMS.INFLUENCED_DECISIONS]: { name: 'Influenced decisions', description: 'Carousel of influenced decisions' },
  [ENUMS.MEDIA]: { name: 'Media', description: 'Carousel of project images' },
  [ENUMS.LOCATION]: { name: 'Location', description: 'Map showing location of the project' },
  [ENUMS.TEAM]: { name: 'Team', description: 'List of all team members' },
};

const decodeTitle = (itemName) => {
  return blockObjects[itemName] ? blockObjects[itemName] : { name: 'Untitled', description: 'Description' };
};

function BlockItem(props) {
  const { item, onChange } = props;

  return (
    <div className='c-reorder'>
      <ReorderHandle />
      <div className={`c-block-card ${item.visible ? '' : 'is-hidden'}`}>
        <h3 className='c-block-card__title'>{decodeTitle(item.sectionType).name}</h3>
        <div className='c-block-card__action'>
          <div className='o-checkbox o-checkbox--toggle o-checkbox--s'>
            <input
              type='checkbox'
              id={`checkbox-block-${item.sectionType}`}
              checked={item.visible}
              onChange={(e) => onChange(e, item.sectionType)}
            />
            <label htmlFor={`checkbox-block-${item.sectionType}`}>
              {item.visible ? 'Visible' : 'Hidden'}
              <SvgIcon icon='o-icon-check' />
            </label>
          </div>
        </div>
        <p className='c-block-card__description'>{decodeTitle(item.sectionType).description}</p>
      </div>
    </div>
  );
}

const SortableItem = SortableElement((props) => {
  return <BlockItem {...props} />;
});

export default SortableItem;

BlockItem.propTypes = {
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
