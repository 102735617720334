import React from 'react';
import { inject, observer } from 'mobx-react';

import { HELP } from 'shared/engage/engage-help-enums';
import ActionBar from '../../../common/components/actionBar/ActionBar';
import InlineButton from '../../../common/components/inlineButton/InlineButton';
import PanelItem from './PanelItem';

function Panels(props) {
  const { Store } = props;

  const addNew = () => {
    Store.create();
  };

  return (
    <div className='l-main'>
      <ActionBar
        addNew={addNew}
        label='Panels'
        hasButton={true}
        buttonLabel='Add'
        helpKey={HELP.PROJECT.EDIT_PROJECT.CUSTOMISE.PANELS.PAGE}
        isTextOnlyHelp={true}
      />
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p>Panels can be placed at the top of pages to provide a message to users such as "Surveys Closed".</p>
      <div className='c-switchable-list c-switchable-list--polls'>
        {/* <PanelItem panel={{ data: { title: 'sax'}, isOpened: true  }} /> */}
        {Store.panels.map((panel, index) => (
          <PanelItem key={panel.data._id} index={index} panel={panel} switchableModifier='c-switchable--reorder' />
        ))}
      </div>
      {Store.panels.length > 8 && <InlineButton onClick={addNew} buttonLabel='Add' />}
    </div>
  );
}

export default inject((root) => ({ Store: root.RootStore.panelsStore }))(observer(Panels));
