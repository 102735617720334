import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import GeneratedReportsCompleted from './GeneratedReportsCompleted';
import GeneratedReportsWorking from './GeneratedReportsWorking';

const { FAILED, COMPLETED, RUNNING, QUEUED } = require('../../../../../shared/engage/engage-report-enums')()
  .QUEUE_STATUS;

function GeneratedReports(props) {
  const { projectId, ReportsStore } = props;
  const reportStatuses = ReportsStore.reportStatuses;

  useEffect(() => {
    // skip if not yet initialized
    if (ReportsStore && projectId) {
      ReportsStore.getJobStatus(projectId);
      ReportsStore.setCurrentProjectId(projectId);
    }
  }, [projectId, ReportsStore]);

  return (
    <React.Fragment>
      {reportStatuses.length > 0 ? (
        <div className='c-report-download'>
          <h3 className='c-report-download__title'>Generated reports</h3>
          <p className='c-report-download__description'>Links to download reports will expire after 20 minutes.</p>
          <div className='c-report-list'>
            {reportStatuses.map((report) => {
              return (
                <React.Fragment key={report._id}>
                  {COMPLETED === report.status ? (
                    <GeneratedReportsCompleted
                      name={report.reportKey}
                      timestamp={report.timestamp}
                      jobId={report._id}
                      dataType={report.dataType}
                      reportType={report.reportType}
                    />
                  ) : null}
                  {[RUNNING, QUEUED, FAILED].includes(report.status) ? (
                    <GeneratedReportsWorking
                      name={report.reportKey}
                      timestamp={report.timestamp}
                      isError={report.status === FAILED}
                    />
                  ) : null}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
}

GeneratedReports.propTypes = {
  ReportsStore: PropTypes.object.isRequired,
};

export default inject((root) => ({
  ReportsStore: root.RootStore.generateReportStore,
}))(observer(GeneratedReports));
