import React from 'react';
import PropTypes from 'prop-types';
import { SortableElement } from 'react-sortable-hoc';
import ImageUtils from '../../../ImageUtils';
import { IMAGE_FORMATS } from '../fileUpload/ImageFormatEnums';
import ReorderHandle from '../../reorderHandle/ReorderHandle';
import { getVideoThumbnail } from '../../../UrlUtils';

function CarouselImage(props) {
  const { image, disabled, onClick, selectedMediaItem, index } = props;

  return (
    <li
      className='c-multiple-image-upload-navigation__item c-multiple-image-upload-navigation__item--reorder'
      disabled={disabled}
      onClick={() => onClick(image)}
    >
      <button
        role='tab'
        tabIndex={index}
        aria-selected={image?._id === selectedMediaItem?._id}
        aria-controls={`${image}-multiple-image-upload--panel`}
        type='button'
      >
        {image.mediaType === 'image' && (
          <img src={ImageUtils.imageUrl(image.url, IMAGE_FORMATS.AVATAR.imageType)} alt='' />
        )}

        {image.mediaType === 'video' && <img src={getVideoThumbnail(image.url)} alt='' />}

        <ReorderHandle />
      </button>
    </li>
  );
}

CarouselImage.propTypes = {
  image: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  selectedMediaItem: PropTypes.object,
  index: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};

/**
 * Sortable item wrapper
 */
const SortableItem = SortableElement((props) => {
  return <CarouselImage {...props} />;
});

export default SortableItem;
