import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SlimFileUpload from '../fileUpload/SlimFileUpload';
import EngageTextInputWithAction from '../textInputField/TextInputWithAction';
import { validateYoutubeUrl } from '../../../UrlUtils';

function MediaUploader(props) {
  const { id, uploadImage, uploadVideo, imageFormat, defaultImageId } = props;

  const [videoUrl, setVideoUrl] = useState('');
  const [meta, setMeta] = useState({});

  function uploadVideoHandler() {
    const validationMessage = validateYoutubeUrl(videoUrl);
    if (!validationMessage) {
      uploadVideo(videoUrl).then(() => {
        setVideoUrl('');
        setMeta({});
      });
    } else {
      setMeta({ error: validationMessage, touched: true });
    }
  }

  function changeHandler(event) {
    const newValue = event.target.value;
    setVideoUrl(newValue);

    const validationMessage = validateYoutubeUrl(newValue);
    if (!validationMessage || !newValue) {
      setMeta({});
    } else {
      setMeta({ error: validationMessage, touched: true });
    }
  }

  return (
    <div className='c-field__group'>
      <div className='c-upload-group'>
        <div className='c-upload-group__item'>
          <SlimFileUpload
            imageFormat={imageFormat}
            id={id}
            handleUpload={uploadImage}
            label=''
            classModifier='c-field--label-hidden'
            removeImage={() => {}}
            defaultImageId={defaultImageId}
          />
        </div>
        <div className='c-upload-group__separator' />
        <div className='c-upload-group__item'>
          <EngageTextInputWithAction
            id={id}
            buttonLabel='Add'
            label='Add video'
            placeholder='Paste YouTube link'
            fieldDescription='Paste the full <strong>YouTube video link</strong> to add it to the gallery. E.g. https://www.youtube.com/watch?v=pHueSvBACQs'
            fieldName='resource'
            isRequired={false}
            input={{ value: videoUrl, onChange: changeHandler }}
            onClick={uploadVideoHandler}
            buttonType='button'
            meta={meta}
          />
        </div>
      </div>
    </div>
  );
}

MediaUploader.propTypes = {
  uploadImage: PropTypes.func.isRequired,
  uploadVideo: PropTypes.func.isRequired,
  imageFormat: PropTypes.object.isRequired,
  defaultImageId: PropTypes.string,
};

export default MediaUploader;
