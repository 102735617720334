import { QUESTION_CONDITIONAL_LABELS } from 'common/constants/QuestionConstants';
import { PollEnums } from 'shared/engage';

const GRAPH_BASE = 'stateDiagram\n';

function addEdge(source, destination, adjacencyList) {
  adjacencyList[source].push(destination);
}

function isCyclic(numberOfVertices, adjacencyList) {
  const inDegree = new Array(numberOfVertices).fill(0); // stores in-degree of each vertex
  const q = []; // queue to store vertices with 0 in-degree
  let visited = 0; // count of visited vertices

  // calculate in-degree of each vertex
  for (let u = 0; u < numberOfVertices; u++) {
    adjacencyList[u].forEach((v) => {
      inDegree[v]++;
    });
  }

  // enqueue vertices with 0 in-degree
  for (let u = 0; u < numberOfVertices; u++) {
    if (inDegree[u] === 0) {
      q.push(u);
    }
  }

  // BFS traversal
  while (q.length > 0) {
    const u = q.shift();
    visited++;

    // reduce in-degree of adjacent vertices
    adjacencyList[u].forEach((v) => {
      inDegree[v]--;
      // if in-degree becomes 0, enqueue the vertex
      if (inDegree[v] === 0) {
        q.push(v);
      }
    });
  }

  if (visited !== numberOfVertices) {
    // console.log('Graph contains a cycle.');
  } else {
    // console.log('Graph does not contain a cycle.');
  }

  return visited !== numberOfVertices; // if not all vertices are visited, there is a cycle
}

function isCyclicGraph(conditionalLogicNodes) {
  // console.log('INPUT: ', conditionalLogicNodes);
  const set = new Set(conditionalLogicNodes.flat());
  const numberOfVertices = set.size; // determine number of vertices (questions)
  const adjacencyList = new Array(numberOfVertices).fill(null).map(() => []); // create adjacency list

  conditionalLogicNodes.forEach((edge) => addEdge(edge[0], edge[1], adjacencyList));

  // console.log('OUTPUT: ', adjacencyList);

  return isCyclic(numberOfVertices, adjacencyList);
}

function multiLineTextProcessing(input = '') {
  const output = input
    .replaceAll(/(\r\n|\r)/gm, '\n')
    .replaceAll('\n\n', '\n')
    .replaceAll('\n', '\\n');
  return output;
}

function prepareGraph(questions) {
  const graphData = { graph: '', cyclicGraph: false };

  try {
    if (questions.length === 0) return graphData;

    const startOfPollNode = {
      id: '[*]',
      transitionLabel: 'Start of survey',
    };
    const endOfPollNode = {
      id: '[*]',
    };

    const questionNodes = [];
    questions.forEach((question, questionIndex) => {
      const questionLabel = multiLineTextProcessing(question.questionText);
      const questionNode = {
        id: questionIndex,
        questionLabel: questionLabel || 'No title',
        destinations: [],
      };

      const destinationNodes = [];
      const conditionalExecution = question?.questionConfiguration?.conditionalExecution;

      const nextPollQuestion = questions[questionIndex + 1];
      const nextPollQuestionNode = nextPollQuestion
        ? {
            id: questionIndex + 1,
            questionLabel: nextPollQuestion.questionText || 'No title',
            transitionLabel: '',
          }
        : null;

      // Set 'Next question', 'End of survey', 'else' destination node
      if (!conditionalExecution && nextPollQuestionNode) {
        destinationNodes.push(nextPollQuestionNode);
      } else if (
        nextPollQuestionNode &&
        conditionalExecution.else === null &&
        (conditionalExecution?.rules?.length !== question.choices.length ||
          (conditionalExecution?.rules?.length === question.choices.length &&
            (PollEnums().isSliderQuestion(PollEnums().QuestionTypes[question.questionType]) ||
              question.questionType === PollEnums().QuestionTypes.RATING)))
      ) {
        destinationNodes.push(nextPollQuestionNode);
      } else if (conditionalExecution?.else === -1 || (!conditionalExecution && !nextPollQuestionNode)) {
        destinationNodes.push(endOfPollNode);
      } else if (conditionalExecution?.else) {
        const elseDestinationQuestion = questions.find(
          (pollQuestion) => pollQuestion._id === conditionalExecution.else,
        );
        if (elseDestinationQuestion) {
          const elseDestinationQuestionIndex = questions.findIndex(
            (pollQuestion) => pollQuestion._id === elseDestinationQuestion._id,
          );
          const elseDestinationQuestionNode = {
            id: elseDestinationQuestionIndex,
            transitionLabel: conditionalExecution.rules?.length ? 'Else' : '',
          };
          destinationNodes.push(elseDestinationQuestionNode);
        }
      }

      // Set rules destination nodes
      if (conditionalExecution) {
        conditionalExecution.rules.forEach((rule) => {
          const jumpToDestinationQuestion = questions.find((pollQuestion) => pollQuestion._id === rule.jumpTo);

          // Prepare transition labels from rule conditions
          let conditionsLabel = '';
          rule.condition.forEach((condition, index) => {
            if (condition.logicOp) {
              const logicOpLabel = QUESTION_CONDITIONAL_LABELS[condition.logicOp];
              conditionsLabel = conditionsLabel.concat(
                index === 0 ? `${logicOpLabel} ` : `${logicOpLabel.toLowerCase()} `,
              );
            }

            if (condition.criteria) {
              const criteriaLabel = QUESTION_CONDITIONAL_LABELS[condition.criteria];
              conditionsLabel = conditionsLabel.concat(
                index === 0 ? `${criteriaLabel} ` : `${criteriaLabel.toLowerCase()} `,
              );
            }

            if (
              PollEnums().isSliderQuestion(PollEnums().QuestionTypes[question.questionType]) ||
              question.questionType === PollEnums().QuestionTypes.RATING
            ) {
              conditionsLabel = conditionsLabel.concat(`${condition.value} `);
            } else {
              const choice = question.choices.find((ch) => ch._id === condition.choiceId);
              if (choice) conditionsLabel = conditionsLabel.concat(`${choice.choice} `);
            }
          });

          if (jumpToDestinationQuestion) {
            const jumpToDestinationQuestionNodeIndex = questions.findIndex(
              (pollQuestion) => pollQuestion._id === jumpToDestinationQuestion._id,
            );
            const jumpToDestinationQuestionNode = {
              id: jumpToDestinationQuestionNodeIndex,
              questionLabel: jumpToDestinationQuestion.questionText || 'No title',
              transitionLabel: conditionsLabel,
            };

            destinationNodes.push(jumpToDestinationQuestionNode);
          } else {
            destinationNodes.push({ id: '[*]', transitionLabel: conditionsLabel, questionLabel: 'End of survey' });
          }
        });
      }

      questionNode.destinations = destinationNodes;

      questionNodes.push(questionNode);
    });

    // Prepare graph definition string
    // Write list of questions with identifiers
    let graph = GRAPH_BASE;
    questionNodes.forEach((element) => {
      const questionLabel = element.questionLabel
        .replaceAll(';', '#semi;')
        .replaceAll('{', '#lbrace;')
        .replaceAll('}', '#rbrace;')
        .replaceAll(':', '#colon;');
      graph = graph.concat(`${element.id}: ${element.id + 1}.${questionLabel}\n`);
    });

    const graphNodes = [];
    // Write node relations (using identifiers)
    graph = graph.concat(`${startOfPollNode.id}-->${questionNodes[0].id}\n`);
    questionNodes.forEach((element) => {
      element.destinations.forEach((destination, index) => {
        graph = graph.concat(
          `${element.id}-->${destination.id}${destination.transitionLabel ? `:${destination.transitionLabel}` : ''}\n`,
        );
        if (destination.id !== '[*]') graphNodes.push([element.id, destination.id]);
      });
    });

    const cyclicGraph = isCyclicGraph(graphNodes);
    graphData.graph = graph === GRAPH_BASE ? '' : graph;
    graphData.cyclicGraph = cyclicGraph;
  } catch (error) {
    graphData.graph = GRAPH_BASE.concat('0: Not possible to prepare graph data.');
    // console.error('Error preparing graph data:', error);
  }

  return graphData;
}

export { prepareGraph, isCyclicGraph };
