import React from 'react';
import PropTypes from 'prop-types';
import LanguageItemStatus from './LanguageItemStatus';

function UpdateDetailsRow(props) {
  const { rowLabel, languageData } = props;

  return (
    <tr data-testid={`${rowLabel}-details-row`}>
      <td>{rowLabel}</td>
      {languageData.map((languageItem, index) => (
        <LanguageItemStatus key={`${languageItem.language}-${index}`} languageItemStatus={languageItem.status} />
      ))}
    </tr>
  );
}

UpdateDetailsRow.propTypes = {
  rowLabel: PropTypes.string.isRequired,
  languageData: PropTypes.array.isRequired,
};

export default UpdateDetailsRow;
