import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import SwitchableButtonBar from '../../../../../common/components/switchableButtonBar/SwitchableButtonBar';
import EngageTextInputField from '../../../../../common/components/form-elements/textInputField/TextInputField';
import { QUESTION_CHARACTER_LIMIT } from '../../../../../common/constants/QuestionConstants';
import EngageCheckboxInputField from '../../../../../common/components/form-elements/checkboxInputField/CheckboxInputField';
import EngageCheckboxGroupInputField from '../../../../../common/components/form-elements/checkboxGroupInputField/CheckboxGroupInputField';
import {
  prepareFeedbackQuestionConfigFormValues,
  prepareFeedbackQuestionPersonalInfoGroupData,
  prepareFeedbackQuestionSubmitConfig,
  FEEDBACK_QUESTION_PERSONAL_INFO_PREFIX,
  FEEDBACK_QUESTION_ALLOW_IMAGE_FIELD,
  FEEDBACK_QUESTION_COLLECT_EMAIL_ONLY,
  FEEDBACK_QUESTION_PERSONAL_INFO_EMAIL_FIELD,
  FEEDBACK_QUESTION_PERSONAL_INFO_POST_CODE_FIELD,
} from '../../PollUtils';
import { getFormFieldClass, getFormFieldTranslationTooltip } from '../../../../../common/TranslationUtils';
import ConditionalLogic from '../../../../../common/components/conditionalLogic/ConditionalLogic';
import ConditionalLogicUtil from '../../../../../common/ConditionalLogicUtil';

function FeedbackQuestion(props) {
  const { question, updateQuestion, removeQuestion, openPollPreview, showConditionalLogic, Store } = props;

  const [choices, setChoices] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [rules, setRules] = useState([]);

  const [isEmailOnly, setIsEmailOnly] = useState();

  useEffect(() => {
    if (!question) return;

    const initialFormQuestionValues = {
      questionText: question.questionText,
    };

    setIsEmailOnly(question.questionConfiguration?.collectEmailOnly);

    const configurationValues = prepareFeedbackQuestionConfigFormValues(question, initialFormQuestionValues);
    const prepChoices = prepareFeedbackQuestionPersonalInfoGroupData(question, configurationValues);
    setChoices(prepChoices);

    const initialState = ConditionalLogicUtil.setupFormInitialValues(question, initialFormQuestionValues);
    setFormValues({ ...initialFormQuestionValues, ...initialState.initialFormConditionValues });
    setRules(initialState.initialRules);
  }, [question]);

  const onSubmit = (values) => {
    const updatedQuestionData = toJS(question);
    // Question content
    updatedQuestionData.questionText = values.questionText;

    prepareFeedbackQuestionSubmitConfig(updatedQuestionData, values);

    ConditionalLogicUtil.extractConditionalFormValues(question, updatedQuestionData, values, rules);

    updateQuestion(updatedQuestionData);
  };

  function emailOnlyChangeHandler(args, state) {
    setIsEmailOnly(state.formState.values.emailOny);

    if (state.formState.values.emailOny) {
      const allowImagesField = state.fields.allowImages;
      const requireEmailField =
        state.fields[
          `${FEEDBACK_QUESTION_PERSONAL_INFO_PREFIX}-${FEEDBACK_QUESTION_PERSONAL_INFO_EMAIL_FIELD}-${question._id}`
        ];
      const requirePostcodeField =
        state.fields[
          `${FEEDBACK_QUESTION_PERSONAL_INFO_PREFIX}-${FEEDBACK_QUESTION_PERSONAL_INFO_POST_CODE_FIELD}-${question._id}`
        ];
      allowImagesField.change(false);
      requireEmailField.change(false);
      requirePostcodeField.change(false);
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      keepDirtyOnReinitialize={true}
      initialValues={formValues}
      mutators={{ emailOnlyChangeHandler }}
      render={({ values, handleSubmit, form }) => {
        return (
          <form className='c-fields l-form' onSubmit={(event) => event.preventDefault()}>
            <EngageTextInputField
              id={`input-question-${question._id}`}
              placeholder='Enter question'
              label='Question'
              maxLength={QUESTION_CHARACTER_LIMIT}
              fieldDescription='Ask users to provide additional feedback. If people decide to write feedback (up to 2000 characters)
                they will have to provide an e-mail address. This question is best placed at the end of the survey.'
              fieldName='questionText'
              isRequired={true}
              fieldClass={getFormFieldClass(question.defaultData.questionText, null, 'l-form__item')}
              tooltipInLabel={getFormFieldTranslationTooltip(question.defaultData.questionText)}
            />

            <div className='c-toggle-content'>
              <div className='c-toggle-content__container'>
                <ConditionalLogic
                  formValues={values}
                  setFormValues={setFormValues}
                  question={question}
                  questions={Store.poll.questions}
                  rules={rules}
                  setRules={setRules}
                  show={showConditionalLogic}
                  choiceFieldNames={[]}
                />
                <div className='c-toggle-content__content' hidden={showConditionalLogic}>
                  <div className='c-field l-form__item'>
                    <EngageCheckboxInputField
                      id={`checkbox-allow-images-${question._id}`}
                      type='checkbox'
                      fieldName={FEEDBACK_QUESTION_ALLOW_IMAGE_FIELD}
                      fieldClass='l-form__item'
                      label='Image upload'
                      labelTooltip='Users will be able to attach an image with their feedback.'
                      isToggle={true}
                      checkedLabel='Enable images'
                      uncheckedLabel='Enable images'
                      hideLabel={false}
                      disabled={isEmailOnly}
                    />
                    <EngageCheckboxGroupInputField
                      choices={choices}
                      label='Mandatory personal information'
                      fieldName={FEEDBACK_QUESTION_PERSONAL_INFO_PREFIX}
                      isToggle={true}
                      disabled={isEmailOnly}
                    />
                    <EngageCheckboxInputField
                      id={`checkbox-email-only-${question._id}`}
                      type='checkbox'
                      fieldName={FEEDBACK_QUESTION_COLLECT_EMAIL_ONLY}
                      fieldClass='l-form__item'
                      label='Collect email only'
                      labelTooltip='If checked users will only be able to provide email but not additional feedback.'
                      isToggle={true}
                      checkedLabel='Yes'
                      uncheckedLabel='No'
                      hideLabel={false}
                      onSelectionChange={form.mutators.emailOnlyChangeHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
            <SwitchableButtonBar
              updateAction={handleSubmit}
              removeAction={removeQuestion}
              isNew={null}
              previewAction={() => openPollPreview(question._id)}
            />
          </form>
        );
      }}
    />
  );
}

FeedbackQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  updateQuestion: PropTypes.func.isRequired,
  removeQuestion: PropTypes.func.isRequired,
  openPollPreview: PropTypes.func.isRequired,
  showConditionalLogic: PropTypes.bool.isRequired,
  Store: PropTypes.object.isRequired,
};

export default observer(FeedbackQuestion);
