import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import SvgIcon from '../../../../common/components/SvgIcon';
import DateUtils from '../../../../common/DateUtils';
import FeedbackToolbar from './FeedbackToolbar';
import FeedbackForm from './FeedbackForm';

const SentimentTypes = require('../../../../shared/engage').SentimentEnums().SentimentType;

function SentimentIndicator(props) {
  const { feedbackItem } = props;

  let icon = '';
  let label = '';
  if (feedbackItem.sentimentAnalysis) {
    switch (feedbackItem.sentimentAnalysis.sentiment) {
      case SentimentTypes.POSITIVE:
        icon = 'grin';
        label = 'Positive';
        break;
      case SentimentTypes.NEGATIVE:
        icon = 'frown';
        label = 'Negative';
        break;
      case SentimentTypes.NEUTRAL:
        icon = 'meh-blank';
        label = 'Neutral';
        break;
      case SentimentTypes.MIXED:
        icon = 'meh';
        label = 'Mixed';
        break;
      default:
        icon = '';
        label = '';
        break;
    }
  }

  return (
    <span className='c-feedback__sentiment'>
      <SvgIcon icon={icon} />
      <strong>{` ${label}`}</strong>
    </span>
  );
}

function FeedbackItem(props) {
  const { feedbackItem, Store } = props;

  const highlight = `${feedbackItem.highlight ? 'c-feedback--highlight' : ''}`;
  const replyOpen = `${feedbackItem.isReplyOpen ? 'c-feedback--reply' : ''}`;
  // const itemType = Store.getTypeLabel(feedbackType);

  const toggleOpened = () => {
    Store.toggleOpened(feedbackItem);
  };

  const toggleHighLight = () => {
    Store.toggleHighLight(feedbackItem);
  };

  const removeItem = () => {
    Store.remove(feedbackItem);
  };

  return (
    <div className={`c-feedback jsFeedback ${highlight} ${replyOpen}`}>
      <div className='c-feedback__header'>
        {feedbackItem.sentimentAnalysis && <SentimentIndicator feedbackItem={feedbackItem} />}
        {/* // issue Type is no longer needed https://github.com/sdesregistry/IH-Engage/issues/667
        <span className='c-feedback__type'>{itemType}</span> */}
        {feedbackItem.engageUsername && <strong className='c-feedback__author'>{feedbackItem.engageUsername}</strong>}
        <span className='c-feedback__time'>{DateUtils.getTimeDateString(feedbackItem.timestamp)}</span>
        <strong className='c-feedback__author'>{feedbackItem.email}</strong>
        <FeedbackToolbar toggleOpened={toggleOpened} toggleHighLight={toggleHighLight} removeItem={removeItem} />
      </div>
      <div className='c-feedback__subject'>{feedbackItem.subject}</div>
      <div className='c-feedback__content'>
        <p>{feedbackItem.description}</p>
      </div>
      {feedbackItem.adminResponse && (
        <div className='c-feedback__reply'>
          <span className='c-feedback__reply-meta'>
            <SvgIcon icon='reply' />
            Replied on {DateUtils.getTimeDateString(feedbackItem.adminResponseTimestamp)}
          </span>
          <p>{feedbackItem.adminResponse}</p>
        </div>
      )}

      {!feedbackItem.adminResponse && <FeedbackForm item={feedbackItem} />}
    </div>
  );
}

FeedbackItem.propTypes = {
  Store: PropTypes.object.isRequired,
  feedbackItem: PropTypes.shape({
    highlight: PropTypes.bool,
    isReplyOpen: PropTypes.bool,
    sentimentAnalysis: PropTypes.object,
    engageUsername: PropTypes.string,
    timestamp: PropTypes.string,
    description: PropTypes.string,
    adminResponseTimestamp: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    adminResponse: PropTypes.string,
  }).isRequired,
};

export default inject((root) => ({ Store: root.RootStore.feedbackStore }))(observer(FeedbackItem));
