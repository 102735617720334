/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { DISABLE_ANNOTATE_IMAGE_QUESTION } from 'shared/engage/client-constants';
import { PollEnums } from 'shared/engage';
import cloneDeep from 'lodash.clonedeep';
import { HELP, SURVEY_QUESTIONS } from 'shared/engage/engage-help-enums';
import { QUESTION_TYPES } from '../../../../common/constants/QuestionConstants';
import SwitchableButtonBar from '../../../../common/components/switchableButtonBar/SwitchableButtonBar';
import PollChoice from './PollChoice';
import SvgIcon from '../../../../common/components/SvgIcon';
import HelpUtils from '../../../../common/HelpUtils';

function QuestionPicker(props) {
  const { createQuestion, setPickerVisible, isQQ = false, isDemographic = false, alreadyPicked = [] } = props;

  const [allQuestionTypes, setAllQuestionTypes] = useState();
  const [currentQuestionTypes, setCurrentQuestionTypes] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState();
  const [selectedQuestion, setSelectedQuestion] = useState();

  const temporaryRemoveAnnotateImageQuestion = useCallback((filteredByTypes) => {
    const filteredTypes = [];
    filteredByTypes.forEach((q) => {
      if (q[0] !== 'ANNOTATE_GROUP') {
        filteredTypes.push(q);
        return;
      }

      const itemClone = cloneDeep(q);
      delete itemClone[1].items[QUESTION_TYPES.ANNOTATION_IMAGE];
      filteredTypes.push([...Object.keys(itemClone[1].items), ...Object.values(itemClone[1].items)]);
    });

    return filteredTypes;
  }, []);

  useEffect(() => {
    if (!allQuestionTypes) {
      // Filter questions based on context (poll, demographic, segmentation)
      const filteredByTypes = Object.entries(QUESTION_TYPES.properties).filter((item) => {
        // Filter only QQ, demographic or non-QQ based on flag (item.isQQ and item.isDemographic = undefined for non-QQ items)
        return (
          (item[1].isQQ && isQQ) ||
          (!item[1].isQQ && !isQQ && !item[1].isDemographic && !isDemographic) ||
          (item[1].isDemographic && isDemographic)
        );
      });

      // Set list data provider
      if (isDemographic) {
        // Mark already added demographic questions as disabled
        const preparedDemographicQuestions = filteredByTypes.map((q) => {
          if (
            alreadyPicked.find(
              (picked) => picked.questionType === q[0].toLowerCase() && q[0] !== QUESTION_TYPES.DEMOGRAPHIC_CUSTOM_TYPE,
            )
          ) {
            q.disabled = true;
          } else {
            q.disabled = false;
          }

          return q;
        });

        setAllQuestionTypes(preparedDemographicQuestions);
        setCurrentQuestionTypes(preparedDemographicQuestions);
      } else if (DISABLE_ANNOTATE_IMAGE_QUESTION) {
        // Temoporary remove annotate image question [Issue#2246 - https://github.com/sdesregistry/IH-Engage/issues/2246]
        const _filteredByTypes = temporaryRemoveAnnotateImageQuestion(filteredByTypes);
        setAllQuestionTypes(_filteredByTypes);
        setCurrentQuestionTypes(_filteredByTypes);
      } else {
        setAllQuestionTypes(filteredByTypes);
        setCurrentQuestionTypes(filteredByTypes);
      }
    }
  }, [allQuestionTypes, alreadyPicked, isDemographic, isQQ, temporaryRemoveAnnotateImageQuestion]);

  function closePicker() {
    setPickerVisible(false);
  }

  function handleSubmit() {
    createQuestion(selectedQuestion[0]);
    setPickerVisible(false);
  }

  function listSelectHandler(type) {
    if (type.disabled) return;

    if (type[1].items) {
      setSelectedGroup(type[1]);
      setCurrentQuestionTypes(Object.entries(type[1].items));
      setSelectedQuestion();
    } else {
      setSelectedQuestion(type);
    }
  }

  function exitQuestionGroup() {
    setCurrentQuestionTypes(allQuestionTypes);
    setSelectedGroup();
    setSelectedQuestion();
  }

  return (
    <div className='c-poll-add'>
      <form onSubmit={handleSubmit} className='c-fields l-form'>
        <div className={`l-form__item ${selectedGroup ? 'c-poll-add__submenu' : 'c-poll-add__items'}`}>
          {selectedGroup ? (
            <div className='c-poll-add__label'>
              <button className='c-poll-add__back' type='button' onClick={exitQuestionGroup}>
                All questions
              </button>
              <SvgIcon icon='angle-right' />
              <span className='c-poll-add__active'>{selectedGroup.label}</span>
            </div>
          ) : (
            <div className='c-poll-add__label'>
              <span className='c-poll-add__active'>Select type</span>
            </div>
          )}

          <div className='c-poll-choice'>
            <ul className='c-poll-choice__items'>
              {currentQuestionTypes.map((type) => (
                <PollChoice
                  key={type[0]}
                  questionType={type}
                  selectHandler={listSelectHandler}
                  helpKey={HelpUtils.getHelpKeyForQuestionPicker(type[0])}
                />
              ))}
            </ul>
          </div>
        </div>

        <SwitchableButtonBar
          updateAction={selectedQuestion ? handleSubmit : null}
          removeAction={closePicker}
          isNew={true}
        />
      </form>
    </div>
  );
}

QuestionPicker.propTypes = {
  createQuestion: PropTypes.func.isRequired,
  setPickerVisible: PropTypes.func.isRequired,
  isQQ: PropTypes.bool,
  isDemographic: PropTypes.bool,
  alreadyPicked: PropTypes.array,
};

export default QuestionPicker;
