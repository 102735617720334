import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { PANELS, PROJECT } from 'common/constants/RouteConstants';
import PanelVisibility from './PanelVisibility';

function PanelSection(props) {
  const { Store, projectId } = props;

  const panels = Store?.publishStatus?.panels || [];

  function getPanelInstructionMessage() {
    let infoMessage = 'Only one panel can be visible on each page.';
    if (panels.length === 0) infoMessage = 'There are no panels.';

    return infoMessage;
  }

  function getPanelActionMessage() {
    return panels.length === 0 ? 'Add first panel' : 'Edit panels';
  }

  return (
    <form
      className='c-fields c-fields--modern l-form __web-inspector-hide-shortcut__'
      data-testid='panel-section-wrapper'
    >
      <h2 className='c-heading-section c-heading-section--large'>Panel visibility</h2>

      {panels?.length > 0 && <PanelVisibility projectId={projectId} />}

      <div className='c-message is-note-subtle c-message--large'>
        <p className='c-message__description'>
          {getPanelInstructionMessage()}
          <br />
          <Link to={`/${PROJECT}/${projectId}/edit/${PANELS}`}>{getPanelActionMessage()}</Link>
        </p>
      </div>
    </form>
  );
}

PanelSection.propTypes = {
  Store: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
};

export default inject((root) => ({
  Store: root.RootStore.projectControlStore,
}))(observer(PanelSection));
