import React, { useState, useEffect, useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import ActionBar from '../../../common/components/actionBar/ActionBar';
import EngageTextInputField from '../../../common/components/form-elements/textInputField/TextInputField';
import EngageSelectInputField from '../../../common/components/form-elements/selectInputField/SelectInputField';
import EngageRadioGroupInputField from '../../../common/components/form-elements/radioGroupInputField/RadioGroupInputField';
import { PROJECT_ACCESS_TYPES, SPECIFIC_PROJECTS, ALL_PROJECTS } from '../../../common/constants/AccessEnum';
import SwitchableButtonBar from '../../../common/components/switchableButtonBar/SwitchableButtonBar';
import ProjectItem from './ProjectItem';
import displayConfirmationPrompt from '../../../common/PromptUtil';
import ROLE_ENUMS from '../../../shared/engage/engage-role.enums';
import { USERS, EDIT_USERS, PROJECT, PROFILE } from '../../../common/constants/RouteConstants';
import history from '../../../common/history';

function EditUser(props) {
  const { UsersStore, ProjectListStore, ToastrStore, AuthStore, match } = props;
  const [user, setUser] = useState();
  const [showPermissionsTable, setShowPermissionsTable] = useState(false);
  const [defaultRole, setDefaultRole] = useState(null);
  const [allowedRoles, setAllowedRoles] = useState([]);
  const [projects, setProjects] = useState();

  const userId = match.params.userId;
  const clientId = AuthStore.clientId;
  const projectId = match.params.id;

  // eslint-disable-next-line no-shadow
  const preparePermissionsTableData = useCallback((allUsersProjects, user, clientId) => {
    const usersProjects = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const rawProject of allUsersProjects) {
      // Find matching project inside user.editingProfiles projects
      const matchingProject = user
        ? user.editingProfiles[0].editingProjects.find((project) => project.projectId === rawProject._id)
        : null;
      if (matchingProject) {
        rawProject.isAssigned = true;
        rawProject.projectsViewOnly = matchingProject.projectsViewOnly;
        rawProject.projectId = matchingProject.projectId;
        rawProject.roles = matchingProject.roles;
      } else {
        rawProject.isAssigned = false;
        rawProject.projectsViewOnly = true;
        rawProject.projectId = rawProject._id;
        rawProject.roles = [];
      }

      usersProjects.push(rawProject);
    }
    setProjects(usersProjects);
  }, []);

  useEffect(() => {
    if (clientId) {
      Promise.all([
        userId ? UsersStore.getById(clientId, userId) : Promise.resolve(null),
        UsersStore.getRoles(clientId),
        ProjectListStore.loadWithClientId(clientId),
      ]).then((data) => {
        const userData = data[0];
        const roles = data[1];
        const allUserClientsProjects = data[2];

        setUser(userData);

        const filteredRoles = roles.filter((role) => role.category === ROLE_ENUMS().category.CLIENT_COMPANY);
        const simplifiedFilteredRoles = filteredRoles.map((role) => [role._id, role.label]);
        setAllowedRoles(simplifiedFilteredRoles);
        setDefaultRole(filteredRoles[0]);

        preparePermissionsTableData(allUserClientsProjects, userData, clientId);
        setShowPermissionsTable(userData ? !userData.editingProfiles[0].allProjectsAccessible : false);
      });
    }
  }, [userId, clientId, UsersStore, ProjectListStore, preparePermissionsTableData]);

  /**
   * Form initial values
   */
  const initialValues = user
    ? {
        name: user.name,
        email: user.email,
        role: user.roles[0],
        allProjectsAccessible: user.editingProfiles[0].allProjectsAccessible ? ALL_PROJECTS : SPECIFIC_PROJECTS,
      }
    : {
        name: '',
        email: '',
        role: defaultRole ? defaultRole._id : '',
        allProjectsAccessible: ALL_PROJECTS,
      };

  function onProjectsAccessChanged(event) {
    setShowPermissionsTable(event.currentTarget.value === SPECIFIC_PROJECTS);
  }

  function onSubmit(values) {
    const newEditingProjects = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const project of projects) {
      if (project.isAssigned) {
        const editingProject = {
          _id: project.projectId,
          projectsViewOnly: project.projectsViewOnly,
        };
        newEditingProjects.push(editingProject);
      }
    }

    const userData = {
      name: values.name.trim(),
      email: values.email.trim(),
      roles: [values.role],
      allProfilesAccessible: user ? user.allProfilesAccessible : false,
      editingProfiles: [
        {
          profileId: userId ? user.editingProfiles[0].profileId : clientId,
          allProjectsAccessible: values.allProjectsAccessible === ALL_PROJECTS,
          editingProjects: newEditingProjects,
          roles: [values.role],
        },
      ],
    };

    if (userId) {
      UsersStore.update(clientId, userId, userData).then((updatedUser) => {
        if (updatedUser) history.push(`/${PROFILE}/${USERS}`);
      });
    } else {
      UsersStore.create(clientId, userData).then((createdUser) => {
        if (createdUser) history.push(`/${PROFILE}/${USERS}/${EDIT_USERS}/${createdUser._id}`);
      });
    }
  }

  function remove(event) {
    event.preventDefault();
    if (!userId) {
      history.goBack();
    } else {
      const prompt = `To delete please enter user's e-mail: \n${user.email}`;
      const res = displayConfirmationPrompt(prompt, user.email, false, ToastrStore, true);

      if (res === 1) {
        UsersStore.remove(clientId, userId).then((deletedUserId) => {
          if (deletedUserId) history.push(`/${PROJECT}/${projectId}/${USERS}`);
        });
      }
    }
  }

  return (
    <div className='l-main' data-testid='information-container'>
      <ActionBar label={user ? 'Edit user' : 'New user'} hasButton={false} />
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ values, form, handleSubmit, submitting, pristine }) => {
          return (
            <form className='c-fields l-form' data-testid='information-form'>
              <EngageTextInputField
                id='input-user-name'
                placeholder='Name'
                label='Name'
                maxLength='35'
                fieldName='name'
                isRequired={true}
                fieldClass='l-form__item--m'
              />
              <EngageTextInputField
                id='input-user-email'
                placeholder='Email'
                label='Email'
                fieldName='email'
                isRequired={true}
                fieldClass='l-form__item--m'
              />
              <EngageSelectInputField
                choices={allowedRoles}
                id='input-user-role'
                label='Select role'
                fieldName='role'
                fieldClass='l-form__item--m'
                firstOptionEmpty={false}
              />

              <div className='c-field l-form__item'>
                <label className='c-field__label' htmlFor='input-password'>
                  Password
                </label>
                <div className='c-field__group'>
                  <p className='c-field__description'>
                    Users will receive an email with instructions to set up their password.
                  </p>
                </div>
              </div>

              <EngageRadioGroupInputField
                id='projectsAccess'
                label='Access'
                fieldName='allProjectsAccessible'
                choices={PROJECT_ACCESS_TYPES}
                onSelectionChange={onProjectsAccessChanged}
                value={values.allProjectsAccessible}
              />

              {showPermissionsTable && (
                <div className='c-field l-form__item'>
                  <label className='c-field__label' htmlFor='input-role'>
                    Select projects
                  </label>
                  <div className='c-field__group'>
                    <ul className='c-list-settings'>
                      {projects.map((project) => {
                        return <ProjectItem project={project} key={project._id} />;
                      })}
                    </ul>
                  </div>
                </div>
              )}

              <SwitchableButtonBar
                updateAction={handleSubmit}
                removeAction={remove}
                labelRemove='Delete'
                isNew={!user}
              />
            </form>
          );
        }}
      />
    </div>
  );
}

EditUser.propTypes = {
  UsersStore: PropTypes.object.isRequired,
  ProjectListStore: PropTypes.object.isRequired,
  ToastrStore: PropTypes.object.isRequired,
  AuthStore: PropTypes.object.isRequired,
};

export default inject((root) => ({
  UsersStore: root.RootStore.usersStore,
  ProjectListStore: root.RootStore.projectListStore,
  ToastrStore: root.RootStore.toastrStore,
  AuthStore: root.RootStore.authStore,
}))(observer(EditUser));
