module.exports = function(){

  let _ = require('lodash');

  const EngageAESSalt = 'BID-Engage-TmpSeed';

  const FailedLoginAtempts = {
    MAX_COUNT: 9,
  };

  // https://basecamp.com/2916771/projects/15717618/messages/81423725
  const UserLevels = {
      LOCAL: 'Level-1000',
      LOCAL_PARTICIPATOR: 'Level-1100',
      LOCAL_CONSULTANT: 'Level-1200',
      NEIGBOURHOOD_INFLUENCER: 'Level-1300',
      NEIGBOURHOOD_PRO: 'Level-1400',
      NEIGBOURHOOD_EXPERT: 'Level-1500',
      COMMUNITY_CHAMPION: 'Level-1600',
      COMMUNITY_HERO: 'Level-1700',
      COMMUNITY_SUPERSTAR: 'Level-1800',
      COMMUNITY_LEGEND: 'Level-1900',
    };

  const UserLevelsTranslations = {
      LOCAL: 'Local',
      LOCAL_PARTICIPATOR: 'Local Participator',
      LOCAL_CONSULTANT: 'Local Consultant',
      NEIGBOURHOOD_INFLUENCER: 'Neighbourhood Influencer',
      NEIGBOURHOOD_PRO: 'Neighbourhood Pro',
      NEIGBOURHOOD_EXPERT: 'Neighbourhood Expert',
      COMMUNITY_CHAMPION: 'Community Champion',
      COMMUNITY_HERO: 'Community Hero',
      COMMUNITY_SUPERSTAR: 'Community Superstar',
      COMMUNITY_LEGEND: 'Community Legend',
    };

  // https://basecamp.com/2916771/projects/15717618/messages/81423725
  const UserActionAwardPoints = {
    USER_SIGNUP_POINS: 25,
    SOCIAL_SHARE_POINTS: 10,
    FEEDBACK: 5
  };

  return {
    EngageAESSalt: EngageAESSalt,
    UserLevels: UserLevels,
    UserLevelsList: UserLevelsList,
    UserLevelsMap: UserLevelsMap,
    UserLevelsTranslationsMap: UserLevelsTranslationsMap,
    UserIntegerLevelsMap: UserIntegerLevelsMap,
    UserActionAwardPoints: UserActionAwardPoints,
    FailedLoginAtempts: FailedLoginAtempts,
  };
  /**
   *
   */
  function UserLevelsList(){
    return _.map(Object.keys(UserLevels), (key) => { return UserLevels[key]; });
  }
  /**
   *
   */
  function UserLevelsMap(){
    // https://basecamp.com/2916771/projects/15717618/messages/81423725
    // User Levels (and points required to hit that level)
    let userLevelsMap = {};
    userLevelsMap[UserLevels.LOCAL] = 1;
    userLevelsMap[UserLevels.LOCAL_PARTICIPATOR] = 250;
    userLevelsMap[UserLevels.LOCAL_CONSULTANT] = 500;
    userLevelsMap[UserLevels.NEIGBOURHOOD_INFLUENCER] = 1000;
    userLevelsMap[UserLevels.NEIGBOURHOOD_PRO] = 1750;
    userLevelsMap[UserLevels.NEIGBOURHOOD_EXPERT] = 3000;
    userLevelsMap[UserLevels.COMMUNITY_CHAMPION] = 5000;
    userLevelsMap[UserLevels.COMMUNITY_HERO] = 10000;
    userLevelsMap[UserLevels.COMMUNITY_SUPERSTAR] = 20000;
    userLevelsMap[UserLevels.COMMUNITY_LEGEND] = 50000;
    return userLevelsMap;
  }
  /**
   *
   */
  function UserLevelsTranslationsMap(){
    let aMap = {};
    _.map(Object.keys(UserLevelsTranslations), (key) => {
      aMap[UserLevels[key]] = UserLevelsTranslations[key];
    } );
    return aMap;
  }
  /**
   */
  function UserIntegerLevelsMap(){
    // User Levels
    let userLevelsMap = {};
    userLevelsMap[UserLevels.LOCAL] = 1;
    userLevelsMap[UserLevels.LOCAL_PARTICIPATOR] = 2;
    userLevelsMap[UserLevels.LOCAL_CONSULTANT] = 3;
    userLevelsMap[UserLevels.NEIGBOURHOOD_INFLUENCER] = 4;
    userLevelsMap[UserLevels.NEIGBOURHOOD_PRO] = 5;
    userLevelsMap[UserLevels.NEIGBOURHOOD_EXPERT] = 6;
    userLevelsMap[UserLevels.COMMUNITY_CHAMPION] = 7;
    userLevelsMap[UserLevels.COMMUNITY_HERO] = 8;
    userLevelsMap[UserLevels.COMMUNITY_SUPERSTAR] = 9;
    userLevelsMap[UserLevels.COMMUNITY_LEGEND] = 10;
    return userLevelsMap;
  }

};
