export const QuestionPositionType = {
  FIRST: 'first',
  MIDDLE: 'middle',
  LAST: 'last',
};

export const QUESTION_POSITION_TYPES = [
  [QuestionPositionType.FIRST, 'First'],
  [QuestionPositionType.MIDDLE, 'Middle'],
  [QuestionPositionType.LAST, 'Last'],
];
