import React from 'react';

import ActionBar from '../../../../common/components/actionBar/ActionBar';
import PollInformationForm from '../pollInformationForm/PollInformationForm';

export default function CreatePoll(props) {
  // console.log(props);
  return (
    <div className='l-main'>
      <ActionBar label='Create survey' hasButton={false} />
      <PollInformationForm projectId={props.match.params.id} history={props.history} />
    </div>
  );
}
