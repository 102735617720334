import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { inject, observer } from 'mobx-react';

import ReorderHandle from '../../../common/components/reorderHandle/ReorderHandle';
import MediaCaptionItem from '../../../common/components/mediaItem/MediaCaptionItem';

/**
 * Influenced desision form
 */
function GalleryItemFn(props) {
  const { item, Store } = props;

  const removeImage = (event) => {
    event.preventDefault();
    return Store.removeResource(item.data._id);
  };

  const updateCaption = (caption) => {
    return Store.updateCaption(item.data._id, caption);
  };
  // console.log(item);

  return (
    <div className='c-reorder'>
      <ReorderHandle />
      <MediaCaptionItem
        type={item.data.mediaType}
        mediaId={item.data._id}
        mediaUrl={item.data.resource}
        removeMedia={removeImage}
        caption={item.data.caption}
        updateCaption={updateCaption}
        defaultCaption={item.defaultData.caption}
      />
    </div>
  );
}

const GalleryItem = inject((root) => ({ Store: root.RootStore.galleryStore }))(observer(GalleryItemFn));

/**
 * Sortable item wrapper
 */
const SortableItem = SortableElement((props) => {
  return <GalleryItem {...props} />;
});

export default SortableItem;
