import React, { useState, useEffect, Fragment } from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import SimpleTextInputField from '../textInputField/SimpleTextInputField';

function DoubleTextInputField(props) {
  const { fieldName, extraField, extraFieldValue, actionTooltip, isRequired, validator } = props;

  const [showExtraField, setShowExtraField] = useState(false);
  const [initialChange, setInitialChange] = useState(true);
  const [descriptionActionTooltip, setDescriptionActionTooltip] = useState();

  useEffect(() => {
    if (!initialChange) return;

    if (extraFieldValue) {
      setShowExtraField(true);
      setDescriptionActionTooltip(null);
      setInitialChange(false);
    } else {
      setShowExtraField(false);
      setDescriptionActionTooltip(actionTooltip);
    }
  }, [extraFieldValue, actionTooltip, initialChange]);

  function validateRequired(value) {
    const val = value && value.trim();
    return !val && isRequired ? 'Required' : undefined;
  }

  function onShowExtraField() {
    setShowExtraField(true);
    setDescriptionActionTooltip(null);
  }

  return (
    <Fragment>
      <Field
        component={SimpleTextInputField}
        name={fieldName}
        validate={validator || validateRequired}
        {...props}
        actionTooltip={descriptionActionTooltip ? { ...descriptionActionTooltip, onAction: onShowExtraField } : null}
        fieldClass={showExtraField ? 'l-form__item--m' : ''}
      />
      {showExtraField && (
        <Field
          id={extraField.id}
          component={SimpleTextInputField}
          name={extraField.fieldName}
          label={extraField.label}
          placeholder={extraField.placeholder}
          tooltip={extraField.tooltip}
          fieldClass='l-form__item--m is-block-element'
        />
      )}
    </Fragment>
  );
}

DoubleTextInputField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  extraField: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    tooltip: PropTypes.object,
  }).isRequired,
  extraFieldValue: PropTypes.string,
  actionTooltip: PropTypes.object,
  isRequired: PropTypes.bool,
  validator: PropTypes.func,
};

export default DoubleTextInputField;
