import Agent from './agent';

const requests = Agent.userAPIRequests;
const AuthAgent = {
  me: async () => requests.get(`/me`),
  login: async (data) => requests.post('/login/local', data),
  requestResetPassword: async (data) => requests.post('/reset-password', data),
  resetPassword: async (data) => requests.post('/confirm-reset-password', data),
};

export default AuthAgent;
