import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import SnapshotableImage from '../../../../../common/components/snapshotableImage/ScreenshotableImage';
import SvgIcon from '../../../../../common/components/SvgIcon';
import { perc } from '../PollReportUtils';
import { IMAGE_FORMATS } from '../../../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import { DefaultValueEnums } from '../../../../../shared/engage';

function AnnotatePreviewReactionsList(props) {
  const { choice, choicesCount, onSelectionChange } = props;
  const { choiceId, resource, label, count, countFeedback, isDeleted } = choice;

  const [selected, setSelected] = useState(true);

  // In case reaction was removed from annotate question and resource and label values were permanently deleted
  const reactionResource = resource || DefaultValueEnums().DELETED_ANNOTATE_REACTION;

  function selectionChangeHandler(event) {
    setSelected(event.target.checked);
    onSelectionChange(event, choiceId);
  }

  return (
    <li
      key={choiceId}
      className={`c-result-choice ${selected ? '' : 'c-result-choice--fade'} ${
        isDeleted ? 'c-result-choice--deleted' : ''
      }`}
    >
      <div className='o-checkbox c-result-choice__toggle'>
        <input type='checkbox' id={choiceId} onChange={selectionChangeHandler} checked={selected} />
        <label htmlFor={choiceId}>
          <SvgIcon icon='check' />
          Toggle
        </label>
      </div>
      <div className='c-result-choice__media'>
        <SnapshotableImage resource={reactionResource} format={IMAGE_FORMATS.REACTION.imageType} isPng={true} />
      </div>
      <div className='c-result-choice__content'>
        <span className='c-result-choice__title'>
          {label || ''}
          {isDeleted ? (
            <Fragment>
              <span className='o-tooltip o-tooltip--left-center o-tooltip--dark'>
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                <span className='o-tooltip__action' tabIndex='0'>
                  <SvgIcon icon='info-circle' />
                </span>
                <span className='o-tooltip__description' role='tooltip'>
                  This reaction was deleted from the question after it has been used in answered surveys.
                </span>
              </span>
              <strong className='o-tag'>Deleted</strong>
            </Fragment>
          ) : null}
        </span>
        <span className='c-result-choice__votes'>{`${count} reactions / ${perc(countFeedback, count)}% feedback`}</span>
        <div className='c-result-choice__value'>
          <div className='c-result-choice__bar'>
            <div
              className='c-result-choice__bar-filled'
              style={{ width: `${selected ? perc(count, choicesCount) : '0'}%` }}
            />
          </div>
          <div className='c-result-choice__percent'>{`${selected ? perc(count, choicesCount) : '0'}%`}</div>
        </div>
      </div>
    </li>
  );
}

AnnotatePreviewReactionsList.propTypes = {
  choice: PropTypes.shape({
    choiceId: PropTypes.string.isRequired,
    resource: PropTypes.string,
    label: PropTypes.string,
    count: PropTypes.number.isRequired,
    countFeedback: PropTypes.number.isRequired,
    isDeleted: PropTypes.bool,
  }).isRequired,
  choicesCount: PropTypes.number.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
};

export default AnnotatePreviewReactionsList;
