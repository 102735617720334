/**
 * IMPORTANT this is GLOBAL STATE
 * DO NOT ADD variables without team wide consultations
 */
import HelpAgent from 'common/agent/HelpAgent';
import { action, observable, computed, runInAction, makeObservable } from 'mobx';

const HELP_LOAD_ERROR = 'Error loading help';

export default class HelpStore {
  _isContextualHelpActive =
    localStorage.getItem('isContextualHelpActive') === 'true' ||
    localStorage.getItem('isContextualHelpActive') === null;

  _currentHelpKey = '';

  _help = [];

  _currenHelpItem = null;

  _positionTop = 0;

  constructor(rootStore) {
    makeObservable(this, {
      _help: observable,
      help: computed,
      _currentHelpKey: observable,
      currentHelpKey: computed,
      _isContextualHelpActive: observable,
      isContextualHelpActive: computed,
      _positionTop: observable,
      positionTop: computed,

      closeHelp: action,
      resetFlags: action,
    });

    this.rootStore = rootStore;
  }

  get help() {
    return this._help;
  }

  get currentHelpItem() {
    return this._currenHelpItem;
  }

  get currentHelpKey() {
    return this._currentHelpKey;
  }

  set currentHelpKey(val) {
    this._currentHelpKey = val;

    this._currenHelpItem = this._help.find((item) => item.key === val && item.active);
  }

  get isContextualHelpActive() {
    return this._isContextualHelpActive;
  }

  set isContextualHelpActive(val) {
    localStorage.setItem('isContextualHelpActive', val);
    this._isContextualHelpActive = val;
  }

  get positionTop() {
    return this._positionTop;
  }

  set positionTop(val) {
    this._positionTop = val;
  }

  closeHelp() {
    this._currentHelpKey = '';
  }

  async getHelp() {
    this._loading = true;
    this._error = null;
    try {
      const res = await HelpAgent.getHelp();
      runInAction(() => {
        this._help = res.data;
      });
      return res.data;
    } catch (err) {
      this.rootStore.toastrStore.error(HELP_LOAD_ERROR, null, err);
      throw err;
    } finally {
      this.resetFlags();
    }
  }

  resetFlags = () => {
    this._loading = false;
  };
}
