import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { SortableContainer } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import PropTypes from 'prop-types';

import ActionBar from '../../../common/components/actionBar/ActionBar';
import NewsItem from './NewsItem';
import NewsSortableItem from './NewsSortableItem';

function NewsContainer(props) {
  const { items, isCustom, allowDrag } = props;
  return (
    <div className='c-reorder-list c-reorder-list--content'>
      {items.map((item, index) => (
        <NewsSortableItem key={item.data._id} index={index} item={item} isCustom={isCustom} disabled={!allowDrag} />
      ))}
    </div>
  );
}

/**
 * News container
 */
function News(props) {
  // console.log(props);
  const { Store } = props;
  const [customOrder, setCustomOrder] = useState(true);
  const [allowDrag, setAllowDrag] = useState(true);

  const Custom = { value: 'custom', name: 'Custom order' };
  const Chrono = { value: 'chrono', name: 'Chronological' };
  const options = [Custom, Chrono];

  const onChange = (event) => {
    setCustomOrder(event.target.value === options[0].value);
  };

  const addNew = () => {
    Store.create({
      title: 'New information',
      status: 'draft',
    }).then((newsId) => {
      if (newsId) props.history.push(`news/${newsId}/edit-news`);
    });

    // Old news creation flow - redirect user to news creation form
    // props.history.push(`news/create-news`);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setAllowDrag(false);
    const newOrder = arrayMoveImmutable(Store.news, oldIndex, newIndex);
    Store.reorder(newOrder).then(() => {
      setAllowDrag(true);
    });
  };

  const resetOrder = () => {
    Store.reorder(Store.news.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)));
  };

  return (
    <div className='l-main'>
      <ActionBar
        options={options}
        optionsLabel='Sort new by'
        optionsOnChange={onChange}
        addNew={addNew}
        label='News'
        hasButton={true}
        buttonLabel='Add'
        optionValue={customOrder ? Custom.value : Chrono.value}
      />
      {customOrder ? (
        <SortableList
          isCustom={customOrder}
          items={Store.news}
          onSortEnd={onSortEnd}
          useDragHandle={true}
          allowDrag={allowDrag}
        />
      ) : (
        Store.news
          .slice()
          .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
          .map((item, index) => <NewsItem key={item._id} index={index} item={item} isCustom={customOrder} />)
      )}
      {customOrder && (
        <button className='o-button o-button--alternate o-button--xs' onClick={resetOrder}>
          <div className='o-label'>Reset order</div>
        </button>
      )}
    </div>
  );
}

const SortableList = SortableContainer((props) => {
  return <NewsContainer {...props} />;
});

export default inject((root) => ({ Store: root.RootStore.newsStore }))(observer(News));

News.propTypes = {
  Store: PropTypes.object.isRequired,
};
