import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SortableElement } from 'react-sortable-hoc';
import { inject, observer } from 'mobx-react';
import DemographicForm from './DemographicForm';
import SwitchableHeader from '../../../common/components/switchableHeader/SwitchableHeader';
import DEMOGRAPHIC_ENUMS from '../../../shared/engage/engage-demographics-enums';
import { QUESTION_TYPES } from '../../../common/constants/QuestionConstants';
import displayConfirmationPrompt from '../../../common/PromptUtil';

const DEMOGRAPHIC_CUSTOM_TYPE = DEMOGRAPHIC_ENUMS().DemographicsTypes.DEMOGRAPHIC_CUSTOM_TYPE;

function DemographicQuestionFn(props) {
  const { question, Store, ToastrStore, openPollPreview, switchableModifier, projectId } = props;
  const { _id, questionText, questionType, isOpened } = question;

  const [allowDrag, setAllowDrag] = useState(true);
  const [allowChoiceDelete, setAllowChoiceDelete] = useState(true);

  function toggleOpened() {
    Store.toggleOpened([question._id]);
  }

  function removeQuestion(event) {
    event.preventDefault();

    const res = displayConfirmationPrompt(null, null, false, ToastrStore);

    if (res === 1) Store.removeQuestion(projectId, question._id);
  }

  function uploadImage(file, progressHandler) {
    return Store.uploadImage(projectId, question, file, progressHandler);
  }

  function removeImage(event, imageId) {
    event.preventDefault();
    return Store.removeImage(projectId, question, imageId);
  }

  function updateQuestion(changedQuestion) {
    Store.updateQuestion(projectId, changedQuestion);
  }

  function overrideQuestion(event) {
    event.preventDefault();
    Store.overrideQuestion(projectId, question._id);
  }

  function addChoice(choiceType) {
    setAllowDrag(false);
    Store.createQuestionChoice(projectId, _id, choiceType).then(() => {
      setAllowDrag(true);
    });
  }

  function removeChoice(choiceId) {
    setAllowDrag(false);
    setAllowChoiceDelete(false);
    Store.removeQuestionChoice(projectId, _id, choiceId).then(() => {
      setAllowDrag(true);
      setAllowChoiceDelete(true);
    });
  }

  function reorderChoice(newOrder) {
    setAllowDrag(false);
    Store.reorderQuestionChoicess(projectId, _id, newOrder).then(() => {
      setAllowDrag(true);
    });
  }

  function showWarningMessage(message) {
    ToastrStore.warning(message);
  }

  const myProps = {
    overrideQuestion,
    updateQuestion,
    removeQuestion,
    uploadImage,
    removeImage,
    openPollPreview,
    addChoice,
    removeChoice,
    reorderChoice,
    showWarningMessage,
  };

  function getQuestionIcon() {
    return questionType === DEMOGRAPHIC_CUSTOM_TYPE ? 'user-edit' : 'user-lock';
  }

  function getQuestionLabel() {
    const qType = Object.entries(QUESTION_TYPES.properties).find((item) => item[0].toLowerCase() === questionType);
    return qType ? qType[1].label : '';
  }

  return (
    <div className={`c-switchable ${switchableModifier} ${isOpened ? 'is-opened' : 'is-closed'}`}>
      <SwitchableHeader
        id={_id}
        label={getQuestionLabel()}
        name={questionText || ''}
        icon={getQuestionIcon()}
        toggleOpened={toggleOpened}
      />
      <div className='c-switchable__content'>
        <DemographicForm question={question} {...myProps} allowDrag={allowDrag} allowChoiceDelete={allowChoiceDelete} />
      </div>
    </div>
  );
}

DemographicQuestionFn.propTypes = {
  question: PropTypes.object.isRequired,
  Store: PropTypes.object.isRequired,
  ToastrStore: PropTypes.object.isRequired,
  openPollPreview: PropTypes.func,
  switchableModifier: PropTypes.string,
  projectId: PropTypes.string.isRequired,
};

const DemographicQuestion = inject((root) => ({
  Store: root.RootStore.demographicsStore,
  ToastrStore: root.RootStore.toastrStore,
}))(observer(DemographicQuestionFn));

const SortableItem = SortableElement((props) => <DemographicQuestion {...props} />);

export default SortableItem;
