import { action, observable, runInAction, makeObservable } from 'mobx';
import AgentPoll from '../common/agent';

const Agent = AgentPoll.ToolsAgent;

const GDPR_DATA_LOAD_ERROR = `Error loading GDPR email references`;
const GDPR_DATA_REMOVE_ERROR = `Error removing GDPR email references`;
const DUPLICATE_SURVEY_ERROR = `Error duplicating survey`;

export default class Toolstore {
  _loading = false;

  _initialized = false;

  _error = null;

  _gdprEmailReferences = null;

  constructor(rootStore) {
    makeObservable(this, {
      _gdprEmailReferences: observable,
      resetFlags: action,
    });

    this.rootStore = rootStore;
  }

  get gdprEmailReferences() {
    return this._gdprEmailReferences;
  }

  /**
   * Serves button "Duplicate survey"
   * @param sourceSurvey, sourceProject, targetProject
   * @returns {Promise<void>}
   */
  async duplicateSurvey(sourceSurveyId, sourceProjectId, targetProjectId) {
    this._loading = true;
    this._error = null;
    try {
      const data = { destinationProjectId: targetProjectId };
      const res = await Agent.duplicateSurvey(data, sourceSurveyId, sourceProjectId);

      return res.data;
    } catch (err) {
      this.rootStore.toastrStore.error(DUPLICATE_SURVEY_ERROR, null, err);
      throw err;
    }
  }

  /**
   * Serves button "Search"
   * @param email
   * @returns {Promise<void>}
   */
  async getGdprEmailReferences(email, clientId, projectId) {
    this._loading = true;
    this._error = null;
    try {
      const data = { email, clientId };
      const res = await Agent.getGdprEmailReferences(data, projectId);
      runInAction(() => {
        this._gdprEmailReferences = res.data;
      });
      return res.data;
    } catch (err) {
      this.rootStore.toastrStore.error(GDPR_DATA_LOAD_ERROR, null, err);
      throw err;
    }
  }

  /**
   * Serves button "Anonymise user data"
   * @param email
   * @returns {Promise<void>}
   */
  async removeGdprEmailReferences(email, clientId, projectId) {
    this._loading = true;
    this._error = null;
    try {
      const data = { email, clientId };
      const res = await Agent.removeGdprEmailReferences(data, projectId);
      runInAction(() => {
        this._gdprEmailReferences = [];
      });
      return res.data;
    } catch (err) {
      this.rootStore.toastrStore.error(GDPR_DATA_REMOVE_ERROR, null, err);
      throw err;
    }
  }

  resetFlags = () => {
    this._loading = false;
    this._error = null;
  };
}
