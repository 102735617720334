import React from 'react';
import PropTypes from 'prop-types';
// import SvgIcon from '../SvgIcon';

function GridImage(props) {
  const { image, onImageSelect, unsplashAppName } = props;

  const rowHeightValue = 200;
  const calcProportion = (image.width * rowHeightValue) / image.height;

  return (
    <div
      className={`c-image-grid__item ${image.isFavourite ? 'c-image-grid__item--highlight' : ''}`}
      style={{ width: `${calcProportion}px`, flexGrow: `${calcProportion}` }}
      data-testid={`${image.urls.small}-container`}
    >
      <a href='#img'>
        <span className='c-image-grid__spacer' style={{ paddingBottom: `${(image.height / image.width) * 100}%` }} />
        <img
          className='c-image-grid__image'
          src={image.urls.small}
          alt={image.urls.small}
          onClick={() => onImageSelect(image.links.download_location)}
        />
      </a>
      {/* Enable this once we have backend that supports 'set as favourite' feature */}
      {/* <div className='c-image-grid__actions'>
        <button className='c-image-grid__action c-image-grid__action--star'>
          <SvgIcon icon='star' />
          <SvgIcon icon='star-filled' />
        </button>
      </div> */}
      <div className='c-image-grid__meta'>
        Photo by{' '}
        <a
          href={`https://unsplash.com/@${image.user.username}?utm_source=${unsplashAppName}&utm_medium=referral`}
          target='_blank'
          rel='noopener noreferrer'
        >
          {`${image.user.first_name ? image.user.first_name : ''}  ${image.user.last_name ? image.user.last_name : ''}`}
        </a>
      </div>
    </div>
  );
}

GridImage.propTypes = {
  image: PropTypes.object.isRequired,
  onImageSelect: PropTypes.func.isRequired,
  unsplashAppName: PropTypes.string.isRequired,
};

export default GridImage;
