// sax
import React from 'react';
import PropTypes from 'prop-types';
import ReorderHandle from '../../../common/components/reorderHandle/ReorderHandle';
import NewsTeaser from './NewsTeaser';

/**
 * News item
 */
export default function NewsItem(props) {
  const { item, isCustom } = props;

  // ##### if c-reorder div is put into parent NewsContainer,
  // something breaks with reordering, its why code is duplicated
  return (
    <React.Fragment>
      {isCustom ? (
        <div className='c-reorder'>
          <ReorderHandle />
          <NewsTeaser
            title={item.data.title}
            id={item.data._id}
            description={item.data.description}
            date={item.data.timestamp}
            status={item.data.status}
            classModifier='c-text-teaser--reorder'
          />
        </div>
      ) : (
        <NewsTeaser
          title={item.data.title}
          id={item.data._id}
          description={item.data.description}
          date={item.data.timestamp}
          status={item.data.status}
        />
      )}
    </React.Fragment>
  );
}

NewsItem.propTypes = {
  item: PropTypes.object.isRequired,
};
