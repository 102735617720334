import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

function Tooltip(props) {
  const { modifier, label, labelStrong = '', icon, description, img, displaysOriginalValue = false } = props;

  function createMediaItems() {
    let images;
    if (Array.isArray(img)) {
      const imageNodes = img.map((image) => <img key={image} src={image} alt='' />);

      if (img.length > 1) {
        images = <span className='multiple-images'>{imageNodes}</span>;
      } else {
        images = imageNodes;
      }
    } else {
      images = <img src={img} alt='' />;
    }

    return images;
  }
  return (
    <span className={`o-tooltip ${modifier || ''}`} data-testid='tooltip'>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
      <span className='o-tooltip__action' tabIndex='0'>
        {label && (
          <span className='o-label'>
            {label}
            <strong>{labelStrong}</strong>
          </span>
        )}
        {icon && <SvgIcon icon={icon} />}
      </span>
      <span className='o-tooltip__description' role='tooltip'>
        {displaysOriginalValue && <strong>Original value:</strong>}
        {displaysOriginalValue && img ? <br /> : null}
        {createMediaItems()}
        {displaysOriginalValue && description ? <br /> : null}
        {description}
      </span>
    </span>
  );
}

Tooltip.propTypes = {
  modifier: PropTypes.string,
  label: PropTypes.string,
  labelStrong: PropTypes.string,
  icon: PropTypes.string,
  description: PropTypes.string,
  img: PropTypes.string,
  displaysOriginalValue: PropTypes.bool,
};

export default Tooltip;
