import React from 'react';
import { Link } from 'react-router-dom';
import { CREATE_PROJECT } from 'common/constants/RouteConstants';

function GettingStarted() {
  return (
    <div className='c-blank-state' data-testid='getting-started-wrapper'>
      <h1 className='c-blank-state__title'>Build your community</h1>
      <p className='c-blank-state__description'>
        Welcome to your community engagement hub! From here you can give communities a sense of empowerment and
        emotional investment in your schemes. Your Built-ID Account Team are always available to assist in any way you
        need, so don’t hesitate to reach out.
      </p>
      <div className='c-blank-state__action'>
        <Link className='o-button o-button--primary' to={CREATE_PROJECT}>
          <span className='o.label'>Get started</span>
        </Link>
      </div>
    </div>
  );
}

export default GettingStarted;
