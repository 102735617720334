import React from 'react';
import PropTypes from 'prop-types';

function FilterList(props) {
  const { availableOptions, selectedOption, onFilterChange } = props;

  return (
    <nav className='c-filter-tabs' data-testid='filter-list-wrapper'>
      <ul className='c-filter-tabs__items'>
        {availableOptions.map((option, index) => (
          <li key={index} className={`c-filter-tabs__item ${selectedOption === option.value ? 'is-active' : ''}`}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions */}
            <a className='c-filter-tabs__link' onClick={() => onFilterChange(option.value)}>
              {option.label}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
}

FilterList.propTypes = {
  availableOptions: PropTypes.array.isRequired,
  selectedOption: PropTypes.string.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

export default FilterList;
