import { useState, useEffect, useRef, useCallback } from 'react';
import Hammer from 'hammerjs';
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill';
// eslint-disable-next-line import/no-extraneous-dependencies
import clamp from 'lodash/clamp';

import {
  getNormalizedClient,
  transformString,
  getBoundedTransform,
  getZoomModifier,
  getDoubleTapZoomModifier,
  ZOOM_IN,
} from './utils';

const usePanZoom = (minScale = 0.25, maxScale = 4) => {
  const [state, setState] = useState({ translateX: 0, translateY: 0, scale: 1 });
  const [reactionLocation, setReactionLocation] = useState({ left: 0, top: 0 });
  const bounds = useRef({ maxX: 300, minX: -300, maxY: 300, minY: -300, minScale, maxScale });

  const hammerjs = useRef(null);
  const animationFrameRef = useRef(null);
  const transformStartRef = useRef(null);
  const wheelThrottleRef = useRef(false);
  const dragStartDimension = useRef({ width: 0, height: 0 });

  const { ref: containerRef } = useResizeDetector({
    // eslint-disable-next-line no-use-before-define
    onResize: () => recalculateBoundsAndUpdate(),
    refreshMode: 'debounce',
    refreshRate: 50,
    refreshOptions: { trailing: true, leading: false },
  });

  const { ref: dragRef } = useResizeDetector({
    // eslint-disable-next-line no-use-before-define
    onResize: () => recalculateBoundsAndUpdate(),
    refreshMode: 'debounce',
    refreshRate: 50,
    refreshOptions: { trailing: true, leading: false },
  });

  const updateTransform = useCallback(
    (translateX, translateY, scale) => {
      // console.log('update', translateX, translateY, scale);
      dragRef.current.style.transform = transformString(
        getBoundedTransform(translateX, translateY, scale, bounds.current),
      );
      animationFrameRef.current = 0;
    },
    [dragRef, animationFrameRef],
  );

  const recalculateBounds = useCallback(
    (scaleRatio = 1) => {
      const containerRect = containerRef.current.getBoundingClientRect();
      const dragRect = dragRef.current.getBoundingClientRect();
      const maxX = containerRect.width / 2;
      const minX = -dragRect.width * scaleRatio + containerRect.width / 2;
      const maxY = containerRect.height / 2;
      const minY = -dragRect.height * scaleRatio + containerRect.height / 2;
      // console.log('recalc', dragRect);
      bounds.current = { ...bounds.current, maxX, minX, maxY, minY };
    },
    [containerRef, dragRef, bounds],
  );

  const getNewTranslate = useCallback(
    (clientX, clientY, newScale) => {
      const clientInContainer = getNormalizedClient(clientX, clientY, containerRef.current);
      // console.log('newTranslate', clientInContainer.x, clientInContainer.y);
      const x = clientInContainer.x - ((clientInContainer.x - state.translateX) * newScale) / state.scale;
      const y = clientInContainer.y - ((clientInContainer.y - state.translateY) * newScale) / state.scale;
      recalculateBounds(newScale / state.scale);
      return { x, y };
    },
    [recalculateBounds, state, containerRef],
  );

  const onPan = useCallback(
    (ev) => {
      if (!animationFrameRef.current && !(ev.maxPointers > 1)) {
        // console.log('pan');
        const transformX = transformStartRef.current.translateX + ev.deltaX;
        const transformY = transformStartRef.current.translateY + ev.deltaY;
        animationFrameRef.current = window.requestAnimationFrame(() =>
          updateTransform(transformX, transformY, transformStartRef.current.scale),
        );
      }
    },
    [updateTransform, animationFrameRef, transformStartRef],
  );

  const onZoom = useCallback(
    (clientX, clientY, isZoomIn) => {
      const zoomModifier = getDoubleTapZoomModifier(isZoomIn);
      const newScale = clamp(state.scale + zoomModifier, bounds.current.minScale, bounds.current.maxScale);
      const newTranslate = getNewTranslate(clientX, clientY, newScale);
      setState(getBoundedTransform(newTranslate.x, newTranslate.y, newScale, bounds.current));
    },
    [state, getNewTranslate],
  );

  const onPinchZoom = useCallback(
    (clientX, clientY, zoomModifier) => {
      const newScale = clamp(state.scale + zoomModifier, bounds.current.minScale, bounds.current.maxScale);
      const newTranslate = getNewTranslate(clientX, clientY, newScale);
      updateTransform(newTranslate.x, newTranslate.y, newScale);
    },
    [state, updateTransform, getNewTranslate],
  );

  const onReset = () => {
    // console.log('reset');
    const widthContainer = containerRef.current.getBoundingClientRect().width;
    const heightContainer = containerRef.current.getBoundingClientRect().height;
    const width = dragRef.current.getBoundingClientRect().width;
    const height = dragRef.current.getBoundingClientRect().height;
    const newScale = clamp(
      Math.round((heightContainer / height) * state.scale * 100) / 100,
      bounds.current.minScale,
      bounds.current.maxScale,
    );
    recalculateBounds(newScale / state.scale);
    setState(
      getBoundedTransform(
        ((-width * newScale) / state.scale + widthContainer) / 2,
        ((-height * newScale) / state.scale + heightContainer) / 2,
        newScale,
        bounds.current,
      ),
    );
  };

  const onLoad = (imageWidth, imageHeight) => {
    dragStartDimension.current = { width: imageWidth, height: imageHeight };
    onReset();
  };

  const onPinch = useCallback(
    (ev) => {
      if (!animationFrameRef.current) {
        animationFrameRef.current = window.requestAnimationFrame(() =>
          onPinchZoom(ev.center.x, ev.center.y, ev.scale - 1),
        );
      }
    },
    [animationFrameRef, onPinchZoom],
  );

  const onPinchEnd = useCallback(
    (ev) => {
      const newScale = clamp(state.scale + (ev.scale - 1), bounds.current.minScale, bounds.current.maxScale);
      const newTranslate = getNewTranslate(ev.center.x, ev.center.y, newScale);
      // each pinch action already recalculates bounds (necessary so when pinching it doesnt go out of bounds),
      // but then the scaling is wrong when doing getNewTranslate, so must be recalculated again with ratio = 1
      recalculateBounds();
      setState(getBoundedTransform(newTranslate.x, newTranslate.y, newScale, bounds.current));
      if (animationFrameRef.current) {
        window.cancelAnimationFrame(animationFrameRef.current);
        animationFrameRef.current = 0;
      }
    },
    [state, getNewTranslate, recalculateBounds],
  );

  useEffect(() => {
    transformStartRef.current = state;
    const containerRect = containerRef.current.getBoundingClientRect();
    // const dragRect = dragRef.current.getBoundingClientRect();
    // console.log(containerRect, dragRect);
    setReactionLocation({
      left: ((-state.translateX + containerRect.width / 2) / (dragStartDimension.current.width * state.scale)) * 100,
      top: ((-state.translateY + containerRect.height / 2) / (dragStartDimension.current.height * state.scale)) * 100,
    });
    updateTransform(state.translateX, state.translateY, state.scale);
  }, [state, updateTransform, containerRef, dragRef]);

  const onPanEnd = (ev) => {
    if (!(ev.maxPointers > 1)) {
      setState(
        getBoundedTransform(
          transformStartRef.current.translateX + ev.deltaX,
          transformStartRef.current.translateY + ev.deltaY,
          transformStartRef.current.scale,
          bounds.current,
        ),
      );
      if (animationFrameRef.current) {
        window.cancelAnimationFrame(animationFrameRef.current);
        animationFrameRef.current = 0;
      }
    }
  };

  useEffect(() => {
    hammerjs.current = new Hammer(containerRef.current);
    hammerjs.current.get('pinch').set({ enable: true });
    hammerjs.current.get('pan').set({ direction: Hammer.DIRECTION_ALL });

    hammerjs.current.on('pan', onPan);
    hammerjs.current.on('doubletap', (ev) => onZoom(ev.center.x, ev.center.y, ZOOM_IN));
    hammerjs.current.on('pinch', onPinch);
    hammerjs.current.on('panend pancancel', onPanEnd);
    hammerjs.current.on('pinchend pinchcancel', onPinchEnd);
    return () => {
      hammerjs.current.off('pan', onPan);
      hammerjs.current.off('doubletap', (ev) => onZoom(ev.center.x, ev.center.y, ZOOM_IN));
      hammerjs.current.off('pinch', onPinch);
      hammerjs.current.off('panend pancancel', onPanEnd);
      hammerjs.current.off('pinchend pinchcancel', onPinchEnd);
    };
  }, [containerRef, onPan, onZoom, onPinch, onPinchEnd]);

  const onWheel = (ev) => {
    // console.log('wheel');
    if (ev.deltaY !== 0 && !wheelThrottleRef.current) {
      wheelThrottleRef.current = true;
      const zoomModifier = getZoomModifier(ev.deltaY < 0);
      const newScale = clamp(state.scale + zoomModifier, bounds.current.minScale, bounds.current.maxScale);
      // console.log('onwheel', newScale, state.scale);
      const newTranslate = getNewTranslate(ev.clientX, ev.clientY, newScale);
      setState(getBoundedTransform(newTranslate.x, newTranslate.y, newScale, bounds.current));
      setTimeout(() => {
        wheelThrottleRef.current = false;
      }, 20);
    }
  };

  const recalculateBoundsAndUpdate = useCallback(() => {
    recalculateBounds();
    updateTransform(state.translateX, state.translateY, state.scale);
    setState(getBoundedTransform(state.translateX, state.translateY, state.scale, bounds.current));
  }, [updateTransform, state, recalculateBounds]);

  return {
    containerRef,
    dragRef,
    onWheel,
    onZoom,
    onReset,
    onLoad,
    reactionLocation,
    state,
  };
};

export default usePanZoom;
