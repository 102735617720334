// Authentication routes
export const AUTH = 'auth';
export const AUTH_LOGIN = 'login';
export const AUTH_FORGOT_PASSWORD = 'forgot-password';
export const AUTH_RESET_PASSWORD = 'reset-password';

export const PROFILE = 'profile';
export const PROJECTS = 'projects';
export const STAFF = 'staff';
export const CLIENTS = 'clients';
export const EDIT_PROJECT = 'edit';
export const SETTINGS = 'settings';

// Routes listed ordered as in the menu
export const HOME = 'home';
export const PROJECT = 'project';
export const OVERVIEW = 'overview';
export const INFORMATION = 'information';
export const BRANDING = 'branding';
export const GEOLOCATION = 'geolocation';
export const TIMELINE = 'timeline';
export const LANGUAGES = 'languages';
export const IMAGE_GALLERY = 'media';
export const TEAM = 'team';
export const INFLUENCED_DECISIONS = 'influenced-decisions';
export const CHARITIES = 'charities';
export const PANELS = 'panels';

export const POLLS = 'surveys';
export const SEGMENTATION = 'segmentation';
export const DEMOGRAPHICS = 'demographics';

export const CUSTOMIZE = 'customize';
export const CONTENT = 'content';
export const ACTIONS = 'actions';
export const BLOCKS = 'blocks';
export const PERSONAL_DATA = 'personal-data';
export const FAQ = 'faq';
export const NEWS = 'news';
export const REPORTS = 'reports';
export const REPORTS_DASHBOARD = 'reports-overview';
export const REPORTS_POLLS = 'reports-surveys';
export const REPORTS_DEMOGRAPHICS = 'reports-demographics';
export const REPORTS_FEEDBACK = 'reports-feedback';
export const REPORTS_GENERATE = 'reports-generate';
// export const REPORTS_SEGMENTATION = 'reports-segmentation';
export const PUBLISHING = 'publishing';
export const CONFIGURATION = 'configuration';
export const EMBED = 'embed';
export const TOOLS = 'tools';
//
export const CREATE_PROJECT = 'create-project';
export const USERS = 'users';
export const EDIT_USERS = 'edit_users';

export const ERROR_403 = 'error-403';
export const ERROR_NO_SUBSCRIPTION = 'error-no-subscription';

function mapRouteToLabel(route) {
  switch (route) {
    case HOME:
      return 'Home';
    case OVERVIEW:
      return 'Overview';
    case INFORMATION:
      return 'Information';
    case BRANDING:
      return 'Branding';
    case GEOLOCATION:
      return 'Location';
    case TIMELINE:
      return 'Timeline';
    case LANGUAGES:
      return 'Languages';
    case IMAGE_GALLERY:
      return 'Media';
    case TEAM:
      return 'Team';
    case INFLUENCED_DECISIONS:
      return 'Influenced decisions';
    case CHARITIES:
      return 'Charities';
    case PANELS:
      return 'Panels';
    case POLLS:
      return 'Surveys';
    case CUSTOMIZE:
      return 'Customise';
    case ACTIONS:
      return 'End of Survey Actions';
    case BLOCKS:
      return 'Homepage Blocks';
    case PERSONAL_DATA:
      return 'Personal Data';
    case CONTENT:
      return 'Static Text';
    case FAQ:
      return 'FAQ';
    case NEWS:
      return 'News';
    case PUBLISHING:
      return 'Publishing';
    case CONFIGURATION:
      return 'Configuration';
    case EMBED:
      return 'Embed';
    case TOOLS:
      return 'Tools';
    case REPORTS:
      return 'Reports';
    case REPORTS_DASHBOARD:
      return 'Overview';
    case REPORTS_POLLS:
      return 'Surveys';
    case REPORTS_DEMOGRAPHICS:
      return 'Demographics';
    case REPORTS_FEEDBACK:
      return 'Feedback';
    case REPORTS_GENERATE:
      return 'Generate';
    case CREATE_PROJECT:
      return 'Create project';
    case SEGMENTATION:
      return 'Segmentation';
    case DEMOGRAPHICS:
      return 'Demographics';
    case USERS:
      return 'Users';
    case EDIT_USERS:
      return 'Edit users';
    case SETTINGS:
      return 'Settings';
    default:
      return 'Navigation';
  }
}

export const routeToLabel = mapRouteToLabel;
