import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { SortableElement } from 'react-sortable-hoc';

import SvgIcon from '../../../../common/components/SvgIcon';
import DateUtils from '../../../../common/DateUtils';
import ReorderHandle from '../../../../common/components/reorderHandle/ReorderHandle';
import { BATCH_AFFILIATION_ENUM } from '../pollInformationForm/pollBatchAffiliationEnum';

function PollTeaser(props) {
  const { poll, PollEditStore } = props;

  const batch = BATCH_AFFILIATION_ENUM[poll.batchAffiliation] ? BATCH_AFFILIATION_ENUM[poll.batchAffiliation][1] : null;

  function linkOnClickHandler() {
    window.scrollTo(0, 0);

    // Clear previous poll data before loading and displaying currently selected poll
    PollEditStore.poll = null;
  }

  return (
    <div className='c-reorder'>
      <ReorderHandle />
      <div className='c-poll-teaser c-poll-teaser--reorder'>
        <h3 className='c-poll-teaser__title'>
          <Link to={`surveys/${poll._id}/edit-survey`} onClick={linkOnClickHandler}>
            {poll.name || 'No title'}
          </Link>
          {batch && <span className='o-tag is-highlight'>{batch}</span>}
          {poll.status === 'active' && <span className='o-tag'>Active</span>}
        </h3>
        {/* <p className='c-poll-teaser__description'>{poll.description}</p> */}
        <ul className='c-poll-teaser__meta'>
          <li>
            <SvgIcon icon='poll-coin' />
            {poll.totalPoints} points
          </li>
          <li>
            <SvgIcon icon='poll' />
            {poll.questionCount} questions
          </li>
          <li>
            <SvgIcon icon='poll-fact-text' />
            {poll.factCount} facts
          </li>

          {(poll.createdBy || poll.timestamp) && (
            <li>
              <span className='o-tooltip o-tooltip--top-center'>
                <span className='o-tooltip__action'>
                  <SvgIcon icon='info-circle' />
                </span>
                <span className='o-tooltip__description' role='tooltip'>
                  {poll.createdBy && (
                    <Fragment>
                      Survey created by <strong>{poll.createdBy}</strong>
                    </Fragment>
                  )}
                  {poll.timestamp && (
                    <Fragment>
                      <br />
                      on <strong>{DateUtils.getDateTimeString(poll.timestamp)}</strong>
                    </Fragment>
                  )}
                </span>
              </span>
            </li>
          )}

          {poll.dateStart && poll.dateEnd && (
            <li>
              <SvgIcon icon='calendar-alt' />
              {DateUtils.getShortDateString(poll.dateStart)} - {DateUtils.getShortDateString(poll.dateEnd)}
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}

const SortableItem = SortableElement((props) => {
  return <PollTeaser {...props} />;
});

export default inject((root) => ({
  PollEditStore: root.RootStore.pollEditStore,
}))(observer(SortableItem));
