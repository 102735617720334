import ToastrStore from './ToastrStore';

import AuthStore from './AuthStore';
import CharitiesStore from './CharitiesStore';
import FaqStore from './FaqStore';
import FeedbackStore from './FeedbackStore';
import GalleryStore from './GalleryStore';
import GeolocationStore from './GeolocationStore';
import InfluencedStore from './InfluencedStore';
import LanguageStore from './LanguageStore';
import StaticContentStore from './StaticContentStore';
import ActionsStore from './ActionsStore';
import NewsStore from './NewsStore';
import OverviewStore from './OverviewStore';
import PanelsStore from './PanelsStore';
import ProjectInformationStore from './ProjectInformationStore';
import ProjectStore from './ProjectStore';
import ProjectListStore from './ProjectListStore';
import ProjectControlStore from './ProjectControlStore';
import PollsListStore from './PollsListStore';
import PollEditStore from './PollEditStore';
import TimelineStore from './TimelineStore';
import TeamStore from './TeamStore';
import ConfigStore from './ConfigStore';
import PollLibraryStore from './PollLibraryStore';
import BlockStore from './BlockStore';
import ClientStore from './ClientStore';
import DemographicsStore from './DemographicsStore';
import ImageLibraryStore from './ImageLibraryStore';
import PersonalDataStore from './PersonalDataStore';
import HelpStore from './HelpStore';

// Reports
import OverviewReportStore from './reports/OverviewReportStore';
import PollsReportStore from './reports/PollsReportStore';
import GenerateReportStore from './reports/GenerateReportStore';
import SegmentationStore from './reports/SegmentationStore';
import DemographicsReportStore from './reports/DemographicsReportStore';
import GeneralStore from './GeneralStore';

// Settings
import ToolsStore from './ToolsStore';

// Legacy Built-ID API
// import BuiltIDStore from './BuiltIDStore';
import UsersStore from './UsersStore';

export default class RootStore {
  constructor() {
    this.authStore = new AuthStore(this);
    this.projectStore = new ProjectStore(this);
    this.projectListStore = new ProjectListStore(this);
    this.toastrStore = new ToastrStore(this);
    this.languageStore = new LanguageStore(this, this.projectStore);
    this.projectInformationStore = new ProjectInformationStore(this, this.projectStore);
    this.geolocationStore = new GeolocationStore(this, this.projectStore);
    this.teamStore = new TeamStore(this, this.projectStore);
    this.influencedStore = new InfluencedStore(this, this.projectStore);
    this.charitiesStore = new CharitiesStore(this, this.projectStore);
    this.galleryStore = new GalleryStore(this, this.projectStore);
    this.pollsListStore = new PollsListStore(this);
    this.pollEditStore = new PollEditStore(this);
    this.timelineStore = new TimelineStore(this, this.projectStore);
    this.projectControlStore = new ProjectControlStore(this, this.projectStore);
    this.faqStore = new FaqStore(this, this.projectStore);
    this.newsStore = new NewsStore(this, this.projectStore);
    this.overviewStore = new OverviewStore(this);
    this.feedbackStore = new FeedbackStore(this);
    this.panelsStore = new PanelsStore(this, this.projectStore);
    this.staticContentStore = new StaticContentStore(this, this.projectStore);
    this.personalDataStore = new PersonalDataStore(this, this.projectStore);
    this.actionStore = new ActionsStore(this, this.projectStore);
    this.blockStore = new BlockStore(this, this.projectStore);
    this.configStore = new ConfigStore(this);
    this.pollLibraryStore = new PollLibraryStore(this);
    this.demographicsStore = new DemographicsStore(this);
    this.imageLibraryStore = new ImageLibraryStore(this);
    this.helpStore = new HelpStore(this);

    // Reports
    this.overviewReportStore = new OverviewReportStore(this);
    this.pollsReportStore = new PollsReportStore(this);
    this.generateReportStore = new GenerateReportStore(this);
    this.segmentationStore = new SegmentationStore(this);
    this.demographicsReportStore = new DemographicsReportStore(this);
    this.clientStore = new ClientStore(this);

    // Users
    this.usersStore = new UsersStore(this);

    // General app store
    this.generalStore = new GeneralStore(this);

    // Settings
    this.toolsStore = new ToolsStore(this);

    // legacy Built-ID
    // this.builtIDStore = new BuiltIDStore(this);
  }
}
