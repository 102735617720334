module.exports = function () {
  const THEMES = [
    'Building and Design',
    'Public Spaces',
    'Housing',
    'Infrastructure',
    'Transport',
    'Wealth and Economy',
    'Heritage and Identity',
    'Health and Wellbeing',
    'Community and Belonging',
    'Crime and Safety',
    'Environmental Issues',
    'Data capture',
  ];

  return {
    THEMES: THEMES,
  };
};
