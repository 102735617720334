import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import SvgIcon from 'common/components/SvgIcon';
import UpdateDetailsButton from './updateDetails/UpdateDetailsButton';
import InformationUpdateDetails from './updateDetails/InformationUpdateDetails';
import CustomizeUpdateDetails from './updateDetails/CustomizeUpdateDetails';
import PollUpdateDetails from './updateDetails/PollUpdateDetails';

export const PROJECT = 'information';
export const CUSTOMISE = 'customise';
export const SEGMENTATION = 'segmentation';
export const POLL = 'poll';
export const DEMOGRAPHICS = 'demographics';

function UpdateItem(props) {
  const { id, label, type, validation, onSelectionChange, hasDetails = true, tag, value, Store } = props;

  const [showDetails, setShowDetails] = useState(false);

  function updateSelectedCollection(currenCollection, targetValue, checked) {
    let selectedCollection = [];

    if (checked) {
      selectedCollection = [...currenCollection, targetValue];
    } else {
      selectedCollection = currenCollection.filter((v) => v !== targetValue);
    }

    return selectedCollection;
  }

  function onChange(event) {
    let information = Store.formState.information;
    let customize = Store.formState.customize;
    let segmentation = Store.formState.segmentation;
    let polls = Store.formState.polls;
    let demographics = Store.formState.demographics;

    const tempPolls = [];
    const tempDemographics = [];

    switch (type) {
      case PROJECT:
        information = event.target.checked;
        break;
      case CUSTOMISE:
        customize = event.target.checked;
        break;
      case SEGMENTATION:
        segmentation = event.target.checked;
        break;
      case POLL:
        polls = updateSelectedCollection(polls, event.target.value, event.target.checked);
        break;
      case DEMOGRAPHICS:
        demographics = updateSelectedCollection(demographics, event.target.value, event.target.checked);
        break;
      default:
        break;
    }

    Store.formState = {
      information,
      customize,
      segmentation,
      polls,
      demographics,
    };

    onSelectionChange(event.target.checked);
  }

  function showDetailsHandler() {
    setShowDetails(!showDetails);
  }

  function getDetailsComponent() {
    switch (type) {
      case PROJECT:
        return (
          <div className='c-table-validation' data-testid={`${PROJECT}-details-wrapper`}>
            <InformationUpdateDetails infoValidation={validation} sectionLabel='Section' />
          </div>
        );
      case POLL:
        return (
          <div className='c-table-validation' data-testid={`${POLL}-details-wrapper`}>
            <PollUpdateDetails pollValidation={validation} sectionLabel='Questions' />
          </div>
        );
      case CUSTOMISE:
        return (
          <div className='c-table-validation' data-testid={`${CUSTOMISE}-details-wrapper`}>
            <CustomizeUpdateDetails customizeValidation={validation} sectionLabel='Section' />
          </div>
        );
      default:
        return null;
    }
  }

  return (
    <div className='o-checkbox' data-testid={`update-item-wrapper-${id}`}>
      <input type='checkbox' id={`checkbox-update-${id}`} onChange={onChange} value={value} />
      <label htmlFor={`checkbox-update-${id}`}>
        {label}
        {tag ? <span className='o-tag'>{tag}</span> : '\u00A0'}
        <SvgIcon icon='check' />
      </label>
      {hasDetails && (
        <Fragment>
          <UpdateDetailsButton
            updateStatus={validation.validation.validationStatus}
            toggleUpdateDetailsVisible={showDetailsHandler}
          />
          {showDetails && getDetailsComponent()}
        </Fragment>
      )}
    </div>
  );
}

UpdateItem.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  validation: PropTypes.object,
  onSelectionChange: PropTypes.func.isRequired,
  hasDetails: PropTypes.bool,
  value: PropTypes.string,
  tag: PropTypes.string,
  Store: PropTypes.object.isRequired,
};

export default inject((root) => ({ Store: root.RootStore.projectControlStore }))(observer(UpdateItem));
