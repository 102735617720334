import React from 'react';
import { Link } from 'react-router-dom';

import SvgIcon from '../components/SvgIcon';
import { HOME } from '../constants/RouteConstants';

export default function Staff() {
  // const { match } = props;
  return (
    <div className='l-body'>
      <div className='l-container'>
        <div className='l-page'>
          <div className='l-main'>
            <div className='c-message c-message--icon c-message--highlight is-danger'>
              <SvgIcon icon='triangle' />
              <h3 className='c-message__title'>Unauthorised access</h3>
              <p className='c-message__description'>
                You were trying to access a resource you are not authorised to access.
                <br />
                <Link to={`/${HOME}`}>Go to the homepage</Link> or contact the admin for help.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
