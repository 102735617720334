import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import { IMAGE_FORMATS } from '../../../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import EngageTextInputField from '../../../../../common/components/form-elements/textInputField/TextInputField';
import EngageSelectInputField from '../../../../../common/components/form-elements/selectInputField/SelectInputField';
import ImageChoice from '../../../../../common/components/form-elements/imageChoice/ImageChoice';
import SwitchableButtonBar from '../../../../../common/components/switchableButtonBar/SwitchableButtonBar';
import { QUESTION_CHARACTER_LIMIT, IMAGE_CHARACTER_LIMIT } from '../../../../../common/constants/QuestionConstants';
import { getFormFieldClass, getFormFieldTranslationTooltip } from '../../../../../common/TranslationUtils';
import displayConfirmationPrompt from '../../../../../common/PromptUtil';
import ConditionalLogicUtil from '../../../../../common/ConditionalLogicUtil';
import ConditionalLogic from '../../../../../common/components/conditionalLogic/ConditionalLogic';

function MultipleImageQuestion(props) {
  const {
    question,
    updateQuestion,
    uploadChoiceImage,
    removeChoiceImage,
    removeQuestion,
    openPollPreview,
    showConditionalLogic,
    Store,
    ToastrStore,
  } = props;

  const CHOICES = [
    [4, '4 choices'],
    [6, '6 choices'],
    [8, '8 choices'],
  ];
  const ANSWERS = [
    [1, 'Only 1'],
    [2, 'Up to 2'],
    [3, 'Up to 3'],
    [4, 'Up to 4'],
  ];
  const CHOICE_LABELS = [
    'Choice 1',
    'Choice 2',
    'Choice 3',
    'Choice 4',
    'Choice 5',
    'Choice 6',
    'Choice 7',
    'Choice 8',
  ];

  const [formValues, setFormValues] = useState();
  const [rules, setRules] = useState([]);
  const [choiceFieldNames, setChoiceFieldNames] = useState([]);

  const choices = question?.choices || [];

  useEffect(() => {
    if (question) {
      const initialFormQuestionValues = {
        questionText: question.questionText,
        multiChoicesOfferedBound: question.multiChoicesOfferedBound,
        multiChoicesSelectableBound: question.multiChoicesSelectableBound,
      };

      question.choices.forEach((choice, index) => {
        const choiceFieldName = ConditionalLogicUtil.QUESTION_CHOICE_FIELDS[index].fieldName;
        if (choice.choice) initialFormQuestionValues[choiceFieldName] = choice.choice;
      });

      const initialState = ConditionalLogicUtil.setupFormInitialValues(question, initialFormQuestionValues);
      setFormValues({ ...initialFormQuestionValues, ...initialState.initialFormConditionValues });
      setRules(initialState.initialRules);
      setChoiceFieldNames(ConditionalLogicUtil.QUESTION_CHOICE_FIELDS.slice(0, question.multiChoicesOfferedBound));
    }
  }, [question]);

  const onSubmit = (values) => {
    // console.log(values);
    const updatedQuestionData = toJS(question);
    updatedQuestionData.questionText = values.questionText;
    updatedQuestionData.multiChoicesOfferedBound = values.multiChoicesOfferedBound;
    updatedQuestionData.multiChoicesSelectableBound = values.multiChoicesSelectableBound;
    updatedQuestionData.choices[0].choice = values.choice1Label || '';
    updatedQuestionData.choices[1].choice = values.choice2Label || '';
    updatedQuestionData.choices[2].choice = values.choice3Label || '';
    updatedQuestionData.choices[3].choice = values.choice4Label || '';
    updatedQuestionData.choices[4].choice = values.choice5Label || '';
    updatedQuestionData.choices[5].choice = values.choice6Label || '';
    updatedQuestionData.choices[6].choice = values.choice7Label || '';
    updatedQuestionData.choices[7].choice = values.choice8Label || '';

    ConditionalLogicUtil.extractConditionalFormValues(question, updatedQuestionData, values, rules);

    updateQuestion(updatedQuestionData);
  };

  function isChoicesLimit(value, limit) {
    if (!value) return false;
    return parseInt(value, 10) === limit;
  }

  function choicesOfferedChangeHandler(newChoiceCount, oldChoiceCount, currentFormValues) {
    let changeApproved = true;

    if (newChoiceCount < oldChoiceCount) {
      if (ConditionalLogicUtil.hasTempConditionalLogic(currentFormValues)) {
        const promptMessage =
          "If you reduce number of possible choices all conditional logic rules will be removed. Please enter 'DELETE' to confirm removal of conditional logic rules.";

        const res = displayConfirmationPrompt(promptMessage, '', false, ToastrStore, true);

        if (res !== 1) changeApproved = false;
      }

      if (changeApproved) {
        const newState = ConditionalLogicUtil.removeConditionalLogic(currentFormValues, question._id);
        setRules(newState.newRules);
        setFormValues(newState.newFormValues);
      }
    }

    if (changeApproved) setChoiceFieldNames(ConditionalLogicUtil.QUESTION_CHOICE_FIELDS.slice(0, newChoiceCount));

    return changeApproved;
  }

  return (
    <Form
      onSubmit={onSubmit}
      keepDirtyOnReinitialize={true}
      initialValues={formValues}
      render={({ values, handleSubmit }) => {
        return (
          <form className='c-fields l-form'>
            <EngageTextInputField
              id={`input-question-${question._id}`}
              placeholder='Enter question'
              label='Question'
              maxLength={QUESTION_CHARACTER_LIMIT}
              fieldDescription=''
              fieldName='questionText'
              isRequired={true}
              fieldClass={getFormFieldClass(question.defaultData.questionText, null, 'l-form__item')}
              tooltipInLabel={getFormFieldTranslationTooltip(question.defaultData.questionText)}
            />

            <div className='c-toggle-content'>
              <div className='c-toggle-content__container'>
                <ConditionalLogic
                  formValues={values}
                  setFormValues={setFormValues}
                  question={question}
                  questions={Store.poll.questions}
                  rules={rules}
                  setRules={setRules}
                  show={showConditionalLogic}
                  choiceFieldNames={choiceFieldNames}
                />
                <div className='c-toggle-content__content' hidden={showConditionalLogic}>
                  <div className='l-form'>
                    <EngageSelectInputField
                      choices={CHOICES}
                      fieldName='multiChoicesOfferedBound'
                      id={`input-question-choices-${question._id}`}
                      label='Choices'
                      fieldClass='l-form__item--m'
                      firstOptionEmpty={false}
                      onSelectionChange={(newValue) =>
                        choicesOfferedChangeHandler(newValue, values.multiChoicesOfferedBound, values)
                      }
                      requireChangeApproval={true}
                    />

                    <EngageSelectInputField
                      choices={ANSWERS}
                      fieldName='multiChoicesSelectableBound'
                      id={`input-question-answers-${question._id}`}
                      label='Possible answers'
                      fieldClass='l-form__item--m'
                      firstOptionEmpty={false}
                    />

                    {choices.slice(0, values.multiChoicesOfferedBound).map((choice, index) => (
                      <ImageChoice
                        imageFormat={IMAGE_FORMATS.SQUARE}
                        key={choice._id}
                        index={index}
                        placeholder='Choice name'
                        maxLength={IMAGE_CHARACTER_LIMIT}
                        choice={choice}
                        fieldName={`choice${index + 1}Label`}
                        label={CHOICE_LABELS[index]}
                        handleUpload={uploadChoiceImage}
                        removeImage={removeChoiceImage}
                        classModifier={getFormFieldClass(
                          choice.defaultData.choice,
                          choice.defaultData.resource,
                          isChoicesLimit(values.multiChoicesOfferedBound, 4) ? 'l-form__item--m' : 'l-form__item--s',
                        )}
                        tooltipInLabel={getFormFieldTranslationTooltip(
                          choice.defaultData.choice,
                          choice.defaultData.resource,
                          IMAGE_FORMATS.SQUARE,
                        )}
                      />
                    ))}

                    {isChoicesLimit(values.multiChoicesOfferedBound, 8) && (
                      <div className='l-form__item l-form__item--s' />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <SwitchableButtonBar
              updateAction={handleSubmit}
              removeAction={removeQuestion}
              isNew={null}
              previewAction={() => openPollPreview(question._id)}
            />
          </form>
        );
      }}
    />
  );
}

MultipleImageQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  updateQuestion: PropTypes.func.isRequired,
  uploadChoiceImage: PropTypes.func.isRequired,
  removeChoiceImage: PropTypes.func.isRequired,
  removeQuestion: PropTypes.func.isRequired,
  showConditionalLogic: PropTypes.bool.isRequired,
  Store: PropTypes.object.isRequired,
  ToastrStore: PropTypes.object.isRequired,
};

export default observer(MultipleImageQuestion);
