// NOTE:
//      Derived from /server/bid/api/model/permissions/role.enums.js
//

// Motivation / rationale:
//
// Any user may access GMV backend app if she has Permissions.ENGAGE_COMPANY_PERMISSIONS.ENGAGE_BASIC_SERVICE permission.
// Required permission is obtained either associating user with company that has required permission, or in case of administrative people, it may be granted by supervisor as personal permission.
//
// Binding user-profile must be explicit, and it intended for regular GMV backend users, i.e. those who do not have special admin privileges. Binding is stored in Engage.Profile.Backend.User collection.
//
// After company account has been terminated, for post-mortem reporting & analyses within BID/GMV team, Permissions.ENGAGE_USER_PERMISSIONS.ENGAGE_REPORT permission permits read access to DB.
//
(function(){

  module.exports = function(){
  const roleIDs = {
      ENGAGE_BASIC_ENABLED: 'ENGAGE_BASIC_ENABLED', // enables full access to the projects / polls (editing & reporting)
      SUPER_ADMIN: 'SUPER_ADMIN', // can do all, respect to Admin CAN create other Admin and SuperAdmin users
      ADMINISTRATOR: 'ADMINISTRATOR', // can do all, Can create other users, NOT ALLOWED to create other admin users
      ACCOUNT_MANAGER: 'ACCOUNT_MANAGER', // can do anything on ALL clients
      ACCOUNT_ASISTANT: 'ACCOUNT_ASISTANT', // can do anything on ASSIGNED clients (ie. comms agencies)
      ACCOUNT_VIEWER: 'ACCOUNT_VIEWER', // as an administrative staff member, restricted to view only
      CLIENT_MASTER: 'CLIENT_MASTER', // account to be given to client as master account. Can create ClientUser(s), Can do anything on single client (all projects)
      CLIENT_BRANCH_MANAGER: 'CLIENT_BRANCH_MANAGER', // account to be given to client as branch manager account. Can create ClientUser(s), Can do anything on single client restricted to certain projects
      CLIENT_USER: 'CLIENT_USER', // can manage/edit any client project, Can be assigned to particular project(s) - in this case she can work only on assigned projects
      CLIENT_VIEWER: 'CLIENT_VIEWER', // as a client staff member, restricted to view only
  };

  const roleLabels = {
      ENGAGE_BASIC_ENABLED: 'Engage Feature Enabled',
      SUPER_ADMIN: 'SuperAdmin (Team)',
      ADMINISTRATOR: 'Admin (Team)',
      ACCOUNT_MANAGER: 'Account Manager (Team)',
      ACCOUNT_ASISTANT: 'Account Assistant (Team)',
      ACCOUNT_VIEWER: 'Viewer (Team)',
      CLIENT_MASTER: 'Master (Client)',
      CLIENT_BRANCH_MANAGER: 'Branch manager (Client)',
      CLIENT_USER: 'User (Client)',
      CLIENT_VIEWER: 'Viewer (Client)'
  };

  const roleDescriptions = {
      ENGAGE_BASIC_ENABLED: 'Engage Feature Enabled',
      SUPER_ADMIN: '(BID) Super Admin (AS)',
      ADMINISTRATOR: '(BID) Admin (AD)',
      ACCOUNT_MANAGER: '(BID) Account Manager (AM)',
      ACCOUNT_ASISTANT: '(BID) Account Assistant (AA)',
      ACCOUNT_VIEWER: '(BID) View Only Restriction (AV)',
      CLIENT_MASTER: 'Client Master (CM)',
      CLIENT_BRANCH_MANAGER: 'Client Branch manager (CBM)',
      CLIENT_USER: 'Client User (CU)',
      CLIENT_VIEWER: 'Client User View Only Restriction (AV)'
  };

  const category = {
      COMPANY_SERVICES: 'COMPANY_SERVICES',
      BID_GMV_STAFF: 'BID_GMV_FORCE_STAFF',
      CLIENT_COMPANY: 'CLIENT_COMPANY_STAFF'
  };


  return {
    roleIDs: roleIDs,
    roleLabels: roleLabels,
    roleDescriptions: roleDescriptions,
    category: category,
  };

  }
})();
