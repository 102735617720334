import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import SvgIcon from '../components/SvgIcon';
import LanguageSelector from '../components/languageSelector/LanguageSelector';
import {
  EDIT_PROJECT,
  PROJECT,
  INFORMATION,
  CUSTOMIZE,
  GEOLOCATION,
  TIMELINE,
  LANGUAGES,
  IMAGE_GALLERY,
  TEAM,
  INFLUENCED_DECISIONS,
  CHARITIES,
  PANELS,
  POLLS,
  FAQ,
  NEWS,
  SEGMENTATION,
  routeToLabel,
  CONTENT,
  ACTIONS,
  BLOCKS,
  PERSONAL_DATA,
  DEMOGRAPHICS,
} from '../constants/RouteConstants';
import { VIEW } from '../ability';
import { Can } from '../Can';

function AsideEdit({ match }) {
  // console.log('ASIDE', match);
  // console.log(name);
  const [isDrawerOpened, setDrawerState] = useState(false);

  const childMenus = [GEOLOCATION, TIMELINE, LANGUAGES, IMAGE_GALLERY, TEAM, INFLUENCED_DECISIONS, CHARITIES];
  const pollsChildMenus = [SEGMENTATION, DEMOGRAPHICS];
  const customizeChildMenus = [PANELS, CONTENT, ACTIONS, BLOCKS, PERSONAL_DATA];

  function getSelectedClass(item) {
    // Handle parent selection for information menu
    if (item === INFORMATION && childMenus.some((menu) => menu === match.params.route)) {
      return 'c-nav-drawer__item is-parent-active';
    }

    if (item === POLLS && pollsChildMenus.some((menu) => menu === match.params.route)) {
      return 'c-nav-drawer__item is-parent-active';
    }
    if (item === CUSTOMIZE && customizeChildMenus.some((menu) => menu === match.params.route)) {
      return 'c-nav-drawer__item is-parent-active';
    }

    // Other items
    return item === match.params.route ? 'c-nav-drawer__item is-active' : 'c-nav-drawer__item';
  }

  function getLink(route) {
    return `/${PROJECT}/${match.params.id}/${EDIT_PROJECT}/${route}`;
  }

  return (
    <div className='l-aside'>
      <div className={`c-nav-drawer c-nav-drawer--icons ${isDrawerOpened ? 'c-nav-drawer-opened' : ''}`}>
        <div className='c-nav-drawer__content'>
          <ul
            className='c-nav-drawer__items'
            onClick={() => {
              setDrawerState(!isDrawerOpened);
            }}
          >
            <Can I={VIEW} a={INFORMATION}>
              <li className={getSelectedClass(INFORMATION)} onClick={(e) => e.stopPropagation()}>
                <Link className='c-nav-drawer__link' to={getLink(INFORMATION)}>
                  <SvgIcon icon='map-pin' />
                  {routeToLabel(INFORMATION)}
                </Link>
                <ul className='c-nav-drawer__children' onClick={() => setDrawerState(!isDrawerOpened)}>
                  <Can I={VIEW} a={GEOLOCATION}>
                    <li className={getSelectedClass(GEOLOCATION)}>
                      <Link className='c-nav-drawer__link' to={getLink(GEOLOCATION)}>
                        {routeToLabel(GEOLOCATION)}
                      </Link>
                    </li>
                  </Can>
                  <Can I={VIEW} a={TIMELINE}>
                    <li className={getSelectedClass(TIMELINE)}>
                      <Link className='c-nav-drawer__link' to={getLink(TIMELINE)}>
                        {routeToLabel(TIMELINE)}
                      </Link>
                    </li>
                  </Can>
                  <Can I={VIEW} a={LANGUAGES}>
                    <li className={getSelectedClass(LANGUAGES)}>
                      <Link className='c-nav-drawer__link' to={getLink(LANGUAGES)}>
                        {routeToLabel(LANGUAGES)}
                      </Link>
                    </li>
                  </Can>
                  <Can I={VIEW} a={IMAGE_GALLERY}>
                    <li className={getSelectedClass(IMAGE_GALLERY)}>
                      <Link className='c-nav-drawer__link' to={getLink(IMAGE_GALLERY)}>
                        {routeToLabel(IMAGE_GALLERY)}
                      </Link>
                    </li>
                  </Can>
                  <Can I={VIEW} a={TEAM}>
                    <li className={getSelectedClass(TEAM)}>
                      <Link className='c-nav-drawer__link' to={getLink(TEAM)}>
                        {routeToLabel(TEAM)}
                      </Link>
                    </li>
                  </Can>
                  <Can I={VIEW} a={INFLUENCED_DECISIONS}>
                    <li className={getSelectedClass(INFLUENCED_DECISIONS)}>
                      <Link className='c-nav-drawer__link' to={getLink(INFLUENCED_DECISIONS)}>
                        {routeToLabel(INFLUENCED_DECISIONS)}
                      </Link>
                    </li>
                  </Can>
                  <Can I={VIEW} a={CHARITIES}>
                    <li className={getSelectedClass(CHARITIES)}>
                      <Link className='c-nav-drawer__link' to={getLink(CHARITIES)}>
                        {routeToLabel(CHARITIES)}
                      </Link>
                    </li>
                  </Can>
                </ul>
              </li>
            </Can>

            <Can I={VIEW} a={POLLS}>
              <li className={getSelectedClass(POLLS)}>
                <Link className='c-nav-drawer__link' to={getLink(POLLS)}>
                  <SvgIcon icon='poll' />
                  {routeToLabel(POLLS)}
                </Link>
                <ul className='c-nav-drawer__children' onClick={() => setDrawerState(!isDrawerOpened)}>
                  <Can I={VIEW} a={SEGMENTATION}>
                    <li className={getSelectedClass(SEGMENTATION)}>
                      <Link className='c-nav-drawer__link' to={getLink(SEGMENTATION)}>
                        {routeToLabel(SEGMENTATION)}
                      </Link>
                    </li>
                  </Can>
                  <Can I={VIEW} a={DEMOGRAPHICS}>
                    <li className={getSelectedClass(DEMOGRAPHICS)}>
                      <Link className='c-nav-drawer__link' to={getLink(DEMOGRAPHICS)}>
                        {routeToLabel(DEMOGRAPHICS)}
                      </Link>
                    </li>
                  </Can>
                </ul>
              </li>
            </Can>
            <Can I={VIEW} a={CUSTOMIZE}>
              <li className={getSelectedClass(CUSTOMIZE)}>
                <Link className='c-nav-drawer__link' to={getLink(CUSTOMIZE)}>
                  <SvgIcon icon='edit' />
                  {routeToLabel(CUSTOMIZE)}
                </Link>
                <ul className='c-nav-drawer__children' onClick={() => setDrawerState(!isDrawerOpened)}>
                  <Can I={VIEW} a={PANELS}>
                    <li className={getSelectedClass(PANELS)}>
                      <Link className='c-nav-drawer__link' to={getLink(PANELS)}>
                        {routeToLabel(PANELS)}
                      </Link>
                    </li>
                  </Can>
                  <Can I={VIEW} a={CONTENT}>
                    <li className={getSelectedClass(CONTENT)}>
                      <Link className='c-nav-drawer__link' to={getLink(CONTENT)}>
                        {routeToLabel(CONTENT)}
                      </Link>
                    </li>
                  </Can>
                  <Can I={VIEW} a={ACTIONS}>
                    <li className={getSelectedClass(ACTIONS)}>
                      <Link className='c-nav-drawer__link' to={getLink(ACTIONS)}>
                        {routeToLabel(ACTIONS)}
                      </Link>
                    </li>
                  </Can>
                  <Can I={VIEW} a={BLOCKS}>
                    <li className={getSelectedClass(BLOCKS)}>
                      <Link className='c-nav-drawer__link' to={getLink(BLOCKS)}>
                        {routeToLabel(BLOCKS)}
                      </Link>
                    </li>
                  </Can>
                  <Can I={VIEW} a={PERSONAL_DATA}>
                    <li className={getSelectedClass(PERSONAL_DATA)}>
                      <Link className='c-nav-drawer__link' to={getLink(PERSONAL_DATA)}>
                        {routeToLabel(PERSONAL_DATA)}
                      </Link>
                    </li>
                  </Can>
                </ul>
              </li>
            </Can>
            <Can I={VIEW} a={FAQ}>
              <li className={getSelectedClass(FAQ)}>
                <Link className='c-nav-drawer__link' to={getLink(FAQ)}>
                  <SvgIcon icon='pen-alt' />
                  {routeToLabel(FAQ)}
                </Link>
              </li>
            </Can>
            <Can I={VIEW} a={NEWS}>
              <li className={getSelectedClass(NEWS)}>
                <Link className='c-nav-drawer__link' to={getLink(NEWS)}>
                  <SvgIcon icon='megaphone' />
                  {routeToLabel(NEWS)}
                </Link>
              </li>
            </Can>
          </ul>
          <LanguageSelector />
        </div>
      </div>
    </div>
  );
}

export default inject((root) => ({ ProjectStore: root.RootStore.projectStore }))(observer(AsideEdit));
