import React from 'react';
import PropTypes from 'prop-types';

function ConditionalFooter(props) {
  const { onRemoveConditionalLogic } = props;

  return (
    <footer className='c-conditional__footer' data-testid='conditionalFooter-wrapper'>
      <button
        className='o-button o-button--danger o-button--solid o-button--s'
        type='button'
        onClick={onRemoveConditionalLogic}
      >
        <span className='o-label'>Remove conditional logic</span>
      </button>
    </footer>
  );
}

ConditionalFooter.propTypes = {
  onRemoveConditionalLogic: PropTypes.func.isRequired,
};

export default ConditionalFooter;
