import Agent from './agent';

const requests = Agent.requests;
const ENDPOINT = 'panel';

const PanelsAgent = {
  // Panels list
  create: (projectId, data) => requests.post(`/projects/${projectId}/${ENDPOINT}`, data),

  // Panel edit
  update: (projectId, itemId, data) => requests.patch(`/projects/${projectId}/${ENDPOINT}/${itemId}`, data),
  remove: (projectId, itemId) => requests.remove(`/projects/${projectId}/${ENDPOINT}/${itemId}`),
  uploadResource: (projectId, itemId, file, progress) =>
    requests.uploadImage(`/projects/${projectId}/${ENDPOINT}/${itemId}/resource`, 'panel', file, progress),
  removeResource: (projectId, itemId) => requests.remove(`/projects/${projectId}/${ENDPOINT}/${itemId}/resource`),
};

export default PanelsAgent;
