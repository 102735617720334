import React from 'react';
import PropTypes from 'prop-types';
import SimpleSelectInputField from '../form-elements/selectInputField/SimpleSelectInputField';
import Pagination from '../pagination/Pagination';

export const RESULTS_PER_PAGE = [
  [1, 25],
  [2, 50],
  [3, 100],
];

function PollReportFeedbackPagination(props) {
  const { resultsPerPage, onResultsPerPageChange, totalFeedbackCount, numberOfPages, onPaginationChange, currentPage } =
    props;

  return (
    <nav className='c-nav-page' data-testid='feedback-pagination-wrapper'>
      <div className='c-nav-page__meta'>
        <SimpleSelectInputField
          id='resultsPerPage'
          input={{
            value: resultsPerPage,
            onChange: (event) => onResultsPerPageChange(event.target.value),
          }}
          choices={RESULTS_PER_PAGE}
          firstOptionEmpty={false}
          baseFieldClass=''
          fieldClass='c-field--label-hidden'
          selectClass='o-select--inline'
        />
        <div className='c-nav-page__total'>{`${totalFeedbackCount} results `}</div>
      </div>
      <Pagination numberOfPages={numberOfPages} onPageChange={onPaginationChange} currentPage={currentPage} />
    </nav>
  );
}

PollReportFeedbackPagination.propTypes = {
  resultsPerPage: PropTypes.number,
  onResultsPerPageChange: PropTypes.func.isRequired,
  totalFeedbackCount: PropTypes.number.isRequired,
  numberOfPages: PropTypes.number.isRequired,
  onPaginationChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
};

export default PollReportFeedbackPagination;
