import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { MANAGED, RECENT, getFilteredClients, getFilteredProjects } from 'common/FilterUtils';
import SvgIcon from '../../common/components/SvgIcon';
import { PROFILE, PROJECTS } from '../../common/constants/RouteConstants';
import OverviewFilters from './OverviewFilters';
import ClientList from './ClientList';
import RecentlyVisited from './RecentlyVisited';

function Overview(props) {
  const { Store, AuthStore, UsersStore } = props;
  const [allClients, setAllClients] = useState([]);
  const [recentlyVisited, setRecentlyVisited] = useState({ clients: [], projects: [] });
  const [filterState, setFilterState] = useState({ selectedFilterOption: RECENT, searchValue: '' });

  useEffect(() => {
    if (!Store) return;

    Promise.all([Store.loadAllClients(), Store.getRecentClientsAndProjects()]).then((data) => {
      const clients = data[0];
      const recent = data[1];

      setAllClients(clients);
      setRecentlyVisited({ clients: recent.recentClients, projects: recent.recentProjects });

      if (!recent?.recentClients?.[0] && !recent?.recentProjects?.[0])
        setFilterState({ selectedFilterOption: MANAGED, searchValue: '' });
    });
  }, [Store]);

  return (
    <React.Fragment>
      <OverviewFilters filter={{ filterState, setFilterState }} />
      {filterState.selectedFilterOption === RECENT ? (
        <RecentlyVisited
          clients={getFilteredClients(
            recentlyVisited.clients,
            filterState.selectedFilterOption,
            filterState.searchValue,
          )}
          projects={getFilteredProjects(recentlyVisited.projects, filterState.searchValue)}
        />
      ) : (
        <ClientList
          clients={getFilteredClients(allClients, filterState.selectedFilterOption, filterState.searchValue)}
        />
      )}
    </React.Fragment>
  );
}

Overview.propTypes = {
  Store: PropTypes.object.isRequired,
};

export default inject((root) => ({
  Store: root.RootStore.clientStore,
}))(observer(Overview));
