import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

export default function PillActionButton(props) {
  const { id, label, onKeywordRemove } = props;
  return (
    <div className='o-pill' data-testid={`${id}-pill-action`}>
      <span className='o-pill__label'>{label}</span>
      <button className='o-pill__action' onClick={() => onKeywordRemove(label)}>
        <SvgIcon icon='times' />
        <span className='o-label'>Remove</span>
      </button>
    </div>
  );
}

PillActionButton.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onKeywordRemove: PropTypes.func.isRequired,
};
