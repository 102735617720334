import Agent from './agent';

const requests = Agent.requests;
const ENDPOINT = 'demographics';

const DemographicAgent = {
  // Project demographics
  getAll: (projectId) => requests.get(`/projects/${projectId}/${ENDPOINT}`),
  getById: (projectId, demographicsId) => requests.get(`/projects/${projectId}/${ENDPOINT}/${demographicsId}`),

  // Project demographic questions
  createQuestion: (projectId, demographicsId, data) =>
    requests.post(`/projects/${projectId}/${ENDPOINT}/${demographicsId}/add-custom-question`, data),
  updateQuestion: (projectId, demographicsId, questionId, data) =>
    requests.patch(`/projects/${projectId}/${ENDPOINT}/${demographicsId}/question/${questionId}`, data),
  removeQuestion: (projectId, demographicsId, questionId) =>
    requests.remove(`/projects/${projectId}/${ENDPOINT}/${demographicsId}/question/${questionId}`),
  overrideQuestion: (projectId, demographicsId, questionId) =>
    requests.post(`/projects/${projectId}/${ENDPOINT}/${demographicsId}/question/${questionId}/convert-to-custom`),
  reorderQuestions: (projectId, demographicsId, data) =>
    requests.post(`/projects/${projectId}/${ENDPOINT}/${demographicsId}/reorder-questions`, data),
  uploadQuestionImage: (projectId, demographicsId, questionId, file, progress) =>
    requests.uploadImage(
      `/projects/${projectId}/${ENDPOINT}/${demographicsId}/images/questions/${questionId}/resource/`,
      'project',
      file,
      progress,
    ),
  removeQuestionImage: (projectId, demographicsId, questionId, imageId) =>
    requests.remove(
      `/projects/${projectId}/${ENDPOINT}/${demographicsId}/images/questions/${questionId}/resource/${imageId}`,
    ),

  // Project demographic question choices
  createQuestionChoice: (projectId, demographicsId, questionId, data) =>
    requests.post(`/projects/${projectId}/${ENDPOINT}/${demographicsId}/question/${questionId}/choice`, data),
  removeQuestionChoice: (projectId, demographicsId, questionId, choiceId) =>
    requests.remove(`/projects/${projectId}/${ENDPOINT}/${demographicsId}/question/${questionId}/choice/${choiceId}`),
  reorderQuestionChoices: (projectId, demographicsId, questionId, data) =>
    requests.post(
      `/projects/${projectId}/${ENDPOINT}/${demographicsId}/question/${questionId}/reorder-question-choices`,
      data,
    ),
};

export default DemographicAgent;
