import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import SimpleCheckboxInput from './SimpleCheckboxInputField';

export default function EngageCheckboxInputField(props) {
  const { fieldName } = props;

  return <Field component={SimpleCheckboxInput} name={fieldName} {...props} />;
}

EngageCheckboxInputField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  validator: PropTypes.func,
};
