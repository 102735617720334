import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import SimpleRadioGroupInputField from './SimpleRadioGroupInputField';

export default function RadioGroupInputField(props) {
  const { fieldName, isRequired } = props;

  function validateRequired(value) {
    return !value && isRequired ? 'Required' : undefined;
  }

  return (
    <Field
      component={SimpleRadioGroupInputField}
      name={fieldName}
      type='radio'
      validate={validateRequired}
      {...props}
    />
  );
}

RadioGroupInputField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
};
