import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { arrayMoveImmutable } from 'array-move';
import { SortableContainer } from 'react-sortable-hoc';
import DEMOGRAPHIC_ENUMS from '../../../shared/engage/engage-demographics-enums';
import DraggableTextInputFieldWithAction from '../../../common/components/form-elements/draggableTextInputFieldWithAction/DraggableTextInputFieldWithAction';
import SvgIcon from '../../../common/components/SvgIcon';

const DEFINED = DEMOGRAPHIC_ENUMS().DemographicsChoiceContentTypes.DEFINED;
const OTHER = DEMOGRAPHIC_ENUMS().DemographicsChoiceContentTypes.OTHER;
const SKIP = DEMOGRAPHIC_ENUMS().DemographicsChoiceContentTypes.SKIP;

export const CHOICE_IDENTIFIER_PREFIX = 'choice';

function ChoiceContainer(props) {
  const { choices, onListItemAction, isEditable, allowDrag, allowChoiceDelete } = props;

  function getChoiceIcon(choice) {
    let icon;
    switch (choice.choiceContentType) {
      case OTHER:
        icon = 'pen-alt';
        break;
      case SKIP:
        icon = 'forward';
        break;
      default:
        icon = '';
        break;
    }

    return icon;
  }

  function getInputModifier(choice) {
    return choice.choiceContentType === DEFINED ? '' : 'o-input-icon';
  }

  return (
    <div className='c-field__reorder-list'>
      {choices.map((choice, index) => (
        <DraggableTextInputFieldWithAction
          key={`${choice._id}-${index}`}
          id={choice._id}
          index={index}
          choice={choice}
          onAction={(event) => onListItemAction(choice, event)}
          disableAction={!allowChoiceDelete}
          isEditable={isEditable}
          isInputEditable={choice.choiceContentType === DEFINED}
          fieldName={`${CHOICE_IDENTIFIER_PREFIX}-${choice._id}`}
          placeholder='Option'
          choiceIcon={getChoiceIcon(choice)}
          inputModifier={getInputModifier(choice)}
          disabled={!allowDrag}
        />
      ))}
    </div>
  );
}

ChoiceContainer.propTypes = {
  choices: PropTypes.array.isRequired,
  onListItemAction: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  allowDrag: PropTypes.bool.isRequired,
  allowChoiceDelete: PropTypes.bool.isRequired,
};

const SortList = SortableContainer((props) => {
  return <ChoiceContainer {...props} />;
});

function ChoicesList(props) {
  const {
    currentChoices,
    addChoice,
    removeChoice,
    reorderChoice,
    allowDrag,
    allowChoiceDelete,
    isCustomQuestion,
    showWarningMessage,
  } = props;

  function onSortEnd({ oldIndex, newIndex }) {
    const newOrder = arrayMoveImmutable(currentChoices, oldIndex, newIndex);
    reorderChoice(newOrder);
  }

  function deleteChoiceHandler(choice, event) {
    event.preventDefault();

    if (currentChoices.length > 1) {
      removeChoice(choice._id);
    } else {
      showWarningMessage(`Can't delete last option`);
    }
  }

  function addChoiceHandler(event, choiceType) {
    event.preventDefault();
    addChoice(choiceType);
  }

  function containsOtherChoice() {
    if (currentChoices.find((ch) => ch.choiceContentType === OTHER)) return true;

    return false;
  }

  function containsSkipChoice() {
    if (currentChoices.find((choice) => choice.choiceContentType === SKIP)) return true;

    return false;
  }
  return (
    <div className='c-field l-form__item c-field--reorder-list'>
      <label className='c-field__label'>Options</label>
      <div className='c-field__group'>
        <SortList
          useDragHandle={true}
          helperClass='c-field--reorder-helper'
          onSortEnd={onSortEnd}
          onListItemAction={deleteChoiceHandler}
          choices={currentChoices}
          isEditable={isCustomQuestion}
          allowDrag={allowDrag}
          allowChoiceDelete={allowChoiceDelete}
        />

        {isCustomQuestion && (
          <Fragment>
            <button
              className='o-button o-button--primary o-button--xs js-demographicOptionAdd'
              onClick={(event) => addChoiceHandler(event, DEFINED)}
              type='button'
            >
              <span className='o-label'>Add option</span>
            </button>
            {!containsOtherChoice() && (
              <button
                className='o-button o-button--naked o-button--xs js-demographicOtherAdd'
                onClick={(event) => addChoiceHandler(event, OTHER)}
                type='button'
              >
                <span className='o-label'>Add other</span>
                <SvgIcon icon='pen-alt' />
              </button>
            )}
            {!containsSkipChoice() && (
              <button
                className='o-button o-button--naked o-button--xs js-demographicSkipAdd'
                onClick={(event) => addChoiceHandler(event, SKIP)}
                type='button'
              >
                <span className='o-label'>Add skip</span>
                <SvgIcon icon='forward' />
              </button>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
}

ChoicesList.propTypes = {
  currentChoices: PropTypes.array.isRequired,
  isCustomQuestion: PropTypes.bool.isRequired,
  addChoice: PropTypes.func.isRequired,
  removeChoice: PropTypes.func.isRequired,
  reorderChoice: PropTypes.func.isRequired,
  allowDrag: PropTypes.bool.isRequired,
  allowChoiceDelete: PropTypes.bool.isRequired,
  showWarningMessage: PropTypes.func.isRequired,
};

export default ChoicesList;
