import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Form as FinalForm } from 'react-final-form';

import history from '../../common/history';

import { HOME } from '../../common/constants/RouteConstants';
import { TextInputField } from '../../common/components/form-elements';

function ResetPassword(props) {
  const { Store } = props;
  const [activationCodeExpired, setActivationCodeExpired] = useState(false);

  async function onSubmit(values) {
    const { email, password, passwordRepeated, resetCode } = values;

    try {
      const result = await Store.resetPassword(email, password, passwordRepeated, resetCode);

      if (result) {
        history.push(`/${HOME}`);
      } else {
        setActivationCodeExpired(true);
      }
    } catch (err) {
      // console.log(err)
    }
  }

  async function resendCodeHandler(event, email) {
    event.preventDefault();
    try {
      await Store.requestResetPassword(email);
    } catch (err) {
      // console.log(err)
    }
  }

  return (
    <React.Fragment>
      <h2 className='c-login__title'>Set your password</h2>
      <p className='c-login__description'>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Please enter a new password. Password's should be at least 8 character long, be a mix of upper and lower case
        letters and contain at least one number and one symbol (!@?;:)
      </p>
      <FinalForm
        onSubmit={onSubmit}
        render={({ values, handleSubmit }) => {
          return (
            <form>
              <TextInputField
                id='email'
                fieldName='email'
                classModifier='mb-3'
                icon='envelope'
                placeholder='Enter email'
                label='Your Email'
                isRequired={true}
              />
              <TextInputField
                id='resetCode'
                fieldName='resetCode'
                icon='pen-alt'
                placeholder='Password reset code from email'
                label='Password reset code'
                isRequired={true}
              />
              <TextInputField
                id='password'
                fieldName='password'
                icon='lock'
                placeholder='New password'
                customType='password'
                label='New Password'
                isRequired={true}
              />
              <TextInputField
                id='passwordRepeated'
                fieldName='passwordRepeated'
                icon='lock'
                placeholder='Repeat password'
                customType='password'
                label='Repeat password'
                isRequired={true}
              />

              <div className='c-field c-field--action l-form__item'>
                <div className='c-field__group'>
                  <button className='o-button o-button--primary' type='submit' onClick={handleSubmit}>
                    <span className='o-label'>Set new password</span>
                  </button>
                  {activationCodeExpired && (
                    <button
                      className='o-button o-button--alternate'
                      onClick={(event) => resendCodeHandler(event, values.email)}
                    >
                      <span className='o-label'>Resend activation code</span>
                    </button>
                  )}
                </div>
              </div>
            </form>
          );
        }}
      />
      {activationCodeExpired && (
        <div className='c-message is-warning-strong'>
          <h3 className='c-message__title'>Activation Code Expired</h3>
          <p className='c-message__description'>
            It appears that your activation code has expired. Activation code is valid for 24 hours. You can request a
            new activation code by clicking on &quot;Resend activation code&quot; button above.
          </p>
        </div>
      )}
    </React.Fragment>
  );
}

export default inject((root) => ({ Store: root.RootStore.authStore }))(observer(ResetPassword));
