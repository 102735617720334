import React from 'react';
import SvgIcon from '../SvgIcon';

export default function InlineButton(props) {
  const { onClick, buttonLabel, icon, classModifier = 'o-button--primary', 'data-testid': dataTestId } = props;
  return (
    <button className={`o-button ${classModifier}`} type='button' onClick={onClick} data-testid={dataTestId}>
      {icon && <SvgIcon icon={icon} dataTestId={`${dataTestId}-icon`} />}
      <span className='o-label' data-testid={`${dataTestId}-label`}>
        {buttonLabel}
      </span>
    </button>
  );
}
