import React, { useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
// eslint-disable-next-line import/no-cycle
import { DemographicReportContext } from './DemographicsReport';
import QuestionFooter from '../QuestionFooter';
import QuestionHeader from '../QuestionHeader';
import PreviewUtils from '../../../../common/PreviewUtils';
import agent from '../../../../common/agent/agent';
import DemographicChoice from './DemographicChoice';
import DEMOGRAPHIC_ENUMS from '../../../../shared/engage/engage-demographics-enums';

const DEMOGRAPHIC_CUSTOM_TYPE = DEMOGRAPHIC_ENUMS().DemographicsTypesEnums.DEMOGRAPHIC_CUSTOM_TYPE;
function DemographicPreview(props) {
  const { DemographicReportStore, ToastrStore, GeneralStore, question, fromDate, toDate, compareActive } = props;
  const { questionId, choices, countAnswered, countOverallAnswered, questionType, multiChoicesOfferedBound } = question;

  const pollReportContext = useContext(DemographicReportContext);
  const containerReference = useRef();

  // console.log('QUESTIONN', countOverallAnswered);

  const tag = questionType !== DEMOGRAPHIC_CUSTOM_TYPE ? 'TEMPLATE' : '';

  useEffect(() => {
    if (pollReportContext)
      pollReportContext.addReference({ id: questionId, containerReference: containerReference.current });
  }, [pollReportContext, questionId]);

  function handleDownload() {
    PreviewUtils.excelDownload(agent, DemographicReportStore.projectId, questionId, ToastrStore, fromDate, toDate);
  }

  return (
    <div ref={containerReference} className='c-poll-preview__item'>
      <div className='c-question c-question js-questionCompare'>
        <QuestionHeader tag={tag} question={question} isDemographic={true} />

        <div className='c-question__content'>
          <div className='c-result-group'>
            <ul className='c-result-group__items'>
              {choices
                .slice(0, multiChoicesOfferedBound)
                .sort((choiceA, choiceB) => choiceB.count - choiceA.count)
                .map((choice, idx) => (
                  <DemographicChoice
                    key={idx}
                    isExporting={GeneralStore.isSnapshotting}
                    buttonLabel='Show'
                    onButtonClick={handleDownload}
                    compareActive={compareActive}
                    choice={choice}
                    questionAnsweredCount={countAnswered}
                    questionOverallAnsweredCount={countOverallAnswered}
                  />
                ))}
            </ul>
          </div>
        </div>

        <QuestionFooter answered={countAnswered} showSkipped={false} showViewed={false} />
      </div>
    </div>
  );
}

DemographicPreview.propTypes = {
  ToastrStore: PropTypes.object.isRequired,
  DemographicReportStore: PropTypes.object.isRequired,
  GeneralStore: PropTypes.object.isRequired,
  question: PropTypes.shape({
    questionId: PropTypes.string.isRequired,
    countAnswered: PropTypes.number,
    countOverallAnswered: PropTypes.number,
    choices: PropTypes.array,
    questionType: PropTypes.string.isRequired,
  }).isRequired,
  fromDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  toDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default inject((root) => ({
  ToastrStore: root.RootStore.toastrStore,
  DemographicReportStore: root.RootStore.demographicsReportStore,
  GeneralStore: root.RootStore.generalStore,
}))(observer(DemographicPreview));
