import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { arrayMoveImmutable } from 'array-move';

import { HELP } from 'shared/engage/engage-help-enums';
import QuestionPicker from '../polls/pollEdit/QuestionPicker';
import ActionBar from '../../../common/components/actionBar/ActionBar';
import PreviewModal from '../../../common/components/previewModal/PreviewModal';
import DemographicLibraryModal from './demographicLibraryModal/DemographicLibraryModal';
import QuestionsList from './QuestionsList';

function DemographicsFn(props) {
  const { Store, ConfigStore, ProjectStore, PollsListStore, HelpStore, match } = props;
  const [isPickerVisible, setPickerVisible] = useState(false);
  const [isModalLibraryVisible, setModalLibraryVisible] = useState(false);
  const [isModalPreviewVisible, setModalPreviewVisible] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [allowDrag, setAllowDrag] = useState(true);
  const [pollId, setPollId] = useState();

  const clientEnvironment = ConfigStore.config.clientPath;
  const language = ProjectStore.language;
  const projectId = match.params.id;
  const demographicId = Store.demographic ? Store.demographic._id : null;
  const slug = ProjectStore.project ? ProjectStore.project.slug : '';

  useEffect(() => {
    Promise.all([Store.load(projectId), PollsListStore.load(projectId)]).then((result) => {
      setQuestions([...result[0].questions]);
      setPollId(result[1].length ? result[1][0]._id : '');
    });
  }, [Store, PollsListStore, ProjectStore.project, projectId]);

  useEffect(() => {
    if (Store.demographic) setQuestions([...Store.demographic.questions]);
  }, [Store.demographic]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setAllowDrag(false);
    const newOrder = arrayMoveImmutable(questions, oldIndex, newIndex);
    Store.reorderQuestions(projectId, newOrder).then(() => {
      setAllowDrag(true);
    });
  };

  const openPollPreview = () => {
    setModalPreviewVisible(true);
    HelpStore.closeHelp();
  };

  function getIframeSrc() {
    const src = `${clientEnvironment}/${slug}/surveys/${pollId}?isPreview=true&language=${language}${
      demographicId ? `&question=${demographicId}` : ''
    }`;

    return src;
  }

  function addQuestion(questionType) {
    setQuestions([...questions, { questionType: questionType.toLowerCase() }]);
    Store.createQuestion(projectId, { questionType: questionType.toLowerCase() }).then((result) => {
      if (!result) setQuestions([...questions]);
    });
  }

  return (
    <div className='l-main'>
      <ActionBar
        addNew={null}
        label='Demographics'
        hasButton={false}
        helpKey={HELP.PROJECT.EDIT_PROJECT.DEMOGRAPHICS.PAGE}
        isTextOnlyHelp={true}
      />
      <p>
        Demographic questions have been carefully prepared to be inclusive for all walks of society. You can override
        each question and modify choices, or create a completely new question, but in doing so, you will lose the option
        to benchmark demographics against other projects.
      </p>
      <QuestionsList
        useDragHandle
        onSortEnd={onSortEnd}
        openPollPreview={openPollPreview}
        projectId={projectId}
        allowDrag={allowDrag}
      />
      {!isPickerVisible && (
        <div className='c-field c-field--action l-form__item'>
          <div className='c-field__group'>
            <React.Fragment>
              <button className='o-button o-button--primary' type='button' onClick={() => setPickerVisible(true)}>
                <span className='o-label'>Add</span>
              </button>
              {/* Hide until demographic library is built
              <button
                className='o-button o-button--alternate'
                type='button'
                onClick={() => setModalLibraryVisible(true)}
              >
                <span className='o-label'>Add from library</span>
              </button> */}
            </React.Fragment>
          </div>
        </div>
      )}
      {isPickerVisible && (
        <QuestionPicker
          createQuestion={addQuestion}
          setPickerVisible={setPickerVisible}
          isDemographic
          alreadyPicked={questions}
        />
      )}
      {isModalPreviewVisible && (
        <PreviewModal closeModal={() => setModalPreviewVisible(false)} iframeSrc={getIframeSrc()} />
      )}
      {isModalLibraryVisible && (
        <DemographicLibraryModal
          closeModal={() => setModalLibraryVisible(false)}
          projectId={match.params.id}
          demographicId={match.params.demographicId}
        />
      )}
    </div>
  );
}

DemographicsFn.propTypes = {
  Store: PropTypes.object.isRequired,
  ConfigStore: PropTypes.object.isRequired,
  ProjectStore: PropTypes.object.isRequired,
  PollsListStore: PropTypes.object.isRequired,
  HelpStore: PropTypes.object.isRequired,
};

const Demographics = inject((root) => ({
  Store: root.RootStore.demographicsStore,
  ConfigStore: root.RootStore.configStore,
  ProjectStore: root.RootStore.projectStore,
  PollsListStore: root.RootStore.pollsListStore,
  HelpStore: root.RootStore.helpStore,
}))(observer(DemographicsFn));

export default Demographics;
