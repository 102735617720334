import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MDEditor from '@uiw/react-md-editor';
import Tooltip from '../../tooltip/Tooltip';
import FieldDescription from '../fieldDescription/FieldDescription';
import { MARKDOWN_MID, EDITOR_EXTRA_COMMANDS } from './MarkdownConstants';

function SimpleMarkdownInputField(props) {
  const {
    disabled,
    fieldClass,
    actionTooltip,
    fieldDescription,
    id,
    input,
    label,
    maxLength,
    meta = {},
    placeholder,
    tooltipInLabel,
    editorConfig = MARKDOWN_MID,
    extraCommands = EDITOR_EXTRA_COMMANDS,
  } = props;

  const [isTouched, setIsTouched] = useState(false);

  function getContainerClass(metadata) {
    const errorClass = metadata.error && isTouched ? 'is-error' : '';
    return `c-field l-form__item ${fieldClass} ${errorClass}`;
  }

  function onSelectHandler(event) {
    const textarea = event.target;
    const selection = textarea.value.substring(textarea.selectionStart, textarea.selectionEnd);

    if (textarea.selectionStart === textarea.selectionEnd) return;

    if (selection.charAt(0) === ' ') {
      textarea.selectionStart += 1;
    }

    if (selection.charAt(selection.length - 1) === ' ') {
      textarea.selectionEnd -= 1;
    }
  }

  function changeHandler(newValue) {
    setIsTouched(true);
    input.onChange(newValue);
  }
  return (
    <div className={getContainerClass(meta)}>
      <label className='c-field__label' htmlFor={id}>
        {`${label} `}
        {tooltipInLabel && <Tooltip {...tooltipInLabel} />}
      </label>
      <div className='c-field__group'>
        <div className='o-textarea o-textarea--rich-text'>
          <MDEditor
            value={input.value}
            onChange={changeHandler}
            onSelect={onSelectHandler}
            commands={editorConfig.editorConfig}
            textareaProps={{ id, placeholder }}
            extraCommands={extraCommands.editorConfig}
            disabled={disabled}
            prefixCls='w-md-custom-editor'
            toolbarheight={40}
            preview='edit'
          />
        </div>

        <FieldDescription
          meta={{ error: meta.error, touched: isTouched }}
          fieldDescription={fieldDescription}
          actionTooltip={actionTooltip}
        />

        <span className='c-field__count' title='Characters left'>
          {maxLength ? maxLength - input.value.length : ''}
        </span>
      </div>
    </div>
  );
}

SimpleMarkdownInputField.propTypes = {
  disabled: PropTypes.bool,
  fieldClass: PropTypes.string,
  actionTooltip: PropTypes.object,
  fieldDescription: PropTypes.string,
  id: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  tooltipInLabel: PropTypes.object,
  editorConfig: PropTypes.object,
  extraCommands: PropTypes.object,
};

export default SimpleMarkdownInputField;
