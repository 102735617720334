import { action, observable, computed, makeObservable } from 'mobx';

import AgentPoll from '../common/agent';

const Agent = AgentPoll.ConfigAgent;

export default class ConfigStore {
  _loading = false;

  _initialized = false;

  _error = null;

  _config = {};

  constructor(rootStore) {
    makeObservable(this, {
      _loading: observable,
      _initialized: observable,
      _error: observable,
      _config: observable,

      isLoading: computed,
      isInitialized: computed,
      error: computed,

      config: computed,
    });

    this.rootStore = rootStore;
  }

  get isLoading() {
    return this._loading;
  }

  get isInitialized() {
    return this._initialized;
  }

  get error() {
    return this._error;
  }

  get config() {
    return this._config;
  }

  /**
   * Loads config file from server
   */
  loadConfig = () => {
    this._loading = true;
    this._error = null;
    return Agent.getConfig()
      .then(action((res) => (this._config = res.data)))
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error('Error loading config data', null, err);
      })
      .finally(action(() => (this.loading = false)));
  };
}
