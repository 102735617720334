/**
 * IMPORTANT this is GLOBAL STATE
 * DO NOT ADD variables without team wide consultations
 */

// import { observable, computed, decorate, action } from 'mobx';
import { observable, computed, makeObservable } from 'mobx';

export default class GeneralStore {
  // This is system wide UI status when we have to replace how we render
  // both images and SVGs.
  // Not happy with this, but seems less bad solution.
  // Global state seems like a better alternative tha passing the state
  // down all components hierarchy to every image and SVG
  // Less damage than props or Context
  _isSnapshotting = false;

  _isSnapshottingSingleQuestion = false;

  _isTranslationInstructionVisible = false;

  constructor(rootStore) {
    makeObservable(this, {
      _isSnapshotting: observable,
      isSnapshotting: computed,
      _isSnapshottingSingleQuestion: observable,
      isSnapshottingSingleQuestion: computed,
      _isTranslationInstructionVisible: observable,
      isTranslationInstructionVisible: computed,
    });

    this.rootStore = rootStore;
  }

  get isSnapshotting() {
    return this._isSnapshotting;
  }

  set isSnapshotting(val) {
    this._isSnapshotting = val;
  }

  get isSnapshottingSingleQuestion() {
    return this._isSnapshottingSingleQuestion;
  }

  set isSnapshottingSingleQuestion(val) {
    this._isSnapshottingSingleQuestion = val;
  }

  get isTranslationInstructionVisible() {
    return this._isTranslationInstructionVisible;
  }

  set isTranslationInstructionVisible(val) {
    this._isTranslationInstructionVisible = val;
  }
}
