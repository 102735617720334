module.exports = function () {
  let _ = require('lodash');

  const Themes = {
    BUILDING_AND_DESIGN: 'Building and Design',
    COMMUNITY_AND_BELONGING: 'Community and Belonging',
    CRIME_AND_SAFETY: 'Crime and Safety',
    DATA_CAPTURE: 'Data capture',
    ENVIRONMENTAL_ISSUES: 'Environmental Issues',
    HEALTH_AND_WELLBEING: 'Health and Wellbeing',
    HERITAGE_AND_IDENTITY: 'Heritage and Identity',
    HOUSING: 'Housing',
    INFRASTRUCTURE: 'Infrastructure',
    PUBLIC_SPACES: 'Public Spaces',
    TRANSPORT: 'Transport',
    WEALTH_AND_ECONOMY: 'Wealth and Economy',
  };

  function themeList() {
    return _.map(Object.keys(Themes), (key) => {
      return Themes[key];
    });
  }

  return {
    Themes: Themes,
    themeList: themeList,
  };
};
