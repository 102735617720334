export const SAMPLE_URL = 'https://www.example.com';

export const URL_TYPES = {
  GOOGLE_MAP_URL: 'GOOGLE_MAP_URL',
};

export function getCrossAppLink(url) {
  return process.env.NODE_ENV === 'development' ? `http://localhost:9000${url}` : url;
}

export function validateUrl(string, type = '') {
  if (!string) return undefined;

  let url;
  try {
    url = new URL(string);
  } catch (error) {
    return 'Not a valid URL';
  }

  if (url.protocol !== 'http:' && url.protocol !== 'https:') return 'Not a valid URL';

  switch (type) {
    case URL_TYPES.GOOGLE_MAP_URL:
      if (!string.includes('https://www.google.com/maps/')) return 'Not a valid Google map URL';
      break;
    default:
      break;
  }
}

export function validateYoutubeUrl(str) {
  // eslint-disable-next-line prefer-regex-literals
  const regexp = new RegExp(
    `^((?:https?:)?\\/\\/)?((?:www|m)\\.)?((?:youtube\\.com|youtu.be))(\\/(?:[\\w\\-]+\\?v=|embed\\/|v\\/)?)([\\w\\-]+)(\\S+)?$`,
  );

  if (!str) return 'Required';

  if (regexp.test(str)) {
    return undefined;
  }
  return 'Not a valid Youtube URL';
}

export function getVideoThumbnail(video) {
  let videoCode = video;

  // case I have full URL extract code only
  if (video && video.indexOf('https://www.youtube.com/watch?v=') >= 0) {
    videoCode = video.substring('https://www.youtube.com/watch?v='.length);

    const timestampIndex = videoCode.indexOf('&t=');
    if (timestampIndex > 0) videoCode = videoCode.slice(0, timestampIndex);
  } else if (video && video.indexOf('https://youtu.be/') >= 0) {
    videoCode = video.substring('https://youtu.be/'.length);
  }

  const thumbnailUrl = `https://img.youtube.com/vi/${videoCode}/maxresdefault.jpg`;

  // Loads smaller sized image that includes top/bottom black space areas
  // const thumbnailUrl = `https://img.youtube.com/vi/${videoCode}/hqdefault.jpg`;

  return thumbnailUrl;
}
