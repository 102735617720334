import Agent from './agent';
import ImageUtils from './ImageUtils';
import { getVideoThumbnail } from './UrlUtils';

export function isDefaultLanguage() {
  const res = Agent.getLanguage() === Agent.DEFAULT_LANGUAGE;

  return res;
}

export function getFormFieldClass(defaultText, defaultResource, baseClass) {
  const hasDefaultValue = defaultText || defaultResource;
  if (!hasDefaultValue) return baseClass;

  return !isDefaultLanguage() && hasDefaultValue ? `${baseClass} c-field--contains-translation` : baseClass;
}

export function getFormFieldTranslationTooltip(defaultText, defaultResource, imageFormat) {
  if (isDefaultLanguage() || (!defaultText && !defaultResource)) return null;

  let imageSrcs;

  if (defaultResource && imageFormat) {
    // Default resource can contain one or multiple media items (images and videos)
    if (Array.isArray(defaultResource)) {
      imageSrcs = defaultResource.map((res) => {
        let imgSrc;
        if (res.mediaType === 'video') {
          imgSrc = getVideoThumbnail(res.url);
        } else {
          imgSrc = ImageUtils.imageUrl(res.url, imageFormat.imageType);
        }
        return imgSrc;
      });
    } else {
      imageSrcs = ImageUtils.imageUrl(defaultResource, imageFormat.imageType);
    }
  }

  return {
    img: imageSrcs,
    modifier: 'o-tooltip--top-center o-tooltip--assisted-translation',
    icon: 'globe',
    description: defaultText,
    displaysOriginalValue: true,
  };
}
