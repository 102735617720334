import { observable, makeObservable } from 'mobx';
import Agent from '../common/agent';

export default class StaticContentStore {
  loading = false;

  initialized = false;

  error = null;

  constructor(rootStore, projectStore) {
    makeObservable(this, {
      initialized: observable,
      loading: observable,
      error: observable,
    });

    this.rootStore = rootStore;
    this.projectStore = projectStore;
  }

  /**
   * Retrieve basic project info GET /project/:id
   * // ****** IMPORTANT ******
   * replace is here because react-final-form creates objects from dot notation
   * which then messes up the keys that the server expects
   * @param id
   * @returns {Promise<boolean>}
   */
  getTextById = (id) => {
    this.loading = true;
    this.error = null;
    return Agent.Projects.getById(id)
      .then((data) => {
        const init = {};
        const staticText = data.data.runtimeOptions.staticTextsReplacements;
        if (!staticText) return init;
        // eslint-disable-next-line no-return-assign
        staticText.map((value) => (init[value.key.replace(/\./g, '-')] = value.value));
        return init;
      })
      .catch((err) => {
        this.error = err;
        this.rootStore.toastrStore.error('Error loading project', null, err);
      })
      .finally(() => {
        this.loading = false;
      });
  };

  /**
   * // ****** IMPORTANT ******
   * replace is here because react-final-form creates objects from dot notation
   * which then messes up the keys that the server expects
   */
  updateStaticText = (id, body) => {
    this.loading = true;
    this.error = null;
    const data = [];
    Object.getOwnPropertyNames(body).forEach((property) => {
      // const key = property.replace('-', '.');
      const key = property.replace(/-/g, '.');
      data.push({ key, value: body[property] });
    });
    const staticData = {
      staticTextsReplacements: data,
    };
    return Agent.Projects.updateStaticText(id, staticData)
      .then(() => {
        this.getTextById(id);
        this.rootStore.toastrStore.success('Project updated', null);
      })
      .catch((err) => {
        this.error = err;
        this.rootStore.toastrStore.error('Error saving project', null, err);
      })
      .finally(() => {
        this.loading = false;
      });
  };
}
