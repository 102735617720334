import React from 'react';
import { inject, observer } from 'mobx-react';
import { Form } from 'react-final-form';

import EngageTextareaInputField from '../../../../common/components/form-elements/textareaInputField/TextareaInputField';

function FeedbackForm(props) {
  const { item, Store } = props;

  const onSubmit = (values) => {
    // console.log('REPLY', values);
    Store.submitReply(item, values);
  };

  return (
    <div className='c-feedback__form'>
      <Form
        onSubmit={onSubmit}
        initialValues={{ response: '' }}
        render={({ values, form, handleSubmit, submitting, pristine }) => {
          return (
            <form className='c-fields l-form'>
              <div className='c-field l-form__item c-field--label-hidden'>
                <label className='c-field__label' htmlFor={`input-response-${item._id}`}>
                  Message
                </label>
                <EngageTextareaInputField
                  id={`input-response-${item._id}`}
                  placeholder='Write your response...'
                  label=''
                  maxLength=''
                  fieldDescription=''
                  fieldName='response'
                  isRequired={true}
                  fieldClass='l-form__item'
                />
              </div>
              <div className='c-field c-field--action l-form__item'>
                <div className='c-field__group'>
                  <button className='o-button o-button--primary' onClick={handleSubmit}>
                    <span className='o-label'>Reply</span>
                  </button>
                </div>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
}

export default inject((root) => ({ Store: root.RootStore.feedbackStore }))(observer(FeedbackForm));
