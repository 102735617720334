import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../../SvgIcon';

export default function RadioInput(props) {
  const { choices, classModifier, disabled, name, input, onSelectionChange, isStrongDescription = false } = props;

  const { onChange } = input;

  function getDescription(value) {
    if (isStrongDescription) return <strong>{value}</strong>;

    return <small>{value}</small>;
  }

  const mergedOnChange = (e) => {
    if (onSelectionChange) onSelectionChange(e.currentTarget.value);

    setTimeout(() => {
      onChange(e);
    }, 200);
  };

  const newInput = { ...input, onChange: mergedOnChange };

  return (
    <React.Fragment>
      {choices.map((choice) => (
        <div className={`o-radio ${classModifier}`} key={choice.id}>
          <input
            {...newInput}
            type='radio'
            id={choice.id}
            name={name}
            checked={input.value === choice.id}
            disabled={disabled ? 'disabled' : ''}
            value={choice.id}
          />
          <label htmlFor={choice.id}>
            {choice.icon && <SvgIcon icon={choice.icon} />}
            {`${choice.text} `}
            {choice.description && getDescription(choice.description)}
          </label>
        </div>
      ))}
    </React.Fragment>
  );
}

RadioInput.propTypes = {
  choices: PropTypes.array,
  classModifier: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  input: PropTypes.object,
  onSelectionChange: PropTypes.func,
  isStrongDescription: PropTypes.bool,
};
