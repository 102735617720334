import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';

import SwitchableButtonBar from '../../../common/components/switchableButtonBar/SwitchableButtonBar';
import SwitchableHeader from '../../../common/components/switchableHeader/SwitchableHeader';
import EngageTextInputField from '../../../common/components/form-elements/textInputField/TextInputField';
import SvgIcon from '../../../common/components/SvgIcon';
import enums from '../../../shared/engage/engage-flow-enums';
import displayConfirmationPrompt from '../../../common/PromptUtil';
import { getFormFieldClass, getFormFieldTranslationTooltip } from '../../../common/TranslationUtils';

const ENUMS = enums().EngageEndOfPollFlowButtonType;

function ActionItemFn(props) {
  const { item, Store, ToastrStore, override, isDisabled, infoMessage, isNew } = props;

  const tooltip = {
    modifier: 'o-tooltip--bottom-center',
    label: 'Adding relative links',
    icon: 'question-circle',
    description:
      'If actions lead to other pages within the app you can use relative links. E.g. "/surveys" or "/timeline"',
  };

  const toggleOpened = () => {
    Store.toggleOpened(item);
  };

  const remove = (event) => {
    event.preventDefault();

    const res = displayConfirmationPrompt(null, null, false, ToastrStore);

    if (res === 1) Store.removeEndOfPollFlowById(item.data._id);
  };

  const onSubmit = (values) => {
    if (isDisabled) {
      override();
    } else {
      Store.setEndOfPollButtons(values);
    }
  };

  return (
    <div className={`c-switchable ${item.formState.isOpened ? 'is-opened' : 'is-closed'}`}>
      <SwitchableHeader
        id={item.data._id}
        label='Button'
        name={item.data.endOfPollButtonTitle || 'Enter button title'}
        icon='external-link'
        toggleOpened={toggleOpened}
        ignoreDragHandle={true}
      />
      <div className='c-switchable__content'>
        <Form
          onSubmit={onSubmit}
          initialValues={item.data}
          render={({ values, form, handleSubmit, submitting, pristine }) => {
            return (
              <form className='c-fields l-form'>
                {infoMessage && (
                  <div className='l-form__item'>
                    <div className='c-message c-message--icon is-information'>
                      <SvgIcon icon='info-circle' />
                      <p className='c-message__description'>{infoMessage}</p>
                    </div>
                  </div>
                )}
                <EngageTextInputField
                  id={`input-action-title-${item.data._id}`}
                  placeholder='Enter button title'
                  label='Title'
                  maxLength='25'
                  fieldDescription=''
                  fieldName='endOfPollButtonTitle'
                  isRequired={true}
                  disabled={isDisabled}
                  fieldClass={getFormFieldClass(item.defaultData.endOfPollButtonTitle, null, 'l-form__item--m')}
                  tooltipInLabel={getFormFieldTranslationTooltip(item.defaultData.endOfPollButtonTitle)}
                />
                <EngageTextInputField
                  id={`input-action-subtitle-${item.data._id}`}
                  placeholder='Enter button subtitle'
                  label='Subtitle'
                  maxLength='35'
                  fieldDescription=''
                  fieldName='endOfPollButtonSubTitle'
                  isRequired={true}
                  disabled={isDisabled}
                  fieldClass={getFormFieldClass(item.defaultData.endOfPollButtonSubTitle, null, 'l-form__item--m')}
                  tooltipInLabel={getFormFieldTranslationTooltip(item.defaultData.endOfPollButtonSubTitle)}
                />
                <EngageTextInputField
                  id={`input-action-url-${item.data._id}`}
                  placeholder='Enter button URL'
                  label='Action URL'
                  fieldDescription=''
                  fieldName='endOfPollButtonActionUrl'
                  isRequired={true}
                  disabled={isDisabled}
                  tooltip={tooltip}
                  fieldClass={getFormFieldClass(item.defaultData.endOfPollButtonActionUrl, null, 'l-form__item--m')}
                  tooltipInLabel={getFormFieldTranslationTooltip(item.defaultData.endOfPollButtonActionUrl)}
                />
                <div className='c-field l-form__item l-form__item--m'>
                  <label className='c-field__label'>Behaviour</label>
                  <div className='c-field__group'>
                    <div className='o-checkbox'>
                      <Field
                        name='openInNewWindow'
                        id={`behaviour-${item.data._id}`}
                        component='input'
                        type='checkbox'
                        disabled={isDisabled}
                      />
                      <label htmlFor={`behaviour-${item.data._id}`}>
                        Open in new window
                        <SvgIcon icon='check' />
                      </label>
                    </div>
                  </div>
                </div>
                <SwitchableButtonBar
                  updateAction={handleSubmit}
                  labelUpdate={isDisabled ? 'Override' : 'Update'}
                  labelRemove={item.data.buttonTypeIdentifier === ENUMS.DEFAULT_BUTTON ? 'Reset' : 'Delete'}
                  removeAction={item.data._id ? remove : undefined}
                  isNew={isNew}
                />
              </form>
            );
          }}
        />
      </div>
    </div>
  );
}

const ActionItem = inject((root) => ({ Store: root.RootStore.actionStore, ToastrStore: root.RootStore.toastrStore }))(
  observer(ActionItemFn),
);

export default ActionItem;

ActionItemFn.propTypes = {
  item: PropTypes.object.isRequired,
  Store: PropTypes.object.isRequired,
  ToastrStore: PropTypes.object.isRequired,
  override: PropTypes.func,
  isDisabled: PropTypes.bool,
  infoMessage: PropTypes.string,
  isNew: PropTypes.bool,
};
