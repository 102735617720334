import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import SvgIcon from '../components/SvgIcon';
import {
  OVERVIEW,
  EDIT_PROJECT,
  REPORTS,
  REPORTS_DASHBOARD,
  PUBLISHING,
  PROJECT,
  INFORMATION,
  PROFILE,
  PROJECTS,
  SETTINGS,
} from '../constants/RouteConstants';
import ImageUtils, { IMAGE_TYPE_LOGO } from '../ImageUtils';
import PreviewModal from '../components/previewModal/PreviewModal';
import TranslationInstructions from '../components/translationInstructions/TranslationInstructions';
import { VIEW } from '../ability';
import { Can } from '../Can';

function ProjectMasthead(props) {
  const { route, id, projectName, projectLogo, ConfigStore, ProjectStore, GeneralStore, HelpStore } = props;
  const [isModalPreviewVisible, setModalPreviewVisible] = useState(false);

  const clientEnvironment = ConfigStore.config.clientPath;
  const language = ProjectStore.language;

  // console.log(route, id);

  function getSelectedClass(item) {
    return item === route ? 'c-nav-masthead__item is-active' : 'c-nav-masthead__item';
  }

  // eslint-disable-next-line no-shadow
  function getLink(route) {
    return `/${PROJECT}/${id}/${route}`;
  }

  function getIframeScr() {
    const src = `${clientEnvironment}/${ProjectStore.project.slug}?isPreview=true&language=${language}`;
    return src;
  }

  function openPreviewModal(e) {
    // console.log('Open popup');
    e.preventDefault();
    setModalPreviewVisible(true);
    HelpStore.closeHelp();
  }

  return (
    <Fragment>
      <div className='c-nav-masthead'>
        <div className='c-nav-masthead__group'>
          <Link to={`/${PROFILE}/${PROJECTS}`} className='c-nav-masthead__back'>
            <SvgIcon icon='angle-left' />
            <span className='o-label'>All projects</span>
          </Link>
          <h2 className='c-nav-masthead__title'>
            {projectLogo && (
              <img className='c-nav-masthead__avatar' src={ImageUtils.imageUrl(projectLogo, IMAGE_TYPE_LOGO)} alt='' />
            )}
            <span className='o-label'>{projectName}</span>
          </h2>
        </div>

        <div className='c-nav-masthead__content'>
          <ul className='c-nav-masthead__items'>
            <Can I={VIEW} a={OVERVIEW}>
              <li className={getSelectedClass(OVERVIEW)}>
                <Link className='c-nav-masthead__link' to={getLink(OVERVIEW)}>
                  Dashboard
                </Link>
              </li>
            </Can>
            <Can I={VIEW} a={EDIT_PROJECT}>
              <li className={getSelectedClass(EDIT_PROJECT)}>
                <Link className='c-nav-masthead__link' to={getLink(`${EDIT_PROJECT}/${INFORMATION}`)}>
                  Edit project
                </Link>
              </li>
            </Can>
            <Can I={VIEW} a={REPORTS}>
              <li className={getSelectedClass(REPORTS)}>
                <Link className='c-nav-masthead__link' to={getLink(`${REPORTS}/${REPORTS_DASHBOARD}`)}>
                  Reporting
                </Link>
              </li>
            </Can>
            <Can I={VIEW} a={SETTINGS}>
              <li className={getSelectedClass(SETTINGS)}>
                <Link className='c-nav-masthead__link' to={getLink(`${SETTINGS}/${PUBLISHING}`)}>
                  Settings
                </Link>
              </li>
            </Can>
            {/* PREVIEW ICON */}
            <li className='c-nav-masthead__item c-nav-masthead__item--end' onClick={openPreviewModal}>
              <button className='c-nav-masthead__link'>
                Preview
                <SvgIcon icon='mobile' />
              </button>
            </li>
          </ul>
        </div>
        {isModalPreviewVisible && (
          <PreviewModal closeModal={() => setModalPreviewVisible(false)} iframeSrc={getIframeScr()} />
        )}
      </div>
      {GeneralStore.isTranslationInstructionVisible && (
        <TranslationInstructions canClose={true} classModifier='c-message--icon c-message--highlight  is-dismissable' />
      )}
    </Fragment>
  );
}

export default inject((root) => ({
  ConfigStore: root.RootStore.configStore,
  ProjectStore: root.RootStore.projectStore,
  GeneralStore: root.RootStore.generalStore,
  HelpStore: root.RootStore.helpStore,
}))(observer(ProjectMasthead));
