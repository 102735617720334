import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import ImageUtils, { ACCESS_IMAGES_DIRECTLY_ON_S3 } from '../../ImageUtils';

/**
 * Due to CORS problems, when making a snapshot I have to access the image via JS (CORS)
 * So I configured CORS on S3 and
 * 1) for normal image I access via CloudFront (standard image)
 * 2) when making a snapshot I access the image directly from S3 (no CF caching)
 * I might have enabled CORS headers passing through CF, but it reduces caching efficiency and more importantly
 * it might impact image resizing redirects that already have a complicated flow of redirects
 *
 */
function SnapshotableImage(props) {
  const { resource, format, Store, isPng = false, classModifier, onLoad } = props;
  const isSnapshotting = Store && Store.isSnapshotting;

  function onLoadHandler() {
    if (onLoad) onLoad();
  }

  return (
    <React.Fragment>
      {/* eslint-disable-next-line no-nested-ternary */}
      {isSnapshotting ? (
        // I need to set crossOrigin='anonymous' which forces CORS headers to be sent
        // so that image is in cache with the correct headers. Otherwise image is retrieved from cache with no headers
        isPng ? (
          <img
            src={ImageUtils.pngImageUrl(resource, format, ACCESS_IMAGES_DIRECTLY_ON_S3)}
            alt=''
            crossOrigin='anonymous'
            className={classModifier}
            onLoad={onLoadHandler}
          />
        ) : (
          <img
            src={ImageUtils.imageUrl(resource, format, ACCESS_IMAGES_DIRECTLY_ON_S3)}
            alt=''
            crossOrigin='anonymous'
            className={classModifier}
            onLoad={onLoadHandler}
          />
        )
      ) : isPng ? (
        <img src={ImageUtils.pngImageUrl(resource, format)} alt='' className={classModifier} onLoad={onLoadHandler} />
      ) : (
        <img src={ImageUtils.imageUrl(resource, format)} alt='' className={classModifier} onLoad={onLoadHandler} />
      )}
    </React.Fragment>
  );
}

SnapshotableImage.propTypes = {
  resource: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
  Store: PropTypes.object,
  isPng: PropTypes.bool,
  classModifier: PropTypes.string,
  onLoad: PropTypes.func,
};

export default inject((root) => ({
  Store: root.RootStore ? root.RootStore.generalStore : {},
}))(observer(SnapshotableImage));
