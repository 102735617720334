// General app constants
/* eslint-disable global-require */
/**
 * Constants moved to shared
 * Here we're only remapping for use in populo to avoid signature change
 */
// export const ENGAGE_LANGUAGES = {
//   en: {languageCode:'en', languageTitle:'English', script:'latin', rightToLeft: false},
//   ar: {languageCode:'ar', languageTitle:'Arabic', script:'arabic', rightToLeft: true},
//   bn: {languageCode:'bn', languageTitle:'Bengali', script:'bengali', rightToLeft: false},
//   de: {languageCode:'de', languageTitle:'German', script:'latin', rightToLeft: false},
//   es: {languageCode:'es', languageTitle:'Spanish', script:'latin', rightToLeft: false},
//   // cy: {languageCode:'cy', languageTitle:'Welsh', script:'latin', rightToLeft: false},
//   gu: {languageCode:'gu', languageTitle:'Gujarati', script:'gujarati', rightToLeft: false},
//   pa: {languageCode:'pa', languageTitle:'Punjabi', script:'arabic', rightToLeft: true},
//   pl: {languageCode:'pl', languageTitle:'Polish', script:'latin', rightToLeft: false},
//   ur: {languageCode:'ur', languageTitle:'Urdu', script:'arabic', rightToLeft: true},
// };

export const AWS_PATH = 'https://s3.amazonaws.com/cdn.built-id.com/static/';

export const LanguageDataCompletness = require('../../shared/engage').LanguageTools().LanguageDataCompletness;
