import React from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import SlimUploader from './SlimUploader';
import Tooltip from '../../tooltip/Tooltip';

/**
 * Wrapper around Uploader component
 * Wraps uploader in form field
 * @param props
 * @returns {*}
 * @constructor
 */
function FileUpload(props) {
  const { classModifier, label, tooltipInLabel } = props;

  return (
    <div className={`c-field l-form__item ${classModifier}`}>
      <label className='c-field__label' htmlFor='input-project-logo'>
        {label}
        {tooltipInLabel && <Tooltip {...tooltipInLabel} />}
      </label>
      <div className='c-field__group'>
        <SlimUploader {...props} />
      </div>
    </div>
  );
}

FileUpload.propTypes = {
  classModifier: PropTypes.string,
  label: PropTypes.string,
  tooltipInLabel: PropTypes.object,
};

export default inject((root) => ({ ToastrStore: root.RootStore.toastrStore }))(observer(FileUpload));
