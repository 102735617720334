import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../../SvgIcon';
import SnapshotableImage from '../../snapshotableImage/ScreenshotableImage';
import { IMAGE_FORMATS } from '../../form-elements/fileUpload/ImageFormatEnums';

function AnnotateImageCluster(props) {
  const { cluster, openClusterId, setOpenClusterId, scale } = props;
  const { id, count, left, top, reactions } = cluster;

  return (
    <div
      className={`c-annotate-image__cluster ${openClusterId === id ? 'is-opened' : ''}`}
      style={{ left: `${left}%`, top: `${top}%`, transform: `scale(${1 / scale})` }}
      key={id}
    >
      <div className='c-annotate-image__cluster-image'>
        {reactions.slice(0, 3).map((reaction, index) => (
          <SnapshotableImage
            key={index}
            resource={reaction.resource}
            format={IMAGE_FORMATS.REACTION.imageType}
            isPng={true}
            alt={reaction.label}
          />
        ))}
      </div>
      {reactions.length === 1 ? (
        <div className='c-annotate-image__cluster-value'>{count}</div>
      ) : (
        <Fragment>
          <button
            className='c-annotate-image__cluster-value'
            onClick={() => setOpenClusterId(id === openClusterId ? '' : id)}
          >
            {count}
            <SvgIcon icon='angle-down' />
          </button>
          <div className='c-annotate-image__cluster-details'>
            {reactions.map((reaction, index) => (
              <span key={index} className='c-annotate-image__reaction'>
                <SnapshotableImage
                  resource={reaction.resource}
                  format={IMAGE_FORMATS.REACTION.imageType}
                  isPng={true}
                  alt={reaction.label}
                />
                <span className='c-annotate-image__value'>{reaction.count}</span>
              </span>
            ))}
          </div>
        </Fragment>
      )}
    </div>
  );
}

AnnotateImageCluster.propTypes = {
  count: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  scale: PropTypes.number.isRequired,
  reactions: PropTypes.array,
};

export default AnnotateImageCluster;
