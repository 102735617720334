import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import SimpleReactionPicker from './SimpleReactionPicker';

function ReactionPicker(props) {
  const { fieldName, label } = props;

  return (
    <Fragment>
      <Field name={fieldName} component='input' type='select' style={{ display: 'none' }} />
      <div className='c-field l-form__item l-form__item--m'>
        {label && (
          <label className='c-field__label' htmlFor='select--rating--type'>
            {label}
          </label>
        )}
        <div className='c-field__group'>
          <SimpleReactionPicker wrapperClass='c-filter-set--icons' {...props} />
        </div>
      </div>
    </Fragment>
  );
}

ReactionPicker.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string,
  reactions: PropTypes.array.isRequired,
  selectedReaction: PropTypes.object,
  onReactionChange: PropTypes.func.isRequired,
  hasShowAllOption: PropTypes.bool,
  wrapperClass: PropTypes.string,
};

export default ReactionPicker;
