import React, { useState, useEffect } from 'react';

import MediaItem from './MediaItem';
import CaptionItem from './CaptionItem';

export default function MediaCaptionItem(props) {
  const { type, mediaId, mediaUrl, removeMedia, caption, updateCaption, defaultCaption } = props;
  const [showCaption, setShowCaption] = useState(caption);

  useEffect(() => {
    setShowCaption(caption);
  }, [caption]);

  const deleteCaption = () => {
    setShowCaption(false);
    updateCaption('');
  };

  return (
    <div
      className={`c-upload-caption ${showCaption ? 'c-upload-caption--added' : ''}`}
      data-testid={`${mediaId}-media-caption-container`}
    >
      <MediaItem
        type={type}
        mediaId={mediaId}
        mediaUrl={mediaUrl}
        removeMedia={removeMedia}
        showButton={!showCaption}
        setShowCaption={setShowCaption}
        linkToVideo={true}
      />
      {showCaption && (
        <CaptionItem
          imageId={mediaId}
          imageCaption={caption}
          defaultCaption={defaultCaption}
          updateCaption={updateCaption}
          deleteCaption={deleteCaption}
        />
      )}
    </div>
  );
}
