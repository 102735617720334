import {
  FEEDBACK_EXCEL,
  OUTPUT_PDF,
  POLL_DATA_OPTIONS,
  PROJECT_OVERALL_EXCEL,
  PROJECT_OVERALL_PDF,
  RAW_DATA_ZIP,
} from './ReportEnums';

function getSelectedPolls(formValues, polls) {
  let pollIdList = [];
  if (formValues.pollsData && formValues.pollsDataOption === POLL_DATA_OPTIONS.CUSTOM) {
    pollIdList = polls.map((poll) => poll.id).filter((pollId) => formValues[`pollsDataPoll-${pollId}`]);
  }
  return pollIdList;
}

function getSegmentationFilter(formValues) {
  return formValues.segmentationFilter && formValues.segmentationFilterQuestionChoice
    ? [formValues.segmentationFilterQuestionChoice]
    : [];
}

function prepareSegmentationQuestions(questions) {
  const preparedSegmentationQuestions = questions.map((item) => {
    const label = item.questionText ? item.questionText : 'No question title';
    return [item._id, label];
  });

  return preparedSegmentationQuestions;
}

function prepareSegmentationQuestionChoices(questions, questionId) {
  let selectedSegmentationQuestion;

  if (questionId) {
    selectedSegmentationQuestion = questions.find((item) => item._id === questionId);
  } else {
    selectedSegmentationQuestion = questions[0] ? questions[0] : null;
  }

  if (!selectedSegmentationQuestion) return [];

  const selectedSegmentationQuestionChoices = selectedSegmentationQuestion.choices.map((item) => {
    const label = item.choice ? item.choice : 'Missing choice label';
    return [item._id, label];
  });

  // Remove "unused" choices (we always have 10 choices but we should display only number of choices set in question config)
  const trimmedSelectedSegmentationQuestionChoices = selectedSegmentationQuestionChoices.slice(
    0,
    selectedSegmentationQuestion.multiChoicesOfferedBound,
  );

  return trimmedSelectedSegmentationQuestionChoices;
}

function createPayload(formValues, outputType, fromDate, toDate, polls, secondaryOutputType) {
  const reportType = outputType === OUTPUT_PDF ? PROJECT_OVERALL_PDF : formValues.dataGroup;
  const query = {
    reportType,
    outputType: secondaryOutputType || outputType,
    filter: {
      timestampFrom: fromDate,
      timestampTo: toDate,
      segmentation: [],
      demographics: [],
    },
    allPolls: !(formValues.pollsData && formValues.pollsDataOption === POLL_DATA_OPTIONS.CUSTOM),
    pollList: [],
    includeSections: {},
  };

  if (outputType === OUTPUT_PDF) {
    query.reportName = formValues.reportName || '';
    query.pdfOverviewType = formValues.overviewData ? formValues.overviewDataOptions : '';
    query.includeSections.overview = formValues.overviewData;
  }

  if (outputType === OUTPUT_PDF || formValues.dataGroup === PROJECT_OVERALL_EXCEL) {
    query.includeSections.polls = formValues.pollsData;
    query.includeSections.demographics = formValues.demographicsData;
    query.filter.segmentation = getSegmentationFilter(formValues);
    query.pollList = getSelectedPolls(formValues, polls);
  }

  if (formValues.dataGroup === FEEDBACK_EXCEL || formValues.dataGroup === RAW_DATA_ZIP) query.allPolls = true;

  return query;
}

export default {
  getSelectedPolls,
  getSegmentationFilter,
  prepareSegmentationQuestions,
  prepareSegmentationQuestionChoices,
  createPayload,
};
