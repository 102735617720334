import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Router, Route, Switch } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import SidebarHelp from 'common/components/sidebarHelp/SidebarHelp';
import history from './common/history';
import {
  AUTH,
  STAFF,
  PROFILE,
  PROJECT,
  ERROR_403,
  HOME,
  ERROR_NO_SUBSCRIPTION,
} from './common/constants/RouteConstants';

import Header from './common/header/Header';
import Footer from './common/footer/Footer';
import Auth from './containers/auth/Auth';
import Staff from './containers/staff/Staff';
import Profile from './containers/profile/Profile';
import Project from './containers/project/Project';
import Error403 from './common/error/Error403';
import NoSubscription from './common/error/NoSubscription';
import HomeRedirect from './containers/HomeRedirect';

function App(props) {
  const { AuthStore, ConfigStore, HelpStore } = props;

  useEffect(() => {
    ConfigStore.loadConfig();
    HelpStore.getHelp();

    // do not init user on AUTH pages (login/resetPwd/forgotPwd)
    if (history.location.pathname.indexOf(`/${AUTH}/`) >= 0) {
      return;
    }

    AuthStore.init();
  }, [AuthStore, ConfigStore, HelpStore]);

  return (
    <Router history={history}>
      {!AuthStore.isInitialized ? (
        <div>
          <Header />
          <Route path={`/${AUTH}`} component={Auth} />
        </div>
      ) : (
        <div>
          <Header />
          <Switch>
            <Route path={`/${AUTH}`} component={Auth} />
            <Route path={`/${STAFF}`} component={Staff} />
            <Route path={`/${PROFILE}`} component={Profile} />
            <Route path={`/${PROJECT}/:id`} component={Project} />
            <Route path={`/${ERROR_403}`} component={Error403} />
            <Route path={`/${ERROR_NO_SUBSCRIPTION}`} component={NoSubscription} />
            <Route path={`/${HOME}`} component={HomeRedirect} />
            <Route component={HomeRedirect} />
          </Switch>
          <Footer />
        </div>
      )}
    </Router>
  );
}

App.propTypes = {
  AuthStore: PropTypes.object.isRequired,
  ConfigStore: PropTypes.object.isRequired,
  HelpStore: PropTypes.object.isRequired,
};

export default inject((root) => ({
  AuthStore: root.RootStore.authStore,
  ConfigStore: root.RootStore.configStore,
  HelpStore: root.RootStore.helpStore,
}))(observer(App));
