import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { PROFILE, PROJECTS } from 'common/constants/RouteConstants';
import history from '../../common/history';

function ClientListItem(props) {
  const { client, AuthStore } = props;

  function clientSelectHandler(event) {
    event.preventDefault();
    AuthStore.setClientId(client._id);
    history.push(`/${PROFILE}/${PROJECTS}`);
  }

  return (
    <li className='c-list-clients__item'>
      <a className='c-list-clients__link' href='populo-projects.html' onClick={clientSelectHandler}>
        <span className='c-list-clients__name'>{client.profileName}</span>
        <span className='c-list-clients__meta'>
          {client.projectCount === 1 ? '1 project' : `${client.projectCount} projects`}{' '}
        </span>
      </a>
    </li>
  );
}

ClientListItem.propTypes = {
  client: PropTypes.object.isRequired,
  AuthStore: PropTypes.object.isRequired,
};

export default inject((root) => ({
  AuthStore: root.RootStore.authStore,
}))(observer(ClientListItem));
