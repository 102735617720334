import React from 'react';
import { Field } from 'react-final-form';

import SimpleCheckboxInput from '../../../../common/components/form-elements/checkboxInputField/SimpleCheckboxInput';

export default function DemographicsListItem(props) {
  const { demographicsDataValue } = props;
  return (
    <li className={`c-list-settings__item ${demographicsDataValue ? 'c-list-settings__item--highlight' : ''}`}>
      <Field
        component={SimpleCheckboxInput}
        name='demographicsData'
        classModifier='c-list-settings__name'
        label='Demographics'
        id='demographicsData'
        type='checkbox'
        defaultValue={true}
      />
    </li>
  );
}
