import React from 'react';
import { Field } from 'react-final-form';
import { SortableElement } from 'react-sortable-hoc';
import PropTypes from 'prop-types';
import SimpleDraggableTextInputFieldWithAction from './SimpleDraggableTextInputFieldWithAction';

function DraggableTextInputFieldWithAction(props) {
  const { fieldName, isRequired, validator } = props;

  function validateRequired(value) {
    const val = value && value.trim();
    return !val && isRequired ? 'Required' : undefined;
  }

  return (
    <Field
      component={SimpleDraggableTextInputFieldWithAction}
      name={fieldName}
      validate={validator || validateRequired}
      {...props}
    />
  );
}

DraggableTextInputFieldWithAction.propTypes = {
  fieldName: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  validator: PropTypes.func,
};

/**
 * Sortable item wrapper
 */
const SortableItem = SortableElement((props) => <DraggableTextInputFieldWithAction {...props} />);

export default SortableItem;
