import React from 'react';
import PropTypes from 'prop-types';
import { VIEW } from 'common/ability';
import { Can } from 'common/Can';
import {
  GEOLOCATION,
  INFORMATION,
  TEAM,
  TIMELINE,
  INFLUENCED_DECISIONS,
  CHARITIES,
  FAQ,
} from 'common/constants/RouteConstants';
import UpdateDetailsRow from './UpdateDetailsRow';

function InformationUpdateDetails(props) {
  const { infoValidation, sectionLabel } = props;
  const { validation, info, geolocation, timeline, team, influencedDecisions, charities, faq } = infoValidation;

  return (
    <table>
      <thead>
        <tr>
          <th>{sectionLabel}</th>
          {validation.languages.map((item, index) => (
            <th key={`${item.language}-${index}`}>
              <abbr title={item.languageTitleEN}>{item.language}</abbr>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <Can I={VIEW} a={INFORMATION}>
          <UpdateDetailsRow key='info' rowLabel='Information' languageData={info.info.thisTreeLanguageData} />
        </Can>
        <Can I={VIEW} a={GEOLOCATION}>
          <UpdateDetailsRow
            key='location'
            rowLabel='Location'
            languageData={geolocation.validation.thisTreeLanguageData}
          />
        </Can>
        <Can I={VIEW} a={TIMELINE}>
          <UpdateDetailsRow
            key='timeline'
            rowLabel='Timeline'
            languageData={timeline.validation.thisTreeLanguageData}
          />
        </Can>
        <Can I={VIEW} a={TEAM}>
          <UpdateDetailsRow key='team' rowLabel='Team' languageData={team.validation.thisTreeLanguageData} />
        </Can>
        <Can I={VIEW} a={INFLUENCED_DECISIONS}>
          <UpdateDetailsRow
            key='influencedDecisions'
            rowLabel='Influenced decisions'
            languageData={influencedDecisions.validation.thisTreeLanguageData}
          />
        </Can>
        <Can I={VIEW} a={CHARITIES}>
          <UpdateDetailsRow
            key='charities'
            rowLabel='Charities'
            languageData={charities.validation.thisTreeLanguageData}
          />
        </Can>
        <Can I={VIEW} a={FAQ}>
          <UpdateDetailsRow key='faq' rowLabel='FAQ' languageData={faq.validation.thisTreeLanguageData} />
        </Can>
      </tbody>
    </table>
  );
}

InformationUpdateDetails.propTypes = {
  infoValidation: PropTypes.shape({
    info: PropTypes.shape({
      info: PropTypes.shape({
        thisTreeLanguageData: PropTypes.array.isRequired,
      }).isRequired,
    }).isRequired,
    geolocation: PropTypes.shape({
      validation: PropTypes.shape({
        thisTreeLanguageData: PropTypes.array.isRequired,
      }).isRequired,
    }).isRequired,
    timeline: PropTypes.shape({
      validation: PropTypes.shape({
        thisTreeLanguageData: PropTypes.array.isRequired,
      }).isRequired,
    }).isRequired,
    team: PropTypes.shape({
      validation: PropTypes.shape({
        thisTreeLanguageData: PropTypes.array.isRequired,
      }).isRequired,
    }).isRequired,
    influencedDecisions: PropTypes.shape({
      validation: PropTypes.shape({
        thisTreeLanguageData: PropTypes.array.isRequired,
      }).isRequired,
    }).isRequired,
    charities: PropTypes.shape({
      validation: PropTypes.shape({
        thisTreeLanguageData: PropTypes.array.isRequired,
      }).isRequired,
    }).isRequired,
    faq: PropTypes.shape({
      validation: PropTypes.shape({
        thisTreeLanguageData: PropTypes.array.isRequired,
      }).isRequired,
    }).isRequired,
    validation: PropTypes.shape({
      languages: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
  sectionLabel: PropTypes.string.isRequired,
};

export default InformationUpdateDetails;
