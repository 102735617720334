import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { HELP } from 'shared/engage/engage-help-enums';
import { OUTPUT_PDF as REPORT_FORMAT_PDF, OUTPUT_EXCEL as REPORT_FORMAT_SPREADSHEET, OUTPUT_PDF } from './ReportEnums';
import ActionBar from '../../../../common/components/actionBar/ActionBar';
import GenerateFileTypeChoice from './GenerateFileTypeChoice';
import GeneratePdf from './GeneratePdf';
import GenerateSpreadsheet from './GenerateSpreadsheet';
import SimpleRangePickerInputField from '../../../../common/components/form-elements/datePicker/SimpleRangePickerInputField';

function GenerateReport(props) {
  const { ReportsStore, PollsListStore, match, SegmentationStore } = props;
  const projectId = match.params.id;

  const [buttonLoading, setButtonLoading] = useState(false);

  const [segmentationQuestions, setSegmentationQuestions] = useState();
  const [polls, setPolls] = useState([]);

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [outputType, setOutputType] = useState(OUTPUT_PDF);
  const [pdfReportFormValues, setPdfReportFormValues] = useState();
  const [spreadsheetReportFormValues, setSpreadsheetReportFormValues] = useState();

  useEffect(() => {
    if (!ReportsStore || !PollsListStore || !SegmentationStore || !match.params.id) return;

    Promise.all([PollsListStore.load(match.params.id), SegmentationStore.load(match.params.id)]).then((result) => {
      if (!result) return;

      const storePolls = result[0];
      const storeSegmentationQuestions = result[1];
      const preparedPolls = storePolls.map((item) => {
        return { id: item._id, name: item.name };
      });
      setPolls(preparedPolls);

      setSegmentationQuestions(storeSegmentationQuestions);
    });
  }, [ReportsStore, PollsListStore, SegmentationStore, match.params.id]);

  function reportTypeChangeHandler(type) {
    setOutputType(type);
  }

  function rangeChangeHandler(rangeFrom, rangeTo) {
    setFromDate(rangeFrom);
    setToDate(rangeTo);
  }

  function setPdfFormValuesHandler(pdfFormValues) {
    setPdfReportFormValues(pdfFormValues);
  }

  function setSpreadsheetFormValuesHandler(spreadsheetFormValues) {
    setSpreadsheetReportFormValues(spreadsheetFormValues);
  }

  function generateReportHandler(payload) {
    setButtonLoading(true);
    setTimeout(() => setButtonLoading(false), 2500);
    ReportsStore.generateReport(projectId, payload);
  }

  return (
    <div className='l-main'>
      <ActionBar
        addNew={null}
        label='Generate'
        hasButton={false}
        helpKey={HELP.PROJECT.REPORTING.GENERATE.REPORT_TYPE}
        isTextOnlyHelp={true}
      />
      <GenerateFileTypeChoice value={outputType} onSelectionChange={reportTypeChangeHandler} />
      <SimpleRangePickerInputField
        startDate={fromDate}
        endDate={toDate}
        onRangeChange={rangeChangeHandler}
        showPredefinedRanges={true}
      />

      <React.Fragment>
        {outputType === REPORT_FORMAT_PDF && (
          <GeneratePdf
            pdfFormData={pdfReportFormValues}
            segmentationQuestions={segmentationQuestions}
            polls={polls}
            onSetPdfReportFormValues={setPdfFormValuesHandler}
            onGenerateReport={generateReportHandler}
            buttonLoading={buttonLoading}
            fromDate={fromDate}
            toDate={toDate}
          />
        )}
        {outputType === REPORT_FORMAT_SPREADSHEET && (
          <GenerateSpreadsheet
            spreadsheetFormData={spreadsheetReportFormValues}
            segmentationQuestions={segmentationQuestions}
            polls={polls}
            onSetSpreadsheetReportFormValues={setSpreadsheetFormValuesHandler}
            onGenerateReport={generateReportHandler}
            buttonLoading={buttonLoading}
            fromDate={fromDate}
            toDate={toDate}
          />
        )}
      </React.Fragment>
    </div>
  );
}

GenerateReport.propTypes = {
  ReportsStore: PropTypes.object.isRequired,
  PollsListStore: PropTypes.object.isRequired,
  SegmentationStore: PropTypes.object.isRequired,
};

export default inject((root) => ({
  ReportsStore: root.RootStore.generateReportStore,
  PollsListStore: root.RootStore.pollsListStore,
  SegmentationStore: root.RootStore.segmentationStore,
}))(observer(GenerateReport));
