import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { SortableContainer } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

import ActionBar from '../../../common/components/actionBar/ActionBar';
import InlineButton from '../../../common/components/inlineButton/InlineButton';
import TeamMember from './TeamMember';

/**
 * Member list container
 * ### IMPORTANT this one is sort container!!
 */
function TeamContainerFn(props) {
  const { TeamStore, allowDrag } = props;
  return (
    <div className='c-switchable-list c-switchable-list--polls'>
      {TeamStore.team.map((member, index) => (
        <TeamMember
          key={member.data._id}
          index={index}
          member={member}
          switchableModifier='c-switchable--reorder'
          disabled={!allowDrag}
        />
      ))}
    </div>
  );
}
const TeamContainer = inject((root) => ({ TeamStore: root.RootStore.teamStore }))(observer(TeamContainerFn));

/**
 * Team page container
 */
function TeamFn(props) {
  const { TeamStore } = props;
  const [allowDrag, setAllowDrag] = useState(true);

  const addNew = () => {
    TeamStore.create();
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setAllowDrag(false);
    const newOrder = arrayMoveImmutable(props.TeamStore.team, oldIndex, newIndex);
    TeamStore.reorder(newOrder).then(() => {
      setAllowDrag(true);
    });
  };

  return (
    <div className='l-main'>
      <ActionBar addNew={addNew} label='Team' hasButton={true} buttonLabel='Add' />
      <SortableList useDragHandle={true} onSortEnd={onSortEnd} allowDrag={allowDrag} />
      {TeamStore.team.length > 8 && <InlineButton onClick={addNew} buttonLabel='Add' />}
    </div>
  );
}
const Team = inject((root) => ({ TeamStore: root.RootStore.teamStore }))(observer(TeamFn));

/**
 * Sortable collection wrapper
 */
const SortableList = SortableContainer((props) => {
  return <TeamContainer {...props} />;
});

export default Team;
