import React from 'react';
import PropTypes from 'prop-types';

export default function SimpleRangeInputField(props) {
  const { id, input, label, fieldClass, min, max, step, classModifier, type } = props;

  return (
    <div className={`c-field l-form__item ${fieldClass}`} data-testid={`${id}-wrapper`}>
      <label className='c-field__label' htmlFor={id} data-testid={`${id}-label`}>
        {label}
      </label>
      <div className='c-field__group'>
        <div className={`o-input-range ${classModifier}`}>
          <input type={type} {...input} id={id} min={min} max={max} step={step} data-testid={`${id}-input`} />
          <span className='o-input-range__value' data-testid={`${id}-value-display`}>
            {input.value}
          </span>
        </div>
      </div>
    </div>
  );
}

SimpleRangeInputField.propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  fieldClass: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  step: PropTypes.string,
  classModifier: PropTypes.string,
  type: PropTypes.string,
};
