import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import EngageTextInputField from '../../../../../common/components/form-elements/textInputField/TextInputField';
import SwitchableButtonBar from '../../../../../common/components/switchableButtonBar/SwitchableButtonBar';
import { QUESTION_CHARACTER_LIMIT } from '../../../../../common/constants/QuestionConstants';
import EngageCheckboxInputField from '../../../../../common/components/form-elements/checkboxInputField/CheckboxInputField';
import EngageCheckboxGroupInputField from '../../../../../common/components/form-elements/checkboxGroupInputField/CheckboxGroupInputField';
import {
  prepareFeedbackQuestionConfigFormValues,
  prepareFeedbackQuestionPersonalInfoGroupData,
  prepareFeedbackQuestionSubmitConfig,
  FEEDBACK_QUESTION_PERSONAL_INFO_PREFIX,
  FEEDBACK_QUESTION_ALLOW_IMAGE_FIELD,
} from '../../PollUtils';
import { getFormFieldClass, getFormFieldTranslationTooltip } from '../../../../../common/TranslationUtils';
import ConditionalLogic from '../../../../../common/components/conditionalLogic/ConditionalLogic';
import ConditionalLogicUtil from '../../../../../common/ConditionalLogicUtil';
import LeadImage from '../../../../../common/components/form-elements/leadImage/LeadImage';
import { IMAGE_FORMATS } from '../../../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import { LEAD_IMAGE_TYPES } from '../../../../../shared/engage/engage-question-constants';

function SliderFeedback(props) {
  const labelArray = [
    "E.g. That's great to hear! Please tell us more about what you like so much!",
    "E.g. That's good to hear! Please tell us more about what you like.",
    'E.g. Thanks for letting us know. Please do give us some more feedback.',
    "E.g. That's a shame! Thanks for sharing your views. Give us some feedback.",
  ];

  const { question, openPollPreview, removeMedia, uploadMedia, uploadVideo, showConditionalLogic, Store } = props;

  const { _id, resource, defaultData } = question;

  const FEEDBACK_LABELS = [
    '😍 76% – 100% response',
    '😊 51% – 75% response',
    '🤨 26% – 50% response',
    '😠 0% – 25% response',
  ];

  const [choices, setChoices] = useState([]);
  const [formValues, setFormValues] = useState();
  const [rules, setRules] = useState([]);

  useEffect(() => {
    if (!question) return;

    const initialFormQuestionValues = {
      questionText: question.questionText,
      feedbackQuestion1: question.resourceLabels[0].label ? question.resourceLabels[0].label : '',
      feedbackQuestion2: question.resourceLabels[1].label ? question.resourceLabels[1].label : '',
      feedbackQuestion3: question.resourceLabels[2].label ? question.resourceLabels[2].label : '',
      feedbackQuestion4: question.resourceLabels[3].label ? question.resourceLabels[3].label : '',
      leadImageType: question.questionConfiguration?.leadImageType || LEAD_IMAGE_TYPES.STANDARD_SINGLE_IMAGE,
    };

    const configurationValues = prepareFeedbackQuestionConfigFormValues(question, initialFormQuestionValues);

    const prepChoices = prepareFeedbackQuestionPersonalInfoGroupData(question, configurationValues);

    setChoices(prepChoices);

    const initialState = ConditionalLogicUtil.setupFormInitialValues(question);
    setFormValues({ ...initialFormQuestionValues, ...initialState.initialFormConditionValues });
    setRules(initialState.initialRules);
  }, [question]);

  const onSubmit = (values) => {
    const updatedQuestionData = toJS(question);
    updatedQuestionData.questionText = values.questionText;
    updatedQuestionData.allowImages = values.allowImages;
    updatedQuestionData.resourceLabels[0].label = values.feedbackQuestion1;
    updatedQuestionData.resourceLabels[1].label = values.feedbackQuestion2;
    updatedQuestionData.resourceLabels[2].label = values.feedbackQuestion3;
    updatedQuestionData.resourceLabels[3].label = values.feedbackQuestion4;
    updatedQuestionData.questionConfiguration.leadImageType = values.leadImageType;

    prepareFeedbackQuestionSubmitConfig(updatedQuestionData, values);

    ConditionalLogicUtil.extractConditionalFormValues(question, updatedQuestionData, values, rules);

    props.updateQuestion(updatedQuestionData);
  };

  function getFeedbackLabel(label, index) {
    if (label.defaultData) return label.defaultData.label;

    return labelArray[index];
  }
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={formValues}
      render={({ values, form, handleSubmit, submitting, pristine }) => {
        return (
          <form className='c-fields l-form'>
            <EngageTextInputField
              id={`input-question-${question._id}`}
              placeholder='Enter question'
              label='Question'
              maxLength={QUESTION_CHARACTER_LIMIT}
              fieldDescription=''
              fieldName='questionText'
              isRequired={true}
              fieldClass={getFormFieldClass(question.defaultData.questionText, null, 'l-form__item')}
              tooltipInLabel={getFormFieldTranslationTooltip(question.defaultData.questionText)}
            />

            <div className='c-toggle-content'>
              <div className='c-toggle-content__container'>
                <ConditionalLogic
                  formValues={values}
                  setFormValues={setFormValues}
                  question={question}
                  questions={Store.poll.questions}
                  rules={rules}
                  setRules={setRules}
                  show={showConditionalLogic}
                />
                <div className='c-toggle-content__content' hidden={showConditionalLogic}>
                  <div className='l-form'>
                    <LeadImage
                      questionId={_id}
                      resource={resource}
                      defaultResource={defaultData?.resource}
                      store={Store}
                      label='Lead image'
                      uploadImage={uploadMedia}
                      uploadVideo={uploadVideo}
                      removeMedia={removeMedia}
                      fieldName='leadImageType'
                      leadImageType={values.leadImageType}
                      classModifier={getFormFieldClass(null, defaultData.resource)}
                      tooltipInLabel={getFormFieldTranslationTooltip(
                        null,
                        defaultData.resource,
                        IMAGE_FORMATS.FLUID_PANEL,
                      )}
                    />

                    <div className='c-field l-form__item'>
                      <div className='c-field__group'>
                        <p className='c-field__description'>
                          Users will be able to provide additional feedback in the next step. You can write custom
                          messages or use our examples that will be visible on the feedback form — based on their
                          response.
                        </p>
                      </div>
                    </div>

                    {question.resourceLabels.map((label, index) => (
                      <EngageTextInputField
                        key={`${question._id}${index}`}
                        index={index}
                        id={`input-label-${question._id}-${index}`}
                        placeholder={getFeedbackLabel(label, index)}
                        label={FEEDBACK_LABELS[index]}
                        maxLength='80'
                        fieldDescription=''
                        isRequired={true}
                        fieldName={`feedbackQuestion${index + 1}`}
                        fieldClass='l-form__item'
                      />
                    ))}

                    <EngageCheckboxInputField
                      id={`checkbox-allow-images-${question._id}`}
                      type='checkbox'
                      fieldName={FEEDBACK_QUESTION_ALLOW_IMAGE_FIELD}
                      fieldClass='l-form__item'
                      label='Image upload'
                      labelTooltip='Users will be able to attach an image with their feedback.'
                      isToggle={true}
                      checkedLabel='Enable images'
                      uncheckedLabel='Enable images'
                      hideLabel={false}
                    />
                    <EngageCheckboxGroupInputField
                      choices={choices}
                      label='Mandatory personal information'
                      fieldName={FEEDBACK_QUESTION_PERSONAL_INFO_PREFIX}
                      isToggle={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <SwitchableButtonBar
              updateAction={handleSubmit}
              removeAction={props.removeQuestion}
              isNew={null}
              previewAction={() => openPollPreview(question._id)}
            />
          </form>
        );
      }}
    />
  );
}

SliderFeedback.propTypes = {
  question: PropTypes.object.isRequired,
  openPollPreview: PropTypes.func.isRequired,
  uploadMedia: PropTypes.func.isRequired,
  uploadVideo: PropTypes.func.isRequired,
  removeMedia: PropTypes.func.isRequired,
  Store: PropTypes.object.isRequired,
  showConditionalLogic: PropTypes.bool.isRequired,
};

export default observer(SliderFeedback);
