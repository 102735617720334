import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Form } from 'react-final-form';

import SwitchableButtonBar from '../../../../../common/components/switchableButtonBar/SwitchableButtonBar';
import { IMAGE_FORMATS } from '../../../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import EngageMarkdownInputField, {
  MARKDOWN_MIN_PLUS,
} from '../../../../../common/components/form-elements/markdownInputField/MarkdownInputField';
import { getVideoThumbnail, validateYoutubeUrl } from '../../../../../common/UrlUtils';
import { getFormFieldClass, getFormFieldTranslationTooltip } from '../../../../../common/TranslationUtils';
import ImageUtils from '../../../../../common/ImageUtils';
import ConditionalLogic from '../../../../../common/components/conditionalLogic/ConditionalLogic';
import ConditionalLogicUtil from '../../../../../common/ConditionalLogicUtil';
import LeadImage from '../../../../../common/components/form-elements/leadImage/LeadImage';
import { LEAD_IMAGE_TYPES } from '../../../../../shared/engage/engage-question-constants';

function SingleImageFact(props) {
  const {
    question,
    updateQuestion,
    removeQuestion,
    uploadMedia,
    removeMedia,
    uploadVideo,
    openPollPreview,
    showConditionalLogic,
    Store,
  } = props;

  const { _id, resource, defaultData } = question;

  const [formValues, setFormValues] = useState({});
  const [rules, setRules] = useState([]);

  const mediaResource = resource?.length > 0 ? resource[0] : null;
  const imageId = mediaResource ? mediaResource._id : null;
  const defaultImage =
    // eslint-disable-next-line no-use-before-define
    question?.defaultData?.resource?.length > 0 ? extractDefaultImage(question.defaultData.resource[0].url) : null;

  useEffect(() => {
    if (!question) return;

    const initialFormQuestionValues = {
      questionText: question.questionText,
      leadImageType: question.questionConfiguration?.leadImageType || LEAD_IMAGE_TYPES.STANDARD_SINGLE_IMAGE,
    };

    const initialState = ConditionalLogicUtil.setupFormInitialValues(question, initialFormQuestionValues);
    setFormValues({ ...initialFormQuestionValues, ...initialState.initialFormConditionValues });
    setRules(initialState.initialRules);
  }, [question]);

  const onSubmit = (values) => {
    const updatedQuestionData = toJS(question);
    // Question content
    updatedQuestionData.questionText = values.questionText;
    updatedQuestionData.questionConfiguration.leadImageType = values.leadImageType;

    ConditionalLogicUtil.extractConditionalFormValues(question, updatedQuestionData, values, rules);

    updateQuestion(updatedQuestionData);
  };

  function extractDefaultImage(defaultResource) {
    let res;
    if (!validateYoutubeUrl(defaultResource)) {
      // If resource is Youtube video get preview image
      res = getVideoThumbnail(defaultResource);
    } else {
      res = ImageUtils.imageUrl(defaultResource, IMAGE_FORMATS.PANEL.imageType);
    }

    return res;
  }

  return (
    <Form
      onSubmit={onSubmit}
      keepDirtyOnReinitialize={true}
      initialValues={formValues}
      render={({ values, handleSubmit }) => {
        return (
          <form
            className='c-fields l-form'
            onSubmit={(event) => {
              event.preventDefault();
            }}
          >
            <EngageMarkdownInputField
              id={`input-question-${_id}`}
              placeholder='Keep description short'
              label='Fact'
              maxLength='200'
              fieldDescription=''
              fieldName='questionText'
              isRequired={true}
              editorConfig={MARKDOWN_MIN_PLUS}
              fieldClass={getFormFieldClass(defaultData.questionText, null, 'l-form__item')}
              tooltipInLabel={getFormFieldTranslationTooltip(defaultData.questionText)}
            />

            <div className='c-toggle-content'>
              <div className='c-toggle-content__container'>
                <ConditionalLogic
                  formValues={values}
                  setFormValues={setFormValues}
                  question={question}
                  questions={Store.poll.questions}
                  rules={rules}
                  setRules={setRules}
                  show={showConditionalLogic}
                  choiceFieldNames={[]}
                />
                <div className='c-toggle-content__content' hidden={showConditionalLogic}>
                  <LeadImage
                    questionId={_id}
                    resource={resource}
                    defaultResource={defaultData?.resource}
                    store={Store}
                    label='Fact media'
                    uploadImage={uploadMedia}
                    uploadVideo={uploadVideo}
                    removeMedia={removeMedia}
                    fieldName='leadImageType'
                    leadImageType={values.leadImageType}
                    classModifier={getFormFieldClass(null, defaultData.resource)}
                    tooltipInLabel={getFormFieldTranslationTooltip(
                      null,
                      defaultData.resource,
                      IMAGE_FORMATS.FLUID_PANEL,
                    )}
                  />
                </div>
              </div>
            </div>
            <SwitchableButtonBar
              updateAction={handleSubmit}
              removeAction={removeQuestion}
              isNew={!_id}
              previewAction={() => openPollPreview(_id)}
            />
          </form>
        );
      }}
    />
  );
}

SingleImageFact.propTypes = {
  question: PropTypes.object.isRequired,
  updateQuestion: PropTypes.func.isRequired,
  removeQuestion: PropTypes.func.isRequired,
  uploadMedia: PropTypes.func.isRequired,
  removeMedia: PropTypes.func.isRequired,
  uploadVideo: PropTypes.func.isRequired,
  openPollPreview: PropTypes.func.isRequired,
  showConditionalLogic: PropTypes.bool.isRequired,
  Store: PropTypes.object.isRequired,
};

export default observer(SingleImageFact);
