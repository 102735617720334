import agent from './agent';

const requests = agent.requests;
const ENDPOINT = '/user-administration';

const UsersAgent = {
  // we have a post here because we can have search criteria as complex object
  all: (clientId, filter = {}) => requests.post(`${ENDPOINT}/client/${clientId}/all-users`, filter),

  // User
  getById: (clientId, userId) => requests.get(`${ENDPOINT}/client/${clientId}/admin-user/${userId}`),
  create: (clientId, data) => requests.post(`${ENDPOINT}/client/${clientId}/create`, data),
  update: (clientId, userId, data) => requests.patch(`${ENDPOINT}/client/${clientId}/admin-user/${userId}`, data),
  remove: (clientId, userId) => requests.remove(`${ENDPOINT}/client/${clientId}/admin-user/${userId}`),

  // get roles that can be assigned to newly created users
  getRoles: (clientId) => requests.get(`${ENDPOINT}/client/${clientId}/permissions/roles`),
};

export default UsersAgent;
