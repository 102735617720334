import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

export const DEFAULT_LABEL = 'How to use?';
function HelpActionButton(props) {
  const { HelpStore, helpKey, icon = 'question-circle-filled', isTextOnly = false, autoClose = true } = props;

  const helpItem = HelpStore?.help?.find((item) => item.key === helpKey) || {};

  function clickHandler(event) {
    const rect = event.target.getBoundingClientRect() || 0;
    const absoluteTop = rect.top + window.scrollY;

    HelpStore.positionTop = absoluteTop;

    if (HelpStore.currentHelpKey === helpKey && autoClose) {
      HelpStore.closeHelp();
      return;
    }

    HelpStore.currentHelpKey = helpKey;
  }

  return HelpStore.isContextualHelpActive && helpKey && helpItem?.active ? (
    <div
      className='c-help'
      onClick={clickHandler}
      role='button'
      tabIndex='0'
      data-testid={`help-action-wrapper-${helpKey}`}
    >
      <div className={`c-help__action ${isTextOnly ? 'is-text-only' : ''} is-active`}>
        <span className='o-label'>{helpItem.label || DEFAULT_LABEL}</span>
        <SvgIcon icon={icon} />
      </div>
    </div>
  ) : null;
}

HelpActionButton.propTypes = {
  HelpStore: PropTypes.object.isRequired,
  helpKey: PropTypes.string,
  icon: PropTypes.string,
  isTextOnly: PropTypes.bool,
  autoClose: PropTypes.bool,
};

export default inject((root) => ({ HelpStore: root.RootStore.helpStore }))(observer(HelpActionButton));
