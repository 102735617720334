import React from 'react';
import { inject, observer } from 'mobx-react';
// import { toJS } from 'mobx';
import { Form as FinalForm } from 'react-final-form';
import { Link } from 'react-router-dom';

import history from '../../common/history';

import { AUTH, AUTH_LOGIN, AUTH_RESET_PASSWORD } from '../../common/constants/RouteConstants';
import { TextInputField } from '../../common/components/form-elements';

function ForgotPassword(props) {
  const { Store } = props;

  const onSubmit = async (values) => {
    try {
      await Store.requestResetPassword(values.email);
      history.push(`/${AUTH}/${AUTH_RESET_PASSWORD}`);
    } catch (err) {
      // console.log(err)
    }
  };

  return (
    <React.Fragment>
      <h2 className='c-login__title'>Forgot password</h2>
      <p className='c-login__description'>Enter email to which we can send a password reset link.</p>
      <FinalForm
        onSubmit={onSubmit}
        render={({ handleSubmit }) => {
          return (
            <form>
              <TextInputField
                id='email'
                fieldName='email'
                classModifier='mb-3'
                icon='envelope'
                placeholder='Enter email'
                label='Your Email'
                isRequired={true}
              />

              <div className='c-field c-field--action l-form__item'>
                <div className='c-field__group'>
                  <button className='o-button o-button--primary' type='submit' onClick={handleSubmit}>
                    <span className='o-label'>Reset password</span>
                  </button>
                  <p className='c-field__description'>
                    <Link to={`/${AUTH}/${AUTH_LOGIN}`}>Back to login</Link>
                  </p>
                </div>
              </div>
            </form>
          );
        }}
      />
    </React.Fragment>
  );
}

export default inject((root) => ({ Store: root.RootStore.authStore }))(observer(ForgotPassword));
