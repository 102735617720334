import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { SortableContainer } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

import ActionBar from '../../../common/components/actionBar/ActionBar';
import InlineButton from '../../../common/components/inlineButton/InlineButton';
import FaqItem from './FaqItem';

/**
 * Member list container
 * ### IMPORTANT this one is sort container!!
 */
function FaqContainerFn(props) {
  const { Store, allowDrag } = props;
  return (
    <div className='c-switchable-list c-switchable-list--polls'>
      {Store.faq.map((item, index) => (
        <FaqItem
          key={item.data._id}
          index={index}
          item={item}
          switchableModifier='c-switchable--reorder'
          disabled={!allowDrag}
        />
      ))}
    </div>
  );
}
const FaqContainer = inject((root) => ({ Store: root.RootStore.faqStore }))(observer(FaqContainerFn));

/**
 * Team page container
 */
function FaqFn(props) {
  const { Store } = props;

  const [allowDrag, setAllowDrag] = useState(true);

  const addNew = () => {
    Store.create();
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setAllowDrag(false);
    const newOrder = arrayMoveImmutable(props.Store.faq, oldIndex, newIndex);
    Store.reorder(newOrder).then(() => {
      setAllowDrag(true);
    });
  };

  return (
    <div className='l-main'>
      <ActionBar addNew={addNew} label='FAQ' hasButton={true} buttonLabel='Add' />
      <SortableList useDragHandle={true} onSortEnd={onSortEnd} allowDrag={allowDrag} />
      {Store.faq.length > 8 && <InlineButton onClick={addNew} buttonLabel='Add' />}
    </div>
  );
}
const Faq = inject((root) => ({ Store: root.RootStore.faqStore }))(observer(FaqFn));

/**
 * Sortable collection wrapper
 */
const SortableList = SortableContainer((props) => {
  return <FaqContainer {...props} />;
});

export default Faq;
