// On server we have this enums
export const EngagePhaseSize = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

export const EngagePhaseType = {
  IN_PROGRESS: 'in progress',
  DISRUPTIVE: 'disruptive',
  FUTURE: 'future',
  DONE: 'done',
};

export const STAGE_TYPES = [
  [EngagePhaseType.DONE, 'Done', 'check-circle'],
  [EngagePhaseType.IN_PROGRESS, 'In Progress', 'dot-circle'],
  [EngagePhaseType.FUTURE, 'Future', 'circle'],
  [EngagePhaseType.DISRUPTIVE, 'Disruptive', 'triangle'],
];

export const STAGE_SIZE = [
  [EngagePhaseSize.SMALL, 'Small'],
  [EngagePhaseSize.MEDIUM, 'Medium'],
  [EngagePhaseSize.LARGE, 'Large'],
];
