import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import CharityItem from './CharityItem';

/**
 * Sortable item wrapper
 */
const SortableCharityItem = SortableElement((props) => {
  return <CharityItem {...props} />;
});

export default SortableCharityItem;
