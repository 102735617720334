import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import QuestionTemplateAction from './QuestionTemplateAction';
import SvgIcon from '../../../../../common/components/SvgIcon';

import { IMAGE_FORMATS } from '../../../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import PreviewUtils from '../../../../../common/PreviewUtils';
import ImageUtils from '../../../../../common/ImageUtils';
import QuestionTemplateHeader from './QuestionTemplateHeader';
import MediaItem from '../../../../../common/components/mediaItem/MediaItem';

function SliderFeedbackPreview(props) {
  const { question } = props;

  const [uncategorisedPercent, setUncategorisedPercent] = useState(0);

  const { choices, questionFeedbackCount, sentimentMixed, sentimentNegative, sentimentNeutral, sentimentPositive } =
    question;

  const mediaResource = question?.resource?.length > 0 ? question.resource[0] : null;

  const imageId = mediaResource?._id || null;
  const imageUrl = mediaResource?.url || null;
  const imageType = mediaResource?.mediaType || null;

  useEffect(() => {
    if (question)
      setUncategorisedPercent(
        100 -
          Math.round(
            ((question.sentimentPositive +
              question.sentimentNegative +
              question.sentimentNeutral +
              question.sentimentMixed) /
              question.questionFeedbackCount) *
              100,
          ),
      );
  }, [question]);

  const inputValue = (choices[0].range[1] - choices[0].range[0]) / 2;

  function perc(item, total) {
    return total > 0 ? Math.round((item / total) * 100) : 0;
  }

  return (
    <div className='c-library__item'>
      <div className='c-question c-question--slider c-question--library'>
        <QuestionTemplateHeader question={question} />
        <div className='c-question__content'>
          <div className='c-question-slider'>
            <div className='c-question-slider__lead'>
              {mediaResource && (
                <MediaItem
                  type={imageType}
                  mediaId={imageId}
                  mediaUrl={imageUrl}
                  imageClassModifier='l-form__item c-upload--fill'
                  videoClassModifier='c-upload--fill'
                  linkToVideo={true}
                />
              )}
            </div>
            <div className='o-input-range o-input-range--emoji '>
              <input
                type='range'
                id='input-poll'
                min={choices[0].range[0]}
                max={choices[0].range[1]}
                value={inputValue}
                disabled=''
                readOnly
              />
              <span className='o-input-range__value' role='img' aria-label={PreviewUtils.getEmoji(inputValue)[1]}>
                {PreviewUtils.getEmoji(inputValue)[0]}
              </span>
            </div>
            <div className='c-sentiment-stats'>
              <h3 className='c-sentiment-stats__label'>
                {'Sentiments '}
                <span className='o-tooltip o-tooltip--bottom-center'>
                  {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                  <span className='o-tooltip__action' tabIndex='0'>
                    <SvgIcon icon='info-circle' />
                  </span>
                  <span className='o-tooltip__description' role='tooltip'>
                    {`${questionFeedbackCount} feedback submissions`}
                  </span>
                </span>
              </h3>
              <ul className='c-sentiment-stats__items'>
                <li className='c-sentiment-stats__item'>
                  <SvgIcon icon='grin' />
                  <strong>{` ${perc(sentimentPositive, questionFeedbackCount)}% `}</strong>
                  positive
                </li>
                <li className='c-sentiment-stats__item'>
                  <SvgIcon icon='meh' />
                  <strong>{` ${perc(sentimentMixed, questionFeedbackCount)}% `}</strong>
                  mixed
                </li>
                <li className='c-sentiment-stats__item'>
                  <SvgIcon icon='frown' />
                  <strong>{` ${perc(sentimentNegative, questionFeedbackCount)}% `}</strong>
                  negative
                </li>
                <li className='c-sentiment-stats__item'>
                  <SvgIcon icon='meh-blank' />
                  <strong>{` ${perc(sentimentNeutral, questionFeedbackCount)}% `}</strong>
                  neutral
                </li>
                {uncategorisedPercent > 0 && (
                  <li>
                    <SvgIcon icon='archive' />
                    <strong>{` ${uncategorisedPercent}% uncategorised `}</strong>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <QuestionTemplateAction question={question} />
    </div>
  );
}

SliderFeedbackPreview.propTypes = {
  question: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    questionText: PropTypes.string.isRequired,
    choices: PropTypes.array.isRequired,
    questionRespondentsCount: PropTypes.number,
    questionFeedbackCount: PropTypes.number,
    approveAboveMiddlePercent: PropTypes.number,
    resource: PropTypes.array.isRequired,
    feedbackPercent: PropTypes.number,
    sentimentPositive: PropTypes.number,
    sentimentNegative: PropTypes.number,
    sentimentNeutral: PropTypes.number,
    sentimentMixed: PropTypes.number,
  }).isRequired,
};

export default SliderFeedbackPreview;
