import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import { HELP } from 'shared/engage/engage-help-enums';
import EngageTextInputField from '../../../../../common/components/form-elements/textInputField/TextInputField';
import SwitchableButtonBar from '../../../../../common/components/switchableButtonBar/SwitchableButtonBar';
import { QUESTION_CHARACTER_LIMIT } from '../../../../../common/constants/QuestionConstants';
import { getFormFieldClass, getFormFieldTranslationTooltip } from '../../../../../common/TranslationUtils';
import ConditionalLogicUtil from '../../../../../common/ConditionalLogicUtil';
import ConditionalLogic from '../../../../../common/components/conditionalLogic/ConditionalLogic';

function ReorderQuestion(props) {
  const { question, updateQuestion, removeQuestion, openPollPreview, showConditionalLogic, Store, CHOICE_LABELS } =
    props;

  const [formValues, setFormValues] = useState({});
  const [rules, setRules] = useState([]);

  useEffect(() => {
    if (!question) return;

    const initialFormQuestionValues = {
      questionText: question.questionText,
    };

    question.choices.forEach((choice, index) => {
      const choiceFieldName = ConditionalLogicUtil.QUESTION_CHOICE_FIELDS[index].fieldName;
      if (choice.choice) initialFormQuestionValues[choiceFieldName] = choice.choice || '';
    });

    const initialState = ConditionalLogicUtil.setupFormInitialValues(question, initialFormQuestionValues);
    setFormValues({ ...initialFormQuestionValues, ...initialState.initialFormConditionValues });
    setRules(initialState.initialRules);
  }, [question]);

  const onSubmit = (values) => {
    const updatedQuestionData = toJS(question);
    // Question content
    updatedQuestionData.questionText = values.questionText;
    updatedQuestionData.choices[0].choice = values.choice1Label || '';
    updatedQuestionData.choices[1].choice = values.choice2Label || '';
    updatedQuestionData.choices[2].choice = values.choice3Label || '';
    updatedQuestionData.choices[3].choice = values.choice4Label || '';

    ConditionalLogicUtil.extractConditionalFormValues(question, updatedQuestionData, values, rules);

    updateQuestion(updatedQuestionData);
  };

  return (
    <Form
      onSubmit={onSubmit}
      keepDirtyOnReinitialize={true}
      initialValues={formValues}
      render={({ values, handleSubmit }) => {
        return (
          <form className='c-fields l-form'>
            <EngageTextInputField
              id={`input-question-${question._id}`}
              placeholder='Enter question'
              label='Question'
              maxLength={QUESTION_CHARACTER_LIMIT}
              fieldDescription=''
              fieldName='questionText'
              isRequired={true}
              fieldClass={getFormFieldClass(question.defaultData.questionText, null, 'l-form__item')}
              tooltipInLabel={getFormFieldTranslationTooltip(question.defaultData.questionText)}
              helpKey={HELP.PROJECT.EDIT_PROJECT.SURVEY.QUESTIONS.REORDER.TITLE}
            />

            <div className='c-toggle-content'>
              <div className='c-toggle-content__container'>
                <ConditionalLogic
                  formValues={values}
                  setFormValues={setFormValues}
                  question={question}
                  questions={Store.poll.questions}
                  rules={rules}
                  setRules={setRules}
                  show={showConditionalLogic}
                  choiceFieldNames={[]}
                />
                <div className='c-toggle-content__content' hidden={showConditionalLogic}>
                  <div className='l-form'>
                    {question.choices.map((choice, index) => (
                      <EngageTextInputField
                        key={choice._id}
                        index={index}
                        id={`input-choice-${choice._id}`}
                        placeholder='Choice name'
                        label={props.CHOICE_LABELS[index]}
                        maxLength='30'
                        fieldDescription=''
                        fieldName={`choice${index + 1}Label`}
                        isRequired={false}
                        fieldClass={getFormFieldClass(choice.defaultData.choice, null, 'l-form__item--m')}
                        tooltipInLabel={getFormFieldTranslationTooltip(choice.defaultData.choice)}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <SwitchableButtonBar
              updateAction={handleSubmit}
              removeAction={removeQuestion}
              isNew={null}
              previewAction={() => openPollPreview(question._id)}
            />
          </form>
        );
      }}
    />
  );
}

ReorderQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  updateQuestion: PropTypes.func.isRequired,
  removeQuestion: PropTypes.func.isRequired,
  openPollPreview: PropTypes.func.isRequired,
  showConditionalLogic: PropTypes.bool.isRequired,
  Store: PropTypes.object.isRequired,
};

export default observer(ReorderQuestion);
