import React from 'react';
import PropTypes from 'prop-types';
import HelpActionButton from 'common/components/help/HelpActionButton';
import SvgIcon from '../../SvgIcon';
import FieldDescription from '../fieldDescription/FieldDescription';

export default function SimpleSelectInputField(props) {
  const {
    choices,
    id,
    label,
    baseFieldClass = 'l-form__item',
    fieldClass = '',
    selectClass = '',
    firstOptionEmpty = true,
    input,
    meta = {},
    disabled,
    onSelectionChange,
    isRequired = false,
    requireChangeApproval = false,
    helpKey,
  } = props;
  const { onChange } = input;

  const mergedOnChange = (e) => {
    if (requireChangeApproval) {
      const approved = onSelectionChange(e.currentTarget.value);
      if (approved) onChange(e);
    } else {
      if (onSelectionChange) onSelectionChange(e.currentTarget.value);
      onChange(e);
    }
  };

  const newInput = { ...input, onChange: mergedOnChange };

  function getContainerClass() {
    const errorClass = meta.error && meta.touched ? 'is-error' : '';
    return `c-field ${baseFieldClass} ${fieldClass} ${errorClass} ${isRequired ? 'is-required' : ''} ${
      newInput.value && newInput.value !== -1 ? 'is-populated' : ''
    } `;
  }

  return (
    <div className={getContainerClass()} data-testid={`${id}-wrapper`}>
      {label && (
        <label className='c-field__label' htmlFor={id} data-testid={`${id}-label`}>
          {label}
        </label>
      )}
      <div className='c-field__group'>
        <div className={`o-select ${selectClass} ${disabled ? 'is-disabled' : ''}`}>
          <SvgIcon icon='angle-down' />
          <select {...newInput} id={id} data-testid={`${id}-select`} disabled={disabled}>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            {firstOptionEmpty && <option value='' />}
            {choices.map((choice) => (
              <option key={choice[0]} value={choice[0]}>
                {choice[1]}
              </option>
            ))}
          </select>
        </div>
        <HelpActionButton helpKey={helpKey} isTextOnly={true} />
        <FieldDescription meta={meta} val={newInput.value} />
      </div>
    </div>
  );
}

SimpleSelectInputField.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.array),
  id: PropTypes.string,
  label: PropTypes.string,
  baseFieldClass: PropTypes.string,
  fieldClass: PropTypes.string,
  selectClass: PropTypes.string,
  firstOptionEmpty: PropTypes.bool,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  requireChangeApproval: PropTypes.bool,
  helpKey: PropTypes.string,
};
