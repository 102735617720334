module.exports = function() {
  let _ = require('lodash');

  const DemographicsTypes = {
    DEMOGRAPHIC_AGE: 'demographic_age', // type 1001
    DEMOGRAPHIC_ETHNICITY: 'demographic_ethnicity', // type 1002
    DEMOGRAPHIC_GENDER: 'demographic_gender', // type 1003
    DEMOGRAPHIC_SEXUALITY: 'demographic_sexuality', // type 1004
    DEMOGRAPHIC_RELIGION: 'demographic_religion', // type 1005
    DEMOGRAPHIC_HOUSEHOLD_NUMBER: 'demographic_household_number', // type 1006
    DEMOGRAPHIC_HOUSEHOLD_INCOME: 'demographic_household_income', // type 1007
    DEMOGRAPHIC_ACCOMODATION_TYPE: 'demographic_accommodation_type', // type 1008
    DEMOGRAPHIC_ACCOMODATION_OWNERSHIP: 'demographic_accommodation_ownership', // type 1009
    DEMOGRAPHIC_EMPLOYEMENT: 'demographic_employment', // type 1010
    DEMOGRAPHIC_CUSTOM_TYPE: 'demographic_custom_type', // type 1100
  };

  const DemographicsEnums = {
    demographic_age: 'DEMOGRAPHIC_AGE', // type 1001
    demographic_ethnicity: 'DEMOGRAPHIC_ETHNICITY', // type 1002
    demographic_gender: 'DEMOGRAPHIC_GENDER', // type 1003
    demographic_sexuality: 'DEMOGRAPHIC_SEXUALITY', // type 1004
    demographic_religion: 'DEMOGRAPHIC_RELIGION', // type 1005
    demographic_household_number: 'DEMOGRAPHIC_HOUSEHOLD_NUMBER', // type 1006
    demographic_household_income: 'DEMOGRAPHIC_HOUSEHOLD_INCOME', // type 1007
    demographic_accommodation_type: 'DEMOGRAPHIC_ACCOMODATION_TYPE', // type 1008
    demographic_accommodation_ownership: 'DEMOGRAPHIC_ACCOMODATION_OWNERSHIP', // type 1009
    demographic_employment: 'DEMOGRAPHIC_EMPLOYEMENT', // type 1010
    demographic_custom_type: 'DEMOGRAPHIC_CUSTOM_TYPE', // type 1100
  };

  const DemographicsTypesEnums = {
    DEMOGRAPHIC_AGE: 'DEMOGRAPHIC_AGE', // type 1001
    DEMOGRAPHIC_ETHNICITY: 'DEMOGRAPHIC_ETHNICITY', // type 1002
    DEMOGRAPHIC_GENDER: 'DEMOGRAPHIC_GENDER', // type 1003
    DEMOGRAPHIC_SEXUALITY: 'DEMOGRAPHIC_SEXUALITY', // type 1004
    DEMOGRAPHIC_RELIGION: 'DEMOGRAPHIC_RELIGION', // type 1005
    DEMOGRAPHIC_HOUSEHOLD_NUMBER: 'DEMOGRAPHIC_HOUSEHOLD_NUMBER', // type 1006
    DEMOGRAPHIC_HOUSEHOLD_INCOME: 'DEMOGRAPHIC_HOUSEHOLD_INCOME', // type 1007
    DEMOGRAPHIC_ACCOMODATION_TYPE: 'DEMOGRAPHIC_ACCOMODATION_TYPE', // type 1008
    DEMOGRAPHIC_ACCOMODATION_OWNERSHIP: 'DEMOGRAPHIC_ACCOMODATION_OWNERSHIP', // type 1009
    DEMOGRAPHIC_EMPLOYEMENT: 'DEMOGRAPHIC_EMPLOYEMENT', // type 1010
    DEMOGRAPHIC_CUSTOM_TYPE: 'DEMOGRAPHIC_CUSTOM_TYPE', // type 1100
  };

  const DemographicLabels = {
    DEMOGRAPHIC_AGE: 'Age', // type 1001
    DEMOGRAPHIC_ETHNICITY: 'Ethnicity', // type 1002
    DEMOGRAPHIC_GENDER: 'Gender', // type 1003
    DEMOGRAPHIC_SEXUALITY: 'Sexuality', // type 1004
    DEMOGRAPHIC_RELIGION: 'Religion', // type 1005
    DEMOGRAPHIC_HOUSEHOLD_NUMBER: 'Household number', // type 1006
    DEMOGRAPHIC_HOUSEHOLD_INCOME: 'Household income', // type 1007
    DEMOGRAPHIC_ACCOMODATION_TYPE: 'Accommodation type', // type 1008
    DEMOGRAPHIC_ACCOMODATION_OWNERSHIP: 'Accommodation ownership', // type 1009
    DEMOGRAPHIC_EMPLOYEMENT: 'Employment', // type 1010
    DEMOGRAPHIC_CUSTOM_TYPE: 'Custom', // type 1100
  };

  function isCustomQuestion(questionType) {
    return (
      DemographicsEnums.demographic_custom_type === questionType ||
      DemographicsTypes.DEMOGRAPHIC_CUSTOM_TYPE === questionType
    );
  }
  function isStandardQuestion(questionType) {
    return (DemographicsEnums[questionType] || DemographicsTypes[questionType]) && !isCustomQuestion(questionType);
  }

  const DemographicsChoiceContentTypes = {
    DEFINED: 'DEFINED',
    OTHER: 'OTHER',
    SKIP: 'SKIP',
  };

  const PredefinedChoices = {
    SKIP: {
      choiceKey: 'custom_skip_choice',
      translationKey: 'common.custom_skip_choice.choice',
    },
    OTHER: {
      choiceKey: 'custom_other_choice',
      translationKey: 'common.custom_other_choice.choice',
    },
  };

  const CUSTOM_QUESTION_CHOICE_COUNT = 2;

  return {
    CUSTOM_QUESTION_CHOICE_COUNT,
    DemographicsTypes,
    DemographicsTypesEnums,
    DemographicsEnums,
    DemographicLabels,
    DemographicsEnumsInvers,
    DemographicsTypesList,
    DemographicsTypesIntegerMap,
    DemographicsChoiceContentTypes,
    DemographicsChoiceContentTypesList,
    PredefinedChoices,
    isCustomQuestion,
    isStandardQuestion,
  };
  /**
   *
   */
  function DemographicsTypesList() {
    return _.map(Object.keys(DemographicsTypes), key => {
      return DemographicsTypes[key];
    });
  }

  function DemographicsTypesIntegerMap() {
    // User Levels (and points required to hit that level)
    let demographicsTypesMap = {};
    demographicsTypesMap[DemographicsTypes.DEMOGRAPHIC_AGE] = 1001;
    demographicsTypesMap[DemographicsTypes.DEMOGRAPHIC_ETHNICITY] = 1002;
    demographicsTypesMap[DemographicsTypes.DEMOGRAPHIC_GENDER] = 1003;
    demographicsTypesMap[DemographicsTypes.DEMOGRAPHIC_SEXUALITY] = 1004;
    demographicsTypesMap[DemographicsTypes.DEMOGRAPHIC_RELIGION] = 1005;
    demographicsTypesMap[DemographicsTypes.DEMOGRAPHIC_HOUSEHOLD_NUMBER] = 1006;
    demographicsTypesMap[DemographicsTypes.DEMOGRAPHIC_HOUSEHOLD_INCOME] = 1007;
    demographicsTypesMap[DemographicsTypes.DEMOGRAPHIC_ACCOMODATION_TYPE] = 1008;
    demographicsTypesMap[DemographicsTypes.DEMOGRAPHIC_ACCOMODATION_OWNERSHIP] = 1009;
    demographicsTypesMap[DemographicsTypes.DEMOGRAPHIC_EMPLOYEMENT] = 1010;
    demographicsTypesMap[DemographicsTypes.DEMOGRAPHIC_CUSTOM_TYPE] = 1100;
    return demographicsTypesMap;
  }

  function DemographicsChoiceContentTypesList() {
    return _.map(Object.keys(DemographicsChoiceContentTypes), key => {
      return DemographicsChoiceContentTypes[key];
    });
  }
  /**
   */
  function DemographicsEnumsInvers() {
    let aMap = {};
    _.map(Object.keys(DemographicsEnums), key => {
      aMap[DemographicsEnums[key]] = key;
    });
    return aMap;
  }
};
