import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ImageUtils from '../../../ImageUtils';
import { IMAGE_FORMATS } from '../fileUpload/ImageFormatEnums';
import SvgIcon from '../../SvgIcon';

export const SHOW_ALL_REACTIONS = { choiceId: 'SHOW_ALL_REACTIONS', label: 'Show all reactions' };

function SimpleReactionPicker(props) {
  const {
    selectedReaction,
    reactions,
    hasShowAllOption = true,
    onReactionChange,
    wrapperClass,
    reactionsListClass,
  } = props;

  const [showReactions, setShowReactions] = useState(false);

  function reactionChangeHandler(reaction) {
    setShowReactions(false);
    onReactionChange(reaction);
  }

  return (
    <div className={`c-filter-set ${wrapperClass}`} data-testid='reaction-picker-wrapper'>
      <div className='c-filter-set__add'>
        <button className='o-button' type='button' onClick={() => setShowReactions(!showReactions)}>
          {selectedReaction?.resource && (
            <span className='c-filter-select__image'>
              <img
                src={ImageUtils.pngImageUrl(selectedReaction.resource, IMAGE_FORMATS.REACTION.imageType)}
                alt={selectedReaction.label}
              />
            </span>
          )}
          <span className='o-label'>{selectedReaction?.label}</span>
          <SvgIcon icon='angle-down' />
        </button>

        {showReactions && (
          <div
            className={`c-filter-dropdown ${reactionsListClass}`}
            style={{ display: 'block' }}
            data-testid='reactionsListWrapper'
          >
            <div className='c-filter-dropdown__content'>
              <div className='c-filter-select'>
                <div className='c-filter-select__group'>
                  <div className='c-filter-select__choices'>
                    {hasShowAllOption && (
                      <button
                        className={`c-filter-select__button ${
                          selectedReaction && selectedReaction.choiceId === SHOW_ALL_REACTIONS.choiceId
                            ? 'is-selected'
                            : ''
                        }`}
                        type='button'
                        onClick={() => reactionChangeHandler(SHOW_ALL_REACTIONS)}
                      >
                        <h2 className='c-filter-select__title'>{SHOW_ALL_REACTIONS.label}</h2>
                      </button>
                    )}
                    {reactions.map((reaction) => (
                      <button
                        key={reaction.choiceId}
                        className={`c-filter-select__button ${
                          selectedReaction && selectedReaction.choiceId === reaction.choiceId ? 'is-selected' : ''
                        }`}
                        type='button'
                        onClick={() => reactionChangeHandler(reaction)}
                      >
                        <span className='c-filter-select__image'>
                          <img
                            src={ImageUtils.pngImageUrl(reaction.resource, IMAGE_FORMATS.REACTION.imageType)}
                            alt={reaction.label}
                          />
                        </span>
                        <h2 className='c-filter-select__title'>
                          {reaction.label}
                          {reaction?.count > 0 ? (
                            <span className='additional__info'>{`(${reaction.count} ${
                              reaction.count > 1 ? 'results' : 'result'
                            })`}</span>
                          ) : null}
                        </h2>
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

SimpleReactionPicker.propTypes = {
  selectedReaction: PropTypes.object,
  reactions: PropTypes.array.isRequired,
  hasShowAllOption: PropTypes.bool,
  onReactionChange: PropTypes.func.isRequired,
  wrapperClass: PropTypes.string,
  reactionsListClass: PropTypes.string,
};

export default SimpleReactionPicker;
