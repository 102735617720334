import { observable, computed, action, makeObservable } from 'mobx';
// eslint-disable-next-line import/no-extraneous-dependencies
import AES from 'crypto-js/aes';
import Cookies from 'universal-cookie';
import { PASSWORD_RESET_ERRORS } from 'shared/engage/api-error-messages-V2';
import { GMV_COOKIE } from '../shared/engage/client-constants';
import USER_ENUMS from '../shared/engage/engage-user-enums';
import ROLE_ENUMS from '../shared/engage/engage-role.enums';
// eslint-disable-next-line import/no-named-default
import { default as _agent } from '../common/agent';
import { ApiErrorEnums } from '../shared/engage';

const Agent = _agent.Auth;
const cookies = new Cookies();
const COOKIE_OPTIONS = { path: '/' };

const LOGIN_ERROR = 'Authentication failed';
const ERROR_RESET_PASSWORD_MISMATCH = 'Wrong email or password reset code';
const PASSWORD_RESET_REQUEST_SUCCESS = 'Request sent';
const PASSWORD_RESET_REQUEST_ERROR = 'Failed requesting password reset';
const PASSWORD_RESET_SUCCESS = 'New password saved';
const PASSWORD_RESET_ERROR = 'Failed saving new password';
const PASSWORD_VALIDATION_ERROR = `Password's should be at least 8 character long, be a mix of upper and lower case letters and contain at least one number and one symbol (!@?;:)`;
const UNSUCCESSFUL_LOGIN_ATTEMPTS_EXCEEDED = 'Unsuccessful login attempts exceeded, please try again in 1 hour';
const INVALID_LOGIN_CREDENTIAL = 'Invalid username/password';
const PASSWORD_RESET_CODE_SUCCESS = 'New activation code sent';

export default class AuthStore {
  _loading = false;

  _initialized = false;

  _error = null;

  _me = { company: {} };

  _clientId = null;

  constructor(rootStore) {
    makeObservable(this, {
      _me: observable,
      _loading: observable,
      _initialized: observable,
      _error: observable,
      _clientId: observable,

      loadData: action,
      logout: action,

      me: computed,
      isGmvStaff: computed,
      clientId: computed,
      client: computed,
      isSingleClient: computed,

      setClientId: action,
    });

    this.rootStore = rootStore;
  }

  async init() {
    return Agent.me().then((res) => {
      // console.log('Load user', res.data);
      // tmp fix for switching APIs
      // res.data.company = [];
      this.loadData(res.data);
      // console.log('AUTH DONE')
      return res.data;
    });
  }

  get isLoading() {
    return this._loading;
  }

  get isInitialized() {
    return this._initialized;
  }

  get error() {
    return this._error;
  }

  get clientId() {
    return this._clientId;
  }

  get client() {
    if (this._me && this._me.editingProfiles) {
      // eslint-disable-next-line no-shadow
      const profile = this._me.editingProfiles.find((profile) => profile.profileId === this._clientId);
      return profile || {};
    }
    return {};
  }

  get isSingleClient() {
    return this._me && this._me.editingProfiles && this._me.editingProfiles.length === 1;
  }

  get me() {
    return this._me;
  }

  setClientId = (value) => {
    this._clientId = value;
  };

  get isGmvStaff() {
    if (!this._me || !this._me.roles) {
      return false;
    }

    return this._me.roles.some((role) => role.category === ROLE_ENUMS().category.BID_GMV_STAFF);
  }

  /**
   * This password check function should be shared with server code
   * @param password
   * @param _passwordRepeated
   * @returns {boolean}
   */
  // eslint-disable-next-line class-methods-use-this
  validatePasswordShared(password = '', _passwordRepeated = '') {
    const isShortPassword = !password || password.length < 8;
    const noUperLowerCase = !(password.match(/[a-z]/) && password.match(/[A-Z]/));
    const noNumber = !password.match(/[0-9]/);
    const noSymbol = !password.match(/.[,!,@,#,$,%,^,&,*,?,_,~,-,(,)]/);

    if (isShortPassword || noUperLowerCase || noNumber || noSymbol) {
      // console.log('isShortPassword, noUperLowerCase, noNumber, noSymbol', password,passwordRepeated,isShortPassword,noUperLowerCase,noNumber,noSymbol);
      return false;
    }

    return true;
  }

  /**
   * Validate password
   */
  validatePassword(password = '', passwordRepeated = '') {
    const isPasswordOK = this.validatePasswordShared(password, passwordRepeated);
    const isMismatch = password !== passwordRepeated;

    if (isMismatch) throw new Error(PASSWORD_RESET_ERRORS.MISSMATCHED_PASSWORDS.errorMessage);

    if (!isPasswordOK) throw new Error(PASSWORD_RESET_ERRORS.FAILED_PASSWORD_VALIDATON.errorMessage);
  }

  /**
   * Logout
   */
  logout() {
    cookies.remove(GMV_COOKIE, COOKIE_OPTIONS);
    this._me = { company: {} };
  }

  // eslint-disable-next-line class-methods-use-this
  encryptPassword(clearPwd) {
    const tmpSalt = USER_ENUMS().EngageAESSalt;
    return AES.encrypt(clearPwd, tmpSalt).toString();
  }

  /**
   * Login
   */
  async login(email, clearPwd, rememberMe) {
    const encPass = this.encryptPassword(clearPwd);

    try {
      // login
      const res = await Agent.login({ email, password: encPass });
      const token = res.data.token;

      cookies.set(GMV_COOKIE, token, COOKIE_OPTIONS);

      // load user profile
      await this.init();
    } catch (err) {
      if (err.response.data.message === ApiErrorEnums().Err401KeyMmap.NUMBER_EXCEEDED_MSG) {
        this.rootStore.toastrStore.warning(UNSUCCESSFUL_LOGIN_ATTEMPTS_EXCEEDED, null, err);
      } else if (err.response.data.message === ApiErrorEnums().Err401KeyMmap.INVALID_CREDENTIALS_MSG) {
        this.rootStore.toastrStore.error(INVALID_LOGIN_CREDENTIAL, null, err);
      } else {
        this.rootStore.toastrStore.error(LOGIN_ERROR, null, err);
      }
      throw err;
    }
  }

  /**
   * Request password reset code
   */
  async requestResetPassword(email) {
    try {
      await Agent.requestResetPassword({ email });

      this.rootStore.toastrStore.success(PASSWORD_RESET_CODE_SUCCESS);
    } catch (err) {
      this.rootStore.toastrStore.error(PASSWORD_RESET_REQUEST_ERROR, null, err);
      throw err;
    }
  }

  /**
   * Reset password
   */
  async resetPassword(email, clearPwd, passwordRepeated, resetCode) {
    try {
      this.validatePassword(clearPwd, passwordRepeated);

      const newPassword = this.encryptPassword(clearPwd);
      const reqData = {
        email,
        newPassword,
        code: resetCode,
      };

      // reset password
      await Agent.resetPassword(reqData);
      // then emulate user login to load user profile
      await this.login(email, clearPwd, true);

      this.rootStore.toastrStore.success(PASSWORD_RESET_SUCCESS);

      return true;
    } catch (err) {
      // Errors are handled differently, depending on error type and error code

      // custom error in response {errorCode, errorMessage}
      const customError = err?.response?.data?.message;

      // case: reset code expired - toaster message + do not throw
      if (customError?.errorCode === PASSWORD_RESET_ERRORS.CODE_EXPIRED.errorCode) {
        this.rootStore.toastrStore.error(customError.errorMessage, null, '');
        return false;
      }

      // case: all other errors - toaster message + throw
      if (customError) {
        // specific errors provided by server (like email validation)
        this.rootStore.toastrStore.error(customError.errorMessage, null, '');
      } else if (
        // specific errors provided by local validator (password validation)
        err.message === PASSWORD_RESET_ERRORS.MISSMATCHED_PASSWORDS.errorMessage ||
        err.message === PASSWORD_RESET_ERRORS.FAILED_PASSWORD_VALIDATON.errorMessage
      ) {
        this.rootStore.toastrStore.error(err, null, err);
      } else {
        // generic error
        this.rootStore.toastrStore.error(PASSWORD_RESET_ERROR, null, err);
      }
      throw err;
    }
  }

  /**
   * Load and prepare data
   * @param items
   */
  loadData(user) {
    this._me = user;
    // this._clientId = user.company.profile;
    this._clientId = user.editingProfiles.length === 1 ? user.editingProfiles[0].profileId : null;
    this._initialized = true;
  }
}
