import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { SortableContainer } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { Link } from 'react-router-dom';

import ActionBar from '../../../../common/components/actionBar/ActionBar';
import PollTeaser from './PollTeaser';
import SvgIcon from '../../../../common/components/SvgIcon';
import { SEGMENTATION } from '../../../../common/constants/RouteConstants';
import { VIEW } from '../../../../common/ability';
import { Can } from '../../../../common/Can';

function PollContainer(props) {
  const { items, allowDrag } = props;
  return (
    <div className='c-reorder-list c-reorder-list--content'>
      {items.map((poll, index) => (
        <PollTeaser key={poll._id} index={index} poll={poll} disabled={!allowDrag} />
      ))}
    </div>
  );
}

PollContainer.propTypes = {
  items: PropTypes.array.isRequired,
};

function PollsList(props) {
  const { Store, SegmentationStore, ProjectStore, match, history } = props;

  const [allowDrag, setAllowDrag] = useState(true);

  const projectId = match.params.id;

  useEffect(() => {
    if (Store && SegmentationStore && projectId) {
      Store.load(projectId);
      SegmentationStore.load(projectId);
    }
  }, [Store, SegmentationStore, ProjectStore.project, projectId]);

  function addNew() {
    history.push(`surveys/create-survey`);
  }

  function onSortEnd({ oldIndex, newIndex }) {
    setAllowDrag(false);
    const newOrder = arrayMoveImmutable(Store.polls, oldIndex, newIndex);
    Store.update(match.params.id, newOrder).then(() => {
      setAllowDrag(true);
    });
  }

  return (
    <div className='l-main'>
      <ActionBar addNew={addNew} label='Surveys' hasButton={true} buttonLabel='Add' />
      <Can I={VIEW} a={SEGMENTATION}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {SegmentationStore.activeQuestion ? (
          <div className='c-message c-message--icon is-success'>
            <SvgIcon icon='poll-segment' />
            <p className='c-message__description'>
              {`Segmentation: `}
              <strong>{SegmentationStore.activeQuestion.questionText}</strong>
            </p>
          </div>
        ) : Store.polls.length === 0 ? null : (
          <div className='c-message c-message--icon is-information'>
            <SvgIcon icon='poll-segment' />
            <p className='c-message__description'>
              {`Currently there is not a segmentation question active. `}
              <Link to={SEGMENTATION}>Add segmentation question</Link>
            </p>
          </div>
        )}
      </Can>
      <SortableList items={Store.polls} onSortEnd={onSortEnd} useDragHandle={true} allowDrag={allowDrag} />
    </div>
  );
}

const SortableList = SortableContainer((props) => {
  return <PollContainer {...props} />;
});

PollsList.propTypes = {
  Store: PropTypes.object.isRequired,
  SegmentationStore: PropTypes.object.isRequired,
  ProjectStore: PropTypes.object.isRequired,
};

export default inject((root) => ({
  Store: root.RootStore.pollsListStore,
  SegmentationStore: root.RootStore.segmentationStore,
  ProjectStore: root.RootStore.projectStore,
}))(observer(PollsList));
