import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import SvgIcon from '../../../common/components/SvgIcon';

function QuestionFooter(props) {
  const {
    answered,
    skipped = undefined,
    viewed = undefined,
    possibleAnswers,
    showAnswered = true,
    showSkipped = true,
    showViewed = true,
    GeneralStore,
  } = props;

  return (
    <div className='c-question__footer'>
      <ul className='c-question__stats'>
        {showAnswered && (
          <li>
            <SvgIcon icon='check' />
            <strong>{`${answered} `}</strong>
            answered{' '}
            {possibleAnswers &&
              `\u2014 Could have chosen ${possibleAnswers > 1 ? 'up to' : ''} ${possibleAnswers} ${
                possibleAnswers > 1 ? 'choices' : 'choice'
              }`}
          </li>
        )}
        {showSkipped && (
          <li>
            <SvgIcon icon='forward' />
            <strong>{`${skipped} `}</strong>

            <span className='o-tooltip o-tooltip--top-center'>
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
              <span className='o-tooltip__action' tabIndex='0'>
                <span className='o-label'>skipped</span>
                <SvgIcon icon='info-circle' />
              </span>
              {!GeneralStore.isSnapshotting && (
                <span className='o-tooltip__description' role='tooltip'>
                  Skipped and viewed count is from 28th June 2021 onwards, therefore they may not reflect all voters if
                  your survey was live before this date.
                </span>
              )}
            </span>
          </li>
        )}
        {showViewed && (
          <li>
            <SvgIcon icon='eye' />
            <strong>{`${viewed} `}</strong>
            viewed
          </li>
        )}
      </ul>
    </div>
  );
}

QuestionFooter.propTypes = {
  answered: PropTypes.number,
  skipped: PropTypes.number,
  viewed: PropTypes.number,
  possibleAnswers: PropTypes.number,
  showAnswered: PropTypes.bool,
  showSkipped: PropTypes.bool,
  showViewed: PropTypes.bool,
  GeneralStore: PropTypes.object.isRequired,
};

export default inject((root) => ({
  GeneralStore: root.RootStore.generalStore,
}))(observer(QuestionFooter));
