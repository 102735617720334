import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { getFormFieldClass, getFormFieldTranslationTooltip } from 'common/TranslationUtils';
import SvgIcon from '../../../common/components/SvgIcon';
import MapComponent from '../../../common/components/map/MapComponent';
import GOOGLE_MAP_ENUMS from '../../../shared/engage/engage-google-map-enums';
import ActionBar from '../../../common/components/actionBar/ActionBar';
import SwitchableButtonBar from '../../../common/components/switchableButtonBar/SwitchableButtonBar';
import EngageTextInputField from '../../../common/components/form-elements/textInputField/TextInputField';
import EngageCheckboxInputField from '../../../common/components/form-elements/checkboxInputField/CheckboxInputField';

const shapeTypes = GOOGLE_MAP_ENUMS().shapeTypes;
const mapStyle = GOOGLE_MAP_ENUMS().locationMapStyle;
function Geolocation(props) {
  const { GeolocationStore, ProjectStore, ToastrStore, match } = props;

  const projectId = match.params.id;

  const [project, setProject] = useState();
  const [initValues, setInitValues] = useState();
  const [location, setLocation] = useState();
  const [locationZoom, setLocationZoom] = useState();
  const [domain, setDomain] = useState();
  const [domainZoom, setDomainZoom] = useState();

  useEffect(() => {
    if (!ProjectStore.project) return;

    setProject(ProjectStore.project);
    setLocation({ ...ProjectStore.project.location });
    setLocationZoom(ProjectStore.project.location.zoom || GOOGLE_MAP_ENUMS().mapOptions.zoom);
    setDomain({ ...ProjectStore.project.domain });
    setDomainZoom(ProjectStore.project.domain.zoom || GOOGLE_MAP_ENUMS().mapOptions.zoom);

    setInitValues({
      locationName: ProjectStore.project.location.locationName,
      isGeofencingActive: ProjectStore.project.domain.isActivated,
    });
  }, [ProjectStore.project]);

  function isValidPollingArea(domainData, isGeofencingActive) {
    if (!isGeofencingActive) {
      return true;
    }
    if (!domainData.circle && !domainData.rectangle && !domainData.polygon) {
      return false;
    }
    if (
      domainData.shape === 'polygon' &&
      domainData.polygon &&
      (!domainData.polygon.path || domainData.polygon.path.length === 0)
    ) {
      return false;
    }
    return true;
  }

  function preparePayload(payload) {
    const newPayload = {};
    newPayload.shape = payload.shape;
    newPayload.isActivated = payload.isActivated;

    switch (payload.shape) {
      case shapeTypes.CIRCLE:
        newPayload.circle = payload.circle;
        break;
      case shapeTypes.RECTANGLE:
        newPayload.rectangle = payload.rectangle;
        break;
      case shapeTypes.POLYGON:
        newPayload.polygon = payload.polygon;
        break;
      case shapeTypes.MARKER:
        newPayload.point = payload.point;
        break;
      default:
        break;
    }

    return newPayload;
  }

  function onSubmit(values) {
    const newLocation = preparePayload(location);
    const newDomain = preparePayload(domain);

    if (values.isGeofencingActive && !isValidPollingArea(newDomain, values.isGeofencingActive)) {
      ToastrStore.error('Please define your geofence before activating', null, null);
      return;
    }
    if (
      values.isGeofencingActive &&
      !project.domain.isActivated &&
      !window.confirm('ATTENTION!!! This will activate geofencing.')
    )
      return;

    GeolocationStore.setGeoValidArea(projectId, {
      ...newDomain,
      isActivated: values.isGeofencingActive,
    }).then(() => {
      GeolocationStore.setGeoLocation(projectId, {
        ...newLocation,
        locationName: values.locationName,
        zoom: locationZoom,
      });
    });
  }

  return (
    <div>
      {project ? (
        <div className='l-main'>
          <ActionBar label='Location' hasButton={false} />
          <Form
            onSubmit={onSubmit}
            initialValues={initValues}
            render={({ values, form, handleSubmit, submitting, pristine }) => {
              return (
                <form className='c-fields l-form'>
                  <MapComponent
                    id='locationMap'
                    mapData={location}
                    setMapData={setLocation}
                    mapZoom={locationZoom}
                    setMapZoom={setLocationZoom}
                    label='Project location'
                    mapDescription='Define project with a pin, circle, rectangle or a custom polygon.'
                    clearMapText='Clear location'
                    allowedDrawTools={[shapeTypes.CIRCLE, shapeTypes.POLYGON, shapeTypes.RECTANGLE, shapeTypes.MARKER]}
                    includeSearchControl={true}
                    mapStyle={mapStyle}
                    memorizeZoomLevel={true}
                    clickableOverlays={true}
                  />
                  <h3>Geolocation</h3>
                  <EngageTextInputField
                    id='input-location-name'
                    placeholder='Enter surveying area location'
                    label='Surveying area location'
                    maxLength='40'
                    fieldName='locationName'
                    fieldClass={getFormFieldClass(
                      ProjectStore.projectDefault.location.locationName,
                      null,
                      'l-form__item',
                    )}
                    tooltipInLabel={getFormFieldTranslationTooltip(ProjectStore.projectDefault.location.locationName)}
                  />
                  <MapComponent
                    id='surveyAreaMap'
                    mapData={domain}
                    setMapData={setDomain}
                    mapZoom={domainZoom}
                    setMapZoom={setDomainZoom}
                    label='Surveying area'
                    mapDescription='Define surveying area with a circle, rectangle or a custom polygon.'
                    clearMapText='Clear surveying area'
                    allowedDrawTools={[shapeTypes.CIRCLE, shapeTypes.POLYGON, shapeTypes.RECTANGLE]}
                    includeSearchControl={true}
                    mapStyle={mapStyle}
                    clickableOverlays={true}
                  />
                  <EngageCheckboxInputField
                    id='checkbox-project-status'
                    type='checkbox'
                    fieldName='isGeofencingActive'
                    // fieldClass='l-form__item--m'
                    label='Geolocation surveying'
                    isToggle={true}
                    checkedLabel='Active'
                    uncheckedLabel='Active'
                    hideLabel={false}
                    fieldDescription='Projects with active geolocation will limit voting in surveys to people inside the surveying area.'
                  />
                  <div className='l-form__item'>
                    {project.publishedProjectGeofenceIsActivated && (
                      <div className='c-message c-message--icon is-success'>
                        <SvgIcon icon='check' />
                        <h3 className='c-message__title'>Geolocation is active on live project!</h3>
                      </div>
                    )}
                    {!project.publishedProjectGeofenceIsActivated && domain.isActivated && (
                      <div className='c-message c-message--icon is-warning'>
                        <SvgIcon icon='triangle' />
                        <h3 className='c-message__title'>Geolocation turned on!</h3>
                        <p className='c-message__description'>
                          Live project still has geolocation turned off! This change won’t be reflected on the live
                          project until the changes are published on the settings page.
                        </p>
                      </div>
                    )}
                    {project.publishedProjectGeofenceIsActivated && !domain.isActivated && (
                      <div className='c-message c-message--icon is-warning'>
                        <SvgIcon icon='triangle' />
                        <h3 className='c-message__title'>Geolocation turned off!</h3>
                        <p className='c-message__description'>
                          Live project still has geolocation turned on! This change won’t be reflected on the live
                          project until the changes are published on the settings page.
                        </p>
                      </div>
                    )}
                  </div>
                  <SwitchableButtonBar updateAction={handleSubmit} />
                </form>
              );
            }}
          />
        </div>
      ) : (
        'Loading project'
      )}
    </div>
  );
}

Geolocation.propTypes = {
  GeolocationStore: PropTypes.object.isRequired,
  ProjectStore: PropTypes.object.isRequired,
  ToastrStore: PropTypes.object.isRequired,
};

export default inject((root) => ({
  GeolocationStore: root.RootStore.geolocationStore,
  ProjectStore: root.RootStore.projectStore,
  ToastrStore: root.RootStore.toastrStore,
}))(observer(Geolocation));
