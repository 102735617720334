
module.exports = function(){

  let _ = require('lodash');

  const EngageFeedbackTypeFilter = {
      ENGAGE_FEEDBACK_ALL: 'all',
      ENGAGE_FEEDBACK_HIGHLIGHTS: 'highlights',
      ENGAGE_FEEDBACK_PROBLEMS: 'problems',
      ENGAGE_FEEDBACK_REQUESTS: 'requests',
      ENGAGE_FEEDBACK_OTHER: 'other',
    };


  const EngageFeedbackType = {
    ENGAGE_FEEDBACK_TYPE_PROBLEM: 'problem feedback',
    ENGAGE_FEEDBACK_TYPE_REQUEST: 'request feedback',
    ENGAGE_FEEDBACK_TYPE_GENERAL: 'general feedback',
  };

  const EngageFeedbackTypeMap = [
    {feedbackType: EngageFeedbackType.ENGAGE_FEEDBACK_TYPE_PROBLEM, _id:1, description: [
      {language:'ar', description:'لدي إشكال بشأن هذا المشروع'},
      {language:'bn', description:'এই প্রকল্পে আমার একটি সমস্যা রয়েছে'},
      {language:'de', description:'Ich habe ein Problem mit diesem Projekt'},
      {language:'en', description:'I have a problem with this project'},
      {language:'es', description:'Tengo un problema con este proyecto'},
      {language:'cy', description:'I have a problem with this project'},
      {language:'gu', description:'મને આ પરિયોજનાથી સમસ્યા છે '},
      {language:'pa', description:'ਮੈਨੂੰ ਇਸ ਪ੍ਰਾਜੈਕਟ ਨਾਲ ਕੋਈ ਸਮੱਸਿਆ ਹੈ'},
      {language:'pl', description:'Mam problem z tym projektem'},
      {language:'ur', description:'مجھے اس منصوبے میں ایک مسئلہ درپیش ہے'}
    ]},

    {feedbackType: EngageFeedbackType.ENGAGE_FEEDBACK_TYPE_REQUEST, _id:2, description: [
      {language:'ar', description:'لدي طلب بشأن هذا المشروع'},
      {language:'bn', description:'এই প্র্রকল্পের জন্য আমার একটি অনুরোধ রয়েছে'},
      {language:'de', description:'Ich habe eine Frage bezüglich dieses Projekts'},
      {language:'en', description:'I have a request for this project'},
      {language:'es', description:'Tengo una petición para este proyecto'},
      {language:'cy', description:'I have a request for this project'},
      {language:'gu', description:'મારી આ પરિયોજના માટે વિનંતી છે. '},
      {language:'pa', description:'ਮੇਰੇ ਕੋਲ ਇਸ ਪ੍ਰੋਜੈਕਟ ਲਈ ਬੇਨਤੀ ਹੈ'},
      {language:'pl', description:'Mam pytanie do tego projektu'},
      {language:'ur', description:'اس منصوبے کے بارے میں میری ایک درخواست ہے'}
    ]},

    {feedbackType: EngageFeedbackType.ENGAGE_FEEDBACK_TYPE_GENERAL, _id:3, description: [
      {language:'ar', description:'لدي تعليقات بشكل عام حول هذا المشروع'},
      {language:'bn', description:'এই প্র্রকল্পের জন্য আমার সাধারণ ফিডব্যাক আছে'},
      {language:'de', description:'Ich habe generelles Feedback für dieses Projekt '},
      {language:'en', description:'I have general feedback for this project'},
      {language:'es', description:'Tengo una opinión general para este proyecto'},
      {language:'cy', description:'I have general feedback for this project'},
      {language:'gu', description:'આ પરિયોજના માટે મારો સામાન્ય પ્રતિસાદ છે '},
      {language:'pa', description:'ਮੇਰੇ ਕੋਲ ਇਸ ਪ੍ਰੋਜੈਕਟ ਲਈ ਆਮ ਫੀਡਬੈਕ ਹੈ'},
      {language:'pl', description:'Mam ogólną opinię na temat tego projektu'},
      {language:'ur', description:'اس منصوبے کے بارے میں میری ایک عمومی رائے ہے'}
    ]}
  ];

  return {
    EngageFeedbackTypeFilter: EngageFeedbackTypeFilter,
    EngageFeedbackType: EngageFeedbackType,
    engageFeedbackTypeList: engageFeedbackTypeList,
    EngageFeedbackTypeMap: EngageFeedbackTypeMap
  };

  function engageFeedbackTypeList(){
    return _.map(Object.keys(EngageFeedbackType), (key) => { return EngageFeedbackType[key]; });
  }

};

