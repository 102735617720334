/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import mermaid from 'mermaid';

const DEFAULT_CONFIG = {
  startOnLoad: true,
  logLevel: 'fatal',
  securityLevel: 'strict',
  arrowMarkerAbsolute: false,
  theme: 'base',
  themeVariables: {
    fontFamily: "'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif",
    primaryColor: '#eff4fa',
    primaryTextColor: '#222',
    primaryBorderColor: '#829FC5',
    lineColor: '#ccc',
  },
};

function Mermaid(props) {
  const { chart, name } = props;

  useEffect(() => {
    setTimeout(() => {
      // Mermaid initilize its config
      mermaid.initialize(DEFAULT_CONFIG);

      document.getElementById('mermaid')?.removeAttribute('data-processed');
      mermaid.contentLoaded();
    }, 200);
  }, [chart]);

  return (
    <pre
      id='mermaid'
      className={`mermaid ${chart === '' ? 'is-hidden' : ''}`}
      name={name}
      data-testid='mermaid-container'
    >
      {chart}
    </pre>
  );
}

PropTypes.ConditionalLogicModal = {
  chart: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default Mermaid;
