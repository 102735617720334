import React from 'react';
import PropTypes from 'prop-types';
import SnapshotableImage from '../../../../../common/components/snapshotableImage/ScreenshotableImage';
import { AWS_PATH } from '../../../../../common/constants/GeneralConstants';
import { perc } from '../PollReportUtils';

function QuestionChoice(props) {
  const {
    compareActive = false,
    isExporting = false,
    buttonLabel = '',
    onButtonClick,
    choice,
    isWinner = false,
    isOther = false,
    hasMedia = true,
    hasTitle = true,
    questionAnsweredCount,
    imageFormat,
  } = props;
  const { label = '', resource } = choice;

  return (
    <li
      className={`c-result-choice ${isWinner ? 'is-highlight' : ''} ${hasMedia ? '' : 'c-result-choice--no-media'} ${
        hasTitle ? '' : 'c-result-choice--no-title'
      }`}
    >
      {(isOther || resource) && (
        <div className='c-result-choice__media'>
          {isOther ? (
            <img
              src={`${AWS_PATH}images/choice-other-wide.png?v=${new Date().getTime()}`}
              alt=''
              crossOrigin={isExporting ? 'anonymous' : ''}
            />
          ) : (
            <SnapshotableImage resource={resource} format={imageFormat} />
          )}
        </div>
      )}
      {label && !hasMedia && (
        <div className='c-result-choice__label'>
          <span className='o-label'>{label}</span>
        </div>
      )}
      <div className='c-result-choice__content'>
        {hasTitle && <span className='c-result-choice__title'>{label}</span>}
        {!isExporting && isOther && (
          <button className='o-button o-button--xs o-button--inline' onClick={onButtonClick}>
            <span className='o-label'>{buttonLabel}</span>
          </button>
        )}
        {!compareActive && <span className='c-result-choice__votes'>{choice.count}</span>}
        <div className='c-result-choice__value'>
          <div className='c-result-choice__bar'>
            <div
              className='c-result-choice__bar-filled'
              style={{ width: `${perc(choice.count, questionAnsweredCount)}%` }}
            />
          </div>
          <div className='c-result-choice__percent'>{`${perc(choice.count, questionAnsweredCount)}%`}</div>
        </div>
        {compareActive && (
          <div className='c-result-choice__compare'>
            <div className='c-result-choice__bar'>
              <div className='c-result-choice__bar-filled' style={{ width: `${choice.comparePercentage}%` }} />
            </div>
            <div className='c-result-choice__percent'>{choice.comparePercentage}</div>
          </div>
        )}
      </div>
    </li>
  );
}

QuestionChoice.propTypes = {
  isExporting: PropTypes.bool,
  buttonLabel: PropTypes.string,
  onButtonClick: PropTypes.func,
  compareActive: PropTypes.bool,
  choice: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    resource: PropTypes.string,
    value: PropTypes.number,
    percentage: PropTypes.number,
    comparePercentage: PropTypes.number,
  }).isRequired,
  isWinner: PropTypes.bool,
  isOther: PropTypes.bool,
  hasMedia: PropTypes.bool,
  hasTitle: PropTypes.bool,
  questionAnsweredCount: PropTypes.number,
  imageFormat: PropTypes.string,
};

export default QuestionChoice;
