import { action, observable, computed, makeObservable } from 'mobx';
// import { toJS } from 'mobx';

// import {
//   decodeReportType,
//   decodeFeedbackTypeArray,
// } from '../../containers/project/reports/generate/ReportEnums';
import AgentPoll from '../../common/agent';
// import { BACKEND_API_ROOT } from '../../shared/engage/client-constants';

const Agent = AgentPoll.ReportsAgent;

const MSG_REPORT_TITLE = 'Report generation';
const MSG_REPORT_GENERATION_STARTED = 'Started generating report';
// const MSG_REPORT_GENERATION_SUCCESS = 'Report is ready for download. Your download link will expire in 20 minutes.';
const MSG_REPORT_GENERATION_ERROR = 'Failed generating report';
// const MSG_REPORT_GENERATION_TIMEOUT = 'Report generation timed out';
const MSG_REPORT_EXPIRED = 'Your report expired';

const POLLING_PERIOD = 1000 * 2; // Polling for report ready
const EXPIRE_PERIOD = 1000 * 60 * 20; // 20 minutes
// const NR_RETRIES = 100;
const { RUNNING, QUEUED } = require('../../shared/engage/engage-report-enums')().QUEUE_STATUS;

export default class GenerateReportStore {
  _loading = false;

  _initialized = false;

  _error = null;

  _reportStatuses = [];

  _currentProjectId = null;

  // polling controls
  _jobTimer = null;

  constructor(rootStore) {
    makeObservable(this, {
      _loading: observable,
      _initialized: observable,
      _error: observable,
      _reportStatuses: observable,
      _currentProjectId: observable,

      isLoading: computed,
      isInitialized: computed,
      error: computed,
      reportStatuses: computed,
      currentProjectId: computed,

      generateReport: action,
      getJobStatus: action,
      onReportExpire: action,
      addJob: action,
      setCurrentProjectId: action,
    });

    this.rootStore = rootStore;
  }

  get isLoading() {
    return this._loading;
  }

  get currentProjectId() {
    return this._currentProjectId;
  }

  get isInitialized() {
    return this._initialized;
  }

  get error() {
    return this._error;
  }

  get reportStatuses() {
    return this._reportStatuses;
  }

  setCurrentProjectId = (projectId) => {
    this._currentProjectId = projectId;
  };

  onReportExpire = (expiredJobId) => {
    // don't show the toastr if on a different project
    this._reportStatuses.findIndex((status) => status._id === expiredJobId) !== -1 &&
      this.rootStore.toastrStore.info(MSG_REPORT_EXPIRED, MSG_REPORT_TITLE);
    this._reportStatuses.replace(this._reportStatuses.filter((status) => status._id !== expiredJobId));
  };

  getJobStatus = (projectId) => {
    Agent.checkReportStatus(projectId)
      .then(
        action((res) => {
          this._reportStatuses = res.data;
          if (res.data.filter((job) => [RUNNING, QUEUED].includes(job.status)).length === 0) {
            clearInterval(this._jobTimer);
            this._jobTimer = null;
          } else if (!this._jobTimer) {
            this._jobTimer = setInterval(() => this.getJobStatus(projectId), POLLING_PERIOD);
          }
        }),
      )
      .catch((err) => {
        /* console.log(err) */
      });
  };

  addJob = (data, projectId) => {
    const jobId = data.jobId || '';
    setTimeout(this.onReportExpire, EXPIRE_PERIOD, jobId);
    this.getJobStatus(projectId);
    this.rootStore.toastrStore.info(MSG_REPORT_GENERATION_STARTED, MSG_REPORT_TITLE);
  };

  onReportError = (err) => {
    this.rootStore.toastrStore.error(MSG_REPORT_GENERATION_ERROR, MSG_REPORT_TITLE, err);
  };

  /**
   * Starts report generation
   */
  generateReport = (projectId, params) => {
    // console.log('params', params);
    Agent.startReport(projectId, params)
      .then(
        action((res) => {
          setTimeout(() => {
            this.addJob(res.data, projectId);
          }, 1000);
        }),
      )
      .catch((err) => this.onReportError(err));
  };
}
