module.exports = function(){

  let _ = require('lodash');

  const EngageNotificationSourceType = {
      INFLUENCED_DECISION: 'INFLUENCED_DECISION',
      NEWS: 'NEWS'
  };

  const EngageNotificationSortType = {
      RECENT: 'RECENT',
      TOP: 'TOP'
  };

  return {
    EngageNotificationSourceType: EngageNotificationSourceType,
    engageNotificationSourceTypeList: engageNotificationSourceTypeList,
    EngageNotificationSortType: EngageNotificationSortType
  };

  function engageNotificationSourceTypeList(){
    return _.map(Object.keys(EngageNotificationSourceType), (key) => { return EngageNotificationSourceType[key]; });
  }


};
