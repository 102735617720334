import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
// eslint-disable-next-line import/no-cycle
import { PollReportContext } from '../PollsReport';
import QuestionFooter from '../../QuestionFooter';
import QuestionChoice from './QuestionChoice';
import QuestionHeader from '../../QuestionHeader';
import PreviewUtils from '../../../../../common/PreviewUtils';
import agent from '../../../../../common/agent/ReportsAgent';
import { isOtherChoice } from '../PollReportUtils';
import { QUESTION_TYPES } from '../../../../../common/constants/QuestionConstants';
import { IMAGE_FORMATS } from '../../../../../common/components/form-elements/fileUpload/ImageFormatEnums';

function ImagePreview(props) {
  const { PollReportStore, ToastrStore, GeneralStore, question, fromDate, toDate } = props;
  const {
    questionId,
    choices,
    countAnswered,
    questionType,
    multiChoicesOfferedBound,
    multiChoicesSelectableBound,
    skipSeen,
  } = question;

  const pollReportContext = useContext(PollReportContext);
  const containerReference = useRef();

  const [compareActive, setCompareActive] = useState(false);

  useEffect(() => {
    if (pollReportContext)
      pollReportContext.addReference({ id: questionId, containerReference: containerReference.current });
  }, [pollReportContext, questionId]);

  function handleDownload() {
    PreviewUtils.excelDownload(
      agent,
      PollReportStore.projectId,
      PollReportStore.pollId,
      questionId,
      ToastrStore,
      fromDate,
      toDate,
      PollReportStore.segmentChoiceId,
      PollReportStore.demographicChoiceId,
    );
  }

  function getImageFormat() {
    if (
      questionType === QUESTION_TYPES.THREE_IMAGE_SINGLE_CHOICE ||
      questionType === QUESTION_TYPES.FOUR_IMAGE_SINGLE_CHOICE ||
      questionType === QUESTION_TYPES.MULTIPLE_IMAGE_MULTIPLE_CHOICE
    ) {
      return IMAGE_FORMATS.SQUARE.imageType;
    }
    return IMAGE_FORMATS.FLUID_PANEL.imageType;
  }

  return (
    <div ref={containerReference} className='c-poll-preview__item'>
      <div className='c-question c-question js-questionCompare'>
        <QuestionHeader question={question} onCompareChange={() => setCompareActive(!compareActive)} />

        <div className='c-question__content'>
          <div className='c-result-group'>
            <ul className='c-result-group__items'>
              {choices
                .slice(0, multiChoicesOfferedBound)
                .sort((choiceA, choiceB) => choiceB.count - choiceA.count)
                .map((choice, idx) => (
                  <QuestionChoice
                    key={idx}
                    isExporting={GeneralStore.isSnapshotting}
                    buttonLabel='Download'
                    onButtonClick={handleDownload}
                    compareActive={compareActive}
                    choice={choice}
                    questionAnsweredCount={countAnswered}
                    isWinner={idx === 0}
                    isOther={isOtherChoice(choice, choices, questionType)}
                    imageFormat={getImageFormat()}
                  />
                ))}
            </ul>
          </div>
        </div>

        <QuestionFooter
          answered={countAnswered}
          skipped={skipSeen.skipCount}
          viewed={skipSeen.seenCount}
          possibleAnswers={multiChoicesSelectableBound}
        />
      </div>
    </div>
  );
}

ImagePreview.propTypes = {
  ToastrStore: PropTypes.object.isRequired,
  PollReportStore: PropTypes.object.isRequired,
  GeneralStore: PropTypes.object.isRequired,
  question: PropTypes.shape({
    questionId: PropTypes.string.isRequired,
    countAnswered: PropTypes.number,
    choices: PropTypes.array,
    questionType: PropTypes.string.isRequired,
    multiChoicesOfferedBound: PropTypes.number,
    multiChoicesSelectableBound: PropTypes.number,
    skipSeen: PropTypes.shape({
      seenCount: PropTypes.number,
      skipCount: PropTypes.number,
    }),
  }).isRequired,
  fromDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  toDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default inject((root) => ({
  ToastrStore: root.RootStore.toastrStore,
  PollReportStore: root.RootStore.pollsReportStore,
  GeneralStore: root.RootStore.generalStore,
}))(observer(ImagePreview));
