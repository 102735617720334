import React, { useState, useEffect, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
// import { toJS } from 'mobx';
import { SortableContainer } from 'react-sortable-hoc';
import PropTypes from 'prop-types';
import { arrayMoveImmutable } from 'array-move';
import Mermaid from 'common/components/mermaid/Mermaid';
import { prepareGraph } from 'common/components/mermaid/MermaidUtils';
import AnnotateReactionModal from 'common/components/annotateQuestion/annotateReactionModal/AnnotateReactionModal';
import PreviewModal from 'common/components/previewModal/PreviewModal';
import ActionBanner from 'common/components/actionBanner/ActionBanner';
import ConditionalLogicModal from 'common/components/conditionalLogicModal/ConditionalLogicModal';
import { HELP } from 'shared/engage/engage-help-enums';
import ActionBar from '../../../../common/components/actionBar/ActionBar';
import SortableQuestionSwitcher from './SortableQuestionSwitcher';
import PollInformationForm from '../pollInformationForm/PollInformationForm';
import QuestionPicker from './QuestionPicker';
import PollLibraryModal from '../polLibraryModal/PollLibraryModal';
import ConditionalLogicUtil from '../../../../common/ConditionalLogicUtil';

function QuestionContainerFn(props) {
  const { Store, openPollPreview, allowDrag, updateLastItemScrollPosition, openReactionsModal } = props;

  const questions = Store.poll?.questions || [];

  return (
    <div className='c-switchable-list c-switchable-list--polls'>
      {questions.map((question, index) => (
        <SortableQuestionSwitcher
          key={question._id}
          index={index}
          orderNumber={index + 1}
          classModifier='c-switchable--reorder'
          question={question}
          openPollPreview={openPollPreview}
          disabled={!allowDrag}
          isLastItem={index === questions.length - 1}
          updateLastItemScrollPosition={updateLastItemScrollPosition}
          openReactionsModal={openReactionsModal}
        />
      ))}
    </div>
  );
}

const SortableList = SortableContainer((props) => {
  return <QuestionContainer {...props} />;
});

function PollEditFn(props) {
  const { Store, match, history, ConfigStore, ProjectStore, HelpStore } = props;

  const [isPickerVisible, setPickerVisible] = useState(false);
  const [isModalPreviewVisible, setModalPreviewVisible] = useState(false);
  const [isConditionalLogicModalVisible, setIsConditionalLogicModalVisible] = useState(false);
  const [questionId, setQuestionId] = useState('');
  const [isModalLibraryVisible, setModalLibraryVisible] = useState(false);
  const [allowDrag, setAllowDrag] = useState(true);

  const [lastItemScrollPosition, setLastItemScrollPosition] = useState(0);
  const [scrollReposition, setScrollReposition] = useState(false);
  const [currentScrollPosition, setCurrentScrollPosition] = useState(0);

  const [graphData, setGraphData] = useState();

  const clientEnvironment = ConfigStore.config.clientPath;
  const language = ProjectStore.language;

  useEffect(() => {
    if (!Store.annotateReactionsModalOpen && currentScrollPosition > 0) window.scrollTo(0, currentScrollPosition);
  }, [Store.annotateReactionsModalOpen, currentScrollPosition]);

  useEffect(() => {
    const preparedGraph = prepareGraph(Store.pollQuestions);
    setGraphData({ graph: preparedGraph.graph, isCyclic: preparedGraph.cyclicGraph });
  }, [Store.pollQuestions]);

  useEffect(() => {
    // Clear previous poll data before loading and displaying currently selected poll
    Store.poll = null;

    Store.load(match.params.id, match.params.pollId);
  }, [Store, ProjectStore.project, match.params.id, match.params.pollId]);

  useEffect(() => {
    if (!isModalLibraryVisible && scrollReposition) {
      window.scrollTo(0, lastItemScrollPosition);
      setScrollReposition(false);
    }
  }, [isModalLibraryVisible, lastItemScrollPosition, scrollReposition]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setAllowDrag(false);
    const newOrder = arrayMoveImmutable(Store.poll.questions, oldIndex, newIndex);
    Store.reorder(newOrder).then(() => {
      setAllowDrag(true);
    });
  };

  function closeHelpSidebar() {
    HelpStore.closeHelp();
  }

  // eslint-disable-next-line no-shadow
  const openPollPreview = (questionId) => {
    setQuestionId(questionId);
    setModalPreviewVisible(true);
    closeHelpSidebar();
  };

  function getIframeSrc() {
    const src = `${clientEnvironment}/${Store.projectSlug}/surveys/${
      match.params.pollId
    }?isPreview=true&language=${language}${questionId ? `&question=${questionId}` : ''}`;
    return src;
  }

  function handleOpenModal() {
    setModalLibraryVisible(true);
    closeHelpSidebar();
  }

  function handleCloseModal() {
    setModalLibraryVisible(false);
    setScrollReposition(true);
  }

  function handleOpenReactionsModal() {
    setCurrentScrollPosition(window.pageYOffset);

    Store.annotateReactionsModalOpen = true;
  }

  function handleCloseReactionsModal() {
    Store.annotateReactionsModalOpen = false;
  }

  function openConditionalLogicModal() {
    setIsConditionalLogicModalVisible(true);
    closeHelpSidebar();
  }

  function closeConditionalLogicModal() {
    setIsConditionalLogicModalVisible(false);
  }

  return (
    <div className='l-main'>
      <ActionBar
        label='Edit survey'
        hasButton={false}
        helpKey={HELP.PROJECT.EDIT_PROJECT.SURVEY.PAGE}
        isTextOnlyHelp={true}
      />
      <h2 className='c-heading-section'>Survey information</h2>
      <PollInformationForm
        projectId={match.params.id}
        pollId={match.params.pollId}
        history={history}
        openPollPreview={openPollPreview}
      />
      <h2 className='c-heading-section'>Survey content</h2>
      {graphData?.graph && ConditionalLogicUtil.hasSurveyConditionalLogic(Store.pollQuestions) && (
        <ActionBanner
          title={graphData.isCyclic ? 'Survey Loop Detected' : 'Conditional Logic Active'}
          fieldClass={graphData.isCyclic ? 'is-warning-strong' : 'is-success'}
          description={
            graphData.isCyclic
              ? 'We detected a loop in your survey. Use Survey Visualisation to learn more about the issue.'
              : 'Now that you have added your first conditional logic argument in this survey, you can see a visualisation of it if you click on the button below.'
          }
          actionLabel='Visualise Survey'
          onAction={openConditionalLogicModal}
        />
      )}
      <SortableList
        useDragHandle={true}
        onSortEnd={onSortEnd}
        openPollPreview={openPollPreview}
        allowDrag={allowDrag}
        updateLastItemScrollPosition={(value) => setLastItemScrollPosition(value)}
        openReactionsModal={handleOpenReactionsModal}
      />
      {!isPickerVisible && (
        <div className='c-field c-field--action l-form__item'>
          <div className='c-field__group'>
            <React.Fragment>
              <button className='o-button o-button--primary' type='button' onClick={() => setPickerVisible(true)}>
                <span className='o-label'>Add</span>
              </button>
              <button className='o-button o-button--alternate' type='button' onClick={handleOpenModal}>
                <span className='o-label'>Add from library</span>
              </button>
            </React.Fragment>
          </div>
        </div>
      )}
      {isPickerVisible && (
        <QuestionPicker
          createQuestion={Store.createQuestion}
          setPickerVisible={setPickerVisible}
          isQQ={false}
          isDemographic={false}
        />
      )}
      {isModalPreviewVisible && (
        <PreviewModal closeModal={() => setModalPreviewVisible(false)} iframeSrc={getIframeSrc()} />
      )}
      {isModalLibraryVisible && (
        <PollLibraryModal closeModal={handleCloseModal} projectId={match.params.id} pollId={match.params.pollId} />
      )}
      {Store.annotateReactionsModalOpen && <AnnotateReactionModal closeModal={handleCloseReactionsModal} />}
      {isConditionalLogicModalVisible && (
        <ConditionalLogicModal closeModal={closeConditionalLogicModal} graphData={graphData?.graph} />
      )}
    </div>
  );
}
const QuestionContainer = inject((root) => ({ Store: root.RootStore.pollEditStore }))(observer(QuestionContainerFn));

export default inject((root) => ({
  Store: root.RootStore.pollEditStore,
  ConfigStore: root.RootStore.configStore,
  ProjectStore: root.RootStore.projectStore,
  HelpStore: root.RootStore.helpStore,
}))(observer(PollEditFn));

PollEditFn.propTypes = {
  ConfigStore: PropTypes.object.isRequired,
  Store: PropTypes.object.isRequired,
  HelpStore: PropTypes.object.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
};

QuestionContainerFn.propTypes = {
  Store: PropTypes.object.isRequired,
};
