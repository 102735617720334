import React, { useState, useEffect, createContext } from 'react';
import PropTypes from 'prop-types';
import html2canvas from 'html2canvas';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import CheckboxToggleControl from 'common/components/checkboxToggleControl/CheckboxToggleControl';
import { HELP } from 'shared/engage/engage-help-enums';
import ActionBar from '../../../../common/components/actionBar/ActionBar';
// eslint-disable-next-line import/no-cycle
import QuestionReportSwitcher from './QuestionReportSwitcher';
import PollSegmentation from './PollSegmentation';
import SvgIcon from '../../../../common/components/SvgIcon';
import PollReportFeedbackModal from '../../../../common/components/pollReportFeedbackModal/PollReportFeedbackModal';
import RangePickerInputField from '../../../../common/components/form-elements/datePicker/SimpleRangePickerInputField';
import StatGraphBlock from '../dashboard/StatGraphBlock';
import ReportUtils from '../../../../common/ReportUtils';

export const PollReportContext = createContext();

function PollsReport(props) {
  const { Store, PollsListStore, GeneralStore, ToastrStore, HelpStore, match } = props;

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [selectedPollId, setSelectedPollId] = useState();
  const [exportCounter, setExportCounter] = useState(1);
  const [currentScrollPosition, setCurrentScrollPosition] = useState(0);

  const [questionReferences, setQuestionReferences] = useState([]);
  const [updateEnabled, setUpdateEnabled] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState();
  const [showLanguages, setShowLanguages] = useState(false);

  const projectId = match.params.id;
  const polls = PollsListStore ? PollsListStore.polls : [];
  const report = Store ? Store.pollsReport : {};
  const languageStats = ReportUtils.prepareLanguageStats(report?.languageStats);
  // eslint-disable-next-line no-nested-ternary
  const pollName = Store ? (Store.pollsReport && Store.pollsReport.meta ? Store.pollsReport.meta.pollName : '') : '';
  // eslint-disable-next-line no-use-before-define
  const sortedStorePolls = sortPolls(polls);
  const segmentationFilterQuestions = Store ? Store.segmentationFilterQuestions : [];
  const demographicFilterQuestions = Store ? Store.demographicFilterQuestions : [];
  const pollFilterQuestions = Store ? Store.pollFilterQuestions : [];

  const abbrBD = { title: 'Data from Built-ID', label: 'BD' };

  useEffect(() => {
    if (!Store.feedbackModalOpen && currentScrollPosition > 0) window.scrollTo(0, currentScrollPosition);
  }, [Store.feedbackModalOpen, currentScrollPosition]);

  // eslint-disable-next-line no-shadow
  function sortPolls(polls) {
    // doing toJS to perform sort on a copy - otherwise
    return toJS(polls).sort((a, b) => {
      const x = a.name ? a.name.toLowerCase() : '';
      const y = b.name ? b.name.toLowerCase() : '';
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
  }

  useEffect(() => {
    if (Store && PollsListStore && projectId) {
      Store.reset(projectId);

      // PollsListStore.load(projectId)
      // then pre-select the first item (poll)
      PollsListStore.load(projectId).then((result) => {
        if (PollsListStore.polls[0]) {
          // handling initial landing - sort polls and pick first active one
          const sortedPolls = sortPolls(PollsListStore.polls);
          const firstActivePoll = sortedPolls.find((item) => item.status === 'active');

          let pollId;
          // Load initial report
          if (firstActivePoll) {
            // Show report for fist active poll
            pollId = firstActivePoll._id;
          } else if (sortedPolls[0]) {
            // Fall back to first poll if one exists
            pollId = sortedPolls[0]._id;
          }
          setSelectedPollId(pollId);
          Store.loadPollFilterData(projectId, pollId);
          Store.loadPollsReport(projectId, pollId);
        }
      });
    }
  }, [Store, PollsListStore, projectId]);

  function rangeChangeHandler(rangeFrom, rangeTo) {
    setFromDate(rangeFrom);
    setToDate(rangeTo);

    Store.loadPollFilterData(projectId, selectedPollId, rangeFrom, rangeTo);
  }

  async function createSnapshots() {
    setUpdateEnabled(false);
    setExportCounter(1);
    GeneralStore.isSnapshotting = true;
    setTimeout(async () => {
      try {
        const exportSet = [];
        report.questions.forEach((question) => {
          const matchingRef = questionReferences.find((refer) => refer.id === question.questionId);
          if (matchingRef) exportSet.push(matchingRef);
        });
        for (let i = 0; i < exportSet.length; i++) {
          // eslint-disable-next-line no-await-in-loop, no-use-before-define
          await createSnapshot(exportSet[i].containerReference, report.meta.pollName, i);
        }
      } catch (error) {
        ToastrStore.error('Exporting survey report failed!', null, error);
      } finally {
        setUpdateEnabled(true);
        GeneralStore.isSnapshotting = false;
      }
    }, 1000);
  }

  async function createSnapshot(reference, name, index) {
    setExportCounter(index + 1);
    return html2canvas(reference, {
      useCORS: true,
      scrollX: -8,
      scrollY: -window.scrollY,
      removeContainer: true,
    })
      .then((canvas) => {
        const a = document.createElement('a');
        a.href = canvas.toDataURL('image/png');
        a.download = `${name}-${index + 1}.png`;
        a.click();
      })
      .catch();
  }

  function onUpdateHandler(filter) {
    setQuestionReferences([]);
    setSelectedFilter(filter);
  }

  function addExportReference(newReference) {
    const existingReference = questionReferences.find((reference) => reference.id === newReference.id);
    if (!existingReference) {
      questionReferences.push({ id: newReference.id, containerReference: newReference.containerReference });
    } else {
      existingReference.containerReference = newReference.containerReference;
    }
  }

  function handleOpenFeedbackModal() {
    setCurrentScrollPosition(window.pageYOffset);

    Store.feedbackModalOpen = true;
    HelpStore.closeHelp();
  }

  function handleCloseReactionsModal() {
    Store.feedbackModalOpen = false;
  }

  function pollSelectionChangeHandler(pollId) {
    Store.feedbackModalOpen = false;
    setSelectedPollId(pollId);
    setSelectedFilter(null);
    Store.loadPollFilterData(projectId, pollId, fromDate, toDate);
  }

  function toggleShowLanguages() {
    setShowLanguages(!showLanguages);
  }

  return (
    <div className='l-main'>
      <ActionBar
        label='Surveys'
        hasButton={false}
        helpKey={HELP.PROJECT.REPORTING.SURVEYS.PAGE}
        isTextOnlyHelp={true}
      />
      <RangePickerInputField
        startDate={fromDate}
        endDate={toDate}
        onRangeChange={rangeChangeHandler}
        fieldClass='l-form__item--l'
        showPredefinedRanges={true}
      />
      <PollSegmentation
        polls={sortedStorePolls}
        segmentationQuestions={segmentationFilterQuestions}
        demographicQuestions={demographicFilterQuestions}
        pollQuestions={pollFilterQuestions}
        fromDate={fromDate}
        toDate={toDate}
        onUpdate={onUpdateHandler}
        onPollSelectionChange={pollSelectionChangeHandler}
        updateEnabled={updateEnabled}
        selectedPollId={selectedPollId}
      />
      {languageStats.length > 1 && (
        <div className='c-report-overview'>
          <div className='c-report-overview__snapshot'>
            <div className={`c-report-overview__body ${showLanguages ? 'is-open' : 'is-closed'}`}>
              <h2 className='c-heading-section c-heading-section--separator'>
                <span>Languages used in this survey</span>
                <button className='c-toggle-button' type='button' onClick={toggleShowLanguages}>
                  <span className='o-label'>Toggle</span>
                  <SvgIcon icon='angle-down' />
                </button>
              </h2>
              {showLanguages && (
                <div className='c-stat-list l-stat-list'>
                  <StatGraphBlock
                    title={pollName}
                    icon='globe'
                    abbr={abbrBD}
                    stats={languageStats}
                    wrapperClass='l-stat--l'
                    showCount={true}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className='c-poll-preview'>
        <div className='c-poll-preview__actions'>
          <div className='c-poll-preview__label'>{`${report.questions.length} questions`}</div>
          <button
            className={`o-button o-button--naked o-button--s c-poll-preview__snapshot o-tooltip o-tooltip--button o-tooltip--bottom-center ${
              GeneralStore.isSnapshotting ? 'is-disabled' : ''
            }`}
            onClick={createSnapshots}
            data-testid='snapshot-action-button'
            disabled={GeneralStore.isSnapshotting}
          >
            <span className='o-label'>
              {/* eslint-disable-next-line no-nested-ternary */}
              {GeneralStore.isSnapshotting
                ? GeneralStore.isSnapshottingSingleQuestion
                  ? 'Generating snapshot'
                  : `Generating ${exportCounter} of ${report.questions.length}`
                : 'Capture snapshots'}
            </span>
            {!GeneralStore.isSnapshotting && <SvgIcon icon='image' />}
            <span className='o-tooltip__description'>
              Generate an image of each question and fact. Images will begin downloading once the capture is complete.
            </span>
          </button>
        </div>

        <div className='c-poll-preview__items'>
          {report.questions.map((question) => {
            return (
              <PollReportContext.Provider
                key={question.questionId}
                value={{
                  addReference: (reference) => addExportReference(reference),
                }}
              >
                <QuestionReportSwitcher
                  key={question._id}
                  question={question}
                  fromDate={fromDate}
                  toDate={toDate}
                  pollName={pollName}
                  onOpenFeedbackModal={handleOpenFeedbackModal}
                />
              </PollReportContext.Provider>
            );
          })}
        </div>

        {Store.feedbackModalOpen && (
          <PollReportFeedbackModal
            closeModal={handleCloseReactionsModal}
            projectId={projectId}
            surveyFilters={{ fromDate, toDate, ...selectedFilter }}
          />
        )}
      </div>
    </div>
  );
}

PollsReport.propTypes = {
  Store: PropTypes.object.isRequired,
  PollsListStore: PropTypes.object.isRequired,
  GeneralStore: PropTypes.object.isRequired,
  ToastrStore: PropTypes.object.isRequired,
};

export default inject((root) => ({
  Store: root.RootStore.pollsReportStore,
  PollsListStore: root.RootStore.pollsListStore,
  GeneralStore: root.RootStore.generalStore,
  ToastrStore: root.RootStore.toastrStore,
  HelpStore: root.RootStore.helpStore,
}))(observer(PollsReport));
