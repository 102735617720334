import React from 'react';
import { inject, observer } from 'mobx-react';

import SvgIcon from '../../../common/components/SvgIcon';
// import { ENGAGE_LANGUAGES } from '../../../common/constants/GeneralConstants';
import ActionBar from '../../../common/components/actionBar/ActionBar';
import TranslationInstructions from '../../../common/components/translationInstructions/TranslationInstructions';

const ENGAGE_LANGUAGES = require('../../../shared/engage/engage-languages')().engageLanguages();

function Warning() {
  return (
    <div className='l-form__item'>
      <div className='c-message c-message--icon is-warning'>
        <SvgIcon icon='triangle' />
        <h3 className='c-message__title'>Language change detected</h3>
        <p className='c-message__description'>
          Changing the list of supported languages for your project will affect your project globally. When language is
          removed, all translated content (project information, news, polls etc.) for that language is deleted.
        </p>
      </div>
    </div>
  );
}

function Languages(props) {
  const { languages } = props.LanguageStore;
  const allLanguages = ENGAGE_LANGUAGES.filter((lang) => lang.languageCode !== 'en' && lang.available);
  // console.log('allLanguages', allLanguages);

  const onChange = (event) => {
    // console.log('onChange', event.target, event.target.value);
    const selected = props.LanguageStore.languages;
    let tmp = [];

    if (event.target.checked) {
      tmp = [...selected, event.target.value];
    } else {
      tmp = selected.filter((v) => v !== event.target.value);
    }
    // eslint-disable-next-line no-param-reassign
    props.LanguageStore.languages = tmp;
  };

  const onSubmit = (event) => {
    event.preventDefault();
    // console.log(event);
    props.LanguageStore.saveLanguages();
  };

  return (
    <div className='l-main'>
      <ActionBar label='Languages' hasButton={false} />

      {/* <div className='c-message is-warning-strong'> */}
      {/* <div className='c-message c-message--icon c-message--highlight is-warning-strong is-dismissable'>
        <h3 className='c-message__title'>How to translate your content?</h3>
        <p className='c-message__description'>
          Please note that if you don&#180;t translate your original content (like text or images), values from original
          language will be used instead. Before translating, you can always check for values in the original language by
          hovering the globe icon (
          <span className='no-break'>
            <SvgIcon icon='globe' />
          </span>
          ) next to label.
        </p>
      </div> */}
      <TranslationInstructions canClose={false} />

      <form className='c-fields l-form'>
        <div className='c-field l-form__item'>
          <label className='c-field__label'>Select language</label>
          <div className='c-field__group'>
            <div className='o-checkbox'>
              <input
                name='language'
                type='checkbox'
                id='checkbox-english'
                value='en'
                checked={true}
                disabled={true}
                readOnly={true}
              />
              <label htmlFor='checkbox-english'>
                English
                <SvgIcon icon='check' />
              </label>
            </div>
            {allLanguages.map((lang) => (
              <div key={lang.languageCode} className='o-checkbox'>
                <input
                  name='language'
                  type='checkbox'
                  id={`checkbox-${lang.languageCode}`}
                  value={lang.languageCode}
                  checked={languages.some((_lang) => _lang === lang.languageCode)}
                  onChange={onChange}
                />
                <label htmlFor={`checkbox-${lang.languageCode}`}>
                  {lang.languageTitleEN}
                  <SvgIcon icon='check' />
                </label>
              </div>
            ))}
            <p className='c-field__description'>
              All content needs to be translated for activated languages. English will be used as a fallback.
            </p>
          </div>
        </div>
        <Warning />
        <div className='c-field l-form__item'>
          <div className='c-field__group'>
            <button className='o-button o-button--primary' onClick={onSubmit}>
              <span className='o-label'>Update</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default inject((root) => ({ LanguageStore: root.RootStore.languageStore }))(observer(Languages));
