import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import debounce from 'lodash.debounce';
import { QUESTION_TYPES } from 'common/constants/QuestionConstants';
import { DISABLE_ANNOTATE_IMAGE_QUESTION } from 'shared/engage/client-constants';
import { openModal } from '../PollUtils';
import ModalCloseButton from '../../../../common/components/buttons/ModalCloseButton';
import SvgIcon from '../../../../common/components/SvgIcon';
import PROTOCOL_ENUMS from '../../../../shared/engage/engage-protocolEnum';
import SearchBar from '../../../../common/components/searchBar/SearchBar';
import PollLibraryOptions from './PollLibraryOptions';
import PollLibraryItems from './PollLibraryItems';
import { QuestionMetadataEnums } from '../../../../shared/engage';
import SimpleCheckboxGroupInputField from '../../../../common/components/form-elements/checkboxGroupInputField/SimpleCheckboxGroupInputField';

const DISPLAY_BLOCK_SIZE = 30;
const MAX_LOADED_ITEMS = PROTOCOL_ENUMS().TEMPLATE_SEARCH_MAX_QUESTIONS_COUNT;
function PollLibraryModalFn(props) {
  const { closeModal, PollLibraryStore, pollId, projectId } = props;

  const [searchWord, setSearchWord] = useState('');
  const [keywords, setKeywords] = useState([]);
  const [suggestedKeywords, setSuggestedKeywords] = useState(PollLibraryStore.suggestedKeywords);

  const [selectedThemes, setSelectedThemes] = useState([]);

  const [profileValue, setProfileValue] = useState('');
  const [projectsValue, setProjectsValue] = useState('');
  const [pollsValue, setPollsValue] = useState('');

  const [hideCheckboxes, setHideCheckboxes] = useState(true);
  const [checkboxValues, setCheckboxValues] = useState({});

  const [isOptionsOpened, setIsOptionsOpened] = useState(false);

  const [questionsForDisplay, setQuestionsForDisplay] = useState([]);
  const [displayedQuestionBlocksCount, setDisplayedQuestionBlocks] = useState(1);
  const [questions, setQuestions] = useState([]);

  // eslint-disable-next-line
  const delayedGetSuggestedKeywords = useCallback(
    debounce((value) => PollLibraryStore.getSuggestedKeywords(value), 300),
    [],
  );

  useEffect(() => {
    return openModal(closeModal);
  }, [closeModal]);

  useEffect(() => {
    setSuggestedKeywords(PollLibraryStore.suggestedKeywords);
  }, [PollLibraryStore.suggestedKeywords]);

  useEffect(() => {
    if (PollLibraryStore.profiles.length === 1) setProfileValue(PollLibraryStore.profiles[0]._id);
  }, [PollLibraryStore.profiles]);

  useEffect(() => {
    const questionTypes = hideCheckboxes
      ? []
      : Object.entries(checkboxValues)
          .filter((item) => item[1] === true)
          .map((item) => item[0]);
    const data = {
      query: keywords,
      questionTypes,
      requestOriginProjectId: projectId,
      requestOriginPollId: pollId,
    };
    if (profileValue) {
      data.profiles = [profileValue];
    }
    if (projectsValue) {
      data.projects = [projectsValue];
    }
    if (pollsValue) {
      data.polls = [pollsValue];
    }
    if (selectedThemes) {
      data.themes = selectedThemes;
    }
    PollLibraryStore.getQuestions(data);
  }, [
    PollLibraryStore,
    keywords,
    checkboxValues,
    hideCheckboxes,
    profileValue,
    projectsValue,
    pollsValue,
    projectId,
    pollId,
    selectedThemes,
  ]);

  useEffect(() => {
    if (!PollLibraryStore.questions) return;

    /* 
      - Filtering by 'pollId' is done due to issue 1194 [https://github.com/sdesregistry/IH-Engage/issues/1194]
      - segmentation questions are missing pollId which is required for adding poll library questions

      - Filtering by question type is done due to issue 2246 [https://github.com/sdesregistry/IH-Engage/issues/2246]
      - adding annotate image questions is disabled (DISABLE_ANNOTATE_IMAGE_QUESTION flag)
    */
    let filteredQuestions;
    if (DISABLE_ANNOTATE_IMAGE_QUESTION) {
      filteredQuestions = PollLibraryStore.questions.filter(
        (question) => question.pollId && question.questionType !== QUESTION_TYPES.ANNOTATION_IMAGE,
      );
    } else {
      filteredQuestions = PollLibraryStore.questions.filter((question) => question.pollId);
    }

    setQuestions(filteredQuestions);
    setDisplayedQuestionBlocks(1);
    if (filteredQuestions.length > DISPLAY_BLOCK_SIZE) {
      setQuestionsForDisplay(filteredQuestions.slice(0, DISPLAY_BLOCK_SIZE));
    } else {
      setQuestionsForDisplay(filteredQuestions);
    }
  }, [PollLibraryStore.questions]);

  const onSearchEnter = (event) => {
    if (event.key === 'Enter') {
      setKeywords(keywords.concat(event.target.value));
      setSearchWord('');
    }
  };

  function onClearSearchbar() {
    setSearchWord('');
    setSuggestedKeywords([]);
  }

  function onSuggestionSelected(selectedKeyword) {
    setKeywords(keywords.concat(selectedKeyword));
    setSearchWord('');
  }

  const onSearchInputChanged = (event) => {
    const value = event.target.value;
    setSearchWord(value);

    if (value && value.length > 2) {
      delayedGetSuggestedKeywords(value);
    } else if (suggestedKeywords.length > 0) {
      setSuggestedKeywords([]);
    }
  };

  function showMoreHandler() {
    const numberOfBlocksToDisplay = displayedQuestionBlocksCount + 1;
    setDisplayedQuestionBlocks(numberOfBlocksToDisplay);
    setQuestionsForDisplay(questions.slice(0, numberOfBlocksToDisplay * DISPLAY_BLOCK_SIZE));
  }

  function prepareThemes(source) {
    return source ? [...source.map((theme) => [theme, theme])] : [];
  }

  function themeChangeHandler(event) {
    if (event.target.checked) {
      setSelectedThemes([...selectedThemes, event.target.value]);
    } else {
      selectedThemes.splice(selectedThemes.indexOf(event.target.value), 1);
      setSelectedThemes([...selectedThemes]);
    }
  }

  function clearThemeSelectionHandler(source) {
    setSelectedThemes([]);
  }

  return (
    <div className='c-modal c-modal--full-screen' style={{ display: 'block' }}>
      <div className='c-modal__container'>
        <div className='c-modal__header'>
          <h2 className='c-modal__title'>Question Library</h2>
          <ModalCloseButton closeModal={closeModal} />
        </div>
        <div className='c-modal__content'>
          <div className={`c-library ${isOptionsOpened ? 'is-options-opened' : ''}`}>
            <PollLibraryOptions
              checkboxValues={checkboxValues}
              hideCheckboxes={hideCheckboxes}
              pollsValue={pollsValue}
              profileValue={profileValue}
              projectsValue={projectsValue}
              setProfileValue={setProfileValue}
              setProjectsValue={setProjectsValue}
              setPollsValue={setPollsValue}
              setHideCheckboxes={setHideCheckboxes}
              setCheckboxValues={setCheckboxValues}
            />
            <div className='c-library__content is-scrollable'>
              <button className='c-library__toggle' onClick={() => setIsOptionsOpened(!isOptionsOpened)}>
                <span className='o-label'>Toggle options</span>
                <SvgIcon icon='cog' />
              </button>
              <div className='c-library__search c-fields l-fields'>
                <SearchBar
                  value={searchWord}
                  onChange={onSearchInputChanged}
                  onClear={onClearSearchbar}
                  onKeyPress={onSearchEnter}
                  onSuggestionSelect={onSuggestionSelected}
                  onKeywordRemove={(itemToRemove) => setKeywords(keywords.filter((item) => item !== itemToRemove))}
                  keywords={keywords}
                  suggestedKeywords={suggestedKeywords}
                  placeholder='Search questions titles and choices'
                />
                <SimpleCheckboxGroupInputField
                  fieldName='themesRadioGroup'
                  id='themesRadioGroup-data-group'
                  choices={prepareThemes(QuestionMetadataEnums().THEMES)}
                  label='Select theme'
                  horizontalLayout={true}
                  inputWrapperClass='o-checkbox--s o-checkbox--button'
                  input={{ value: selectedThemes, onChange: themeChangeHandler }}
                  type='checkbox'
                  onClearSelection={clearThemeSelectionHandler}
                />
              </div>
              {questions.length >= MAX_LOADED_ITEMS ? (
                <div className='c-library__message'>
                  <div className='c-message c-message--icon is-note'>
                    <SvgIcon icon='bell' />
                    <p className='c-message__description'>
                      {`${MAX_LOADED_ITEMS} results loaded of many. `}
                      <strong>Refine your search to get better results.</strong>
                    </p>
                  </div>
                </div>
              ) : null}
              <PollLibraryItems questions={questionsForDisplay} isLoading={PollLibraryStore.isLoading} />

              {DISPLAY_BLOCK_SIZE * displayedQuestionBlocksCount < questions.length ? (
                <div className='c-library__load-more'>
                  <button className='o-button o-button--primary o-button--s' type='button' onClick={showMoreHandler}>
                    <span className='o-label'>{`Show next ${DISPLAY_BLOCK_SIZE}`}</span>
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default inject((root) => ({ PollLibraryStore: root.RootStore.pollLibraryStore }))(observer(PollLibraryModalFn));

PollLibraryModalFn.propTypes = {
  closeModal: PropTypes.func.isRequired,
  PollLibraryStore: PropTypes.object.isRequired,
};
