import React, { useState, useEffect } from 'react';
import { Form } from 'react-final-form';
import { inject, observer } from 'mobx-react';
import { SAMPLE_URL, validateUrl } from 'common/UrlUtils';
import EngageTextInputField from '../../../common/components/form-elements/textInputField/TextInputField';
import SwitchableButtonBar from '../../../common/components/switchableButtonBar/SwitchableButtonBar';
import SlimFileUpload from '../../../common/components/form-elements/fileUpload/SlimFileUpload';
import { IMAGE_FORMATS } from '../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import EngageMarkdownInputField, {
  MARKDOWN_MAX,
  MARKDOWN_MIN_LINK,
} from '../../../common/components/form-elements/markdownInputField/MarkdownInputField';
import EngageDatePickerInputField from '../../../common/components/form-elements/datePicker/DatePickerInputField';
import { CheckboxInputField } from '../../../common/components/form-elements';
import displayConfirmationPrompt from '../../../common/PromptUtil';
import { getFormFieldClass, getFormFieldTranslationTooltip } from '../../../common/TranslationUtils';
import ImageUtils from '../../../common/ImageUtils';

const DESCRIPTION_ACTION_TOOLTIP = {
  label: 'Want to add more text?',
  actionLabel: 'Add details',
  icon: 'pen-alt',
};

function NewsForm(props) {
  const { Store, ToastrStore, news, openNewsPreview, history } = props;
  const [showDetails, setShowDetails] = useState(true);
  const [descriptionActionTooltip, setDescriptionActionTooltip] = useState(null);

  useEffect(() => {
    if (news) {
      if (news?.data?._id) {
        if (news.data.newsContent || (!news.data.newsContent && news.defaultData?.newsContent)) {
          setShowDetails(true);
          setDescriptionActionTooltip(null);
        } else {
          setShowDetails(false);
          setDescriptionActionTooltip(DESCRIPTION_ACTION_TOOLTIP);
        }
      }
    } else {
      setShowDetails(false);
      setDescriptionActionTooltip(DESCRIPTION_ACTION_TOOLTIP);
    }
  }, [news]);

  // console.log('POLL EDIT', props);
  // console.log('NEWS', toJS(news));
  const newsId = news?.data ? news.data._id : null;
  const imageId = news?.data ? news.data.resource : null;

  const initialValues = newsId
    ? {
        title: news.data.title,
        description: news.data.description,
        content: news.data.newsContent,
        timestamp: news.data.timestamp ? new Date(news.data.timestamp) : '',
        actionName: news.data.actionName,
        actionUrl: news.data.actionUrl,
        status: news.data.status === 'published',
      }
    : {
        title: '',
        description: '',
        content: '',
        status: false,
        actionName: '',
        actionUrl: '',
      };

  const onSubmit = (values) => {
    // console.log('submit', values);
    // eslint-disable-next-line no-shadow
    const news = {
      title: values.title.trim(),
      description: values.description ? values.description : '',
      newsContent: values.content ? values.content : '',
      timestamp: values.timestamp,
      actionName: values.actionName,
      actionUrl: values.actionUrl,
      status: values.status ? 'published' : 'draft',
    };

    if (newsId) {
      Store.update(newsId, news);
    } else {
      // eslint-disable-next-line no-shadow
      Store.create(news).then((newsId) => props.history.push(`${newsId}/edit-news`));
    }
  };

  const cancel = (event) => {
    event.preventDefault();
    if (newsId) {
      const res = displayConfirmationPrompt(null, null, false, ToastrStore);

      if (res === 1) {
        Store.remove(newsId).then((result) => {
          // Makse sure that user is still on news edit page before redirecting
          if (result && history.location.pathname.includes('/edit-news')) history.push(`../../news`);
        });
      }
    } else {
      history.push(`../news`);
    }
  };

  const uploadImage = (file, progressHandler) => {
    // console.log('ID', newsId, news, news._id);
    return Store.uploadResource(newsId, file, progressHandler);
  };

  const removeImage = (event) => {
    event.preventDefault();
    return Store.removeResource(newsId);
  };

  function onShowDetails() {
    setShowDetails(true);
    setDescriptionActionTooltip(null);
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      render={({ values, form, handleSubmit, submitting, pristine }) => {
        return (
          <form className='c-fields l-form'>
            <EngageTextInputField
              id='input-news-heading'
              placeholder='Heading'
              label='News title'
              maxLength='80'
              fieldDescription=''
              fieldName='title'
              isRequired={true}
              fieldClass={getFormFieldClass(news?.defaultData?.title, null, 'l-form__item')}
              tooltipInLabel={getFormFieldTranslationTooltip(news?.defaultData?.title)}
            />

            <EngageMarkdownInputField
              id='input-news-summary'
              placeholder='Summary text which will be shown on the news page and as the first paragraph of a news item'
              label='Short excerpt'
              maxLength='500'
              fieldDescription=''
              fieldName='description'
              editorConfig={MARKDOWN_MIN_LINK}
              actionTooltip={descriptionActionTooltip ? { ...descriptionActionTooltip, onAction: onShowDetails } : null}
              fieldClass={getFormFieldClass(news?.defaultData?.description)}
              tooltipInLabel={getFormFieldTranslationTooltip(news?.defaultData?.description)}
            />

            {(showDetails || (values.content && values.content.length > 0) || news?.defaultData?.newsContent) && (
              <EngageMarkdownInputField
                id='input-news-content'
                placeholder='News content which will only be displayed on the news item'
                label='Details'
                maxLength='2000'
                fieldDescription=''
                fieldName='content'
                editorConfig={MARKDOWN_MAX}
                fieldClass={getFormFieldClass(news?.defaultData?.newsContent)}
                tooltipInLabel={getFormFieldTranslationTooltip(news?.defaultData?.newsContent)}
              />
            )}

            {newsId && (
              <SlimFileUpload
                imageFormat={IMAGE_FORMATS.PANEL}
                imageId={imageId}
                defaultImageId={news.defaultData.resource}
                id={newsId}
                handleUpload={uploadImage}
                label='Image'
                removeImage={removeImage}
                classModifier={getFormFieldClass(imageId, news.defaultData.resource)}
                tooltipInLabel={getFormFieldTranslationTooltip(null, news.defaultData.resource, IMAGE_FORMATS.PANEL)}
              />
            )}

            <EngageTextInputField
              id={`input-news-action-name-${newsId}`}
              placeholder='Enter action name'
              label='Action name'
              maxLength='30'
              fieldDescription=''
              fieldName='actionName'
              isRequired={false}
              fieldClass={getFormFieldClass(news?.defaultData?.actionName, null, 'l-form__item--m')}
              tooltipInLabel={getFormFieldTranslationTooltip(news?.defaultData?.actionName)}
            />

            <EngageTextInputField
              id={`input-news-action-url-${newsId}`}
              placeholder={SAMPLE_URL}
              label='Action URL'
              fieldDescription=''
              fieldName='actionUrl'
              isRequired={false}
              fieldClass='l-form__item--m'
            />

            <EngageDatePickerInputField
              id='input-news-time'
              placeholder='Select date'
              label='Date'
              fieldDescription=''
              fieldName='timestamp'
              isRequired={false}
              fieldClass='l-form__item--m'
              showTimeSelect={true}
            />
            <CheckboxInputField
              id='checkbox-news-status'
              type='checkbox'
              fieldName='status'
              fieldClass='l-form__item--m'
              label='Status'
              isToggle={true}
              checkedLabel='Published'
              uncheckedLabel='Draft'
              hideLabel={false}
            />

            <SwitchableButtonBar
              updateAction={handleSubmit}
              removeAction={cancel}
              previewAction={openNewsPreview || undefined}
              isNew={!news}
            />
          </form>
        );
      }}
    />
  );
}
export default inject((root) => ({ Store: root.RootStore.newsStore, ToastrStore: root.RootStore.toastrStore }))(
  observer(NewsForm),
);
