import React from 'react';
import PropTypes from 'prop-types';
import QuestionTemplateAction from './QuestionTemplateAction';
import { IMAGE_FORMATS } from '../../../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import ImageUtils from '../../../../../common/ImageUtils';
import QuestionTemplateHeader from './QuestionTemplateHeader';
import MediaItem from '../../../../../common/components/mediaItem/MediaItem';

function ThreeImagePreview(props) {
  const { question } = props;

  const mediaResource = question?.resource?.length > 0 ? question.resource[0] : null;

  const imageId = mediaResource?._id || null;
  const imageUrl = mediaResource?.url || null;
  const imageType = mediaResource?.mediaType || null;

  return (
    <div className='c-library__item'>
      <div className='c-question c-question--image-three c-question--library'>
        <QuestionTemplateHeader question={question} />
        <div className='c-question__content'>
          <div className='c-question-image c-question-image--three'>
            <div className='c-question-image__lead'>
              {mediaResource && (
                <MediaItem
                  type={imageType}
                  mediaId={imageId}
                  mediaUrl={imageUrl}
                  imageClassModifier='l-form__item c-upload--fill'
                  videoClassModifier='c-upload--fill'
                  linkToVideo={true}
                />
              )}
            </div>
            <div className='c-question-image__items'>
              {question.choices.map((choice, idx) => (
                <div key={idx} className='c-question-image__item'>
                  <div className='c-question-image__media'>
                    <img src={ImageUtils.imageUrl(choice.resource, IMAGE_FORMATS.SQUARE.imageType)} alt='' />
                  </div>
                  <h3 className='c-question-image__label'>{choice.choice}</h3>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <QuestionTemplateAction question={question} />
    </div>
  );
}

ThreeImagePreview.propTypes = {
  question: PropTypes.shape({
    questionText: PropTypes.string.isRequired,
    choices: PropTypes.array.isRequired,
    resource: PropTypes.array.isRequired,
  }).isRequired,
};

export default ThreeImagePreview;
