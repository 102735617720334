import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../../../../../common/components/SvgIcon';
import { QUESTION_TYPES } from '../../../../../common/constants/QuestionConstants';

function QuestionTemplateHeader(props) {
  const { question, tagLabel, showTitle = true } = props;
  const { questionType, questionText, metaData = {} } = question;

  function getQuestionTypeObject(type) {
    if (QUESTION_TYPES.properties[type]) return QUESTION_TYPES.properties[type];

    // If question is part of a group
    // eslint-disable-next-line no-restricted-syntax
    for (const item of Object.entries(QUESTION_TYPES.properties)) {
      if (item[1].items) if (item[1].items[type]) return item[1].items[type];
    }

    return {};
  }

  return (
    <div className='c-question__header'>
      <div className='c-question__type'>
        <SvgIcon icon={getQuestionTypeObject(questionType).icon} />
        {getQuestionTypeObject(questionType).label}
        {tagLabel && <span className='o-tag is-invert'>{tagLabel}</span>}
      </div>
      {metaData.expert && (
        <div className='c-question__category'>
          {metaData.expert.expertName ? (
            <span className='o-tooltip o-tooltip--marker o-tooltip--avatar o-tooltip--top-center'>
              <span className='o-tooltip__action'>
                <SvgIcon icon='badge-check' />
              </span>
              <span className='o-tooltip__description' role='tooltip'>
                <img src={metaData.expert.profileImage} alt='' />
                <strong>{metaData.expert.expertName}</strong>
                <br />
                {metaData.expert.description}
              </span>
            </span>
          ) : (
            <span className='o-tooltip o-tooltip--marker o-tooltip--top-center'>
              <span className='o-tooltip__action'>
                <SvgIcon icon='badge-check' />
              </span>
              <span className='o-tooltip__description' role='tooltip'>
                <span>Curated by Give My View team</span>
              </span>
            </span>
          )}
        </div>
      )}
      {showTitle && <h2 className='c-question__title'>{questionText}</h2>}
    </div>
  );
}

QuestionTemplateHeader.propTypes = {
  question: PropTypes.shape({
    questionType: PropTypes.string.isRequired,
    questionText: PropTypes.string,
    metaData: PropTypes.shape({
      expert: PropTypes.object,
    }),
  }).isRequired,
  tagLabel: PropTypes.string,
  showTitle: PropTypes.bool,
};

export default QuestionTemplateHeader;
