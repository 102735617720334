import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

export default function CheckboxToggleControl(props) {
  const { id, initialValue = false, onChangeCallback, labelOn, labelOff = labelOn, classModifier } = props;
  const [isChecked, setIsChecked] = useState(initialValue);

  function onChange(e) {
    setIsChecked(e.target.checked);
    onChangeCallback(e.target.checked);
  }

  return (
    <div className={`o-checkbox o-checkbox--toggle ${classModifier}`} data-testid={`${id}-checkbox-toggle`}>
      <input type='checkbox' id={id} checked={isChecked} onChange={onChange} />
      <label htmlFor={id}>
        {isChecked ? labelOn : labelOff}
        <SvgIcon icon='check' />
      </label>
    </div>
  );
}

CheckboxToggleControl.propTypes = {
  id: PropTypes.string,
  initialValue: PropTypes.bool,
  onChangeCallback: PropTypes.func.isRequired,
  labelOn: PropTypes.string,
  labelOff: PropTypes.string,
  classModifier: PropTypes.string,
};
