import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import svgIcons from '../../shared/design/assets-v2/images/symbol-js';

function SvgIcon(props) {
  const { icon, dataTestId = 'svg-icon', Store, classModifier = '' } = props;

  const iconClass = `o-icon o-icon-${icon} ${classModifier}`;
  const url = `/assets-v2/images/symbol-defs.svg#o-icon-${icon}`;

  function getIconForImageExport(iconType) {
    let svgElement = svgIcons[iconType.replaceAll('-', '_')];
    if (svgElement) {
      svgElement = svgElement.replace('className', `class='o-icon o-icon-${iconType}'`);
      svgElement = svgElement.replace('dataTestId', `data-testid='${dataTestId}'`);
    } else {
      svgElement = svgIcons.default;
    }

    return { __html: svgElement };
  }

  return (
    <>
      {!Store.isSnapshotting ? (
        <svg className={iconClass} data-testid={dataTestId}>
          <use xlinkHref={url} />
        </svg>
      ) : (
        // eslint-disable-next-line react/no-danger
        <div className={`o-icon o-icon--inline o-icon-${icon}`} dangerouslySetInnerHTML={getIconForImageExport(icon)} />
      )}
    </>
  );
}

SvgIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
  Store: PropTypes.object,
  classModifier: PropTypes.string,
};

export default inject((root) => ({
  Store: root.RootStore ? root.RootStore.generalStore : {},
}))(observer(SvgIcon));
