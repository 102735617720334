import React from 'react';
import PropTypes from 'prop-types';
import ReportUtils from './ReportUtils';

import { OUTPUT_PDF, OUTPUT_EXCEL, RAW_DATA_ZIP, PROJECT_OVERALL_EXCEL, POLL_DATA_OPTIONS } from './ReportEnums';

function GenerateReportSubmitButtons(props) {
  const { formValues, outputType, buttonLoading, onGenerateReport, pollsList } = props;
  const { dataGroup, pollsData, pollsDataOption, demographicsData, overviewData } = formValues;

  function getGenerateButtonName() {
    switch (outputType) {
      case OUTPUT_PDF:
        return 'PDF';
      case OUTPUT_EXCEL:
        return dataGroup !== RAW_DATA_ZIP ? 'XLSX' : 'ZIP';
      default:
        return '';
    }
  }

  function disableGenerateButton() {
    // No report sub type is selected (Feedback data, Raw data, Survey data) on spreadsheet report
    const isDisabledNoDataGroup = outputType === OUTPUT_EXCEL && !dataGroup;

    // Nothing is selected to go into report (Overview, Surveys, Demographic) in case of PDF or Spreadsheet survey data report
    const isDisabledNoSurveyOrDemog =
      !pollsData &&
      !demographicsData &&
      !overviewData &&
      (outputType === OUTPUT_PDF || dataGroup === PROJECT_OVERALL_EXCEL);

    const selectedPolls = ReportUtils.getSelectedPolls(formValues, pollsList);

    // No poll is selected when Custom option is selected on polls list
    const isDisabledNoPolls =
      pollsData &&
      !(pollsDataOption === POLL_DATA_OPTIONS.ALL) &&
      pollsDataOption === POLL_DATA_OPTIONS.CUSTOM &&
      selectedPolls.length === 0 &&
      (outputType === OUTPUT_PDF || dataGroup === PROJECT_OVERALL_EXCEL);

    const disableButton = isDisabledNoDataGroup || isDisabledNoSurveyOrDemog || isDisabledNoPolls;
    return disableButton;
  }

  return (
    <div className='c-field c-field--action l-form__item'>
      <div className='c-field__group'>
        <button
          className={`o-button o-button--primary ${disableGenerateButton() ? 'is-disabled' : ''} ${
            buttonLoading ? 'is-loading' : ''
          }`}
          type='button'
          disabled={disableGenerateButton()}
          onClick={() => onGenerateReport()}
        >
          <span className='o-label'>
            Generate <small>{getGenerateButtonName()}</small>
          </span>
        </button>
        <p className='c-field__description'>
          Reports take <strong>several seconds to generate</strong>. We will display a link to download the report after
          it is generated.
        </p>
      </div>
    </div>
  );
}

GenerateReportSubmitButtons.propTypes = {
  formValues: PropTypes.shape({
    dataGroup: PropTypes.string,
    pollsData: PropTypes.bool,
    pollsDataOption: PropTypes.string,
    demographicsData: PropTypes.bool,
    overviewData: PropTypes.bool,
  }).isRequired,
  outputType: PropTypes.string,
  buttonLoading: PropTypes.bool.isRequired,
  onGenerateReport: PropTypes.func.isRequired,
  pollsList: PropTypes.array.isRequired,
};

export default GenerateReportSubmitButtons;
