/* eslint-disable no-param-reassign */
import debounce from 'lodash.debounce';
import { MarkerUtils } from '@googlemaps/markerclusterer';
import GOOGLE_MAP_ENUMS from '../../../shared/engage/engage-google-map-enums';

const KM = 1000;
const M_LABEL = 'm';
const KM_LABEL = 'km';

const google = window.google;
const shapeTypes = GOOGLE_MAP_ENUMS().shapeTypes;
const roadmapOverlayStyle = GOOGLE_MAP_ENUMS().roadmapOverlayStyle;
const satelliteOverlayStyle = GOOGLE_MAP_ENUMS().satelliteOverlayStyle;
const markerStyle = GOOGLE_MAP_ENUMS().markerStyle;
const clusterRenderer = {
  render: ({ count, position }, stats, map) => {
    // change color if this cluster has more markers than the mean cluster
    const color = count > Math.max(10, stats.clusters.markers.mean) ? '#DF543F' : '#0C87E1';
    // create svg literal with fill color
    const svg = `<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" width="50" height="50">
                  <circle cx="120" cy="120" opacity=".8" r="70" />
                  <circle cx="120" cy="120" opacity=".3" r="90" />
                  <circle cx="120" cy="120" opacity=".2" r="110" />
                  <text x="50%" y="50%" style="fill:#fff" text-anchor="middle" font-size="50" dominant-baseline="middle" font-family="roboto,arial,sans-serif">${count}</text>
                  </svg>`;
    const title = `Cluster of ${count} markers`;
    // adjust zIndex to be above other markers
    const zIndex = Number(google.maps.Marker.MAX_ZINDEX) + count;
    if (MarkerUtils.isAdvancedMarkerAvailable(map)) {
      // create cluster SVG element
      const parser = new DOMParser();
      const svgEl = parser.parseFromString(svg, 'image/svg+xml').documentElement;
      svgEl.setAttribute('transform', 'translate(0 25)');
      const clusterOptions = {
        map,
        position,
        zIndex,
        title,
        content: svgEl,
      };
      return new google.maps.marker.AdvancedMarkerElement(clusterOptions);
    }
    const clusterOptions = {
      position,
      zIndex,
      title,
      icon: {
        url: `data:image/svg+xml;base64,${btoa(svg)}`,
        anchor: new google.maps.Point(25, 25),
      },
    };
    return new google.maps.Marker(clusterOptions);
  },
};

function getCustomMapUrl(customMapLayer) {
  if (!customMapLayer) {
    return '';
  }
  return customMapLayer.getUrl().split('&ver')[0];
}

function drawCustomMapLayer(map, customMapUrl, customMapLayer) {
  let newCustomMapLayer = null;

  const existingCustomMapUrl = getCustomMapUrl(customMapLayer);

  if ((customMapUrl && !customMapLayer) || (customMapUrl && customMapLayer && customMapUrl !== existingCustomMapUrl)) {
    // In case custom map URL is added or replaced create custom map layer and add it to the map
    if (customMapLayer) customMapLayer.setMap(null);

    const synthUrl = `${customMapUrl}&ver=${new Date().getTime()}`;
    newCustomMapLayer = new google.maps.KmlLayer(synthUrl, {
      preserveViewport: true,
      map,
    });
  } else if (customMapUrl === '' && customMapLayer) {
    // Clear custom map layer
    customMapLayer.setMap(null);
  }

  return newCustomMapLayer;
}

function setUpDrawingManagerInstance(map, mapData, allowedDrawTools, hasClickableOverlays) {
  const currentOverlayStyle =
    mapData.mapTypeId === google.maps.MapTypeId.ROADMAP || !mapData.mapTypeId
      ? roadmapOverlayStyle
      : satelliteOverlayStyle;

  currentOverlayStyle.clickable = hasClickableOverlays;

  const drawingManager = new google.maps.drawing.DrawingManager({
    drawingMode: shapeTypes.MARKER,
    drawingControl: !mapData.shape,
    drawingControlOptions: {
      position: google.maps.ControlPosition.BOTTOM_CENTER,
      drawingModes: allowedDrawTools,
    },
    markerOptions: markerStyle,
    circleOptions: currentOverlayStyle,
    polygonOptions: currentOverlayStyle,
    rectangleOptions: currentOverlayStyle,
  });
  drawingManager.setMap(map);
  drawingManager.setDrawingMode(null);

  return drawingManager;
}

function setMapBounds(definedArea, newCustomMapLayer, map) {
  if (newCustomMapLayer && !google.maps.event.hasListeners(newCustomMapLayer, 'defaultviewport_changed')) {
    // Wait for custom map to load and then set the final bounds
    google.maps.event.addListener(newCustomMapLayer, 'defaultviewport_changed', (event) => {
      const newLayerBounds = newCustomMapLayer ? newCustomMapLayer.getDefaultViewport() : null;
      const definedAreaBounds = definedArea?.bounds;
      if (newLayerBounds && definedAreaBounds) {
        const newBounds = newLayerBounds.union(definedAreaBounds);
        map.fitBounds(newBounds);
      } else if (newLayerBounds) {
        map.fitBounds(newLayerBounds);
      } else if (definedAreaBounds) {
        map.fitBounds(definedAreaBounds);
      }
    });
  }
}

function setDefinedAreaInteractivity(map, definedArea, customMapUrl) {
  if (!map || !definedArea) return;

  const newOverlayStyle =
    map.getMapTypeId() === google.maps.MapTypeId.ROADMAP ? roadmapOverlayStyle : satelliteOverlayStyle;

  if (
    definedArea.type === shapeTypes.CIRCLE ||
    definedArea.type === shapeTypes.RECTANGLE ||
    definedArea.type === shapeTypes.POLYGON
  ) {
    newOverlayStyle.clickable = customMapUrl === '' || !customMapUrl;
  }

  definedArea.overlay.setOptions(newOverlayStyle);
}

function drawDefinedAreaOverlay(map, mapData, hasClickableOverlays) {
  let shape;
  let bounds;
  const currentOverlayStyle =
    mapData.mapTypeId === google.maps.MapTypeId.ROADMAP || !mapData.mapTypeId
      ? roadmapOverlayStyle
      : satelliteOverlayStyle;

  currentOverlayStyle.clickable = hasClickableOverlays;

  switch (mapData.shape) {
    case shapeTypes.CIRCLE: {
      shape = new google.maps.Circle({
        map,
        center: new google.maps.LatLng(mapData.circle.center.lat, mapData.circle.center.lng),
        radius: mapData.circle.radius,
        ...currentOverlayStyle,
      });

      bounds = shape.getBounds();
      break;
    }
    case shapeTypes.POLYGON: {
      const polygonCoords = [];
      mapData.polygon.path.forEach((coordinate) => {
        polygonCoords.push({ lat: coordinate.lat, lng: coordinate.lng });
      });

      shape = new google.maps.Polygon({
        map,
        paths: polygonCoords,
        ...currentOverlayStyle,
      });

      bounds = new google.maps.LatLngBounds();
      polygonCoords.forEach((coord) => {
        bounds.extend(coord);
      });
      break;
    }
    case shapeTypes.RECTANGLE: {
      const north = mapData.rectangle.bounds.ne.lat;
      const south = mapData.rectangle.bounds.sw.lat;
      const east = mapData.rectangle.bounds.ne.lng;
      const west = mapData.rectangle.bounds.sw.lng;

      shape = new google.maps.Rectangle({
        map,
        bounds: { north, south, east, west },
        ...currentOverlayStyle,
      });

      bounds = shape.getBounds();
      break;
    }
    case shapeTypes.MARKER: {
      shape = new google.maps.Marker({
        map,
        position: mapData.point,
        ...markerStyle,
      });

      map.setCenter(mapData.point);
      if (mapData.zoom) map.setZoom(parseInt(mapData.zoom, 10));

      break;
    }
    default:
      break;
  }

  return { shape, bounds };
}

function setMapPositionAndZoomLevel(map, areaBounds, zoomLevel, memorizeZoomLevel) {
  if (!areaBounds) return;

  if (memorizeZoomLevel && zoomLevel) {
    map.setCenter(areaBounds.getCenter());
    map.setZoom(parseInt(zoomLevel, 10));
  } else {
    map.fitBounds(areaBounds);
  }
}

function getValueInUnits(value, showInMeters) {
  return showInMeters ? Math.round(value) : Math.round(value / 100) / 10;
}

function getBoundsWidthAndHeight(bounds) {
  const sePoint = new google.maps.LatLng(bounds.getSouthWest().lat(), bounds.getNorthEast().lng());
  const width = google.maps.geometry.spherical.computeDistanceBetween(bounds.getSouthWest(), sePoint);
  const height = google.maps.geometry.spherical.computeDistanceBetween(bounds.getNorthEast(), sePoint);

  return { width, height };
}

function determinePolygonDrawnAreaInfo(bounds, setDrawnAreaWidth, setDrawnAreaHeight, setDrawnAreaUnit) {
  const sePoint = new google.maps.LatLng(bounds.getSouthWest().lat(), bounds.getNorthEast().lng());
  const areaWidth = google.maps.geometry.spherical.computeDistanceBetween(bounds.getSouthWest(), sePoint);
  const areaHeight = google.maps.geometry.spherical.computeDistanceBetween(bounds.getNorthEast(), sePoint);

  const showInMeters = areaWidth < KM && areaHeight < KM;
  setDrawnAreaWidth(getValueInUnits(areaWidth, showInMeters));
  setDrawnAreaHeight(getValueInUnits(areaHeight, showInMeters));
  setDrawnAreaUnit(showInMeters ? M_LABEL : KM_LABEL);
}

function determineCircleDrawnAreaInfo(shape, setDrawnAreaRadius, setDrawnAreaUnit) {
  const radius = shape.getRadius();
  const center = shape.getCenter();

  const showInMeters = radius < KM;
  setDrawnAreaRadius(getValueInUnits(radius, showInMeters));
  setDrawnAreaUnit(showInMeters ? M_LABEL : KM_LABEL);
}

function prepareCircleData(shape) {
  const radius = shape.getRadius();
  const center = shape.getCenter();
  const circle = {};
  const bounds = shape.getBounds();
  circle.bounds = {
    ne: { lat: bounds.getNorthEast().lat(), lng: bounds.getNorthEast().lng() },
    sw: { lat: bounds.getSouthWest().lat(), lng: bounds.getSouthWest().lng() },
  };
  circle.center = { lat: center.lat(), lng: center.lng() };
  circle.radius = radius;

  return circle;
}

function prepareRectangleData(bounds) {
  const rectangle = {};
  rectangle.bounds = {
    ne: { lat: bounds.getNorthEast().lat(), lng: bounds.getNorthEast().lng() },
    sw: { lat: bounds.getSouthWest().lat(), lng: bounds.getSouthWest().lng() },
  };

  return rectangle;
}

function preparePolygonData(shape) {
  const polygon = {};
  const polygonPaths = [];
  shape.getPath().forEach((point) => {
    polygonPaths.push({ lat: point.lat(), lng: point.lng() });
  });

  polygon.path = polygonPaths;

  return polygon;
}

function preparePointData(shape) {
  const point = {};
  const position = shape.getPosition();
  point.lat = position.lat();
  point.lng = position.lng();

  return point;
}

function setUpMapInstance(
  id,
  mapOptions,
  mapData,
  mapZoom,
  mapType,
  mapStyle,
  showMapTypeControl,
  showZoomControl,
  showFullscreenControl,
  showScaleControl,
  showStreetViewControl,
) {
  const map = new google.maps.Map(document.getElementById(`map-${id}`), {
    center: new google.maps.LatLng(mapOptions.lat, mapOptions.lng),
    zoom: mapData.shape ? mapZoom : mapOptions.zoom,
    mapTypeId: mapType || google.maps.MapTypeId.ROADMAP,
    disableDefaultUI: true,
    mapTypeControl: showMapTypeControl,
    mapTypeControlOptions: {
      style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
      mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.SATELLITE],
      position: google.maps.ControlPosition.TOP_RIGHT,
    },
    styles: mapStyle,
    zoomControl: showZoomControl,
    zoomControlOptions: {
      position: google.maps.ControlPosition.RIGHT_BOTTOM,
    },
    fullscreenControl: showFullscreenControl,
    scaleControl: showScaleControl,
    streetViewControl: showStreetViewControl,
  });

  return map;
}

function setUpSearchControl(id, map) {
  // Handle search result by centering map to the searched location
  const input = document.getElementById(`${id}-map-search-input`);
  const inputWrapper = document.getElementById(`${id}-map-search-input-wrapper`);

  if (!input) return;

  const searchBox = new google.maps.places.SearchBox(input);
  map.controls[google.maps.ControlPosition.TOP_LEFT].push(inputWrapper);
  // Bias the SearchBox results towards current map's viewport.
  map.addListener('bounds_changed', () => {
    searchBox.setBounds(map.getBounds());
  });

  let markers = [];
  // Listen for the event fired when the user selects a prediction and retrieve
  // more details for that place.
  searchBox.addListener('places_changed', () => {
    const places = searchBox.getPlaces();

    if (places.length === 0) return;

    // Clear out the old markers.
    markers.forEach((marker) => {
      marker.setMap(null);
    });
    markers = [];
    // For each place, get the icon, name and location.
    const bounds = new google.maps.LatLngBounds();
    places.forEach((place) => {
      if (!place.geometry || !place.geometry.location) {
        return;
      }
      const icon = {
        url: place.icon,
        size: new google.maps.Size(71, 71),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(17, 34),
        scaledSize: new google.maps.Size(25, 25),
      };
      // Create a marker for each place.
      markers.push(
        new google.maps.Marker({
          map,
          icon,
          title: place.name,
          position: place.geometry.location,
        }),
      );

      if (place.geometry.viewport) {
        // Only geocodes have viewport.
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
    });
    map.fitBounds(bounds);
  });
}

function attachMapEventHandlers(
  map,
  mapData,
  drawingManager,
  memorizeZoomLevel,
  setMapZoom,
  setMapType,
  handleOverlayEvents,
) {
  // Events dispatched when user changes map type or zoom level
  map.addListener('maptypeid_changed', () => {
    const newMapType = map.getMapTypeId();
    setMapType(newMapType);
    const newOverlayStyle = newMapType === google.maps.MapTypeId.ROADMAP ? roadmapOverlayStyle : satelliteOverlayStyle;
    drawingManager.setOptions({
      circleOptions: newOverlayStyle,
      polygonOptions: newOverlayStyle,
      rectangleOptions: newOverlayStyle,
    });
  });

  map.addListener('zoom_changed', () => {
    if (memorizeZoomLevel) setMapZoom(map.getZoom());
  });
}

function attachDefinedAreaEventHandlers(definedArea, handleOverlayEvents) {
  // Event dispatched when user moves or changes dimensions of defined area on the map
  ['dragend', 'radius_changed', 'mouseup'].forEach((event) => {
    google.maps.event.addListener(definedArea.overlay, event, () =>
      handleOverlayEvents(definedArea.overlay, definedArea.type),
    );
  });

  ['center_changed', 'bounds_changed'].forEach((event) => {
    google.maps.event.addListener(
      definedArea.overlay,
      event,
      debounce(() => {
        handleOverlayEvents(definedArea.overlay, definedArea.type);
      }, 400),
    );
  });
}

function attachDrawingManagerEventHandlers(drawingManager, handleOverlayEvents, setDefinedArea) {
  // Event dispatched when user defines new area on the map
  google.maps.event.addListener(drawingManager, 'overlaycomplete', (event) => {
    const overlay = event.overlay;

    if (event.type === shapeTypes.MARKER) {
      setDefinedArea({ overlay, type: event.type });
    } else if (event.type === shapeTypes.POLYGON) {
      const bounds = new google.maps.LatLngBounds();
      overlay.getPath().forEach((coord) => {
        bounds.extend(coord);
      });
      setDefinedArea({ overlay, type: event.type, bounds });
    } else {
      setDefinedArea({ overlay, type: event.type, bounds: overlay.getBounds() });
    }

    handleOverlayEvents(overlay, event.type);

    drawingManager.setOptions({
      drawingControl: false,
      drawingMode: null,
    });
  });
}

async function getMaxZoomLevel(latLng) {
  let maxZoomLevel = 10;
  try {
    const maxZoomService = new google.maps.MaxZoomService();
    const res = await maxZoomService.getMaxZoomAtLatLng(latLng);
    maxZoomLevel = res.zoom;
  } catch (error) {
    // console.log('error ');
  }
  return maxZoomLevel;
}

export default {
  clusterRenderer,
  getCustomMapUrl,
  drawCustomMapLayer,
  setUpDrawingManagerInstance,
  setMapBounds,
  setDefinedAreaInteractivity,
  drawDefinedAreaOverlay,
  setMapPositionAndZoomLevel,
  getBoundsWidthAndHeight,
  determinePolygonDrawnAreaInfo,
  determineCircleDrawnAreaInfo,
  prepareCircleData,
  prepareRectangleData,
  preparePolygonData,
  preparePointData,
  setUpMapInstance,
  setUpSearchControl,
  attachMapEventHandlers,
  attachDefinedAreaEventHandlers,
  attachDrawingManagerEventHandlers,
  getMaxZoomLevel,
};
