import React from 'react';
import PropTypes from 'prop-types';
import DragHandle from '../../switchableHeader/DragHandle';
import SvgIcon from '../../SvgIcon';

export default function SimpleDraggableTextInputFieldWithAction(props) {
  const {
    id,
    input,
    onAction,
    disableAction,
    label,
    maxLength,
    placeholder,
    type = 'text',
    isEditable,
    isInputEditable,
    choiceIcon,
    inputModifier = '',
  } = props;

  return (
    <div className='c-field c-field--label-hidden c-field--reorder'>
      {isEditable && <DragHandle fieldClass='c-field__handle' />}
      <label className='c-field__label' htmlFor={id} data-testid={`${id}-label`}>
        {label}
      </label>
      <div className='c-field__group'>
        <div className={`o-input o-input o-input-clear ${inputModifier}`}>
          <input
            {...input}
            data-testid={`${id}-input`}
            disabled={!isEditable || !isInputEditable}
            id={id}
            maxLength={maxLength}
            placeholder={placeholder}
            type={type}
          />

          {choiceIcon && <SvgIcon icon={choiceIcon} />}
          {isEditable && (
            <button className='o-button' onClick={onAction} type='button' disabled={disableAction}>
              <SvgIcon icon='trash' />
              <span className='o-label'>Delete</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

SimpleDraggableTextInputFieldWithAction.propTypes = {
  id: PropTypes.string,
  input: PropTypes.object.isRequired,
  onAction: PropTypes.func,
  disableAction: PropTypes.bool,
  label: PropTypes.string,
  maxLength: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  isEditable: PropTypes.bool,
  isInputEditable: PropTypes.bool,
  choiceIcon: PropTypes.string,
  inputModifier: PropTypes.string,
};
