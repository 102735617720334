import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { MARKDOWN_MID } from '../form-elements/markdownInputField/MarkdownConstants';
import SimpleMarkdownInputField from '../form-elements/markdownInputField/SimpleMarkdownInputField';
import { getFormFieldClass, getFormFieldTranslationTooltip } from '../../TranslationUtils';

export const MAX_LENGTH = 120;
function CaptionItem(props) {
  const {
    imageId,
    imageCaption,
    updateCaption,
    deleteCaption,
    editorConfig = MARKDOWN_MID,
    defaultCaption,
    ToastrStore,
  } = props;

  const [caption, setCaption] = useState(imageCaption || '');
  const [selectedTab, setSelectedTab] = useState('write');

  useEffect(() => {
    setCaption(imageCaption || '');
  }, [imageCaption]);

  function updateHandler() {
    if (caption.length > MAX_LENGTH) {
      ToastrStore.error('Entered caption is too long!');
      return;
    }

    updateCaption(caption);
  }

  return (
    <div className='c-upload-caption__edit' data-testid={`${imageId}-caption-item-container`}>
      <SimpleMarkdownInputField
        id={`media-caption-${imageId}`}
        label='Caption'
        input={{ value: caption, onChange: setCaption }}
        editorConfig={editorConfig}
        placeholder='Enter caption'
        maxLength={MAX_LENGTH}
        fieldClass={getFormFieldClass(defaultCaption)}
        tooltipInLabel={getFormFieldTranslationTooltip(defaultCaption)}
      />
      <div className='c-field c-field--action'>
        <div className='c-field__group'>
          <button
            className={`o-button o-button--primary o-button--s ${imageCaption || caption !== '' ? '' : 'is-disabled'}`}
            type='button'
            onClick={updateHandler}
            disabled={!imageCaption && caption === ''}
          >
            <span className='o-label'>{imageCaption ? 'Update' : 'Add caption'}</span>
          </button>
          {imageCaption && (
            <button className='o-button o-button--danger o-button--s' type='button' onClick={() => deleteCaption()}>
              <span className='o-label'>Delete caption</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

CaptionItem.propTypes = {
  imageId: PropTypes.string.isRequired,
  imageCaption: PropTypes.string,
  updateCaption: PropTypes.func.isRequired,
  deleteCaption: PropTypes.func.isRequired,
  editorConfig: PropTypes.object,
  ToastrStore: PropTypes.object.isRequired,
};

export default inject((root) => ({
  ToastrStore: root.RootStore.toastrStore,
}))(observer(CaptionItem));
