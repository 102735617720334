import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../../../../../common/components/SvgIcon';
import DateUtils from '../../../../../common/DateUtils';

export default function GeneratedReportsWorking(props) {
  const { name, timestamp, isError } = props;
  return (
    <div className={`c-report-list__item ${isError ? 'is-error' : 'is-loading'}`}>
      <span className={`c-report-list__state ${isError ? 'is-error' : ''}`}>
        {isError ? <SvgIcon icon='triangle' /> : <span className='o-loading' />}
      </span>
      <h4 className='c-report-list__title'>{name}</h4>
      <div className='c-report-list__meta'>
        {isError ? 'Problem with generating report' : DateUtils.getDateFullTimeString(timestamp)}
      </div>
    </div>
  );
}

GeneratedReportsWorking.propTypes = {
  name: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  isError: PropTypes.bool.isRequired,
};
