import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../../SvgIcon';

export default function AnnotateQuestionControls(props) {
  const { onZoomIn, onZoomOut, onReset } = props;
  return (
    <div className='c-result-annotate__actions'>
      <button className='o-button c-result-annotate__zoom-in' onClick={onZoomIn}>
        <span className='o-button__label'>Zoom in</span>
        <SvgIcon icon='plus' />
      </button>
      <button className='o-button c-result-annotate__zoom-out' onClick={onZoomOut}>
        <span className='o-button__label'>Zoom out</span>
        <SvgIcon icon='minus' />
      </button>
      <button className='o-button c-result-annotate__center' onClick={onReset}>
        <span className='o-button__label'>Center</span>
        <SvgIcon icon='expand' />
      </button>
    </div>
  );
}

AnnotateQuestionControls.propTypes = {
  onZoomIn: PropTypes.func.isRequired,
  onZoomOut: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};
