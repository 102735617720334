import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import FilterList from 'common/components/filterList/FilterList';
import FilterSearch from 'common/components/filterSearch/FilterSearch';
import { ALL, getFilteredProjects } from 'common/FilterUtils';
import ProjectCard from './ProjectCard';
import { CREATE_PROJECT } from '../../../common/constants/RouteConstants';
import Agent from '../../../common/agent';
import SvgIcon from '../../../common/components/SvgIcon';
import ProjectsBar from './ProjectsBar';
import ProjectList from './ProjectsList';
import GettingStarted from './GettingStarted';

function Projects(props) {
  const { ProjectListStore, AuthStore, ClientStore } = props;

  const [allProjects, setAllProjects] = useState([]);

  const [filterState, setFilterState] = useState({ selectedFilterOption: ALL, searchValue: '' });

  const projectList = ProjectListStore.projectList;
  const clientId = AuthStore.clientId;

  useEffect(() => {
    // Reset language to 'english' each time user goes to projects screen
    Agent.setLanguage('en');

    // console.log('HOME effect', AuthStore.clientId);
    // The store will take care of handling empty clientId
    // case of initial load for non-client accounts (ie. AM)
    // AuthStore.clientId && ProjectListStore.loadWithClientId(AuthStore.clientId);
    ProjectListStore.loadWithClientId(AuthStore.clientId).then(() => {
      setAllProjects(ProjectListStore.projectList);
    });

    ClientStore.registerAsVisited(AuthStore.clientId);
  }, [ProjectListStore, AuthStore.clientId, ClientStore]);

  return (
    <div className='l-main' data-testid='projects-wrapper'>
      <ProjectsBar filter={{ filterState, setFilterState }} />

      <ProjectList
        projects={getFilteredProjects(allProjects, filterState.searchValue, filterState.selectedFilterOption)}
      />

      {projectList.length === 0 && clientId ? <GettingStarted /> : null}
    </div>
  );
}

export default inject((root) => ({
  ProjectListStore: root.RootStore.projectListStore,
  AuthStore: root.RootStore.authStore,
  ClientStore: root.RootStore.clientStore,
}))(observer(Projects));
