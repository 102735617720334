/* eslint-disable global-require */
/**
 * Constants moved to shared
 * Here we're only remapping for use in populo to avoid signature change
 */
export const QUESTION_CHARACTER_LIMIT = '80';
export const IMAGE_CHARACTER_LIMIT = '30';
export const QUESTION_GROUPS = require('../../shared/engage').QuestionConstants.QUESTION_GROUPS;
export const QUESTION_TYPES = require('../../shared/engage').QuestionConstants.QUESTION_TYPES;
export const QUESTION_CONDITIONAL = require('../../shared/engage').QuestionConstants.QUESTION_CONDITIONAL;
export const QUESTION_CONDITIONAL_LABELS = require('../../shared/engage').QuestionConstants.QUESTION_CONDITIONAL_LABELS;
