import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../tooltip/Tooltip';
import FieldDescription from '../fieldDescription/FieldDescription';

export default function SimpleColorPickerField(props) {
  const {
    disabled,
    fieldClass,
    fieldDescription,
    id,
    input,
    label,
    maxLength,
    meta = {},
    placeholder,
    showSlugLabel,
    tooltip,
    tooltipInLabel,
  } = props;

  // eslint-disable-next-line no-shadow
  function getContainerClass(meta) {
    const errorClass = meta.error && meta.touched ? 'is-error' : '';
    return `c-field l-form__item ${fieldClass} ${errorClass}`;
  }

  return (
    <div className={getContainerClass(meta)} data-testid={`${id}-wrapper`}>
      <label className='c-field__label' htmlFor={id} data-testid={`${id}-label`}>
        {label}
        {tooltipInLabel && <Tooltip {...tooltipInLabel} />}
      </label>
      <div className='c-field__group'>
        <div className='o-input-color'>
          <input {...input} data-testid={`${id}-color-testid`} disabled={disabled} id={`${id}-color`} type='color' />
          <input
            {...input}
            data-testid={`${id}-text-testid`}
            disabled={disabled}
            id={`${id}-color-hex`}
            maxLength={maxLength}
            placeholder={placeholder}
            type='text'
          />
        </div>
        <FieldDescription
          fieldDescription={fieldDescription}
          meta={meta}
          showSlugLabel={showSlugLabel}
          tooltip={tooltip}
          val={input.value}
        />
      </div>
    </div>
  );
}

SimpleColorPickerField.propTypes = {
  disabled: PropTypes.bool,
  fieldClass: PropTypes.string,
  fieldDescription: PropTypes.string,
  id: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  maxLength: PropTypes.string,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  showSlugLabel: PropTypes.bool,
  tooltip: PropTypes.object,
  tooltipInLabel: PropTypes.object,
};
