/* eslint-disable no-param-reassign */
const FEEDBACK_QUESTION_PERSONAL_INFO_PREFIX = 'mandatoryPersonalInfo';
const FEEDBACK_QUESTION_PERSONAL_INFO_EMAIL_FIELD = 'email';
const FEEDBACK_QUESTION_PERSONAL_INFO_POST_CODE_FIELD = 'postCode';
const FEEDBACK_QUESTION_ALLOW_IMAGE_FIELD = 'allowImages';
const FEEDBACK_QUESTION_COLLECT_EMAIL_ONLY = 'emailOny';
const FEEDBACK_QUESTION_LEAD_IMAGE_TYPE = 'leadImageType';

/**
 * ESC key handler for closing poll, wrapped in useCallback
 * https://reactjs.org/docs/hooks-reference.html#usecallback
 */
const onOverlayKeyDown = (event, closeModal) => {
  if (event.which === 27) {
    closeModal();
  }
};

//
// Mount (and unmount) ESC listener and popup modal class
// Runs once on component mount and once on unmount (hence [])
//
const openModal = (closeModal) => {
  const body = document.getElementById('top');

  if (body) {
    body.classList.add('is-modal-opened');
    window.addEventListener('keydown', (event) => onOverlayKeyDown(event, closeModal));

    // returned function will be called on component unmount
    return () => {
      body.classList.remove('is-modal-opened');
      window.removeEventListener('keydown', (event) => onOverlayKeyDown(event, closeModal));
    };
  }
};

function prepareFeedbackQuestionConfigFormValues(question, formValues) {
  if (!question || !formValues) return;

  let isEmailOptional = false;
  let isPostCodeOptional = false;
  let allowImages = false;
  let collectEmailOnly = false;

  if (question.questionConfiguration && question.questionConfiguration.feedbackConfiguration) {
    isEmailOptional = question.questionConfiguration.feedbackConfiguration.isEmailOptional;
    isPostCodeOptional = question.questionConfiguration.feedbackConfiguration.isPostCodeOptional;
  }

  if (question.questionConfiguration && question.questionConfiguration.allowUserContentUpload)
    allowImages = question.questionConfiguration.allowUserContentUpload.image;

  if (question.questionConfiguration && question.questionConfiguration.collectEmailOnly)
    collectEmailOnly = question.questionConfiguration.collectEmailOnly;

  formValues[
    `${FEEDBACK_QUESTION_PERSONAL_INFO_PREFIX}-${FEEDBACK_QUESTION_PERSONAL_INFO_EMAIL_FIELD}-${question._id}`
  ] = !isEmailOptional;
  formValues[
    `${FEEDBACK_QUESTION_PERSONAL_INFO_PREFIX}-${FEEDBACK_QUESTION_PERSONAL_INFO_POST_CODE_FIELD}-${question._id}`
  ] = !isPostCodeOptional;
  formValues[FEEDBACK_QUESTION_ALLOW_IMAGE_FIELD] = allowImages;
  formValues[FEEDBACK_QUESTION_COLLECT_EMAIL_ONLY] = collectEmailOnly;

  return {
    FEEDBACK_QUESTION_PERSONAL_INFO_EMAIL_FIELD: isEmailOptional,
    FEEDBACK_QUESTION_PERSONAL_INFO_POST_CODE_FIELD: isPostCodeOptional,
    FEEDBACK_QUESTION_ALLOW_IMAGE_FIELD: allowImages,
    FEEDBACK_QUESTION_COLLECT_EMAIL_ONLY: collectEmailOnly,
  };
}

function prepareFeedbackQuestionPersonalInfoGroupData(question, configurationValues) {
  if (!question || !configurationValues) return [];

  return [
    [
      `${FEEDBACK_QUESTION_PERSONAL_INFO_EMAIL_FIELD}-${question._id}`,
      'Require email',
      !configurationValues.FEEDBACK_QUESTION_PERSONAL_INFO_EMAIL_FIELD,
    ],
    [
      `${FEEDBACK_QUESTION_PERSONAL_INFO_POST_CODE_FIELD}-${question._id}`,
      'Require postcode',
      !configurationValues.FEEDBACK_QUESTION_PERSONAL_INFO_POST_CODE_FIELD,
    ],
  ];
}

function prepareFeedbackQuestionSubmitConfig(question, formValues) {
  if (!question || !formValues) return;

  question.questionConfiguration = {
    allowComments: true,
    collectEmailOnly: formValues[`${FEEDBACK_QUESTION_COLLECT_EMAIL_ONLY}`],
    allowUserContentUpload: {
      image: formValues[`${FEEDBACK_QUESTION_ALLOW_IMAGE_FIELD}`],
    },
    feedbackConfiguration: {
      isPostCodeOptional:
        !formValues[
          `${FEEDBACK_QUESTION_PERSONAL_INFO_PREFIX}-${FEEDBACK_QUESTION_PERSONAL_INFO_POST_CODE_FIELD}-${question._id}`
        ],
      isEmailOptional:
        !formValues[
          `${FEEDBACK_QUESTION_PERSONAL_INFO_PREFIX}-${FEEDBACK_QUESTION_PERSONAL_INFO_EMAIL_FIELD}-${question._id}`
        ],
    },
    leadImageType: formValues[`${FEEDBACK_QUESTION_LEAD_IMAGE_TYPE}`],
  };
}

export {
  openModal,
  prepareFeedbackQuestionConfigFormValues,
  prepareFeedbackQuestionPersonalInfoGroupData,
  prepareFeedbackQuestionSubmitConfig,
  FEEDBACK_QUESTION_PERSONAL_INFO_PREFIX,
  FEEDBACK_QUESTION_PERSONAL_INFO_EMAIL_FIELD,
  FEEDBACK_QUESTION_PERSONAL_INFO_POST_CODE_FIELD,
  FEEDBACK_QUESTION_ALLOW_IMAGE_FIELD,
  FEEDBACK_QUESTION_COLLECT_EMAIL_ONLY,
};
