import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import ModalCloseButton from '../../buttons/ModalCloseButton';
import { IMAGE_FORMATS } from '../../form-elements/fileUpload/ImageFormatEnums';
import ImageUtils from '../../../ImageUtils';
import SvgIcon from '../../SvgIcon';
import { openModal } from '../../../../containers/project/polls/PollUtils';

const CATEGORY_ALL = 'All';
function AnnotateReactionModal(props) {
  const { Store, closeModal } = props;

  const [reactions, setReactions] = useState([]);
  const [reactionCategories, setReactionCategories] = useState([]);
  const [allReactions, setAllReactions] = useState([]);
  const [reactionsCount, setReactionsCount] = useState();
  const [disableAddButton, setDisableAddButton] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(CATEGORY_ALL);

  const MAXIMUM_REACTIONS_ALLOWED = Store.annotateReactionsModalAllowSingleReaction ? 1 : 10;

  useEffect(() => openModal(closeModal), [closeModal]);

  useEffect(() => {
    if (Store) {
      Store.loadAnnotationReactions().then((data) => {
        if (data) {
          const preparedReactions = [];

          data.forEach((item) => {
            const reactionExists = Store.annotateReactionsModalInputIds.find((id) => id === item._id);

            if (reactionExists) {
              preparedReactions.push({ ...item, existing: true, checked: false });
            } else {
              preparedReactions.push({ ...item, existing: false, checked: false });
            }
          });

          setReactionsCount(Store.annotateReactionsModalInputIds.length);

          setReactions(preparedReactions);
          setAllReactions(preparedReactions);

          if (Store.annotateReactionCategories.length > 0)
            setReactionCategories([CATEGORY_ALL, ...Store.annotateReactionCategories]);
        }
      });
    }
  }, [Store]);

  function addReactions() {
    setDisableAddButton(true);

    const newReactions = allReactions.filter((reaction) => reaction.checked);
    Store.addAnnotationReactions({ reactionTemplateIds: newReactions.map((reaction) => reaction._id) }).then(
      (success) => {
        if (!success) setDisableAddButton(false);
      },
    );
  }

  function reactionChangeHandler(event, reaction) {
    const targetedReaction = reactions.find((item) => item._id === reaction._id);
    targetedReaction.checked = event.target.checked;

    const targetedFullListReaction = allReactions.find((item) => item._id === reaction._id);
    targetedFullListReaction.checked = event.target.checked;

    if (!targetedReaction.existing) {
      event.target.checked ? setReactionsCount(reactionsCount + 1) : setReactionsCount(reactionsCount - 1);
    }

    setReactions([...reactions]);
  }

  function filterReactions(search, category) {
    if (!search && category === CATEGORY_ALL) setReactions([...allReactions]);

    let filteredByCategory = [...allReactions];
    if (category !== CATEGORY_ALL)
      filteredByCategory = allReactions.filter((reaction) => reaction.categories?.includes(category));

    const filteredReactions = filteredByCategory.filter((reaction) =>
      reaction.choice.toLowerCase().includes(search.toLowerCase()),
    );

    setReactions([...filteredReactions]);
  }

  function searchHandler(event) {
    const newSearchValue = event.target.value;
    setSearchValue(newSearchValue);

    filterReactions(newSearchValue, selectedCategory);
  }

  function categoryChangeHandler(event) {
    // console.log(event.target.value);
    setSelectedCategory(event.target.value);

    filterReactions(searchValue, event.target.value);
  }

  function clearFilter() {
    setSearchValue('');
    setReactions([...allReactions]);
  }

  return (
    <div className='c-modal c-modal--centered-top' style={{ display: 'flex' }}>
      <div className='c-modal__container'>
        <div className='c-modal__header'>
          <h2 className='c-modal__title'>Annotate Reactions</h2>
          <ModalCloseButton closeModal={closeModal} />
        </div>
        <div className='c-modal__content'>
          <form className='c-fields l-fields'>
            <div className='c-field l-form__item c-field--label-hidden'>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className='c-field__label' htmlFor='filter-clients'>
                Search
              </label>
              <div className='c-field__group'>
                <div className='o-input-icon o-input-clear'>
                  <input
                    type='text'
                    id='filter-reactions'
                    placeholder='Search reaction labels'
                    value={searchValue}
                    onChange={searchHandler}
                  />
                  <SvgIcon icon='search' />
                  {searchValue && (
                    <button className='o-button' onClick={clearFilter}>
                      <SvgIcon icon='times' />
                      <span className='o-label'>Clear</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
            {reactionCategories.length > 0 && (
              <div className='c-field l-form__item'>
                <label className='c-field__label' htmlFor=''>
                  Select category
                </label>
                <div className='c-field__group'>
                  {reactionCategories.map((category, index) => (
                    <div key={index} className='o-radio o-radio--inline o-radio--s o-radio--button'>
                      <input
                        type='radio'
                        id={`image-categories-${category}`}
                        name='image-categories'
                        defaultChecked={index === 0}
                        value={category}
                        onChange={categoryChangeHandler}
                      />
                      <label htmlFor={`image-categories-${category}`}>{category}</label>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className='c-field l-form__item'>
              <label className='c-field__label' htmlFor='select-reaction'>
                {`You selected ${reactionsCount} reaction${
                  reactionsCount === 1 ? '' : 's'
                }. You can select up to ${MAXIMUM_REACTIONS_ALLOWED} reaction${
                  MAXIMUM_REACTIONS_ALLOWED > 1 ? 's' : ''
                }.`}
              </label>
              <div className='c-field__group'>
                <ul className='c-reactions'>
                  {reactions.map((reaction) => (
                    <li
                      key={reaction._id}
                      className={`c-reactions__item  ${reaction.existing ? 'is-added' : ''} ${
                        reactionsCount === MAXIMUM_REACTIONS_ALLOWED && !reaction.existing && !reaction.checked
                          ? 'is-disabled'
                          : ''
                      }`}
                    >
                      <input
                        key={reaction._id}
                        type='checkbox'
                        id={`select-reaction-0-${reaction._id}`}
                        name='select-reaction'
                        checked={reaction.existing || reaction.checked}
                        onChange={(event) => reactionChangeHandler(event, reaction)}
                        readOnly={reaction.existing}
                      />
                      <label htmlFor={`select-reaction-0-${reaction._id}`}>
                        <span className='c-reactions__figure'>
                          <img
                            src={ImageUtils.pngImageUrl(reaction.resource, IMAGE_FORMATS.REACTION.imageType)}
                            alt=''
                          />
                          <span className='c-reactions__indicator'>
                            <SvgIcon icon='check' />
                          </span>
                        </span>
                        <span className='c-reactions__name'>{reaction.existing ? 'Added' : reaction.choice}</span>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className='c-field c-field--action l-form__item'>
              <div className='c-field__group'>
                <button
                  className={`o-button o-button--primary ${disableAddButton ? 'is-disabled' : ''}`}
                  type='button'
                  onClick={addReactions}
                  disabled={disableAddButton}
                >
                  <span className='o-label'>Add reactions</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

AnnotateReactionModal.propTypes = {
  Store: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default inject((root) => ({ Store: root.RootStore.pollEditStore }))(observer(AnnotateReactionModal));
