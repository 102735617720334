import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { AUTH, AUTH_LOGIN, AUTH_FORGOT_PASSWORD, AUTH_RESET_PASSWORD } from '../../common/constants/RouteConstants';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';

function Auth() {
  // const { match } = props;
  return (
    <div className='l-body l-body--transparent'>
      <div className='l-container'>
        <div className='l-main'>
          <div className='c-login'>
            <Switch>
              <Route exact path={`/${AUTH}`} component={Login} />
              <Route exact path={`/${AUTH}/${AUTH_LOGIN}`} component={Login} />
              <Route exact path={`/${AUTH}/${AUTH_FORGOT_PASSWORD}`} component={ForgotPassword} />
              <Route exact path={`/${AUTH}/${AUTH_RESET_PASSWORD}`} component={ResetPassword} />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Auth;
