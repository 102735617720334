import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import ConditionalLogicUtil from '../../ConditionalLogicUtil';
import SimpleDestinationSelector from './SimpleDestinationSelector';

export const DESTINATIONS = {
  JUMP_TO: 'jumpTo',
  ELSE: 'else',
};
function DestinationSelector(props) {
  const { fieldName, allowNull = true, isRequired } = props;

  function validator(value) {
    return !value && isRequired ? 'Required' : undefined;
  }

  return (
    <Field
      name={fieldName}
      component='select'
      allowNull={allowNull}
      parse={(value) => ConditionalLogicUtil.parseSelectedOption(value)}
      validate={validator}
    >
      {({ input, meta }) => {
        return <SimpleDestinationSelector input={input} meta={meta} {...props} />;
      }}
    </Field>
  );
}

DestinationSelector.propTypes = {
  fieldName: PropTypes.string.isRequired,
  allowNull: PropTypes.bool,
  formValues: PropTypes.object.isRequired,
  question: PropTypes.object,
  questions: PropTypes.array.isRequired,
  titleLabel1: PropTypes.string,
  titleLabelStrong: PropTypes.string,
  titleLabel2: PropTypes.string,
  hasSelectOption: PropTypes.bool,
  hasNextOption: PropTypes.bool,
  hasEndOfPollOption: PropTypes.bool,
  markSelectedDestinations: PropTypes.bool,
  isRequired: PropTypes.bool,
};

export default DestinationSelector;
