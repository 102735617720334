import { observable, computed, when, action, makeObservable } from 'mobx';
import Agent from '../common/agent';

const SLUG_ALREADY_EXISTS = require('../shared/engage').ApiErrorEnums().Err422KeyMmap.SLUG_ALREADY_EXISTS;

const UPDATE_SUCCESS = 'Project information saved';
const UPDATE_ERROR = `Error saving project`;
const REMOVE_LOGO_ERROR = `Error deleting logo`;
const REMOVE_BACKGROUND_ERROR = `Error deleting project background`;

export default class ProjectInformationStore {
  loading = false;

  error = null;

  constructor(rootStore, projectStore) {
    makeObservable(this, {
      loading: observable,
      error: observable,

      project: computed,
    });

    this.rootStore = rootStore;
    this.projectStore = projectStore;
  }

  // eslint-disable-next-line class-methods-use-this
  loadProjectData(project, projectDefault) {
    const projectData = {
      projectName: project.projectName,
      slug: project.slug,
      architects: project.architects,
      projectUrl: project.projectUrl,
      about: project.about,
    };

    const projectDefaultData = {
      projectName: projectDefault.projectName,
      projectUrl: projectDefault.projectUrl,
      about: projectDefault.about,
    };

    return {
      project: projectData,
      projectDefault: projectDefaultData,
      projectLogo: project.projectLogo,
      projectHeader: project.projectHeader,
    };
  }

  /**
   * Observable project getter
   * This is just a bridge to Project i ProjectStore that prepares data for Information form
   * @returns {*}
   */
  get project() {
    if (!this.projectStore.isInitialized) {
      return new Promise((resolve) => {
        when(
          () => this.projectStore.isInitialized,
          () => resolve(this.loadProjectData(this.projectStore.project, this.projectStore.projectDefault)),
        );
      });
    }
    return this.loadProjectData(this.projectStore.project, this.projectStore.projectDefault);
  }

  /**
   * update basic information PATCH /project/id
   * @param body
   */
  saveProjectInformation(body) {
    this.loading = true;
    this.error = null;
    return Agent.Projects.updateInformation(this.projectStore.project._id, body)
      .then(() => {
        this.rootStore.toastrStore.success(UPDATE_SUCCESS);
        return this.projectStore.refresh();
      })
      .catch((err) => {
        const errorInfo =
          err && err.response && err.response.data && err.response.data.error ? err.response.data.error : '';
        if (errorInfo === SLUG_ALREADY_EXISTS) {
          this.error = errorInfo;
          this.rootStore.toastrStore.error(errorInfo, null, err);
        } else {
          this.error = err;
          this.rootStore.toastrStore.error(UPDATE_ERROR, null, err);
        }
      })
      .finally(() => (this.loading = false));
  }

  /**
   * Upload  project logo POST /projects/${projectId}/logo
   * @param file
   * @param progress
   * @returns {Promise<boolean> | Promise<T> | *}
   */
  uploadLogo(file, progress) {
    this.loading = true;
    this.error = null;
    return Agent.Projects.uploadLogo(file, this.projectStore.project._id, progress)
      .then(
        action(({ data }) => {
          this.projectStore.project.projectLogo = data;
        }),
      )
      .finally(() => (this.loading = false));
  }

  /**
   * Remove project logo DELETE /projects/{{projectId}}/logo
   * @returns {Promise<boolean>}
   */
  removeLogo() {
    this.loading = true;
    this.error = null;
    return Agent.Projects.removeLogo(this.projectStore.project._id)
      .then(
        action(() => {
          this.projectStore.project.projectLogo = null;
        }),
      )
      .catch((err) => {
        this.error = err;
        this.rootStore.toastrStore.error(REMOVE_LOGO_ERROR, null, err);
      })
      .finally(() => (this.loading = false));
  }

  /**
   * Upload  project background POST /projects/${projectId}/background
   * @param file
   * @param progress
   * @returns {Promise<boolean> | Promise<T> | *}
   */
  uploadBackground(file, progress) {
    this.loading = true;
    this.error = null;
    return (
      Agent.Projects.uploadBackground(file, this.projectStore.project._id, progress)
        // .then(() => this.projectStore.refresh())
        .then(
          action((res) => {
            this.projectStore.project.projectHeader = res.data;
          }),
        )
        .finally(() => (this.loading = false))
    );
  }

  /**
   * Remove project background DELETE /projects/{{projectId}}/background
   * @returns {Promise<boolean>}
   */
  removeBackground() {
    this.loading = true;
    this.error = null;
    return Agent.Projects.removeBackground(this.projectStore.project._id)
      .then(
        action(() => {
          this.projectStore.project.projectHeader = null;
        }),
      )
      .catch((err) => {
        this.error = err;
        this.rootStore.toastrStore.error(REMOVE_BACKGROUND_ERROR, null, err);
      })
      .finally(() => (this.loading = false));
  }

  /**
   * Validate project slug
   * @returns {*}
   */
  async validateSlug(slug, validationType, useProjectId) {
    this._loading = false;
    this._error = null;

    try {
      await Agent.Projects.validateSlug({
        slug,
        validationType,
        projectId: useProjectId ? this.projectStore.project._id : undefined,
      });
    } catch (err) {
      if (err.response.status === 422 && err.response?.data?.message === SLUG_ALREADY_EXISTS) {
        throw new Error(SLUG_ALREADY_EXISTS);
      }
      this.error = err;
      this.rootStore.toastrStore.error('Error validating project slug', null);
    }
  }
}
