import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Slugify from 'react-slugify';
import ActionBar from '../../../common/components/actionBar/ActionBar';
import NewsForm from './NewsForm';
import PreviewModal from '../../../common/components/previewModal/PreviewModal';

/**
 * News item
 */
function NewsEdit(props) {
  const { Store, ConfigStore, ProjectStore, HelpStore, match } = props;

  const [isModalPreviewVisible, setModalPreviewVisible] = useState(false);

  const newsId = match.params.newsId;
  const newsById = Store.news.find((_news) => _news.data._id === newsId);
  const news = newsById || {};

  const clientEnvironment = ConfigStore.config.clientPath;

  function openNewsPreview() {
    setModalPreviewVisible(true);
    HelpStore.closeHelp();
  }

  function getIframeSrc() {
    const src = `${clientEnvironment}/${ProjectStore.project.slug}/news/${Slugify(
      newsById.data.title,
    )}/${newsId}?isPreview=true&language=${ProjectStore.language}`;
    // console.log(src);
    return src;
  }

  return (
    <div className='l-main'>
      <ActionBar label='Edit News' hasButton={false} />

      <NewsForm
        projectId={props.match.params.id}
        news={news}
        history={props.history}
        openNewsPreview={openNewsPreview}
      />
      {isModalPreviewVisible && (
        <PreviewModal closeModal={() => setModalPreviewVisible(false)} iframeSrc={getIframeSrc()} />
      )}
    </div>
  );
}

NewsEdit.propTypes = {
  Store: PropTypes.object.isRequired,
  ConfigStore: PropTypes.object.isRequired,
  ProjectStore: PropTypes.object.isRequired,
  HelpStore: PropTypes.object.isRequired,
  match: PropTypes.object,
};

export default inject((root) => ({
  Store: root.RootStore.newsStore,
  ConfigStore: root.RootStore.configStore,
  ProjectStore: root.RootStore.projectStore,
  HelpStore: root.RootStore.helpStore,
}))(observer(NewsEdit));
