import React from 'react';
import PropTypes from 'prop-types';
import MDEditor from '@uiw/react-md-editor';
import QuestionTemplateAction from './QuestionTemplateAction';
import QuestionHeader from '../../../reports/QuestionHeader';
import MediaItem from '../../../../../common/components/mediaItem/MediaItem';
import QuestionTemplateHeader from './QuestionTemplateHeader';

function FactMediaPreview(props) {
  const { question } = props;

  const mediaResource = question?.resource?.length > 0 ? question.resource[0] : null;

  const imageId = mediaResource?._id || null;
  const imageUrl = mediaResource?.url || null;
  const imageType = mediaResource?.mediaType || null;

  return (
    <div className='c-library__item'>
      <div className='c-question c-question--fact-image c-question--library$'>
        <QuestionTemplateHeader question={question} showTitle={false} />
        <div className='c-question__content'>
          <div className='c-question-fact c-question-fact--image'>
            <div className='c-question-fact__content u-rich-text'>
              <MDEditor.Markdown source={question.questionText} className='markdown_preview' />
            </div>
            <div className='c-question-fact__media'>
              {mediaResource && (
                <MediaItem
                  type={imageType}
                  mediaId={imageId}
                  mediaUrl={imageUrl}
                  imageClassModifier='l-form__item c-upload--fill'
                  videoClassModifier='c-upload--fill'
                  linkToVideo={true}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <QuestionTemplateAction question={question} />
    </div>
  );
}

FactMediaPreview.propTypes = {
  question: PropTypes.shape({
    questionText: PropTypes.string.isRequired,
    resource: PropTypes.array.isRequired,
  }).isRequired,
};

export default FactMediaPreview;
