import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import debounce from 'lodash.debounce';
import PollReportFeedbackListItem from './PollReportFeedbackListItem';
import { openModal } from '../../../containers/project/polls/PollUtils';
import ModalCloseButton from '../buttons/ModalCloseButton';
import PollReportFeedbackHeader from './PollReportFeedbackHeader';
import PollReportFeedbackPagination, { RESULTS_PER_PAGE } from './PollReportFeedbackPagination';
import { SHOW_ALL_REACTIONS } from './ActiveFilter';
import { PollEnums } from '../../../shared/engage';

const FILTER_CHANGES = {
  SEARCH: 'SEARCH',
  SHOW_MEDIA: 'SHOW_MEDIA',
  SHOW_TEXT: 'SHOW_TEXT',
  REACTION: 'REACTION',
  PAGE: 'PAGE',
  PAGE_SIZE: 'PAGE_SIZE',
};

function PollReportFeedbackModal(props) {
  const { closeModal, Store, projectId, surveyFilters } = props;

  const [searchValue, setSearchValue] = useState('');
  const [showMediaFeedback, setShowMediaFeedback] = useState(false);
  const [showTextFeeedback, setShowTextFeeedback] = useState(false);
  const [selectedReaction, setSelectedReaction] = useState(SHOW_ALL_REACTIONS);

  const [displayLocations, setDisplayLocations] = useState(false);

  const [paginationData, setPaginationData] = useState();
  const [totalFeedbackCount, setTotalFeedbackCount] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [resultsPerPage, setResultsPerPage] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const [feedback, setFeedback] = useState([]);
  const [feedbackMeta, setFeedbackMeta] = useState();
  const [isFetchingData, setIsFetchingData] = useState(false);

  const [nonEditableSurveyFilters, setNonEditableSurveyFilters] = useState({
    fromDate: surveyFilters?.fromDate,
    toDate: surveyFilters?.toDate,
    segmentationFilter: surveyFilters?.segmentation?.selectedChoiceId || null,
    demographicFilter: surveyFilters?.demographic?.selectedChoiceId || null,
    conditionalLogicFilter: surveyFilters?.conditional?.selectedChoiceId || null,
  });

  const questionId = Store?.feedbackModalActivatorQuestion ? Store.feedbackModalActivatorQuestion.questionId : '';
  const questionType = Store?.feedbackModalActivatorQuestion ? Store.feedbackModalActivatorQuestion.questionType : '';
  const reactions = Store?.feedbackModalActivatorReactions ? Store.feedbackModalActivatorReactions : [];

  // Load feedback data, triggered on every filter and pagination change
  const getData = useCallback(
    (filterData, clearFilters = false) => {
      if (!Store || !filterData.projectId || !filterData.questionId) return;

      const pagingFilterData = { size: filterData.resultsPerPage, page: filterData.page };

      setIsFetchingData(true);

      Store.loadQuestionFeedback(
        filterData.projectId,
        filterData.questionId,
        filterData.fromDate,
        filterData.toDate,
        filterData.segmentationFilter,
        filterData.demographicFilter,
        filterData.conditionalLogicFilter,
        filterData.searchValue,
        filterData.showMediaFeedback,
        filterData.showTextFeeedback,
        filterData.selectedReaction,
        pagingFilterData,
      ).then((result) => {
        const newFeedback = result?.data?.data?.report?.feedback || [];
        const newFeedbackMeta = result?.data?.data?.report?.meta || {};

        const paging = result?.data?.meta.paging || {};

        setFeedback([...newFeedback]);
        setFeedbackMeta(newFeedbackMeta);

        const itemsPerPage = RESULTS_PER_PAGE.find((res) => res[1] === paging.size);
        const totalRecords = paging.totalRecords || 0;
        const numOfPages = Math.ceil(totalRecords / paging.size);

        setNumberOfPages(numOfPages);
        setTotalFeedbackCount(totalRecords);

        setPaginationData({
          total: totalRecords,
          numberOfPages: numOfPages,
          pageSize: itemsPerPage,
          currentPage: paging.page,
        });

        setIsFetchingData(false);

        if (clearFilters) {
          setSearchValue('');
          setShowMediaFeedback(false);
          setShowTextFeeedback(false);
          setSelectedReaction(SHOW_ALL_REACTIONS);
        }
      });
    },
    [Store],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchData = useCallback(
    debounce((qu) => {
      getData(qu);
    }, 500),
    [getData],
  );

  useEffect(() => {
    return openModal(closeModal);
  }, [closeModal]);

  // Initial data loading
  useEffect(() => {
    if (!Store || !projectId || !questionId) return;

    const initialPageSize = RESULTS_PER_PAGE[0];
    const intialPage = 1;
    setResultsPerPage(initialPageSize[0]);
    setCurrentPage(intialPage);

    setDisplayLocations(PollEnums().isAnnotationQuestion(Store.feedbackModalActivatorQuestion.questionType));

    getData({
      projectId,
      questionId,
      fromDate: nonEditableSurveyFilters.fromDate,
      toDate: nonEditableSurveyFilters.toDate,
      segmentationFilter: nonEditableSurveyFilters.segmentationFilter,
      demographicFilter: nonEditableSurveyFilters.demographicFilter,
      conditionalLogicFilter: nonEditableSurveyFilters.conditionalLogicFilter,
      searchValue: '',
      showMediaFeedback: false,
      showTextFeeedback: false,
      selectedReaction: null,
      page: intialPage,
      resultsPerPage: initialPageSize[1],
    });
  }, [Store, questionId, projectId, nonEditableSurveyFilters, getData]);

  function prepareFilterData(value, changeSource) {
    let paginationPage = currentPage;

    let reactionId = selectedReaction.choiceId;

    const paginationPageSizeId = changeSource === FILTER_CHANGES.PAGE_SIZE ? value : resultsPerPage;
    const itemsPerPage = RESULTS_PER_PAGE.find((res) => res[0] === paginationPageSizeId);
    const paginationPageSize = itemsPerPage[1];

    // On each filter change reset current page to 1
    if (changeSource !== FILTER_CHANGES.PAGE) {
      setCurrentPage(1);
      paginationPage = 1;
    }

    if (changeSource !== FILTER_CHANGES.REACTION && reactionId === SHOW_ALL_REACTIONS.choiceId) reactionId = null;

    return {
      projectId,
      questionId,
      fromDate: nonEditableSurveyFilters.fromDate,
      toDate: nonEditableSurveyFilters.toDate,
      segmentationFilter: nonEditableSurveyFilters.segmentationFilter,
      demographicFilter: nonEditableSurveyFilters.demographicFilter,
      conditionalLogicFilter: nonEditableSurveyFilters.conditionalLogicFilter,
      searchValue: changeSource === FILTER_CHANGES.SEARCH ? value : searchValue,
      showMediaFeedback: changeSource === FILTER_CHANGES.SHOW_MEDIA ? value : showMediaFeedback,
      showTextFeeedback: changeSource === FILTER_CHANGES.SHOW_TEXT ? value : showTextFeeedback,
      selectedReaction: changeSource === FILTER_CHANGES.REACTION ? value : reactionId,
      page: changeSource === FILTER_CHANGES.PAGE ? value : paginationPage,
      resultsPerPage: paginationPageSize,
    };
  }

  function resultsPerPageChangeHandler(value) {
    const newValue = parseInt(value, 10);
    setResultsPerPage(newValue);
    setCurrentPage(1);

    const filterData = prepareFilterData(newValue, FILTER_CHANGES.PAGE_SIZE);
    getData(filterData);
  }

  function paginationChangeHandler(page) {
    setCurrentPage(page);

    const filterData = prepareFilterData(page, FILTER_CHANGES.PAGE);
    getData(filterData);
  }

  function cleaActiveFiltersHandler() {
    setCurrentPage(1);

    const itemsPerPage = RESULTS_PER_PAGE.find((res) => res[0] === resultsPerPage);
    getData(
      {
        projectId,
        questionId,
        fromDate: nonEditableSurveyFilters.fromDate,
        toDate: nonEditableSurveyFilters.toDate,
        segmentationFilter: nonEditableSurveyFilters.segmentationFilter,
        demographicFilter: nonEditableSurveyFilters.demographicFilter,
        conditionalLogicFilter: nonEditableSurveyFilters.conditionalLogicFilter,
        searchValue: '',
        showMediaFeedback: false,
        showTextFeeedback: false,
        selectedReaction: null,
        page: 1,
        resultsPerPage: itemsPerPage[1],
      },
      true,
    );
  }

  function mediaFilterChangeHandler() {
    setShowMediaFeedback(!showMediaFeedback);

    const filterData = prepareFilterData(!showMediaFeedback, FILTER_CHANGES.SHOW_MEDIA);
    getData(filterData);
  }

  function textFilterChangeHandler() {
    setShowTextFeeedback(!showTextFeeedback);

    const filterData = prepareFilterData(!showTextFeeedback, FILTER_CHANGES.SHOW_TEXT);
    getData(filterData);
  }

  function searchChangeHandler(value) {
    setSearchValue(value);

    const filterData = prepareFilterData(value, FILTER_CHANGES.SEARCH);
    debouncedFetchData(filterData);
  }

  function searchClearHandler() {
    setSearchValue('');

    const filterData = prepareFilterData('', FILTER_CHANGES.SEARCH);
    getData(filterData);
  }

  function reactionChangeHandler(reaction) {
    setSelectedReaction(reaction);

    const selectedReactionId = reaction.choiceId === SHOW_ALL_REACTIONS.choiceId ? null : reaction.choiceId;
    const filterData = prepareFilterData(selectedReactionId, FILTER_CHANGES.REACTION);
    getData(filterData);
  }

  function displayLocationsChangeHandler() {
    setDisplayLocations(!displayLocations);
  }

  return (
    <div className='c-modal c-modal--full-screen' style={{ display: 'block' }} data-testid='feedback-modal-wrapper'>
      <div className='c-modal__container'>
        <div className='c-modal__header'>
          <h2 className='c-modal__title'>Browse Feedback</h2>
          <ModalCloseButton closeModal={closeModal} />
        </div>
        <div className='c-modal__content'>
          <div className='c-browse-feedback'>
            <PollReportFeedbackHeader
              title={Store?.feedbackModalActivatorQuestion?.label}
              searchValue={searchValue}
              showMediaFeedback={showMediaFeedback}
              showTextFeeedback={showTextFeeedback}
              selectedReaction={selectedReaction}
              reactions={reactions}
              onClearActiveFilters={cleaActiveFiltersHandler}
              onMediaFilterChange={mediaFilterChangeHandler}
              onTextFilterChange={textFilterChangeHandler}
              onSearchChange={searchChangeHandler}
              onSearchClear={searchClearHandler}
              onReactionChange={reactionChangeHandler}
              displayLocations={displayLocations}
              onDisplayLocationsChange={displayLocationsChangeHandler}
              nonEditableSurveyFilters={surveyFilters}
              questionType={questionType}
              feedbackMeta={feedbackMeta}
              isFetchingData={isFetchingData}
            />
            <div className='c-browse-feedback__content'>
              <PollReportFeedbackPagination
                paginationData={paginationData}
                resultsPerPage={resultsPerPage}
                onResultsPerPageChange={resultsPerPageChangeHandler}
                totalFeedbackCount={totalFeedbackCount}
                numberOfPages={numberOfPages}
                onPaginationChange={paginationChangeHandler}
                currentPage={currentPage}
              />
              <div className='c-browse-feedback__items'>
                {feedback.map((item, index) => (
                  <PollReportFeedbackListItem
                    key={index}
                    feedback={item}
                    feedbackMeta={feedbackMeta}
                    displayLocation={displayLocations}
                  />
                ))}
              </div>
              {feedback.length > 8 && (
                <PollReportFeedbackPagination
                  resultsPerPage={resultsPerPage}
                  onResultsPerPageChange={resultsPerPageChangeHandler}
                  totalFeedbackCount={totalFeedbackCount}
                  numberOfPages={numberOfPages}
                  onPaginationChange={paginationChangeHandler}
                  currentPage={currentPage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PollReportFeedbackModal.propTypes = {
  projectId: PropTypes.string.isRequired,
  surveyFilters: PropTypes.shape({
    fromDate: PropTypes.string,
    toDate: PropTypes.string,
    segmentation: PropTypes.shape({
      selectedChoiceId: PropTypes.string,
      question: PropTypes.object,
    }),
    demographic: PropTypes.shape({
      selectedChoiceId: PropTypes.string,
      question: PropTypes.object,
    }),
    conditional: PropTypes.shape({
      selectedChoiceId: PropTypes.string,
      question: PropTypes.object,
    }),
  }),
  closeModal: PropTypes.func.isRequired,
  Store: PropTypes.object.isRequired,
};

export default inject((root) => ({ Store: root.RootStore.pollsReportStore }))(observer(PollReportFeedbackModal));
