module.exports = function () {
  let _ = require('lodash');

  const QuestionTypes = {
    THREE_IMAGE_SINGLE_CHOICE: 'three-image single choice', // type 1 - ok
    ORDERING_CHOICE: 'ordering choice', // type 2
    SINGLE_SLIDER_CHOICE: 'single-slider choice', // type 3
    THREE_SLIDER_CHOICE_VALUES: 'three-slider choice values', // type 4 - ok
    FOUR_IMAGE_SINGLE_CHOICE: 'four-image single choice', // type 5 - ok
    TWO_IMAGE_SINGLE_CHOICE: 'two-image single choice', // type 6 - ok
    MULTIPLE_IMAGE_MULTIPLE_CHOICE: 'multiple-image multiple choice', // type 7
    DESCRIPTION_FACT: 'description fact', // type 10 - n/a
    SINGLE_IMAGE_FACT: 'single-image fact', // type 11 - n/a
    TWO_IMAGE_FACT: 'two-image fact', // type 12 - n/a
    FEEDBACK: 'feedback', // type 13
    SLIDER_FEEDBACK: 'slider feedback', // type 14
    MULTIPLE_IMAGE_OTHER_CHOICE: 'multiple-image other choice', // type 15 - ok
    DEMOGRAPHICS: 'demographics', // type 16
    ANNOTATION_IMAGE: 'ANNOTATION_IMAGE', // type 17 - ok
    ANNOTATION_MAP: 'ANNOTATION_MAP', // type 18 - ok
    QUICK_TEXT: 'QUICK_TEXT', // type 19 - ok
    RATING: 'RATING', // type 20 - ok
    PERSONAL_DATA: 'PERSONAL_DATA', // 21 - ok
  };

  const QuestionLabels = {
    THREE_IMAGE_SINGLE_CHOICE: 'Three image choice', // type 1
    ORDERING_CHOICE: 'Reorder', // type 2
    SINGLE_SLIDER_CHOICE: 'Slider', // type 3
    THREE_SLIDER_CHOICE_VALUES: 'Distribute', // type 4
    FOUR_IMAGE_SINGLE_CHOICE: 'Four image choice', // type 5
    TWO_IMAGE_SINGLE_CHOICE: 'Two image choice', // type 6
    MULTIPLE_IMAGE_MULTIPLE_CHOICE: 'Multiple image choice', // type 7
    DESCRIPTION_FACT: 'Fact text', // type 10
    SINGLE_IMAGE_FACT: 'Fact media', // type 11
    TWO_IMAGE_FACT: 'Fact compare', // type 12
    FEEDBACK: 'Feedback', // type 13
    SLIDER_FEEDBACK: 'Slider feedback', // type 14
    MULTIPLE_IMAGE_OTHER_CHOICE: 'Image choice or other', // type 15
    DEMOGRAPHICS: 'demographics', // type 16
    ANNOTATION_IMAGE: 'Annotate image', // type 17
    ANNOTATION_MAP: 'Annotate map', // type 18
    QUICK_TEXT: 'Quick answer', // type 19
    RATING: 'Rating', // type 20
  };

  function isFact(questionType) {
    return [QuestionTypes.DESCRIPTION_FACT, QuestionTypes.SINGLE_IMAGE_FACT, QuestionTypes.TWO_IMAGE_FACT].includes(
      questionType,
    );
  }
  function isQuestion(questionType) {
    return !isFact(questionType);
  }

  function isAnnotationQuestion(questionType) {
    return [QuestionTypes.ANNOTATION_IMAGE, QuestionTypes.ANNOTATION_MAP].includes(questionType);
  }

  function isSliderQuestion(questionType) {
    return [QuestionTypes.SINGLE_SLIDER_CHOICE, QuestionTypes.SLIDER_FEEDBACK].includes(questionType);
  }

  /*
    ####
    #### communityResponse and communityAgreement CURRENT STATUS
    ####

    // YES communityAgreement + YES communityAnswer
    TWO_IMAGE_SINGLE_CHOICE
    THREE_IMAGE_SINGLE_CHOICE
    FOUR_IMAGE_SINGLE_CHOICE
    MULTIPLE_IMAGE_OTHER_CHOICE

    // NO communityAgreement + YES communityAnswer
    MULTIPLE_IMAGE_MULTIPLE_CHOICE
    SINGLE_SLIDER_CHOICE
    SLIDER_FEEDBACK
    THREE_SLIDER_CHOICE_VALUES

    // NO communityAgreement + NO communityAnswer
    ORDERING_CHOICE
    FEEDBACK
    ANNOTATION_IMAGE
    ANNOTATION_MAP
    QUICK_TEXT

    DEMOGRAPHICS

    DESCRIPTION_FACT
    SINGLE_IMAGE_FACT
    TWO_IMAGE_FACT
   */
  function hasCommunityAnswer(questionType, isDbEncoded = false) {
    const qt = isDbEncoded ? QuestionEnums[questionType] : questionType;
    const _hasCommunityAnswer = [
      QuestionTypesEnums.TWO_IMAGE_SINGLE_CHOICE,
      QuestionTypesEnums.THREE_IMAGE_SINGLE_CHOICE,
      QuestionTypesEnums.FOUR_IMAGE_SINGLE_CHOICE,
      QuestionTypesEnums.MULTIPLE_IMAGE_OTHER_CHOICE,
      //
      QuestionTypesEnums.MULTIPLE_IMAGE_MULTIPLE_CHOICE,
      QuestionTypesEnums.SINGLE_SLIDER_CHOICE,
      QuestionTypesEnums.SLIDER_FEEDBACK,
      QuestionTypesEnums.THREE_SLIDER_CHOICE_VALUES,
    ];
    return _hasCommunityAnswer.includes(qt);
  }
  function hasCommunityAgreement(questionType, isDbEncoded = false) {
    const qt = isDbEncoded ? QuestionEnums[questionType] : questionType;
    const _hasCommunityAgreement = [
      QuestionTypesEnums.THREE_IMAGE_SINGLE_CHOICE,
      QuestionTypesEnums.MULTIPLE_IMAGE_OTHER_CHOICE,
      QuestionTypesEnums.TWO_IMAGE_SINGLE_CHOICE,
      QuestionTypesEnums.FOUR_IMAGE_SINGLE_CHOICE,
    ];
    return _hasCommunityAgreement.includes(qt);
  }
  function canHaveFeedbackOnChoice(questionType, isDbEncoded = false) {
    const qt = isDbEncoded ? QuestionEnums[questionType] : questionType;
    const _canHaveFeedbackOnChoice = [
      QuestionTypesEnums.ANNOTATION_MAP,
      QuestionTypesEnums.ANNOTATION_IMAGE,
      QuestionTypesEnums.QUICK_TEXT,
    ];
    return _canHaveFeedbackOnChoice.includes(qt);
  }
  function canHaveFeedbackOnQuestion(questionType, isDbEncoded = false) {
    const qt = isDbEncoded ? QuestionEnums[questionType] : questionType;
    const _canHaveFeedbackOnQuestion = [
      QuestionTypesEnums.FEEDBACK,
      QuestionTypesEnums.SLIDER_FEEDBACK,
      QuestionTypesEnums.MULTIPLE_IMAGE_OTHER_CHOICE,
      QuestionTypesEnums.RATING,
    ];
    return _canHaveFeedbackOnQuestion.includes(qt);
  }

  function canHaveFeedback(questionType, isDbEncoded = false) {
    return canHaveFeedbackOnChoice(questionType, isDbEncoded) || canHaveFeedbackOnQuestion(questionType, isDbEncoded);
  }

  function canHaveAttachment(questionType, isDbEncoded = false) {
    const qt = isDbEncoded ? QuestionEnums[questionType] : questionType;
    const _canHaveAttachment = [
      QuestionTypesEnums.FEEDBACK,
      QuestionTypesEnums.SLIDER_FEEDBACK,
      QuestionTypesEnums.ANNOTATION_MAP,
      QuestionTypesEnums.ANNOTATION_IMAGE,
    ];
    return _canHaveAttachment.includes(qt);
  }

  function hasSentimentAnalysis(questionType, isDbEncoded = false) {
    const qt = isDbEncoded ? QuestionEnums[questionType] : questionType;
    const _hasSentimentAnalysis = [
      QuestionTypesEnums.FEEDBACK,
      QuestionTypesEnums.SLIDER_FEEDBACK,
      QuestionTypesEnums.ANNOTATION_MAP,
      QuestionTypesEnums.ANNOTATION_IMAGE,
      QuestionTypesEnums.RATING,
    ];
    return _hasSentimentAnalysis.includes(qt);
  }

  const QuestionEnums = {
    'three-image single choice': 'THREE_IMAGE_SINGLE_CHOICE', // type 1
    'ordering choice': 'ORDERING_CHOICE', // type 2
    'single-slider choice': 'SINGLE_SLIDER_CHOICE', // type 3
    'three-slider choice values': 'THREE_SLIDER_CHOICE_VALUES', // type 4
    'four-image single choice': 'FOUR_IMAGE_SINGLE_CHOICE', // type 5
    'two-image single choice': 'TWO_IMAGE_SINGLE_CHOICE', // type 6
    'multiple-image multiple choice': 'MULTIPLE_IMAGE_MULTIPLE_CHOICE', // type 7
    'description fact': 'DESCRIPTION_FACT', // type 10
    'single-image fact': 'SINGLE_IMAGE_FACT', // type 11
    'two-image fact': 'TWO_IMAGE_FACT', // type 12
    feedback: 'FEEDBACK', // type 13
    'slider feedback': 'SLIDER_FEEDBACK', // type 14
    'multiple-image other choice': 'MULTIPLE_IMAGE_OTHER_CHOICE', // type 15
    demographics: 'DEMOGRAPHICS', // type 16
    ANNOTATION_IMAGE: 'ANNOTATION_IMAGE', // type 17
    ANNOTATION_MAP: 'ANNOTATION_MAP', // type 18
    QUICK_TEXT: 'QUICK_TEXT', // type 19
    RATING: 'RATING', // type 20
  };

  const QuestionTypesEnums = {
    THREE_IMAGE_SINGLE_CHOICE: 'THREE_IMAGE_SINGLE_CHOICE', // type 1
    ORDERING_CHOICE: 'ORDERING_CHOICE', // type 2
    SINGLE_SLIDER_CHOICE: 'SINGLE_SLIDER_CHOICE', // type 3
    THREE_SLIDER_CHOICE_VALUES: 'THREE_SLIDER_CHOICE_VALUES', // type 4
    FOUR_IMAGE_SINGLE_CHOICE: 'FOUR_IMAGE_SINGLE_CHOICE', // type 5
    TWO_IMAGE_SINGLE_CHOICE: 'TWO_IMAGE_SINGLE_CHOICE', // type 6
    MULTIPLE_IMAGE_MULTIPLE_CHOICE: 'MULTIPLE_IMAGE_MULTIPLE_CHOICE', // type 7
    DESCRIPTION_FACT: 'DESCRIPTION_FACT', // type 10
    SINGLE_IMAGE_FACT: 'SINGLE_IMAGE_FACT', // type 11
    TWO_IMAGE_FACT: 'TWO_IMAGE_FACT', // type 12
    FEEDBACK: 'FEEDBACK', // type 13
    SLIDER_FEEDBACK: 'SLIDER_FEEDBACK', // type 14
    MULTIPLE_IMAGE_OTHER_CHOICE: 'MULTIPLE_IMAGE_OTHER_CHOICE', // type 15
    DEMOGRAPHICS: 'DEMOGRAPHICS', // type 16
    ANNOTATION_IMAGE: 'ANNOTATION_IMAGE', // type 17
    ANNOTATION_MAP: 'ANNOTATION_MAP', // type 18
    QUICK_TEXT: 'QUICK_TEXT', // type 19
    RATING: 'RATING', // type 20
  };

  const QuestionGroupsEnums = {
    IMAGE_GROUP: 'IMAGE_GROUP',
    FACT_GROUP: 'FACT_GROUP',
    ANNOTATE_GROUP: 'ANNOTATE_GROUP',
  };

  // Property Developer to choose points per question in a poll. They can choose from: 2, 3, 4, 5 points.
  // https://basecamp.com/2916771/projects/15717618/messages/81423725
  const QuestionPointAward = [0, 2, 3, 4, 5];

  const TrackingEnums = {
    SKIP: 'SKIP', // question skipped
    SEEN: 'SEEN', // question displayed to user
  };

  const ResourceTypes = {
    IMAGE: 'image',
    VIDEO: 'video',
    URL: 'url',
    TEXT: 'text',
  };

  const SegmentationQuestionPosition = {
    FIRST: 'first',
    MIDDLE: 'middle',
    LAST: 'last',
  };

  // copy from /server-batch/images/BIDLambdaImageResize.js
  const ResourceImageSizes = {
    IMAGE_FULL: '-full.jpeg',
    IMAGE_FULL_2X: '-full_2x.jpeg',

    IMAGE_FULL_600x600x80: '-fullx600x600x80.jpeg',
    IMAGE_FULL_600x600x80_2X: '-fullx600x600x80_2x.jpeg',

    IMAGE_SMALL: '-small.jpeg',
    IMAGE_SMALL_2X: '-small_2x.jpeg',

    IMAGE_PANEL: '-panel.jpeg',
    IMAGE_PANEL_2X: '-panel_2x.jpeg',

    IMAGE_SQUARE: '-square.jpeg',
    IMAGE_SQUARE_2X: '-square_2x.jpeg',

    // ORIGINAL
    IMAGE_ORIGINAL: '-original.jpeg',

    // PROFILE BACKGROUND
    IMAGE_BG_L: '-bg-l.jpeg',
    IMAGE_BG_L_2X: '-bg-l_2x.jpeg',

    IMAGE_BG_M: '-bg-m.jpeg',
    IMAGE_BG_M_2X: '-bg-m_2x.jpeg',

    IMAGE_BG_S: '-bg-s.jpeg',
    IMAGE_BG_S_2X: '-bg-s_2x.jpeg',

    // Logo / avatar
    IMAGE_LOGO: '-logo.jpeg',
    IMAGE_LOGO_2X: '-logo_2x.jpeg',

    IMAGE_AVATAR: '-avatar.jpeg',
    IMAGE_AVATAR_2X: '-avatar_2x.jpeg',

    // Banners
    BANNER_TOP: '-banner-top.jpeg',
    BANNER_TOP_2X: '-banner-top_2x.jpeg',

    BANNER_SQUARE: '-banner-square.jpeg',
    BANNER_SQUARE_2X: '-banner-square_2x.jpeg',

    BANNER_INLINE: '-banner-inline.jpeg',
    BANNER_INLINE_2X: '-banner-inline_2x.jpeg',
  };

  const GMVResourceImageSizes = {
    /**
     * GMV formats (IH-Engage/src/components/image/Formats.js)
     */
    GMV_IMAGE_SQUARE_S_300x300: '-gmv-square-s_300x300.jpeg',
    GMV_IMAGE_SQUARE_M_600x600: '-gmv-square-m_600x600.jpeg',
    GMV_IMAGE_SQUARE_L_1200x1200: '-gmv-square-l_1200x1200.jpeg',
    GMV_IMAGE_WIDE_S_800x480: '-gmv-wide-s_800x480.jpeg',
    GMV_IMAGE_WIDE_M_1200x720: '-gmv-wide-m_1200x720.jpeg',
    GMV_IMAGE_WIDE_L_1600x960: '-gmv-wide-l_1600x960.jpeg',
    GMV_IMAGE_ULTRA_WIDE_S_800x200: '-gmv-ultra-wide-s_800x200.jpeg',
    GMV_IMAGE_ULTRA_WIDE_M_1200x300: '-gmv-ultra-wide-m_1200x300.jpeg',
    GMV_IMAGE_ULTRA_WIDE_L_1600x400: '-gmv-ultra-wide-l_1600x400.jpeg',
    GMV_IMAGE_ULTRA_WIDE_XL_2000x500: '-gmv-ultra-wide-xl_2000x500.jpeg',
  };

  return {
    QuestionTypes: QuestionTypes,
    QuestionLabels: QuestionLabels,
    QuestionTypesEnums: QuestionTypesEnums,
    QuestionGroupsEnums: QuestionGroupsEnums,
    QuestionEnums: QuestionEnums,
    QuestionEnumsInvers: QuestionEnumsInvers,
    QuestionTypesList: QuestionTypesList,
    QuestionTypesIntegerMap: QuestionTypesIntegerMap,
    SegmentationQuestionTypesIntegerMap: SegmentationQuestionTypesIntegerMap,
    QuestionPointAward: QuestionPointAward,
    ResourceTypes: ResourceTypes,
    ResourceTypesList: ResourceTypesList,
    ResourceImageSizes: ResourceImageSizes,
    GMVResourceImageSizes: GMVResourceImageSizes,
    SegmentationQuestionPosition: SegmentationQuestionPosition,
    SegmentationQuestionPositionList: SegmentationQuestionPositionList,
    // Utility
    isFact,
    isQuestion,
    isAnnotationQuestion,
    isSliderQuestion,
    hasCommunityAnswer,
    hasCommunityAgreement,
    canHaveAttachment,
    canHaveFeedback,
    TrackingEnums,
    canHaveFeedbackOnChoice,
    canHaveFeedbackOnQuestion,
    hasSentimentAnalysis,
    canHaveLeadImage,
  };

  /**
   *
   */
  function QuestionTypesList() {
    return _.map(Object.keys(QuestionTypes), (key) => {
      return QuestionTypes[key];
    });
  }

  function QuestionTypesIntegerMap() {
    // User Levels (and points required to hit that level)
    let questionTypesMap = {};
    questionTypesMap[QuestionTypes.THREE_IMAGE_SINGLE_CHOICE] = 0;
    questionTypesMap[QuestionTypes.ORDERING_CHOICE] = 1;
    questionTypesMap[QuestionTypes.SINGLE_SLIDER_CHOICE] = 2;
    questionTypesMap[QuestionTypes.THREE_SLIDER_CHOICE_VALUES] = 3;
    questionTypesMap[QuestionTypes.FOUR_IMAGE_SINGLE_CHOICE] = 4;
    questionTypesMap[QuestionTypes.TWO_IMAGE_SINGLE_CHOICE] = 5;
    questionTypesMap[QuestionTypes.MULTIPLE_IMAGE_MULTIPLE_CHOICE] = 6;
    questionTypesMap[QuestionTypes.DESCRIPTION_FACT] = 10;
    questionTypesMap[QuestionTypes.SINGLE_IMAGE_FACT] = 11;
    questionTypesMap[QuestionTypes.TWO_IMAGE_FACT] = 12;
    questionTypesMap[QuestionTypes.FEEDBACK] = 13;
    questionTypesMap[QuestionTypes.SLIDER_FEEDBACK] = 14;
    questionTypesMap[QuestionTypes.MULTIPLE_IMAGE_OTHER_CHOICE] = 15;
    questionTypesMap[QuestionTypes.DEMOGRAPHICS] = 16;
    questionTypesMap[QuestionTypes.ANNOTATION_IMAGE] = 17;
    questionTypesMap[QuestionTypes.ANNOTATION_MAP] = 18;
    questionTypesMap[QuestionTypes.QUICK_TEXT] = 19;
    questionTypesMap[QuestionTypes.RATING] = 20;

    return questionTypesMap;
  }
  /**
   *
   */
  function SegmentationQuestionTypesIntegerMap() {
    // User Levels (and points required to hit that level)
    let questionTypesMap = {};
    questionTypesMap[QuestionTypes.ORDERING_CHOICE] = 100;
    questionTypesMap[QuestionTypes.SINGLE_SLIDER_CHOICE] = 101;
    //added questionTypes 102, 103,104 for 2-image-single-choice-qualifying-question, 3-image-single-choice-qualifying-question, 4-image-single-choice-qualifying-question respctively.
    questionTypesMap[QuestionTypes.TWO_IMAGE_SINGLE_CHOICE] = 102;
    questionTypesMap[QuestionTypes.THREE_IMAGE_SINGLE_CHOICE] = 103;
    questionTypesMap[QuestionTypes.FOUR_IMAGE_SINGLE_CHOICE] = 104;
    questionTypesMap[QuestionTypes.THREE_SLIDER_CHOICE_VALUES] = 105;

    questionTypesMap[QuestionTypes.MULTIPLE_IMAGE_MULTIPLE_CHOICE] = 106;
    questionTypesMap[QuestionTypes.FEEDBACK] = 107;
    questionTypesMap[QuestionTypes.SLIDER_FEEDBACK] = 108;
    questionTypesMap[QuestionTypes.MULTIPLE_IMAGE_OTHER_CHOICE] = 109;

    questionTypesMap[QuestionTypes.DESCRIPTION_FACT] = 10;
    questionTypesMap[QuestionTypes.SINGLE_IMAGE_FACT] = 11;
    questionTypesMap[QuestionTypes.TWO_IMAGE_FACT] = 12;

    questionTypesMap[QuestionTypes.PERSONAL_DATA] = 21;

    return questionTypesMap;
  }
  /**
   *
   */
  function ResourceTypesList() {
    return _.map(Object.keys(ResourceTypes), (key) => {
      return ResourceTypes[key];
    });
  }
  /**
   *
   */
  function QuestionEnumsInvers() {
    let aMap = {};
    _.map(Object.keys(QuestionEnums), (key) => {
      aMap[QuestionEnums[key]] = key;
    });
    return aMap;
  }
  /**
   *
   */
  function SegmentationQuestionPositionList() {
    return _.map(Object.keys(SegmentationQuestionPosition), (key) => {
      return SegmentationQuestionPosition[key];
    });
  }
  /**
   *
   * @param {*} questionType
   * @param {*} isDbEncoded
   * @returns
   */
  function canHaveLeadImage(questionType, isDbEncoded = false) {
    const qt = isDbEncoded ? QuestionEnums[questionType] : questionType;
    const _canHaveLeadImage = [
      QuestionTypesEnums.THREE_IMAGE_SINGLE_CHOICE,
      QuestionTypesEnums.MULTIPLE_IMAGE_OTHER_CHOICE,
      QuestionTypesEnums.SINGLE_IMAGE_FACT,
      QuestionTypesEnums.RATING,
      QuestionTypesEnums.QUICK_TEXT,
      QuestionTypesEnums.SLIDER_FEEDBACK,
      QuestionTypesEnums.SINGLE_SLIDER_CHOICE,
    ];
    return _canHaveLeadImage.includes(qt);
  }
};
