import React from 'react';
import PropTypes from 'prop-types';
import PollLibraryQuestionSwitcher from './PollLibraryQuestionSwitcher';

function PollLibraryItems(props) {
  const { questions, isLoading } = props;
  return (
    <div
      className={`c-library__items ${questions.length > 0 ? '' : 'is-empty'} ${isLoading ? 'is-loading' : ''}`}
      data-results={`${questions.length > 0 ? '' : 'No questions found.'}`}
    >
      {questions.map((item) => (
        <PollLibraryQuestionSwitcher key={item._id} question={item} />
      ))}
    </div>
  );
}

PollLibraryItems.propTypes = {
  questions: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default PollLibraryItems;
