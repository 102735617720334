import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { POLLS, PROJECT } from 'common/constants/RouteConstants';
import SettingsToggle from '../../SettingsToggle';

function SurveyVisibility(props) {
  const { Store, projectId } = props;

  const polls = Store?.publishStatus?.polls || [];

  function onChangeHandler(checked, poll) {
    Store.setPollVisibility(poll, checked);
  }

  return (
    <div className='c-field c-field--modern l-form__item' data-testid='survey-visibility-wrapper'>
      <label className='c-field__label'>Survey visibility</label>
      <div className='c-field__group'>
        {polls.map((poll) => (
          <SettingsToggle
            key={poll._id}
            id={poll._id}
            labelOn={poll.name}
            tag={poll.isVisible ? 'Public' : 'Hidden'}
            goToActionUrl={`/${PROJECT}/${projectId}/edit/${POLLS}/${poll._id}/edit-survey`}
            goToActionLabel='EDIT SURVEY'
            goToActionIcon='edit'
            isActive={poll.isVisible}
            onChange={(checked) => onChangeHandler(checked, poll)}
            isDisabled={!poll.isPresentInPublicProject}
          />
        ))}
      </div>
    </div>
  );
}

SurveyVisibility.propTypes = {
  Store: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
};

export default inject((root) => ({
  Store: root.RootStore.projectControlStore,
}))(observer(SurveyVisibility));
