import React from 'react';
import PropTypes from 'prop-types';
import QuestionTemplateAction from './QuestionTemplateAction';
import QuestionTemplateHeader from './QuestionTemplateHeader';

function DistributePreview(props) {
  const { question } = props;

  return (
    <div className='c-library__item'>
      <div className='c-question c-question--distribute c-question--library'>
        <QuestionTemplateHeader question={question} />
        <div className='c-question__content'>
          <div className='c-question-distribute'>
            <div className='c-question-distribute__label'>
              {question.maxValue} {question.label}
            </div>
            <div className='c-question-distribute__items'>
              {question.choices.map((choice, idx) => (
                <div key={idx} className='c-question-distribute__item'>
                  <div className='o-input-range'>
                    <input
                      type='range'
                      id='input-poll'
                      min={choice.range[0]}
                      max={question.maxValue}
                      value={choice.range[0]}
                      disabled=''
                      readOnly
                    />
                    <span className='o-input-range__value'>{0}</span>
                  </div>
                  <div className='o-label'>{choice.choice}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <QuestionTemplateAction question={question} />
    </div>
  );
}

DistributePreview.propTypes = {
  question: PropTypes.shape({
    maxValue: PropTypes.number.isRequired,
    questionText: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    choices: PropTypes.array.isRequired,
  }).isRequired,
};

export default DistributePreview;
