const REGULAR_PROJECT = 'REGULAR_PROJECT';
const SINGLE_SURVEY_ONLY = 'SINGLE_SURVEY_ONLY';
const SINGLE_SURVEY_WITH_PROJECT_INFORMATION = 'SINGLE_SURVEY_WITH_PROJECT_INFORMATION';

const PROJECT_CONFIGURATIONS = [
  [REGULAR_PROJECT, 'Regular project'],
  [SINGLE_SURVEY_ONLY, 'Single survey only'],
  [SINGLE_SURVEY_WITH_PROJECT_INFORMATION, 'Single survey with project information visible'],
];

const PROJECT_CONFIGURATION_ENUM = PROJECT_CONFIGURATIONS.map((p) => p[0]);

module.exports = {
  REGULAR_PROJECT,
  SINGLE_SURVEY_ONLY,
  SINGLE_SURVEY_WITH_PROJECT_INFORMATION,
  PROJECT_CONFIGURATIONS,
  PROJECT_CONFIGURATION_ENUM,
};
