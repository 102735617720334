import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import HelpActionButton from 'common/components/help/HelpActionButton';
import { HELP } from 'shared/engage/engage-help-enums';
import { QUESTION_TYPES } from '../../../../common/constants/QuestionConstants';
import SwitchableHeader from '../../../../common/components/switchableHeader/SwitchableHeader';
import QQSwitchableHeader from '../../../../common/components/switchableHeader/QQSwitchableHeader';

import displayConfirmationPrompt from '../../../../common/PromptUtil';
import HelpUtils from '../../../../common/HelpUtils';
import TabNavigation, { TAB_NAV_CHOICES } from '../../../../common/components/tabNavigation/TabNavigation';
import ConditionalLogicUtil from '../../../../common/ConditionalLogicUtil';

/**
 * Question Types
 */
import TwoImageQuestion from './questions/TwoImageQuestion';
import ThreeImageQuestion from './questions/ThreeImagesQuestion';
import FourImageQuestion from './questions/FourImageQuestion';
import ThreeSliderQuestion from './questions/ThreeSliderQuestion';
import ReorderQuestion from './questions/ReorderQuestion';
import SliderQuestion from './questions/SliderQuestion';
import DescriptionFact from './questions/DescriptionFact';
import SingleImageFact from './questions/SingleImageFact';
import TwoImageFact from './questions/TwoImageFact';
import MultipleImageQuestion from './questions/MultipleImageQuestion';
import FeedbackQuestion from './questions/FeedbackQuestion';
import SliderFeedback from './questions/SliderFeedback';
import ImageChoiceOther from './questions/ImageChoiceOther';
import RatingQuestion from './questions/RatingQuestion';
import AnnotateQuestion from './questions/AnnotateQuestion';
import QuickText from './questions/QuickText';
/**
 * QQ Question Types
 */
import QQTwoImageQuestion from './questions/segmentation/QQTwoImageQuestion';
import QQThreeImageQuestion from './questions/segmentation/QQThreeImagesQuestion';
import QQFourImageQuestion from './questions/segmentation/QQFourImageQuestion';
import QQTenImageQuestion from './questions/segmentation/QQTenImageQuestion';

function QuestionSwitcherFn(props) {
  const {
    classModifier,
    question,
    Store,
    ProjectStore,
    HelpStore,
    ToastrStore,
    isQQ = false,
    openPollPreview,
    isLastItem,
    updateLastItemScrollPosition,
    openReactionsModal,
    orderNumber,
  } = props;
  const { _id, questionText = '' } = question;

  const [showConditionalLogic, setShowConditionalLogic] = useState(false);

  // ### Not nice, but have types mapped differently from server
  // Re-mapping Question type for QQ
  // ###
  const questionType = isQQ ? `QQ_${question.questionType}` : question.questionType;

  const CHOICE_LABELS = ['First choice', 'Second choice', 'Third choice', 'Fourth choice'];

  /**
   *
   */
  function getTypeMeta() {
    if (QUESTION_TYPES.properties[questionType]) return QUESTION_TYPES.properties[questionType];

    // If question is part of a group
    // eslint-disable-next-line no-restricted-syntax
    for (const item of Object.entries(QUESTION_TYPES.properties)) {
      if (item[1].items) if (item[1].items[questionType]) return item[1].items[questionType];
    }
  }

  const typeMeta = getTypeMeta();

  /**
   *
   */
  const toggleOpened = () => {
    Store.toggleOpened([question._id]);
  };

  /**
   *
   */
  const removeQuestion = (event) => {
    event.preventDefault();

    const res = displayConfirmationPrompt(null, null, false, ToastrStore);

    if (res === 1) Store.removeQuestion(question._id);
  };

  /**
   *
   */
  const uploadImage = (file, progressHandler) => {
    // event.preventDefault();
    return Store.uploadImage(question, file, progressHandler);
  };

  /**
   *
   */
  const removeImage = (event, imageId) => {
    // console.log('remove', event);
    if (event) event.preventDefault();
    // const imageId = question.resource && question.resource.length > 0 && question.resource[0];
    return Store.removeImage(question, imageId);
  };

  /**
   *
   */
  const uploadChoiceImage = (choiceId, file, progress) => {
    return Store.uploadChoiceImage(question, choiceId, file, progress);
  };

  /**
   *
   */
  const removeChoiceImage = (choiceId) => {
    // console.log('remove', event);
    return Store.removeChoiceImage(question, choiceId);
  };

  /**
   *
   */
  const updateQuestion = (q) => {
    Store.updateQuestion(q);
  };

  /**
   *
   */
  const uploadVideo = (data) => {
    // event.preventDefault();
    return Store.uploadVideo(question, data);
  };

  /**
   *
   */
  const uploadMedia = (file, progressHandler) => {
    // event.preventDefault();
    return Store.uploadImage(question, file, progressHandler, true);
  };

  /**
   *
   */
  const removeMedia = (event, media) => {
    if (event) event.preventDefault();
    return Store.removeMedia(question, media);
  };

  /**
   *
   */
  const getHeaderClass = () => {
    // modifier from container
    let headerClass = classModifier;

    // handling open/close
    if (question.isOpened) {
      headerClass += ' is-opened ';
    } else {
      headerClass += ' is-closed ';
    }

    // handling question status
    if (typeMeta.isQQ && question.isActive) {
      headerClass += ' is-success is-active';
    }

    if (!typeMeta.isQQ) {
      const hasConditionalLogic = ConditionalLogicUtil.hasQuestionConditionalLogic(question);
      const isUnreachable = ConditionalLogicUtil.isUnreachableQuestion(question, Store.poll.questions);

      if (hasConditionalLogic) {
        headerClass += ' is-conditional';
      }

      if (isUnreachable) headerClass += ' is-error';

      if (hasConditionalLogic && isUnreachable) headerClass += ' is-error--conditional';
    }

    return headerClass;
  };

  function getHeaderTooltips() {
    const tooltips = [];
    if (ConditionalLogicUtil.isUnreachableQuestion(question, Store.poll.questions))
      tooltips.push({
        icon: 'exclamation-circle',
        label:
          'Due to an error in conditional logic this question will never get answered and will be skipped altogether.',
      });

    if (ConditionalLogicUtil.hasQuestionConditionalLogic(question))
      tooltips.push({
        icon: 'poll-condition',
        label: 'This question uses conditional logic.',
      });

    return tooltips;
  }

  /**
   *
   */
  const myProps = {
    updateQuestion,
    removeQuestion,
    uploadVideo,
    uploadMedia,
    uploadImage,
    removeImage,
    removeMedia,
    uploadChoiceImage,
    removeChoiceImage,
    Store,
    ProjectStore,
    ToastrStore,
    HelpStore,
    CHOICE_LABELS,
    openPollPreview,
    openReactionsModal,
    showConditionalLogic,
  };

  function handleSizeChanges(el) {
    if (!el || !isLastItem) return;

    let prevValue = JSON.stringify(el.getBoundingClientRect());
    const handle = setInterval(() => {
      const nextValue = JSON.stringify(el.getBoundingClientRect());
      if (nextValue === prevValue) {
        updateLastItemScrollPosition(el.getBoundingClientRect().top);
        clearInterval(handle);
      } else {
        prevValue = nextValue;
      }
    }, 100);
  }

  function tabNavigationHandler(value) {
    if (value === TAB_NAV_CHOICES.FIRST) {
      setShowConditionalLogic(false);
    } else {
      setShowConditionalLogic(true);
    }
  }

  return (
    <div className={`c-switchable  ${getHeaderClass()} ${classModifier}`} ref={(el) => handleSizeChanges(el)}>
      {!typeMeta.isQQ && (
        <SwitchableHeader
          id={_id}
          label={typeMeta.label}
          name={questionText}
          orderNumber={orderNumber}
          icon={typeMeta.icon}
          toggleOpened={toggleOpened}
          isActive={question.isActive}
          tooltips={getHeaderTooltips()}
        />
      )}

      {!typeMeta.isQQ && (
        <HelpActionButton
          helpKey={HelpUtils.getHelpKeyForQuestionHeader(questionType)}
          isTextOnly={true}
          autoClose={false}
        />
      )}

      {typeMeta.isQQ && (
        <QQSwitchableHeader name={questionText} toggleOpened={toggleOpened} isActive={question.isActive} />
      )}
      {/* <div className='c-switchable__content'>Question type </div> */}

      <div className='c-switchable__content'>
        {!typeMeta.isQQ && (
          <TabNavigation
            firstOptionLabel='Edit question'
            secondOptionLabel='Edit conditional logic'
            onSelectionChange={tabNavigationHandler}
          />
        )}

        {questionType === QUESTION_TYPES.TWO_IMAGE_SINGLE_CHOICE && (
          <TwoImageQuestion question={question} {...myProps} />
        )}
        {questionType === QUESTION_TYPES.THREE_IMAGE_SINGLE_CHOICE && (
          <ThreeImageQuestion question={question} {...myProps} />
        )}
        {questionType === QUESTION_TYPES.FOUR_IMAGE_SINGLE_CHOICE && (
          <FourImageQuestion question={question} {...myProps} />
        )}
        {questionType === QUESTION_TYPES.MULTIPLE_IMAGE_MULTIPLE_CHOICE && (
          <MultipleImageQuestion question={question} {...myProps} />
        )}
        {questionType === QUESTION_TYPES.MULTIPLE_IMAGE_OTHER_CHOICE && (
          <ImageChoiceOther question={question} {...myProps} />
        )}
        {questionType === QUESTION_TYPES.THREE_SLIDER_CHOICE_VALUES && (
          <ThreeSliderQuestion question={question} {...myProps} />
        )}
        {questionType === QUESTION_TYPES.ORDERING_CHOICE && <ReorderQuestion question={question} {...myProps} />}
        {questionType === QUESTION_TYPES.SINGLE_SLIDER_CHOICE && <SliderQuestion question={question} {...myProps} />}
        {questionType === QUESTION_TYPES.SLIDER_FEEDBACK && <SliderFeedback question={question} {...myProps} />}
        {questionType === QUESTION_TYPES.DESCRIPTION_FACT && <DescriptionFact question={question} {...myProps} />}
        {questionType === QUESTION_TYPES.SINGLE_IMAGE_FACT && <SingleImageFact question={question} {...myProps} />}
        {questionType === QUESTION_TYPES.TWO_IMAGE_FACT && <TwoImageFact question={question} {...myProps} />}
        {questionType === QUESTION_TYPES.FEEDBACK && <FeedbackQuestion question={question} {...myProps} />}
        {questionType === QUESTION_TYPES.ANNOTATION_IMAGE && <AnnotateQuestion question={question} {...myProps} />}
        {questionType === QUESTION_TYPES.ANNOTATION_MAP && <AnnotateQuestion question={question} {...myProps} />}
        {questionType === QUESTION_TYPES.QUICK_TEXT && <QuickText question={question} {...myProps} />}
        {questionType === QUESTION_TYPES.RATING && <RatingQuestion question={question} {...myProps} />}

        {/* Segmentation questionsTypes ~ QualifyingQuestion */}
        {questionType === QUESTION_TYPES.QQ_TWO_IMAGE_SINGLE_CHOICE && (
          <QQTwoImageQuestion question={question} {...myProps} />
        )}
        {questionType === QUESTION_TYPES.QQ_THREE_IMAGE_SINGLE_CHOICE && (
          <QQThreeImageQuestion question={question} {...myProps} />
        )}
        {questionType === QUESTION_TYPES.QQ_FOUR_IMAGE_SINGLE_CHOICE && (
          <QQFourImageQuestion question={question} {...myProps} />
        )}
        {questionType === QUESTION_TYPES.QQ_MULTIPLE_IMAGE_MULTIPLE_CHOICE && (
          <QQTenImageQuestion question={question} {...myProps} />
        )}
      </div>
    </div>
  );
}

QuestionSwitcherFn.propTypes = {
  classModifier: PropTypes.string,
  question: PropTypes.object.isRequired,
  Store: PropTypes.object.isRequired,
  ProjectStore: PropTypes.object,
  HelpStore: PropTypes.object.isRequired,
  ToastrStore: PropTypes.object.isRequired,
  isQQ: PropTypes.bool,
  openPollPreview: PropTypes.func,
  updateLastItemScrollPosition: PropTypes.func,
  openReactionsModal: PropTypes.func,
  orderNumber: PropTypes.number,
};

const QuestionSwitcher = observer(QuestionSwitcherFn);
export default QuestionSwitcher;
