import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Form } from 'react-final-form';

import ConditionalLogicUtil from '../../../../../common/ConditionalLogicUtil';
import EngageTextInputField from '../../../../../common/components/form-elements/textInputField/TextInputField';
import SwitchableButtonBar from '../../../../../common/components/switchableButtonBar/SwitchableButtonBar';
import { QUESTION_CHARACTER_LIMIT } from '../../../../../common/constants/QuestionConstants';
import { getFormFieldClass, getFormFieldTranslationTooltip } from '../../../../../common/TranslationUtils';
import ConditionalLogic from '../../../../../common/components/conditionalLogic/ConditionalLogic';
import LeadImage from '../../../../../common/components/form-elements/leadImage/LeadImage';
import { IMAGE_FORMATS } from '../../../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import { LEAD_IMAGE_TYPES } from '../../../../../shared/engage/engage-question-constants';

function SliderQuestion(props) {
  const {
    question,
    updateQuestion,
    removeMedia,
    uploadMedia,
    uploadVideo,
    openPollPreview,
    showConditionalLogic,
    Store,
  } = props;

  const { _id, resource, defaultData } = question;

  const [formValues, setFormValues] = useState();
  const [rules, setRules] = useState([]);

  useEffect(() => {
    if (!question) return;

    const initialFormQuestionValues = {
      questionText: question.questionText,
      leadImageType: question.questionConfiguration?.leadImageType || LEAD_IMAGE_TYPES.STANDARD_SINGLE_IMAGE,
    };

    const initialState = ConditionalLogicUtil.setupFormInitialValues(question);
    setFormValues({ ...initialFormQuestionValues, ...initialState.initialFormConditionValues });
    setRules(initialState.initialRules);
  }, [question]);

  const onSubmit = (values) => {
    // console.log('SUBMIT', values);
    const updatedQuestionData = toJS(question);
    updatedQuestionData.questionSubtext = values.questionSubtext;
    updatedQuestionData.questionText = values.questionText;
    updatedQuestionData.questionConfiguration.leadImageType = values.leadImageType;

    ConditionalLogicUtil.extractConditionalFormValues(question, updatedQuestionData, values, rules);

    updateQuestion(updatedQuestionData);
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={formValues}
      render={({ values, form, handleSubmit, submitting, pristine }) => {
        return (
          <form className='c-fields l-form' onSubmit={(event) => event.preventDefault()}>
            <EngageTextInputField
              id={`input-question-${question._id}`}
              placeholder='Enter question'
              label='Question'
              maxLength={QUESTION_CHARACTER_LIMIT}
              fieldDescription=''
              fieldName='questionText'
              isRequired={true}
              fieldClass={getFormFieldClass(question.defaultData.questionText, null, 'l-form__item')}
              tooltipInLabel={getFormFieldTranslationTooltip(question.defaultData.questionText)}
            />
            <div className='c-toggle-content'>
              <div className='c-toggle-content__container'>
                <ConditionalLogic
                  formValues={values}
                  setFormValues={setFormValues}
                  question={question}
                  questions={Store.poll.questions}
                  rules={rules}
                  setRules={setRules}
                  show={showConditionalLogic}
                />
                <div className='c-toggle-content__content' hidden={showConditionalLogic}>
                  <div className='l-form'>
                    <LeadImage
                      questionId={_id}
                      resource={resource}
                      defaultResource={defaultData?.resource}
                      store={Store}
                      label='Lead image'
                      uploadImage={uploadMedia}
                      uploadVideo={uploadVideo}
                      removeMedia={removeMedia}
                      fieldName='leadImageType'
                      leadImageType={values.leadImageType}
                      classModifier={getFormFieldClass(null, defaultData.resource)}
                      tooltipInLabel={getFormFieldTranslationTooltip(
                        null,
                        defaultData.resource,
                        IMAGE_FORMATS.FLUID_PANEL,
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>

            <SwitchableButtonBar
              updateAction={handleSubmit}
              removeAction={props.removeQuestion}
              isNew={!question._id}
              previewAction={() => openPollPreview(question._id)}
            />
          </form>
        );
      }}
    />
  );
}

SliderQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  openPollPreview: PropTypes.func.isRequired,
  uploadMedia: PropTypes.func.isRequired,
  uploadVideo: PropTypes.func.isRequired,
  removeMedia: PropTypes.func.isRequired,
  Store: PropTypes.object.isRequired,
  showConditionalLogic: PropTypes.bool.isRequired,
};

export default observer(SliderQuestion);
