import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import history from '../common/history';
import { CLIENTS, PROFILE, PROJECTS, STAFF } from '../common/constants/RouteConstants';

/**
 * Redirect to appropriate Homepage
 */
function HomeRedirect(props) {
  const { AuthStore } = props;

  useEffect(() => {
    if (AuthStore.isSingleClient) {
      history.push(`/${PROFILE}/${PROJECTS}`);
    } else {
      history.push(`/${STAFF}/${CLIENTS}`);
    }
  }, [AuthStore.isSingleClient]);

  return <h1>Redirecting</h1>;
}

export default inject((root) => ({ AuthStore: root.RootStore.authStore }))(observer(HomeRedirect));
