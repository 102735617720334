import React from 'react';
import PropTypes from 'prop-types';
import MDEditor from '@uiw/react-md-editor';
import QuestionTemplateAction from './QuestionTemplateAction';
import QuestionHeader from '../../../reports/QuestionHeader';
import { MARKDOWN_MID } from '../../../../../common/components/form-elements/markdownInputField/MarkdownConstants';
import QuestionTemplateHeader from './QuestionTemplateHeader';

function FactTextPreview(props) {
  const { question } = props;

  return (
    <div className='c-library__item'>
      <div className='c-question c-question--fact c-question--library'>
        <QuestionTemplateHeader question={question} showTitle={false} />
        <div className='c-question__content'>
          <div className='c-question-fact'>
            <div className='c-question-fact__content u-rich-text'>
              <MDEditor.Markdown source={question.questionText} className='markdown_preview' />
            </div>
          </div>
        </div>
      </div>

      <QuestionTemplateAction question={question} />
    </div>
  );
}

FactTextPreview.propTypes = {
  question: PropTypes.shape({
    questionText: PropTypes.string.isRequired,
  }).isRequired,
};

export default FactTextPreview;
