import React from 'react';
import ReactDOM from 'react-dom';
import { configure } from 'mobx';
import { Provider } from 'mobx-react';

// import './App.css';
import './shared/design/assets-v2/stylesheets/style.scss';
import 'toastr/build/toastr.css';

// MobX stores
import RootStore from './store/RootStore';

import App from './App';
import * as serviceWorker from './serviceWorker';

import { AbilityContext } from './common/Can';
import ability from './common/ability';

/**
 * INITIALIZATIONS
 */
// Mobx configure
configure({
  enforceActions: 'observed',
});

ReactDOM.render(
  <AbilityContext.Provider value={ability}>
    <Provider RootStore={new RootStore()}>
      <App />
    </Provider>
  </AbilityContext.Provider>,
  document.getElementById('top'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
