import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SlimUploader from '../form-elements/fileUpload/SlimUploader';
import SnapshotableImage from '../snapshotableImage/ScreenshotableImage';
import { IMAGE_FORMATS } from '../form-elements/fileUpload/ImageFormatEnums';
import SvgIcon from '../SvgIcon';
import { getVideoThumbnail } from '../../UrlUtils';

function MediaItem(props) {
  const {
    type,
    mediaId,
    mediaUrl,
    removeMedia,
    imageClassModifier,
    videoClassModifier,
    linkToVideo,
    showButton,
    setShowCaption,
  } = props;

  const [deleteEnabled, setDeleteEnabled] = useState(true);

  async function removeVideoHandler(event) {
    setDeleteEnabled(false);
    await removeMedia(event, mediaId);
    setDeleteEnabled(true);
  }

  return (
    <>
      {type === 'image' && (
        <div className='c-upload-caption__media'>
          {showButton && (
            <button
              className='o-button o-button--s c-upload-caption__add'
              type='button'
              onClick={() => setShowCaption(true)}
            >
              <SvgIcon icon='pen-alt' />
              <span className='o-label'>Add caption</span>
            </button>
          )}
          {removeMedia ? (
            <SlimUploader
              imageFormat={IMAGE_FORMATS.PANEL}
              imageId={mediaId}
              imageUrl={mediaUrl}
              id={`${mediaId}`}
              label=''
              tip=''
              removeImage={removeMedia}
              handleUpload={() => {}}
              modifierClass={imageClassModifier}
            />
          ) : (
            <SnapshotableImage resource={mediaUrl} format={IMAGE_FORMATS.PANEL.imageType} />
          )}
        </div>
      )}

      {
        /* VIDEO thumbnail */
        type === 'video' && (
          <React.Fragment>
            <div className={`c-upload c-upload--video ${videoClassModifier}`}>
              <div className='c-upload__content'>
                {linkToVideo ? (
                  <a href={`${mediaUrl}`} rel='noopener noreferrer' target='_blank'>
                    <img src={getVideoThumbnail(mediaUrl)} alt='' />
                  </a>
                ) : (
                  <img src={getVideoThumbnail(mediaUrl)} alt='' />
                )}
                <span className='o-tag is-invert'>Video</span>
                {removeMedia && (
                  <button
                    className='c-upload__remove'
                    onClick={(event) => {
                      removeVideoHandler(event);
                    }}
                    disabled={!deleteEnabled}
                  >
                    <SvgIcon icon='times' />
                    <span className='o-label'>Remove</span>
                  </button>
                )}
              </div>
            </div>
          </React.Fragment>
        )
      }
    </>
  );
}

MediaItem.propTypes = {
  type: PropTypes.string.isRequired,
  mediaId: PropTypes.string.isRequired,
  mediaUrl: PropTypes.string.isRequired,
  removeMedia: PropTypes.func,
  imageClassModifier: PropTypes.string,
  videoClassModifier: PropTypes.string,
  linkToVideo: PropTypes.bool,
  showButton: PropTypes.bool,
  setShowCaption: PropTypes.func,
};

export default MediaItem;
