import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import InfiniteScroll from 'react-infinite-scroll-component';

import ModalCloseButton from '../buttons/ModalCloseButton';
import GridImage from './GridImage';
import SvgIcon from '../SvgIcon';
import { openModal } from '../../../containers/project/polls/PollUtils';

const UNSPLASH_IMAGES_PER_PAGE = 30;
const UNSPLASH_APP_NAME = 'Give My View';

function ImageLibraryModal(props) {
  const { closeModal, ImageLibraryStore } = props;

  const [showNoResultMessage, setShowNoResultMessage] = useState(false);
  const [unsplashSearchTerm, setUnsplashSearchTerm] = useState('');
  const [unsplashSearchedTerm, setUnsplashSearchedTerm] = useState('');
  const [unsplashCurrentPage, setUnsplashCurrentPage] = useState(1);
  const [unsplashHasMoreToLoad, setUnsplashHasMoreToLoad] = useState(true);
  const [unsplashImages, setUnsplashImages] = useState([]);

  const searchElement = useRef(null);

  useEffect(() => {
    if (searchElement.current) {
      searchElement.current.focus();
    }
  }, []);

  useEffect(() => {
    return openModal(closeModal);
  }, [closeModal]);

  function searchUnsplash(event) {
    if (event.key === 'Enter') {
      setUnsplashSearchedTerm(unsplashSearchTerm);
      ImageLibraryStore.loadUnsplashImages(unsplashSearchTerm, 1, UNSPLASH_IMAGES_PER_PAGE).then((data) => {
        if (data) {
          setUnsplashCurrentPage(1);
          setUnsplashImages([...data.results]);

          if (data.total_pages === 1) {
            setUnsplashHasMoreToLoad(false);
          } else {
            setUnsplashHasMoreToLoad(true);
          }

          if (data.results.length === 0) {
            setShowNoResultMessage(true);
          } else {
            setShowNoResultMessage(false);
          }
        }
      });
    }
  }

  function clearUnsplashSearch() {
    setUnsplashSearchTerm('');
    setUnsplashSearchedTerm('');
    setUnsplashHasMoreToLoad(false);
    setUnsplashImages([]);
  }

  function unsplashLoadNext() {
    const nextPage = unsplashCurrentPage + 1;

    ImageLibraryStore.loadUnsplashImages(unsplashSearchedTerm, nextPage, UNSPLASH_IMAGES_PER_PAGE).then((data) => {
      if (data) {
        if (data.total_pages >= nextPage) {
          setUnsplashCurrentPage(nextPage);
        } else {
          setUnsplashHasMoreToLoad(false);
        }

        setUnsplashImages([...unsplashImages, ...data.results]);
      }
    });
  }

  function imageSelectHandler(downloadLocationUrl) {
    ImageLibraryStore.getUnsplashImageDownloadUrl(downloadLocationUrl);
  }

  return (
    <div id='c-modal' className='c-modal c-modal--fill-screen c-modal--centered-wide' style={{ display: 'flex' }}>
      <div className='c-modal__container'>
        <div className='c-modal__header'>
          <h2 className='c-modal__title'>Image Library</h2>
          <ModalCloseButton closeModal={closeModal} />
        </div>
        <div className='c-modal__content'>
          <div className='c-media'>
            <div className='c-media__content'>
              <h2 className='c-heading-main'>Search images</h2>
              <div className='c-media__search'>
                <div className='c-fields l-form'>
                  <div className='c-field l-form__item c-field--label-hidden'>
                    <label className='c-field__label' htmlFor='unsplash-search'>
                      Search
                    </label>
                    <div className='c-field__group'>
                      <div className='c-suggest c-suggest--floating js-suggest'>
                        <div className='o-input o-input-icon o-input-clear'>
                          <input
                            type='text'
                            id='unsplash-search'
                            ref={searchElement}
                            placeholder='Search images on Unsplash'
                            value={unsplashSearchTerm}
                            onChange={(event) => setUnsplashSearchTerm(event.target.value)}
                            onKeyPress={searchUnsplash}
                          />
                          <SvgIcon icon='search' />
                          {unsplashSearchTerm && (
                            <button className='o-button' onClick={clearUnsplashSearch}>
                              <SvgIcon icon='times' />
                              <span className='o-label'>Clear</span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {showNoResultMessage && <p>There are no results with this keyword.</p>}
              <div className='c-image-grid'>
                <div className='c-image-grid__attribution'>
                  Photos by{' '}
                  <a
                    href={`https://unsplash.com/?utm_source=${UNSPLASH_APP_NAME}&utm_medium=referral`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Unsplash
                  </a>
                </div>

                <InfiniteScroll
                  dataLength={unsplashImages.length}
                  next={unsplashLoadNext}
                  hasMore={unsplashHasMoreToLoad}
                  scrollableTarget='c-modal'
                  style={{ overflow: 'inital' }}
                >
                  <div className='c-image-grid__items'>
                    {unsplashImages.map((image, index) => (
                      <GridImage
                        key={index}
                        image={image}
                        onImageSelect={(imageDownloadURL) => imageSelectHandler(imageDownloadURL)}
                        unsplashAppName={UNSPLASH_APP_NAME}
                      />
                    ))}
                  </div>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ImageLibraryModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  ImageLibraryStore: PropTypes.object.isRequired,
};

export default inject((root) => ({ ImageLibraryStore: root.RootStore.imageLibraryStore }))(observer(ImageLibraryModal));

//               !!! DO NOT DELETE !!!
// CODE BELOW IS VERSION THAT INCLUDES OTHER SOURCES EXCEPT UNSPASH
//               !!! DO NOT DELETE !!!

// import React, { useState } from 'react';
// import PropTypes from 'prop-types';
// import { inject, observer } from 'mobx-react';

// import ModalCloseButton from '../buttons/ModalCloseButton';
// import GridImage from './GridImage';
// import InfiniteScroll from 'react-infinite-scroll-component';
// import SvgIcon from '../SvgIcon';

// const SEARCH_IMAGES = 'SEARCH_IMAGES';
// const GMV_LIBRARY = 'GMV_LIBRARY';
// const RECENT_IMAGES = 'RECENT_IMAGES';
// const FAVOURITES_IMAGES = 'FAVOURITES_IMAGES';

// const UNSPLASH_IMAGES_PER_PAGE = 30;

// function ImageLibraryModal(props) {
//   const { ImageLibraryStore } = props;

//   const [currentActiveNav, setCurrentActiveNav] = useState(SEARCH_IMAGES);

//   const [showNoResultMessage, setShowNoResultMessage] = useState(false);
//   const [unsplashSearchTerm, setUnsplashSearchTerm] = useState('');
//   const [unsplashCurrentPage, setUnsplashCurrentPage] = useState(1);
//   const [unsplashHasMoreToLoad, setUnsplashHasMoreToLoad] = useState(true);
//   const [unsplashImages, setUnsplashImages] = useState([]);

//   const [gmvCategoryImages, setGmvCategoryImages] = useState([]);
//   const [gmvCategories, setGmvCategories] = useState([]);
//   const [selectedGmvCategory, setSelectedGmvCategory] = useState('');

//   const [recentImages, setRecentImages] = useState([]);
//   const [favouriteImages, setFavouriteImages] = useState([]);

//   function navHandler(selected) {
//     setCurrentActiveNav(selected);

//     if (selected === GMV_LIBRARY) {
//       ImageLibraryStore.loadGmvLibraryCategories().then(data => setGmvCategories(data));
//     } else if (selected === RECENT_IMAGES) {
//       ImageLibraryStore.loadRecentImages().then(data => setRecentImages(data));
//     } else if (selected === FAVOURITES_IMAGES) {
//       ImageLibraryStore.loadFavouriteImages().then(data => setFavouriteImages(data));
//     }
//   }

//   function searchUnsplash(event) {
//     if (event.key === 'Enter') {
//       setUnsplashSearchTerm(unsplashSearchTerm);
//       ImageLibraryStore.loadUnsplashImages(unsplashSearchTerm, 1, UNSPLASH_IMAGES_PER_PAGE).then(data => {
//         if (data) {
//           setUnsplashCurrentPage(1);
//           setUnsplashImages([...data.results]);

//           if (data.total_pages === 1) {
//             setUnsplashHasMoreToLoad(false);
//           } else {
//             setUnsplashHasMoreToLoad(true);
//           }

//           if (data.results.length === 0) {
//             setShowNoResultMessage(true);
//           } else {
//             setShowNoResultMessage(false);
//           }
//         }
//       });
//     }
//   }

//   function clearUnsplashSearch() {
//     setUnsplashSearchTerm('');
//     setUnsplashHasMoreToLoad(false);
//     setUnsplashImages([]);
//   }

//   function unsplashLoadNext() {
//     const nextPage = unsplashCurrentPage + 1;

//     ImageLibraryStore.loadUnsplashImages(unsplashSearchTerm, nextPage, UNSPLASH_IMAGES_PER_PAGE).then(data => {
//       if (data) {
//         if (data.total_pages >= nextPage) {
//           setUnsplashCurrentPage(nextPage);
//         } else {
//           setUnsplashHasMoreToLoad(false);
//         }

//         setUnsplashImages([...unsplashImages, ...data.results]);
//       }
//     });
//   }

//   function gmvCategorySelectHandler(category) {
//     setSelectedGmvCategory(category);

//     ImageLibraryStore.loadGmvCategoryImages(category).then(result => {
//       if (result) setGmvCategoryImages(result);
//     });
//   }

//   function imageSelectHandler(url) {
//     ImageLibraryStore.currentlySelectedUrl = url;
//     ImageLibraryStore.modalOpen = false;
//   }

//   return (
//     <div id='c-modal' className='c-modal c-modal--fill-screen' style={{ display: 'block' }}>
//       <div className='c-modal__container'>
//         <div className='c-modal__header'>
//           <h2 className='c-modal__title'>Question Library</h2>
//           <ModalCloseButton closeModal={() => (ImageLibraryStore.modalOpen = false)} />
//         </div>
//         <div className='c-modal__content'>
//           <div className='c-media'>
//             <div className='c-media__options'>
//               <nav className='c-nav-list'>
//                 <ul className='c-nav-list__items'>
//                   <li className={`c-nav-list__item ${currentActiveNav === SEARCH_IMAGES ? 'is-active' : ''}`}>
//                     <a className='c-nav-list__link' href='#media-search' onClick={() => navHandler(SEARCH_IMAGES)}>
//                       Search images
//                     </a>
//                   </li>
//                   <li className={`c-nav-list__item ${currentActiveNav === GMV_LIBRARY ? 'is-active' : ''}`}>
//                     <a className='c-nav-list__link' href='#media-library' onClick={() => navHandler(GMV_LIBRARY)}>
//                       Give My View library
//                     </a>
//                   </li>
//                   <li className={`c-nav-list__item ${currentActiveNav === RECENT_IMAGES ? 'is-active' : ''}`}>
//                     <a className='c-nav-list__link' href='#media-recent' onClick={() => navHandler(RECENT_IMAGES)}>
//                       Recently uploaded
//                     </a>
//                   </li>
//                   <li className={`c-nav-list__item ${currentActiveNav === FAVOURITES_IMAGES ? 'is-active' : ''}`}>
//                     <a
//                       className='c-nav-list__link'
//                       href='#media-favourites'
//                       onClick={() => navHandler(FAVOURITES_IMAGES)}
//                     >
//                       Favourites
//                     </a>
//                   </li>
//                 </ul>
//               </nav>
//             </div>

//             {currentActiveNav === SEARCH_IMAGES && (
//               <div className='c-media__content'>
//                 <h2 className='c-heading-main'>Search images</h2>
//                 <div className='c-media__search'>
//                   <div className='c-fields l-form'>
//                     <div className='c-field l-form__item c-field--label-hidden'>
//                       <label className='c-field__label' htmlFor='unsplash-search'>
//                         Search
//                       </label>
//                       <div className='c-field__group'>
//                         <div className='c-suggest c-suggest--floating js-suggest'>
//                           <div className='o-input o-input-icon o-input-clear'>
//                             <input
//                               type='text'
//                               id='unsplash-search'
//                               placeholder='Search images on Unsplash'
//                               value={unsplashSearchTerm}
//                               onChange={event => setUnsplashSearchTerm(event.target.value)}
//                               onKeyPress={searchUnsplash}
//                             />
//                             <SvgIcon icon='search' />
//                             {unsplashSearchTerm && (
//                               <button className='o-button' onClick={clearUnsplashSearch}>
//                                 <SvgIcon icon='times' />
//                                 <span className='o-label'>Clear</span>
//                               </button>
//                             )}
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 {showNoResultMessage && <p>There are no results with this keyword.</p>}
//                 <InfiniteScroll
//                   dataLength={unsplashImages.length}
//                   next={unsplashLoadNext}
//                   hasMore={unsplashHasMoreToLoad}
//                   scrollableTarget='c-modal'
//                 >
//                   <div className='c-image-grid'>
//                     {unsplashImages.map((image, index) => (
//                       <GridImage key={image.id} image={image} onImageSelect={url => imageSelectHandler(url)} />
//                     ))}
//                   </div>
//                 </InfiniteScroll>
//               </div>
//             )}

//             {currentActiveNav === GMV_LIBRARY && (
//               <div className='c-media__content'>
//                 <h2 className='c-heading-main'>Give My View library</h2>
//                 <div className='c-media__filter'>
//                   <div className='c-fields l-form'>
//                     <div className='c-field l-form__item'>
//                       <label className='c-field__label'>Select category</label>
//                       <div className='c-field__group'>
//                         {gmvCategories.map((category, index) => (
//                           <div key={category._id} className='o-radio o-radio--inline o-radio--s o-radio--button'>
//                             <input
//                               type='radio'
//                               id={`image-categories-${category._id}`}
//                               name='image-categories'
//                               checked={selectedGmvCategory === category._id}
//                               onChange={event => gmvCategorySelectHandler(category.label)}
//                             />
//                             <label htmlFor={`image-categories-${category._id}`} />
//                           </div>
//                         ))}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className='c-image-grid'>
//                   {gmvCategoryImages.map((image, index) => (
//                     <GridImage key={image.id} />
//                   ))}
//                 </div>
//               </div>
//             )}

//             {currentActiveNav === RECENT_IMAGES && (
//               <div className='c-media__content'>
//                 <h2 className='c-heading-main'>Recently uploaded</h2>
//                 <div className='c-image-grid'>
//                   {recentImages.map((image, index) => (
//                     <GridImage key={image.id} />
//                   ))}
//                 </div>
//               </div>
//             )}

//             {currentActiveNav === FAVOURITES_IMAGES && (
//               <div className='c-media__content'>
//                 <h2 className='c-heading-main'>Favourites</h2>
//                 <div className='c-image-grid'>
//                   {favouriteImages.map((image, index) => (
//                     <GridImage key={image.id} />
//                   ))}
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// ImageLibraryModal.propTypes = {
//   ImageLibraryStore: PropTypes.object.isRequired,
// };

// export default inject(root => ({ ImageLibraryStore: root.RootStore.imageLibraryStore }))(observer(ImageLibraryModal));
