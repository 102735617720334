import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SortableElement } from 'react-sortable-hoc';
import { Field } from 'react-final-form';
import SvgIcon from '../../SvgIcon';
import DragHandle from '../../switchableHeader/DragHandle';
import ImageUtils from '../../../ImageUtils';
import { IMAGE_FORMATS } from '../fileUpload/ImageFormatEnums';

function ReactionChoice(props) {
  const { choice, fieldName, placeholder, maxLength, removeReaction } = props;
  const [showCounter, setShowCounter] = useState(false);

  const resource = choice.resource || choice?.defaultData?.resource;
  const validate = (value) => {
    const val = value && value.trim();
    return !val ? 'Required' : undefined;
  };

  return (
    <Field name={fieldName} type='text' placeholder={placeholder} validate={validate}>
      {/* eslint-disable-next-line no-shadow */}
      {({ input, meta, placeholder }) => {
        return (
          <div
            className={`c-field c-field--label-hidden c-field--reorder c-field--reaction  ${
              meta.error ? 'is-error' : ''
            }`}
          >
            <DragHandle fieldClass='c-field__handle' />
            <div className='c-field__group'>
              <div className='c-upload-preview'>
                <div className='c-upload-preview__content'>
                  <img src={ImageUtils.pngImageUrl(resource, IMAGE_FORMATS.REACTION.imageType)} alt='' />
                </div>
                <button className='c-upload-preview__remove' type='button' onClick={() => removeReaction(choice._id)}>
                  <SvgIcon icon='times' />
                  <span className='o-label'>Remove</span>
                </button>
              </div>
              <div className='o-input'>
                <input
                  {...input}
                  id={`input-question-reaction-${choice._id}`}
                  placeholder={`${meta.error ? meta.error : placeholder}`}
                  maxLength='20'
                  onFocus={() => setShowCounter(true)}
                  onBlur={() => setShowCounter(false)}
                />
              </div>
              {maxLength && showCounter && (
                <span
                  className={`c-field__count ${
                    maxLength && maxLength - input.value.length < 6 ? 'c-field__count--limit' : ''
                  }`}
                  title='Characters left'
                >
                  {maxLength ? maxLength - input.value.length : ''}
                </span>
              )}
            </div>
          </div>
        );
      }}
    </Field>
  );
}

ReactionChoice.propTypes = {
  fieldName: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  validator: PropTypes.func,
  maxLength: PropTypes.string,
};

/**
 * Sortable item wrapper
 */
const SortableItem = SortableElement((props) => {
  return <ReactionChoice {...props} />;
});

export default SortableItem;
