/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { QUESTION_TYPES } from '../../../../common/constants/QuestionConstants';
import SliderPreview from './questions/SliderPreview';
import ReorderPreview from './questions/ReorderPreview';
import FeedbackPreview from './questions/FeedbackPreview';
import FactTextPreview from './questions/FactTextPreview';
import FactMediaPreview from './questions/FactMediaPreview';
import FactComparePreview from './questions/FactComparePreview';
import ImagePreview from './questions/ImagePreview';
import SliderFeedbackPreview from './questions/SliderFeedbackPreview';
import DistributePreview from './questions/DistributePreview';
import AnnotateImagePreview from './questions/AnnotateImagePreview';
import AnnotateMapPreview from './questions/AnnotateMapPreview';
import QuickTextPreview from './questions/QuickTextPreview';
import RatingPreview from './questions/RatingPreview';

export default function QuestionReportSwitcher(props) {
  const { question, fromDate, toDate, pollName, onOpenFeedbackModal } = props;

  return (
    <React.Fragment>
      {(question.questionType === QUESTION_TYPES.TWO_IMAGE_SINGLE_CHOICE ||
        question.questionType === QUESTION_TYPES.THREE_IMAGE_SINGLE_CHOICE ||
        question.questionType === QUESTION_TYPES.FOUR_IMAGE_SINGLE_CHOICE ||
        question.questionType === QUESTION_TYPES.MULTIPLE_IMAGE_OTHER_CHOICE ||
        question.questionType === QUESTION_TYPES.MULTIPLE_IMAGE_MULTIPLE_CHOICE) && (
        <ImagePreview question={question} fromDate={fromDate} toDate={toDate} />
      )}

      {question.questionType === QUESTION_TYPES.SINGLE_SLIDER_CHOICE && <SliderPreview question={question} />}

      {question.questionType === QUESTION_TYPES.SLIDER_FEEDBACK && (
        <SliderFeedbackPreview
          question={question}
          fromDate={fromDate}
          toDate={toDate}
          showEmoji={true}
          onOpenFeedbackModal={onOpenFeedbackModal}
        />
      )}

      {question.questionType === QUESTION_TYPES.THREE_SLIDER_CHOICE_VALUES && <DistributePreview question={question} />}

      {question.questionType === QUESTION_TYPES.FEEDBACK && (
        <FeedbackPreview
          question={question}
          fromDate={fromDate}
          toDate={toDate}
          onOpenFeedbackModal={onOpenFeedbackModal}
        />
      )}

      {question.questionType === QUESTION_TYPES.ORDERING_CHOICE && <ReorderPreview question={question} />}

      {question.questionType === QUESTION_TYPES.SINGLE_IMAGE_FACT && <FactMediaPreview question={question} />}

      {question.questionType === QUESTION_TYPES.TWO_IMAGE_FACT && <FactComparePreview question={question} />}

      {question.questionType === QUESTION_TYPES.DESCRIPTION_FACT && <FactTextPreview question={question} />}

      {question.questionType === QUESTION_TYPES.ANNOTATION_IMAGE && (
        <AnnotateImagePreview
          question={question}
          fromDate={fromDate}
          toDate={toDate}
          pollName={pollName}
          onOpenFeedbackModal={onOpenFeedbackModal}
        />
      )}
      {question.questionType === QUESTION_TYPES.ANNOTATION_MAP && (
        <AnnotateMapPreview
          question={question}
          fromDate={fromDate}
          toDate={toDate}
          pollName={pollName}
          onOpenFeedbackModal={onOpenFeedbackModal}
        />
      )}
      {question.questionType === QUESTION_TYPES.QUICK_TEXT && (
        <QuickTextPreview
          question={question}
          fromDate={fromDate}
          toDate={toDate}
          onOpenFeedbackModal={onOpenFeedbackModal}
        />
      )}
      {question.questionType === QUESTION_TYPES.RATING && (
        <RatingPreview
          question={question}
          fromDate={fromDate}
          toDate={toDate}
          onOpenFeedbackModal={onOpenFeedbackModal}
        />
      )}
    </React.Fragment>
  );
}

QuestionReportSwitcher.propTypes = {
  question: PropTypes.object.isRequired,
  fromDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  toDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  pollName: PropTypes.string,
  onOpenFeedbackModal: PropTypes.func,
};
