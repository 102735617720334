import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { inject, observer } from 'mobx-react';
import { SortableElement, SortableContainer } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

import SwitchableHeader from '../../../common/components/switchableHeader/SwitchableHeader';
import EngageTextInputField from '../../../common/components/form-elements/textInputField/TextInputField';
import SwitchableButtonBar from '../../../common/components/switchableButtonBar/SwitchableButtonBar';
import EngageRangeInputField from '../../../common/components/form-elements/rangeInputField/RangeInputField';
import EngageMarkdownInputField, {
  MARKDOWN_MIN,
} from '../../../common/components/form-elements/markdownInputField/MarkdownInputField';
import Stage from './Stage';
import displayConfirmationPrompt from '../../../common/PromptUtil';
import { getFormFieldClass, getFormFieldTranslationTooltip } from '../../../common/TranslationUtils';

function UpdatesContainerFn(props) {
  const { phase, allowDrag } = props;
  return (
    <div className='c-switchable__nested'>
      {phase.updates.map((stage, index) => (
        <Stage
          key={stage._id}
          index={index}
          phaseId={phase._id}
          stage={stage}
          switchableModifier='c-switchable--nested c-switchable--reorder'
          disabled={!allowDrag}
        />
      ))}
    </div>
  );
}
const UpdatesContainer = inject((root) => ({ Store: root.RootStore.charitiesStore }))(observer(UpdatesContainerFn));

const SortableUpdatesList = SortableContainer((props) => {
  return <UpdatesContainer {...props} />;
});

function PhaseFn(props) {
  const { phase, Store, ToastrStore } = props;

  const [allowDrag, setAllowDrag] = useState(true);

  const initialValues = {
    phaseTitle: phase.phaseTitle,
    phaseDescription: phase.phaseDescription,
    phasePercentage: `${phase.phasePercentage}`,
  };

  const toggleOpened = () => {
    Store.toggleOpened(phase);
  };

  const onSubmit = (values) => {
    const newPhase = {
      phaseTitle: values.phaseTitle,
      phaseDescription: values.phaseDescription ? values.phaseDescription : '',
      phasePercentage: `${values.phasePercentage}`,
    };
    Store.updatePhase(phase._id, newPhase);
  };

  const remove = (event) => {
    event.preventDefault();

    const res = displayConfirmationPrompt(null, null, false, ToastrStore, phase.updates.length > 0);

    if (res === 1) Store.removePhase(phase._id);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setAllowDrag(false);
    const newOrder = arrayMoveImmutable(phase.updates, oldIndex, newIndex);
    Store.reorderUpdates(phase._id, newOrder).then(() => {
      setAllowDrag(true);
    });
  };

  const addUpdate = () => {
    // console.log('Add update');
    Store.createUpdate(phase._id);
  };

  return (
    <div className={`c-switchable ${props.switchableModifier} ${phase.isOpened ? 'is-opened' : 'is-closed'}`}>
      <SwitchableHeader
        id={phase._id}
        label='Phase'
        name={phase.phaseTitle}
        icon='calendar-alt'
        toggleOpened={toggleOpened}
      />

      <div className='c-switchable__content'>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ values, form, handleSubmit, submitting, pristine }) => {
            return (
              <form className='c-fields l-form' onSubmit={(event) => event.preventDefault()}>
                <EngageTextInputField
                  id={`input-project-title-${phase._id}`}
                  placeholder='Enter name'
                  label='Phase name'
                  maxLength='40'
                  fieldDescription=''
                  fieldName='phaseTitle'
                  isRequired={true}
                  fieldClass={getFormFieldClass(phase.defaultData.phaseTitle, null, 'l-form__item')}
                  tooltipInLabel={getFormFieldTranslationTooltip(phase.defaultData.phaseTitle)}
                />

                <EngageMarkdownInputField
                  id={`input-project-description-${phase._id}`}
                  placeholder='Make it short, sweet and under 300 characters.'
                  label='Phase description'
                  maxLength='300'
                  fieldDescription=''
                  fieldName='phaseDescription'
                  isRequired={false}
                  editorConfig={MARKDOWN_MIN}
                  fieldClass={getFormFieldClass(phase.defaultData.phaseDescription, null, 'l-form__item')}
                  tooltipInLabel={getFormFieldTranslationTooltip(phase.defaultData.phaseDescription)}
                />

                <EngageRangeInputField
                  id={`input-pahse-range-${phase._id}`}
                  label='Phase completion %'
                  fieldName='phasePercentage'
                  min='0'
                  max='100'
                  step='10'
                  fieldClass='l-form__item--m'
                />

                <SwitchableButtonBar updateAction={handleSubmit} removeAction={remove} isNew={!phase._id} />
              </form>
            );
          }}
        />
      </div>

      <SortableUpdatesList phase={phase} useDragHandle={true} onSortEnd={onSortEnd} allowDrag={allowDrag} />

      <div className='c-switchable__actions'>
        <button className='o-button o-button--primary o-button--xs' onClick={addUpdate}>
          <span className='o-label'>Add stage</span>
        </button>
      </div>
    </div>
  );
}
const Phase = inject((root) => ({ Store: root.RootStore.timelineStore, ToastrStore: root.RootStore.toastrStore }))(
  observer(PhaseFn),
);

/**
 * Sortable phase wrapper
 */
const SortableItem = SortableElement((props) => {
  return <Phase {...props} />;
});

export default SortableItem;
