import React from 'react';
import PropTypes from 'prop-types';
import DoughnutChart from '../../../../common/components/charts/DonutChart';
import SvgIcon from '../../../../common/components/SvgIcon';
import { ReportEnums } from '../../../../shared/engage';

function StatGraphBlock(props) {
  const { title, stats, icon, abbr, wrapperClass, showCount } = props;

  /**
   * Adapter for chart data
   * Have to convert [{value, label}]
   * to a format appropriate for svg chart
   *
   How to produce charts:
   1) add `circle.o-pie__segment`
   2) add next color from the set
   3) add `stroke-dasharray="25 75"` for 25%. For 37% it would be "37 64".
   4) add `stroke-dashoffset="X"` where X is calculated by the formula:
   Circumference − All preceding segments’ total length + First segment’s offset = Current segment offset
   100 - 25 + 25

   ### IMPORTANT
   ### Full article: https://medium.com/@heyoka/scratch-made-svg-donut-pie-charts-in-html5-2c587e935d72
   ### Values have to be reported to 100% total

   Color set:
   - #EEF4FB (Grey, default)
   - #78DCA0 (Green)
   - #060049 (Blue)
   - #7882DC (Blue-Violet)
   - #DC78B4 (Red-Violet)
   - #DCD278 (Yellow)
   - #CBE11E (Lime)
   - #E11ECB (Magenta) 

   * @param stats
   * @returns {Array}
   */
  // eslint-disable-next-line no-shadow
  const statsAdapter = (stats) => {
    let tot = 0;
    const res = [];
    let currentOffset = 25;

    // calc total
    stats.map((item) => {
      return (tot += item.value);
    });

    // scale values to %
    stats.map((item, idx) => {
      // value in % of total
      const val = tot === 0 ? 0 : Math.round((item.value / tot) * 100);
      const tmp = {
        value: val,
        rest: 100 - val,
        label: item.label,
        offset: currentOffset,
        count: item.count,
      };

      currentOffset += 100 - val;

      res[idx] = tmp;
      return tmp;
    });

    return res;
  };

  // console.log('STATS', stats);
  /**
   * Need converted values here because I get numeric data from API and
   * on charts I need % on 100% total
   */
  const statsView = statsAdapter(stats);
  // console.log('VIEW', statsView);

  return (
    <div className={`c-stat l-stat ${wrapperClass}`}>
      <h3 className='c-stat__title'>
        <div className='c-stat__icon'>
          <SvgIcon icon={icon} />
        </div>
        {title}
        {abbr && <abbr title={abbr.title}>{abbr.label}</abbr>}
      </h3>
      <div className='c-visualisation__content'>
        <DoughnutChart stats={statsView} />
      </div>
      <div className='c-visualisation__legend'>
        {statsView.map((item, index) => (
          <div key={index} className='c-visualisation__legend-item'>
            <span
              className='c-visualisation__legend-circle'
              style={{ background: ReportEnums().getChartColor(index) }}
            />
            <strong>{item.value || 0}</strong>%
            <ShowCount count={item.count} showCount={showCount} />
            {item.label}
          </div>
        ))}
      </div>
    </div>
  );
}

/**
 * Component to show voters count along with percentage, currently used ONLY for language stats
 * NOTE: WHEN CHANGING COUNT VISUAL, PROPAGE NEW VISUAL IN THE PDF REPORT
 *
 * @param {*} props
 * @returns
 */
function ShowCount(props) {
  const { count, showCount } = props;

  return <>{showCount && <strong> - {count || 0} </strong>}</>;
}

StatGraphBlock.propTypes = {
  title: PropTypes.string,
  stats: PropTypes.array,
  icon: PropTypes.string,
  abbr: PropTypes.object,
  wrapperClass: PropTypes.string,
};

export default StatGraphBlock;
