import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { POLLS, PROJECT } from 'common/constants/RouteConstants';
import SettingsToggle from '../../SettingsToggle';

function PanelVisibility(props) {
  const { Store, projectId } = props;

  const panels = Store?.publishStatus?.panels || [];

  function onChangeHandler(checked, panel) {
    Store.setPanelVisibility(panel, checked);
  }

  return (
    <div className='c-field c-field--modern l-form__item' data-testid='panel-visibility-wrapper'>
      <label className='c-field__label'>Panel visibility</label>
      <div className='c-field__group'>
        {panels.map((panel) => (
          <SettingsToggle
            key={panel._id}
            id={panel._id}
            labelOn={panel.panelIdentifier}
            tag={panel.status === 'active' ? 'Public' : 'Hidden'}
            isActive={panel.status === 'active'}
            onChange={(checked) => onChangeHandler(checked, panel)}
            isDisabled={!panel.isPresentInPublicProject}
          />
        ))}
      </div>
    </div>
  );
}

PanelVisibility.propTypes = {
  Store: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
};

export default inject((root) => ({
  Store: root.RootStore.projectControlStore,
}))(observer(PanelVisibility));
