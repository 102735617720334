import React from 'react';
import PropTypes from 'prop-types';
import { LAST_WEEK, THIS_WEEK, THIS_MONTH, LAST_MONTH } from './DatePickerUtils';

function PredefinedRanges(props) {
  const { onPredefinedRangeSelect } = props;

  return (
    <div className='c-date-picker__presets'>
      <button id={THIS_WEEK} onClick={onPredefinedRangeSelect} className='o-button o-button--s' type='button'>
        <span className='o-label'>This week</span>
      </button>
      <button id={LAST_WEEK} onClick={onPredefinedRangeSelect} className='o-button o-button--s' type='button'>
        <span className='o-label'>Last week</span>
      </button>
      <button id={THIS_MONTH} onClick={onPredefinedRangeSelect} className='o-button o-button--s' type='button'>
        <span className='o-label'>This month</span>
      </button>
      <button id={LAST_MONTH} onClick={onPredefinedRangeSelect} className='o-button o-button--s' type='button'>
        <span className='o-label'>Last month</span>
      </button>
    </div>
  );
}

PredefinedRanges.propTypes = {
  onPredefinedRangeSelect: PropTypes.func.isRequired,
};

export default PredefinedRanges;
