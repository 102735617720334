import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../../SvgIcon';

function RuleFooter(props) {
  const { ruleIndex, rule, rules, onAddRule, onRemoveRule } = props;

  return (
    <div className='c-conditional__rule c-conditional__rule--actions' data-testid='ruleFooter-wrapper'>
      {ruleIndex === rules.length - 1 && (
        <button className='o-button o-button--primary o-button--solid o-button--xs' type='button' onClick={onAddRule}>
          <SvgIcon icon='plus' />
          <span className='o-label'>Add another rule</span>
        </button>
      )}
      <button
        className='o-button o-button--danger o-button--solid o-button--xs'
        type='button'
        onClick={() => onRemoveRule(rule.id)}
      >
        <SvgIcon icon='trash' />
        <span className='o-label'>Remove rule</span>
      </button>
    </div>
  );
}

RuleFooter.propTypes = {
  ruleIndex: PropTypes.number.isRequired,
  rule: PropTypes.object.isRequired,
  rules: PropTypes.array.isRequired,
  onAddRule: PropTypes.func.isRequired,
  onRemoveRule: PropTypes.func.isRequired,
};

export default RuleFooter;
