import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SvgIcon from 'common/components/SvgIcon';

function SettingsToggle(props) {
  const {
    id,
    labelOn,
    labelOff = labelOn,
    tag,
    goToActionUrl,
    goToActionLabel,
    goToActionIcon,
    isActive,
    onChange,
    isDisabled = false,
  } = props;

  function onChangeHandler(e) {
    onChange(e.target.checked);
  }

  return (
    <div className='o-checkbox o-checkbox--toggle' data-testid={`toggle-wrapper-${id}`}>
      <input
        type='checkbox'
        id={`${id}-settings-toggle`}
        checked={isActive}
        onChange={onChangeHandler}
        disabled={isDisabled}
      />
      <label htmlFor={`${id}-settings-toggle`}>
        {isActive ? labelOn : labelOff}
        {tag && <span className='o-tag'>{tag}</span>}
        {goToActionUrl && (
          <Link className='o-link' to={goToActionUrl}>
            {goToActionLabel}
            <SvgIcon icon={goToActionIcon} />
          </Link>
        )}
      </label>
    </div>
  );
}

SettingsToggle.propTypes = {
  id: PropTypes.string.isRequired,
  labelOn: PropTypes.string.isRequired,
  labelOff: PropTypes.string,
  tag: PropTypes.string,
  goToActionUrl: PropTypes.string,
  goToActionLabel: PropTypes.string,
  goToActionIcon: PropTypes.string,
  isActive: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

export default SettingsToggle;
