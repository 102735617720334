exports.PASSWORD_RESET_ERRORS = {
  USER_NOT_FOUND: { errorMessage: 'Email does not exist', errorCode: 1010 },
  CODE_EXPIRED: { errorMessage: 'Password reset code has expired', errorCode: 1020 },
  CODE_MISSMATCH: { errorMessage: 'Invalid password reset code', errorCode: 1030 },
  MISSING_PASSWORD: { errorMessage: 'Password cannot be empty', errorCode: 1040 },
  FAILED_PASSWORD_VALIDATON: {
    errorMessage: `Password's should be at least 8 character long, be a mix of upper and lower case letters and contain at least one number and one symbol (!@?;:)`,
    errorCode: 1050,
  },
  FAILED_PASSWORD_DECRYPTION: {
    errorMessage: 'Password decription failed',
    errorCode: 1060,
  },
  MISSMATCHED_PASSWORDS: { errorMessage: "Passwords don't match", errorCode: 1070 },
};
