import React, { useEffect, useContext, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
// eslint-disable-next-line import/no-cycle
import { PollReportContext } from '../PollsReport';
import QuestionFooter from '../../QuestionFooter';
import QuestionHeader from '../../QuestionHeader';
import PreviewUtils from '../../../../../common/PreviewUtils';
import agent from '../../../../../common/agent/ReportsAgent';
import MediaItem from '../../../../../common/components/mediaItem/MediaItem';

function QuickTextPreview(props) {
  const { question, PollReportStore, ToastrStore, GeneralStore, fromDate, toDate, onOpenFeedbackModal } = props;
  const { questionId, countAnswered, resource, multiChoicesOfferedBound, skipSeen, resourceMediaType } = question;

  const pollReportContext = useContext(PollReportContext);
  const containerReference = useRef();

  const imageId = questionId || null;
  const imageUrl = resource || null;
  const imageType = resourceMediaType || null;

  useEffect(() => {
    if (pollReportContext)
      pollReportContext.addReference({ id: questionId, containerReference: containerReference.current });
  }, [pollReportContext, questionId]);

  function handleDownload() {
    PreviewUtils.excelDownload(
      agent,
      PollReportStore.projectId,
      PollReportStore.pollId,
      questionId,
      ToastrStore,
      fromDate,
      toDate,
      PollReportStore.segmentChoiceId,
      PollReportStore.demographicChoiceId,
    );
  }

  function handlePreview() {
    PollReportStore.feedbackModalActivatorQuestion = question;
    PollReportStore.feedbackModalOpen = true;
    onOpenFeedbackModal();
  }

  return (
    <div ref={containerReference} className='c-poll-preview__item'>
      <div className='c-question c-question--quick-answer'>
        <QuestionHeader question={question} />

        <div className='c-question__content'>
          <div className='c-question-quick-answer'>
            <div className='c-question-quick-answer__lead'>
              <MediaItem
                type={imageType}
                mediaId={imageId}
                mediaUrl={imageUrl}
                imageClassModifier='l-form__item c-upload--fill'
                videoClassModifier='c-upload--fill'
                linkToVideo={true}
              />
            </div>
            <p className='c-question-quick-answer__info'>{`${multiChoicesOfferedBound} possible answer${
              multiChoicesOfferedBound > 1 ? 's' : ''
            }`}</p>
            {!GeneralStore.isSnapshotting && (
              <Fragment>
                <button className='o-button o-button--s' type='button' onClick={handlePreview}>
                  <span className='o-label'>Preview</span>
                </button>
                <button className='o-button o-button--s o-button--primary' type='button' onClick={handleDownload}>
                  <span className='o-label'>Download spreadsheet</span>
                </button>
              </Fragment>
            )}
          </div>
        </div>

        <QuestionFooter answered={countAnswered} skipped={skipSeen.skipCount} viewed={skipSeen.seenCount} />
      </div>
    </div>
  );
}

QuickTextPreview.propTypes = {
  ToastrStore: PropTypes.object.isRequired,
  PollReportStore: PropTypes.object.isRequired,
  GeneralStore: PropTypes.object.isRequired,
  question: PropTypes.shape({
    questionId: PropTypes.string.isRequired,
    countAnswered: PropTypes.number,
    resource: PropTypes.string,
    multiChoicesOfferedBound: PropTypes.number,
    skipSeen: PropTypes.shape({
      seenCount: PropTypes.number,
      skipCount: PropTypes.number,
    }),
  }).isRequired,
  fromDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  toDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onOpenFeedbackModal: PropTypes.func,
};

export default inject((root) => ({
  ToastrStore: root.RootStore.toastrStore,
  PollReportStore: root.RootStore.pollsReportStore,
  GeneralStore: root.RootStore.generalStore,
}))(observer(QuickTextPreview));
