import { QUESTION_PICKER, SURVEY_QUESTIONS } from 'shared/engage/engage-help-enums';
import { QUESTION_GROUPS, QUESTION_TYPES } from './constants/QuestionConstants';

function getHelpKeyForQuestionHeader(questionType) {
  let key;
  switch (questionType) {
    case QUESTION_TYPES.TWO_IMAGE_SINGLE_CHOICE:
      key = SURVEY_QUESTIONS.TWO_IMAGE_CHOICE.BASE;
      break;
    case QUESTION_TYPES.THREE_IMAGE_SINGLE_CHOICE:
      key = SURVEY_QUESTIONS.THREE_IMAGE_CHOICE.BASE;
      break;
    case QUESTION_TYPES.FOUR_IMAGE_SINGLE_CHOICE:
      key = SURVEY_QUESTIONS.FOUR_IMAGE_CHOICE.BASE;
      break;
    case QUESTION_TYPES.MULTIPLE_IMAGE_MULTIPLE_CHOICE:
      key = SURVEY_QUESTIONS.MULTIPLE_IMAGE_CHOICE.BASE;
      break;
    case QUESTION_TYPES.MULTIPLE_IMAGE_OTHER_CHOICE:
      key = SURVEY_QUESTIONS.IMGAGE_CHOICE_OR_OTHER.BASE;
      break;
    case QUESTION_TYPES.THREE_SLIDER_CHOICE_VALUES:
      key = SURVEY_QUESTIONS.DISTRIBUTE.BASE;
      break;
    case QUESTION_TYPES.ORDERING_CHOICE:
      key = SURVEY_QUESTIONS.REORDER.BASE;
      break;
    case QUESTION_TYPES.SINGLE_SLIDER_CHOICE:
      key = SURVEY_QUESTIONS.SLIDER.BASE;
      break;
    case QUESTION_TYPES.SLIDER_FEEDBACK:
      key = SURVEY_QUESTIONS.SLIDER_FEEDBACK.BASE;
      break;
    case QUESTION_TYPES.DESCRIPTION_FACT:
      key = SURVEY_QUESTIONS.FACT_TEXT.BASE;
      break;
    case QUESTION_TYPES.SINGLE_IMAGE_FACT:
      key = SURVEY_QUESTIONS.FACT_MEDIA.BASE;
      break;
    case QUESTION_TYPES.TWO_IMAGE_FACT:
      key = SURVEY_QUESTIONS.FACT_COMPARE.BASE;
      break;
    case QUESTION_TYPES.FEEDBACK:
      key = SURVEY_QUESTIONS.FEEDBACK.BASE;
      break;
    case QUESTION_TYPES.ANNOTATION_IMAGE:
      key = SURVEY_QUESTIONS.ANNOTATION_IMAGE.BASE;
      break;
    case QUESTION_TYPES.ANNOTATION_MAP:
      key = SURVEY_QUESTIONS.ANNOTATION_MAP.BASE;
      break;
    case QUESTION_TYPES.QUICK_TEXT:
      key = SURVEY_QUESTIONS.QUICK_TEXT.BASE;
      break;
    case QUESTION_TYPES.RATING:
      key = SURVEY_QUESTIONS.RATING.BASE;
      break;
    default:
      key = null;
      break;
  }

  return key;
}

function getHelpKeyForQuestionPicker(questionType) {
  let key;
  switch (questionType) {
    case QUESTION_GROUPS.IMAGE_GROUP:
      key = QUESTION_PICKER.IMAGE_GROUP;
      break;
    case QUESTION_GROUPS.FACT_GROUP:
      key = QUESTION_PICKER.FACT_GROUP;
      break;
    case QUESTION_GROUPS.ANNOTATE_GROUP:
      key = QUESTION_PICKER.ANNOTATE_GROUP;
      break;
    case QUESTION_TYPES.TWO_IMAGE_SINGLE_CHOICE:
      key = QUESTION_PICKER.TWO_IMAGE_CHOICE;
      break;
    case QUESTION_TYPES.THREE_IMAGE_SINGLE_CHOICE:
      key = QUESTION_PICKER.THREE_IMAGE_CHOICE;
      break;
    case QUESTION_TYPES.FOUR_IMAGE_SINGLE_CHOICE:
      key = QUESTION_PICKER.FOUR_IMAGE_CHOICE;
      break;
    case QUESTION_TYPES.MULTIPLE_IMAGE_MULTIPLE_CHOICE:
      key = QUESTION_PICKER.MULTIPLE_IMAGE_CHOICE;
      break;
    case QUESTION_TYPES.MULTIPLE_IMAGE_OTHER_CHOICE:
      key = QUESTION_PICKER.IMGAGE_CHOICE_OR_OTHER;
      break;
    case QUESTION_TYPES.THREE_SLIDER_CHOICE_VALUES:
      key = QUESTION_PICKER.DISTRIBUTE;
      break;
    case QUESTION_TYPES.ORDERING_CHOICE:
      key = QUESTION_PICKER.REORDER;
      break;
    case QUESTION_TYPES.SINGLE_SLIDER_CHOICE:
      key = QUESTION_PICKER.SLIDER;
      break;
    case QUESTION_TYPES.SLIDER_FEEDBACK:
      key = QUESTION_PICKER.SLIDER_FEEDBACK;
      break;
    case QUESTION_TYPES.DESCRIPTION_FACT:
      key = QUESTION_PICKER.FACT_TEXT;
      break;
    case QUESTION_TYPES.SINGLE_IMAGE_FACT:
      key = QUESTION_PICKER.FACT_MEDIA;
      break;
    case QUESTION_TYPES.TWO_IMAGE_FACT:
      key = QUESTION_PICKER.FACT_COMPARE;
      break;
    case QUESTION_TYPES.FEEDBACK:
      key = QUESTION_PICKER.FEEDBACK;
      break;
    case QUESTION_TYPES.ANNOTATION_IMAGE:
      key = QUESTION_PICKER.ANNOTATION_IMAGE;
      break;
    case QUESTION_TYPES.ANNOTATION_MAP:
      key = QUESTION_PICKER.ANNOTATION_MAP;
      break;
    case QUESTION_TYPES.QUICK_TEXT:
      key = QUESTION_PICKER.QUICK_TEXT;
      break;
    case QUESTION_TYPES.RATING:
      key = QUESTION_PICKER.RATING;
      break;
    default:
      key = null;
      break;
  }

  return key;
}

export default {
  getHelpKeyForQuestionHeader,
  getHelpKeyForQuestionPicker,
};
