import Agent from './agent';

const requests = Agent.requests;
const ENDPOINT = 'imageLibrary';

const ImageLibraryAgent = {
  getImageLibraryCategories: () => requests.get(`/${ENDPOINT}/categories`),
  getGmvCategoryImages: (category) => requests.get(`/${ENDPOINT}/categories/${category}`),
  getRecentImages: () => requests.get(`/${ENDPOINT}/recent`),
  getFavouriteImages: () => requests.get(`/${ENDPOINT}/favourites`),

  getUnsplashImageDownloadUrl: (source) => requests.getRaw(`${source}`),
};

export default ImageLibraryAgent;
