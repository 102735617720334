import SvgIcon from '../SvgIcon';

type ModalCloseButtonProps = {
  closeModal(): void;
};

export default function ModalCloseButton({ closeModal }: ModalCloseButtonProps): JSX.Element {
  return (
    <button className='o-button c-modal__close' onClick={() => closeModal()} type='button'>
      <SvgIcon icon='times' />
      <span className='o-label'>Close</span>
    </button>
  );
}
