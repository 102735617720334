import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { KNOWLEDGLE_BASE_URL } from 'shared/engage/engage-help-enums';
import SvgIcon from 'common/components/SvgIcon';
import useComponentVisible from './UseComponentVisible';

function HelpMenu(props) {
  const { isContextualHelpActive, onContexutalHelpChange } = props;

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const isOpened = isComponentVisible ? 'is-opened' : '';

  function toggleOpen() {
    setIsComponentVisible(!isComponentVisible);
  }

  return (
    <div className={`c-header-nav__item c-header-nav__item--parent ${isOpened ? 'is-opened' : ''}`}>
      <button className='c-header-nav__link' type='button' onClick={toggleOpen}>
        <span className='c-header-nav__label'>Help</span>
        <SvgIcon icon='angle-down' />
      </button>
      <div ref={ref} className='c-header-dropdown'>
        <ul className='c-header-dropdown__children'>
          {/* <li className='c-header-dropdown__item'>
            <a className='c-header-dropdown__link' href={KNOWLEDGLE_BASE_URL} target='_blank' rel='noreferrer'>
              Knowledge base
            </a>
          </li> */}
          <li className='c-header-dropdown__item'>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className='c-header-dropdown__link' href='#' onClick={onContexutalHelpChange}>
              {`${isContextualHelpActive ? 'Deactivate' : 'Activate'} contextual help`}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

HelpMenu.propTypes = {
  isContextualHelpActive: PropTypes.bool.isRequired,
  onContexutalHelpChange: PropTypes.func.isRequired,
};

export default HelpMenu;
