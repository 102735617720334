import Agent from './agent';

const requests = Agent.requests;
const TeamAgent = {
  // Team list /team
  create: (projectId) => requests.post(`/projects/${projectId}/team`, {}),
  reorder: (projectId, data) => requests.post(`/projects/${projectId}/team/reorder`, data),

  // Team member
  updateTeamMember: (projectId, teamId, data) => requests.patch(`/projects/${projectId}/team/${teamId}`, data),
  deleteTeamMember: (projectId, teamId) => requests.remove(`/projects/${projectId}/team/${teamId}`),
  uploadMemberLogo: (projectId, teamId, file, progress) =>
    requests.uploadImage(`/projects/${projectId}/team/${teamId}/resource`, 'logo', file, progress),
  removeMemberLogo: (projectId, teamId) => requests.remove(`/projects/${projectId}/team/${teamId}/resource`),
};

export default TeamAgent;
