import Agent from './agent';

const requests = Agent.requests;
const ENDPOINT = 'influenced-decisions';

const InfluencedAgent = {
  // Influenced decisions list /team
  create: (projectId) => requests.post(`/projects/${projectId}/${ENDPOINT}`, {}),
  reorder: (projectId, data) => requests.post(`/projects/${projectId}/${ENDPOINT}/reorder`, data),

  // Influenced decisions
  update: (projectId, itemId, data) => requests.patch(`/projects/${projectId}/${ENDPOINT}/${itemId}`, data),
  remove: (projectId, itemId) => requests.remove(`/projects/${projectId}/${ENDPOINT}/${itemId}`),
  uploadResource: (projectId, itemId, file, progress) =>
    requests.uploadImage(`/projects/${projectId}/${ENDPOINT}/${itemId}/resource`, 'project', file, progress),
  removeResource: (projectId, itemId) => requests.remove(`/projects/${projectId}/${ENDPOINT}/${itemId}/resource`),
};

export default InfluencedAgent;
