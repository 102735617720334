import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from 'common/components/SvgIcon';
import { LanguageDataCompletness } from 'common/constants/GeneralConstants';

export const STATUS_TO_CLASS = {
  [LanguageDataCompletness.LANGUAGE_COMPLETE]: 'is-success',
  [LanguageDataCompletness.LANGUAGE_INCOMPLETE]: 'is-warning',
  [LanguageDataCompletness.LANGUAGE_MISSING]: 'is-danger',
};
export const STATUS_TO_ICON = {
  [LanguageDataCompletness.LANGUAGE_COMPLETE]: 'check',
  [LanguageDataCompletness.LANGUAGE_INCOMPLETE]: 'triangle',
  [LanguageDataCompletness.LANGUAGE_MISSING]: 'times',
};

function LanguageItemStatus(props) {
  const { languageItemStatus } = props;

  return (
    <td className={`${STATUS_TO_CLASS[languageItemStatus]}`}>
      <SvgIcon icon={`${STATUS_TO_ICON[languageItemStatus]}`} />
    </td>
  );
}

LanguageItemStatus.propTypes = {
  languageItemStatus: PropTypes.string.isRequired,
};

export default LanguageItemStatus;
