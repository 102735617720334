import ability, {
  EDIT,
  HOMEPAGE_CONTENT,
  POLLS_CONTENT,
  FEEDBACK_QUESTION_CONTENT,
  CHARITIES_CONTENT,
  MORE_CONTENT,
} from '../../../common/ability';

export const CONTENT_INPUT_TYPES = {
  TEXT_INPUT: 'text_input',
  EXPANDABLE_TEXT_INPUT: 'expandable_text_input',
  TEXT_AREA_INPUT: 'text_area',
  RICH_TEXT_INPUT: 'rich_text_input',
  RICH_TEXT_AREA_INPUT: 'rich_text_area',
};

const CONTENT_HOMEPAGE = {
  section: 'Homepage',
  entries: [
    {
      id: 'about',
      fieldName: 'body-about',
      placeholder: 'Default: About the Project',
      label: 'Intro title ',
      img: 'tooltip-intro-title.png',
    },
    {
      id: 'say',
      fieldName: 'body-say',
      placeholder: 'Default: Give your view',
      label: 'Intro action ',
      maxLength: 20,
      img: 'tooltip-intro-action.png',
      inputType: CONTENT_INPUT_TYPES.EXPANDABLE_TEXT_INPUT,
      actionTooltip: {
        label: 'Link leads to the surveys page by default',
        actionLabel: 'Change link',
        icon: 'pen-alt',
      },
      extraField: {
        id: 'sayUrl',
        fieldName: 'body-sayUrl',
        placeholder: 'Default: /surveys',
        label: 'Intro action URL',
        tooltip: {
          modifier: 'o-tooltip--bottom-center',
          label: 'Adding relative links',
          icon: 'question-circle',
          description:
            'If actions lead to other pages within the app you can use relative links. E.g. "/surveys" or "/timeline"',
        },
      },
    },
    {
      id: 'decisionTitle',
      fieldName: 'body-decision_title',
      placeholder: 'Default: Influenced decisions',
      label: 'Influenced decision title ',
      img: 'tooltip-influenced-decision-title.png',
    },
    {
      id: 'galleryTitle',
      fieldName: 'body-gallery_title',
      placeholder: 'Default: Our vision for the project',
      label: 'Gallery title ',
      img: 'tooltip-gallery-title.png',
    },
    {
      id: 'location',
      fieldName: 'body-location',
      placeholder: 'Default: Where we are',
      label: 'Location title ',
      img: 'tooltip-location-title.png',
    },
    {
      id: 'team',
      fieldName: 'body-team',
      placeholder: 'Default: Meet the team',
      label: 'Team title ',
      img: 'tooltip-team-title.png',
    },
  ],
};

// const CONTENT_TIMELINE = {
//   section: 'Timeline',
//   entries: [
//     {
//       id: 'timelineTitle',
//       fieldName: 'timeline-title',
//       placeholder: "Default: This is our project timeline. If anything changes, we'll keep you updated!",
//       label: 'Timeline description ',
//       textarea: true,
//       img: 'tooltip-timeline-description.png',
//     },
//   ],
// };

const CONTENT_POLLS = {
  section: 'Surveys',
  entries: [
    {
      id: 'pollsScreenTitle',
      fieldName: 'pollsScreen-title',
      placeholder: 'Default: Give your view!',
      label: 'Surveys title ',
      img: 'tooltip-polls-title.png',
    },
    {
      id: 'pollsScreenFeedbackTitle',
      fieldName: 'pollsScreen-feedbackTitle',
      placeholder: 'Default: We want to hear from you',
      label: 'Surveys feedback title ',
      img: 'tooltip-polls-feedback-title.png',
    },
    {
      id: 'pollsScreenFeedbackDescription',
      fieldName: 'pollsScreen-feedbackSubtitle',
      placeholder:
        'Default: Whether you have a project or a survey idea, or just want to give general feedback about this consultation - let us know.',
      label: 'Surveys feedback description ',
      inputType: CONTENT_INPUT_TYPES.TEXT_AREA_INPUT,
      img: 'tooltip-polls-feedback-description.png',
    },
    {
      id: 'startPollButtonLabel',
      fieldName: 'pollsScreen-answerPoll',
      placeholder: 'Default: Answer survey',
      label: 'Start survey button label ',
      img: 'tooltip-polls-start-survey-button.png',
    },
  ],
};

const CONTENT_FEEDBACK_QUESTION = {
  section: 'Feedback',
  entries: [
    {
      id: 'questionFeedbackFormPostcodeLabel',
      fieldName: 'questions-feedbackForm-postcodeLabel',
      placeholder: 'Default: Postcode',
      label: 'Postcode ',
      img: 'tooltip-feedback-postcode-label.png',
    },
    {
      id: 'questionFeedbackFormPostcodePlaceholder',
      fieldName: 'questions-feedbackForm-postcodePlaceholder',
      placeholder: 'Default: Enter postcode',
      label: 'Enter postcode ',
      img: 'tooltip-feedback-postcode-placeholder.png',
    },
    {
      id: 'questionFeedbackFormSubjectLabel',
      fieldName: 'questions-feedbackForm-subjectLabel',
      placeholder: 'Subject',
      label: 'Subject ',
      maxLength: 100,
      inputType: CONTENT_INPUT_TYPES.TEXT_INPUT,
      img: 'tooltip-feedback-subject-label.png',
    },
  ],
};

const CONTENT_CHARITIES = {
  section: 'Charities',
  entries: [
    {
      id: 'supportCharity',
      fieldName: 'charity-supportCharity',
      placeholder: 'Default: Choose a social initiative to support with your points',
      label: 'Charities ',
      img: 'tooltip-choose-charity-1.png',
    },
    // {
    //   id: 'charitySpend',
    //   fieldName: 'charity-spend',
    //   placeholder: 'Default: Default: Spend it wherever you like! 🎉',
    //   label: 'Charities line 2 ',
    //   img: 'tooltip-choose-charity-2.png',
    // },
  ],
};

const CONTENT_MORE = {
  section: 'More',
  entries: [
    {
      id: 'questionFeedbackSubmitMessagePlaceholder',
      fieldName: 'feedBackScreen-submit',
      placeholder: 'Default: Feedback submitted successfully',
      label: 'General feedback submit message',
      maxLength: 300,
      inputType: CONTENT_INPUT_TYPES.RICH_TEXT_AREA_INPUT,
      img: 'tooltip-feedback-submit-message-placeholder.png',
    },
  ],
};

export function getContent() {
  const allowedContent = [];

  if (ability.can(EDIT, HOMEPAGE_CONTENT)) allowedContent.push(CONTENT_HOMEPAGE);
  if (ability.can(EDIT, POLLS_CONTENT)) allowedContent.push(CONTENT_POLLS);
  if (ability.can(EDIT, FEEDBACK_QUESTION_CONTENT)) allowedContent.push(CONTENT_FEEDBACK_QUESTION);
  if (ability.can(EDIT, CHARITIES_CONTENT)) allowedContent.push(CONTENT_CHARITIES);
  if (ability.can(EDIT, MORE_CONTENT)) allowedContent.push(CONTENT_MORE);

  return allowedContent;
}
