import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import PredefinedRanges from './PredefinedRanges';
import DATE_ENUMS from '../../../../shared/engage/engage-date-enums';

function BaseDatePickerInputField(props) {
  const {
    input,
    placeholder,
    returnDayEndTime = false,
    showTimeSelect = false,
    selectsStart = false,
    selectsEnd = false,
    startDate = null,
    endDate = null,
    minDate = null,
    isClearable = true,
    onPredefinedRangeSelect,
    showPredefinedRanges = false,
  } = props;

  function handleChange(date) {
    let selectedDate = date;

    if (!showTimeSelect && date) {
      if (returnDayEndTime) {
        selectedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
      } else {
        selectedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
      }
    }
    input.onChange(selectedDate);
  }

  function predefinedRangeClickHandler(event) {
    if (onPredefinedRangeSelect) onPredefinedRangeSelect(event.currentTarget.id);
  }

  return (
    <Fragment>
      <DatePicker
        selected={input.value}
        onChange={handleChange}
        showTimeSelect={showTimeSelect}
        timeFormat='HH:mm'
        dateFormat={`${
          showTimeSelect
            ? DATE_ENUMS().DateFormatPatterns.DATE_WITH_MERIDIEM_TIME
            : DATE_ENUMS().DateFormatPatterns.DATE_ONLY
        }`}
        placeholderText={placeholder}
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        isClearable={isClearable}
      >
        {showPredefinedRanges && <PredefinedRanges onPredefinedRangeSelect={predefinedRangeClickHandler} />}
      </DatePicker>
    </Fragment>
  );
}

BaseDatePickerInputField.propTypes = {
  input: PropTypes.object,
  placeholder: PropTypes.string,
  returnDayEndTime: PropTypes.bool,
  showTimeSelect: PropTypes.bool,
  selectsStart: PropTypes.bool,
  selectsEnd: PropTypes.bool,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  minDate: PropTypes.object,
  isClearable: PropTypes.bool,
  onPredefinedRangeSelect: PropTypes.func,
  showPredefinedRanges: PropTypes.bool,
};

export default BaseDatePickerInputField;
