import React from 'react';
import PropTypes from 'prop-types';
import { SortableContainer } from 'react-sortable-hoc';
import { CHOICE_IDENTIFIER_PREFIX } from 'containers/project/demographics/ChoicesList';
import ReactionChoice from '../../form-elements/reactionChoice/ReactionChoice';

function ReactionsContainer(props) {
  const { choices, removeReaction, allowDragAndDelete } = props;

  return (
    <div className='c-field__reorder'>
      {choices.map((choice, index) => (
        <ReactionChoice
          key={choice._id}
          index={index}
          classModifier='l-form__item--m'
          placeholder='Reaction'
          choice={choice}
          fieldName={`${CHOICE_IDENTIFIER_PREFIX}-${choice._id}`}
          removeReaction={removeReaction}
          disabled={!allowDragAndDelete}
          maxLength='20'
        />
      ))}
    </div>
  );
}

ReactionsContainer.propTypes = {
  choices: PropTypes.array.isRequired,
  allowDragAndDelete: PropTypes.bool.isRequired,
};

const SortList = SortableContainer((props) => {
  return <ReactionsContainer {...props} />;
});

function ReactionsList(props) {
  const { currentReactions, onSortEnd, onRemoveReaction, allowDragAndDelete, allowImages, onOpenModal } = props;

  return (
    <div className='c-field l-form__item c-field--reorder-images'>
      <label className='c-field__label' htmlFor=''>
        Reactions
      </label>
      <div className='c-field__group'>
        <SortList
          choices={currentReactions}
          useDragHandle={true}
          helperClass='c-field--reorder-helper'
          onSortEnd={onSortEnd}
          removeReaction={onRemoveReaction}
          allowDragAndDelete={allowDragAndDelete}
          axis='xy'
        />
        <button
          className={`o-button o-button--primary o-button--xs  ${
            allowImages && currentReactions.length > 0 ? 'is-disabled' : ''
          }`}
          onClick={() => onOpenModal(allowImages)}
          type='button'
        >
          <span className='o-label'>Add choice</span>
        </button>
      </div>
    </div>
  );
}

ReactionsList.propTypes = {
  currentReactions: PropTypes.array.isRequired,
  onSortEnd: PropTypes.func.isRequired,
  onRemoveReaction: PropTypes.func.isRequired,
  allowDragAndDelete: PropTypes.bool.isRequired,
  allowImages: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
};

export default ReactionsList;
