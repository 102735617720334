import { action, observable, computed, makeObservable } from 'mobx';
import Agent from '../common/agent';
import PROTOCOL_ENUMS from '../shared/engage/engage-protocolEnum';

export default class ProjectStore {
  _loading = false;

  _initialized = false;

  _error = null;

  _project = {};

  _projectDefault = {};

  constructor(rootStore) {
    makeObservable(this, {
      _project: observable,
      _projectDefault: observable,
      _loading: observable,
      _initialized: observable,
      _error: observable,

      project: computed,
      projectDefault: computed,
      isLoading: computed,
      isInitialized: computed,
      error: computed,

      getById: action,
      resetFlags: action,
    });

    this.rootStore = rootStore;
  }

  get isLoading() {
    return this._loading;
  }

  get isInitialized() {
    return this._initialized;
  }

  get error() {
    return this._error;
  }

  get project() {
    return this._project;
  }

  get projectDefault() {
    return this._projectDefault;
  }

  // eslint-disable-next-line class-methods-use-this
  get language() {
    return Agent.getLanguage();
  }

  /**
   * Sets new language and refreshes project
   * @param lang
   */
  changeLanguage = (lang) => {
    Agent.setLanguage(lang);
    this.getById(this._project._id);
  };

  /**
   * Refresh current project after some update
   * ### IMPORTANT
   * We don't relay on return from ie. POST request but
   * on success refresh the  project
   * ###
   */
  refresh = () => this.getById(this._project._id);

  /**
   * Retrieve basic project info GET /project/:id
   * @param id
   * @returns {Promise<boolean>}
   */
  getById = (id) => {
    this._loading = true;
    this._error = null;
    this._initialized = false;
    return Agent.Projects.getById(id)
      .then(
        action((res) => {
          this._project = res.data;
          this._projectDefault = res.data.defaultEnglish;
          this._initialized = true;
          return this._project;
        }),
      )
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error('Error loading project', null, err);
      })
      .finally(
        action(() => {
          this._loading = false;
        }),
      );
  };

  async registerAsVisited(projectId) {
    try {
      await Agent.Projects.registerAsVisited({
        destination: PROTOCOL_ENUMS().VISIT_DESTINATIONS.PROJECT,
        _id: projectId,
      });

      return true;
    } catch (err) {
      this._error = err;
    } finally {
      this.resetFlags();
    }
  }

  resetFlags = () => {
    this._loading = false;
  };
}
