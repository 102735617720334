import React from 'react';
import PropTypes from 'prop-types';
import GdprReferencesTableRow from './GdprReferencesTableRow';

function GdprReferencesTable(props) {
  const { gdprEmailReferences } = props;

  return (
    <div className='c-field l-form__item' data-testid='gdpr-table-wrapper'>
      <div className='c-field__group'>
        <div className='c-table'>
          <table>
            <thead>
              <tr>
                <th>Time</th>
                <th>Location</th>
                <th>Data</th>
                <th>Postcode</th>
                <th>Feedback</th>
              </tr>
            </thead>
            <tbody>
              {gdprEmailReferences.map((reference) => (
                <GdprReferencesTableRow key={reference._id} gdprReference={reference} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

GdprReferencesTable.propTypes = {
  gdprEmailReferences: PropTypes.array.isRequired,
};

export default GdprReferencesTable;
