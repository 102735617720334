import React from 'react';
import PropTypes from 'prop-types';
import { QUESTION_TYPES } from '../../../../common/constants/QuestionConstants';
import TwoImagePreview from './questions/TwoImagePreview';
import ThreeImagePreview from './questions/ThreeImagePreview';
import FourImagePreview from './questions/FourImagePreview';
import DistributePreview from './questions/DistributePreview';
import SliderPreview from './questions/SliderPreview';
import ReorderPreview from './questions/ReorderPreview';
import FeedbackPreview from './questions/FeedbackPreview';
import SliderFeedbackPreview from './questions/SliderFeedbackPreview';
import MultiImageChoicePreview from './questions/MultiImageChoicePreview';
import ImageChoiceOtherPreview from './questions/ImageChoiceOtherPreview';
import FactTextPreview from './questions/FactTextPreview';
import FactMediaPreview from './questions/FactMediaPreview';
import FactComparePreview from './questions/FactComparePreview';
import AnnotatePreview from './questions/AnnotatePreview';
import QuickTextPreview from './questions/QuickTextPreview';
import RatingPreview from './questions/RatingPreview';

export default function PollLibraryQuestionSwitcher(props) {
  const { question } = props;
  return (
    <React.Fragment>
      {question.questionType === QUESTION_TYPES.TWO_IMAGE_SINGLE_CHOICE && <TwoImagePreview question={question} />}
      {question.questionType === QUESTION_TYPES.THREE_IMAGE_SINGLE_CHOICE && <ThreeImagePreview question={question} />}
      {question.questionType === QUESTION_TYPES.FOUR_IMAGE_SINGLE_CHOICE && <FourImagePreview question={question} />}
      {question.questionType === QUESTION_TYPES.THREE_SLIDER_CHOICE_VALUES && <DistributePreview question={question} />}
      {question.questionType === QUESTION_TYPES.ORDERING_CHOICE && <ReorderPreview question={question} />}
      {question.questionType === QUESTION_TYPES.SINGLE_SLIDER_CHOICE && <SliderPreview question={question} />}
      {question.questionType === QUESTION_TYPES.FEEDBACK && <FeedbackPreview question={question} />}
      {question.questionType === QUESTION_TYPES.SLIDER_FEEDBACK && <SliderFeedbackPreview question={question} />}
      {question.questionType === QUESTION_TYPES.MULTIPLE_IMAGE_MULTIPLE_CHOICE && (
        <MultiImageChoicePreview question={question} />
      )}
      {question.questionType === QUESTION_TYPES.MULTIPLE_IMAGE_OTHER_CHOICE && (
        <ImageChoiceOtherPreview question={question} />
      )}
      {question.questionType === QUESTION_TYPES.SINGLE_IMAGE_FACT && <FactMediaPreview question={question} />}
      {question.questionType === QUESTION_TYPES.TWO_IMAGE_FACT && <FactComparePreview question={question} />}
      {question.questionType === QUESTION_TYPES.DESCRIPTION_FACT && <FactTextPreview question={question} />}
      {question.questionType === QUESTION_TYPES.ANNOTATION_IMAGE && <AnnotatePreview question={question} />}
      {question.questionType === QUESTION_TYPES.ANNOTATION_MAP && <AnnotatePreview question={question} />}
      {question.questionType === QUESTION_TYPES.QUICK_TEXT && <QuickTextPreview question={question} />}
      {question.questionType === QUESTION_TYPES.RATING && <RatingPreview question={question} />}
    </React.Fragment>
  );
}

PollLibraryQuestionSwitcher.propTypes = {
  question: PropTypes.object.isRequired,
};
