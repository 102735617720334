import React from 'react';
import PropTypes from 'prop-types';
import QuestionTemplateAction from './QuestionTemplateAction';
import QuestionTemplateHeader from './QuestionTemplateHeader';

function FeedbackPreview(props) {
  const { question } = props;

  return (
    <div className='c-library__item'>
      <div className='c-question c-question--feedback c-question--library'>
        <QuestionTemplateHeader question={question} />
      </div>
      <QuestionTemplateAction question={question} />
    </div>
  );
}

FeedbackPreview.propTypes = {
  question: PropTypes.shape({
    questionText: PropTypes.string.isRequired,
  }).isRequired,
};

export default FeedbackPreview;
