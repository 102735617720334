import Agent from './agent';
import Auth from './AuthAgent';
import Projects from './ProjectAgent';
import Team from './TeamAgent';
import Influenced from './InfluencedAgent';
import CharitiesAgent from './CharitiesAgent';
import GalleryAgent from './GalleryAgent';
import PollsAgent from './PollsAgent';
import TimelineAgent from './TimelineAgent';
import ProjectControlAgent from './ProjectControlAgent';
import FaqAgent from './FaqAgent';
import NewsAgent from './NewsAgent';
import OverviewAgent from './OverviewAgent';
import FeedbackAgent from './FeedbackAgent';
import SegmentationAgent from './SegmentationAgent';
import PanelsAgent from './PanelsAgent';
import ConfigAgent from './ConfigAgent';
import PollLibraryAgent from './PollLibraryAgent';
import ClientAgent from './ClientAgent';
import DemographicAgent from './DemographicAgent';
import ImageLibraryAgent from './ImageLibraryAgent';
import HelpAgent from './HelpAgent';

// Reports
import ReportsAgent from './ReportsAgent';

// Tools
import ToolsAgent from './ToolsAgent';

// Users
import UsersAgent from './UsersAgent';

// Legacy Built-ID Agent
// import BuiltIDAgent from './BuiltIDAgent';

export { default as ClientAgent } from './ClientAgent';
export { default as UsersAgent } from './UsersAgent';
// eslint-disable-next-line
export default {
  setLanguage: Agent.setLanguage,
  getLanguage: Agent.getLanguage,
  setToken: Agent.setToken,
  DEFAULT_LANGUAGE: Agent.DEFAULT_LANGUAGE,
  Auth,
  Projects,
  Team,
  Influenced,
  CharitiesAgent,
  GalleryAgent,
  PollsAgent,
  TimelineAgent,
  ProjectControlAgent,
  FaqAgent,
  NewsAgent,
  OverviewAgent,
  FeedbackAgent,
  ReportsAgent,
  SegmentationAgent,
  PanelsAgent,
  ConfigAgent,
  PollLibraryAgent,
  ClientAgent,
  UsersAgent,
  DemographicAgent,
  ImageLibraryAgent,
  ToolsAgent,
  HelpAgent,
  // Legacy Built-ID Agent
  // BuiltIDAgent,
};
