import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../../SvgIcon';
import { CONDITION_INPUT_TYPE, CONDITIONAL_LOGICAL_OPERATORS } from '../../../ConditionalLogicUtil';
import ChoiceConditionBlock from './ChoiceConditionBlock';
import ValueConditionBlock from './ValueConditionBlock';
import EngageSelectInputField from '../../form-elements/selectInputField/SelectInputField';
import { QUESTION_TYPES } from '../../../constants/QuestionConstants';

function Condition(props) {
  const {
    condition,
    choiceInputType,
    conditionIndex,
    questionChoices,
    rule,
    onAddCondition,
    onRemoveCondition,
    questionType,
    formValues,
  } = props;

  return (
    <div
      key={condition.id}
      className='c-conditional__option c-conditional__option--condition'
      data-testid='condition-wrapper'
    >
      <div className='conditional__title'>
        {condition.logicOp && (
          <EngageSelectInputField
            choices={CONDITIONAL_LOGICAL_OPERATORS}
            id={condition.logicOp}
            fieldName={condition.logicOp}
            firstOptionEmpty={false}
            selectClass='conditional__operator'
          />
        )}
        {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
        <strong>If</strong> {`current question's answer`}
      </div>
      <div className='conditional__options'>
        {/* Depending on question type we use different equality options and choice input types */}
        {choiceInputType === CONDITION_INPUT_TYPE.SELECT ? (
          <ChoiceConditionBlock condition={condition} questionChoices={questionChoices} />
        ) : (
          <ValueConditionBlock condition={condition} questionType={questionType} formValues={formValues} />
        )}
        <button
          className='o-button o-button--danger o-button--solid o-button--s'
          type='button'
          onClick={() => onRemoveCondition(condition.id, rule.id, rule.condition)}
        >
          <SvgIcon icon='trash' />
          <span className='o-label'>Delete condition</span>
        </button>
      </div>
      {conditionIndex === rule.condition.length - 1 && (
        <div className='conditional__actions'>
          <button
            className='o-button o-button--solid o-button--primary o-button--xs'
            type='button'
            onClick={() => onAddCondition(rule.condition, rule.id)}
          >
            <SvgIcon icon='plus' />
            <span className='o-label'>Add condition</span>
          </button>
        </div>
      )}
    </div>
  );
}

Condition.propTypes = {
  condition: PropTypes.object.isRequired,
  choiceInputType: PropTypes.string.isRequired,
  conditionIndex: PropTypes.number.isRequired,
  questionChoices: PropTypes.array.isRequired,
  rule: PropTypes.object.isRequired,
  onAddCondition: PropTypes.func.isRequired,
  onRemoveCondition: PropTypes.func.isRequired,
  questionType: PropTypes.string.isRequired,
  formValues: PropTypes.object.isRequired,
};

export default Condition;
