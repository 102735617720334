import { action, observable, computed, makeObservable } from 'mobx';
import AgentPoll from '../common/agent';

const Agent = AgentPoll.ProjectControlAgent;

const ERROR_LOADING = 'Error loading project status';

const PUBLISH_SUCCESS = 'Project successfully updated';
const PUBLISH_ERROR = 'Error publishing';
const PUBLISH_ERROR_NOTHING_TO_PUBLISH = 'Nothing selected for publishing';

const SET_PROJECT_VISIBLE = 'Project is now public';
const SET_PROJECT_HIDDEN = 'Project is now hidden';
const ERROR_PROJECT_VISIBILITY = 'Error changing project visibility';

const SET_SITEMAP_VISIBLE = 'Project will now be available to search engines';
const SET_SITEMAP_HIDDEN = 'Project will now be hidden from search engines';
const ERROR_SITEMAP_VISIBILITY = 'Error changing project visibility';

const SET_POLL_VISIBLE = 'Survey is now public';
const SET_POLL_HIDDEN = 'Survey is now hidden';
const ERROR_POLL_VISIBILITY = 'Error changing survey visibility';

const SET_PANEL_VISIBLE = 'Panel is now public';
const SET_PANEL_HIDDEN = 'Panel is now hidden';
const ERROR_PANEL_VISIBILITY = 'Error changing panel visibility';

const SET_DEMOGRAPHICS_VISIBLE = 'Demographic question is now public';
const SET_DEMOGRAPHICS_HIDDEN = 'Demographic question is now hidden';
const ERROR_DEMOGRAPHICS_VISIBILITY = 'Error changing Demographic question visibility';

const SET_ALLOW_MULTIPLE_ANSWER = 'Multiple answers are now allowed';
const SET_PREVENT_MULTIPLE_ANSWER = 'Multiple answers are now prevented';
const ERROR_MULTIPLE_ANSWER = 'Error changing multiple answer setting.';

const ENABLE_PERSONAL_DATA = 'Personal data acquisition enabled';
const DISABLE_PERSONAL_DATA = 'Personal data acquisition disabled';
const ERROR_PERSONAL_DATA = 'Error changing personal data Acquisition';
const SUCCESS_PERSONAL_DATA_PROJECT_TYPE = 'Project type set';
const ERROR_PERSONAL_DATA_PROJECT_TYPE = 'Error project type';

const GENERAL_FEEDBACK_SUBJECT_REQUIRED = 'General feedback subject is required';
const GENERAL_FEEDBACK_SUBJECT_OPTIONAL = 'General feedback subject is optional';
const ERROR_GENERAL_FEEDBACK_SUBJECT = 'Error changing general feedback subject status';

const SET_AS_REGULAR_PROJECT_ERROR = 'Error setting project as regular project';
const SET_AS_POLLS_ONLY_PROJECT_SUCCESS = 'Project set as survey-only project';
const SET_AS_POLLS_ONLY_PROJECT_ERROR = 'Error setting project as survey-only project';
const SET_AS_REGULAR_PROJECT_SUCCESS = 'Project set as regular project';

export default class ProjectControlStore {
  _loading = false;

  _initialized = false;

  _error = null;

  _projectId = null;

  _publishStatus = {
    polls: [],
    isProjectActive: false,
    projectInformationHasUpdates: false,
    projectCustomizeHasUpdates: false,
    validation: {},
    sitemapPresence: false,
    demographics: [],
    forceExpireToken: null,
    // personal data
    personalDataIsActive: false,
    personalDataIsConfigured: false,
  };

  _formState = null;

  constructor(rootStore, projectStore) {
    makeObservable(this, {
      _publishStatus: observable,
      _loading: observable,
      _initialized: observable,
      _error: observable,
      _formState: observable,

      resetFlags: action,
      loadData: action,

      isLoading: computed,
      isInitialized: computed,
      error: computed,

      publishStatus: computed,
      hasPolls: computed,
      hasUpdates: computed,
      hasPanels: computed,
      hasDemographics: computed,
      modifiedPolls: computed,
      formState: computed,
      validation: computed,
      modifiedDemographics: computed,
    });

    this.rootStore = rootStore;
    this.projectStore = projectStore;
    this._formState = this.blankFormState();
  }

  blankFormState = () => ({
    information: false,
    customize: false,
    segmentation: false,
    polls: [],
    demographics: [],
  });

  get isLoading() {
    return this._loading;
  }

  get isInitialized() {
    return this._initialized;
  }

  get error() {
    return this._error;
  }

  get publishStatus() {
    return this._publishStatus;
  }

  get formState() {
    return this._formState;
  }

  set formState(val) {
    this._formState = val;
  }

  get hasPolls() {
    return this._publishStatus.polls && this._publishStatus.polls.length > 0;
  }

  get hasPanels() {
    return this._publishStatus.panels && this._publishStatus.panels.length > 0;
  }

  get hasDemographics() {
    return this._publishStatus.demographics && this._publishStatus.demographics.length > 0;
  }

  get hasUpdates() {
    return (
      this._publishStatus.projectInformationHasUpdates ||
      this._publishStatus.segmentationHasUpdates ||
      this._publishStatus.projectCustomizeHasUpdates ||
      this._publishStatus.polls.find((poll) => poll.hasUpdates) ||
      this._publishStatus.demographics.find((demog) => demog.hasUpdates)
    );
  }

  get modifiedPolls() {
    return this._publishStatus.polls.filter((poll) => poll.hasUpdates);
  }

  get modifiedDemographics() {
    return this._publishStatus.demographics.filter((demog) => demog.hasUpdates);
  }

  get validation() {
    return this._publishStatus.validation;
  }

  async getStatus(projectId) {
    this._loading = true;
    this._error = null;

    try {
      const res = await Agent.getStatus(projectId);
      this.loadData(res, projectId);

      return res.data;
    } catch (err) {
      this._error = err;
      this.rootStore.toastrStore.error(ERROR_LOADING, null, err);
    } finally {
      this.resetFlags();
    }
  }

  async doPublish() {
    this._loading = true;
    this._error = null;

    // if nothing selected for publish do error
    if (
      !(
        this._formState.information ||
        this._formState.segmentation ||
        this._formState.customize ||
        (this._formState.polls && this._formState.polls.length > 0) ||
        (this._formState.demographics && this._formState.demographics.length > 0)
      )
    ) {
      return this.rootStore.toastrStore.error(
        PUBLISH_ERROR_NOTHING_TO_PUBLISH,
        'Publish',
        PUBLISH_ERROR_NOTHING_TO_PUBLISH,
      );
    }

    try {
      const res = await Agent.doPublish(this._projectId, this._formState);
      await this.projectStore.refresh();

      this.rootStore.toastrStore.success(PUBLISH_SUCCESS);
      this.refresh();
      return true;
    } catch (err) {
      this._error = err;
      this.rootStore.toastrStore.error(PUBLISH_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  async operationExecutor(
    operation,
    operationArgs,
    toastr,
    toastrMessage,
    toastrTitle,
    errorMessage,
    refreshProject = false,
  ) {
    try {
      await operation(...operationArgs);
      toastr(toastrMessage, toastrTitle);

      this.refresh();

      if (refreshProject) return this.projectStore.refresh();
    } catch (err) {
      this._error = err;
      this.rootStore.toastrStore.error(errorMessage, null, err);
    } finally {
      this.resetFlags();
    }
  }

  async setProjectVisibility(isVisible) {
    this._loading = true;
    this._error = null;

    const operation = isVisible ? Agent.showProject : Agent.hideProject;
    const message = isVisible ? SET_PROJECT_VISIBLE : SET_PROJECT_HIDDEN;
    const toast = isVisible ? this.rootStore.toastrStore.warning : this.rootStore.toastrStore.info;

    this.operationExecutor(operation, [this._projectId, isVisible], toast, message, '', ERROR_PROJECT_VISIBILITY);
  }

  async setSitemapVisibility(isVisible) {
    this._loading = true;
    this._error = null;

    const operation = isVisible ? Agent.showOnSitemap : Agent.hideOnSitemap;
    const message = isVisible ? SET_SITEMAP_VISIBLE : SET_SITEMAP_HIDDEN;
    const toast = isVisible ? this.rootStore.toastrStore.warning : this.rootStore.toastrStore.info;

    this.operationExecutor(operation, [this._projectId, isVisible], toast, message, '', ERROR_SITEMAP_VISIBILITY);
  }

  async setMultipleResponse(duration) {
    this._loading = true;
    this._error = null;

    const operation = duration ? Agent.allowMultipleResponse : Agent.preventMultipleResponse;
    const message = duration ? SET_ALLOW_MULTIPLE_ANSWER : SET_PREVENT_MULTIPLE_ANSWER;
    const toast = duration ? this.rootStore.toastrStore.warning : this.rootStore.toastrStore.info;

    this.operationExecutor(operation, [this._projectId, { duration }], toast, message, '', ERROR_MULTIPLE_ANSWER);
  }

  async setPollVisibility(poll, isVisible) {
    this._loading = true;
    this._error = null;

    const operation = isVisible ? Agent.showPoll : Agent.hidePoll;
    const title = isVisible ? SET_POLL_VISIBLE : SET_POLL_HIDDEN;
    const toast = isVisible ? this.rootStore.toastrStore.warning : this.rootStore.toastrStore.info;

    this.operationExecutor(operation, [this._projectId, poll._id], toast, poll.name, title, ERROR_POLL_VISIBILITY);
  }

  async setPanelVisibility(panel, isVisible) {
    this._loading = true;
    this._error = null;

    const operation = isVisible ? Agent.showPanel : Agent.hidePanel;
    const title = isVisible ? SET_PANEL_VISIBLE : SET_PANEL_HIDDEN;
    const toast = isVisible ? this.rootStore.toastrStore.warning : this.rootStore.toastrStore.info;

    this.operationExecutor(operation, [this._projectId, panel._id], toast, panel.name, title, ERROR_PANEL_VISIBILITY);
  }

  async setDemographicsVisibility(demographics, isVisible) {
    this._loading = true;
    this._error = null;

    const operation = isVisible ? Agent.showDemographics : Agent.hideDemographics;
    const title = isVisible ? SET_DEMOGRAPHICS_VISIBLE : SET_DEMOGRAPHICS_HIDDEN;
    const toast = isVisible ? this.rootStore.toastrStore.warning : this.rootStore.toastrStore.info;

    this.operationExecutor(
      operation,
      [this._projectId, demographics._id],
      toast,
      'Demographic questions',
      title,
      ERROR_DEMOGRAPHICS_VISIBILITY,
    );
  }

  async setPersonalDataAcquisition(isActive) {
    this._loading = true;
    this._error = null;

    const operation = isActive ? Agent.enablePersonalData : Agent.disablePersonalData;
    const title = isActive ? ENABLE_PERSONAL_DATA : DISABLE_PERSONAL_DATA;
    const toast = isActive ? this.rootStore.toastrStore.warning : this.rootStore.toastrStore.info;

    this.operationExecutor(
      operation,
      [this._projectId],
      toast,
      'Personal data acquisition',
      title,
      ERROR_PERSONAL_DATA,
      true,
    );
  }

  async setGeneralFeedbackSubjectStatus(isRequired) {
    this._loading = true;
    this._error = null;

    const operation = isRequired ? Agent.setGeneralFeedbackSubjectRequired : Agent.setGeneralFeedbackSubjectOptional;
    const title = isRequired ? GENERAL_FEEDBACK_SUBJECT_REQUIRED : GENERAL_FEEDBACK_SUBJECT_OPTIONAL;
    const toast = isRequired ? this.rootStore.toastrStore.warning : this.rootStore.toastrStore.info;

    this.operationExecutor(operation, [this._projectId], toast, '', title, ERROR_GENERAL_FEEDBACK_SUBJECT);
  }

  async setProjectType(isRegular) {
    this._loading = true;
    this._error = null;

    const operation = isRegular ? Agent.setProjectAsRegular : Agent.setProjectAsPollsOnly;
    const message = isRegular ? SET_AS_REGULAR_PROJECT_SUCCESS : SET_AS_POLLS_ONLY_PROJECT_SUCCESS;
    const toast = this.rootStore.toastrStore.success;
    const errorMessage = isRegular ? SET_AS_REGULAR_PROJECT_ERROR : SET_AS_POLLS_ONLY_PROJECT_ERROR;

    return this.operationExecutor(operation, [this._projectId], toast, message, '', errorMessage, true);
  }

  async setProjectConfiguration(projectType) {
    this._loading = true;
    this._error = null;

    const operation = Agent.setProjectConfiguration;
    const message = SUCCESS_PERSONAL_DATA_PROJECT_TYPE;
    const toast = this.rootStore.toastrStore.info;
    const errorMessage = ERROR_PERSONAL_DATA_PROJECT_TYPE;

    this.operationExecutor(operation, [this._projectId, projectType], toast, message, 'Personal data', errorMessage);
  }

  loadData(status, projectId) {
    this._publishStatus = status.data;
    this._initialized = true;
    this._projectId = projectId;
    this._formState = this.blankFormState();
  }

  resetFlags = () => {
    this._loading = false;
  };

  refresh = () => this.getStatus(this._projectId);
}
