import { action, observable, computed, runInAction, makeObservable } from 'mobx';

import AgentPoll from '../common/agent';

const Agent = AgentPoll.DemographicAgent;

const QUESTION_ITEM_LABEL = 'demographic question';
const CHOICE_ITEM_LABEL = 'choice';
const CREATE_QUESTION_SUCCESS = `New ${QUESTION_ITEM_LABEL} added`;
const CREATE_QUESTION_ERROR = `Error creating ${QUESTION_ITEM_LABEL}`;
const REORDER_QUESTION_ERROR = `Error reordering ${QUESTION_ITEM_LABEL}`;
const UPDATE_SUCCESS = 'Saved';
const UPDATE_QUESTION_ERROR = `Error saving ${QUESTION_ITEM_LABEL}`;
const DELETE_QUESTION_SUCCESS = 'Question removed';
const DELETE_QUESTION_ERROR = `Error deleting ${QUESTION_ITEM_LABEL}`;
const CREATE_CHOICE_SUCCESS = `New ${CHOICE_ITEM_LABEL} added`;
const CREATE_CHOICE_ERROR = `Error creating ${CHOICE_ITEM_LABEL}`;
const REORDER_CHOICE_SUCCESS = `Choice reordered`;
const REORDER_CHOICE_ERROR = `Error reordering ${CHOICE_ITEM_LABEL}`;
const DELETE_CHOICE_SUCCESS = 'Choice removed';
const DELETE_CHOICE_ERROR = `Error deleting ${CHOICE_ITEM_LABEL}`;
const OVERRIDE_SUCCESS = 'Template question overriden';
const OVERRIDE_ERROR = `Error overriding template question`;
const ERROR_LOADING_PROJECT_DEMOGRAPHIC_QUESTIONS = 'Error loading demographic questions';
const RESOURCE_UPLOAD_ERROR = 'Error uploading resource';
const RESOURCE_DELETE_QUESTION_ERROR = 'Error deleting resource';

export default class DemographicsStore {
  _loading = false;

  _initialized = false;

  _error = null;

  _demographic = null;

  constructor(rootStore) {
    makeObservable(this, {
      _demographic: observable,
      _loading: observable,
      _initialized: observable,
      _error: observable,

      isLoading: computed,
      isInitialized: computed,
      error: computed,
      demographic: computed,

      toggleOpened: action,
      resetFlags: action,
    });

    this.rootStore = rootStore;
  }

  get isLoading() {
    return this._loading;
  }

  get isInitialized() {
    return this._initialized;
  }

  get error() {
    return this._error;
  }

  get demographic() {
    return this._demographic;
  }

  async load(projectId) {
    this._loading = false;
    this._error = null;

    try {
      const res = await Agent.getAll(projectId);

      runInAction(() => {
        this._demographic = res.data[0];
      });
      return res.data[0];
    } catch (err) {
      this._error = err.response;
      this.rootStore.toastrStore.error(ERROR_LOADING_PROJECT_DEMOGRAPHIC_QUESTIONS, null, err);
    } finally {
      this.resetFlags();
    }
  }

  async createQuestion(projectId, data) {
    this._loading = false;
    this._error = null;

    try {
      const res = await Agent.createQuestion(projectId, this._demographic._id, data);
      const questionId = res.data._id;

      await this.refreshAndKeepOpen(projectId, questionId);

      this.rootStore.toastrStore.success(CREATE_QUESTION_SUCCESS, null);

      return questionId;
    } catch (err) {
      this._error = err.response;
      this.rootStore.toastrStore.error(CREATE_QUESTION_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  async updateQuestion(projectId, data) {
    this._loading = false;
    this._error = null;

    try {
      await Agent.updateQuestion(projectId, this._demographic._id, data._id, data);
      await this.refreshAndKeepOpen(projectId, data._id);
      this.rootStore.toastrStore.success(UPDATE_SUCCESS, null);
    } catch (err) {
      this._error = err.response;
      this.rootStore.toastrStore.error(UPDATE_QUESTION_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  async removeQuestion(projectId, questionId) {
    this._loading = false;
    this._error = null;

    try {
      await Agent.removeQuestion(projectId, this._demographic._id, questionId);
      await this.refreshAndKeepOpen(projectId, questionId);
      this.rootStore.toastrStore.success(DELETE_QUESTION_SUCCESS, null);
      return true;
    } catch (err) {
      this._error = err.response;
      this.rootStore.toastrStore.error(DELETE_QUESTION_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  async overrideQuestion(projectId, questionId) {
    this._loading = false;
    this._error = null;

    try {
      const res = await Agent.overrideQuestion(projectId, this._demographic._id, questionId);
      await this.refreshAndKeepOpen(projectId, res.data._id);
      this.rootStore.toastrStore.success(OVERRIDE_SUCCESS, null);
    } catch (err) {
      this._error = err.response;

      if (err.response.status === 422) {
        this.rootStore.toastrStore.error(err.response.data.error, 'Error overriding question', err);
      } else {
        this.rootStore.toastrStore.error(OVERRIDE_ERROR, null, err);
      }
    } finally {
      this.resetFlags();
    }
  }

  async reorderQuestions(projectId, data) {
    this._loading = false;
    this._error = null;

    try {
      await Agent.reorderQuestions(
        projectId,
        this._demographic._id,
        data.map((item) => item._id),
      );
      await this.refreshAndKeepOpen(projectId);
    } catch (err) {
      this._error = err.response;
      this.rootStore.toastrStore.error(REORDER_QUESTION_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  async createQuestionChoice(projectId, questionId, choiceContentType) {
    this._loading = false;
    this._error = null;

    try {
      await Agent.createQuestionChoice(projectId, this._demographic._id, questionId, { choiceContentType });
      await this.refreshAndKeepOpen(projectId, questionId);
      this.rootStore.toastrStore.success(CREATE_CHOICE_SUCCESS, null);
    } catch (err) {
      this._error = err.response;
      this.rootStore.toastrStore.error(CREATE_CHOICE_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  async removeQuestionChoice(projectId, questionId, choiceId) {
    this._loading = false;
    this._error = null;

    try {
      await Agent.removeQuestionChoice(projectId, this._demographic._id, questionId, choiceId);
      await this.refreshAndKeepOpen(projectId, questionId);
      this.rootStore.toastrStore.success(DELETE_CHOICE_SUCCESS, null);
    } catch (err) {
      this._error = err.response;
      this.rootStore.toastrStore.error(DELETE_CHOICE_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  async reorderQuestionChoicess(projectId, questionId, data) {
    this._loading = false;
    this._error = null;

    try {
      await Agent.reorderQuestionChoices(
        projectId,
        this._demographic._id,
        questionId,
        data.map((item) => item._id),
      );
      await this.refreshAndKeepOpen(projectId, questionId);
      this.rootStore.toastrStore.success(REORDER_CHOICE_SUCCESS, null);
      return true;
    } catch (err) {
      this._error = err.response;
      this.rootStore.toastrStore.error(REORDER_CHOICE_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  async uploadImage(projectId, question, file, progress) {
    this._loading = false;
    this._error = null;

    try {
      const res = await Agent.uploadQuestionImage(projectId, this._demographic._id, question._id, file, progress);

      runInAction(() => {
        const q = this._demographic.questions.find((_question) => _question._id === question._id);
        q.resource = res.data;
      });

      return true;
    } catch (err) {
      this._error = err.response;
      this.rootStore.toastrStore.error(RESOURCE_UPLOAD_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  async removeImage(projectId, question, imageId) {
    this._loading = false;
    this._error = null;

    try {
      await Agent.removeQuestionImage(projectId, this._demographic._id, question._id, imageId);

      runInAction(() => {
        const q = this._demographic.questions.find((_question) => _question._id === question._id);
        q.resource = q.resource.filter((img) => img._id !== imageId);
      });

      return true;
    } catch (err) {
      this._error = err.response;
      this.rootStore.toastrStore.error(RESOURCE_DELETE_QUESTION_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  toggleOpened = (questionIds) => {
    questionIds.forEach((id) => {
      const q = this._demographic.questions.find((question) => question._id === id);
      if (q) {
        q.isOpened = !q.isOpened;
      }
    });
  };

  async refreshAndKeepOpen(projectId, itemId) {
    const openedQuestionsIds = this.demographic.questions
      .filter((question) => question.isOpened && question._id !== itemId)
      .map((q) => q._id);

    await this.load(projectId);
    this.toggleOpened([...openedQuestionsIds, itemId]);
  }

  resetFlags = () => {
    this._loading = false;
    this._error = null;
  };
}
