import React from 'react';
import PropTypes from 'prop-types';
import FieldDescription from '../fieldDescription/FieldDescription';
import Tooltip from '../../tooltip/Tooltip';
import BaseDatePickerInputField from './BaseDatePickerInputField';

function SimpleDatePickerInputField(props) {
  const {
    id,
    label,
    input,
    showTimeSelect = false,
    meta,
    fieldClass,
    fieldDescription,
    showSlugLabel,
    tooltip,
    tooltipInLabel,
    placeholder,
    returnDayEndTime = false,
    selectsStart = false,
    selectsEnd = false,
    startDate = null,
    endDate = null,
    minDate = null,
  } = props;

  return (
    <div className={`c-field l-form__item ${fieldClass}`} data-testid={`${id}-wrapper`}>
      <label className='c-field__label' htmlFor='input-range-from-date' data-testid={`${id}-group-label`}>
        {label}
        {tooltipInLabel && <Tooltip {...tooltipInLabel} />}
      </label>

      <div className='c-field__group'>
        <div className='c-date-picker'>
          <BaseDatePickerInputField
            input={input}
            showTimeSelect={showTimeSelect}
            returnDayEndTime={returnDayEndTime}
            placeholder={placeholder}
            selectsStart={selectsStart}
            selectsEnd={selectsEnd}
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
          />
        </div>
        <FieldDescription
          fieldDescription={fieldDescription}
          meta={meta}
          showSlugLabel={showSlugLabel}
          tooltip={tooltip}
          val={input.value ? input.value.toString() : ''}
        />
      </div>
    </div>
  );
}

SimpleDatePickerInputField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  input: PropTypes.object,
  showTimeSelect: PropTypes.bool,
  meta: PropTypes.object.isRequired,
  fieldClass: PropTypes.string,
  fieldDescription: PropTypes.string,
  showSlugLabel: PropTypes.bool,
  tooltip: PropTypes.object,
  tooltipInLabel: PropTypes.object,
  placeholder: PropTypes.string,
  returnDayEndTime: PropTypes.bool,
  selectsStart: PropTypes.bool,
  selectsEnd: PropTypes.bool,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  minDate: PropTypes.object,
};

export default SimpleDatePickerInputField;
