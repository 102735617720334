import React from 'react';
import PropTypes from 'prop-types';
import './Embed.css';

function EmbedModal(props) {
  const { close, iframeSource } = props;

  return (
    <div className='gmv-container'>
      <div id='gmv-modal' className='gmv-modal'>
        <div className='gmv-modal-content'>
          <button onClick={close} type='button' className='gmv-modal-close' href='#gmv-modal-button'>
            <span className='gmv-modal-close-icon'>×</span>
            <span className='gmv-modal-close-label'>Close</span>
          </button>
          <iframe title='GMV modal' src={iframeSource} width='100%' height='800px' />
        </div>
      </div>
    </div>
  );
}

EmbedModal.propTypes = {
  close: PropTypes.func.isRequired,
  iframeSource: PropTypes.string.isRequired,
};

export default EmbedModal;
