import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import QuestionTemplateAction from './QuestionTemplateAction';
import { IMAGE_FORMATS } from '../../../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import ImageUtils from '../../../../../common/ImageUtils';
import { QUESTION_TYPES } from '../../../../../common/constants/QuestionConstants';
import QuestionTemplateHeader from './QuestionTemplateHeader';

function AnnotatePreview(props) {
  const { question } = props;

  const { questionType } = question;
  const isImageQuestion = questionType === QUESTION_TYPES.ANNOTATION_IMAGE;
  const mapPlaceholder = '/assets-v2/images/polls/map-placeholder-selection.png';

  return (
    <div className='c-library__item'>
      <div className='c-question c-question--library c-question--annotation'>
        <QuestionTemplateHeader question={question} tagLabel='Beta' />
        <div className='c-question__content'>
          <div
            className={`c-question-annotation ${
              isImageQuestion ? 'c-question-annotation--image' : 'c-question-annotation--map'
            }`}
          >
            <div className='c-question-annotation__lead'>
              {isImageQuestion ? (
                <img
                  src={ImageUtils.imageUrl(
                    isImageQuestion ? question.resource[0] : mapPlaceholder,
                    IMAGE_FORMATS.PANEL.imageType,
                  )}
                  alt=''
                />
              ) : (
                <Fragment>
                  <img src={mapPlaceholder} alt='' />
                  <p className='c-question-annotation__note'>Specimen map</p>
                </Fragment>
              )}
            </div>
            <div className='c-question-annotation__items'>
              {question.choices.map((choice, idx) => (
                <div key={idx} className='c-question-annotation__item'>
                  <div className='c-question-annotation__media'>
                    <img src={ImageUtils.pngImageUrl(choice.resource, IMAGE_FORMATS.REACTION.imageType)} alt='' />
                  </div>
                  <h3 className='c-question-annotation__label'>{choice.choice}</h3>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <QuestionTemplateAction question={question} />
    </div>
  );
}

AnnotatePreview.propTypes = {
  question: PropTypes.shape({
    questionText: PropTypes.string.isRequired,
    choices: PropTypes.array.isRequired,
    resource: PropTypes.array.isRequired,
  }).isRequired,
};

export default AnnotatePreview;
