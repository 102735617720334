import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import ActionBar from '../../../common/components/actionBar/ActionBar';
import SvgIcon from '../../../common/components/SvgIcon';
import { PANELS, CONTENT, ACTIONS, BLOCKS, PERSONAL_DATA } from '../../../common/constants/RouteConstants';
import { VIEW } from '../../../common/ability';
import { Can } from '../../../common/Can';

function Customize(props) {
  const { Store } = props;
  return (
    <div className='l-main'>
      <ActionBar hasButton={false} label='Customise' />
      <p>Customise your project even further.</p>
      <ul className='c-list-icons'>
        <Can I={VIEW} a={PANELS}>
          <li className='c-list-icons__item'>
            <SvgIcon icon='pen-alt' />
            {Store.panels.length > 0 ? `${Store.panels.length} panels — ` : null}
            <Link to={PANELS}>{Store.panels.length > 0 ? 'Edit' : 'Add new panel'}</Link>
          </li>
        </Can>
        <Can I={VIEW} a={CONTENT}>
          <li className='c-list-icons__item'>
            <SvgIcon icon='pen-alt' />
            <Link to={CONTENT}>Customise static text</Link>
          </li>
        </Can>
        <Can I={VIEW} a={ACTIONS}>
          <li className='c-list-icons__item'>
            <SvgIcon icon='external-link' />
            <Link to={ACTIONS}>Customise end of survey actions</Link>
          </li>
        </Can>
        <Can I={VIEW} a={BLOCKS}>
          <li className='c-list-icons__item'>
            <SvgIcon icon='ellipsis-v' />
            <Link to={BLOCKS}>Customise homepage blocks</Link>
          </li>
        </Can>
        <Can I={VIEW} a={PERSONAL_DATA}>
          <li className='c-list-icons__item'>
            <SvgIcon icon='pen-alt' />
            <Link to={PERSONAL_DATA}>Personal Data Gathering</Link>
          </li>
        </Can>
      </ul>
    </div>
  );
}

export default inject((root) => ({ Store: root.RootStore.panelsStore }))(observer(Customize));
