import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { SortableContainer } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { Form } from 'react-final-form';

import ActionBar from '../../../common/components/actionBar/ActionBar';
import GalleryItem from './GalleryItem';
import SlimFileUpload from '../../../common/components/form-elements/fileUpload/SlimFileUpload';
import { IMAGE_FORMATS } from '../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import EngageTextInputWithAction from '../../../common/components/form-elements/textInputField/TextInputWithAction';
import { validateYoutubeUrl } from '../../../common/UrlUtils';

/**
 * Member list container
 * ### IMPORTANT this one is sort container!!
 */
function GalleryContainerFn(props) {
  const { Store, allowDrag } = props;
  return (
    <div className='c-reorder-list c-reorder-list--gallery-captions'>
      {Store.gallery.map((item, index) => (
        <GalleryItem
          key={item.data._id}
          index={index}
          item={item}
          switchableModifier='c-switchable--reorder'
          disabled={!allowDrag}
        />
      ))}
    </div>
  );
}
const GalleryContainer = inject((root) => ({ Store: root.RootStore.galleryStore }))(observer(GalleryContainerFn));

/**
 * Team page container
 */
function GalleryFn(props) {
  const { Store } = props;
  const [allowDrag, setAllowDrag] = useState(true);

  const uploadImage = (file, progressHandler) => {
    return Store.uploadResource(file, progressHandler);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setAllowDrag(false);
    const newOrder = arrayMoveImmutable(props.Store.gallery, oldIndex, newIndex);
    Store.reorder(newOrder).then(() => {
      setAllowDrag(true);
    });
  };

  const postVideo = (values) => {
    return Store.postVideo(values);
  };

  return (
    <div className='l-main'>
      <ActionBar label='Media' hasButton={false} />
      <div className='c-fields l-form'>
        <div className='c-field l-form__item'>
          <div className='c-field__group'>
            <SortableList useDragHandle={true} onSortEnd={onSortEnd} allowDrag={allowDrag} />
          </div>
        </div>
      </div>

      <div className='c-upload-group'>
        <div className='c-upload-group__item'>
          <SlimFileUpload
            imageFormat={IMAGE_FORMATS.PANEL}
            id='gallery-image'
            handleUpload={uploadImage}
            label=''
            classModifier='c-field--label-hidden'
            removeImage={() => {}}
          />
        </div>

        <div className='c-upload-group__separator' />

        <div className='c-upload-group__item'>
          <Form
            onSubmit={postVideo}
            initialValues={{ resource: '' }}
            render={({ values, form, handleSubmit, invalid }) => {
              return (
                <form
                  className='c-fields l-form'
                  onSubmit={(event) => {
                    event.preventDefault();
                    !invalid &&
                      handleSubmit(event).then(() => {
                        form.restart();
                      });
                  }}
                >
                  <EngageTextInputWithAction
                    id='input-media-youtube'
                    buttonLabel='Add'
                    label='Add video'
                    placeholder='Paste YouTube link'
                    fieldDescription='Paste the full <strong>YouTube video link</strong> to add it to the gallery. E.g. https://www.youtube.com/watch?v=pHueSvBACQs'
                    fieldName='resource'
                    isRequired={true}
                    validator={(value) => {
                      return validateYoutubeUrl(value);
                    }}
                  />
                </form>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}

const Gallery = inject((root) => ({ Store: root.RootStore.galleryStore }))(observer(GalleryFn));

/**
 * Sortable collection wrapper
 */
const SortableList = SortableContainer((props) => {
  return <GalleryContainer {...props} />;
});

export default Gallery;
