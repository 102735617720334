import React from 'react';
import PropTypes from 'prop-types';

export default function SimpleCheckboxGroupInputField(props) {
  const {
    choices,
    id,
    label,
    input,
    disabled,
    fieldClass = '',
    inputWrapperClass = '',
    horizontalLayout = true,
    type = 'checkbox',
    onClearSelection,
  } = props;

  return (
    <div
      className={`c-field l-form__item l-form__item--button-group ${fieldClass}`}
      data-testid={`${id}-wrapper`}
      style={{ display: 'block' }}
    >
      <label className='c-field__label' htmlFor={id} data-testid={`${id}-group-label`}>
        {label}
      </label>
      <div className='c-field__group' data-testid={`${id}-radio-wrapper`}>
        {choices.map((choice) => {
          return (
            <div
              className={`o-checkbox ${inputWrapperClass} ${horizontalLayout ? 'o-checkbox--inline' : ''}`}
              key={choice[0]}
            >
              <input
                {...input}
                id={`${id}-${choice[0]}`}
                disabled={disabled}
                checked={input ? input.value.includes(choice[0]) : false}
                data-testid={`${id}-${choice[0]}-radio`}
                name={`${id}-radioGroup`}
                value={choice[0]}
                className='custom-control-input'
                type={type}
              />
              <label
                htmlFor={`${id}-${choice[0]}`}
                data-testid={`${id}-${choice[0]}-label`}
                className='custom-control-label'
              >
                {choice[1]}
                <small data-testid={`${id}-${choice[0]}-label-small`}> {choice[2]}</small>
              </label>
            </div>
          );
        })}
        {input.value.length > 0 && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a className='o-reset o-reset--link o-reset--s o-reset--inline' href='#' onClick={onClearSelection}>
            Clear selection
          </a>
        )}
      </div>
    </div>
  );
}

SimpleCheckboxGroupInputField.propTypes = {
  choices: PropTypes.array,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  input: PropTypes.object,
  disabled: PropTypes.bool,
  fieldClass: PropTypes.string,
  inputWrapperClass: PropTypes.string,
  horizontalLayout: PropTypes.bool,
  type: PropTypes.string,
  onClearSelection: PropTypes.func.isRequired,
};
