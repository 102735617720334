import React from 'react';
import PropTypes from 'prop-types';
import { IMAGE_FORMATS } from '../../../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import QuestionTemplateAction from './QuestionTemplateAction';
import ImageUtils from '../../../../../common/ImageUtils';
import QuestionTemplateHeader from './QuestionTemplateHeader';
import MediaItem from '../../../../../common/components/mediaItem/MediaItem';

function QuickTextPreview(props) {
  const { question } = props;
  const { multiChoicesOfferedBound } = question;

  const mediaResource = question?.resource?.length > 0 ? question.resource[0] : null;

  const imageId = mediaResource?._id || null;
  const imageUrl = mediaResource?.url || null;
  const imageType = mediaResource?.mediaType || null;

  return (
    <div className='c-library__item'>
      <div className='c-question c-question--library c-question--quick-answer'>
        <QuestionTemplateHeader question={question} />
        <div className='c-question__content'>
          <div className='c-question-quick-answer'>
            <div className='c-question-quick-answer__lead'>
              {mediaResource && (
                <MediaItem
                  type={imageType}
                  mediaId={imageId}
                  mediaUrl={imageUrl}
                  imageClassModifier='l-form__item c-upload--fill'
                  videoClassModifier='c-upload--fill'
                  linkToVideo={true}
                />
              )}
            </div>
            <p className='c-question-quick-answer__info'>{`${multiChoicesOfferedBound} possible answer${
              multiChoicesOfferedBound > 1 ? 's' : ''
            }`}</p>
          </div>
        </div>
      </div>
      <QuestionTemplateAction question={question} />
    </div>
  );
}

QuickTextPreview.propTypes = {
  question: PropTypes.shape({
    questionText: PropTypes.string.isRequired,
    multiChoicesOfferedBound: PropTypes.number.isRequired,
    resource: PropTypes.array.isRequired,
  }).isRequired,
};

export default QuickTextPreview;
