import React from 'react';
import SvgIcon from '../components/SvgIcon';
import { VERSION } from '../../shared/engage/client-constants';

function Footer() {
  return (
    <footer className='c-footer l-footer' id='footer' role='contentinfo'>
      <div className='l-container'>
        <nav className='c-footer-nav'>
          <ul className='c-footer-nav__list c-footer-nav__list--inline'>
            <li className='c-footer-nav__item c-footer-nav__item--social'>
              <a className='c-footer-nav__link' href='https://twitter.com/GiveMyView' target='_blank' rel='noreferrer'>
                <span className='c-footer-nav__label'>X</span>
                <SvgIcon icon='x' />
              </a>
              <a
                className='c-footer-nav__link'
                href='https://www.linkedin.com/company/built-id/'
                target='_blank'
                rel='noreferrer'
              >
                <span className='c-footer-nav__label'>LinkedIn</span>
                <SvgIcon icon='linkedin' />
              </a>
              <a
                className='c-footer-nav__link'
                href='https://www.instagram.com/givemyview/'
                target='_blank'
                rel='noreferrer'
              >
                <span className='c-footer-nav__label'>Instagram</span>
                <SvgIcon icon='instagram' />
              </a>
              <a
                className='c-footer-nav__link'
                href='https://facebook.com/GiveMyViewTech/'
                target='_blank'
                rel='noreferrer'
              >
                <span className='c-footer-nav__label'>Facebook</span>
                <SvgIcon icon='facebook' />
              </a>
            </li>
          </ul>
          <div className='c-footer-info'>
            <div className='c-footer-info__text'>
              <p>
                Give My View is a product supplied by LandTech. Give My View harnesses diverse voices so that community
                needs are at the center of progress.
              </p>
              <p>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                2015–2023. By using Give My View you accept LandTech's{' '}
                <a href='https://land.tech/legal'>Terms and Conditions</a> and{' '}
                <a href='https://land.tech/legal#privacy-policy'>Privacy Policy</a>
              </p>
            </div>
            <span className='c-footer-info__version'>V {VERSION}</span>
            <a className='c-footer-info__top' href='#top'>
              Return to top
              <SvgIcon icon='arrow-up' />
            </a>
          </div>
        </nav>
      </div>
    </footer>
  );
}

export default Footer;
