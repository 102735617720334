import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';
import DestinationSelector from './DestinationSelector';
import ConditionalLogicUtil from '../../ConditionalLogicUtil';

function NoConditionsComponent(props) {
  const { formValues, rules, question, questions, onAddRule } = props;

  const [hasElseFormValue, setHasElseFormValue] = useState();

  const conditionsAllowed = ConditionalLogicUtil.isConditionalQuestion(question.questionType);

  useEffect(() => {
    const formValuesArray = Object.entries(formValues);
    const elseValue = formValuesArray.find((value) => value[0] === `${question._id}-else`);

    setHasElseFormValue(elseValue);
  }, [formValues, question._id, rules]);

  return (
    <div className='c-conditional' data-testid='noConditions-wrapper'>
      {conditionsAllowed && (
        <div className='c-conditional__rule'>
          <div className='c-conditional__option c-conditional__option--condition'>
            <div className='conditional__actions'>
              <button
                className='o-button o-button--solid o-button--primary o-button--s'
                type='button'
                onClick={onAddRule}
              >
                <SvgIcon icon='plus' />
                <span className='o-label'>Add rule</span>
              </button>
            </div>
          </div>
        </div>
      )}
      {rules.length === 0 && hasElseFormValue && (
        <div className='c-conditional__rule c-conditional__rule--always'>
          <DestinationSelector
            fieldName={`${question._id}-else`}
            formValues={formValues}
            question={question}
            questions={questions}
            titleLabel1={conditionsAllowed ? 'If' : 'Next jump to question'}
            titleLabelStrong={conditionsAllowed ? 'no rule is set' : ''}
            titleLabel2={conditionsAllowed ? 'jump to question' : ''}
            hasNextOption={true}
            hasEndOfPollOption={true}
          />
        </div>
      )}
    </div>
  );
}

NoConditionsComponent.propTypes = {
  formValues: PropTypes.object.isRequired,
  rules: PropTypes.array.isRequired,
  question: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  onAddRule: PropTypes.func.isRequired,
};

export default NoConditionsComponent;
