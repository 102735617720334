import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { inject, observer } from 'mobx-react';
import { Form } from 'react-final-form';

import { SAMPLE_URL, validateUrl } from 'common/UrlUtils';
import SwitchableHeader from '../../../common/components/switchableHeader/SwitchableHeader';
import EngageTextInputField from '../../../common/components/form-elements/textInputField/TextInputField';
import SwitchableButtonBar from '../../../common/components/switchableButtonBar/SwitchableButtonBar';
import EngageMarkdownInputField, {
  MARKDOWN_MAX,
} from '../../../common/components/form-elements/markdownInputField/MarkdownInputField';
import displayConfirmationPrompt from '../../../common/PromptUtil';
import { getFormFieldClass, getFormFieldTranslationTooltip } from '../../../common/TranslationUtils';

/**
 * Faq form
 */
function FaqItemFn(props) {
  const { item, Store, ToastrStore } = props;

  const toggleOpened = () => {
    Store.toggleOpened([item.data._id]);
  };

  const remove = (event) => {
    event.preventDefault();

    const res = displayConfirmationPrompt(null, null, false, ToastrStore);

    if (res === 1) Store.remove(item.data._id);
  };

  const onSubmit = (values) => {
    // console.log('Submit', values);
    Store.update(item.data._id, {
      question: values.question,
      answer: values.answer,
      hyperlinkDescription: values.hyperlinkDescription,
      hyperlink: values.hyperlink,
    });
  };

  return (
    <div className={`c-switchable ${props.switchableModifier} ${item.formState.isOpened ? 'is-opened' : 'is-closed'}`}>
      <SwitchableHeader
        id={item.data._id}
        label='Question'
        name={item.data.question}
        icon='pen-alt'
        toggleOpened={toggleOpened}
      />
      <div className='c-switchable__content'>
        <Form
          onSubmit={onSubmit}
          initialValues={item.data}
          render={({ values, form, handleSubmit, submitting, pristine }) => {
            return (
              <form className='c-fields l-form'>
                <EngageTextInputField
                  id={`input-faq-question-${item.data._id}`}
                  placeholder='Question'
                  label='Question'
                  maxLength='80'
                  fieldDescription=''
                  fieldName='question'
                  isRequired={true}
                  fieldClass={getFormFieldClass(item.defaultData.question, null, 'l-form__item')}
                  tooltipInLabel={getFormFieldTranslationTooltip(item.defaultData.question)}
                />

                <EngageMarkdownInputField
                  id={`input-faq-answer-${item.data._id}`}
                  placeholder='Answer summary.'
                  label='Answer'
                  maxLength='500'
                  fieldDescription=''
                  fieldName='answer'
                  isRequired={false}
                  editorConfig={MARKDOWN_MAX}
                  fieldClass={getFormFieldClass(item.defaultData.answer, null, 'l-form__item')}
                  tooltipInLabel={getFormFieldTranslationTooltip(item.defaultData.answer)}
                />

                <EngageTextInputField
                  id={`input-faq-answer-link-description${item.data._id}`}
                  placeholder='Link description'
                  label='Link description'
                  maxLength='80'
                  fieldDescription=''
                  fieldName='hyperlinkDescription'
                  isRequired={false}
                  fieldClass={getFormFieldClass(item.defaultData.hyperlinkDescription, null, 'l-form__item--m')}
                  tooltipInLabel={getFormFieldTranslationTooltip(item.defaultData.hyperlinkDescription)}
                />

                <EngageTextInputField
                  id={`input-faq-answer-link-${item.data._id}`}
                  placeholder={item.defaultData.hyperlink || SAMPLE_URL}
                  label='URL'
                  maxLength='80'
                  fieldDescription=''
                  fieldName='hyperlink'
                  isRequired={false}
                  fieldClass={getFormFieldClass(item.defaultData.hyperlink, null, 'l-form__item--m')}
                  tooltipInLabel={getFormFieldTranslationTooltip(item.defaultData.hyperlink)}
                />

                <SwitchableButtonBar updateAction={handleSubmit} removeAction={remove} isNew={!item.data._id} />
              </form>
            );
          }}
        />
      </div>
    </div>
  );
}

const FaqItem = inject((root) => ({ Store: root.RootStore.faqStore, ToastrStore: root.RootStore.toastrStore }))(
  observer(FaqItemFn),
);

/**
 * Sortable item wrapper
 */
const SortableFaqItem = SortableElement((props) => {
  return <FaqItem {...props} />;
});

export default SortableFaqItem;
