import React from 'react';
import PropTypes from 'prop-types';
import PreviewUtils from '../../../../../common/PreviewUtils';
import MediaItem from '../../../../../common/components/mediaItem/MediaItem';

function SliderChoice(props) {
  const { showEmoji = false, compareActive = false, choice, weightedAmount, question } = props;
  const { label, resource, avgAmount, compareValue = 'TBD' } = choice;

  const imageId = question?.questionId || null;
  const imageType = question?.resourceMediaType || null;

  const imageSource = question?.resource || resource;

  return (
    <li className={`c-result-slider ${showEmoji ? 'c-result-slider--emoji' : ''}`}>
      {imageSource && (
        <div className='c-result-slider__media'>
          <MediaItem
            type={imageType}
            mediaId={imageId}
            mediaUrl={imageSource}
            imageClassModifier='l-form__item c-upload--fill'
            videoClassModifier='c-upload--fill'
            linkToVideo={true}
          />
        </div>
      )}
      <div className='c-result-slider__content'>
        {label && <span className='c-result-slider__title'>{label}</span>}
        <div className='c-result-slider__result'>
          <div className='c-result-slider__bar'>
            <div
              className={`c-result-slider__bar-filled ${PreviewUtils.getEmojiStyle(avgAmount)[0]}`}
              style={{ width: `${weightedAmount || avgAmount}%` }}
            />
          </div>
          <div className='c-result-slider__value'>{`${
            weightedAmount ? Math.round(weightedAmount) : Math.round(avgAmount)
          }%`}</div>
        </div>
        {compareActive && (
          <div className='c-result-slider__compare'>
            <div className='c-result-slider__bar'>
              <div
                className={`c-result-slider__bar-filled ${
                  showEmoji ? PreviewUtils.getEmojiStyle(compareValue)[0] : ''
                }`}
                style={{ width: `${compareValue}%` }}
              />
            </div>
            <div className='c-result-slider__value'>{`${compareValue}%`}</div>
          </div>
        )}
      </div>
    </li>
  );
}

SliderChoice.propTypes = {
  showEmoji: PropTypes.bool,
  compareActive: PropTypes.bool,
  choice: PropTypes.shape({
    imageSource: PropTypes.string,
    value: PropTypes.number,
    compareValue: PropTypes.number,
  }).isRequired,
  weightedAmount: PropTypes.number,
  question: PropTypes.object,
};

export default SliderChoice;
