import React from 'react';
import PropTypes from 'prop-types';
import HelpActionButton from 'common/components/help/HelpActionButton';
import Tooltip from '../../tooltip/Tooltip';
import FieldDescription from '../fieldDescription/FieldDescription';
import SvgIcon from '../../SvgIcon';

export default function SimpleTextInputField(props) {
  const {
    disabled,
    fieldClass,
    actionTooltip,
    fieldDescription,
    fieldDescriptionContext,
    fieldDescriptionLinkUrl,
    id,
    input,
    label,
    maxLength,
    meta,
    placeholder,
    showSlugLabel,
    tooltip,
    tooltipInLabel,
    type = 'text',
    customType,
    icon,
    onValueChange,
    labelLink,
    onLabelLinkClick,
    helpKey,
  } = props;

  const { onChange } = input;

  const mergedOnChange = (e) => {
    if (onValueChange) onValueChange(e.currentTarget.value);
    onChange(e);
  };

  const newInput = { ...input, onChange: mergedOnChange };

  function getContainerClass() {
    const errorClass = meta.error && meta.touched ? 'is-error' : '';
    return `c-field l-form__item ${fieldClass} ${errorClass}`;
  }

  return (
    <div className={getContainerClass()} data-testid={`${id}-wrapper`}>
      {label && (
        <label className='c-field__label' htmlFor={id} data-testid={`${id}-label`}>
          {label}
          {labelLink && (
            <span>
              {' '}
              <button className='o-button o-button--link' type='button' onClick={onLabelLinkClick}>
                &mdash; {labelLink}
              </button>
            </span>
          )}
          {tooltipInLabel && <Tooltip {...tooltipInLabel} />}
        </label>
      )}
      <div className='c-field__group'>
        <div className={`o-input ${icon ? 'o-input-icon' : ''}`}>
          <input
            {...newInput}
            data-testid={`${id}-input`}
            disabled={disabled}
            id={id}
            maxLength={maxLength}
            placeholder={placeholder}
            type={customType || type}
          />
          {icon && <SvgIcon icon={icon} />}
        </div>

        <HelpActionButton helpKey={helpKey} />

        <FieldDescription
          actionTooltip={actionTooltip}
          fieldDescription={fieldDescription}
          fieldDescriptionContext={fieldDescriptionContext}
          fieldDescriptionLinkUrl={fieldDescriptionLinkUrl}
          meta={meta}
          showSlugLabel={showSlugLabel}
          tooltip={tooltip}
          val={newInput.value}
        />
        {/* Discussed with Sax phasing out description and replacig with fieldDescription {description && <p className='c-field__description'>{description}</p>} */}
        <span className='c-field__count' title='Characters left' data-testid={`${id}-max-length`}>
          {maxLength && !disabled ? maxLength - newInput.value.length : ''}
        </span>
      </div>
    </div>
  );
}

SimpleTextInputField.propTypes = {
  disabled: PropTypes.bool,
  fieldClass: PropTypes.string,
  actionTooltip: PropTypes.object,
  fieldDescription: PropTypes.string,
  fieldDescriptionContext: PropTypes.string,
  fieldDescriptionLinkUrl: PropTypes.string,
  id: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  showSlugLabel: PropTypes.bool,
  tooltip: PropTypes.object,
  tooltipInLabel: PropTypes.object,
  type: PropTypes.string,
  onValueChange: PropTypes.func,
  labelLink: PropTypes.string,
  onLabelLinkClic: PropTypes.func,
  helpKey: PropTypes.string,
};
