// NOTE:
//      Derived from /server/bid/api/model/permissions/permissionsEnum.js

/**
 * Here we enumerate all permissions handled by the system
 */
module.exports = function () {
  const HEADER_FIELDS = { SET_AS_CURRENT_BACKEND_PROFILE: 'set-as-current-backend-profile' };

  const ENGAGE_SERVICE_PERMISSIONS = {
    ENGAGE_BASIC_SERVICE: 'ENGAGE_BASIC_SERVICE', // permits editing projects & reporting for given profile
  };

  // NOTE:

  //      Some permissions are doubled for client side, so that these are valid only in pair with license,
  //      i.e. only if client maintains valid service license
  //      e.g.
  //  evaluatePermissionSets(user, {and: [
  //    PermissionsEnums.ENGAGE_SERVICE_PERMISSIONS.ENGAGE_BASIC_SERVICE,
  //    {or:[
  //      PermissionsEnums.GMV_MANAGE_PROJECT_PERMISSIONS.CAN_CREATE_PROJECT,
  //      PermissionsEnums.CLIENT_MANAGE_PROJECT_PERMISSIONS.CLIENT_CAN_CREATE_PROJECT
  //    ]},
  //  ]})
  //  evaluatePermissionSets(user, {or: [
  //    PermissionsEnums.GMV_MANAGE_PROJECT_PERMISSIONS.CAN_VIEW_PROJECT,
  //    {and:[
  //       PermissionsEnums.ENGAGE_SERVICE_PERMISSIONS.ENGAGE_BASIC_SERVICE,
  //       PermissionsEnums.CLIENT_MANAGE_PROJECT_PERMISSIONS.CLIENT_CAN_VIEW_PROJECT
  //    ]},
  //  ]})

  //
  // User creation ----------------
  const MANAGE_ENGAGE_USER_PERMISSIONS = {
    CAN_CREATE_ADMIN_USERS: 'CAN_CREATE_ADMIN_USERS', // Can create admin users
    CAN_CREATE_NON_ADMIN_USERS: 'CAN_CREATE_NON_ADMIN_USERS', // Can create and assign (non admin) users
    CAN_CREATE_CLIENT_USERS: 'CAN_CREATE_CLIENT_USERS', // Can create client users
    CLIENT_CAN_CREATE_CLIENT_USERS: 'CLIENT_CAN_CREATE_CLIENT_USERS', // Client can create client users
    CLIENT_CAN_CREATE_BRANCH_CLIENT_USERS: 'CLIENT_CAN_CREATE_BRANCH_CLIENT_USERS', // Client can create branch client users
  };

  // Client company onboarding -----------
  const MANAGE_CLIENT_COMPANY_PERMISSIONS = {
    CAN_CREATE_COMPANY: 'CAN_CREATE_COMPANY', // Create/edit client
    CAN_CREATE_COMPANY_SERVICE: 'CAN_CREATE_COMPANY_SERVICE', // Assign/revoke client services/licences
    CAN_ASSIGN_USER_TO_COMPANY: 'CAN_ASSIGN_USER_TO_COMPANY', // Assign users to clients/projects
  };

  // Project management -----------
  const GMV_MANAGE_PROJECT_PERMISSIONS = {
    CAN_CREATE_PROJECT: 'CAN_CREATE_PROJECT', // Can create project
    CAN_EDIT_PROJECT: 'CAN_EDIT_PROJECT', // Can edit project (create/delete items)
    CAN_EDIT_TRANSLATIONS: 'CAN_EDIT_TRANSLATIONS', // Can edit translations
    CAN_MANAGE_PUBLISHING: 'CAN_MANAGE_PUBLISHING', // Can manage project publishing
    CAN_VIEW_PROJECT: 'CAN_VIEW_PROJECT', // Can view project
  };

  // Project management client side -----------
  const CLIENT_MANAGE_PROJECT_PERMISSIONS = {
    CLIENT_CAN_CREATE_PROJECT: 'CLIENT_CAN_CREATE_PROJECT', // Can create project
    CLIENT_CAN_EDIT_PROJECT: 'CLIENT_CAN_EDIT_PROJECT', // Can edit project (create/delete items)
    CLIENT_CAN_EDIT_TRANSLATIONS: 'CLIENT_CAN_EDIT_TRANSLATIONS', // Can edit translations
    CLIENT_CAN_MANAGE_PUBLISHING: 'CLIENT_CAN_MANAGE_PUBLISHING', // Can manage project publishing
    CLIENT_CAN_VIEW_PROJECT: 'CLIENT_CAN_VIEW_PROJECT', // Can view project
  };

  // UI access -----------
  const UI_ACCESS_PERMISSIONS = {
    CAN_ACCESS_ADMIN_APP: 'CAN_ACCESS_ADMIN_APP', // Access to admin app
  };

  const ManageUserPermissionHierarchy = {
    CAN_CREATE_ADMIN_USERS: 1, // Can create admin users
    CAN_CREATE_NON_ADMIN_USERS: 2, // Can create and assign (non admin) users
    CAN_CREATE_CLIENT_USERS: 3, // Can create client users
    CLIENT_CAN_CREATE_BRANCH_CLIENT_USERS: 4, // Client can create branch client users
    CLIENT_CAN_CREATE_CLIENT_USERS: 5, // Client can create client users
  };
  const ManageUserPermissionHierarchyLowest = 5; // lowest level associated in ManageUserPermissionHierarchy

  return {
    HEADER_FIELDS: HEADER_FIELDS,
    ENGAGE_SERVICE_PERMISSIONS: ENGAGE_SERVICE_PERMISSIONS,
    MANAGE_ENGAGE_USER_PERMISSIONS: MANAGE_ENGAGE_USER_PERMISSIONS,
    MANAGE_CLIENT_COMPANY_PERMISSIONS: MANAGE_CLIENT_COMPANY_PERMISSIONS,
    GMV_MANAGE_PROJECT_PERMISSIONS: GMV_MANAGE_PROJECT_PERMISSIONS,
    CLIENT_MANAGE_PROJECT_PERMISSIONS: CLIENT_MANAGE_PROJECT_PERMISSIONS,
    UI_ACCESS_PERMISSIONS: UI_ACCESS_PERMISSIONS,
    ManageUserPermissionHierarchy: ManageUserPermissionHierarchy,
    ManageUserPermissionHierarchyLowest: ManageUserPermissionHierarchyLowest,
  };
};
