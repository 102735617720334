const SLUG_CREATE = 'SLUG_CREATE';
const SLUG_UPDATE = 'SLUG_UPDATE';

exports.isSlugValid = isSlugValid;
exports.SLUG_VALIDATION_TYPE = {
  SLUG_CREATE,
  SLUG_UPDATE,
};

function isSlugValid(slug, validationType) {
  // decoding enum into length
  // min 3 chars for update and 5 for create
  // const validationLength = validationType === SLUG_CREATE ? 5 : 3;
  // Overridden by Savannah 16.11.2022 - both limited to 3 chars min
  const validationLength = 3;
  if (!slug || slug.length < validationLength) {
    throw new Error(`Slug should be at least ${validationLength} characters long.`);
  }

  const urlValidator = new RegExp(/^[a-z0-9_-]+$/i);
  if (!urlValidator.test(slug)) {
    throw new Error(`Only alphanumerics and dashes allowed.`);
  }
}

exports.PROJECT_TYPE = { REGULAR_PROJECT: 'REGULAR_PROJECT', SURVEY_ONLY: 'SURVEY_ONLY' };
