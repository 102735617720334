import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

import { Route } from 'react-router-dom';
import SidebarHelp from 'common/components/sidebarHelp/SidebarHelp';
import ProjectMasthead from '../../common/masthead/ProjectMasthead';
import {
  REPORTS,
  REPORTS_DASHBOARD,
  REPORTS_POLLS,
  REPORTS_FEEDBACK,
  REPORTS_GENERATE,
  REPORTS_DEMOGRAPHICS,
} from '../../common/constants/RouteConstants';
import Aside from '../../common/aside/AsideReports';
import Dashboard from './reports/dashboard/Dashboard';
import PollsReport from './reports/polls-report/PollsReport';
import Feedback from './reports/feedback/Feedback';
import Generate from './reports/generate/Generate';
import DemographicsReport from './reports/demographic/DemographicsReport';
import { VIEW } from '../../common/ability';
import { Can } from '../../common/Can';

function ReportingContainer(props) {
  const { match, ProjectStore, HelpStore } = props;
  const id = match.params.id;

  return (
    <div className={`l-page l-page--aside ${HelpStore.isContextualHelpActive ? 'l-page--show-help' : ''}`}>
      <div className='l-masthead'>
        <ProjectMasthead
          id={id}
          route={REPORTS}
          projectLogo={ProjectStore.project.projectLogo}
          projectName={ProjectStore.project.projectName}
        />
      </div>
      <React.Fragment>
        <Route path={`${match.path}/:route`} component={Aside} />
      </React.Fragment>
      <React.Fragment>
        <Route
          exact
          path={`${match.path}/${REPORTS_DASHBOARD}`}
          render={(componentProps) => (
            <Can I={VIEW} a={REPORTS_DASHBOARD}>
              {() => <Dashboard {...componentProps} />}
            </Can>
          )}
        />
        <Route
          exact
          path={`${match.path}/${REPORTS_POLLS}`}
          render={(componentProps) => (
            <Can I={VIEW} a={REPORTS_POLLS}>
              {() => <PollsReport {...componentProps} />}
            </Can>
          )}
        />
        <Route
          exact
          path={`${match.path}/${REPORTS_DEMOGRAPHICS}`}
          render={(componentProps) => (
            <Can I={VIEW} a={REPORTS_DEMOGRAPHICS}>
              {() => <DemographicsReport {...componentProps} />}
            </Can>
          )}
        />
        <Route
          exact
          path={`${match.path}/${REPORTS_FEEDBACK}`}
          render={(componentProps) => (
            <Can I={VIEW} a={REPORTS_FEEDBACK}>
              {() => <Feedback {...componentProps} />}
            </Can>
          )}
        />
        <Route
          exact
          path={`${match.path}/${REPORTS_GENERATE}`}
          render={(componentProps) => (
            <Can I={VIEW} a={REPORTS_GENERATE}>
              {() => <Generate {...componentProps} />}
            </Can>
          )}
        />
      </React.Fragment>
      <SidebarHelp />
    </div>
  );
}

export default inject((root) => ({
  ProjectStore: root.RootStore.projectStore,
  HelpStore: root.RootStore.helpStore,
}))(observer(ReportingContainer));
