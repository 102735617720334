export const BATCH_AFFILIATION_ENUM = [
  [0, 'Phase 1'],
  [1, 'Phase 2'],
  [2, 'Phase 3'],
  [3, 'Phase 4'],
  [4, 'Phase 5'],
  [5, 'Phase 6'],
  [6, 'Phase 7'],
  [7, 'Phase 8'],
];
