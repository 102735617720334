import { reaction, action, observable, computed, makeObservable } from 'mobx';

import enums from '../shared/engage/engage-flow-enums';
import AgentPoll from '../common/agent';

const ENUMS_ARRAY = enums().homepageSectionFlowTypeList();
const Agent = AgentPoll.Projects;

const DEFAULT_SECTIONS = ENUMS_ARRAY.map((item) => ({ sectionType: item, visible: true }));

export default class BlockStore {
  _loading = false;

  _initialized = false;

  _error = null;

  _items = [];

  constructor(rootStore, projectStore) {
    makeObservable(this, {
      _items: observable,
      _loading: observable,
      _initialized: observable,
      _error: observable,

      resetFlags: action,

      blocks: computed,
      isLoading: computed,
      isInitialized: computed,
      error: computed,

      loadData: action,
    });

    this.rootStore = rootStore;
    this.projectStore = projectStore;
    reaction(
      () => this.projectStore.isInitialized,
      () => this.loadData(this.projectStore.project.runtimeOptions),
    );
  }

  get isLoading() {
    return this._loading;
  }

  get isInitialized() {
    return this._initialized;
  }

  get error() {
    return this._error;
  }

  get blocks() {
    return this._items;
  }

  async update(data) {
    this._loading = false;
    this._error = null;

    try {
      await Agent.setHomepageSectionsFlow(this.projectStore.project._id, { homepageSectionFlow: data });
      await this.projectStore.refresh();
    } catch (err) {
      this._error = err;
      this.rootStore.toastrStore.error('Error updating items', null, err);
    } finally {
      this.resetFlags();
    }
  }

  toggleCheckItem(itemName, visibility) {
    this._loading = true;
    this._error = null;
    const newData = this.blocks.map((item) =>
      itemName === item.sectionType ? { ...item, visible: visibility } : { ...item },
    );
    this.update(newData);
  }

  reset() {
    this._loading = true;
    this._error = null;
    Agent.deleteHomepageSectionsFlow(this.projectStore.project._id)
      .then(() => {
        this.refresh();
      })
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error('Error resetting items', null, err);
      })
      .finally(this.resetFlags);
  }

  /**
   * Load and prepare data already loaded in ProjectStore
   * @param data
   */
  loadData(data) {
    let tmp = [];
    this._loading = true;
    this._error = null;
    data.homepageSectionFlow && data.homepageSectionFlow.length !== 0
      ? (tmp = data.homepageSectionFlow)
      : (tmp = DEFAULT_SECTIONS);
    // console.log(toJS(data));
    // console.log(tmp)
    this._items = tmp;
    this._initialized = true;
  }

  refresh = () => {
    this.projectStore.refresh();
  };

  resetFlags = () => {
    this._loading = false;
  };
}
