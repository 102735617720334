import Agent from './agent';

const requests = Agent.requests;
const requests2 = Agent.requestsV2;

const ProjectControlAgent = {
  // Get project status project+polls - visible/published
  getStatus: (projectId) => requests.get(`/projects/${projectId}/publish-status`),
  // show/hide project
  showProject: (projectId) => requests.post(`/projects/${projectId}/show`),
  hideProject: (projectId) => requests.post(`/projects/${projectId}/hide`),
  // show hide from SITEMAP
  showOnSitemap: (projectId) => requests.post(`/projects/${projectId}/sitemap/show`),
  hideOnSitemap: (projectId) => requests.post(`/projects/${projectId}/sitemap/hide`),

  // Publish (copy from draft to public) project and polls
  // In data is what to publish ie.
  // {
  // 	"information": "true",
  // 	"polls":["5bd34199fcdac41240b7d0af", "5bd35816394c3a15e812cd57"]
  // }
  doPublish: (projectId, data) => requests.post(`/projects/${projectId}/update-public`, data),

  // Polls
  showPoll: (projectId, pollId) => requests.post(`/projects/${projectId}/polls/${pollId}/show`, {}),
  hidePoll: (projectId, pollId) => requests.post(`/projects/${projectId}/polls/${pollId}/hide`, {}),

  // Panels
  showPanel: (projectId, panelId) => requests.post(`/projects/${projectId}/panel/${panelId}/show`, {}),
  hidePanel: (projectId, panelId) => requests.post(`/projects/${projectId}/panel/${panelId}/hide`, {}),

  // Demographics
  showDemographics: (projectId, demographicsId) =>
    requests.post(`/projects/${projectId}/demographics/${demographicsId}/show`, {}),
  hideDemographics: (projectId, demographicsId) =>
    requests.post(`/projects/${projectId}/demographics/${demographicsId}/hide`, {}),

  // Allow multiple responses
  // Demographics
  allowMultipleResponse: (projectId, body) => requests.post(`/projects/${projectId}/multiple-answers/allow`, body),
  preventMultipleResponse: (projectId) => requests.post(`/projects/${projectId}/multiple-answers/prevent`, {}),

  // Personal data acquisition
  enablePersonalData: (projectId) => requests.post(`/projects/${projectId}/personal-data/enable`, {}),
  disablePersonalData: (projectId) => requests.post(`/projects/${projectId}/personal-data/disable`, {}),

  setProjectConfiguration: (projectId, projectConfiguration) =>
    requests.post(`/projects/${projectId}/project-configuration`, { projectConfiguration }),

  // General feedback subjecta acquisition
  setGeneralFeedbackSubjectRequired: (projectId) =>
    requests.post(`/projects/${projectId}/general-feeedback-subject/required`, {}),
  setGeneralFeedbackSubjectOptional: (projectId) =>
    requests.post(`/projects/${projectId}/general-feeedback-subject/optional`, {}),

  // Project type
  setProjectAsRegular: (projectId) => requests2.post(`/projects/${projectId}/survey-only/deactivate`, {}),
  setProjectAsPollsOnly: (projectId) => requests2.post(`/projects/${projectId}/survey-only/activate`, {}),
};

export default ProjectControlAgent;
