import React from 'react';

import { inject, observer } from 'mobx-react';
import { Route } from 'react-router-dom';
import Overview from './overview/Overview';
import ProjectMasthead from '../../common/masthead/ProjectMasthead';
import { OVERVIEW } from '../../common/constants/RouteConstants';

function OverviewContainer(props) {
  const { match, ProjectStore, HelpStore } = props;
  const id = match.params.id;
  // console.log(match.path, match, id);
  return (
    <div className={`l-page ${HelpStore.isContextualHelpActive ? 'l-page--show-help' : ''}`}>
      <div className='l-masthead'>
        <ProjectMasthead
          id={id}
          route={OVERVIEW}
          projectLogo={ProjectStore.project.projectLogo}
          projectName={ProjectStore.project.projectName}
        />
      </div>
      <React.Fragment>
        <Route component={Overview} />
      </React.Fragment>
    </div>
  );
}

export default inject((root) => ({
  ProjectStore: root.RootStore.projectStore,
  HelpStore: root.RootStore.helpStore,
}))(observer(OverviewContainer));
