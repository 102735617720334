import Agent from './agent';

const requests = Agent.requestsV2;
const ROOT_TOOLS_ENDPOINT = 'tools';

const ToolsAgent = {
  duplicateSurvey: async (data, sourceSurveyId, sourceProjectId) =>
    requests.post(`/${ROOT_TOOLS_ENDPOINT}/survey-copy/project/${sourceProjectId}/survey/${sourceSurveyId}`, data),
  getGdprEmailReferences: async (data, projectId) =>
    requests.post(`/${ROOT_TOOLS_ENDPOINT}/gdpr/find-by-email/${projectId}`, data),
  removeGdprEmailReferences: async (data, projectId) =>
    requests.post(`/${ROOT_TOOLS_ENDPOINT}/gdpr/remove-by-email/${projectId}`, data),
};

export default ToolsAgent;
