import React from 'react';
import PropTypes from 'prop-types';
import QuestionTemplateAction from './QuestionTemplateAction';

import { IMAGE_FORMATS } from '../../../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import { AWS_PATH } from '../../../../../common/constants/GeneralConstants';
import ImageUtils from '../../../../../common/ImageUtils';
import QuestionTemplateHeader from './QuestionTemplateHeader';

function ImageChoiceOtherPreview(props) {
  const { question } = props;

  return (
    <div className='c-library__item'>
      <div className='c-question c-question--image-three c-question--library'>
        <QuestionTemplateHeader question={question} />
        <div className='c-question__content'>
          <div className='c-question c-question-image--three'>
            <div className='c-question-image__items'>
              {question.choices.map((choice, idx) => (
                <div key={idx} className='c-question-image__item'>
                  <div className='c-question-image__media'>
                    <img
                      src={
                        idx === 2
                          ? `${AWS_PATH}images/choice-other-wide.png`
                          : ImageUtils.imageUrl(choice.resource, IMAGE_FORMATS.PANEL.imageType)
                      }
                      alt=''
                    />
                  </div>
                  <h3 className='c-question-image__label'>{choice.choice}</h3>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <QuestionTemplateAction question={question} />
    </div>
  );
}

ImageChoiceOtherPreview.propTypes = {
  question: PropTypes.shape({
    questionText: PropTypes.string.isRequired,
    choices: PropTypes.array.isRequired,
  }).isRequired,
};

export default ImageChoiceOtherPreview;
