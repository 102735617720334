import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { perc } from '../polls-report/PollReportUtils';
import { IMAGE_FORMATS } from '../../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import SnapshotableImage from '../../../../common/components/snapshotableImage/ScreenshotableImage';
import POLL_ENUMS from '../../../../shared/engage/engage-poll-enums';

function SegmentationStat(props) {
  const { question } = props;
  const { questionText, questionType, totalAnswers, stats } = question;

  // sorted by nrVotes
  // also fixes MobX warning
  // [mobx] `observableArray.sort()` will not update the array in place. Use `observableArray.slice().sort()`
  // to suppress this warning and perform the operation on a copy
  const segmentationChoicesSorted = question && stats ? stats.slice().sort((a, b) => b.count - a.count) : [];

  function getImageFormat() {
    if (questionType === POLL_ENUMS().QuestionTypes.TWO_IMAGE_SINGLE_CHOICE) {
      return IMAGE_FORMATS.PANEL.imageType;
    }
    return IMAGE_FORMATS.SQUARE.imageType;
  }

  return (
    <Fragment>
      <h2 className='c-heading-section c-heading-section--separator'>
        <span>Segmentation</span>
      </h2>
      <div className='c-question'>
        <div className='c-question__header'>
          <h2 className='c-question__title'>{questionText}</h2>
        </div>

        <div className='c-question__content'>
          <div className='c-result-group'>
            <ul className='c-result-group__items'>
              {segmentationChoicesSorted.map((choice, idx) => (
                <li key={choice.choiceId} className='c-result-choice'>
                  <div className='c-result-choice__media'>
                    <SnapshotableImage resource={choice.resource} format={getImageFormat()} />
                  </div>
                  <div className='c-result-choice__content'>
                    <span className='c-result-choice__title'>{choice.label}</span>

                    <span className='c-result-choice__votes'>{choice.count}</span>

                    <div className='c-result-choice__value'>
                      <div className='c-result-choice__bar'>
                        <div
                          className='c-result-choice__bar-filled'
                          style={{ width: `${perc(choice.count, totalAnswers)}%` }}
                        />
                      </div>
                      <div className='c-result-choice__percent'>{`${perc(choice.count, totalAnswers)}%`}</div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

SegmentationStat.propTypes = {
  question: PropTypes.shape({
    questionText: PropTypes.string,
    questionType: PropTypes.string.isRequired,
    totalAnswers: PropTypes.number,
    stats: PropTypes.array.isRequired,
  }).isRequired,
};

export default SegmentationStat;
