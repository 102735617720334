import React from 'react';
import { inject, observer } from 'mobx-react';
import { Form } from 'react-final-form';

import SwitchableHeader from '../../../common/components/switchableHeader/SwitchableHeader';
import EngageTextInputField from '../../../common/components/form-elements/textInputField/TextInputField';
import EngageTextareaInputField from '../../../common/components/form-elements/textareaInputField/TextareaInputField';
import SlimFileUpload from '../../../common/components/form-elements/fileUpload/SlimFileUpload';
import { IMAGE_FORMATS } from '../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import EngageSelectInputField from '../../../common/components/form-elements/selectInputField/SelectInputField';
import SwitchableButtonBar from '../../../common/components/switchableButtonBar/SwitchableButtonBar';
import ability, {
  SET,
  PANEL_LOCATION_ALL,
  PANEL_LOCATION_HOME,
  PANEL_LOCATION_TIMELINE,
  PANEL_LOCATION_NEWS_AND_MEDIA,
  PANEL_LOCATION_POLLS,
} from '../../../common/ability';
import displayConfirmationPrompt from '../../../common/PromptUtil';
import { getFormFieldClass, getFormFieldTranslationTooltip } from '../../../common/TranslationUtils';
import ImageUtils from '../../../common/ImageUtils';

const EngagePanelLocationType = require('../../../shared/engage/engage-panel-enums')().EngagePanelLocationType;

function PanelItem(props) {
  const { panel, Store, ToastrStore } = props;
  const imageId = panel.data.resource;
  const defaultImageId = panel.defaultData.resource;

  const tooltip = {
    modifier: 'o-tooltip--bottom-center',
    label: 'Adding relative links',
    icon: 'question-circle',
    description:
      'If actions lead to other pages within the app you can use relative links. E.g. "/surveys" or "/timeline"',
  };

  const getLocationLabel = (panelPosition) => {
    switch (panelPosition) {
      case EngagePanelLocationType.ALL:
        return 'All sections';
      case EngagePanelLocationType.HOME:
        return 'Home';
      case EngagePanelLocationType.TIMELINE:
        return 'Timeline';
      case EngagePanelLocationType.NEWS_AND_MEDIA:
        return 'News';
      case EngagePanelLocationType.POLLS:
        return 'Surveys';
      default:
        return '';
    }
  };

  function getAllowedPanelLocations() {
    const allowedPanelocations = [];

    if (ability.can(SET, PANEL_LOCATION_ALL))
      allowedPanelocations.push([EngagePanelLocationType.ALL, getLocationLabel(EngagePanelLocationType.ALL)]);
    if (ability.can(SET, PANEL_LOCATION_HOME))
      allowedPanelocations.push([EngagePanelLocationType.HOME, getLocationLabel(EngagePanelLocationType.HOME)]);
    if (ability.can(SET, PANEL_LOCATION_TIMELINE))
      allowedPanelocations.push([EngagePanelLocationType.TIMELINE, getLocationLabel(EngagePanelLocationType.TIMELINE)]);
    if (ability.can(SET, PANEL_LOCATION_NEWS_AND_MEDIA))
      allowedPanelocations.push([
        EngagePanelLocationType.NEWS_AND_MEDIA,
        getLocationLabel(EngagePanelLocationType.NEWS_AND_MEDIA),
      ]);
    if (ability.can(SET, PANEL_LOCATION_POLLS))
      allowedPanelocations.push([EngagePanelLocationType.POLLS, getLocationLabel(EngagePanelLocationType.POLLS)]);

    return allowedPanelocations;
  }

  const headerLabel = `${getLocationLabel(panel.data.panelLocation)} — ${panel.data.panelIdentifier || ''}`;

  const toggleOpened = () => {
    // console.log('toggle');
    Store.toggleOpened([panel.data._id]);
  };

  const uploadImage = (file, progressHandler) => {
    return Store.uploadResource(panel.data._id, file, progressHandler);
  };

  const removeImage = (event) => {
    event.preventDefault();
    return Store.removeResource(panel.data._id);
  };

  const remove = (event) => {
    event.preventDefault();

    const res = displayConfirmationPrompt(null, null, false, ToastrStore);

    if (res === 1) Store.deletePanel(panel.data._id);
  };

  const onSubmit = (values) => {
    // console.log('Submit', values);
    const newPanel = {
      panelIdentifier: values.panelIdentifier,
      name: values.name,
      description: values.description ? values.description : '',
      actionName: values.actionName,
      actionUrl: values.actionUrl,
      panelLocation: values.panelLocation,
    };

    Store.updatePanel(panel.data._id, newPanel);
  };

  const initialValues = (({ actionName, actionUrl, description, name, panelIdentifier, panelLocation }) => {
    return { actionName, actionUrl, description, name, panelIdentifier, panelLocation };
  })(panel.data);

  return (
    <div className={`c-switchable ${props.switchableModifier} ${panel.formState.isOpened ? 'is-opened' : 'is-closed'}`}>
      <SwitchableHeader
        id={panel.data._id}
        ignoreDragHandle={true}
        label='Panel'
        name={headerLabel}
        icon='pen-alt'
        toggleOpened={toggleOpened}
      />

      <div className='c-switchable__content'>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit }) => {
            return (
              <form className='c-fields l-form'>
                <EngageTextInputField
                  id={`input-panel-identifier-${panel.data._id}`}
                  placeholder={panel.defaultData.panelIdentifier || 'Enter identifier'}
                  label='Panel identifier'
                  maxLength='60'
                  fieldDescription='The panel identifier is visible only to you.'
                  fieldName='panelIdentifier'
                  isRequired={true}
                  fieldClass='l-form__item'
                />

                <EngageTextInputField
                  id={`input-panel-name-${panel.data._id}`}
                  placeholder='Enter name'
                  label='Name'
                  maxLength='120'
                  fieldDescription=''
                  fieldName='name'
                  isRequired={false}
                  fieldClass={getFormFieldClass(panel.defaultData.name, null, 'l-form__item')}
                  tooltipInLabel={getFormFieldTranslationTooltip(panel.defaultData.name)}
                />

                <EngageTextareaInputField
                  id={`input-influenced-description-${panel.data._id}`}
                  placeholder='Make it short, sweet and under 500 characters.'
                  label='Description'
                  maxLength='500'
                  fieldDescription=''
                  fieldName='description'
                  isRequired={false}
                  fieldClass={getFormFieldClass(panel.defaultData.description, null, 'l-form__item')}
                  tooltipInLabel={getFormFieldTranslationTooltip(panel.defaultData.description)}
                />

                <SlimFileUpload
                  imageFormat={IMAGE_FORMATS.PANEL}
                  imageId={imageId}
                  defaultImageId={defaultImageId}
                  id={`${panel.data._id}`}
                  handleUpload={uploadImage}
                  label='Image'
                  removeImage={removeImage}
                  classModifier={getFormFieldClass(imageId, defaultImageId)}
                  tooltipInLabel={getFormFieldTranslationTooltip(null, defaultImageId, IMAGE_FORMATS.PANEL)}
                />

                <EngageTextInputField
                  id={`input-panel-action-name-${panel.data._id}`}
                  placeholder='Enter action name'
                  label='Action name'
                  maxLength='30'
                  fieldDescription=''
                  fieldName='actionName'
                  isRequired={false}
                  fieldClass={getFormFieldClass(panel.defaultData.actionName, null, 'l-form__item--m')}
                  tooltipInLabel={getFormFieldTranslationTooltip(panel.defaultData.actionName)}
                />

                <EngageTextInputField
                  id={`input-panel-action-url-${panel.data._id}`}
                  placeholder='Enter action URL'
                  label='Action URL'
                  maxLength='120'
                  fieldDescription=''
                  fieldName='actionUrl'
                  isRequired={false}
                  fieldClass='l-form__item--m'
                  tooltip={tooltip}
                />

                <EngageSelectInputField
                  choices={getAllowedPanelLocations()}
                  id={`input-segment-position-${panel.data._id}`}
                  fieldClass='l-form__item--m'
                  fieldName='panelLocation'
                  label='Panel location'
                  required={true}
                />

                <SwitchableButtonBar updateAction={handleSubmit} removeAction={remove} isNew={null} />
              </form>
            );
          }}
        />
      </div>
    </div>
  );
}

export default inject((root) => ({ Store: root.RootStore.panelsStore, ToastrStore: root.RootStore.toastrStore }))(
  observer(PanelItem),
);
