import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function ActionTooltip(props) {
  const { label, onAction, actionLabel, icon } = props;

  return (
    <span data-testid='action-tooltip'>
      {label}
      {` \u2014 `}
      <button className='o-button o-button--link' type='button' onClick={onAction} data-testid='action-tooltip-button'>
        {icon && <SvgIcon icon={icon} dataTestId='action-tooltip-button-icon' />}{' '}
        <span className='o-label'>{`${actionLabel}`}</span>
      </button>
    </span>
  );
}

ActionTooltip.propTypes = {
  onAction: PropTypes.func.isRequired,
  label: PropTypes.string,
  actionLabel: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default ActionTooltip;
