import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

function QQSwitchableHeader(props) {
  const { name, toggleOpened, isActive } = props;

  return (
    <div className='c-switchable__header'>
      <div className='c-switchable__type'>
        {isActive && <SvgIcon icon='check-circle' />}
        {!isActive && <SvgIcon icon='poll-segment' />}
      </div>
      <h2 className='c-switchable__title'>
        <span className='c-switchable__title-name'>{isActive ? 'Surveying' : 'Question'}</span>
        <span className='c-switchable__title-addon-closed'>{name}</span>
        <span className='c-switchable__title-addon-opened'>Edit</span>
      </h2>
      <button className='c-switchable__toggle' onClick={toggleOpened}>
        <span className='o-label'>Toggle</span>
        <SvgIcon icon='plus' />
      </button>
    </div>
  );
}

QQSwitchableHeader.propTypes = {
  name: PropTypes.string,
  toggleOpened: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default QQSwitchableHeader;
