import React from 'react';

import ActionBar from '../../../common/components/actionBar/ActionBar';
import NewsForm from './NewsForm';

export default function CreateNews(props) {
  // console.log(props);
  return (
    <div className='l-main'>
      <ActionBar label='Create News' hasButton={false} />
      <NewsForm projectId={props.match.params.id} history={props.history} />
    </div>
  );
}
