import { observable, makeObservable } from 'mobx';
import Agent from '../common/agent';

export default class GeolocationStore {
  loading = false;

  initialized = false;

  error = null;

  constructor(rootStore, projectStore) {
    makeObservable(this, {
      initialized: observable,
      loading: observable,
      error: observable,
    });

    this.rootStore = rootStore;
    this.projectStore = projectStore;
  }

  async setGeoLocation(id, body) {
    this._loading = false;
    this._error = null;

    try {
      const res = await Agent.Projects.setGeoLocation(id, body);
      await this.projectStore.refresh();
    } catch (err) {
      this.error = err;
      this.rootStore.toastrStore.error('Error saving location', null, err);
    } finally {
      this.loading = false;
    }
  }

  /**
   *  post /:id/validity-area
   */
  setGeoValidArea = (id, body) => {
    this.loading = true;
    this.error = null;
    return Agent.Projects.setGeoValidArea(id, body)
      .then(() => {
        this.rootStore.toastrStore.success('Location updated', null);
      })
      .catch((err) => {
        this.error = err;
        this.rootStore.toastrStore.error('Error saving location', null, err);
      })
      .finally(() => (this.loading = false));
  };
}
