import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash.clonedeep';
import SvgIcon from '../SvgIcon';
// import SearchBar from '../searchBar/SearchBar';

function FilterSelector(props) {
  const { options, onFilterSelected } = props;

  // const [searchValue, setSearchValue] = useState();
  const [filterOptions, setFilterOptions] = useState([]);

  useEffect(() => {
    setFilterOptions(cloneDeep(options));
  }, [options]);

  function selectedFilterChangeHandler(choiceId) {
    setFilterOptions(cloneDeep(options));

    onFilterSelected(choiceId);
  }

  function openFilterGroup(group) {
    const targetGroup = filterOptions.find((item) => item.label === group);

    if (targetGroup) targetGroup.isOpen = !targetGroup.isOpen;

    setFilterOptions([...filterOptions]);
  }

  function openQuestionGroup(group, questionId) {
    const targetGroup = filterOptions.find((item) => item.label === group);

    if (targetGroup) {
      const targetQuestion = targetGroup.questions.find((item) => item._id === questionId);

      if (targetQuestion) targetQuestion.isOpen = !targetQuestion.isOpen;
    }

    setFilterOptions([...filterOptions]);
  }

  return (
    <div className='c-filter-dropdown' style={{ display: 'block' }} data-testid='fiterSelector-wrapper'>
      {/* We first need to define what we are searching through */}
      {/* <div className='c-filter-dropdown__search'>
        <SearchBar
          value={searchValue}
          onChange={(event) => setSearchValue(event.target.value)}
          onClear={() => setSearchValue('')}
          placeholder='Search questions and choices'
        />
      </div> */}
      <div className='c-filter-dropdown__content'>
        <div className='c-filter-select'>
          {filterOptions.map((filterGroup) => (
            <div
              key={filterGroup.label}
              className={`c-filter-select__group ${filterGroup.isOpen ? 'is-opened' : 'is-closed'}`}
              data-testid={`${filterGroup.label}-filterGroupWrapper`}
            >
              <button
                className='c-filter-select__toggle'
                type='button'
                onClick={() => openFilterGroup(filterGroup.label)}
              >
                <span className='c-filter-select__icon'>
                  <SvgIcon icon='angle-right' />
                </span>
                <h2 className='c-filter-select__title'>{filterGroup.label}</h2>
              </button>
              {filterGroup.questions.map((question) => (
                <div
                  key={question._id}
                  className={`c-filter-select__group c-filter-select__group--nested ${
                    question.isOpen ? 'is-opened' : 'is-closed'
                  }`}
                  data-testid={`${question._id}-filterGroupQuestionWrapper`}
                >
                  <button
                    className='c-filter-select__toggle'
                    type='button'
                    onClick={() => openQuestionGroup(filterGroup.label, question._id)}
                  >
                    <span className='c-filter-select__icon'>
                      <SvgIcon icon={filterGroup.icon} />
                    </span>
                    <h3 className='c-filter-select__title'>{question.questionText}</h3>
                  </button>
                  <div className='c-filter-select__choices'>
                    {question.choices.map((choice) => (
                      <div key={choice._id} className='o-radio o-radio--s o-radio--button'>
                        <input
                          type='radio'
                          id={choice._id}
                          name={question.label}
                          onChange={() => selectedFilterChangeHandler(choice._id)}
                        />
                        <label htmlFor={choice._id}>{choice.choice}</label>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

FilterSelector.propTypes = {
  options: PropTypes.array.isRequired,
  onFilterSelected: PropTypes.func.isRequired,
};

export default FilterSelector;
