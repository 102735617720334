import React from 'react';
import PropTypes from 'prop-types';

function EmbedInlineOptions(props) {
  const { startingScreen, visibleHeaderAndNavigation, dynamicHeight, dynamicHeightValue, onInlineOptionsChange } =
    props;
  return (
    <div className='c-field l-form__item'>
      <label className='c-field__label'>Embed options</label>
      <div className='c-field__group'>
        <div className='o-checkbox o-checkbox--toggle'>
          <input
            type='checkbox'
            id='checkbox-embed-header'
            checked={visibleHeaderAndNavigation}
            onChange={(event) =>
              onInlineOptionsChange(event.target.checked, dynamicHeight, dynamicHeightValue, startingScreen)
            }
          />
          <label htmlFor='checkbox-embed-header'>Visible header and navigation</label>
        </div>
        <div className='u-display-flex'>
          <div className='o-checkbox o-checkbox--toggle o-checkbox--inline'>
            <input
              type='checkbox'
              id='checkbox-embed-height'
              checked={dynamicHeight}
              onChange={(event) =>
                onInlineOptionsChange(
                  visibleHeaderAndNavigation,
                  event.target.checked,
                  dynamicHeightValue,
                  startingScreen,
                )
              }
            />
            <label htmlFor='checkbox-embed-height'>Dynamic height</label>
          </div>
          {!dynamicHeight && (
            <div className='o-input-suffix'>
              <input
                type='number'
                id='embed-height'
                value={dynamicHeightValue}
                onChange={(event) =>
                  onInlineOptionsChange(visibleHeaderAndNavigation, dynamicHeight, event.target.value, startingScreen)
                }
                placeholder='Height'
                size='5'
              />
              <span className='o-input-suffix__label'>px</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

EmbedInlineOptions.propTypes = {
  startingScreen: PropTypes.string.isRequired,
  visibleHeaderAndNavigation: PropTypes.bool.isRequired,
  dynamicHeight: PropTypes.bool.isRequired,
  dynamicHeightValue: PropTypes.string.isRequired,
  onInlineOptionsChange: PropTypes.func.isRequired,
};

export default EmbedInlineOptions;
