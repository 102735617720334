import Agent from './agent';

const requests = Agent.requests;

const OverviewAgent = {
  getProjectOverview: (projectId) => requests.get(`/projects/${projectId}/overview`),
  // Get project status project+polls - visible/published
  getStatus: (projectId) => requests.get(`/projects/${projectId}/publish-status`),
};

export default OverviewAgent;
