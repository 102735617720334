import React from 'react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import UpdateDetailsRow from './UpdateDetailsRow';

function CustomizeUpdateDetails(props) {
  const { customizeValidation, sectionLabel } = props;
  const { validation, panels, staticTextsReplacements, endOfPollFlow } = customizeValidation;

  return (
    <table>
      <thead>
        <tr>
          <th>{sectionLabel}</th>
          {validation.languages.map((item, index) => (
            <th key={`${item.language}-${index}`}>
              <abbr title={item.languageTitleEN}>{item.language}</abbr>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <UpdateDetailsRow key='panel' rowLabel='Panels' languageData={panels.validation.thisTreeLanguageData} />
        <UpdateDetailsRow
          key='staticText'
          rowLabel='Static text'
          languageData={staticTextsReplacements.validation.thisTreeLanguageData}
        />
        <UpdateDetailsRow
          key='actions'
          rowLabel='End of survey actions'
          languageData={endOfPollFlow.validation.thisTreeLanguageData}
        />
      </tbody>
    </table>
  );
}

CustomizeUpdateDetails.propTypes = {
  customizeValidation: PropTypes.shape({
    panels: PropTypes.shape({
      validation: PropTypes.shape({
        thisTreeLanguageData: PropTypes.array.isRequired,
      }).isRequired,
    }).isRequired,
    endOfPollFlow: PropTypes.shape({
      validation: PropTypes.shape({
        thisTreeLanguageData: PropTypes.array.isRequired,
      }).isRequired,
    }).isRequired,
    staticTextsReplacements: PropTypes.shape({
      validation: PropTypes.shape({
        thisTreeLanguageData: PropTypes.array.isRequired,
      }).isRequired,
    }).isRequired,
    validation: PropTypes.shape({
      languages: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
  sectionLabel: PropTypes.string.isRequired,
};

export default CustomizeUpdateDetails;
