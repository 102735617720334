import React, { useState, useEffect } from 'react';
import ActionBar from 'common/components/actionBar/ActionBar';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import SvgIcon from 'common/components/SvgIcon';
import { Can } from 'common/Can';
import { VIEW } from 'common/ability';
import { PROJECT, PERSONAL_DATA, REPORTS, SETTINGS } from 'common/constants/RouteConstants';
import { PROJECT_CONFIGURATIONS, REGULAR_PROJECT } from 'shared/engage/engage-general-enums';
import { HELP } from 'shared/engage/engage-help-enums';
import SettingsOption from '../SettingsOption';
import SurveyOnlyConfiguration from './SurveyOnlyConfiguration';

const D1 = require('shared/engage/engage-token-expirationEnums').ExpirationTypes.D1;

export const CONFIGURATION_TOGGLE_OPTIONS = {
  SEARCH_ENGINE_VISIBILITY: 'search-engine-visibility',
  MULTILINE_RESPONSE: 'multiple-responses',
  PERSONAL_DATA: 'personal-data',
  GENERAL_FEEDBACK_SUBJECT: 'general-feedback-subject',
};

function Configuration(props) {
  const { Store, match } = props;

  const projectId = match.params.id;
  const status = Store.publishStatus;

  const [projectConfiguration, setProjectConfiguration] = useState(REGULAR_PROJECT);

  useEffect(() => {
    Store.getStatus(projectId);
  }, [Store, projectId]);

  useEffect(() => {
    setProjectConfiguration(status?.projectConfiguration || REGULAR_PROJECT);
  }, [status]);

  function projectTypeChangeHandler(value) {
    setProjectConfiguration(value);
    Store.setProjectConfiguration(value);
  }

  return (
    <div className='l-main' data-testid='configuration-wrapper'>
      <ActionBar
        label='Configuration'
        hasButton={false}
        helpKey={HELP.PROJECT.SETTINGS.CONFIGURATION.BASE}
        isTextOnlyHelp={true}
      />
      <form className='c-fields c-fields--modern l-form'>
        <SettingsOption
          id={CONFIGURATION_TOGGLE_OPTIONS.SEARCH_ENGINE_VISIBILITY}
          title='Search engine visibility'
          toggleLabelOn='Encourage search engines to index this project'
          isActive={status.sitemapPresence}
          onChange={(checked) => Store.setSitemapVisibility(checked)}
        />
        <SettingsOption
          id={CONFIGURATION_TOGGLE_OPTIONS.MULTILINE_RESPONSE}
          title='Multiple responses'
          toggleLabelOn='Allow users to answer surveys more than once per day'
          isActive={!!status.forceExpireToken}
          onChange={(checked) => Store.setMultipleResponse(checked && D1)}
        />
        <Can I={VIEW} a={PERSONAL_DATA}>
          <SettingsOption
            id={CONFIGURATION_TOGGLE_OPTIONS.PERSONAL_DATA}
            title='Personal data acquisition'
            toggleLabelOn='Enabled'
            toggleLabelOff='Disabled'
            isActive={status.personalDataIsActive}
            isDisabled={!status.personalDataIsConfigured}
            onChange={(checked) => Store.setPersonalDataAcquisition(checked)}
          />
        </Can>
        <SettingsOption
          id={CONFIGURATION_TOGGLE_OPTIONS.GENERAL_FEEDBACK_SUBJECT}
          title='General feedback form subject field'
          toggleLabelOn='Required'
          toggleLabelOff='Not required'
          isActive={status.isFeedbackSubjectRequired}
          description={`Making <strong>subject field required</strong> means that people <strong>won&apos;t be able to submit the form</strong> without writing a subject.`}
          onChange={(checked) => Store.setGeneralFeedbackSubjectStatus(checked)}
        />

        <Can I={VIEW} a={PERSONAL_DATA}>
          <div className='c-field c-field--modern l-form__item' data-testid='personal-data-config-wrapper'>
            <label className='c-field__label' htmlFor='select-project-type'>
              Project type
            </label>
            <div className='c-field__group'>
              <div className='o-select-group'>
                <div className='o-select'>
                  <SvgIcon icon='angle-down' />
                  <select
                    id='select-project-configuration'
                    value={projectConfiguration}
                    onChange={(event) => projectTypeChangeHandler(event.target.value)}
                  >
                    <option value='Select project type' disabled>
                      Select project type
                    </option>
                    {PROJECT_CONFIGURATIONS.map((type, index) => (
                      <option key={index} value={type[0]}>
                        {type[1]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </Can>
      </form>
      <SurveyOnlyConfiguration />
    </div>
  );
}

Configuration.propTypes = {
  Store: PropTypes.object.isRequired,
};

export default inject((root) => ({ Store: root.RootStore.projectControlStore }))(observer(Configuration));
