import Agent from './agent';

const requests = Agent.requests;

const ENDPOINT = 'recommender';

const PollLibraryAgent = {
  suggestKeyword: (keyword) => requests.get(`/${ENDPOINT}/suggest?key=${keyword}`),
  search: (data, axiosCancelToken) => requests.post(`/${ENDPOINT}/search`, data, { cancelToken: axiosCancelToken }),
  profiles: (keyword) => requests.get(`/${ENDPOINT}/profiles?name=${keyword}`),
  projects: (data) => requests.post(`/${ENDPOINT}/projects`, data),
  polls: (data) => requests.post(`/${ENDPOINT}/polls`, data),
  questions: (projectId, pollId, data) =>
    requests.post(`/${ENDPOINT}/project/${projectId}/poll/${pollId}/questions`, data),
};

export default PollLibraryAgent;
