import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

function ProjectStatusToggle(props) {
  const { Store } = props;

  const onChange = (event) => {
    Store.setProjectVisibility(event.target.checked);
  };

  return (
    <form className='c-fields c-fields--modern l-form' data-testid='project-status-toggle-wrapper'>
      <div className='c-field c-field--modern c-field--large l-form__item'>
        <label className='c-field__label'>Project status</label>
        <div className='c-field__group'>
          <div className='o-checkbox o-checkbox--toggle is-danger'>
            <input
              type='checkbox'
              id='checkbox-project-status'
              value={Store.publishStatus.isProjectActive}
              onChange={onChange}
              checked={Store.publishStatus.isProjectActive}
            />
            <label htmlFor='checkbox-project-status'>
              {Store.publishStatus.isProjectActive ? 'Active' : 'Inactive'}
            </label>
          </div>
          <p className='c-field__description'>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Projects can be <strong>active</strong> or <strong>inactive</strong>. Inactive projects won't be visible to
            the community.
          </p>
        </div>
      </div>
    </form>
  );
}

ProjectStatusToggle.propTypes = {
  Store: PropTypes.object.isRequired,
};

export default inject((root) => ({ Store: root.RootStore.projectControlStore }))(observer(ProjectStatusToggle));
