import { observable, computed, action, runInAction, makeObservable } from 'mobx';
import { UsersAgent as Agent } from '../common/agent';

const ITEM_LABEL = 'user';
const LOAD_ERROR = `Error loading ${ITEM_LABEL}`;
const CREATE_SUCCESS = `New ${ITEM_LABEL} added`;
const CREATE_ERROR = `Error creating ${ITEM_LABEL}`;
const UPDATE_SUCCESS = 'Saved';
const UPDATE_ERROR = `Error saving ${ITEM_LABEL}`;
const DELETE_SUCCESS = 'User removed';
const DELETE_ERROR = `Error deleting ${ITEM_LABEL}`;

export default class UsersStore {
  _loading = false;

  _initialized = false;

  _error = null;

  _item = null;

  _items = [];

  _roles = [];

  constructor(rootStore) {
    makeObservable(this, {
      _item: observable,
      _items: observable,
      _loading: observable,
      _initialized: observable,
      _error: observable,
      _roles: observable,

      resetFlags: action,
      resetUsers: action,

      users: computed,
      user: computed,
      roles: computed,
    });

    this.rootStore = rootStore;
  }

  getAll(clientId) {
    this._loading = true;
    this._error = null;
    return Agent.all(clientId)
      .then((res) => {
        runInAction(() => {
          this._items = res.data;
        });
      })
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(LOAD_ERROR, null, err);
      })
      .finally(this.resetFlags);
  }

  getById(clientId, userId) {
    this._loading = true;
    this._error = null;
    return Agent.getById(clientId, userId)
      .then((res) => {
        runInAction(() => {
          this._item = res.data;
        });
        return res.data;
      })
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(LOAD_ERROR, null, err);
      })
      .finally(this.resetFlags);
  }

  create(clientId, data) {
    this._loading = true;
    this._error = null;
    return Agent.create(clientId, data)
      .then((res) => {
        // eslint-disable-next-line no-param-reassign
        data._id = res.data._id;
        runInAction(() => {
          this._items.push(data);
        });
        this.rootStore.toastrStore.success(CREATE_SUCCESS);
        return data;
      })
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(CREATE_ERROR, null, err);
      })
      .finally(this.resetFlags);
  }

  update(clientId, userId, data) {
    this._loading = true;
    this._error = null;
    return Agent.update(clientId, userId, data)
      .then(() => {
        this.rootStore.toastrStore.success(UPDATE_SUCCESS);
        return data;
      })
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(UPDATE_ERROR, null, err);
      })
      .finally(this.resetFlags);
  }

  remove(clientId, userId) {
    this._loading = true;
    this._error = null;
    return Agent.remove(clientId, userId)
      .then(() => {
        this.rootStore.toastrStore.success(DELETE_SUCCESS);
        return userId;
      })
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(DELETE_ERROR, null, err);
      })
      .finally(this.resetFlags);
  }

  getRoles(clientId) {
    this._loading = true;
    this._error = null;
    return Agent.getRoles(clientId)
      .then((res) => {
        runInAction(() => {
          this._roles = res.data;
        });
        return res.data;
      })
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(LOAD_ERROR, null, err);
      })
      .finally(this.resetFlags);
  }

  resetFlags = () => {
    this._loading = false;
  };

  resetUsers = () => {
    this._items = [];
  };

  get isLoading() {
    return this._loading;
  }

  get isInitialized() {
    return this._initialized;
  }

  get error() {
    return this._error;
  }

  get users() {
    return this._items;
  }

  get user() {
    return this._item;
  }

  get roles() {
    const preparedRoles = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const role of this._roles) {
      preparedRoles.push([role._id, role.label, role.category]);
    }
    preparedRoles.sort((a, b) => a[1].localeCompare(b[1]));

    return preparedRoles;
  }
}
