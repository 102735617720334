import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import { HELP } from 'shared/engage/engage-help-enums';
import ActionBar from '../../../../common/components/actionBar/ActionBar';
import QuestionPicker from '../../polls/pollEdit/QuestionPicker';
import QuestionSwitcher from '../../polls/pollEdit/QuestionSwitcher';

function Segmentation(props) {
  const { Store, ProjectStore, ToastrStore, match } = props;
  const [isPickerVisible, setIsPickerVisible] = useState(false);

  const projectId = match.params.id;
  const questions = Store ? Store.questions : [];

  useEffect(() => {
    if (Store && projectId) Store.load(projectId);
  }, [Store, ProjectStore.project, projectId]);

  function setPickerVisible(isVisible) {
    setIsPickerVisible(isVisible);
  }

  return (
    <div className='l-main'>
      <ActionBar
        addNew={null}
        label='Segmentation'
        hasButton={false}
        helpKey={HELP.PROJECT.EDIT_PROJECT.SEGMENTATION.PAGE}
        isTextOnlyHelp={true}
      />
      <p>
        Segmentation allows you to ask a question of which survey results can be filtered by. Only one segmentation
        question can be active at once and changing the segmentation question during a survey will create an incomplete
        set of data to filter. Segmentation questions should only be changed between survey phases.
      </p>

      <div className='c-switchable-list'>
        {questions.map((question, index) => (
          <QuestionSwitcher
            Store={Store}
            ToastrStore={ToastrStore}
            key={question._id}
            index={index}
            classModifier='c-switchable--reorder'
            question={question}
            isQQ={true}
          />
        ))}
      </div>

      {!isPickerVisible && (
        <button
          className='o-button o-button--primary jsPollAddButton'
          type='button'
          onClick={() => setPickerVisible(true)}
        >
          <span className='o-label'>Add</span>
        </button>
      )}

      {isPickerVisible && (
        <QuestionPicker createQuestion={Store.createQuestion} setPickerVisible={setPickerVisible} isQQ={true} />
      )}
    </div>
  );
}

Segmentation.propTypes = {
  Store: PropTypes.object.isRequired,
  ProjectStore: PropTypes.object.isRequired,
  ToastrStore: PropTypes.object.isRequired,
};

export default inject((root) => ({
  Store: root.RootStore.segmentationStore,
  ProjectStore: root.RootStore.projectStore,
  ToastrStore: root.RootStore.toastrStore,
}))(observer(Segmentation));
