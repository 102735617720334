import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import SvgIcon from '../../../../../common/components/SvgIcon';

function QuestionTemplateAction(props) {
  const { question, PollEditStore, PollLibraryStore } = props;

  const [isCopying, setIsCopying] = useState(false);

  function copyToPoll() {
    setIsCopying(true);
    PollLibraryStore.isCopying = true;
    const questionData = { projectId: question.engageProjectId, pollId: question.pollId, questionId: question._id };
    PollEditStore.copyFromQuestionTemplate(questionData).then((success) => {
      setIsCopying(false);
      PollLibraryStore.isCopying = false;
      if (success) PollLibraryStore.updateQuestionStatus(questionData.questionId);
    });
  }

  return (
    <div className='c-library__actions'>
      <button
        className={`o-button o-button--s ${question.copiedToPoll ? 'o-button--success' : 'o-button--primary'} ${
          PollLibraryStore.isCopying ? 'is-disabled' : ''
        } ${isCopying ? 'is-loading' : ''}`}
        type='button'
        onClick={copyToPoll}
        disabled={question.copiedToPoll || PollLibraryStore.isCopying}
      >
        <SvgIcon icon={`${question.copiedToPoll ? 'check' : 'plus-circle'}`} />
        <span className='o-label'>{`${question.copiedToPoll ? 'Added' : 'Add'}`}</span>
      </button>
    </div>
  );
}

QuestionTemplateAction.propTypes = {
  question: PropTypes.object.isRequired,
  PollEditStore: PropTypes.object.isRequired,
};

export default inject((root) => ({
  PollEditStore: root.RootStore.pollEditStore,
  PollLibraryStore: root.RootStore.pollLibraryStore,
}))(observer(QuestionTemplateAction));
