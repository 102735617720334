import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { QUESTION_TYPES } from 'common/constants/QuestionConstants';
import { CONDITIONAL_CRITERIA } from '../../../ConditionalLogicUtil';
import EngageTextInputField from '../../form-elements/textInputField/TextInputField';
import EngageSelectInputField from '../../form-elements/selectInputField/SelectInputField';

function ValueConditionBlock(props) {
  const { condition, formValues, questionType } = props;

  function getMinValue() {
    if (questionType === QUESTION_TYPES.RATING) return 1;

    return 0;
  }

  function getMaxValue() {
    if (questionType === QUESTION_TYPES.RATING) return parseInt(formValues.multiChoicesOfferedBound, 10);

    return 100;
  }

  function getPlaceholder() {
    if (questionType === QUESTION_TYPES.RATING)
      return `Enter value between 1 and ${formValues.multiChoicesOfferedBound}`;

    return 'Enter value between 0 and 100';
  }

  function validator(value) {
    const maxValue = getMaxValue();
    const minValue = getMinValue();

    return value === undefined || value > maxValue || value < minValue
      ? `Please enter a value between ${minValue} and ${maxValue}`
      : undefined;
  }

  return (
    <Fragment>
      <EngageSelectInputField
        choices={CONDITIONAL_CRITERIA}
        id={condition.criteria}
        fieldName={condition.criteria}
        firstOptionEmpty={false}
      />

      <EngageTextInputField
        id={condition.id}
        placeholder={getPlaceholder()}
        customType='number'
        fieldName={condition.value}
        isRequired={true}
        validator={validator}
      />
    </Fragment>
  );
}

ValueConditionBlock.propTypes = {
  condition: PropTypes.object,
  questionType: PropTypes.string.isRequired,
  formValues: PropTypes.object.isRequired,
};

export default ValueConditionBlock;
