import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { HELP } from 'shared/engage/engage-help-enums';
import HelpActionButton from '../help/HelpActionButton';

export const TAB_NAV_CHOICES = { FIRST: 'first', SECOND: 'second' };

function TabNavigation(props) {
  const { firstOptionLabel, secondOptionLabel, onSelectionChange } = props;

  const [selectedOption, setSelectedOption] = useState(TAB_NAV_CHOICES.FIRST);

  function selectionChangeHandler(option) {
    setSelectedOption(option);
    onSelectionChange(option);
  }

  return (
    <div className='c-tabs-navigation' data-testid='component-wrapper'>
      <ul className='c-tabs-navigation__container' role='tablist' aria-label='Toggle'>
        <li className='c-tabs-navigation__item'>
          <button
            id={TAB_NAV_CHOICES.FIRST}
            role='tab'
            aria-selected={selectedOption === TAB_NAV_CHOICES.FIRST}
            aria-controls='panel--01'
            onClick={(event) => selectionChangeHandler(TAB_NAV_CHOICES.FIRST)}
          >
            {firstOptionLabel}
          </button>
        </li>
        <li className='c-tabs-navigation__item'>
          <button
            id={TAB_NAV_CHOICES.SECOND}
            role='tab'
            aria-selected={selectedOption === TAB_NAV_CHOICES.SECOND}
            aria-controls='panel--02'
            onClick={(event) => selectionChangeHandler(TAB_NAV_CHOICES.SECOND)}
          >
            {secondOptionLabel}
          </button>
          <HelpActionButton helpKey={HELP.PROJECT.EDIT_PROJECT.SURVEY.CONDITIONAL_LOGIC} />
        </li>
      </ul>
    </div>
  );
}

TabNavigation.propTypes = {
  firstOptionLabel: PropTypes.string.isRequired,
  secondOptionLabel: PropTypes.string.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
};

export default TabNavigation;
