import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';

import SvgIcon from '../SvgIcon';

export default SortableHandle(({ fieldClass = 'c-switchable__handle' }) => (
  <div className={fieldClass} data-testid='handle-wrapper'>
    <SvgIcon icon='grab' dataTestId='handle-icon' />
    <span className='o-label' data-testid='handle-label'>
      Reorder
    </span>
  </div>
));
