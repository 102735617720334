import React from 'react';
import { inject, observer } from 'mobx-react';
// import { toJS } from 'mobx';
import { Form } from 'react-final-form';
import { Link } from 'react-router-dom';

import history from '../../common/history';
import { TextInputField } from '../../common/components/form-elements';
import { HOME, AUTH, AUTH_FORGOT_PASSWORD } from '../../common/constants/RouteConstants';

function Login(props) {
  const { Store } = props;

  const onSubmit = async (values) => {
    // console.log(values, Store);
    try {
      await Store.login(values.email, values.password, values.rememberMe);
      history.push(`/${HOME}`);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <h2 className='c-login__title'>Sign in</h2>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => {
          return (
            <form className='c-fields l-form l-form--single-column c-login__form'>
              <TextInputField
                id='email'
                fieldName='email'
                classModifier='mb-3'
                icon='envelope'
                placeholder='Enter email'
                label='Your Email'
                isRequired={true}
              />
              <TextInputField
                id='password'
                fieldName='password'
                icon='lock'
                placeholder='Enter password'
                customType='password'
                label='Your Password'
                isRequired={true}
              />
              {/* <CheckboxInputField fieldName={'rememberMe'} label={'Remember me'} id={'rememberMe'} type='checkbox' /> */}

              <div className='c-field c-field--action l-form__item'>
                <div className='c-field__group'>
                  <button className='o-button o-button--primary' type='submit' onClick={handleSubmit}>
                    <span className='o-label'>Sign in</span>
                  </button>
                  <p className='c-field__description'>
                    <Link to={`/${AUTH}/${AUTH_FORGOT_PASSWORD}`}>Forgot password?</Link>
                  </p>
                </div>
              </div>
            </form>
          );
        }}
      />
    </React.Fragment>
  );
}

export default inject((root) => ({ Store: root.RootStore.authStore }))(observer(Login));
