import Cookies from 'universal-cookie';
import axios from 'axios';
import {
  USER_API_ROOT,
  BACKEND_API_ROOT,
  BACKEND_API_ROOT_V2,
  BUILT_ID_API_ROOT,
  GMV_COOKIE,
} from '../../shared/engage/client-constants';
import history from '../history';
import { AUTH, AUTH_LOGIN, ERROR_403 } from '../constants/RouteConstants';

const DEFAULT_LANGUAGE = 'en';

const cookies = new Cookies();

const instance = axios.create({
  baseURL: BACKEND_API_ROOT,
});

let language = DEFAULT_LANGUAGE;

function getHeaders() {
  // const token = localStorage.getItem('token') || sessionStorage.getItem('token');

  return {
    'Accept-Language': language,
    Authorization: `Bearer ${cookies.get(GMV_COOKIE)}`,
    // Authorization: 'Bearer ' + token,
  };
}

function getConfig(params) {
  return {
    headers: getHeaders(),
    params,
  };
}

function handleError(err) {
  // console.log('API error', JSON.stringify(err.response));
  if (err.response) {
    if (err.response.status === 401) {
      history.push(`/${AUTH}/${AUTH_LOGIN}`);
    }
    if (err.response.status === 403) {
      history.push(`/${ERROR_403}`);
    }
  }
  throw err;
}

const getRequests = (apiRoot = BACKEND_API_ROOT) => ({
  remove: (url) => axios.delete(`${apiRoot}${url}`, getConfig()).catch((err) => handleError(err)),
  get: (url, params) => axios.get(`${apiRoot}${url}`, getConfig(params)).catch((err) => handleError(err)),
  patch: (url, body) => axios.patch(`${apiRoot}${url}`, body, getConfig()).catch((err) => handleError(err)),
  post: (url, body, config) =>
    axios.post(`${apiRoot}${url}`, body, { ...getConfig(), ...config }).catch((err) => handleError(err)),
  upload: (url, file, config) => axios.post(`${apiRoot}${url}`, file, config).catch((err) => handleError(err)),
  // eslint-disable-next-line no-use-before-define
  uploadImage,

  // Uploads a file to S3
  // Actually it's a generic PUT but to an absolute path
  s3upload: (url, file, config) => axios.put(`${url}`, file, config),

  getRaw: (url) => axios.get(`${url}`).catch((err) => handleError(err)),
});

const userAPIRequests = {
  remove: (url) => axios.delete(`${USER_API_ROOT}${url}`, getConfig()).catch((err) => handleError(err)),
  get: (url, params) => axios.get(`${USER_API_ROOT}${url}`, getConfig(params)).catch((err) => handleError(err)),
  patch: (url, body) => axios.patch(`${USER_API_ROOT}${url}`, body, getConfig()).catch((err) => handleError(err)),
  post: (url, body) => axios.post(`${USER_API_ROOT}${url}`, body, getConfig()).catch((err) => handleError(err)),
};

const builtIDRequests = {
  get: (url) => axios.get(`${BUILT_ID_API_ROOT}${url}`, getConfig()),
};

/**
 * Prepare POST request for upload
 * @param url
 * @param type
 * @param file
 * @returns {*}
 */
async function uploadImage(url, type, file, progress) {
  // first get a signed upload URL from S3
  const signed = await getRequests().get('/projects/image-upload-url');
  const { signedUrl, imageId, pureId } = signed.data;

  await getRequests().s3upload(signedUrl, file, {
    headers: { 'Content-Type': 'image/jpeg' },
    onUploadProgress: progress,
  });

  const image = {
    imageId,
    pureId,
    filename: file.name,
    contentType: file.type,
    size: file.size,
    metadata: { type: 'GMV' },
  };

  return getRequests().post(url, image);
}
// eslint-disable-next-line
export default {
  requests: getRequests(BACKEND_API_ROOT),
  requestsV2: getRequests(BACKEND_API_ROOT_V2),
  builtIDRequests,
  userAPIRequests,
  setLanguage: (_language) => {
    language = _language;
    instance.defaults.headers.common['Accept-Language'] = language;
  },
  getLanguage: () => language,
  DEFAULT_LANGUAGE,
};
