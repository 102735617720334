import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';
import DragHandle from './DragHandle';

export default function SwitchableHeader({
  id,
  label,
  name,
  icon,
  toggleOpened,
  ignoreDragHandle,
  tooltips = [],
  orderNumber,
}) {
  return (
    <div className='c-switchable__header' data-testid={`${id}-switchable-header-wrapper`}>
      {!ignoreDragHandle && <DragHandle />}
      <div className='c-switchable__type'>
        <SvgIcon icon={icon} dataTestId={`${id}-switchable-header-icon`} />
      </div>
      <h2 className='c-switchable__title'>
        {orderNumber && <span className='c-switchable__title-number'>{orderNumber}</span>}
        <span className='c-switchable__title-name' data-testid={`${id}-switchable-header-label`}>
          {label}
        </span>
        <span className='c-switchable__title-addon-closed' data-testid={`${id}-switchable-header-closed_title`}>
          {name}
        </span>
        <span className='c-switchable__title-addon-opened' data-testid={`${id}-switchable-header-opened_title`}>
          Edit
        </span>
      </h2>
      {tooltips.map((tooltip) => (
        <span key={tooltip.label} className='o-tooltip o-tooltip--bottom-center' role='tooltip'>
          <span className='o-tooltip__action' role='img'>
            <SvgIcon icon={tooltip.icon} />
          </span>
          <span className='o-tooltip__description'>{tooltip.label}</span>
        </span>
      ))}
      <button
        className='c-switchable__toggle'
        onClick={toggleOpened}
        data-testid={`${id}-switchable-header-toggle_button`}
      >
        <span className='o-label'>Toggle</span>
        <SvgIcon icon='plus' />
      </button>
    </div>
  );
}

SwitchableHeader.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  icon: PropTypes.string.isRequired,
  toggleOpened: PropTypes.func.isRequired,
  ignoreDragHandle: PropTypes.bool,
  tooltips: PropTypes.array,
  orderNumber: PropTypes.number,
};
