export const required = (value) => (value ? undefined : 'Required');

/**
 * Handle disabled and loading state style for a form button
 * @param invalid
 * @param validating
 * @returns {string}
 */
export const getValidatingButtonClass = (invalid, validating) => {
  let clazz = invalid || validating ? ' is-disabled ' : '';
  clazz += validating ? ' is-loading ' : '';
  return clazz;
};

/**
 * Utility function to memoize function calls
 * Basically, don't execute if called with the same argument
 * @param fn
 * @returns {function(*): *}
 */
export const simpleMemoize = (fn) => {
  let lastArg;
  let lastResult;
  return (arg) => {
    if (arg !== lastArg) {
      lastArg = arg;
      lastResult = fn(arg);
    }
    return lastResult;
  };
};

/**
 * Slug validation
 */

const { isSlugValid } = require('../shared/engage').ProjectEnums;

/**
 * Returns error message to display in the field if any, undefined otherwise
 * This is the ReactFinalForms contract
 * @param store
 * @param validationType
 * @param useProjectId
 * @returns {(function(*): Promise<*|undefined>)|*}
 */
const validateSlug = (store, validationType, useProjectId) => {
  return async (slug) => {
    try {
      // formal validation
      isSlugValid(slug, validationType);
      // uniqueness validation (API call)
      await store.validateSlug(slug, validationType, useProjectId);
    } catch (e) {
      return e.message;
    }
  };
};

/**
 * Validate slug formally + online uniqueness validation
 * Need to memoize to avoid validating slug on every form update ie. project name
 */
export const _validateSlugMemoized = (store, validationType, useProjectId) => {
  return simpleMemoize(validateSlug(store, validationType, useProjectId));
};

/**
 * END Slug validation
 */

export function validateMarkdownLength(value, maxLength) {
  if (!value) return undefined;

  return value.length > maxLength ? `Max text length is ${maxLength} characters` : undefined;
}
