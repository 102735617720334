import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

import SvgIcon from '../../../common/components/SvgIcon';
import { PROJECT, PUBLISHING, SETTINGS } from '../../../common/constants/RouteConstants';

// function Overview({ match }) {
class Overview extends React.Component {
  // console.log(match);
  componentDidMount() {
    this.props.Store.getOverview(this.props.match.params.id);
    this.props.Store.getStatus(this.props.match.params.id);
  }

  render() {
    const { Store } = this.props;
    const overview = Store.projectOverview;
    const status = Store.publishStatus;
    // console.log('OVERVIEW', toJS(overview));
    // console.log('STATUS', Store.hasUpdates, toJS(status));

    return (
      <div className='l-main'>
        <div className='c-action-bar'>
          <h1 className='c-action-bar__title'>Dashboard</h1>
          <div className='c-action-bar__action' />
        </div>
        <h2 className='c-heading-section'>Stats</h2>
        {/* <p>Your project has been active for {Store.daysSinceFirstPublish} days with 16 updates since it went live. Well done!</p> */}
        <p>Your project has been active for {Store.daysSinceFirstPublish} days since it went live. Well done!</p>
        <ul className='c-list-icons l-list l-list--halfs'>
          <li className='c-list-icons__item'>
            <SvgIcon icon='poll' />
            {`${overview.polls.nrPublic} ${overview.polls.nrPublic > 1 ? 'surveys' : 'survey'} created`}
          </li>
          <li className='c-list-icons__item'>
            <SvgIcon icon='pen-alt' />
            {overview.questions.nrPublic} questions added
          </li>
          <li className='c-list-icons__item'>
            <SvgIcon icon='pen-alt' />
            {overview.facts.nrPublic} facts added
          </li>
          <li className='c-list-icons__item'>
            <SvgIcon icon='users' />
            {overview.languages.nrPublic} languages activated
          </li>
          <li className='c-list-icons__item'>
            <SvgIcon icon='megaphone' />
            {overview.news.nrPublic} news updates sent
          </li>
          <li className='c-list-icons__item'>
            <SvgIcon icon='calendar-alt' />
            {overview.timeline.nrPhasesPublic} phases added to timeline
          </li>
          <li className='c-list-icons__item'>
            <SvgIcon icon='users' />
            {overview.influenced_decisions.nrPublic} influenced decisions
          </li>
          <li className='c-list-icons__item'>
            <SvgIcon icon='coins' />
            {overview.charities.nrPublic} charities added
          </li>
        </ul>
        {Store.hasUpdates && (
          <React.Fragment>
            <h2 className='c-heading-section'>
              Update available
              <SvgIcon icon='sync' />
            </h2>
            <p>There are content updates available for this project.</p>
            <Link
              className='o-button o-button--alternate'
              to={`/${PROJECT}/${this.props.match.params.id}/${SETTINGS}/${PUBLISHING}`}
            >
              <span className='o-label'>Evaluate and update</span>
            </Link>
          </React.Fragment>
        )}
        {!Store.hasUpdates && <h2 className='c-heading-section'>No unpublished changes</h2>}
      </div>
    );
  }
}

// export default Overview;
export default inject((root) => ({ Store: root.RootStore.overviewStore }))(observer(Overview));
