module.exports = function(){

  const SliderFeedbackLabels = {
    LABEL_76_100:{label: 'That\'s great to hear! Please tell us more about what you like so much!', qualifier: '76-100'},
    LABEL_51_75:{label: 'That\'s good to hear! Please tell us more about what you like.', qualifier: '51-75'},
    LABEL_26_50:{label: 'Thanks for letting us know. Please do give us some more feedback.', qualifier: '26-50'},
    LABEL_0_25:{label: 'That\'s a shame! Thanks for sharing your views. Give us some feedback.', qualifier: '0-25'}
  };

  // TODO: change this value when deleted reaction name gets finally defined
  const DELETED_ANNOTATE_REACTION = 'object_20';

  // Other choice image
// Fix Sax the image showing ok on normal pages/browsers,
// but braking with strange 400 Error from S3 when generating PDF (puppeteer)
// 400 https://s3.amazonaws.com/cdn.built-id.com/static/images/choice-other-wide.png
// ERR Failed to load resource: the server responded with a status of 400 (Bad Request)
  const MultiImageOtherChoiceImage = 'https://d3h3sugvwhjhl.cloudfront.net/static/images/choice-other-wide.png';
  // const MultiImageOtherChoiceImage = 'https://s3.amazonaws.com/cdn.built-id.com/static/images/choice-other-wide.png';

  return {
    SliderFeedbackLabels: SliderFeedbackLabels,
    MultiImageOtherChoiceImage: MultiImageOtherChoiceImage,
    DELETED_ANNOTATE_REACTION
  };
};