import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { POLLS, PROJECT } from 'common/constants/RouteConstants';

function SurveysVisibilityInfo(props) {
  const { projectId, hasPolls } = props;

  return (
    <div className='c-message is-note-subtle c-message--large' data-testid='survey-visilibity-info-wrapper'>
      <p className='c-message__description'>
        {hasPolls ? (
          <Fragment>
            Keep in mind that <strong>only public surveys</strong> can be accessed by your community.
          </Fragment>
        ) : (
          <Fragment>
            {`Project currently doesn't have any surveys. `}
            <br />
            <Link to={`/${PROJECT}/${projectId}/edit/${POLLS}/create-survey`}>Create your first survey</Link>
          </Fragment>
        )}
      </p>
    </div>
  );
}

SurveysVisibilityInfo.propTypes = {
  hasPolls: PropTypes.bool,
  projectId: PropTypes.string.isRequired,
};

export default SurveysVisibilityInfo;
