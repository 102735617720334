import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../tooltip/Tooltip';
import FieldDescription from '../fieldDescription/FieldDescription';

export default function SimpleTextareaInputField(props) {
  const { id, placeholder, label, maxLength, fieldDescription, tooltipInLabel, input, meta = {}, fieldClass } = props;

  return (
    <div className={`c-field l-form__item ${fieldClass}`} data-testid={`${id}-wrapper`}>
      <label className='c-field__label' htmlFor={id} data-testid={`${id}-label`}>
        {label}
        {tooltipInLabel && <Tooltip {...tooltipInLabel} />}
      </label>
      <div className='c-field__group'>
        <div className='o-textarea'>
          <textarea {...input} id={id} placeholder={placeholder} maxLength={maxLength} data-testid={`${id}-input`} />
        </div>
        <FieldDescription meta={meta} fieldDescription={fieldDescription} />
        {/* Discussed with Sax phasing out description and replacig with fieldDescription {fieldDescription && <p className='c-field__description'>{fieldDescription}</p>} */}
        <span className='c-field__count' title='Characters left' data-testid={`${id}-max-length`}>
          {maxLength ? maxLength - input.value.length : ''}
        </span>
      </div>
    </div>
  );
}

SimpleTextareaInputField.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fieldDescription: PropTypes.string,
  tooltipInLabel: PropTypes.object,
  input: PropTypes.object,
  meta: PropTypes.object,
  fieldClass: PropTypes.string,
};
