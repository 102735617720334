import React, { useState, useEffect, useCallback } from 'react';
import { inject, observer } from 'mobx-react';

import { DISABLE_ANNOTATE_IMAGE_QUESTION } from 'shared/engage/client-constants';
import SimpleSelectInputField from '../../../../common/components/form-elements/selectInputField/SimpleSelectInputField';
import { CheckboxInput } from '../../../../common/components/form-elements/checkboxGroupInputField/CheckboxGroupInputField';
import { QUESTION_TYPES } from '../../../../common/constants/QuestionConstants';

function PollLibraryOptionsFn(props) {
  const {
    PollLibraryStore,
    profileValue,
    setProfileValue,
    projectsValue,
    setProjectsValue,
    pollsValue,
    setPollsValue,
    checkboxValues,
    setCheckboxValues,
    hideCheckboxes,
    setHideCheckboxes,
  } = props;

  const [questionTypes, setQuestionTypes] = useState();

  const temporaryRemoveAnnotateImageQuestion = useCallback((pollQuestionTypes) => {
    const filteredTypes = pollQuestionTypes.filter((item) => item[0] !== QUESTION_TYPES.ANNOTATION_IMAGE);
    return filteredTypes;
  }, []);

  useEffect(() => {
    PollLibraryStore.getProfiles('');

    let pollQuestionTypes = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const item of Object.entries(QUESTION_TYPES.properties)) {
      // Ignore segmentation and demographic questions
      if (!item[1].isQQ && !item[1].isDemographic) {
        if (item[1].items) {
          pollQuestionTypes = [...pollQuestionTypes, ...Object.entries(item[1].items)];
        } else {
          pollQuestionTypes.push(item);
        }
      }
    }

    // Temoporary remove annotate image question [Issue#2246 - https://github.com/sdesregistry/IH-Engage/issues/2246]
    if (DISABLE_ANNOTATE_IMAGE_QUESTION) {
      const filteredQuestionTypes = temporaryRemoveAnnotateImageQuestion(pollQuestionTypes);
      setQuestionTypes(filteredQuestionTypes);
    } else {
      setQuestionTypes(pollQuestionTypes);
    }
  }, [PollLibraryStore, temporaryRemoveAnnotateImageQuestion]);

  useEffect(() => {
    if (profileValue) {
      PollLibraryStore.getProjects({ profileIds: [profileValue] });
    }
    setProjectsValue('');
    setPollsValue('');
  }, [PollLibraryStore, profileValue, setProjectsValue, setPollsValue]);

  useEffect(() => {
    if (projectsValue) PollLibraryStore.getPolls({ projectIds: [projectsValue] });

    setPollsValue('');
  }, [PollLibraryStore, projectsValue, setPollsValue]);

  const createClientList = () => {
    const clients = PollLibraryStore.profiles.map((item) => [item._id, item.name]);
    clients.unshift(['', 'All clients']);
    return clients;
  };

  const createProjectList = () => {
    const projects = PollLibraryStore.projects.map((item) => [item._id, item.projectName]);
    projects.unshift(['', 'All projects']);
    return projects;
  };

  const createPollList = () => {
    const polls =
      PollLibraryStore.polls.length > 0
        ? PollLibraryStore.polls[0].polls.map((pollItem) => [pollItem._id, pollItem.name])
        : [];
    polls.unshift(['', 'All surveys']);
    return polls;
  };

  const filterActivationHandler = () => {
    setHideCheckboxes(!hideCheckboxes);

    if (!hideCheckboxes && Object.keys(checkboxValues).length) setCheckboxValues({});
  };

  return (
    <div className='c-library__options'>
      <form className='c-fields l-fields'>
        {PollLibraryStore.profiles.length > 1 && (
          <SimpleSelectInputField
            id='library-clients'
            label='Clients'
            classModifier=''
            choices={createClientList()}
            firstOptionEmpty={false}
            input={{ value: profileValue, onChange: (event) => setProfileValue(event.target.value) }}
          />
        )}
        <SimpleSelectInputField
          id='library-projects'
          label='Projects'
          classModifier=''
          choices={createProjectList()}
          firstOptionEmpty={false}
          disabled={profileValue === ''}
          input={{ value: projectsValue, onChange: (event) => setProjectsValue(event.target.value) }}
        />
        <SimpleSelectInputField
          id='library-polls'
          label='Surveys'
          classModifier=''
          choices={createPollList()}
          firstOptionEmpty={false}
          disabled={projectsValue === ''}
          input={{ value: pollsValue, onChange: (event) => setPollsValue(event.target.value) }}
        />
        <div className='c-field l-form__item'>
          <label className='c-field__label' htmlFor='checkbox-library-all-questions'>
            Question types
          </label>
          <div className='c-field__group'>
            <CheckboxInput
              classModifier='o-checkbox--toggle'
              input={{ checked: hideCheckboxes, onChange: () => filterActivationHandler() }}
              id='checkbox-library-all-questions'
              checkboxLabel='All questions'
            />
            {!hideCheckboxes &&
              questionTypes.map((item) => (
                <CheckboxInput
                  key={item[0]}
                  classModifier='o-checkbox--s'
                  id={`checkbox-${item[0]}`}
                  checkboxLabel={item[1].label}
                  checkboxIcon={item[1].icon}
                  name={item[0]}
                  input={{
                    value: item[0],
                    onChange: (event) =>
                      setCheckboxValues({ ...checkboxValues, [event.target.name]: event.target.checked }),
                  }}
                />
              ))}
          </div>
        </div>
      </form>
    </div>
  );
}

export default inject((root) => ({ PollLibraryStore: root.RootStore.pollLibraryStore }))(
  observer(PollLibraryOptionsFn),
);
