import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import SvgIcon from '../components/SvgIcon';
import { PROFILE, USERS, PROJECTS, STAFF, CLIENTS } from '../constants/RouteConstants';

function ProfileMasthead(props) {
  const { match, AuthStore } = props;
  // console.log(match, match.params.route, AuthStore.client.profileName);

  function getSelectedClass(item) {
    return item === match.params.route ? 'c-nav-masthead__item is-active' : 'c-nav-masthead__item';
  }

  return (
    <div className='c-nav-masthead'>
      <div className='c-nav-masthead__group'>
        {!AuthStore.isSingleClient && (
          <Link to={`/${STAFF}/${CLIENTS}`} className='c-nav-masthead__back'>
            <SvgIcon icon='angle-left' />
            <span className='o-label'>All clients</span>
          </Link>
        )}
        <h2 className='c-nav-masthead__title'>
          <span className='o-label'>{AuthStore.client.profileName}</span>
        </h2>
      </div>

      <div className='c-nav-masthead__content'>
        <ul className='c-nav-masthead__items'>
          <li className={getSelectedClass(PROJECTS)}>
            <Link className='c-nav-masthead__link' to={`/${PROFILE}/${PROJECTS}`}>
              Projects
            </Link>
          </li>
          <li className={getSelectedClass(USERS)}>
            <Link className='c-nav-masthead__link' to={`/${PROFILE}/${USERS}`}>
              Users
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default inject((root) => ({
  AuthStore: root.RootStore.authStore,
}))(observer(ProfileMasthead));
