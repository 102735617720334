import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { HELP } from 'shared/engage/engage-help-enums';
import EngageTextInputField from '../../../../common/components/form-elements/textInputField/TextInputField';
import SwitchableButtonBar from '../../../../common/components/switchableButtonBar/SwitchableButtonBar';
import EngageSelectInputField from '../../../../common/components/form-elements/selectInputField/SelectInputField';
import SlimFileUpload from '../../../../common/components/form-elements/fileUpload/SlimFileUpload';
import { IMAGE_FORMATS } from '../../../../common/components/form-elements/fileUpload/ImageFormatEnums';
import { BATCH_AFFILIATION_ENUM } from './pollBatchAffiliationEnum';
import { CheckboxInputField } from '../../../../common/components/form-elements';
import displayConfirmationPrompt from '../../../../common/PromptUtil';
import { getFormFieldClass, getFormFieldTranslationTooltip } from '../../../../common/TranslationUtils';
import GeneratePreviewForm from './GeneratePreviewForm';

function PollInformationForm(props) {
  const { EditStore, CreateStore, projectId, pollId, ConfigStore, ToastrStore, history, openPollPreview } = props;
  const poll = EditStore.poll;
  const imageId = poll?.resource;
  const defaultData = poll?.defaultEnglish ? poll.defaultEnglish : {};
  const permalinkUrl = `${ConfigStore.config.clientPath}/${EditStore.projectSlug}/surveys/${pollId}`;

  const [permalinkActionTooltip, setPermalinkActionTooltip] = useState({ actionLabel: 'Copy link', icon: 'clone' });

  const initialValues =
    pollId && poll
      ? {
          name: poll.name,
          description: poll.description,
          batchAffiliation: poll.batchAffiliation,
          awardPoints: poll.awardPoints,
          status: poll.status,
          dateStart: poll.dateStart,
          dateEnd: poll.dateEnd,
          skipCommunityResponse: poll.skipCommunityResponse,
        }
      : {
          name: '',
          description: '',
          batchAffiliation: 0,
          awardPoints: 5,
          status: '',
          dateStart: '',
          dateEnd: '',
          skipCommunityResponse: false,
        };

  const onSubmit = (values) => {
    // console.log(values);
    const data = {
      name: values.name.trim(),
      description: values.description,
      batchAffiliation: values.batchAffiliation,
      awardPoints: values.awardPoints,
      status: values.status,
      dateStart: values.dateStart,
      dateEnd: values.dateEnd,
      skipCommunityResponse: values.skipCommunityResponse,
    };

    if (pollId) {
      EditStore.update(data);
    } else {
      CreateStore.create(projectId, data).then((newPollId) => history.push(`${newPollId}/edit-survey`));
    }
  };

  const cancel = (event) => {
    event.preventDefault();
    if (pollId) {
      const res = displayConfirmationPrompt(null, null, false, ToastrStore, poll?.questions?.length > 0);

      if (res === 1)
        EditStore.remove(pollId).then((result) => {
          // Makse sure that user is still on poll edit page before redirecting
          if (result && history.location.pathname.includes('/edit-survey')) history.push(`../../surveys`);
        });
    } else {
      history.push(`../surveys`);
    }
  };

  const uploadImage = (file, progressHandler) => {
    return EditStore.uploadPollResource(pollId, file, progressHandler);
  };

  const removeImage = (event) => {
    event.preventDefault();
    return EditStore.removePollResource(pollId);
  };

  function onCopyPermalink() {
    setPermalinkActionTooltip({ ...permalinkActionTooltip, actionLabel: 'Copied' });

    navigator.clipboard.writeText(permalinkUrl);

    setTimeout(() => {
      setPermalinkActionTooltip({ ...permalinkActionTooltip, actionLabel: 'Copy link' });
    }, 2500);
  }

  const tooltip = {
    modifier: 'o-tooltip--bottom-center',
    label: 'Survey is ',
    labelStrong: poll?.status === 'active' ? 'public' : 'hidden',
    icon: 'info-circle',
    description: 'Survey visibility can be changed under the Publishing section.',
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ values, form, handleSubmit, submitting, pristine }) => {
        return (
          <form className='c-fields l-form' onSubmit={(event) => event.preventDefault()}>
            <EngageTextInputField
              id='input-name'
              placeholder='Name'
              label='Survey name'
              maxLength='25'
              fieldDescription=''
              fieldDescriptionLinkUrl={permalinkUrl}
              actionTooltip={{ ...permalinkActionTooltip, onAction: onCopyPermalink }}
              fieldName='name'
              isRequired={true}
              tooltip={tooltip}
              fieldClass={getFormFieldClass(defaultData.name, null, 'l-form__item--m')}
              tooltipInLabel={getFormFieldTranslationTooltip(defaultData.name)}
              helpKey={HELP.PROJECT.EDIT_PROJECT.SURVEY.NAME}
            />

            <EngageSelectInputField
              choices={BATCH_AFFILIATION_ENUM}
              fieldName='batchAffiliation'
              id='input-poll-phase'
              label='Survey phase'
              fieldDescription=''
              fieldClass='l-form__item--m'
              firstOptionEmpty={false}
              helpKey={HELP.PROJECT.EDIT_PROJECT.SURVEY.PHASE}
            />

            {pollId && (
              <React.Fragment>
                <SlimFileUpload
                  imageFormat={IMAGE_FORMATS.PANEL}
                  imageId={imageId}
                  defaultImageId={defaultData.resource}
                  id={`${pollId}`}
                  handleUpload={uploadImage}
                  label='Survey image'
                  removeImage={removeImage}
                  tip='Survey image is not mandatory, but adding a survey image can help the survey stand out.'
                  classModifier={getFormFieldClass(imageId, defaultData.resource)}
                  tooltipInLabel={getFormFieldTranslationTooltip(null, defaultData.resource, IMAGE_FORMATS.PANEL)}
                />
                <GeneratePreviewForm projectId={projectId} pollId={pollId} previewLink={EditStore.previewPath()} />

                <CheckboxInputField
                  id={`checkbox-segmentation-status-${poll?._id}`}
                  type='checkbox'
                  fieldName='skipCommunityResponse'
                  fieldClass='l-form__item--m'
                  label='Survey responses'
                  isToggle={true}
                  checkedLabel='Hide community response'
                  uncheckedLabel='Hide community response'
                  hideLabel={false}
                />
              </React.Fragment>
            )}

            <SwitchableButtonBar
              updateAction={handleSubmit}
              removeAction={cancel}
              isNew={!pollId}
              previewAction={() => openPollPreview('')}
            />
          </form>
        );
      }}
    />
  );
}
export default inject((root) => ({
  EditStore: root.RootStore.pollEditStore,
  CreateStore: root.RootStore.pollsListStore,
  ConfigStore: root.RootStore.configStore,
  ToastrStore: root.RootStore.toastrStore,
}))(observer(PollInformationForm));

PollInformationForm.propTypes = {
  EditStore: PropTypes.object.isRequired,
  CreateStore: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
  pollId: PropTypes.string.isRequired,
  ConfigStore: PropTypes.object.isRequired,
  ToastrStore: PropTypes.object.isRequired,
  history: PropTypes.object,
  openPollPreview: PropTypes.func.isRequired,
};
