import Agent from './agent';

const requests = Agent.requests;
export const ENDPOINT = 'developer-annoucement';

const NewsAgent = {
  // News list /team
  create: (projectId, data) => requests.post(`/projects/${projectId}/${ENDPOINT}`, data),
  // reorder: (projectId, data) => requests.post(`/projects/${projectId}/${ENDPOINT}/reorder`, data),

  // News
  update: (projectId, itemId, data) => requests.patch(`/projects/${projectId}/${ENDPOINT}/${itemId}`, data),
  reorder: (projectId, data) => requests.post(`/projects/${projectId}/${ENDPOINT}/reorder`, data),
  remove: (projectId, itemId) => requests.remove(`/projects/${projectId}/${ENDPOINT}/${itemId}`),
  uploadResource: (projectId, itemId, file, progress) =>
    requests.uploadImage(`/projects/${projectId}/${ENDPOINT}/${itemId}/resource`, 'project', file, progress),
  removeResource: (projectId, itemId) => requests.remove(`/projects/${projectId}/${ENDPOINT}/${itemId}/resource`),
};

export default NewsAgent;
