import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';
import SelectedFilter from './SelectedFilter';
import FilterSelector from './FilterSelector';
import SimpleCheckboxInputField from '../form-elements/checkboxInputField/SimpleCheckboxInputField';

export const FILTER_GROUPS = {
  SEGMENTATION: { id: 'segmentation', label: 'Segmentation', icon: 'poll' },
  DEMOGRAPHIC: { id: 'demographic', label: 'Demographic', icon: 'user' },
  POLL: { id: 'poll', label: 'Survey', icon: 'poll' },
};

function PollReportFilter(props) {
  const {
    demographicFieldName,
    segmentationFieldName,
    pollFieldName,
    formValues,
    demographicSelectedFilter,
    segmentationSelectedFilter,
    pollSelectedFilter,
    filterOptions,
    onFilterSelected,
    onClearFilter,
  } = props;

  const [showFilters, setShowFilters] = useState(false);
  const [showVoteNumbers, setShowVoteNumbers] = useState(false);

  const showSelectedDemographicFilter = formValues ? formValues[demographicFieldName] : false;
  const showSelectedSegmentationFilter = formValues ? formValues[segmentationFieldName] : false;
  const showSelectedPollFilter = formValues ? formValues[pollFieldName] : false;

  function addMetricHandler() {
    setShowFilters(!showFilters);
  }

  function filterSelectedHandler(selectedChoiceId) {
    setShowFilters(false);
    onFilterSelected(selectedChoiceId);
  }

  return (
    <Fragment>
      <SimpleCheckboxInputField
        id='compare-across-demographic-averages'
        type='checkbox'
        input={{ checked: showVoteNumbers, onChange: (event) => setShowVoteNumbers(event.target.checked) }}
        label='Filter survey data format'
        isToggle={true}
        checkedLabel='Show voter numbers instead of percentage'
        uncheckedLabel='Show voter numbers instead of percentage'
        hideLabel={false}
      />
      <div className='c-field l-form__item'>
        <label className='c-field__label' htmlFor='input-filter'>
          Filter survey
        </label>
        <div className='c-field__group'>
          <div className='c-filter-set'>
            {showSelectedDemographicFilter && (
              <SelectedFilter
                id=''
                fieldName={demographicFieldName}
                onClear={() => onClearFilter(FILTER_GROUPS.DEMOGRAPHIC.id)}
                filterData={demographicSelectedFilter}
                formValues={formValues}
                displayVoteNumbers={showVoteNumbers}
              />
            )}
            {showSelectedSegmentationFilter && (
              <SelectedFilter
                fieldName={segmentationFieldName}
                onClear={() => onClearFilter(FILTER_GROUPS.SEGMENTATION.id)}
                filterData={segmentationSelectedFilter}
                formValues={formValues}
                displayVoteNumbers={showVoteNumbers}
              />
            )}
            {showSelectedPollFilter && (
              <SelectedFilter
                fieldName={pollFieldName}
                onClear={() => onClearFilter(FILTER_GROUPS.POLL.id)}
                filterData={pollSelectedFilter}
                formValues={formValues}
                displayVoteNumbers={showVoteNumbers}
              />
            )}
            {(!showSelectedDemographicFilter || !showSelectedSegmentationFilter || !showSelectedPollFilter) && (
              <div className='c-filter-set__add'>
                <button className='o-button' type='button' onClick={addMetricHandler}>
                  <span className='o-label'>Add filter</span>
                  <SvgIcon icon='plus-circle' />
                </button>
                {showFilters && <FilterSelector options={filterOptions} onFilterSelected={filterSelectedHandler} />}
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

PollReportFilter.propTypes = {
  demographicFieldName: PropTypes.string.isRequired,
  segmentationFieldName: PropTypes.string.isRequired,
  pollFieldName: PropTypes.string.isRequired,
  formValues: PropTypes.object,
  demographicSelectedFilter: PropTypes.object,
  segmentationSelectedFilter: PropTypes.object,
  pollSelectedFilter: PropTypes.object,
  filterOptions: PropTypes.array.isRequired,
  onFilterSelected: PropTypes.func.isRequired,
  onClearFilter: PropTypes.func.isRequired,
};

export default PollReportFilter;
