import React, { useEffect } from 'react';
import { openModal } from 'containers/project/polls/PollUtils';
import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';
import ModalCloseButton from '../buttons/ModalCloseButton';
import Mermaid from '../mermaid/Mermaid';

function ConditionalLogicModal(props) {
  const { closeModal, graphData } = props;

  useEffect(() => {
    return openModal(closeModal);
  }, [closeModal]);

  return (
    <div
      className='c-modal c-modal--centered-wide c-modal--fill-screen '
      style={{ display: 'flex' }}
      data-testid='conditional-logic-modal-container'
    >
      <div className='c-modal__container'>
        <div className='c-modal__header'>
          <h2 className='c-modal__title'>Survey Visualisation</h2>
          <ModalCloseButton closeModal={closeModal} />
        </div>
        <div className='c-modal__content'>
          <div className='svg__container'>
            <Mermaid name='vertical flowchart' chart={graphData} />
          </div>
        </div>
      </div>
    </div>
  );
}

PropTypes.propTypes = {
  closeModal: PropTypes.func.isRequired,
  graphData: PropTypes.string.isRequired,
};

export default ConditionalLogicModal;
