import React from 'react';
import ActionBar from 'common/components/actionBar/ActionBar';
import { DUPLICATE_SURVEY, VIEW } from 'common/ability';
import { Can } from 'common/Can';
import DuplicateSurvey from './duplicateSurvey/DuplicateSurvey';
import Anonymisation from './anonymisation/Anonymisation';

function Tools() {
  return (
    <div className='l-main' data-testid='tools-wrapper'>
      <ActionBar addNew={null} label='Tools' hasButton={false} />

      <div className='c-fields l-form'>
        <Can I={VIEW} a={DUPLICATE_SURVEY}>
          <DuplicateSurvey />
        </Can>

        <Anonymisation />
      </div>
    </div>
  );
}

export default Tools;
