import { action, observable, computed, makeObservable, runInAction, toJS } from 'mobx';
// import { toJS } from 'mobx';
import cloneDeep from 'lodash.clonedeep';
import AgentPoll from '../../common/agent';

const Agent = AgentPoll.ReportsAgent;

const LOAD_GA_DATA_ERROR = `Error loading Google analytics report data`;
const LOAD_GMV_DATA_ERROR = `Error loading Give my view report data`;

export default class OverviewReportStore {
  _loading = false;

  _initialized = false;

  _error = null;

  _report = {};

  constructor(rootStore) {
    makeObservable(this, {
      _loading: observable,
      _initialized: observable,
      _error: observable,

      _report: observable,

      isLoading: computed,
      isInitialized: computed,
      error: computed,

      overviewReport: computed,

      loadOverviewReport: action,
    });

    this.rootStore = rootStore;
    this._report = this.defaultReport();
  }

  defaultReport = () => ({
    charities: [],
    charitiesLegacy: [],
    languageStats: [],
    meta: {},
    users: { devicesGA: {}, timeBreakdownGA: {} },
    polls: { polls: [] },
    segmentation: { segmentation: [] },
  });

  get isLoading() {
    return this._loading;
  }

  get isInitialized() {
    return this._initialized;
  }

  get error() {
    return this._error;
  }

  get overviewReport() {
    return this._report;
  }

  fetchReport = (isGA4) => {
    const report = cloneDeep(toJS(this._report));
    const gaData = isGA4 ? report.ga4 : report.ga;
    report.users = { ...report.users, ...(gaData || {}) };
    return report;
  };

  loadOverviewReport = (projectId, rangeFrom, rangeTo) => {
    action(() => {
      this._report = this.defaultReport();
    });

    const query = {
      timestampFrom: rangeFrom,
      timestampTo: rangeTo,
    };

    const overviewReportData = cloneDeep(this.defaultReport());

    Agent.loadOverviewDBReport(projectId, query)
      .then(
        action((res) => {
          const gmvReportData = res.data.report;
          overviewReportData.charities = gmvReportData.charities;
          overviewReportData.charitiesLegacy = gmvReportData.charitiesLegacy;
          overviewReportData.languageStats = gmvReportData.languageStats;
          overviewReportData.meta = gmvReportData.meta;
          overviewReportData.polls = gmvReportData.polls;
          overviewReportData.segmentation = gmvReportData.segmentation;
          overviewReportData.users = { ...overviewReportData.users, ...gmvReportData.users };

          this._report = overviewReportData;
        }),
      )
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(LOAD_GMV_DATA_ERROR, null, err);
      });

    Agent.loadOverviewGAReport(projectId, query)
      .then(
        action((res) => {
          const gaReportData = res.data.report;
          // store both UA and GA4 data so I can switch without reloading
          overviewReportData.ga = gaReportData.GA_data;
          overviewReportData.ga4 = gaReportData.GA4_data;
          // initially showing UA data
          overviewReportData.users = { ...overviewReportData.users, ...gaReportData.GA_data };

          this._report = overviewReportData;
        }),
      )
      .catch((err) => {
        this._error = err;
        this.rootStore.toastrStore.error(LOAD_GA_DATA_ERROR, null, err);
      });
  };
}
