import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import SvgIcon from 'common/components/SvgIcon';
import { Link } from 'react-router-dom';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'common/Can';
import { updateAbility } from 'common/ability';
import { PROJECT, PUBLISHING, SETTINGS } from 'common/constants/RouteConstants';

function SurveyOnlyConfiguration(props) {
  const { Store, ProjectStore, AuthStore } = props;

  const ability = useAbility(AbilityContext);

  const project = ProjectStore.project;
  const isProjectActive = Store?.publishStatus?.isProjectActive;
  const isPollsOnlyProject = Store?.publishStatus?.isPollsOnlyProject;

  const onChange = async (event) => {
    let updatedProject;
    if (event.target.checked) {
      await Store.setProjectType(false).then((data) => {
        updatedProject = data;
      });
    } else {
      await Store.setProjectType(true).then((data) => {
        updatedProject = data;
      });
    }

    // We need to update the ability to reflect the changes in the project
    if (updatedProject)
      updateAbility(
        ability,
        AuthStore.me.roles[0]._id,
        updatedProject.isPollsOnlyProject,
        updatedProject.isPersonalDataAcquisitionEnabled,
      );
  };

  return (
    <form className='c-fields c-fields--modern l-form' action='#' data-testid='survey-only-configuration-wrapper'>
      <h2 className='c-heading-section c-heading-section--large'>Survey-only project settings</h2>
      <div
        className={`c-field c-field--modern c-field--large c-field--border l-form__item ${
          isProjectActive ? 'is-disabled' : ''
        }`}
        data-testid='survey-only-main-component'
      >
        <div className='c-field__group'>
          <div className='o-checkbox o-checkbox--toggle'>
            <input type='checkbox' id='checkbox-project-type' onChange={onChange} checked={isPollsOnlyProject} />
            <label htmlFor='checkbox-project-type'>
              This is a <strong>survey-only</strong> project
            </label>
          </div>
          <div className='c-message c-message--large is-border'>
            <p className='c-message__description'>
              Survey-only projects don’t have all the additional website content options of regular projects and are
              focused, as the name suggests, on the surveys.
              <br />
              <Link to={`/${PROJECT}/${project._id}/${SETTINGS}/${PUBLISHING}`} onClick={() => window.scrollTo(0, 0)}>
                Change project status
              </Link>
            </p>
          </div>
          {isProjectActive && (
            <div className='c-message c-message--icon is-danger'>
              <SvgIcon icon='exclamation-circle' />
              <h3 className='c-message__title'>Notice</h3>
              <p className='c-message__description'>
                <strong>{`Toggling between 'survey-only' and regular projects is disabled for active projects.`}</strong>
                {` To change this setting, you must first unpublish the project. After unpublishing, you'll be able to modify it.`}
              </p>
            </div>
          )}
          <div className='c-message c-message--icon is-warning'>
            <SvgIcon icon='edit' />
            <h3 className='c-message__title'>Keep in mind</h3>
            <p className='c-message__description'>
              {`If you created this project as a 'survey-only' project, after changing it to a regular project, you'll
              `}
              <strong> need to add all the necessary information</strong> before publishing it again.
            </p>
          </div>
        </div>
      </div>
    </form>
  );
}

SurveyOnlyConfiguration.propTypes = {
  Store: PropTypes.object.isRequired,
  ProjectStore: PropTypes.object.isRequired,
  AuthStore: PropTypes.object.isRequired,
};

export default inject((root) => ({
  Store: root.RootStore.projectControlStore,
  ProjectStore: root.RootStore.projectStore,
  AuthStore: root.RootStore.authStore,
}))(observer(SurveyOnlyConfiguration));
