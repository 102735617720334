import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';

import SvgIcon from '../SvgIcon';

export default SortableHandle(() => (
  <div className='c-reorder__handle'>
    <SvgIcon icon='grab' />
    <span className='o-label'>Reorder</span>
  </div>
));
