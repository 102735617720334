import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import SimpleSelectInputField from './SimpleSelectInputField';

export default function EngageSelectInputField(props) {
  const { fieldName, isRequired, type } = props;

  // Handle field type ie. number but default to text
  const fieldType = type || 'select';

  function validateRequired(value) {
    return !value && isRequired ? 'Required' : undefined;
  }

  return (
    <Field
      component={SimpleSelectInputField}
      name={fieldName}
      type={fieldType}
      validate={validateRequired}
      {...props}
    />
  );
}

EngageSelectInputField.propTypes = {
  fieldName: PropTypes.string,
  isRequired: PropTypes.bool,
  type: PropTypes.string,
};
