import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { EMBED_INLINE, EMBED_POPUP } from './EmbedEnums';
import EmbedModal from './EmbedModal';
import SvgIcon from '../../../../common/components/SvgIcon';

function EmbedPreview(props) {
  const { embedType, buttonLabel, backgroundColor, textColor, borderRadius, iframeSource } = props;

  const [showModal, setShowModal] = useState(false);

  // Clicking the button toggles the modal
  function toggleModal() {
    setShowModal(!showModal);

    // Show or hide header (so there are no z-index problems)
    const header = document.getElementById('header');
    if (header.style.display === 'none') {
      header.style.display = 'block';
    } else {
      header.style.display = 'none';
    }

    // Make the page container not scrollable
    const body = document.getElementsByTagName('body');
    if (body.style) {
      if (body.style.overflow === 'auto') {
        body.style.overflow = 'hidden';
      } else {
        body.style.overflow = 'auto';
      }
    }
  }

  return (
    <div className='c-field l-form__item'>
      <label className='c-field__label'>Preview</label>
      <div className='c-field__group'>
        <div className='c-embed-example'>
          <div className='c-embed-example__decoration'>
            <span className='c-embed-example__line' />
            <span className='c-embed-example__line' />
            <span className='c-embed-example__line' />
          </div>
          {embedType === EMBED_POPUP ? (
            <div className='c-embed-example__content'>
              <div className='gmv-container'>
                <button
                  className='gmv-button'
                  onClick={toggleModal}
                  type='button'
                  style={{ color: textColor, backgroundColor, borderRadius: `${borderRadius}px` }}
                >
                  {buttonLabel}
                </button>
                {showModal && <EmbedModal close={toggleModal} iframeSource={iframeSource} />}
              </div>
            </div>
          ) : null}
          {embedType === EMBED_INLINE ? (
            <div className='c-embed-example__content'>
              <script type='text/javascript' src='https://www.givemyview.com/js/iframeResizer.min.js' />

              <div className='givemyview-wrapper' id='givemyview'>
                <iframe
                  title='My Daily Marathon Tracker'
                  src={iframeSource}
                  width='100%'
                  height='800px'
                  style={{ border: 'none', overflow: 'hidden', height: '760pxs' }}
                  id='iFrameResizer1'
                  scrolling='no'
                />
              </div>
            </div>
          ) : null}
          <div className='c-embed-example__decoration'>
            <span className='c-embed-example__line' />
            <span className='c-embed-example__line' />
            <span className='c-embed-example__line' />
          </div>
        </div>
        <div className='c-message c-message--icon is-warning'>
          <SvgIcon icon='triangle' />
          <p className='c-message__description'>
            <strong>This preview is for informative purposes only.</strong>{' '}
            {`${
              embedType === EMBED_POPUP ? 'The button could vary in style depending on where it is implemented.' : ''
            }`}
          </p>
        </div>
      </div>
    </div>
  );
}

EmbedPreview.propTypes = {
  embedType: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  iframeSource: PropTypes.string.isRequired,
};

export default EmbedPreview;
