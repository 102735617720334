import React from 'react';
import PropTypes from 'prop-types';
import EngageTextInputField from '../../../common/components/form-elements/textInputField/TextInputField';
import EngageTextareaInputField from '../../../common/components/form-elements/textareaInputField/TextareaInputField';
import { getFormFieldClass, getFormFieldTranslationTooltip } from '../../../common/TranslationUtils';
import EngageCheckboxInputField from '../../../common/components/form-elements/checkboxInputField/CheckboxInputField';

function FieldEdit(props) {
  const { field, defaultLabel, defaultOptions, label, showLabelField = false, showOptionsField = false } = props;

  return (
    <div className='c-field l-form__item c-field--boxed c-field--inline-fields' data-testid={`${field}-personal-data`}>
      <label className='c-field__label'>{label}</label>
      <div className='c-field__group'>
        {showLabelField && (
          <EngageTextInputField
            id={field}
            placeholder='Define field label'
            label='Label'
            maxLength={30}
            fieldName={`${field}.label`}
            isRequired={false}
            fieldClass={getFormFieldClass(defaultLabel, null, 'l-form__item--l')}
            tooltipInLabel={getFormFieldTranslationTooltip(defaultLabel)}
          />
        )}
        {showOptionsField && (
          <EngageTextareaInputField
            id={`${field}-lookupValues`}
            placeholder='Define field options, if no options are defined we will show a text input box instead'
            label='Options'
            fieldDescription='To give a dropdown list of options, type each, separated by a semicolon (for example "Cats; Dogs"). To have free-text responses instead, leave this box empty.'
            fieldName={`${field}.lookupValues`}
            isRequired={false}
            fieldClass={getFormFieldClass(defaultOptions, null, 'l-form__item--l')}
            tooltipInLabel={getFormFieldTranslationTooltip(defaultOptions)}
          />
        )}
        <EngageCheckboxInputField
          id={`${field}-visible`}
          type='checkbox'
          fieldName={`${field}.visible`}
          isToggle={true}
          checkedLabel='Visible'
          uncheckedLabel='Hidden'
          hideLabel={true}
          classModifier='o-checkbox--inline'
        />
        <EngageCheckboxInputField
          id={`${field}-required`}
          type='checkbox'
          fieldName={`${field}.required`}
          isToggle={true}
          checkedLabel='Required'
          uncheckedLabel='Optional'
          hideLabel={true}
          classModifier='o-checkbox--inline'
        />
      </div>
    </div>
  );
}

FieldEdit.propTypes = {
  field: PropTypes.string.isRequired,
  defaultLabel: PropTypes.string,
  defaultOptions: PropTypes.string,
  label: PropTypes.string.isRequired,
  showLabelField: PropTypes.bool,
  showOptionsField: PropTypes.bool,
};

export default FieldEdit;
