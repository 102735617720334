import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { VIEW } from 'common/ability';
import { DEMOGRAPHICS, PROJECT } from 'common/constants/RouteConstants';
import SettingsOption from '../../SettingsOption';

function DemographicVisibility(props) {
  const { Store, DemographicsStore, projectId } = props;

  const [demographicQuestions, setDemographicQuestions] = useState([]);

  useEffect(() => {
    DemographicsStore.load(projectId).then((res) => setDemographicQuestions([...res.questions]));
  }, [DemographicsStore, projectId]);

  const demographics = Store?.publishStatus?.demographics[0] || {};

  function toggleChangeHandler(checked) {
    Store.setDemographicsVisibility(demographics, checked);
  }

  function getIsActive() {
    return demographics.isVisible && !demographics.isPublicEmpty;
  }

  function getDemographicInstructionMessage() {
    let infoMessage = '';
    if (demographics.isPublicEmpty) {
      if (demographicQuestions.length > 0) {
        infoMessage = (
          <Fragment>
            Demographics questions <strong>need to be published before they can be activated.</strong>
          </Fragment>
        );
      } else {
        infoMessage = <Fragment>There are no demographic questions added.</Fragment>;
      }
    } else {
      infoMessage = (
        <Fragment>
          Demographic questions are <strong>only visible on active surveys.</strong>
        </Fragment>
      );
    }

    return infoMessage;
  }

  function getDemographicActionMessage() {
    return demographics.isPublicEmpty && demographicQuestions.length === 0
      ? 'Add demographic question'
      : 'Edit demographic questions';
  }

  return (
    <Fragment>
      <SettingsOption
        id='demographics-visibility'
        title='Demographic questions'
        toggleLabelOn='Use demographic questions in your surveys'
        isActive={getIsActive()}
        onChange={toggleChangeHandler}
        toggleTag={getIsActive() ? 'PUBLIC' : 'HIDDEN'}
        isDisabled={!demographics.isPresentInPublicProject || demographics.isPublicEmpty}
      />

      <div className='c-message is-note-subtle c-message--large'>
        <p className='c-message__description'>
          {getDemographicInstructionMessage()}
          <br />
          <Link to={`/${PROJECT}/${projectId}/edit/${DEMOGRAPHICS}`}>{getDemographicActionMessage()}</Link>
        </p>
      </div>
    </Fragment>
  );
}

DemographicVisibility.propTypes = {
  Store: PropTypes.object.isRequired,
  DemographicsStore: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
};

export default inject((root) => ({
  Store: root.RootStore.projectControlStore,
  DemographicsStore: root.RootStore.demographicsStore,
}))(observer(DemographicVisibility));
