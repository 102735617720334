import React from 'react';
import { ReportEnums } from '../../../shared/engage';

/*
How to produce charts:
  1) add `circle.o-pie__segment`
  2) add next color from the set
  3) add `stroke-dasharray="25 75"` for 25%. For 37% it would be "37 64".
  4) add `stroke-dashoffset="X"` where X is calculated by the formula:
    Circumference − All preceding segments’ total length + First segment’s offset = Current segment offset
    100 - 25 + 25

  Full article: https://medium.com/@heyoka/scratch-made-svg-donut-pie-charts-in-html5-2c587e935d72

  Color set:
  - #EEF4FB (Grey, default)
  - #78DCA0 (Green)
  - #060049 (Blue)
*/

export default function DoughnutChart(props) {
  const { stats } = props;
  // console.log('PIE', stats);

  return (
    <svg width='80' height='80' viewBox='0 0 42 42' xmlns='http://www.w3.org/2000/svg'>
      <circle className='o-pie__hole' cx='21' cy='21' r='15.91549430918954' fill='#fff' />
      <circle
        className='o-pie__ring'
        cx='21'
        cy='21'
        r='15.91549430918954'
        fill='transparent'
        stroke='#EEF4FB'
        strokeWidth='8'
      />

      {/* SEGMENTS */}
      {stats.map((item, idx) => (
        <circle
          key={idx}
          className='o-pie__segment'
          cx='21'
          cy='21'
          r='15.91549430918954'
          fill='transparent'
          stroke={ReportEnums().getChartColor(idx)}
          strokeWidth='8'
          strokeDasharray={`${item.value} ${item.rest}`}
          strokeDashoffset={`${item.offset}`}
        />
      ))}
    </svg>
  );
}
