import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '../../../../common/components/SvgIcon';

function StatEffectivenessBlock(props) {
  const { pollStats, modifierClass, icon, isLegacy = false } = props;
  const { effectiveness, effectivenessV2 = { nrStarted: 0, nrCompleted: 0 }, nrEnteredTextFeedback } = pollStats;
  const pollEffectivenessV2 = effectivenessV2.nrStarted
    ? `${Math.round((effectivenessV2.nrCompleted / effectivenessV2.nrStarted) * 100)}%`
    : '-';
  const pollEffectivenessLegacy = effectiveness.nrStarted
    ? `${Math.round((effectiveness.nrCompleted / effectiveness.nrStarted) * 100)}%`
    : '-';
  const pollEffectiveness = isLegacy ? pollEffectivenessLegacy : pollEffectivenessV2;

  return (
    <div className={`c-stat l-stat ${modifierClass}`}>
      <h3 className='c-stat__title'>
        <div className='c-stat__icon'>
          <SvgIcon icon={icon} />
        </div>
        {pollStats.name}
        {/* {pollStats.isActive && <span className='o-tag'>ongoing</span>} */}
      </h3>
      <div className='c-stat__highlight'>{pollEffectiveness}</div>
      <div className='c-stat__description'>
        {pollStats.nrQuestions} questions, {pollStats.nrFacts} facts
        {isLegacy ? (
          <small>
            {effectiveness.nrStarted || 0} users started and {effectiveness.nrCompleted || 0} completed
          </small>
        ) : (
          <Fragment>
            <small>{effectiveness.nrStarted || 0} users voted</small>
            <small>{effectiveness.nrCompleted || 0} users voted on all questions</small>
            <small>{nrEnteredTextFeedback || 0} users entered text feedback</small>
          </Fragment>
        )}
      </div>
    </div>
  );
}

StatEffectivenessBlock.propTypes = {
  pollStats: PropTypes.shape({
    effectiveness: PropTypes.object.isRequired,
    effectivenessV2: PropTypes.object.isRequired,
    nrEnteredTextFeedback: PropTypes.number.isRequired,
  }).isRequired,
  modifierClass: PropTypes.string,
  icon: PropTypes.string,
  isLegacy: PropTypes.bool,
};

export default StatEffectivenessBlock;
