import React from 'react';
import { SortableElement } from 'react-sortable-hoc';

import NewsItem from './NewsItem';

const NewsSortableItem = SortableElement((props) => {
  return <NewsItem {...props} />;
});

export default NewsSortableItem;
