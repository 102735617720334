// export const IMAGE_URL_PREFIX = 'https://d3r96op98ddxpd.cloudfront.net/static/media';
export const IMAGE_URL_PREFIX = 'https://d3h3sugvwhjhl.cloudfront.net/static/media';
export const PNG_IMAGE_URL_PREFIX = 'https://d3h3sugvwhjhl.cloudfront.net/static/assets/reactions';
export const S3_IMAGE_URL_PREFIX = 'https://s3-us-west-2.amazonaws.com/gmv-cdn.givemyview.com/static/media';
export const S3_FEEDBACK_IMAGE_URL_PREFIX =
  'https://s3.us-west-2.amazonaws.com/gmv-done.image.givemyview.com/user-content/image';
export const S3_PNG_IMAGE_URL_PREFIX =
  'https://s3-us-west-2.amazonaws.com/gmv-cdn.givemyview.com/static/assets/reactions';
export const ACCESS_IMAGES_DIRECTLY_ON_S3 = true;

export const IMAGE_TYPE_LOGO = 'LOGO';
export const IMAGE_TYPE_PROFILE_BACKGROUND = 'PROFILE_BACKGROUND';
export const IMAGE_TYPE_PROJECT_SQUARE = 'PROJECT_SQUARE';
export const IMAGE_TYPE_PROJECT_PANEL = 'PROJECT_PANEL';
export const IMAGE_TYPE_PROJECT_SMALL = 'PROJECT_SMALL';
export const IMAGE_TYPE_PROJECT_SQUARE_2x = 'PROJECT_SQUARE_2X';
export const IMAGE_ORIGINAL = 'ORIGINAL';
export const REACTION_PNG_IMAGE_TYPE = 'REACTION_PNG';
export const REACTION_PNG_IMAGE_TYPE_STATIC_MAP = 'REACTION_PNG_IMAGE_TYPE_STATIC_MAP';

// project images

function imageUrl(id, type, useS3Directly) {
  const sufix = {
    LOGO: 'gmv-square-s_300x300',
    PROFILE_BACKGROUND: 'gmv-ultra-wide-s_800x200',
    PROJECT_SQUARE: 'gmv-square-s_300x300',
    PROJECT_PANEL: 'gmv-wide-s_800x480',
    PROJECT_SMALL: 'gmv-wide-s_800x480',
    PROJECT_SQUARE_2X: 'gmv-square-s_300x300',
    ORIGINAL: 'original',
  };

  const url = `${IMAGE_URL_PREFIX}/${id}-${sufix[type]}.jpeg`;
  const directS3url = `${S3_IMAGE_URL_PREFIX}/${id}-${sufix[type]}.jpeg?v=${new Date().getTime()}`;
  return useS3Directly ? directS3url : url;
}

function feedbackImageUrl(id) {
  return `${S3_FEEDBACK_IMAGE_URL_PREFIX}/${id}-original.jpeg`;
}

function spritePngImageUrl(id) {
  return `${PNG_IMAGE_URL_PREFIX}/${id}_sprite.png`;
}

function pngImageUrl(id, type, useS3Directly) {
  const sufix = {
    REACTION_PNG_IMAGE_TYPE_STATIC_MAP: 'gmv-emoji-s_48x48',
    REACTION_PNG: 'gmv-emoji-l_144x144',
  };

  const url = `${PNG_IMAGE_URL_PREFIX}/${id}-${sufix[type]}.png`;
  const directS3url = `${S3_PNG_IMAGE_URL_PREFIX}/${id}-${sufix[type]}.png?v=${new Date().getTime()}`;
  return useS3Directly ? directS3url : url;
}
// eslint-disable-next-line
export default {
  imageUrl,
  feedbackImageUrl,
  spritePngImageUrl,
  pngImageUrl,
};

// Before remapping
// let sufix = {
//   'LOGO': 'logo',
//   'PROFILE_BACKGROUND': 'bg-m',
//   'PROJECT_SQUARE': 'square',
//   'PROJECT_PANEL': 'panel',
//   'PROJECT_SMALL': 'small',
//   'PROJECT_SQUARE_2X': 'square_2x'
// };
