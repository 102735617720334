import Agent from './agent';

const requests = Agent.requests;
const ENDPOINT = 'polls';
const SUB_ENDPOINT = 'questions';
const SUB_SUB_ENDPOINT = 'choice';

const PollsAgent = {
  // ###
  // Polls list /project/:id/polls
  // ###
  all: (projectId) => requests.get(`/projects/${projectId}/${ENDPOINT}`),
  create: (projectId, data) => requests.post(`/projects/${projectId}/${ENDPOINT}`, data),
  reorder: (projectId, itemId, data) => requests.post(`/projects/${projectId}/${ENDPOINT}/${itemId}/reorder`, data),
  reorderPolls: (projectId, data) => requests.post(`/projects/${projectId}/${ENDPOINT}/reorder`, data),

  // ###
  // poll /project/:id/polls/:pollId
  // ###
  getById: (projectId, pollId) => requests.get(`/projects/${projectId}/${ENDPOINT}/${pollId}`),
  update: (projectId, pollId, data) => requests.patch(`/projects/${projectId}/${ENDPOINT}/${pollId}`, data),
  remove: (projectId, pollId) => requests.remove(`/projects/${projectId}/${ENDPOINT}/${pollId}`),
  uploadPollImage: (projectId, pollId, file, progress) =>
    requests.uploadImage(`/projects/${projectId}/${ENDPOINT}/${pollId}/resource/`, 'project', file, progress),
  removePollImage: (projectId, pollId) => requests.remove(`/projects/${projectId}/${ENDPOINT}/${pollId}/resource`),

  // ###
  // questions /project/:id/polls/:pollId/questions/
  // ###
  createQuestion: (projectId, itemId, data) =>
    requests.post(`/projects/${projectId}/${ENDPOINT}/${itemId}/${SUB_ENDPOINT}`, data),
  updateQuestion: (projectId, itemId, questionId, data) =>
    requests.patch(`/projects/${projectId}/${ENDPOINT}/${itemId}/${SUB_ENDPOINT}/${questionId}`, data),
  removeQuestion: (projectId, itemId, questionId) =>
    requests.remove(`/projects/${projectId}/${ENDPOINT}/${itemId}/${SUB_ENDPOINT}/${questionId}`),
  uploadQuestionVideo: (projectId, pollId, questionId, data) =>
    requests.post(`/projects/${projectId}/${ENDPOINT}/${pollId}/${SUB_ENDPOINT}/${questionId}/video/`, data),
  uploadQuestionImage: (projectId, pollId, questionId, file, progress) =>
    requests.uploadImage(
      `/projects/${projectId}/${ENDPOINT}/${pollId}/${SUB_ENDPOINT}/${questionId}/resource/`,
      'project',
      file,
      progress,
    ),
  removeQuestionImage: (projectId, pollId, questionId, questionImageId) =>
    requests.remove(
      `/projects/${projectId}/${ENDPOINT}/${pollId}/${SUB_ENDPOINT}/${questionId}/resource/${questionImageId}`,
    ),
  copyFromQuestionTemplate: (projectId, pollId, data) =>
    requests.post(`/projects/${projectId}/${ENDPOINT}/${pollId}/${SUB_ENDPOINT}/copy-from-template`, data),

  // ###
  // Choice
  // ###
  uploadChoiceImage: (projectId, pollId, questionId, choiceId, file, progress) =>
    requests.uploadImage(
      `/projects/${projectId}/${ENDPOINT}/${pollId}/${SUB_ENDPOINT}/${questionId}/${SUB_SUB_ENDPOINT}/${choiceId}/resource/`,
      'project',
      file,
      progress,
    ),
  removeChoiceImage: (projectId, pollId, questionId, choiceId) =>
    requests.remove(
      `/projects/${projectId}/${ENDPOINT}/${pollId}/${SUB_ENDPOINT}/${questionId}/${SUB_SUB_ENDPOINT}/${choiceId}/resource`,
    ),

  // ###
  // Annotation reactions
  // ###
  loadAnnotationReactions: () => requests.get(`/recommender/reaction-templates`),
  loadRatingIcons: () => requests.get(`/recommender/rating-icons`),
  addAnnotationReactions: (projectId, pollId, questionId, data) =>
    requests.post(`/projects/${projectId}/${ENDPOINT}/${pollId}/${SUB_ENDPOINT}/${questionId}/add-reaction`, data),
  removeAnnotationReaction: (projectId, pollId, questionId, reactionId) =>
    requests.remove(`/projects/${projectId}/${ENDPOINT}/${pollId}/${SUB_ENDPOINT}/${questionId}/choice/${reactionId}`),
  reorderAnnotationReactions: (projectId, pollId, questionId, data) =>
    requests.post(`/projects/${projectId}/${ENDPOINT}/${pollId}/${SUB_ENDPOINT}/${questionId}/reorder`, data),
  reorderLeadImages: (projectId, pollId, questionId, data) =>
    requests.post(`/projects/${projectId}/${ENDPOINT}/${pollId}/${SUB_ENDPOINT}/${questionId}/resource/reorder`, data),
};

export default PollsAgent;
