import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../../../common/components/SvgIcon';
import CheckboxToggleControl from '../../../common/components/checkboxToggleControl/CheckboxToggleControl';
import { QUESTION_TYPES } from '../../../common/constants/QuestionConstants';

function QuestionHeader(props) {
  const {
    question,
    onCompareChange,
    showTitle = true,
    tag = '',
    isDemographic = false,
    onSnapshot,
    exportInProgress,
  } = props;
  const { questionId, label, questionType } = question;

  function getQuestionTypeObject(type) {
    // All demographics questions share same label and icon
    if (isDemographic) return { label: 'Demographic', icon: 'user' };

    if (QUESTION_TYPES.properties[type]) return QUESTION_TYPES.properties[type];

    // If question is part of a group
    // eslint-disable-next-line no-restricted-syntax
    for (const item of Object.entries(QUESTION_TYPES.properties)) {
      if (item[1].items) if (item[1].items[type]) return item[1].items[type];
    }

    return {};
  }

  return (
    <div className='c-question__header'>
      <div className='c-question__type'>
        <SvgIcon icon={getQuestionTypeObject(questionType).icon} />
        {getQuestionTypeObject(questionType).label}
        <span className='o-tag is-invert'>{tag}</span>
      </div>

      {/* false is set intentionaly until we have server side functionality developed */}
      {onCompareChange && false && (
        <div className='c-question__actions'>
          <div className='c-question__compare'>
            <CheckboxToggleControl
              id={`${questionId} checkbox-block-intro`}
              initialValue={false}
              onChangeCallback={onCompareChange}
              labelOn='Compare'
              classModifier='o-checkbox--s'
            />
          </div>
        </div>
      )}

      {onSnapshot && !exportInProgress && (
        <div className='c-question__actions'>
          <div className='c-question__snapshot'>
            <button className='o-button o-button--xs' onClick={onSnapshot}>
              <span className='o-label'>Snapshot</span>
              <SvgIcon icon='image' />
            </button>
          </div>
        </div>
      )}

      {showTitle && <h2 className='c-question__title'>{label}</h2>}
    </div>
  );
}

QuestionHeader.propTypes = {
  question: PropTypes.shape({
    questionId: PropTypes.string.isRequired,
    label: PropTypes.string,
    questionType: PropTypes.string.isRequired,
  }).isRequired,
  // compareActive: PropTypes.bool,
  onCompareChange: PropTypes.func,
  showTitle: PropTypes.bool,
  tag: PropTypes.string,
  isDemographic: PropTypes.bool,
  onSnapshot: PropTypes.func,
  exportInProgress: PropTypes.bool,
};

export default QuestionHeader;
