import { commands } from '@uiw/react-md-editor';

export const MARKDOWN_MIN = {
  editorConfig: [commands.bold, commands.italic],
  renderConfig: ['strong', 'emphasis', 'text'],
};

export const MARKDOWN_MIN_LINK = {
  editorConfig: [commands.bold, commands.italic, commands.link],
  renderConfig: ['strong', 'emphasis', 'text', 'link'],
};

export const MARKDOWN_MIN_PLUS = {
  editorConfig: [commands.bold, commands.italic],
  renderConfig: ['strong', 'paragraph', 'emphasis', 'text'],
};

export const MARKDOWN_MID = {
  editorConfig: [commands.bold, commands.italic, commands.link],
  renderConfig: ['strong', 'paragraph', 'emphasis', 'link', 'text'],
};

export const MARKDOWN_MAX = {
  editorConfig: [
    commands.bold,
    commands.italic,
    commands.link,
    commands.unorderedListCommand,
    commands.orderedListCommand,
  ],
  renderConfig: ['strong', 'p', 'em', 'a', 'ul', 'li'],
};

export const EDITOR_EXTRA_COMMANDS = {
  editorConfig: [commands.codeEdit, commands.codePreview],
};
