module.exports = function () {
  let _ = require('lodash');

  const ActionType = {
    PUBLISH_PROJECT: 'PUBLISH_PROJECT',
    ACTIVATE_PROJECT: 'ACTIVATE_PROJECT',
    INACTIVATE_PROJECT: 'INACTIVATE_PROJECT',
    ACTIVATE_PANEL: 'ACTIVATE_PANEL',
    INACTIVATE_PANEL: 'INACTIVATE_PANEL',
    PUBLISH_OR_UPDATE_PUBLIC_POLL: 'PUBLISH_OR_UPDATE_PUBLIC_POLL',
    ACTIVATE_POLL: 'ACTIVATE_POLL',
    INACTIVATE_POLL: 'INACTIVATE_POLL',
    DELETE_POLL: 'DELETE_POLL',
    COPY_POLL: 'COPY_POLL',
    CREATE_POLL_DRAFT: 'CREATE_POLL_DRAFT',
    PUBLISH_OR_UPDATE_PUBLIC_DEMOGRAPHICS: 'PUBLISH_OR_UPDATE_PUBLIC_DEMOGRAPHICS',
    ACTIVATE_DEMOGRAPHICS: 'ACTIVATE_DEMOGRAPHICS',
    INACTIVATE_DEMOGRAPHICS: 'INACTIVATE_DEMOGRAPHICS',
    DELETE_DEMOGRAPHICS: 'DELETE_DEMOGRAPHICS',
    ACTIVATE_SEGMENTATION: 'ACTIVATE_SEGMENTATION',
    INACTIVATE_SEGMENTATION: 'INACTIVATE_SEGMENTATION',
    PUBLISH_OR_UPDATE_PUBLIC_SEGMENTATION: 'PUBLISH_OR_UPDATE_PUBLIC_SEGMENTATION',
    ACTIVATE_SITEMAP: 'ACTIVATE_SITEMAP',
    INACTIVATE_SITEMAP: 'INACTIVATE_SITEMAP',
    SHIFT_PROJECT_OWNERSHIP: 'SHIFT_PROJECT_OWNERSHIP',
    EDIT_DEMOGRAPHICS_TEMPLATE: 'EDIT_DEMOGRAPHICS_TEMPLATE',
    ADD_ALIAS: 'ADD_ALIAS',
    DELETE_ALIAS: 'DELETE_ALIAS',
    ANONYMIZE_USER_EMAIL: 'ANONYMIZE_USER_EMAIL',
    CHANGE_PROJECT_TYPE: 'CHANGE_PROJECT_TYPE',
  };

  const ActionDescription = {
    PUBLISH_OR_UPDATE_HIDDEN_POLL: 'Publishing poll while hidden. Poll data in additionalInfo.',
    PUBLISH_OR_UPDATE_PUBLIC_POLL: 'Update published poll. Poll data in additionalInfo.',
    PUBLISH_OR_UPDATE_HIDDEN_SEGMENTATION: 'Publishing segmentation while hidden. Segmentation data in additionalInfo.',
    PUBLISH_OR_UPDATE_PUBLIC_SEGMENTATION: 'Update published segmentation. Segmentation data in additionalInfo.',
  };

  return {
    ActionType: ActionType,
    actionTypeList: actionTypeList,
    ActionDescription: ActionDescription,
  };

  function actionTypeList() {
    return _.map(Object.keys(ActionType), (key) => {
      return ActionType[key];
    });
  }
};
