// NOTE:
//      Derived from /server/bid/api/model/permissions/permissionsEnum.js

/**
 * Here we enumerate all permissions handled by the system
 */
module.exports = function () {
  const TEMPLATE_SEARCH_MAX_QUESTIONS_COUNT = 200;

  const VISIT_DESTINATIONS = {
    PROJECT: 'project',
    CLIENT: 'client',
  };

  return {
    TEMPLATE_SEARCH_MAX_QUESTIONS_COUNT: TEMPLATE_SEARCH_MAX_QUESTIONS_COUNT,
    VISIT_DESTINATIONS: VISIT_DESTINATIONS,
  };
};
