import { action, observable, computed, runInAction, makeObservable } from 'mobx';

import { createApi } from 'unsplash-js';
import AgentPoll from '../common/agent';

const Agent = AgentPoll.ImageLibraryAgent;

const SEARCH_UNSPLASH_IMAGES_ERROR = 'Error searching Unsplash images';
const DOWNLOAD_UNSPLASH_IMAGE_ERROR = 'Error downloading Unsplash image';
const LOAD_GMV_CATEGORIES_ERROR = 'Error loading GMV categories';
const LOAD_GMV_CATEGORY_IMAGES_ERROR = 'Error loading images for selected category';
const LOAD_RECENT_IMAGES_ERROR = 'Error loading recent images';
const LOAD_FAVOURITE_IMAGES_ERROR = 'Error loading favourite images';

const UNSPLASH_ACCESS_KEY = '8XibOvjET-oWMs-Q4BqaeF9zo1ATBEMed5xIUJqYqbg';

const unsplash = createApi({
  accessKey: UNSPLASH_ACCESS_KEY,
});

export default class ImageLibraryStore {
  _loading = false;

  _initialized = false;

  _error = null;

  _unsplashSearch = null;

  _gmvLibraryCategories = null;

  _gmvCategoryImages = [];

  _recentImages = [];

  _favouriteImages = [];

  _modalOpen = false;

  _currentlySelectedUrl = null;

  _modalActivatorId = null;

  _disableModalActivation = false;

  _showUploaderSearchButton = true;

  _requiredImageFormat = null;

  constructor(rootStore) {
    makeObservable(this, {
      _unsplashSearch: observable,
      _gmvLibraryCategories: observable,
      _gmvCategoryImages: observable,
      _recentImages: observable,
      _favouriteImages: observable,
      _loading: observable,
      _initialized: observable,
      _error: observable,
      _modalOpen: observable,
      _currentlySelectedUrl: observable,
      _modalActivatorId: observable,
      _disableModalActivation: observable,
      _showUploaderSearchButton: observable,
      _requiredImageFormat: observable,

      isLoading: computed,
      isInitialized: computed,
      error: computed,
      unsplashSearch: computed,
      gmvLibraryCategories: computed,
      gmvCategoryImages: computed,
      recentImages: computed,
      favouriteImages: computed,
      modalOpen: computed,
      currentlySelectedUrl: computed,
      modalActivatorId: computed,
      disableModalActivation: computed,
      showUploaderSearchButton: computed,
      requiredImageFormat: computed,

      resetFlags: action,
    });

    this.rootStore = rootStore;
  }

  get isLoading() {
    return this._loading;
  }

  get isInitialized() {
    return this._initialized;
  }

  get error() {
    return this._error;
  }

  get unsplashSearch() {
    return this._unsplashSearch;
  }

  get gmvLibraryCategories() {
    return this._gmvLibraryCategories;
  }

  get gmvCategoryImages() {
    return this._gmvCategoryImages;
  }

  get recentImages() {
    return this._recentImages;
  }

  get favouriteImages() {
    return this._favouriteImages;
  }

  get modalOpen() {
    return this._modalOpen;
  }

  set modalOpen(val) {
    this._modalOpen = val;
    this.rootStore.helpStore.closeHelp();
  }

  get currentlySelectedUrl() {
    return this._currentlySelectedUrl;
  }

  set currentlySelectedUrl(val) {
    this._currentlySelectedUrl = val;
  }

  get modalActivatorId() {
    return this._modalActivatorId;
  }

  set modalActivatorId(val) {
    this._modalActivatorId = val;
  }

  get disableModalActivation() {
    return this._disableModalActivation;
  }

  set disableModalActivation(val) {
    this._disableModalActivation = val;
  }

  get showUploaderSearchButton() {
    return this._showUploaderSearchButton;
  }

  set showUploaderSearchButton(val) {
    this._showUploaderSearchButton = val;
  }

  get requiredImageFormat() {
    return this._requiredImageFormat;
  }

  set requiredImageFormat(val) {
    this._requiredImageFormat = val;
  }

  async loadUnsplashImages(searchValue, page, perPage) {
    this._loading = false;
    this._error = null;

    try {
      const res = await unsplash.search.getPhotos({ query: searchValue, page, perPage });

      runInAction(() => {
        this._unsplashSearch = res.response;
      });
      return res.response;
    } catch (err) {
      this._error = err.response;
      this.rootStore.toastrStore.error(SEARCH_UNSPLASH_IMAGES_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  async getUnsplashImageDownloadUrl(downloadLocationUrl) {
    this._loading = false;
    this._error = null;

    try {
      const res = await Agent.getUnsplashImageDownloadUrl(`${downloadLocationUrl}&client_id=${UNSPLASH_ACCESS_KEY}`);

      runInAction(() => {
        this._currentlySelectedUrl = res.data.url;
        this._modalOpen = false;
      });

      return true;
    } catch (err) {
      this._error = err.response;
      this.rootStore.toastrStore.error(DOWNLOAD_UNSPLASH_IMAGE_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  async loadGmvLibraryCategories() {
    this._loading = false;
    this._error = null;

    try {
      const res = await Agent.getImageLibraryCategories();

      runInAction(() => {
        this._gmvLibraryCategories = res.data;
      });
      return res.data;
    } catch (err) {
      this._error = err.response;
      this.rootStore.toastrStore.error(LOAD_GMV_CATEGORIES_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  async loadGmvCategoryImages(selectedCategory) {
    this._loading = false;
    this._error = null;

    try {
      const res = await Agent.getGmvCategoryImages(selectedCategory);

      runInAction(() => {
        this._gmvCategoryImages = res.data;
      });
      return res.data;
    } catch (err) {
      this._error = err.response;
      this.rootStore.toastrStore.error(LOAD_GMV_CATEGORY_IMAGES_ERROR, null, err);
      return false;
    } finally {
      this.resetFlags();
    }
  }

  async loadRecentImages() {
    this._loading = false;
    this._error = null;

    try {
      const res = await Agent.getRecentImages();

      runInAction(() => {
        this._recentImages = res.data;
      });
      return res.data;
    } catch (err) {
      this._error = err.response;
      this.rootStore.toastrStore.error(LOAD_RECENT_IMAGES_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  async loadFavouriteImages() {
    this._loading = false;
    this._error = null;

    try {
      const res = await Agent.getFavouriteImages();

      runInAction(() => {
        this._favouriteImages = res.data;
      });
      return res.data;
    } catch (err) {
      this._error = err.response;
      this.rootStore.toastrStore.error(LOAD_FAVOURITE_IMAGES_ERROR, null, err);
    } finally {
      this.resetFlags();
    }
  }

  resetFlags = () => {
    this._loading = false;
    this._error = null;
  };
}
